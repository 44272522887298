import { findIndex, filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RestService } from '../services/rest/rest.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';


declare const $: any;
@Component({
	selector: 'app-add-new-discussion',
	templateUrl: './add-new-discussion.component.html',
	styleUrls: ['./add-new-discussion.component.scss']
})

export class AddNewDiscussionComponent implements OnInit {
	discussionAddForm: FormGroup;
	files: any = [];
	imgArr: any = [];
	pid: any;
	userData: any;
	projectData: any;
	selectAll: boolean;
	selectNone: boolean;
	usersArr: any[] = [];
	isPeopleShow: boolean = false;
	did: any;
	discussionDetail: any;
	isForEdit: boolean = false;
	userList = [];
	userListEscap = [];
	totalProjectUsers = 0;
	isAddThisButtonNotShow: boolean = true;
	deletedAttachmentItmes: any[] = [];



	constructor(private formBuilder: FormBuilder, private restService: RestService, private toastr: ToastrService,
		private route: ActivatedRoute,
		private router: Router) {

		this.userData = JSON.parse(localStorage.getItem("userData"));
		// this.pid = JSON.parse(localStorage.getItem("projectData")).id;
		this.route.params.subscribe(params => {
			this.pid = params['pid'];
			this.did = params['did'];
			if (this.pid && this.pid != null && this.pid !== undefined) {
				// this.isLoading = true;
				this.getProjectDetail();
				// this.getProjectUpdate();
				// this.getProjectDiscussion();
				// this.getAttachments();
				// this.getLinks();
			}
			if (this.did) {
				this.isForEdit = true;
				this.getDiscussionThreads()
			}

		});
		if (this.userData) {
			//this.getUserprofile();
		}

	}

	ngOnInit() {

		$(document).ready(() => {
			let that = this;

			$('.htmlEditor').summernote({
				height: '200px',
				focus: true,
				disableDragAndDrop: true,
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
				callbacks: {

					onChange: function (contents, $editable) {
						//that.discussionAddForm.get('comment').setValue(contents);
						that.discussionAddForm.get('description').setValue(contents);

						console.log('onChange:', contents, $editable);
						console.log('discussionAddForm:', that.discussionAddForm);
					},
					onImageUpload: function (data) {
						data.pop();
					},
					onPaste: function (e) {
						var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
						e.preventDefault();
						document.execCommand('insertText', false, bufferText);
					}
				}



			});


			$('#summernote2').summernote({
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline', 'clear']],
					['font', ['strikethrough', 'superscript', 'subscript']],
					['fontsize', ['fontsize']],
					['color', ['color']],
					['para', ['ul', 'ol', 'paragraph']],
					['height', ['height']]
				]
			});



		});
		this.createForm(null);

	}



	getDiscussionThreads() {

		this.restService.hitApi('discussion/threads/list', { 'projectId': this.pid, 'threadId': this.did }, 'post', '', false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.discussionDetail = responseData.result.details;
					this.createForm(this.discussionDetail);
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})


	}

	createForm(data) {
		if (data) {
			this.discussionAddForm = this.formBuilder.group({
				title: [data.title, [Validators.required, Validators.maxLength(150)]],
				description: [data.description],
				client_display: [''],

			});
			$('.htmlEditor').summernote('code', data.description);

			if (data.projectThreadDetails && data.projectThreadDetails.projectThreadPostImages.length > 0) {
				data.projectThreadDetails.projectThreadPostImages.forEach(element => {
					this.files.push({ id: element.id, name: element.imageName, serverFileName: element.fileName, image: element.imageTitle, isProgressBarShown: false });
					//this.imgArr.push(element.displayName);
				});
				// 	}
			}
		} else {
			this.discussionAddForm = this.formBuilder.group({
				title: ['', [Validators.required, Validators.maxLength(150)]],
				// comment: [''],
				description: [''],
				client_display: [''],
				rad1: ['1']
			});
		}

	}

	getProjectDetail() {
		this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.projectData = responseData.result;
					console.log("projectData", this.projectData);
					this.getUserList(null);
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	getUserList(value) {

		//user list
		this.restService.hitApi('project/projectUsers', { 'projectId': this.pid, escape: value }, 'post', '', false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.userList = responseData.result;
					this.userList = this.userList.filter(el => (el.userId != this.userData.id) && (el.isEmailNotification == 0));
					if (this.projectData && this.userList && this.userList.length > 0) {
						var usersString = "(";
						for (var i = 0; i < this.userList.length; i++) {
							//if(this.userList[i].userId != this.userData.id){
							if (this.userList[i].isEmailNotification == 0) {
								this.totalProjectUsers++;
								// this.userListEscap[j] = this.userList[i];
								// j++;
								if (i != this.userList.length - 1) {
									usersString += this.userList[i].name + ', ';
								} else {
									usersString += this.userList[i].name + ") ";
								}
							}
						}
						this.projectData.projectUsersString = usersString;
						this.userListEscap = this.userList.filter(el => el.userId != this.userData.id);
					}


				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}


	uploadFile(event) {
		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];


			if (this.restService.checkFileSize(file, 1024)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				formData.append('imageFolder', 'thread_image/');
				formData.append('image', file);

				// const element = event[index];
				// this.files.push(file.name);
				var reader = new FileReader();
				const this_ = this;
				var obj;
				reader.onload = function (e: any) {
					obj = { name: file.name, serverFileName: file.name, image: e.target.result, isProgressBarShown: true }
					this_.files.push(obj);
				}

				reader.readAsDataURL(file);

				this.restService.hitApi('discussion/attachments', formData, 'post', '', false, false)
					.subscribe((result) => {
						let responseData: any = result;
						if (responseData.statusCode == 200) {
							this.imgArr.push(responseData.result);
							this.files[this.files.length - 1].serverFileName = responseData.result.fileName;
							this.files.forEach(element => {
								element.isProgressBarShown = false;
							});
							console.log("imgArr", this.imgArr);

						} else {
							this.toastr.error(responseData.message);
						}
					}, (err) => {
						console.log(err);
						let errorData: any = err;
						this.toastr.error(errorData.error.message);
					})
			}
		}
	}
	onRadioButtonChange(value) {
		this.discussionAddForm.get('rad1').setValue(value);
		this.usersArr = [];
		this.selectAll = false;
		this.selectNone = false;
		if (this.userList) {
			this.userList.forEach(element => {
				element.checked = false;

			});
		}
	}
	onCheckBoxChange(value) {
		this.discussionAddForm.get('client_display').setValue(value);
		if (value == true) {
			this.getUserList([2]);
		} else {
			this.getUserList(null);
		}

		// this.userList = this.userList.filter(el => el.userRoleId != 2);
	}

	checkedAllUser() {
		if (this.userList) {
			this.userList.forEach(element => {
				element.checked = true;

			});
			this.emailRadioChange(true);
		}
	}
	checkedNoneUser() {
		if (this.userList) {
			this.userList.forEach(element => {
				element.checked = false;
			});
		}
	}

	userSelect(event, user) {
		user.checked = event.checked;
		var allChecked;
		if (this.userList) {
			console.log(this.userList);
			this.userList.forEach(element => {
				console.log("element -> ", element);
				allChecked = element.checked ? element.checked : false;

			});
			console.log("allChecked -> ", allChecked);
			if (allChecked) {
				this.selectAll = true;
				this.selectNone = false;
			} else {
				this.selectAll = false;
				this.selectNone = false;
			}
		}
		this.usersArr.push(user?.usersUser?.id);
		console.log("usersArr -> ", this.usersArr);
	}


	resetRadio(user, index) {

		var allChecked = false;
		if (this.userList) {
			this.userList.forEach(element => {
				allChecked = element.checked ? true : false;
			});
		}
		if (allChecked) {
			this.selectAll = true;
			this.selectNone = false;
			$('#select-all-radio').prop('checked', true);
			$('#select-none-radio').prop('checked', false);
		} else {
			if (this.userList.filter(t => t.checked).length > 0 && !allChecked) {
				this.selectAll = false;
				this.selectNone = false;
				$('#select-all-radio').prop('checked', false);
				$('#select-none-radio').prop('checked', false);
			} else {
				this.selectAll = false;
				this.selectNone = true;
				$('#select-all-radio').prop('checked', false);
				$('#select-none-radio').prop('checked', true);
			}

		}
		if (user.checked) {
			this.usersArr.push(user.userId)
		} else {
			this.usersArr.splice(index, 1)
		}

		console.log(this.usersArr);

	}

	removeAttachment(index) {
		var fileObj = this.files[index];
		this.deletedAttachmentItmes.push(fileObj?.id)
		// if (fileObj.id) {
		// 	this.deleteAttachment(fileObj.id);
		// } else {
		// 	this.removeUploadedFileFromServer(fileObj?.name);

		// }
		this.files.splice(index, 1);
		this.imgArr.splice(0, 1);


	}

	deleteItemsAndUpdateDiscussion() {
		if (this.deletedAttachmentItmes.length > 0) {
			var count = 0;
			for (var i = 0; i < this.deletedAttachmentItmes.length; i++) {

				var json = { "projectThreadPostImageId": this.deletedAttachmentItmes[i], "projectId": this.pid }

				this.restService.hitApi('discussion/attachments/delete', json, 'post', '', true, false)
					.subscribe((result) => {
						let responseData: any = result;
						if (responseData.statusCode == 200) {
							this.imgArr.splice(name, 1);
							count = i;
							console.log("this.imgArr", this.imgArr);
						} else {
							this.toastr.success(responseData.message);
						}
						if (count == this.deletedAttachmentItmes.length) {
							this.deletedAttachmentItmes = [];
							this.updateDiscussion()
						}
					}, (err) => {
						console.log(err);
						let errorData: any = err;
						this.toastr.error(errorData.error.message);
					})
			}
		} else {
			this.updateDiscussion()
		}
	}

	removeUploadedFileFromServer(name) {
		console.log("this.imgArr", this.imgArr);

		var json = {
			projectId: this.pid,
			imageFolder: 'thread_image/',
			imageName: name
		}

		this.restService.hitApi('discussion/attachments/deleteByFile', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.imgArr.splice(name, 1);
					console.log("this.imgArr", this.imgArr);
				} else {
					this.toastr.success(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	deleteAttachment(aid) {
		this.restService.hitApi('discussion/attachments/delete', { "projectThreadPostImageId": aid, "projectId": this.pid }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}



	emailRadioChange(isSelect: boolean) {
		this.usersArr = [];
		if (this.userList) {
			this.userList.forEach(element => {
				element.checked = isSelect;
				if (isSelect) {
					this.usersArr.push(element?.usersUser?.id);
				}
			});
		}
	}

	commentChange($event) {
		//this.discussionAddForm.get('comment').setValue($event.target.value);
		this.discussionAddForm.get('description').setValue($event.target.value);
	}
	titleChange(event) {
		this.discussionAddForm.get('title').setValue(event.target.value);
	}

	addDiscussion() {
		let that = this;

		//createThread

		this.discussionAddForm.value.description = $('.htmlEditor').val();
		this.discussionAddForm.value.users = this.usersArr;
		this.discussionAddForm.value.projectId = this.pid;
		this.discussionAddForm.value.client_display = this.discussionAddForm.value.client_display ? 0 : 1;
		this.discussionAddForm.value.upload_files_create = this.imgArr;

		//this.discussionAddForm.value.references = this.linkInputArr.value.filter(el => el != "");
		console.log(this.discussionAddForm.value);

		if (this.discussionAddForm.invalid) {
			return false;
		} else {
			this.restService.hitApi('discussion/create', this.discussionAddForm.value, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.files = [];
						this.selectAll = false;
						this.selectNone = false;
						this.usersArr = [];

						this.router.navigateByUrl('/project/' + this.pid + '/discussion-thread/' + responseData.result.id);

					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);

				});
		}


	}

	updateDiscussion() {
		this.discussionAddForm.value.description = $('.htmlEditor').val();
		this.discussionAddForm.value.projectId = this.pid;
		this.discussionAddForm.value.threadId = this.did;
		this.discussionAddForm.value.upload_files_create = this.imgArr;
		this.discussionAddForm.value.postId = this.discussionDetail?.projectThreadDetails?.id;

		if (this.discussionAddForm.invalid) {
			return false;
		} else {
			this.restService.hitApi('discussion/update', this.discussionAddForm.value, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.files = [];
						this.toastr.success(responseData.message);
						this.router.navigateByUrl('/project/' + this.pid + '/discussions');

					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);

				});
		}


	}

	getExtensionOfFileType(fileName) {
		if (fileName) {
			var str = fileName.split(".");
			if (str[str.length - 1] == 'jpg' || str[str.length - 1] == 'png' || str[str.length - 1] == 'jpeg' || str[str.length - 1] == 'JPG' || str[str.length - 1] == 'PNG') {
				return 'image'
			} else {
				return 'file'
			}

		}
	}


}
