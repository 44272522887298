import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { RestService } from '../services/rest/rest.service';

declare const $:any;
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  
  freePlanData: any;
  public href : any;
  constructor(
		private router: Router, 
	) {
		
    $("html, body").animate({ scrollTop: 0 }, "slow");
	}

  ngOnInit() {
    this.href = this.router.url;

    $('document').ready(function () {  
      // Sticky header function
      $(window).scroll(function() {
          if ($(this).scrollTop() > 1){
          $('header').addClass("sticky");
          }
          else{
          $('header').removeClass("sticky");
          }
      });
    });
  }


	

}
