import { Component, OnInit } from '@angular/core';
declare const $:any;
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }

  ngOnInit() {
  }

}
