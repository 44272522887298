import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
// Services
import { RestService } from '../services/rest/rest.service';
declare const $: any;

@Component({
	selector: 'app-discussion',
	templateUrl: './discussion.component.html',
	styleUrls: ['./discussion.component.scss'],
})

export class DiscussionComponent implements OnInit {
	threadAddForm: FormGroup;
	discussionAddForm: FormGroup;
	discussionData: any;
	updateData: any;
	attachmentData: any;
	linksData: any;

	projectData: any;
	discussionThreadData: any = [];
	discussionDetail: any = [];
	pid: any;
	did: any;
	usersArr: any[] = [];
	files: any = [];
	imgArr: any = [];
	linksArr: any = [];

	linkInputArr = new FormArray([new FormControl('')]);

	deleteType: any = "";
	deleteId: any = "";
	editDiscussion: boolean = false;
	userData: any = JSON.parse(localStorage.getItem("userData"));
	projectListData: any;
	selectAll: boolean = false;
	selectNone: boolean = false;
	userInfo: any;
	isThreadAddFormSubmit: boolean = false;

	/*editorConfig: AngularEditorConfig = {
		editable: true,
		  spellcheck: true,
		  height: '150px',
		  minHeight: '0',
		  maxHeight: 'auto',
		  width: 'auto',
		  minWidth: '0',
		  translate: 'yes',
		  enableToolbar: false,
		  showToolbar: true,
		  placeholder: 'Enter message here...',
		  defaultParagraphSeparator: '',
		  defaultFontName: '',
		  defaultFontSize: '1',
		sanitize: true,
		toolbarPosition: 'top',
		toolbarHiddenButtons: [
			[
			  'undo',
			  'redo',
			  'strikeThrough',
			  'subscript',
			  'superscript',
			//   'justifyLeft',
			//   'justifyCenter',
			//   'justifyRight',
			//   'justifyFull',
			  'indent',
			  'outdent',
			//   'insertUnorderedList',
			  //'insertOrderedList',
			  'heading',
			  'fontName'
			],
			[
			  
			  'textColor',
			  'backgroundColor',
			  'customClasses',
			//   'link',
			//   'unlink',
			  'insertImage',
			  'insertVideo',
			  'insertHorizontalRule',
			  'removeFormat',
			  'toggleEditorMode'
			]
		]
		
	};*/

	constructor(
		private formBuilder: FormBuilder,
		private restService: RestService,
		private toastr: ToastrService,
		private route: ActivatedRoute,
		private router: Router) {
		console.log(this.userData);
		this.route.params.subscribe(params => {
			this.pid = params['pid'];
			this.did = params['did'];
			if (this.pid && this.pid != null && this.pid !== undefined) {
				this.getProjectDetail();
			}
			if (this.did && this.did != null && this.did !== undefined) {
				this.getDiscussionThreads();
			}
		});
		if (this.userData) {
			this.getUserprofile();
		}
	}

	ngOnInit() {

		if (!localStorage.getItem('userData')) {
			this.router.navigate(['/login']);
		}

		this.getProjectList();
		this.getProjectDiscussion();
		this.createForm(null);


		$(document).ready(function () {
			$('#comment-discuss-box').click(function () {
				$('.comment-discuss-box-open').toggle("down");
			});
			$('.project-title-head').click(function () {
				$('.project-title-list').toggle("slide");
			});
			$('.select-change-email').click(function () {
				$('.select-email-message').toggle("down");
			});

			$('.htmlEditor').summernote({
				height: '150px'
			});
		});

		$(document).mouseup(function (e) {
			var container = $(".project-title-list");
			if (!container.is(e.target) && container.has(e.target).length === 0) {
				$(".project-title-list").hide('down');
			}
		});
	}


	createForm(data) {
		if (data === null) {
			this.threadAddForm = this.formBuilder.group({
				title: [''],
				projectThreadPostId: [''],
				comment: ['', [Validators.required]],
				client_display: [''],
				rad1: ['1', [Validators.required]]
			});
			// this.discussionAddForm = this.formBuilder.group({
			// 	title: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
			// });
		} else {
			this.threadAddForm = this.formBuilder.group({
				title: [''],
				comment: [data.comment, [Validators.required, Validators.minLength(3)]],
				client_display: [data.client_display],
				rad1: [data.rad1, [Validators.required]]
			});
		}


	}

	getUserprofile() {
		this.restService.hitApi('user/profile', { 'userId': this.userData.id }, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.userInfo = responseData.result;
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}


	getProjectDetail() {
		this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.projectData = responseData.result;
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	getProjectDiscussion() {
		// this.restService.hitApi('discussion/listByDate',{'projectId':this.pid},'post','',true)
		// .subscribe((result)=>{
		// 	let responseData : any = result;
		// 	if(responseData.statusCode==200){
		// 		this.updateData = responseData.result.data;
		// 	}else{
		// 		// this.toastr.error(responseData.message);
		// 	}
		// },(err)=>{
		// 	console.log(err);
		// 	let errorData : any = err;
		// 	// this.toastr.error(errorData.error.message);
		// })

		// this.restService.hitApi('discussion/list',{'projectId':this.pid},'post','',true)
		// .subscribe((result)=>{
		// 	let responseData : any = result;
		// 	if(responseData.statusCode==200){
		// 		this.discussionData = responseData.result.data;
		// 	}else{
		// 		// this.toastr.error(responseData.message);
		// 	}
		// },(err)=>{
		// 	console.log(err);
		// 	let errorData : any = err;
		// 	// this.toastr.error(errorData.error.message);
		// })

		// this.restService.hitApi('discussion/attachments/list',{'projectId':this.pid},'post','',true)
		// .subscribe((result)=>{
		// 	let responseData : any = result;
		// 	if(responseData.statusCode==200){
		// 		this.attachmentData = responseData.result.data;
		// 	}else{
		// 		// this.toastr.error(responseData.message);
		// 	}
		// },(err)=>{
		// 	console.log(err);
		// 	let errorData : any = err;
		// 	// this.toastr.error(errorData.error.message);
		// })

		// this.restService.hitApi('discussion/references/list',{'projectId':this.pid},'post','',true)
		// .subscribe((result)=>{
		// 	let responseData : any = result;
		// 	if(responseData.statusCode==200){
		// 		this.linksData = responseData.result.data;
		// 	}else{
		// 		// this.toastr.error(responseData.message);
		// 	}
		// },(err)=>{
		// 	console.log(err);
		// 	let errorData : any = err;
		// 	// this.toastr.error(errorData.error.message);
		// })

	}

	getDiscussionThreads() {

		this.restService.hitApi('discussion/threads/list', { 'projectId': this.pid, 'threadId': this.did }, 'post', '', false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.discussionThreadData = responseData.result.list.data;
					console.log("discussionThreadData", this.discussionThreadData);
					this.discussionDetail = responseData.result.details;
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})


	}

	addThread() {
		let that = this;
		this.isThreadAddFormSubmit = true;

		// $('.multiple-users-email-checkboxes').find('input').each(function () {
		// 	if ($(this).prop("checked")) {
		// 		that.usersArr.push($(this).val());
		// 	}
		// });

		// $('.link-content').find('input').each(function () {
		// 	if ($(this).val()) {
		// 		that.linksArr.push($(this).val());
		// 	}
		// });

		//createThread
		//console.log(this.threadAddForm.value);
		this.threadAddForm.get('comment').setValue($('#thread').val());
		this.threadAddForm.value.users = this.usersArr;
		this.threadAddForm.value.threadId = this.did;
		this.threadAddForm.value.projectId = this.pid;
		this.threadAddForm.value.client_display = this.threadAddForm.value.client_display ? 0 : 1;
		this.threadAddForm.value.upload_files_create = this.imgArr;
		this.threadAddForm.value.references = this.linkInputArr.value.filter(el => el != "");
		console.log('threadForm', this.threadAddForm);
		if (this.threadAddForm.invalid) {

			return true;
		} else {
			this.restService.hitApi('discussion/create', this.threadAddForm.value, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.isThreadAddFormSubmit = false;

						$('#comment-discuss-box').val('');
						$('.comment-discuss-box-open').toggle("up");
						this.toastr.success(responseData.message);
						this.getDiscussionThreads();
						this.threadAddForm.reset();
						$('.htmlEditor').summernote('code', '');
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);

				})
		}
	}

	//Image upload
	uploadFile(event) {
		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];
			const formData: FormData = new FormData();
			formData.append('projectId', this.pid);
			formData.append('imageFolder', 'thread_image/');
			formData.append('image', file);

			// const element = event[index];
			this.files.push(file.name);

			this.restService.hitApi('discussion/attachments', formData, 'post', '', true, false)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.imgArr.push(responseData.result);

					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}

	}


	checkUncheckAll(ele) {
		var checkboxes = $(".multiple-users-email-checkboxes input");
		if (ele) {
			for (var i = 0; i < checkboxes.length; i++) {
				// if (checkboxes[i].type == 'checkbox') {
				checkboxes[i].checked = true;
				// }
			}
		} else {
			for (var i = 0; i < checkboxes.length; i++) {
				console.log(i)
				// if (checkboxes[i].type == 'checkbox') {
				checkboxes[i].checked = false;
				// }
			}
		}
	}

	public addMoreField(num) {
		this.linkInputArr.push(new FormControl(''));
		// if (num > 0 && $.inArray(num + 1, this.linkInputArr) < 0) {
		// 	this.linkInputArr.push(num + 1);
		// }
	}

	removeField(index) {
		this.linkInputArr.removeAt(index);
	}

	closeModal(modal,) {
		$('#' + modal).modal('hide');
	}


	openEditDiscussion(data) {
		console.log(data)
		this.editDiscussion = true;
		this.discussionAddForm = this.formBuilder.group({
			title: [data.title, [Validators.required, Validators.minLength(3)]],
		});
	}

	openEditThreadModal(data, title = "") {
		console.log(data);
		$('#create_discuss_pop_up').modal('show');
		this.threadAddForm = this.formBuilder.group({
			projectThreadPostId: [data.id],
			title: [title],
			comment: [data.post, [Validators.required, Validators.minLength(3)]],
		});
		$('#discussion').summernote('code', data.post);

	}

	updateThread() {
		this.threadAddForm.value.comment = $('#discussion').val();
		this.threadAddForm.value.projectId = this.pid;
		// console.log(this.threadAddForm);
		this.restService.hitApi('discussion/threads/update', this.threadAddForm.value, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$("#create_discuss_pop_up").modal('hide');
					this.toastr.success(responseData.message);
					this.getDiscussionThreads();
					this.threadAddForm.reset();
					$('.htmlEditor').summernote('code', '');
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);

			})
	}

	updateDiscussion() {
		this.discussionAddForm.value.projectId = this.pid;
		this.discussionAddForm.value.threadId = this.did;
		this.restService.hitApi('discussion/update', this.discussionAddForm.value, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.editDiscussion = false;
					this.toastr.success(responseData.message);
					this.getDiscussionThreads();
					this.discussionAddForm.reset();
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);

			})
	}

	openDelete(type, id) {
		this.deleteType = type;
		this.deleteId = id;
		$('#delete-discussion').modal('show');
	}

	deleteDiscussion(type) {
		if (type == 'discussion') {
			this.restService.hitApi('discussion/delete', { 'projectId': this.pid, 'threadId': this.deleteId }, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.deleteId = "";
						$('#delete-discussion').modal('hide');
						this.router.navigate(['/project/' + this.pid + '/discussions'], { relativeTo: this.route });
						this.toastr.success(responseData.message);
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		} else if (type == 'thread') {
			this.restService.hitApi('discussion/threads/delete', { 'projectId': this.pid, 'projectThreadPostId': this.deleteId }, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.deleteId = "";
						$('#delete-discussion').modal('hide');
						this.toastr.success(responseData.message);
						this.getDiscussionThreads();
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}

	}

	closeDiscussion(status) {
		this.restService.hitApi('discussion/updateStatus', { 'projectId': this.pid, 'threadId': this.did, 'status': status }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getDiscussionThreads();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	removeAttachment(index) {
		this.files.splice(index, 1)
	}


	getProjectList() {
		this.restService.hitApi('discussion/projects', {}, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.projectListData = responseData.result.data;
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	changeProject(pid) {
		localStorage.setItem('projectSelected', pid)
		$('.project-title-list').toggle("slide");
		this.router.navigateByUrl('/project/' + pid + '/discussions');
	}

	emailRadioChange(isSelect: boolean) {
		this.usersArr = [];
		if (this.projectData.projectUsersProject) {
			this.projectData.projectUsersProject.forEach(element => {
				element.checked = isSelect;
				if (isSelect) {
					this.usersArr.push(element?.usersUser?.id);
				}

			});
		}
	}


	userSelect(event, user) {
		user.checked = event.checked;
		var allChecked;
		if (this.projectData.projectUsersProject) {
			console.log(this.projectData.projectUsersProject);
			this.projectData.projectUsersProject.forEach(element => {
				console.log("element -> ", element);
				allChecked = element.checked ? element.checked : false;

			});
			console.log("allChecked -> ", allChecked);
			if (allChecked) {
				this.selectAll = true;
				this.selectNone = false;
			} else {
				this.selectAll = false;
				this.selectNone = false;
			}
		}
		this.usersArr.push(user?.usersUser?.id);
		console.log("users", this.usersArr);
	}
	onCommentChange(value) {
		console.log('comment chane', value);
		if (this.threadAddForm.get('comment').valid) {
			this.isThreadAddFormSubmit = true;
		}
	}

	checkIsDeleteButtonShown(date) {
		var createdDate = new Date(date);
		createdDate.setHours(createdDate.getHours() + 5);
		createdDate.setMinutes(createdDate.getMinutes() + 30);

		var diff = new Date().getTime() - createdDate.getTime();
		console.log("diff", diff);

		var seconds = Math.floor((diff) / 1000);
		var minutes = Math.floor(seconds / 60);
		var hours = Math.floor(minutes / 60);
		var days = Math.floor(hours / 24);

		hours = hours - (days * 24);
		minutes = minutes - (days * 24 * 60) - (hours * 60);
		seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

		console.log("days", days);
		console.log("hours", hours);
		console.log("minutes", minutes);
		console.log("seconds", seconds);

		if (days > 0 || hours > 0 || minutes >= 15) {
			return false;

		} else {
			return true;

		}
	}
}

