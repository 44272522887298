import { append } from '@syncfusion/ej2-base';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
// import { LoaderService } from '../spinner.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TrakkLoaderComponent } from '../../trakk-loader/trakk-loader.component';
import { Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { variable } from 'node_modules1111/@angular/compiler/src/output/output_ast';
import { BroadcastService } from '../../services/broadcast/broadcast.service';

declare const $: any;
declare var require: any
const captchaSiteKey = "6LfBQKgaAAAAAIiEtrhpbFM3xDlt40FfVoP-ItPG"; //"6LeCQlUcAAAAAMRBrhLvKpNFk7EHVhKNpsvQpG5z"//"6LfBQKgaAAAAAIiEtrhpbFM3xDlt40FfVoP-ItPG";
const captchaSecretKey = "6LfBQKgaAAAAAJM-cGT2GCtZBekAWdWY2rzBdz9M";

const FileSaver = require('file-saver');
@Injectable()
export class RestService {

  //public apiURL: string = "http://api.qa.trakk.in/api/v1/";//DEV
  public apiURL: string = 'https://api.trakk.in/api/v1/'; //PROD

  constructor(
    private http: HttpClient,
    private spinnerService: TrakkLoaderComponent,
    private router: Router,
    private toastr: ToastrService,
    private broadcastService: BroadcastService
  ) {


  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }

  public getApiUrl() {
    return this.apiURL;
  }

  public getCaptchaSiteKey() {
    return captchaSiteKey;
  }

  public isSelectedProjectIsAgileType() {
    var type: any = 1;
    if (localStorage.getItem('projectType')) {
      //console.log(localStorage.getItem('projectType'));
      type = localStorage.getItem('projectType');
    }

    if (type == 1) {
      return true;
    } else {
      return false;
    }
  }

  public isAdmin(userData) {
    if (userData.role == 1) {
      return true;
    } else {
      return false;
    }
  }

  public isOwner(userData) {
    if (userData.ownerId && userData.ownerId == userData.userId) {
      return true;
    } else {
      return false;
    }
  }


  public isClient(userData) {
    if (userData.role == 3) {
      return true;
    } else {
      return false;
    }
  }

  public shortName(name) {
    if (name) {
      return name.split(" ")[0].charAt(0) + (name.split(" ")[1] ? name.split(" ")[1].charAt(0) : name.split(" ")[0].charAt(1));
    }

  }


  clearAllAndGoToLogin() {
    localStorage.removeItem('projectShortCode');
    localStorage.removeItem('isOwner');
    localStorage.removeItem('userData');
    localStorage.removeItem("projectData");
    localStorage.removeItem('companyData');
    localStorage.removeItem('company_id');
    localStorage.removeItem('company_name');
    localStorage.removeItem('projectSelected');
    localStorage.removeItem('sprintIdSelected');
    localStorage.removeItem('sprintNameSelected');
    localStorage.clear();

    $.ajax({
      url: "",
      context: document.body,
      success: function (s, x) {

        $('html[manifest=saveappoffline.appcache]').attr('content', '');
        $(this).html(s);
      }
    });

    console.log("clear data after login", localStorage);
    this.router.navigate(['/login']);
  }

  logoutAPI() {
    //this.spinnerService.show();
    this.broadcastService.boradcast("showLoading");
    this.hitApi('signOut', {}, 'delete', '', true)
      .subscribe((result) => {
        //this.spinnerService.hide();
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.clearAllAndGoToLogin();
          this.toastr.success(responseData.message);
        } else {
          this.toastr.error(responseData.message);
        }
      }, (err) => {
        // this.spinnerService.hide();
        this.broadcastService.boradcast("hideLoading");
        this.toastr.error(err.error.message);
      })
  }

  getCompanies() {
    this.hitApi('user/companies', {}, 'get', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          localStorage.setItem('companyData', JSON.stringify(responseData.result));
        } else {
          //this.errorProfileMessage = responseData.message;
        }
      }, (err) => {
        //this.errorProfileMessage = err.error.message;
      })
  }

  setCompany(id, isReload: boolean = false) {
    $('.launch-pad-title-list').toggle("slide");
    //this.spinnerService.show();
    let userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : '';

    //if (userData.companyId != id) {
    this.broadcastService.boradcast("showLoading");
    this.hitApi('user/updateCompany', { "cur_company_id": id }, 'post', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        // this.spinnerService.hide();
        this.broadcastService.boradcast("hideLoading");
        if (responseData.statusCode == 200) {
          localStorage.clear();
          localStorage.removeItem('projectSelected');
          localStorage.setItem('company_id', responseData.result.companyId);
          localStorage.setItem('company_name', responseData.result.companyName);
          localStorage.setItem('userData', JSON.stringify(responseData.result));

          console.log("company selection", responseData.result);

          if (responseData.result.id == responseData.result.ownerId) {
            localStorage.setItem('isOwner', "true");
          } else {
            localStorage.setItem('isOwner', "false");
          }

          console.log("app comp rest: ", localStorage.getItem('isOwner'))

          this.broadcastService.boradcast("FETCH_PROJECT_LIST");

          let userData = responseData.result
          let selectedProjectId = userData && userData.projectId ? userData.projectId : '';
          if (selectedProjectId != null && selectedProjectId != 'null' && selectedProjectId != '') {
            localStorage.setItem('projectSelected', selectedProjectId);
          }

          //this.broadcastService.boradcast("SET_SELECTED_PROJECT_ID_TO_NULL");

          this.broadcastService.boradcast("SWITCH_COMPANY", responseData.result.companyId);
          this.broadcastService.boradcast("FATCH_USER_DATA_GO_TO_DASHBOARD", localStorage.getItem('userData'));

          //localStorage.setItem('ownerId', responseData.result.ownerId);
          // this.getCompanies();
          //this.router.navigateByUrl('/dashboard');
          // if(responseData.result.role==1){
          //   this.router.navigateByUrl('/user-management');
          // }else{
          //   this.router.navigateByUrl('/discussion-board');
          // }

          if (isReload) {
            setTimeout(() => {
              window.location.reload();
            }, 100);
          }

          this.toastr.success(responseData.message);
        } else {
          // this.toastr.error(responseData.message);
        }
      }, (err) => {
        // this.spinnerService.hide();
        this.broadcastService.boradcast("hideLoading");
        this.toastr.error(err.error.message);
      })
    //}

  }

  getCsvFile(url, data, loader: boolean = true) {
    if (loader) {
      //this.spinnerService.show();
      this.broadcastService.boradcast("showLoading");
    }
    //this.apiURL = 'http://api.dev.trakk.in/api/v1/'; //DEV
    //this.apiURL = 'http://api.qa.trakk.in/api/v1/'; //QA
    this.apiURL = 'https://api.trakk.in/api/v1/'; //PROD
    let accessToken = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).accessToken : '';
    let companyId = localStorage.getItem('company_id') ? localStorage.getItem('company_id') : '';
    let projectId = localStorage.getItem('projectSelected') ? localStorage.getItem('projectSelected') : null;
    let headers: any
    if (projectId) {
      headers = new HttpHeaders({ 'deviceType': 'WEB2', 'accessToken': accessToken, 'companyId': companyId, 'projectId': projectId, 'Accept': 'application/json' });

    } else {
      headers = new HttpHeaders({ 'deviceType': 'WEB2', 'accessToken': accessToken, 'companyId': companyId, 'Accept': 'application/json' });

    }
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Methods", "GET, POST, DELETE, PUT");

    this.http.post(this.apiURL + url, data, { headers: headers, responseType: 'text' }).subscribe((result => {
      //this.spinnerService.hide();
      this.broadcastService.boradcast("hideLoading");
      var blob = new Blob([result], { type: 'text/csv' });
      var url = window.URL.createObjectURL(blob);

      this.downloadFile(url, 'exported_filter_issues.csv')
      console.log("result", result);
    }));
  }





  public hitApi(url, data, method, type = '', loader: boolean = true, image: boolean = false) {

    var lisOnline = true
    let noInternet = "No internet connection make sure wifi or mobile data is turned on, then try again"
    this.createOnline$().subscribe(isOnline => {
      if (isOnline) {
        lisOnline = true
      } else {
        lisOnline = false
        this.toastr.error(noInternet);
        return false
      }
    });

    if (lisOnline) {
      if (loader) {
        console.log("loader: ", loader);
        //this.spinnerService.show();
        this.broadcastService.boradcast("showLoading");
      }
      // http://dev.trakk.in/

      //this.apiURL = 'http://api.dev.trakk.in/api/v1/'; //DEV
      //this.apiURL = 'http://api.qa.trakk.in/api/v1/'; //QA
      this.apiURL = 'https://api.trakk.in/api/v1/'; //PROD


      let accessToken = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).accessToken : '';
      let companyId = localStorage.getItem('company_id') ? localStorage.getItem('company_id') : '';
      console.log("comapnyId = " + localStorage.getItem('company_id'));
      let selectedProject = localStorage.getItem('projectSelected');
      console.log("selectedProject = " + selectedProject);
      let projectId = localStorage.getItem('projectSelected') ? localStorage.getItem('projectSelected') : null;

      console.log("projectId==" + projectId);


      let headers: any

      if (image) {
        if (projectId) {
          headers = new HttpHeaders({ 'deviceType': 'WEB1', 'accessToken': accessToken, 'companyId': companyId, 'projectId': projectId, 'Content-Type': 'multipart/form-data' });

        } else {
          headers = new HttpHeaders({ 'deviceType': 'WEB1', 'accessToken': accessToken, 'companyId': companyId, 'Content-Type': 'multipart/form-data' });

        }
      }
      // else if (data.export == 1) {
      //   headers = new HttpHeaders({ 'deviceType': 'WEB1', 'accessToken': accessToken, 'companyId': companyId, 'Content-Type': 'text' });

      // }
      else {
        if (projectId != null && projectId != 'null' && projectId != '') {
          headers = new HttpHeaders({ 'deviceType': 'WEB2', 'accessToken': accessToken, 'companyId': companyId, 'projectId': projectId, 'Accept': 'application/json' });

        } else {
          headers = new HttpHeaders({ 'deviceType': 'WEB2', 'accessToken': accessToken, 'companyId': companyId, 'Accept': 'application/json' });

        }
      }

      console.log("headers==", headers);



      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Methods", "GET, POST, DELETE, PUT");
      if (method == 'get') {
        return this.http.get<any>(this.apiURL + url, { headers: headers, params: data })

          .pipe(
            map((result) => {
              if (loader) {
                //setTimeout(() => {
                // this.spinnerService.hide();
                this.broadcastService.boradcast("hideLoading");
                // }, 200);
              }

              let responseData: any = result;
              if (responseData.statusCode == 422) {
                this.router.navigate(['common-error'], { queryParams: { statusCode: 422 } });
                return false;
              } else if (responseData.statusCode == 401 || responseData.statusCode == 404) {
                this.router.navigate(['common-error'], { queryParams: { statusCode: 404 } });
                return false;
              } else if (responseData.statusCode == 203) {

                let navigationExtras: NavigationExtras = {
                  queryParams: { responseData }
                };

                this.router.navigate(['/project-company-switch'], { state: { data: navigationExtras } });
                return false;
              } else if (responseData.statusCode == 400) {
                this.toastr.error(responseData.message);
              }
              else if (responseData.statusCode == 402) {
                //setTimeout(() => {
                this.router.navigate(['plan-expire']);
                this.broadcastService.boradcast("PLAN_EXPIRED");
                // }, 500);
                return false;
              }

              // alert("i am at: " + responseData.statusCode + " " + this.apiURL + url)

              return result;
            }),
            catchError(err => {
              if (loader) {
                // this.spinnerService.hide();
                this.broadcastService.boradcast("hideLoading");
              }
              if (err.status == 202) {
                this.router.navigate(['/']);
              } else if (err.status == 401) {
                this.toastr.error(err.error.message);
                this.logoutAPI();
                this.clearAllAndGoToLogin();
              } else if (err.status == 500) {
                this.toastr.error("Server Error");
              } else if (err.status == 400) {
                this.toastr.error(err.error.message);
              } else if (err.status == 422) {
                this.router.navigate(['common-error'], { queryParams: { statusCode: 422 } });
              } else if (err.status == 401 || err.status == 404) {
                this.router.navigate(['common-error'], { queryParams: { statusCode: 404 } });
              } else if (err.status == 402) {
                //alert("i am at 402 catchError")
                this.router.navigate(['plan-expire']);

                //this.toastr.error(err.error.message);
              }

              // alert("i am at: " + err.status + " " + this.apiURL + url)

              throw 'error in source. Details: ' + JSON.stringify(err);
            }),
          )
      }
      else if (method == 'post') {
        return this.http.post(this.apiURL + url, data, { headers: headers }).pipe(
          map(result => {
            if (loader) {
              console.log("loader: ", false);
              //setTimeout(() => {
              //this.spinnerService.hide();
              this.broadcastService.boradcast("hideLoading");
              //}, 200);
            }
            let responseData: any = result;
            if (responseData.statusCode == 422) {
              this.router.navigate(['common-error'], { queryParams: { statusCode: 422 } });
              return false;
            } else if (responseData.statusCode == 401 || responseData.statusCode == 404) {
              this.router.navigate(['common-error'], { queryParams: { statusCode: 404 } });
              return false;
            } else if (responseData.statusCode == 400) {
              this.toastr.error(responseData.message);
            } else if (responseData.statusCode == 203) {
              let navigationExtras: NavigationExtras = {
                queryParams: { responseData }
              };

              this.router.navigate(['/project-company-switch'], { state: { data: navigationExtras } });
              return false;
            } else if (responseData.statusCode == 402) {
              this.router.navigate(['plan-expire']);
              this.broadcastService.boradcast("PLAN_EXPIRED");
              return false;
            }

            return result;
          }),
          catchError(err => {
            if (loader) {
              // this.spinnerService.hide();
              this.broadcastService.boradcast("hideLoading");
            }

            if (err.status == 202) {
              this.router.navigate(['/']);
            } else if (err.status == 401) {
              this.toastr.error(err.error.message);
              this.logoutAPI();
              this.clearAllAndGoToLogin();
            } else if (err.status == 500) {
              this.toastr.error("Server Error");
            } else if (err.status == 400) {
              this.toastr.error(err.error.message);
            } else if (err.status == 422) {
              this.router.navigate(['common-error'], { queryParams: { statusCode: 422 } });
            } else if (err.status == 401 || err.status == 404) {
              this.router.navigate(['common-error'], { queryParams: { statusCode: 404 } });
            } else if (err.status == 402) {
              this.router.navigate(['plan-expire']);
            }

            //this.spinnerService.hide();
            if (err.error.text) {
              return err.error.text;
            } else {
              throw err;
            }

            //throw 'error in source. Details: ' + JSON.stringify(err);

          }),
        )
        // this.loaderService.displayLoader(false);
      } else if (method == 'put') {
        return this.http.put(this.apiURL + url, data, { headers: headers }).pipe(
          map(result => {
            if (loader) {
              console.log("loader: ", false);
              //setTimeout(() => {
              //this.spinnerService.hide();
              this.broadcastService.boradcast("hideLoading");
              //}, 200);
            }
            let responseData: any = result;
            if (responseData.statusCode == 422) {
              this.router.navigate(['common-error'], { queryParams: { statusCode: 422 } });
              return false;
            } else if (responseData.statusCode == 401 || responseData.statusCode == 404) {
              this.router.navigate(['common-error'], { queryParams: { statusCode: 404 } });
              return false;
            } else if (responseData.statusCode == 400) {
              this.toastr.error(responseData.message);
            } else if (responseData.statusCode == 203) {
              let navigationExtras: NavigationExtras = {
                queryParams: { responseData }
              };

              this.router.navigate(['/project-company-switch'], { state: { data: navigationExtras } });
              return false;
            } else if (responseData.statusCode == 402) {
              this.router.navigate(['plan-expire']);
              this.broadcastService.boradcast("PLAN_EXPIRED");
              return false;
            }

            return result;
          }),
          catchError(err => {
            if (loader) {
              // this.spinnerService.hide();
              this.broadcastService.boradcast("hideLoading");
            }

            if (err.status == 202) {
              this.router.navigate(['/']);
            } else if (err.status == 401) {
              this.toastr.error(err.error.message);
              this.logoutAPI();
              this.clearAllAndGoToLogin();
            } else if (err.status == 500) {
              this.toastr.error("Server Error");
            } else if (err.status == 400) {
              this.toastr.error(err.error.message);
            } else if (err.status == 422) {
              this.router.navigate(['common-error'], { queryParams: { statusCode: 422 } });
            } else if (err.status == 401 || err.status == 404) {
              this.router.navigate(['common-error'], { queryParams: { statusCode: 404 } });
            } else if (err.status == 402) {
              this.router.navigate(['plan-expire']);
            }

            //this.spinnerService.hide();
            if (err.error.text) {
              return err.error.text;
            } else {
              throw err;
            }

            //throw 'error in source. Details: ' + JSON.stringify(err);

          }),
        )
        // this.loaderService.displayLoader(false);
      }
      else if (method == 'delete') {
        return this.http.delete(this.apiURL + url, { headers: headers, params: data }).pipe(
          map(result => {
            if (loader) {
              console.log("loader: ", false);
              //this.spinnerService.hide();
              this.broadcastService.boradcast("hideLoading");
            }

            let responseData: any = result;
            if (responseData.statusCode == 422) {
              this.router.navigate(['common-error'], { queryParams: { statusCode: 422 } });
              return false;
            } else if (responseData.statusCode == 401 || responseData.statusCode == 404) {
              this.router.navigate(['common-error'], { queryParams: { statusCode: 404 } });
              return false;
            } else if (responseData.statusCode == 400) {
              this.toastr.error(responseData.message);
            } else if (responseData.statusCode == 203) {
              let navigationExtras: NavigationExtras = {
                queryParams: { responseData }
              };

              this.router.navigate(['/project-company-switch'], { state: { data: navigationExtras } });
              return false;
            } else if (responseData.statusCode == 402) {
              this.router.navigate(['plan-expire']);
              this.broadcastService.boradcast("PLAN_EXPIRED");
              return false;
            }

            return result;
          }),
          catchError(err => {
            if (loader) {
              if (err.status == 202) {
                this.router.navigate(['/']);
              } else if (err.status == 401) {
                this.toastr.error(err.error.message);
                this.logoutAPI();
                this.clearAllAndGoToLogin();
              } else if (err.status == 500) {
                this.toastr.error("Server Error");
              } else if (err.status == 400) {
                this.toastr.error(err.error.message);
              } else if (err.status == 422) {
                this.router.navigate(['common-error'], { queryParams: { statusCode: 422 } });
              } else if (err.status == 401 || err.status == 404) {
                this.router.navigate(['common-error'], { queryParams: { statusCode: 404 } });
              }

              //this.spinnerService.hide();
              this.broadcastService.boradcast("hideLoading");
            }
            throw err;
            //throw 'error in source. Details: ' + JSON.stringify(err);
          }),
        )
      }

    } else {
      this.toastr.error(noInternet);
    }

  }

  public downloadFile(url: string, fileName: string) {
    FileSaver.saveAs(url, fileName);
  }

  public extractFirstLetters(name) {
    let firstLetters = null;
    if (name != null) {
      let firstName = name.split(' ')[0];
      let lastName = name.split(' ')[1];

      if (firstName && lastName) {
        firstLetters = firstName.slice(0, 1) + lastName.slice(0, 1);
      } else {
        if (firstName) {
          firstLetters = firstName.slice(0, 2)
        } else if (lastName) {
          firstLetters = lastName.slice(0, 2)

        }
      }
    }

    return firstLetters;
  }

  // This code for show error when file size is greater than given max size
  checkFileSize(file, maxSize) {
    let fileSizeInBytes = file.size
    // Convert the bytes to Kilobytes (1 KB = 1024 Bytes)
    let fileSizeInKB = fileSizeInBytes / 1024;
    // Convert the KB to MegaBytes (1 MB = 1024 KBytes)
    let fileSizeInMB = fileSizeInKB / 1024;

    if (fileSizeInMB > maxSize) {
      this.toastr.error("File too Big, please select a file less than " + maxSize + 'mb');
      return false
    } else {
      return true;
    }

  }
  setIsSelectedProjectIsSame(shortCode) {
    var selectedShortCode = localStorage.getItem("projectShortCode");
    if (shortCode == selectedShortCode) {
      localStorage.setItem("projectIsSame", "true");
    } else {
      localStorage.setItem("projectIsSame", "false");
    }

  }
  getIsSelectedProjectIsSame() {
    if (localStorage.getItem("projectIsSame") == "true") {
      return true
    } else {
      return false
    }
  }

  convertedStorage(totalStorage: any) {
    if (totalStorage < 1024) {
      return totalStorage + ' ' + 'BYTE';
    } else if (totalStorage >= 1024 && totalStorage < 1048576) {
      return (totalStorage / (1024)).toFixed(2) + ' ' + 'KB';
    }
    else if (totalStorage >= 1048576 && totalStorage < 1073741824) {
      return (totalStorage / (1024 * 1024)).toFixed(2) + ' ' + 'MB';
    }
    else if (totalStorage >= 1073741824) {
      return (totalStorage / (1024 * 1024 * 1024)).toFixed(2) + ' ' + 'GB';
    }
    /*
    var convertedStorage: string;
    var gb = (totalStorage / 1024 / 1024 / 1024).toFixed(2);
    var mb = (totalStorage / 1024 / 1024).toFixed(2);
    var kb = (totalStorage / 1024).toFixed(2);
    if (parseInt(gb) > 0.0) {
      convertedStorage = gb + ' ' + 'GB'
    } else if (parseInt(mb) > 0.0) {
      convertedStorage = mb + ' ' + 'MB'
    } else if (parseInt(kb) > 0.0) {
      convertedStorage = kb + ' ' + 'KB'
    } else {
      convertedStorage = totalStorage + ' ' + 'MB'
    }
    return convertedStorage;
    */
  }
  // public hitApi(url,data,method,type='',loader : boolean = true,image:boolean = false,contenttype:any=''){

  //   // if(loader){
  //     this.spinnerService.show();
  //   // }
  //   // this.apiURL = 'http://dev.uchoosecars.com/uchoose-php/public/api/v1/'; //DEV SERVER
  //   // this.apiURL = 'http://staging.uchoosecars.com/uchoose-php/public/api/v1/'; //Staging SERVER
  //   this.apiURL = 'https://www.uchoosecars.com/uchoose-php/public/api/v1/'; //PROD SERVER
  //   // console.log(JSON.parse(localStorage.getItem('uchoosecarUserData')));
  //   let accessToken = localStorage.getItem('uchoosecarUserData')?JSON.parse(localStorage.getItem('uchoosecarUserData')).accessToken:'';


  //   let headers : any
  //   if(image){
  //     headers = new HttpHeaders({'deviceType': 'WEB','accessoken':accessToken,'Content-Type':'multipart/form-data'});
  //   }else{
  //     headers = new HttpHeaders({'deviceType': 'WEB','accessToken':accessToken,'Accept' : 'application/json'});
  //   }
  //   if(contenttype=='json'){
  //     headers = new HttpHeaders({'deviceType': 'WEB','accessoken':accessToken,'Content-Type':'application/x-www-form-urlencoded'});
  //   }
  //   headers.append("Access-Control-Allow-Origin", "*");
  //   headers.append("Access-Control-Allow-Methods", "GET, POST, DELETE, PUT");
  //   if(method=='get'){
  //     return this.http.get<any>(this.apiURL + url, { headers: headers, params:data })

  //     .pipe(
  //       map((result) => {
  //         if(loader){
  //           this.spinnerService.hide();
  //         }
  //         return result;
  //       }),
  //       catchError(err => {
  //         if(loader){
  //           this.spinnerService.hide();
  //         }
  //         throw 'error in source. Details: ' + JSON.stringify(err);
  //       }),
  //     )
  //   }
  //   else if(method=='post'){
  //     return this.http.post(this.apiURL + url,data,{ headers: headers }).pipe(
  //       map(result => {
  //         if(loader){
  //           this.spinnerService.hide();
  //         }
  //         return result;
  //       }),
  //       catchError(err => {
  //         if(loader){
  //           this.spinnerService.hide();
  //         }
  //         throw err;
  //       }),
  //     )
  //     // this.loaderService.displayLoader(false);
  //   }
  //   else if(method=='delete'){
  //     return this.http.delete(this.apiURL + url,{ headers: headers, params:data }).pipe(
  //       map(result => {
  //         return result;
  //       }),
  //       catchError(err => {
  //         throw 'error in source. Details: ' + JSON.stringify(err);
  //       }),
  //     )
  //   }



  // }
}
