import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'specialPipe'
})
export class specialPipe implements PipeTransform {

  transform(value: string): string {
    let newVal = value.replace(/&amp;nbsp;/g," ")
    return newVal.charAt(1).toUpperCase() + newVal.slice(2);
  }

}