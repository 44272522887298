import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { ValidationService } from '../services/config/config.service';
// import { AuthService, GoogleLoginProvider} from 'angular-6-social-login';
import { RestService } from '../services/rest/rest.service';

import { ToastrService } from 'ngx-toastr';
declare const $: any;

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
	changePasswordForm: FormGroup;
	userData : any = JSON.parse(localStorage.getItem("userData"));

	companiesData : any;
	cur_company_id : any;
	isPasswordChanged : boolean = false;
	oldPasswordInputType: any = 'password';
	newPasswordInputType: any = 'password';
	confirmPasswordInputType: any = 'password';

	constructor(
		private formBuilder: FormBuilder, 
		private router: Router, 
		private route: ActivatedRoute, 
		private restService: RestService,
		private toastr: ToastrService) {
		this.changePasswordForm = this.formBuilder.group({
			'oldPassword': ['', [Validators.required,ValidationService.passwordValidator]], //Validators.minLength(7)
			'newPassword': ['', [Validators.required,ValidationService.passwordValidator]],
			'confirmPassword': ['', [Validators.required,ValidationService.passwordValidator]]
		});

		this.isPasswordChanged = false;

	}

	// Check if user already logged in
	ngOnInit() {
		// this.restService.getCompanies();
		this.isPasswordChanged = false;
		// this.companiesData = JSON.parse(localStorage.getItem('companyData'));
		// this.cur_company_id = localStorage.getItem('company_id');

		// $(document).ready(function () {
		// 	$(".toggle-password").click(function () {

		// 		$(this).toggleClass("fa-eye fa-eye-slash");
		// 		var input = $($(this).attr("toggle"));
		// 		if (input.attr("type") == "password") {
		// 			input.attr("type", "text");
		// 		} else {
		// 			input.attr("type", "password");
		// 		}
		// 	});
		// });
	}

	refreshAgain() {
		this.isPasswordChanged = false;
		this.changePasswordForm = this.formBuilder.group({
			'oldPassword': ['', [Validators.required,ValidationService.passwordValidator]], //Validators.minLength(7)
			'newPassword': ['', [Validators.required,ValidationService.passwordValidator]],
			'confirmPassword': ['', [Validators.required,ValidationService.passwordValidator]]
		});
	}

	doChangePassword(){
		if(this.changePasswordForm.value.newPassword!=this.changePasswordForm.value.confirmPassword){
			this.toastr.error("New Password and confirm password must match!");
		}else{
			this.restService.hitApi('changePassword',this.changePasswordForm.value,'post','',true)
			.subscribe((result)=>{
				let responseData : any = result;
				if(responseData.statusCode==200){
					this.isPasswordChanged = true;
					this.changePasswordForm.reset();
					this.toastr.success(responseData.message);
					//this.router.navigate(['/user-profile']);
				}else{
					this.toastr.error(responseData.message);
					this.isPasswordChanged = false;
				}
			},(err)=>{
				this.isPasswordChanged = false;
				this.toastr.error(err.error.message);
			})
		}
	}

    eye(event) {
		event.stopPropagation();
	}

	toggle_eyes(inputType) {
		if (inputType == 1) {
			if (this.oldPasswordInputType == 'password') {
				this.oldPasswordInputType = 'text'
			} else {
				this.oldPasswordInputType = 'password'
			}
		} else if (inputType == 2) {
			if (this.newPasswordInputType == 'password') {
				this.newPasswordInputType = 'text'
			} else {
				this.newPasswordInputType = 'password'
			}
		} else if (inputType == 3) {
			if (this.confirmPasswordInputType == 'password') {
				this.confirmPasswordInputType = 'text'
			} else {
				this.confirmPasswordInputType = 'password'
			}
		}
	}

	

}
