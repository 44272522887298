/**
 * Created By : Sangwin Gawande (http://sangw.in)
 */

import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { ValidationService } from '../services/config/config.service';
// import { AuthService, GoogleLoginProvider} from 'angular-6-social-login';
import { RestService } from '../services/rest/rest.service';

import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'angular2-cookie/services/cookies.service';
declare const $: any;
@Component({
	selector: 'app-landing',
	templateUrl: './landing.component.html',
	styleUrls: ['./landing.component.scss'],
	providers: [CookieService]
})
export class LandingComponent implements OnInit {
	loginForm: FormGroup;
	errorMessage: string = "";
	subscriptionData: any;
	freePlanData: any;
	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		// private userService: UserService, 
		private restService: RestService,
		private toastr: ToastrService,
		// private socialAuthService: AuthService,
		private _cookieService: CookieService) {

	}

	// Check if user already logged in
	ngOnInit() {

		if (localStorage.getItem('userData')) {
			let userData = JSON.parse(localStorage.getItem('userData'))
			// userData.role==1?this.router.navigate(['/user-management']):this.router.navigate(['/discussion-board']);

			if (userData.planStatus == true) {
				//$('#pay-alert').modal('show');
				this.toastr.error("Your plan is not activated ! Upgrade the plan to continue using the Trakk.in");
			} else {
				this.router.navigate(['/dashboard'])
			}

		}

		//this.getSubscriptions();

		/*$('document').ready(function () {  
		  $(window).scroll(function() {
			  if ($(this).scrollTop() > 1){
			  $('header').addClass("sticky");
			  }
			  else{
			  $('header').removeClass("sticky");
			  }
		  });
		});*/

		this.getFreePlan();
	}

	getFreePlan() {
		this.restService.hitApi('users/freePackageDetail', null, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.freePlanData = responseData.result;
					console.log("this.freePlanData: ", this.freePlanData)
				} else {
					// this.toastr.error(responseData.replyMessage);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	getSubscriptions() {
		console.log($('#subscription_term').is(':checked'));
		let frequency = $('#subscription_term').is(':checked') ? 1 : 2;
		this.restService.hitApi('users/subscription', { 'frequency': frequency }, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.subscriptionData = responseData.result;
				} else {
					// this.toastr.error(responseData.replyMessage);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	saveSubscription(packageId) {
		localStorage.setItem("packageId", packageId);
		this.router.navigate(['register']);
	}

	tryFree() {
		if (this.freePlanData.status == 1) {
			// let pack = {
			// 	"package": "free", 
			// 	"minStorage": this.freePlanData.maxData, 
			// 	"minUsers": this.freePlanData.noOfUsers
			//    }

			// this.router.navigate(['/register'], { queryParams: { 'pack': JSON.stringify(pack) } });

			// let navigationExtras: NavigationExtras = {
			// 	queryParams: {
			// 		pack: pack
			// 	}
			// };
			//this.router.navigate(['/register/'], navigationExtras);
			this.router.navigateByUrl('/register', { state: { package: "free", minStorage: this.freePlanData.maxData, minUsers: this.freePlanData.noOfUsers } });

		} else {
			this.toastr.error("Sorry, free trail not available, please try to pay and use Trakk.in");
		}

		// localStorage.setItem("packageId",'11');
		// this.router.navigate(['register']);
		// console.log(this.subscriptionData);
		// this.subscriptionData.forEach(sub => {
		// 	// console.log(sub);
		// 	if(sub.price==0){
		// 		console.log(sub);
		// 		localStorage.setItem("package",sub.id);
		// 	}
		// });
	}

	onChangeImageSlider(event) {
		$('.active').removeClass('active').css('z-index', -10);
		$('#img' + event).addClass('active').css('z-index', 10);
		$('#text' + event).addClass('active').css('z-index', 10);
	}

	goToPayment() {
		$('#pay-alert').modal('hide');
		this.router.navigate(['outer-pricing']);
	}

	closeModal() {

	}
}
