import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { trigger, state, animate, style, transition } from '@angular/animations';


export class ValidationService {

	static emailValidator(control) {
		if (control.value) {
			if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
				return null;
			} else {
				return { 'invalidEmailAddress': true };
			}
		}
	}

	static mobileValidator(control) {
		if (control.value) {
			if (control.value.match("^[0-9]{10}$")) {
				return null;
			} else {
				return { 'invalidMobileNumber': true };
			}
		}
	}

	static passwordValidator(control) {
		// {6,100}           - Assert password is between 6 and 100 characters
		// (?=.*[0-9])       - Assert a string has at least one number
		///^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,50}$/
		// if (control.value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,50}$/)) {
		// 	return null;
		// } else {
		// 	return { 'invalidPassword': true };
		// }

		if (control.value.length > 5) {
			return null;
		} else {
			return { 'invalidPassword': true };
		}
	}

	static checkLimit(min: number, max: number): ValidatorFn {
		return (c: AbstractControl): { [key: string]: boolean } | null => {
			if (c.value && (isNaN(c.value) || c.value < min || c.value > max)) {
				return { 'range': true };
			}
			return null;
		};
	}
	static greaterThanZero(control) {
		if (control != null && (control.value != null && control.value != '')) {
			if (control.value.match('^[1-9]+[0-9]*$')) {
				return null;
			} else {
				return { 'lessThanZero': true };
			}
		} else {
			return null;
		}


	}
}



export function routerTransition() {
	return slideToLeft();
}

export function slideToLeft() {
	return trigger('routerTransition', [
		transition(':enter', [
			style({ transform: 'translateX(100%)', position: 'fixed', width: '100%' }),
			animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
		]),
		transition(':leave', [
			style({ transform: 'translateX(0%)', position: 'fixed', width: '100%' }),
			animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' }))
		])
	]);
}
