import { Component, OnInit, OnDestroy } from '@angular/core';
import { RestService } from '../services/rest/rest.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DateConverterService } from '../services/date-formatter/date-converter.service';
import { PageEvent } from '@angular/material/paginator';
import * as Highcharts from 'highcharts';
import { BroadcastService } from '../services/broadcast/broadcast.service';
import { PreservedSearchStateService } from '../services/search/preserved-search-state.service';





declare const $: any;


@Component({
  selector: 'app-dashboard-company-user',
  templateUrl: './dashboard-company-user.component.html',
  styleUrls: ['./dashboard-company-user.component.scss']
})
export class DashboardCompanyUserComponent implements OnInit, OnDestroy {

  sprintList: any;
  issueStatisticData: any;
  issueStatisticAssigneeByData: any;
  issueStatisticAssigneeByList: any = [];
  issueStatisticsByEntityData: any;
  totalIssueStatisticAssigneeByData = 0;
  watchedIssueData: any;
  watchedIssueList: any = [];
  assignedMeData: any;
  assignedMeList: any = [];
  activityStreamData: any;
  activityStreamList: any = [];

  sprintName: string = '--Select Sprint--';
  sprintId: any;
  typeSelected: any = 0;
  selectedSprint: any;
  typeSelectedText: any = '--Type--';
  watchedStatus: any = 0;
  assignToMeStatus: any = 0;
  activityStreamPage: any = 1;
  assignToMePage: any = 1;
  watchListPage: any = 1;
  watchedConfig: any = { currentPage: 1, itemsPerPage: 10, totalItems: 0 };
  isAssignMeListFetch: boolean = false;
  isWatchMeListFetch: boolean = false;
  isActivityListFetch: boolean = false;
  isDashboardDataFetch: boolean = false;

  issueStatisticAssigneeData: any;
  totalIssueStatisticAssigneeData = 0;



  // activityConfig : any = {currentPage:1,itemsPerPage: 10,totalItems:0};
  userDashboard: any;
  chart: any = {
    type: 'PieChart',
  }
  chartdata1 = []
  chartdata2 = []
  chartdata3 = []
  chartdata4 = []

  userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : '';
  isLoadMoreActivity = false;
  isLoadMoreAssignToMe = false;
  isLoadMoreWatchList = false;
  entityTypeArray = [{ name: 'Test Plan', value: 'testPlan' }, { name: 'Bug', value: 'defect' }, { name: 'Task', value: 'task' },
  { name: 'User Story', value: 'userStory' }, { name: 'User Story Sub Task', value: 'userStorySubTask' }];
  activityStreamEntityTypeArray = [{ name: 'Release', value: 'Release' }, { name: 'Sprint', value: 'Sprint' }, { name: 'Test Plan', value: 'TestPlan' }, { name: 'Test Case', value: 'TestCase' }, { name: 'Bug', value: 'Defect' }, { name: 'Task', value: 'Task' },
  { name: 'User Story', value: 'UserStory' }, { name: 'User Story Task', value: 'UserStoryTask' }];
  entityTypeFilterArrayForWatchList: String[];
  entityTypeFilterArrayForAssignToMeList: String[];
  entityTypeFilterArrayForActivityStreamList: String[];
  userList: any;
  typeList = [{ name: 'Internal', value: 2 }, { name: 'External', value: 1 }];
  dashboardData: any;
  userInfo: any;
  isFilterData: boolean;

  assignedMePageEvent: PageEvent;
  assignedMePageIndex: number = 0;
  assignedMePageSize: number = 10;
  assignedMeLength: number = 0;

  assigneeByPageEvent: PageEvent;
  assigneeByPageIndex: number = 0;
  assigneeByPageSize: number = 10;
  assigneeByLength: number = 0;

  watchedListPageEvent: PageEvent;
  watchedListPageIndex: number = 0;
  watchedListPageSize: number = 10;
  watchedListLength: number = 0;
  issueStatisticsByAssigneeData: any;

  issueStatisticAssigneePage = 1;
  issueStatisticAssigneeSize = 5;
  isAssignByListRefresh: boolean;
  isAssignMeListRefresh: boolean;
  isIssueStatisticsByAssigneeListRefresh: boolean;
  isIssueStatisticsListRefresh: boolean;
  isIssueStatisticsAssigneeListRefresh: boolean;
  isIssueStatisticsByEntityListRefresh: boolean;
  isWatchedListRefresh: boolean;
  isIssueStatisticsByAssigneeListLoading: boolean = false;
  isIssueStatisticsListLoading: boolean;
  isIssueStatisticsAssigneeListLoading: boolean;
  isIssueStatisticsByEntityListLoading: boolean;
  isAssignMeListLoading: boolean;
  isWatchedListLoading: boolean;
  selectedProjectId: string;
  userStories: any = [];
  loggedHrs: any = [];
  actualHrs: any = [];
  burDownChartOptions;
  highcharts = Highcharts;
  projectType: any;
  isAllApiCalled: boolean = false;

  chartOptions = {
    chart: {
      type: "burndown"
    },
    title: {
      text: 'Burndown Chart',
      x: -20 //center
    },
    colors: ['blue', 'red'],
    plotOptions: {
      line: {
        lineWidth: 3
      },
      tooltip: {
        hideDelay: 200
      }
    },
    subtitle: {
      text: 'Sprint 1',
      x: -20
    },
    xAxis: {
      categories: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6',
        'Day 7', 'Day 8', 'Day 9', 'Day 10']
    },
    yAxis: {
      title: {
        text: 'Hours'
      },
      plotLines: [{
        value: 0,
        width: 1
      }]
    },
    tooltip: {
      valueSuffix: ' hrs',
      crosshairs: true,
      shared: true
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      borderWidth: 0
    },
    series: [{
      name: 'Ideal Burn',
      color: 'rgba(255,0,0,0.25)',
      lineWidth: 2,
      data: [100, 90, 80, 70, 60, 50, 40, 30, 20, 10]
    }, {
      name: 'Actual Burn',
      color: 'rgba(0,120,200,0.75)',
      marker: {
        radius: 6
      },
      data: [100, 110, 85, 60, 60, 30, 32, 23, 9, 2]
    }]
  };

  constructor(
    public restService: RestService, private toastr: ToastrService, private router: Router, public dateConverterService: DateConverterService, private broadcastService: BroadcastService,
    private preserveSearch: PreservedSearchStateService) {
    console.log("userData for company user", this.userData);
    this.selectedProjectId = localStorage.getItem('projectSelected');
    this.projectType = parseInt(localStorage.getItem('projectType'));

    this.broadcastService.subscribe("PROJECT_ID_GET", (pid) => {
      if (pid != null && pid != undefined && pid != '' && pid != 'null') {
        this.selectedProjectId = pid;
        //this method call bcause first time api does not call if i comment out this method
        if (!this.isAllApiCalled) {
          this.getAll()
        }

      }
    });

  }

  getAll() {
    var localUserData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : '';
    console.log("localUserData", localUserData);

    if (localUserData.role == 2) {
      this.getIssueStatisticsByAssignee(this.assigneeByPageEvent);
      this.issueStatistics();
      this.issueStatisticsAssignee();
      this.issueStatisticsByEntity();
      this.assignedMe(this.assignedMePageEvent);
      this.watchedIssue(this.watchedListPageEvent);
      this.getUserList();
      this.getSprints();
      this.isAllApiCalled = true;
    }
  }

  ngOnInit() {

    this.recoverLastSearchValue();
    $(function () {
      $("#bars li .bar").each(function (key, bar) {
        var percentage = $(this).data('percentage');

        $(this).animate({
          'height': percentage + '%'
        }, 1000);
      });
    });

    if (this.assignedMePageEvent == null) {
      this.assignedMePageEvent = new PageEvent();
      this.assignedMePageEvent.length = this.assignedMeLength;
      this.assignedMePageEvent.pageIndex = this.assignedMePageIndex;
      this.assignedMePageEvent.pageSize = this.assignedMePageSize;
    }

    if (this.assigneeByPageEvent == null) {
      this.assigneeByPageEvent = new PageEvent();
      this.assigneeByPageEvent.length = this.assigneeByLength;
      this.assigneeByPageEvent.pageIndex = this.assigneeByPageIndex;
      this.assigneeByPageEvent.pageSize = this.assigneeByPageSize;
    }

    if (this.watchedListPageEvent == null) {
      this.watchedListPageEvent = new PageEvent();
      this.watchedListPageEvent.length = this.watchedListLength;
      this.watchedListPageEvent.pageIndex = this.watchedListPageIndex;
      this.watchedListPageEvent.pageSize = this.watchedListPageSize;
    }

    this.getUserprofile();


    if (this.selectedProjectId != null && this.selectedProjectId != undefined && this.selectedProjectId != '' && this.selectedProjectId != 'null' && !this.isAllApiCalled) {
      this.getAll()
      this.getSprints();
    }

    // $(function () {
    //   $('#container').highcharts({
    //     title: {
    //       text: 'Burndown Chart',
    //       x: -20 //center
    //     },
    //     colors: ['blue', 'red'],
    //     plotOptions: {
    //       line: {
    //         lineWidth: 3
    //       },
    //       tooltip: {
    //         hideDelay: 200
    //       }
    //     },
    //     subtitle: {
    //       text: 'Sprint 1',
    //       x: -20
    //     },
    //     xAxis: {
    //       categories: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6',
    //         'Day 7', 'Day 8', 'Day 9', 'Day 10']
    //     },
    //     yAxis: {
    //       title: {
    //         text: 'Hours'
    //       },
    //       plotLines: [{
    //         value: 0,
    //         width: 1
    //       }]
    //     },
    //     tooltip: {
    //       valueSuffix: ' hrs',
    //       crosshairs: true,
    //       shared: true
    //     },
    //     legend: {
    //       layout: 'vertical',
    //       align: 'right',
    //       verticalAlign: 'middle',
    //       borderWidth: 0
    //     },
    //     series: [{
    //       name: 'Ideal Burn',
    //       color: 'rgba(255,0,0,0.25)',
    //       lineWidth: 2,
    //       data: [100, 90, 80, 70, 60, 50, 40, 30, 20, 10]
    //     }, {
    //       name: 'Actual Burn',
    //       color: 'rgba(0,120,200,0.75)',
    //       marker: {
    //         radius: 6
    //       },
    //       data: [100, 110, 85, 60, 60, 30, 32, 23, 9, 2]
    //     }]
    //   });
    // });


  }

  ngOnDestroy() {
    this.preserveSearch.searchUserDashboardState = {
      defectReportPageEvent: this.assigneeByPageEvent,
      assignedToMePageEvent: this.assignedMePageEvent,
      watchedIssuePageEvent: this.watchedListPageEvent,
      selectedSprint: this.selectedSprint,

    };
  }

  recoverLastSearchValue() {
    const lastSearch = this.preserveSearch.searchUserDashboardState;
    if (lastSearch) {
      this.assigneeByPageEvent = lastSearch.defectReportPageEvent;
      this.assignedMePageEvent = lastSearch.assignedToMePageEvent;
      this.watchedListPageEvent = lastSearch.watchedIssuePageEvent;
      this.selectedSprint = lastSearch.selectedSprint


      //this.resultsLength = lastSearch.resultsLength;
    }


  }


  opendropdown(classname) {
    $('.' + classname + '-open').slideToggle("fast");
  }

  getUserprofile() {
    if (this.userData) {
      this.restService.hitApi('user/profile', { 'userId': this.userData.id }, 'get', '', false)
        .subscribe((result) => {
          let responseData: any = result;
          if (responseData.statusCode == 200) {
            this.userInfo = responseData.result;
          }
        }, (err) => {
          console.log(err);
          let errorData: any = err;
          // this.toastr.error(errorData.error.message);
        })

    }

  }

  getIssueStatisticsByAssignee(event?: PageEvent) {
    this.isIssueStatisticsByAssigneeListLoading = true;
    this.restService.hitApi('project/issueStatisticsByAssignee', {
      'projectId': this.selectedProjectId, "page": event.pageIndex + 1,
      "size": event.pageSize
    }, 'post', '', false)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.isIssueStatisticsByAssigneeListLoading = false;
          this.issueStatisticAssigneeByList = [];
          var mainData = responseData.result;
          if (mainData) {
            this.issueStatisticAssigneeByData = mainData.users;
            var stat = mainData.stat;
            this.issueStatisticAssigneeByData.totalCritical = 0;
            this.issueStatisticAssigneeByData.totalHigh = 0;
            this.issueStatisticAssigneeByData.totalMedium = 0;
            this.issueStatisticAssigneeByData.totalLow = 0;
            var users = this.issueStatisticAssigneeByData.data;
            if (stat) {
              Object.keys(stat).forEach(obj => {
                var statKey = obj;
                var statKeyValue = stat[obj];
                var priority = statKeyValue.priority;

                if (users && users.length > 0 && priority) {
                  for (var i = 0; i < Object.keys(priority).length; i++) {
                    var priorityObj = Object.keys(priority)[i];
                    users.forEach(element => {
                      if (element.userId == parseInt(statKey)) {
                        if (priorityObj == '1') {
                          element.critical = priority[priorityObj];
                          element.high = 0;
                          element.medium = 0;
                          element.low = 0;
                        } else if (priorityObj == '2') {
                          element.critical = 0;
                          element.high = priority[priorityObj];
                          element.medium = 0;
                          element.low = 0;
                        } else if (priorityObj == '3') {
                          element.critical = 0;
                          element.high = 0;
                          element.medium = priority[priorityObj];
                          element.low = 0;
                        } else if (priorityObj == '4') {
                          element.critical = 0;
                          element.high = 0;
                          element.medium = priority[priorityObj];
                          element.low = priority[priorityObj];
                        }


                      }

                    });

                  }

                }
              });
              users.forEach(element => {
                let nameparts = element.assigneeName.split(" ");
                if (nameparts.length > 1) {
                  element.firstName = nameparts[0];
                  element.lastName = nameparts[1];
                } else {
                  element.firstName = element.assigneeName;
                }

                if (element.critical || element.high || element.medium || element.low) {
                  element.total = element.critical + element.high + element.medium + element.low;
                } else {
                  element.critical = 0;
                  element.high = 0;
                  element.medium = 0;
                  element.low = 0;
                  element.total = element.critical + element.high + element.medium + element.low;
                }
                this.issueStatisticAssigneeByData.totalCritical = this.issueStatisticAssigneeByData.totalCritical + element.critical;
                this.issueStatisticAssigneeByData.totalHigh = this.issueStatisticAssigneeByData.totalHigh + element.high;
                this.issueStatisticAssigneeByData.totalMedium = this.issueStatisticAssigneeByData.totalMedium + element.medium;
                this.issueStatisticAssigneeByData.totalLow = this.issueStatisticAssigneeByData.totalLow + element.low;

                if (mainData.users.currentPage != 0) {
                  this.assigneeByPageIndex = mainData.users.currentPage - 1;
                }

                this.assigneeByPageSize = mainData.users.perPage;
                if (mainData.users.total > 0) {
                  this.assigneeByLength = mainData.users.total;
                }

                this.assigneeByPageEvent = new PageEvent();
                this.assigneeByPageEvent.length = this.assigneeByLength;
                this.assigneeByPageEvent.pageIndex = this.assigneeByPageIndex;
                this.assigneeByPageEvent.pageSize = this.assigneeByPageSize;

                this.issueStatisticAssigneeByList.push(element);
              });
              this.issueStatisticAssigneeByData.grandTotal = this.issueStatisticAssigneeByData.totalCritical + this.issueStatisticAssigneeByData.totalHigh + this.issueStatisticAssigneeByData.totalMedium + this.issueStatisticAssigneeByData.totalLow;
              console.log("this.issueStatisticAssigneeList", this.issueStatisticAssigneeByList);

            }
          }



        } else {
          this.isIssueStatisticsByAssigneeListLoading = false;
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        this.isIssueStatisticsByAssigneeListLoading = false;
        this.toastr.error(errorData.error.message);
      });
  }


  // fetchData() {
  //   this.isDashboardDataFetch = true;
  //   this.restService.hitApi('project/adminDashboard', { 'projectId': localStorage.getItem('projectSelected') }, 'post', '', false)
  //     .subscribe((result) => {
  //       let responseData: any = result;
  //       //  this.isDashboardDataFetch = false;
  //       if (responseData.statusCode == 200) {
  //         this.dashboardData = responseData.result;
  //       }
  //     });

  // }

  getSprints() {
    let localProjectId = localStorage.getItem("projectSelected")

    if (this.selectedProjectId != null && this.selectedProjectId != undefined && this.selectedProjectId != '' && this.selectedProjectId != 'null' && localProjectId != null && localProjectId != '' && localProjectId != 'null') {
      this.restService.hitApi('userStory/sprintList', { 'projectId': this.selectedProjectId }, 'post', '', false)
        .subscribe((result) => {
          let responseData: any = result;
          if (responseData.statusCode == 200) {
            this.sprintList = responseData.result;
            if (this.sprintList && this.sprintList.length > 0) {
              if (this.selectedSprint == null) {
                this.selectedSprint = this.sprintList[0];

              }
            }
            this.getBurnDownChart(this.selectedSprint?.id);
          } else {
            // this.toastr.error(responseData.replyMessage);
          }
        }, (err) => {
          console.log(err);
          let errorData: any = err;
          // this.toastr.error(errorData.error.replyMessage);
        })
    }

  }

  issueStatistics() {
    this.isIssueStatisticsListLoading = true;
    this.restService.hitApi('project/issueStatistics', { 'projectId': this.selectedProjectId }, 'post', '', false)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.isIssueStatisticsListLoading = false;
          this.issueStatisticData = responseData.result;
          this.issueStatisticData.todo.total = this.issueStatisticData.todo.critical + this.issueStatisticData.todo.high + this.issueStatisticData.todo.medium + this.issueStatisticData.todo.low;
          this.issueStatisticData.inProcess.total = this.issueStatisticData.inProcess.critical + this.issueStatisticData.inProcess.high + this.issueStatisticData.inProcess.medium + this.issueStatisticData.inProcess.low;
          this.issueStatisticData.quality.total = this.issueStatisticData.quality.critical + this.issueStatisticData.quality.high + this.issueStatisticData.quality.medium + this.issueStatisticData.quality.low;
          this.issueStatisticData.done.total = this.issueStatisticData.done.critical + this.issueStatisticData.done.high + this.issueStatisticData.done.medium + this.issueStatisticData.done.low;
          this.issueStatisticData.reopen.total = this.issueStatisticData.reopen.critical + this.issueStatisticData.reopen.high + this.issueStatisticData.reopen.medium + this.issueStatisticData.reopen.low;
          this.issueStatisticData.notBug.total = this.issueStatisticData.notBug.critical + this.issueStatisticData.notBug.high + this.issueStatisticData.notBug.medium + this.issueStatisticData.notBug.low;

          this.issueStatisticData.totalCritical = this.issueStatisticData.todo.critical + this.issueStatisticData.inProcess.critical + this.issueStatisticData.quality.critical + this.issueStatisticData.done.critical + this.issueStatisticData.reopen.critical + this.issueStatisticData.notBug.critical;
          this.issueStatisticData.totalHigh = this.issueStatisticData.todo.high + this.issueStatisticData.inProcess.high + this.issueStatisticData.quality.high + this.issueStatisticData.done.high + this.issueStatisticData.reopen.high + this.issueStatisticData.notBug.high;
          this.issueStatisticData.totalMedium = this.issueStatisticData.todo.medium + this.issueStatisticData.inProcess.medium + this.issueStatisticData.quality.medium + this.issueStatisticData.done.medium + this.issueStatisticData.reopen.medium + this.issueStatisticData.notBug.medium;
          this.issueStatisticData.totalLow = this.issueStatisticData.todo.low + this.issueStatisticData.inProcess.low + this.issueStatisticData.quality.low + this.issueStatisticData.done.low + this.issueStatisticData.reopen.low + this.issueStatisticData.notBug.low;


        } else {
          this.isIssueStatisticsListLoading = false;
          this.toastr.error(responseData.message);
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        this.isIssueStatisticsListLoading = false;
        this.toastr.error(errorData.error.message);
      })
  }
  issueStatisticsAssignee() {
    this.isIssueStatisticsAssigneeListLoading = true;


    this.restService.hitApi('project/issueStatisticsAssignee', { 'projectId': this.selectedProjectId }, 'post', '', false)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.totalIssueStatisticAssigneeData = 0;
          this.issueStatisticAssigneeData = [];
          this.isIssueStatisticsAssigneeListLoading = false;

          this.issueStatisticAssigneeData = responseData.result;
          if (this.issueStatisticAssigneeData) {
            this.issueStatisticAssigneeData.forEach(element => {
              let nameparts = element.assigneeName.split(" ");
              if (nameparts.length > 1) {
                element.firstName = nameparts[0];
                element.lastName = nameparts[1];
              } else {
                element.firstName = element.assigneeName;
              }
              this.totalIssueStatisticAssigneeData = this.totalIssueStatisticAssigneeData + element.bugCount;
            });
          }

          this.issueStatisticAssigneeData.forEach(element => {
            if (this.issueStatisticAssigneeData && element.bugCount) {
              element.percentage = (element.bugCount / this.totalIssueStatisticAssigneeData) * 100;
              element.percentage = element.percentage.toFixed(2);
            }
          });




        } else {
          this.isIssueStatisticsAssigneeListLoading = false;
          this.toastr.error(responseData.message);
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        this.isIssueStatisticsAssigneeListLoading = false;
        this.toastr.error(errorData.error.message);
      })
  }

  issueStatisticsByEntity() {
    this.isIssueStatisticsByEntityListLoading = true;
    this.restService.hitApi('project/issueStatisticsByEntity', { 'projectId': this.selectedProjectId }, 'post', '', false)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.isIssueStatisticsByEntityListLoading = false;
          this.issueStatisticsByEntityData = responseData.result;
          // this.issueStatisticAssigneeData = responseData.result;
          // if (this.issueStatisticAssigneeData) {
          //   this.issueStatisticAssigneeData.forEach(element => {
          //     this.totalIssueStatisticAssigneeData = this.totalIssueStatisticAssigneeData + element.bugCount;
          //   });
          // }

          // this.issueStatisticAssigneeData.forEach(element => {
          //   if (this.issueStatisticAssigneeData && element.bugCount) {
          //     element.percentage = (element.bugCount / this.totalIssueStatisticAssigneeData) * 100;
          //     element.percentage = Math.round(element.percentage);
          //   }
          // });




        } else {
          this.isIssueStatisticsByEntityListLoading = false;
          this.toastr.error(responseData.message);
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        this.isIssueStatisticsByEntityListLoading = false;
        // this.toastr.error(errorData.error.message);
      })
  }

  watchedIssue(event?: PageEvent) {
    this.isWatchedListLoading = true;
    this.restService.hitApi('project/watchedIssue', {
      'projectId': this.selectedProjectId, 'status': this.watchedStatus, 'entityType': this.entityTypeFilterArrayForWatchList, "page": event.pageIndex + 1,
      "size": event.pageSize
    }, 'post', '', false)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.isWatchedListLoading = false;
          this.watchedIssueList = [];
          this.watchedIssueData = responseData.result;

          if (responseData.result.currentPage != 0) {
            this.watchedListPageIndex = responseData.result.currentPage - 1;
          }

          this.watchedListPageSize = responseData.result.perPage;
          if (responseData.result.total > 0) {
            this.watchedListLength = responseData.result.total;
          }

          this.watchedListPageEvent = new PageEvent();
          this.watchedListPageEvent.length = this.watchedListLength;
          this.watchedListPageEvent.pageIndex = this.watchedListPageIndex;
          this.watchedListPageEvent.pageSize = this.watchedListPageSize;

          this.watchedConfig = { currentPage: this.watchedIssueData.currentPage, itemsPerPage: 10, totalItems: this.watchedIssueData.total }
          console.log("watchedIssueData", this.watchedIssueData);
          this.watchedIssueData.data.forEach(element => {
            this.watchedIssueList.push(element);
          });
        } else {
          this.isWatchedListLoading = false;
          this.toastr.error(responseData.message);
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        this.isWatchedListLoading = false;
        this.toastr.error(errorData.error.message);
      })
  }

  assignedMe(event?: PageEvent) {
    this.isAssignMeListLoading = true;
    this.restService.hitApi('project/assignedMe', {
      'projectId': this.selectedProjectId,
      "page": event.pageIndex + 1,
      "size": event.pageSize,
    }, 'post', '', false)
      .subscribe((result) => {
        let responseData: any = result;
        this.isAssignMeListLoading = false;

        if (responseData.statusCode == 200) {
          this.assignedMeList = [];
          this.assignedMeData = responseData.result;
          console.log("assignedMeData", this.assignedMeData);

          if (responseData.result.currentPage != 0) {
            this.assignedMePageIndex = responseData.result.currentPage - 1;
          }

          this.assignedMePageSize = responseData.result.perPage;
          if (responseData.result.total > 0) {
            this.assignedMeLength = responseData.result.total;
          }

          this.assignedMePageEvent = new PageEvent();
          this.assignedMePageEvent.length = this.assignedMeLength;
          this.assignedMePageEvent.pageIndex = this.assignedMePageIndex;
          this.assignedMePageEvent.pageSize = this.assignedMePageSize;
          // if (assignedMePageEvent.isFilterData) {
          //   this.assignedMeList = [];
          //   this.isFilterData = false;
          // }
          this.assignedMeData.data.forEach(element => {
            this.assignedMeList.push(element);
          });


          console.log("assignedMeList", this.assignedMeList);
        } else {
          this.isAssignMeListLoading = false;
          this.toastr.error(responseData.message);
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        this.isAssignMeListLoading = false;
        this.toastr.error(errorData.error.message);
      })
  }
  getBurnDownChart(sprintId) {
    if (this.sprintList && this.sprintList.length > 0) {
      for (var i = 0; i < this.sprintList.length; i++) {
        if (this.sprintList[i].id == sprintId) {
          this.selectedSprint = this.sprintList[i];
          break;
        }
      }

    }
    this.restService.hitApi('project/burnDownChart', {
      'projectId': this.selectedProjectId,
      sprintId: sprintId
    }, 'post', '', false)
      .subscribe((result) => {
        let responseData: any = result;

        if (responseData.statusCode == 200) {
          var data = responseData.result;
          if (data) {
            data.forEach(element => {
              this.userStories.push(element.title);
              var totalHrs = 0;
              if (element.loggedTime) {
                element.loggedTime.forEach(el => {
                  totalHrs = totalHrs + el.loggedHrs;
                });
              }
              this.loggedHrs.push(totalHrs);
              this.actualHrs.push(element.estHr);
            });
            this.makeBurnDownChart();
          }

        }
      })
  }

  getPercentage(percentage) {
    return percentage + '%';
  }
  // activityStream() {
  //   this.isActivityListFetch = true;
  //   this.restService.hitApi('project/activityStream', { 'projectId': localStorage.getItem('projectSelected'), 'page': this.activityStreamPage, 'filterEntity': this.entityTypeFilterArrayForActivityStreamList }, 'post', '', false)
  //     .subscribe((result) => {
  //       let responseData: any = result;
  //       this.isActivityListFetch = false;
  //       if (responseData.statusCode == 200) {
  //         this.activityStreamData = responseData.result;
  //         if (this.isFilterData) {
  //           this.activityStreamList = [];
  //           this.isFilterData = false;
  //         }
  //         // this.activityStreamList.push(JSON.parse(JSON.stringify(responseData.result.data)));
  //         // this.activityConfig = {currentPage: this.activityStreamData.currentPage,itemsPerPage: 10,totalItems:this.activityStreamData.total}
  //         this.activityStreamData.data.forEach(element => {
  //           this.activityStreamList.push(element);
  //         });
  //         this.isLoadMoreActivity = false;
  //         console.log('activityStreamData', this.activityStreamList);
  //       } else {
  //         // this.toastr.error(responseData.message);
  //       }
  //     }, (err) => {
  //       console.log(err);
  //       let errorData: any = err;
  //       // this.toastr.error(errorData.error.message);
  //     })
  // }

  getUserList() {
    this.restService.hitApi('project/projectUsers', { 'projectId': this.selectedProjectId }, 'post', '', false)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.userList = responseData.result;

        } else {
          // this.toastr.error(responseData.message);
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        // this.toastr.error(errorData.error.message);
      })
  }


  refreshIssueStatisticsByAssigneeByList() {
    this.isIssueStatisticsByAssigneeListRefresh = true;
    this.assigneeByLength = 0;
    this.assigneeByPageIndex = 0;
    this.assigneeByPageSize = 10;
    this.assigneeByPageEvent = new PageEvent();
    this.assigneeByPageEvent.length = this.assigneeByLength;
    this.assigneeByPageEvent.pageIndex = this.assigneeByPageIndex;
    this.assigneeByPageEvent.pageSize = this.assigneeByPageSize;

    this.getIssueStatisticsByAssignee(this.assigneeByPageEvent);

  }
  refreshIssueStatisticsList() {
    this.isIssueStatisticsListRefresh = true;
    this.issueStatistics();

  }
  refreshIssueStatisticsAssignee() {
    this.isIssueStatisticsAssigneeListRefresh = true;
    this.issueStatisticsAssignee();

  }
  refreshIssueStatisticsByEntity() {
    this.isIssueStatisticsByEntityListRefresh = true;
    this.issueStatisticsByEntity();

  }
  refreshAssignMeList() {
    this.assignedMeLength = 0;
    this.assigneeByPageIndex = 0;
    this.assignedMePageSize = 10;

    this.isAssignMeListRefresh = true;
    this.assignedMePageEvent = new PageEvent();
    this.assignedMePageEvent.length = this.assignedMeLength;
    this.assignedMePageEvent.pageIndex = this.assignedMePageIndex;
    this.assignedMePageEvent.pageSize = this.assignedMePageSize;

    this.assignedMe(this.assignedMePageEvent)
  }
  refreshWatchedList() {
    this.isWatchedListRefresh = true;
    this.watchedListLength = 0;
    this.watchedListPageIndex = 0;
    this.watchedListPageSize = 10;

    this.watchedListPageEvent = new PageEvent();
    this.watchedListPageEvent.length = this.watchedListLength;
    this.watchedListPageEvent.pageIndex = this.watchedListPageIndex;
    this.watchedListPageEvent.pageSize = this.watchedListPageSize;
    this.watchedIssue(this.watchedListPageEvent)
  }

  redirectPage(entityId, entityType) {
    localStorage.setItem('returnTo', "/dashboard");
    if (entityType == 'defect') {
      this.router.navigateByUrl('/defect/' + entityId);
    } else if (entityType == 'userStory') {
      this.router.navigateByUrl('/userstory/' + entityId);
    } else if (entityType == 'testPlan') {
      this.router.navigateByUrl('/testplan/' + entityId);
    } else if (entityType == 'task') {
      this.router.navigateByUrl('/task/' + entityId);
    }
  }

  makeBurnDownChart() {
    var userStories = this.userStories;
    var loggedHrs = this.loggedHrs;
    var actualHrs = this.actualHrs;
    var selectedSprint = this.selectedSprint;
    this.burDownChartOptions = {
      title: {
        text: 'Burndown Chart',
        x: -20 //center
      },
      colors: ['blue', 'red'],
      plotOptions: {
        line: {
          lineWidth: 3
        },
        tooltip: {
          hideDelay: 200
        }
      },
      subtitle: {
        text: selectedSprint ? selectedSprint.name : '',
        x: -20
      },
      xAxis: {
        categories: userStories
      },
      yAxis: {
        title: {
          text: 'Hours'
        },
        plotLines: [{
          value: 0,
          width: 1
        }]
      },
      tooltip: {
        valueSuffix: ' hrs',
        crosshairs: true,
        shared: true
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        borderWidth: 0
      },
      series: [{
        name: 'Ideal Burn',
        color: 'rgba(255,0,0,0.25)',
        lineWidth: 2,
        data: actualHrs
      }, {
        name: 'Actual Burn',
        color: 'rgba(0,120,200,0.75)',
        marker: {
          radius: 6
        },
        data: loggedHrs
      }]
    };

    console.log("this.burDownChartOptions", this.burDownChartOptions);

    $('#container').highcharts(this.burDownChartOptions);
  }


  // selectSprint(sprint) {
  //   $('.select-sprint-box-open').slideToggle("fast");
  //   this.sprintId = sprint.id;
  //   this.sprintName = sprint.name;
  //   this.issueStatistics();
  // }

  // selectType(type) {
  //   $('.default-selected-dp-open').slideToggle("fast");
  //   this.typeSelected = type;
  //   if (type == 0)
  //     this.typeSelectedText = 'All';
  //   if (type == 1)
  //     this.typeSelectedText = 'External'
  //   if (type == 2)
  //     this.typeSelectedText = 'Internal'
  //   this.issueStatistics();
  // }

  // selectWatchedStatus(status) {
  //   this.watchedStatus = status;
  //   $('.new-work-status-open').slideToggle("fast");
  //   this.watchedIssue();
  // }

  // selectAssigntomeStatus(status) {
  //   this.assignToMeStatus = status;
  //   $('.assign-project-status-open').slideToggle("fast");
  //   this.assignedMe();
  // }

  // showmore(current, last, entity) {
  //   console.log(current);
  //    if (entity == 'WatchList') {
  //     this.watchListPage = current + 1;
  //     this.isLoadMoreWatchList = true;
  //     this.watchListPage();
  //   } else if (entity == 'assignToMe') {
  //     this.assignToMePage = current + 1;
  //     this.isLoadMoreAssignToMe = true;
  //     this.assignToMePage();
  //   }

  // }

  // pageChange(newPage: number) {
  //   console.log(newPage);
  //   this.watchedConfig.currentPage = newPage;
  //   this.watchedIssue(newPage)
  // }

  // timeDiffCalc(dateFuture) {
  //   let date_future: any = new Date(dateFuture);
  //   console.log('date_future', date_future);
  //   var date = this.dateConverterService.convertUTCDateToLocalDate(new Date(date_future));
  //   console.log('date_future_in_local', date.toLocaleString());

  //   let date_now: any = new Date();
  //   console.log('date_now', date_now);
  //   var date1 = this.dateConverterService.convertUTCDateToLocalDate(date_now);
  //   console.log('date_future_in_local', date1.toLocaleString());

  //   let seconds = Math.floor((date_now - (date_future)) / 1000);
  //   let minutes = Math.floor(seconds / 60);
  //   let hours = Math.floor(minutes / 60);
  //   let days = Math.floor(hours / 24);

  //   hours = hours - (days * 24);
  //   minutes = minutes - (days * 24 * 60) - (hours * 60);
  //   seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

  //   let difference = '';
  //   if (days > 0) {
  //     difference = (days === 1) ? `${days} day ago` : `${days} days ago`;
  //   } else if (hours > 0) {
  //     difference = (hours === 1) ? `${hours} hour ago` : `${hours} hours ago`;

  //   } else if (minutes > 0) {
  //     difference = (minutes === 1) ? `${minutes} minute ago` : `${minutes} minutes ago`;

  //   } else {
  //     difference = (seconds === 0 || minutes === 1) ? `${seconds} second ago` : `${seconds} second ago`;

  //   }
  //   return difference;

  // }

  // onWatchListFilterSelection() {
  //   this.isFilterData = true;
  //   console.log("Selected -> ", this.entityTypeFilterArrayForWatchList);
  //   if (this.entityTypeFilterArrayForWatchList.length == 0) {
  //     this.entityTypeFilterArrayForWatchList = null;
  //   }
  //   this.watchedIssue()

  // }
  // onAssignToMeFilterSelection() {
  //   this.isFilterData = true;
  //   console.log("Selected -> ", this.entityTypeFilterArrayForAssignToMeList);
  //   if (this.entityTypeFilterArrayForAssignToMeList.length == 0) {
  //     this.entityTypeFilterArrayForAssignToMeList = null;
  //   }
  //   this.assignedMe()

  // }
  // onActivityStreamFilterSelection() {
  //   this.isFilterData = true;
  //   console.log("Selected -> ", this.entityTypeFilterArrayForActivityStreamList);
  //   if (this.entityTypeFilterArrayForActivityStreamList.length == 0) {
  //     this.entityTypeFilterArrayForActivityStreamList = null;

  //   }

  //   this.activityStream()

  // }
  // navigate(entityType, id) {
  //   var pid = localStorage.getItem('projectSelected');
  //   var url;
  //   if (entityType == 'defect') {
  //     url = '/project/' + pid + '/defect/' + id;
  //   } else if (entityType == 'task') {
  //     url = '/project/' + pid + '/task/' + id;
  //   } else if (entityType == 'userStory' || entityType == 'userstory') {
  //     url = '/project/' + pid + '/userstory/' + id;
  //   } else if (entityType == 'testPlan' || entityType == 'testplan') {
  //     url = '/project/' + pid + '/testplan/' + id;
  //   } else if (entityType == 'release') {
  //     url = '/project/' + pid + '/release/' + id;
  //   } else if (entityType == 'sprint') {
  //     url = '/project/' + pid + '/sprint/' + id;
  //   } else if (entityType == 'testCase' || entityType == 'testcase') {
  //     url = '/project/' + pid + '/testcase/' + id;
  //   }
  //   if (url) {
  //     this.router.navigateByUrl(url);
  //   }

  // }



}
