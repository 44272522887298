export class Data {
    value: string;
    name: string;
    id: string;

    constructor(name: string, value: string, id?: string) {
        this.name = name;
        this.value = value;
        this.id = id;
    }

}