import { find, findIndex } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { DateConverterService } from '../services/date-formatter/date-converter.service';
import { BroadcastService } from '../services/broadcast/broadcast.service';
import { PreservedSearchStateService } from '../services/search/preserved-search-state.service';

import { Data } from '../ui-components/select-dropdown/Data';
declare const $: any;

@Component({
  selector: 'app-company-selection',
  templateUrl: './company-selection.component.html',
  styleUrls: ['./company-selection.component.scss']
})
export class CompanySelectionComponent implements OnInit {

  userData: any = JSON.parse(localStorage.getItem("userData"));
  companyForm: FormGroup;
  companiesData: any = {};
  companies: any = [];
  companyName: any;
  companyId: any;
  isLoading: boolean = false;
  todayDate: any;
  lastCompanyId: any;
  lastCompanyName: any;
  loginDetails: any;
  isClickForSwitch: boolean = false;
  redirectUrl: any;
  redirectData: any;

  constructor(
    private restService: RestService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastr: ToastrService, private dateService: DateConverterService, private broadcastService: BroadcastService,
    private preserveSearch: PreservedSearchStateService) {

    this.isLoading = true;
    this.todayDate = new Date();

    this.loginDetails = this.router.getCurrentNavigation()?.extras.state;

    if (this.userData == null || this.userData == undefined || this.userData == '' || this.userData == 'null') {
      this.router.navigate(['login']);
    } else {
      this.getCompanyData();
    }

  }

  ngOnInit() {
    if (this.userData.companyId) {
      var obj = new Data(this.userData.companyId, this.userData.companyName);
      this.createFormForCompany(obj);
    }
    this.createFormForCompany(null);

    const lastSearch = this.preserveSearch.redirectLastUrlState;
    if (lastSearch) {
      this.redirectUrl = lastSearch.url
      this.redirectData = lastSearch.data
    }

  }



  createFormForCompany(data) {
    if (data === null) {
      this.companyForm = this.formBuilder.group({
        'name': ['', [Validators.required]],
        'id': ['', [Validators.required]],
      });

    } else {
      this.companyForm = this.formBuilder.group({
        'name': [data.name, [Validators.required]],
        'id': [data.id, [Validators.required]],
      });
    }

  }

  getCompanyData() {
    this.restService.hitApi('user/companies', {}, 'get', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.companyName = localStorage.getItem('company_name');
          this.companyId = localStorage.getItem('company_id');
          this.companiesData = responseData.result;

          console.log("companiesData: ", responseData.result);

          this.companies = [];
          this.companiesData.forEach(element => {
            if (this.companyId != element.id) {
              let data = new Data(element.name, element.id);
              this.companies.push(data);
            }

          });

          this.isLoading = false;
        } else {
          //this.errorProfileMessage = responseData.message;
        }

      }, (err) => {
        this.isLoading = false;
        this.toastr.error(err.error.message);
      })
  }

  getCompany(obj: Data) {

    if (obj) {
      console.log("selectedRole" + obj.name);
      console.log("selectedId" + obj.id);
      this.companyForm.get('id').setValue(parseInt(obj.id));
      this.companyForm.get('name').setValue(obj.name);

      console.log(this.companyForm);
    }
  }
  navigateToDashboard() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.broadcastService.boradcast("FATCH_USER_DATA_GO_TO_DASHBOARD", this.userData)

  }

  changeCompany() {
    let id = this.companyForm.value.id;
    var company = this.companiesData.find(el => el.id == id);
    if (company) {
      if (company.ownerId == this.userData.id) {
        if (company.endDate) {
          var date = new Date(company.endDate);
          if (date > this.todayDate) {
            this.restService.setCompany(id)
            localStorage.setItem('company_id', company.id.toString());
            localStorage.setItem('company_name', company.name);

          } else {
            $('#pay-alert').modal('show');
          }
        }
      } else {
        localStorage.setItem('company_id', company.id.toString());
        localStorage.setItem('company_name', company.name);
        this.restService.setCompany(id)


      }


    }

  }
  logout() {
    this.restService.logoutAPI();
  }
  goToPayment() {
    $('#pay-alert').modal('hide');
    this.router.navigate(['outer-pricing']);
  }

  closeModal() {

  }



}
