import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';

// Services
import { RestService } from '../services/rest/rest.service';
import { PreservedSearchStateService } from '../services/search/preserved-search-state.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { FileUploadService } from "../file-upload-service";
import { HttpEvent, HttpEventType } from '@angular/common/http';

// import { MatDialog } from '@angular/material';
// import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
import { Data } from '../ui-components/select-dropdown/Data';
declare const $: any;
@Component({
	selector: 'app-tasks',
	templateUrl: './tasks.component.html',
	styleUrls: ['./tasks.component.scss'],

})

export class TasksComponent implements OnInit {
	taskCreateForm: FormGroup;
	taskData: any = [];
	pid: any;
	usid: any;
	selectedTask: any;

	files: any = [];
	imgArr: any = [];
	projectListData: any;
	projectData: any;
	users: any;
	userDropDownList: Data[];
	sprintDropDownList: Data[];
	userStories: any;
	sprints: any;
	userData: any = JSON.parse(localStorage.getItem("userData"));


	date: Date = new Date();

	sortOrder: any = 'desc';
	sortByField: any = 'id'
	searchdata: any;

	pageEvent: PageEvent;
	datasource: any;
	pageIndex: number = 0;
	pageSize: number = 10;
	length: number = 0;
	searchString: string = "";
	isLoading: boolean = false;
	submitDisable: boolean = false;
	taskCreateFormSubmit: boolean = false;
	isShowMore: boolean = false;
	isCreateDialogShow: boolean = true;
	isOwner: boolean;
	isCompanyAdmin: boolean;
	isProjectManager: boolean;

	isNoSearchResults: boolean = false;
	isNoData: boolean = false;

	//shortCode: string;


	// public myDatePickerOptions: IMyDpOptions = {
	// 	dateFormat: 'dd-mm-yyyy',
	// 	openSelectorTopOfInput : true,
	// 	disableUntil:{year: this.date.getFullYear(), month: this.date.getMonth()+1, day: this.date.getDate()-1}
	// };
	// public myDatePickerOptions2: IMyDpOptions = {
	// 	dateFormat: 'dd-mm-yyyy',
	// 	openSelectorTopOfInput : true,
	// 	disableUntil:{year: this.date.getFullYear(), month: this.date.getMonth()+1, day: this.date.getDate()-1}
	// };


	dataSource: any = [];
	isOther: boolean;
	isActionColumnShown: boolean;

	constructor(
		private restService: RestService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private preserveSearch: PreservedSearchStateService,
		private fileUploadService: FileUploadService) {

		this.pid = localStorage.getItem('projectSelected');
		this.route.params.subscribe(params => {
			this.usid = params['usid'];

		});


		//this.shortCode = localStorage.getItem('projectShortCode');
	}

	ngOnInit() {

		this.recoverLastSearchValue();

		$(document).ready(function () {

			let that = this;
			$('.project-title-head').click(function () {
				$('.project-title-list').toggle("slide");
			});

			$('.htmlEditor').summernote({
				height: '150px',
				focus: true,
				disableDragAndDrop: true,
				dialogsInBody: true,
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
				callbacks: {
					onPaste: function (e) {
						var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
						e.preventDefault();
						document.execCommand('insertText', false, bufferText);
					}
				}
			});

			$('#summernote').summernote({
				dialogsInBody: true
			});

		});

		$(document).mouseup(function (e) {
			var container1 = $(".project-title-head");
			var container2 = $(".project-title-list");
			console.log(e.target);
			if (!container1.is(e.target) && container1.has(e.target).length === 0 && !container2.is(e.target) && container2.has(e.target).length === 0) {
				$(".project-title-list").hide('down');
			}
		});
		// this.getProjectList();
		if (this.pid && this.pid !== null && this.pid !== undefined) {
			if (this.pageEvent == null) {
				this.pageEvent = new PageEvent();
				this.pageEvent.length = this.length;
				this.pageEvent.pageIndex = this.pageIndex;
				this.pageEvent.pageSize = this.pageSize;
			}

			console.log("APi before", this.pageEvent.pageIndex)

			this.isLoading = true;
			this.getProjectDetail();
			this.getTasks(this.pageEvent);
			// this.userList();
			// this.sprintList();
			// this.statusList();
			// this.getTasks();
		}
		this.createForm(null);
	}

	ngOnDestroy() {
		this.preserveSearch.searchTaskState = {
			searchValue: this.searchString,
			pageEvent: this.pageEvent,
			results: this.taskData,
			resultsLength: this.taskData.length,
		};
	}

	recoverLastSearchValue() {
		const lastSearch = this.preserveSearch.searchTaskState;
		console.log("lastSearch", lastSearch);
		if (lastSearch) {
			this.searchString = lastSearch.searchValue;
			this.taskData = lastSearch.results;
			this.pageEvent = lastSearch.pageEvent;


			//this.resultsLength = lastSearch.resultsLength;
		}

	}

	getProjectDetail() {

		if (localStorage.getItem("isOwner") == "true") {
			this.isOwner = true
		} else {
			this.isOwner = false
		}

		if (localStorage.getItem("isCompanyAdmin") == "true") {
			this.isCompanyAdmin = true
		} else {
			this.isCompanyAdmin = false
		}

		if (localStorage.getItem("isProjectManager") == "true") {
			this.isProjectManager = true
		} else {
			this.isProjectManager = false
		}

		if (localStorage.getItem("isOther") == "true") {
			this.isOther = true
		} else {
			this.isOther = false
		}

		// this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', true)
		// 	.subscribe((result) => {
		// 		let responseData: any = result;
		// 		if (responseData.statusCode == 200) {
		// 			this.projectData = responseData.result;

		// 			if (this.userData) {
		// 				var isOwner = localStorage.getItem('isOwner');
		// 				if (isOwner == "true") {
		// 					this.isOwner = true;
		// 					this.isCompanyAdmin = false;
		// 					this.isProjectManager = false;
		// 					this.isOther = false;
		// 				} else {
		// 					if (this.userData.role == 1) {
		// 						this.isOwner = false;
		// 						this.isCompanyAdmin = true;
		// 						this.isProjectManager = false;
		// 						this.isOther = false;
		// 					} else if (this.userData.role == 2) {
		// 						if (this.projectData && this.projectData.projectUsersProject) {
		// 							this.projectData.projectUsersProject.forEach(element => {
		// 								if (element.userId == this.userData.id) {
		// 									if (element.userRoleId == 1) {
		// 										this.isProjectManager = true;
		// 										this.isOther = false;
		// 									} else {
		// 										this.isProjectManager = false;
		// 										this.isOther = true;
		// 									}
		// 								}
		// 							});
		// 							this.isOther = false;

		// 						} else {
		// 							this.isOther = true;
		// 						}
		// 						this.isOwner = false;
		// 						this.isCompanyAdmin = false;
		// 					} else {
		// 						this.isOther = true;
		// 						this.isOwner = false;
		// 						this.isProjectManager = false;
		// 						this.isCompanyAdmin = false;
		// 					}
		// 				}

		// 			}
		// 		} else {
		// 			// this.toastr.error(responseData.message);
		// 		}
		// 	}, (err) => {
		// 		console.log(err);
		// 		let errorData: any = err;
		// 		// this.toastr.error(errorData.error.message);
		// 	})
	}

	// getProjectList() {
	// 	this.restService.hitApi('discussion/projects',{},'get','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.projectListData = responseData.result.data;
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }

	// changeProject(pid){
	// 	localStorage.setItem('projectSelected',pid)
	// 	$('.project-title-list').toggle("slide");
	// 	this.router.navigateByUrl('/project/'+pid+'/tasks');
	// 	setTimeout(() => {
	// 		this.getTasks();
	// 	}, 500);

	// }

	userList() {
		this.restService.hitApi('project/projectUsers', { 'projectId': this.pid, escape: [2] }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.users = responseData.result;
					this.makeDropDownList();
					// this.setAutoSuggest(null);
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	makeDropDownList() {
		this.userDropDownList = [];
		let obj = new Data('Unassigned', '');
		this.userDropDownList.push(obj);
		if (this.users.length) {
			this.users.forEach(element => {
				var data = new Data(element.name, element.userId);
				this.userDropDownList.push(data);
			});
		}
	}

	makeSprintDropDownList() {
		this.sprintDropDownList = [];
		if (this.sprints.length) {
			this.sprints.forEach(element => {
				var data = new Data(element.name, element.id);
				this.sprintDropDownList.push(data);
			});
		}
	}

	// userStoryList(){
	// 	this.restService.hitApi('userStory/list',{'projectId':this.pid},'post','',false)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.userStories = responseData.result.data;
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }

	sprintList() {
		this.restService.hitApi('userStory/sprintList', { 'projectId': this.pid, notCompleted: 1 }, 'post', '', false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.sprints = responseData.result;
					this.makeSprintDropDownList();
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	createForm(data) {
		this.imgArr = [];
		this.files = [];
		this.taskCreateForm = this.formBuilder.group({
			'title': [data ? data.title : '', [Validators.required, Validators.minLength(1), Validators.maxLength(150)]],
			'description': [data ? data.description : ''],
			'assignee': [data ? data.assignee : ''],
			'sprintId': [data ? data.userstoryId : ''],
			'estHours': [data ? data.estHours : ''],
			'estMins': [data ? data.estMins : ''],
			// 'userStoryId': [data?data.userstoryId:''],
			// 'comment': [data?data.comment:''],
			// 'sDate':[data?data.startDate:''],
			// 'eDate':[data?data.endDate:''],
			'projectTaskId': [data ? data.id : ''],
			'assigneeName': ['Unassigned', ''],
			'sprintName': ['', ''],
			'attachments': []

			// 'status':[data?data.status:'',[Validators.required]],
			// 'attachments':[''],
			// 'oldattachments':[data?data.attachments:'']
		});


		// if(data){
		// 	if(data.startDate){
		// 	let startDate = new Date(data.startDate);
		// 		this.taskCreateForm.patchValue({sDate: {
		// 			date: {
		// 				year: startDate.getFullYear(),
		// 				month: startDate.getMonth() + 1,
		// 				day: startDate.getDate()}
		// 		}});
		// 	}
		// 	if(data.endDate){
		// 		let endDate = new Date(data.endDate);
		// 		this.taskCreateForm.patchValue({eDate: {
		// 			date: {
		// 				year: endDate.getFullYear(),
		// 				month: endDate.getMonth() + 1,
		// 				day: endDate.getDate()}
		// 		}});
		// 	}

		// }
		// }

	}


	// onDateChanged(event: IMyDateModel) {
	// 	this.myDatePickerOptions2 = {
	// 		disableUntil:event.date
	// 	};
	// }

	createTask() {
		this.submitDisable = true;

		let assignee: any = [];
		assignee[0] = this.taskCreateForm.value.assignee;
		let apiname = (this.taskCreateForm.value.projectTaskId) ? 'projectTask/update' : 'projectTask/create';
		this.taskCreateForm.value.assignee = assignee;
		this.taskCreateForm.value.projectId = this.pid;
		this.taskCreateForm.value.userStoryId = this.taskCreateForm.value.userStoryId;
		this.taskCreateForm.value.startDate = this.taskCreateForm.value.sDate ? (this.taskCreateForm.value.sDate.date.year + "-" + this.taskCreateForm.value.sDate.date.month + "-" + this.taskCreateForm.value.sDate.date.day) : '';
		this.taskCreateForm.value.endDate = this.taskCreateForm.value.eDate ? (this.taskCreateForm.value.eDate.date.year + "-" + this.taskCreateForm.value.eDate.date.month + "-" + this.taskCreateForm.value.eDate.date.day) : '';
		this.taskCreateForm.value.attachments = this.imgArr;
		this.taskCreateForm.value.description = $('#summernote').val();

		console.log(this.taskCreateForm.value);
		if (this.taskCreateForm.invalid) {
			this.submitDisable = false;
		
			this.taskCreateFormSubmit = true;
			return;
		} else {

			this.restService.hitApi(apiname, this.taskCreateForm.value, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					this.submitDisable = false;
		
					if (responseData.statusCode == 200) {
						this.closeModal();
						this.getTasks(this.pageEvent);
						this.toastr.success(responseData.message);
						this.files = [];
						this.imgArr = [];
						// this.setAutoSuggest(null);
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					this.submitDisable = false;
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}
	}

	closeModal() {
		$('#create_task_popup_web').modal('hide');
		this.isCreateDialogShow = false;
		setTimeout(() => {
			this.resetForm();
			this.isCreateDialogShow = true;
			this.setHeightOfSummarNote();
		});

	}

	sortData(field) {
		this.sortByField = field;
		this.sortOrder = this.sortOrder == 'asc' ? 'desc' : 'asc';
		this.getTasks(this.pageEvent);
	}

	searchData() {
		// this.searchdata = {};

		// if($('#search_id').val()!=""){
		// 	this.searchdata.id = $('#search_id').val().replace(/TID/i,'');
		// }
		// if($('#search_name').val()!=""){
		// 	this.searchdata.name = $('#search_name').val();
		// }
		// if($('#search_status').val()!=""){
		// 	this.searchdata.status = $('#search_status').val();
		// }
		// if($('#search_assignee').val()!=""){
		// 	this.searchdata.assignee = $('#search_assignee').val();
		// }


		// this.getTasks();
		this.searchString = $('#search_name').val();
		console.log("i am searching", this.searchString);
		console.log(this.pageEvent);
		if (this.searchString.length >= 2 || this.searchString.length == 0) {
			this.pageEvent = new PageEvent();
			this.pageEvent.length = 0;
			this.pageEvent.pageIndex = 0;
			this.pageEvent.pageSize = 10;
			this.getTasks(this.pageEvent);
		}
	}

	getTasks(event?: PageEvent) {
		let postdata = {
			"projectId": this.pid,
			"sort": {
				"column": this.sortByField,
				"direction": this.sortOrder
			},
			"page": event.pageIndex + 1,
			"size": event.pageSize,
			"q": this.searchString
		};
		this.restService.hitApi('projectTask/list', postdata, 'post', '', true)
			.subscribe((result) => {

				let responseData: any = result;
				console.log("projectTask responseData.result", responseData.result)
				if (responseData.statusCode == 200) {
					this.taskData = (responseData.result.total > 0) ? responseData.result.data : [];
					this.datasource = responseData.result;
					if (responseData.result.currentPage != 0) {
						this.pageIndex = responseData.result.currentPage - 1;
					}

					this.pageSize = responseData.result.perPage;
					if (responseData.result.total > 0) {
						this.length = responseData.result.total;
					}
					this.pageEvent = new PageEvent();
					this.pageEvent.length = this.length;
					this.pageEvent.pageIndex = this.pageIndex;
					this.pageEvent.pageSize = this.pageSize;

					this.setIndexArray();
					this.checkIfDataHasComes();

					if (this.taskData.length > 0) {
						this.isActionColumnShown = false;
						for (var i = 0; i < this.taskData.length; i++) {
							var element = this.taskData[i];
							if (this.isOwner || this.isCompanyAdmin || this.isProjectManager) {
								this.isActionColumnShown = true;
								break;
							}
						}
					}
				} else {
					this.toastr.error(responseData.message);
				}
				this.isLoading = false;
			}, (err) => {
				this.isLoading = false;
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	checkIfDataHasComes() {
		if (this.taskData.length == 0) {
			if (this.searchString != "" && this.searchString != null) {
				this.isNoSearchResults = true;
				this.isNoData = false;
			} else {
				this.isNoData = true;
				this.isNoSearchResults = false;
			}
		} else {
			this.isNoData = false;
			this.isNoSearchResults = false;
		}
	}

	deleteTask(rid) {
		var index;
		for (var i = 0; i < this.taskData.length; i++) {
			if (this.taskData[i].id == rid) {
				index = i;
				break;
			}
		}
		this.restService.hitApi('projectTask/delete', { 'projectTaskId': rid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					if (index != -1) {
						this.taskData.splice(index, 1);
						if (this.taskData.length == 0) {
							this.pageEvent = new PageEvent();
							this.pageEvent.length = this.length;
							this.pageEvent.pageIndex = 0;
							this.pageEvent.pageSize = this.pageSize;

							this.getTasks(this.pageEvent);
						}
						this.datasource.total = this.datasource.total - 1;
						console.log("this.datasource.total>", this.datasource.total)
						this.length = this.datasource.total;
					}
					$('#delete-record').modal('hide')
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	blockTask(rid, blockstatus) {
		let isBlocked = (blockstatus == 1) ? 0 : 1;
		this.restService.hitApi('projectTask/block', { 'projectTaskId': rid, 'isBlocked': isBlocked }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getTasks(this.pageEvent);
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	//Image upload
	uploadFile(event) {
		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];
			if (this.restService.checkFileSize(file, 50)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				formData.append('entity', this.usid ? 'subtask' : 'task');
				formData.append('file', file);
				this.files.push({ index: index, localFileName: file.name, serverFileName: null, isProgressBarShown: true });
				this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
					.subscribe((event: HttpEvent<any>) => {
						switch (event.type) {
							case HttpEventType.Sent:
								console.log('Request has been made!');
								break;
							case HttpEventType.ResponseHeader:
								console.log('Response header has been received!');
								break;
							case HttpEventType.UploadProgress:
								this.files[this.files.length - 1].progress = Math.round(event.loaded / event.total * 100);
								console.log(`Uploaded! ${this.files[this.files.length - 1].progress}%`);
								$('#attachment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');

								break;
							case HttpEventType.Response:
								let responseData: any = event.body;
								if (responseData.statusCode == 200) {
									this.imgArr.push(responseData.result);
									this.files[this.files.length - 1].serverFileName = responseData.result.fileTitle;
									this.files[this.files.length - 1].displayName = responseData.result.displayName;
									this.files.forEach(element => {
										element.isProgressBarShown = false;
									});
								} else {
									if (responseData.message) {
										this.toastr.error(responseData.message);
									}
								}
								console.log('Upload successfully', event.body);
								setTimeout(() => {
									this.files[this.files.length - 1].progress = 0;
									$('#attachment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');

								}, 1500);


						}
					})
				//this.files.push(file.name);

				// this.restService.hitApi('projectTask/upload-file', formData, 'post', '', true, false)
				// 	.subscribe((result) => {
				// 		let responseData: any = result;
				// 		if (responseData.statusCode == 200) {
				// 			this.files[index].serverFileName = responseData.result;
				// 			this.imgArr.push(responseData.result);
				// 		} else {
				// 			this.toastr.error(responseData.message);
				// 		}
				// 	}, (err) => {
				// 		console.log(err);
				// 		let errorData: any = err;
				// 		this.toastr.error(errorData.error.message);
				// 	})
			}
		}

	}

	deleteAttachment(aid, index) {
		this.restService.hitApi('projectTask/delete-file', { "attachmentId": aid, "projectId": this.pid }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.taskCreateForm.value.oldattachments.splice(index, 1);
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	setIndexArray() {
		for (let i = 0; i < this.taskData.length; i++) {
			if (this.pageIndex == 0) {
				this.taskData[i].tableIndex = i + 1;
			} else {
				this.taskData[i].tableIndex = (this.pageSize * this.pageIndex) + i + 1;
			}
		}
	}

	removeAttachment(index) {
		var file = this.files[index];
		// this.files.splice(index, 1);
		var deleteFileArray = [file.serverFileName];
		this.removeAttachmentFromServer(deleteFileArray, index);
	}

	removeAttachmentFromServer(files, index) {
		var json = {
			projectId: this.pid,
			entity: this.usid ? 'userStoryTask' : 'task',
			files: files
		}
		this.restService.hitApi('project/deleteUploadedFiles', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.files.splice(index, 1);
					if (files && this.imgArr) {
						var removeIndex = null;
						files.forEach(element1 => {
							for (var i = 0; i < this.imgArr.length; i++) {
								if (this.imgArr[i] == element1) {
									removeIndex = i;
									break
								}
							}


						});

					}
					if (removeIndex != null) {
						this.imgArr.splice(removeIndex, 1);

					}
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			});
	}


	isBlocked() {
		this.toastr.error('This task is Blocked');
	}

	getAssignee(obj: Data) {
		if (obj) {
			this.taskCreateForm.get('assigneeName').setValue(obj.name);
			this.taskCreateForm.get('assignee').setValue(obj.value);
		}

	}

	getSprint(obj: Data) {
		if (obj) {
			this.taskCreateForm.get('sprintName').setValue(obj.name);
			this.taskCreateForm.get('sprintId').setValue(obj.value);
		} else {
			this.taskCreateForm.get('sprintName').setValue(null);
			this.taskCreateForm.get('sprintId').setValue(null);

		}


		console.log("this.obj, ", obj)
		console.log("this.taskCreateForm.value, ", this.taskCreateForm.value)
	}

	resetForm() {
		this.taskCreateForm.reset();
		$('#summernote').summernote("reset");
		this.showLess();
	}

	showMore() {
		this.isShowMore = true;
		$('.moretext').slideDown();

	}

	showLess() {
		this.isShowMore = false;
		$('.moretext').slideUp();

	}


	setHeightOfSummarNote() {

		$(document).ready(function () {
			$('.htmlEditor').summernote({
				height: '150px',
				focus: true,
				disableDragAndDrop: true,
				dialogsInBody: true,
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
				callbacks: {
					onPaste: function (e) {
						var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
						e.preventDefault();
						document.execCommand('insertText', false, bufferText);
					}
				}
			});

			$('#summernote').summernote({
				dialogsInBody: true
			});

		})
		this.isCreateDialogShow = true;


	}

}

