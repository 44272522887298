import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RestService } from '../services/rest/rest.service';
import { ValidationService } from '../services/config/config.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'angular2-cookie/services/cookies.service';
// import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
declare const $:any;
declare let paypal: any;
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers:[CookieService]
})


export class PaymentComponent implements OnInit {
	@ViewChild('paypal', { static: true }) paypalElement: ElementRef;
	planId: any;  
  	subcripId: any;
	basicAuth = 'ARtEy22Azz3Kj05cFn1pP3HGLm4vRIuOyPCvibv3oTo48KdEUeJFUHLc-_cq7PEXDftnCvcKAQyUtK3XEM423aT9XjwgZ--smCi8Na4D-bFgAf3iMGebQ2UsPVhizwxYbsOGOU73huA5w3tTvpkkIBRW49tlTpmT';
	// product = {
	// 	price: 777.77,
	// 	description: 'used couch, decent condition',
	// 	img: 'assets/couch.jpg'
	//   };
	
	//   paidFor = false;



//   public payPalConfig?: IPayPalConfig;
  userData : any = JSON.parse(localStorage.getItem('uchoosecarUserData'))
//   addScript: boolean = false;
//   paypalLoad: boolean = true;
//   finalAmount: number = 1;
  subscriptionData : any;

  
//   paypalConfig = {
//     env: 'sandbox',
//     client: {
//       sandbox: 'ARtEy22Azz3Kj05cFn1pP3HGLm4vRIuOyPCvibv3oTo48KdEUeJFUHLc-_cq7PEXDftnCvcKAQyUtK3X',
//       production: 'EM423aT9XjwgZ--smCi8Na4D-bFgAf3iMGebQ2UsPVhizwxYbsOGOU73huA5w3tTvpkkIBRW49tlTpmT'
//     },
//     commit: true,
//     payment: (data, actions) => {
//       return actions.payment.create({
//         payment: {
//           transactions: [
//             { amount: { total: this.finalAmount, currency: 'INR' } }
//           ]
//         }
//       });
//     },
//     onAuthorize: (data, actions) => {
//       return actions.payment.execute().then((payment) => {
//         //Do something when payment is successful.
//       })
//     }
//   };
  constructor(
    private restService : RestService,
    private router : Router,
    private toastr: ToastrService,
  ) { 

	console.log(localStorage.getItem("packageId"));
  }

  ngOnInit() {

console.log(this.subscriptionData);

	// const self = this;  
    // this.planId = 'P-3LP12970YV154810CL4RIJTI';  //Default Plan Id
    // paypal.Buttons({  
    //   createSubscription: function (data, actions) {  
    //     return actions.subscription.create({  
    //       'plan_id': self.planId,  
    //     });  
    //   },  
    //   onApprove: function (data, actions) {  
    //     console.log(data);  
    //     alert('You have successfully created subscription ' + data.subscriptionID);  
    //     // self.getSubcriptionDetails(data.subscriptionID);  
    //   },  
    //   onCancel: function (data) {  
    //     // Show a cancel page, or return to cart  
    //     console.log(data);  
    //   },  
    //   onError: function (err) {  
    //     // Show an error page here, when an error occurs  
    //     console.log(err);  
    //   }  
  
	// }).render(this.paypalElement.nativeElement); 


	
	

	// paypal.Buttons().render('#paypal-button-container');

	// paypal.Buttons({

	// 	createSubscription: function(data, actions) {
	  
	// 	  return actions.subscription.create({
	  
	// 		'plan_id': 'P-2UF78835G6983425GLSM44MA'
	  
	// 	  });
	  
	// 	},
	  
	  
	// 	onApprove: function(data, actions) {
	  
	// 	  alert('You have successfully created subscription ' + data.subscriptionID);
	  
	// 	}
	  
	  
	//   }).render('#paypal-button-container');


	// this.getSubscriptions();
	// this.paypalSuccess();
	
		// if (localStorage.getItem('userData')){
		// 	this.router.navigate(['/dashboard'])
		// }
	
		$('.show-pwd').click(function(){
			console.log('hi');
			if($(this).hasClass('disabled')){
				$(this).removeClass('disabled');
				$(this).prev().attr('type','text');
			}else{
				$(this).addClass('disabled');
				$(this).prev().attr('type','password');
			}
		})



		//
	// 	paypal
	// 		.Buttons({
	// 			createOrder: (data, actions) => {
	// 			return actions.order.create({
	// 				purchase_units: [
	// 				{
	// 					description: this.product.description,
	// 					amount: {
	// 					currency_code: 'USD',
	// 					value: this.product.price
	// 					}
	// 				}
	// 				]
	// 			});
	// 			},
	// 			onApprove: async (data, actions) => {
	// 			const order = await actions.order.capture();
	// 			this.paidFor = true;
	// 			console.log(order);
	// 			this.updateStatus();
	// 			},
	// 			onError: err => {
	// 			console.log(err);
	// 			// this.updateStatus();
	// 			}
	// 		})
	// 		.render(this.paypalElement.nativeElement);
	}


	// ============Start Get Subcription Details Method============================  
	getSubcriptionDetails(subcriptionId) {  
		const xhttp = new XMLHttpRequest();  
		xhttp.onreadystatechange = function () {  
		  if (this.readyState === 4 && this.status === 200) {  
			console.log(JSON.parse(this.responseText));  
			alert(JSON.stringify(this.responseText));  
		  }  
		};  
		xhttp.open('GET', 'https://api.sandbox.paypal.com/v1/billing/subscriptions/' + subcriptionId, true);  
		xhttp.setRequestHeader('Authorization', this.basicAuth);  
	  
		xhttp.send();  
	  }  
	  // ============END Get Subcription Details Method========================  


	getSubscriptions(){
		this.restService.hitApi('users/subscriptionDetail',{'subscriptionId':localStorage.getItem('packageId')},'get','',true)
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.subscriptionData = responseData.result;
				// this.initConfig();
			}else{
				// this.toastr.error(responseData.message);
			}
		},(err)=>{
			console.log(err);
			let errorData : any = err;
			// this.toastr.error(errorData.error.message);
		})
	}

	//sandbox: 'ARtEy22Azz3Kj05cFn1pP3HGLm4vRIuOyPCvibv3oTo48KdEUeJFUHLc-_cq7PEXDftnCvcKAQyUtK3X',
//       production: 'EM423aT9XjwgZ--smCi8Na4D-bFgAf3iMGebQ2UsPVhizwxYbsOGOU73huA5w3tTvpkkIBRW49tlTpmT'
	// private initConfig(): void {
	// 	this.payPalConfig = {
	// 	currency: 'USD',
	// 	clientId: 'ARtEy22Azz3Kj05cFn1pP3HGLm4vRIuOyPCvibv3oTo48KdEUeJFUHLc-_cq7PEXDftnCvcKAQyUtK3X',
	// 	createOrderOnClient: (data) => <ICreateOrderRequest>{
	// 	  intent: 'CAPTURE',
	// 	  purchase_units: [
	// 		{
	// 		  amount: {
	// 			currency_code: 'USD',
	// 			value: this.subscriptionData.price,
	// 			breakdown: {
	// 			  item_total: {
	// 				currency_code: 'USD',
	// 				value: this.subscriptionData.price
	// 			  }
	// 			}
	// 		  },
	// 		  items: [
	// 			{
	// 			  name: this.subscriptionData.name,
	// 			  quantity: '1',
	// 			  category: 'DIGITAL_GOODS',
	// 			  unit_amount: {
	// 				currency_code: 'USD',
	// 				value: this.subscriptionData.price,
	// 			  },
	// 			}
	// 		  ]
	// 		}
	// 	  ]
	// 	},
	// 	advanced: {
	// 	  commit: 'true'
	// 	},
	// 	style: {
	// 	  label: 'paypal',
	// 	  layout: 'vertical'
	// 	},
	// 	onApprove: (data, actions) => {
	// 	  console.log('onApprove - transaction was approved, but not authorized', data, actions);
	// 	  actions.order.get().then(details => {
	// 		console.log('onApprove - you can get full order details inside onApprove: ', details);
	// 		this.paypalSuccess();
	// 	});
	// 	},
	// 	onClientAuthorization: (data) => {
	// 	  console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
	// 	//   this.showSuccess = true;
	// 	this.paypalSuccess();
	// 	},
	// 	onCancel: (data, actions) => {
	// 	  console.log('OnCancel', data, actions);
	// 	  this.paypalCancel();
	// 	},
	// 	onError: err => {
	// 	  console.log('OnError', err);
	// 	},
	// 	onClick: (data, actions) => {
	// 	  console.log('onClick', data, actions);
	// 	},
	//   };
	//   }

	ngAfterViewInit(): void {
		// this.loadExternalScript("https://www.paypalobjects.com/api/checkout.js").then(() => {
		//   paypal.Button.render({
		// 	env: 'sandbox',
		// 	client: {
		// 	  production: 'EM423aT9XjwgZ--smCi8Na4D-bFgAf3iMGebQ2UsPVhizwxYbsOGOU73huA5w3tTvpkkIBRW49tlTpmT',
		// 	  sandbox: 'ARtEy22Azz3Kj05cFn1pP3HGLm4vRIuOyPCvibv3oTo48KdEUeJFUHLc-_cq7PEXDftnCvcKAQyUtK3X'
		// 	},
		// 	commit: true,
		// 	payment: function (data, actions) {
		// 	  return actions.payment.create({
		// 		payment: {
		// 		  transactions: [
		// 			{
		// 			  amount: { total: '1.00', currency: 'USD' }
		// 			}
		// 		  ]
		// 		}
		// 	  })
		// 	},
		// 	onAuthorize: function(data, actions) {
		// 	  return actions.payment.execute().then(function(payment) {
		// 		// TODO
		// 	  })
		// 	}
		//   }, '#paypal-button');
		// });

		// this.loadExternalScript("https://www.paypal.com/sdk/js?client-id=AXrYSs-ysUibk-j0DwMCpR1Re9OSkusrcq-0fBNHWgG0UyfDvXExA1hoLVE6BJ_lzi-xqwEQ7UbWxTDC&vault=true").then(() => {

		// 	paypal.Buttons({
		// 		style: {
		// 			shape: 'rect',
		// 			color: 'gold',
		// 			layout: 'vertical',
		// 			label: 'subscribe',
					
		// 		},
		// 		createSubscription: (data, actions)=> {
		// 		  return actions.subscription.create({
		// 			'plan_id': 'P-109132993A482242KL4UUOPI'
		// 		  });
		// 		},
		// 		onApprove: (data, actions)=> {
		// 		  alert(data.subscriptionID);
		// 		  this.paypalSuccess(data.subscriptionID)
		// 		}
		// 	}).render('#paypal-button-container');
		// });



		this.loadExternalScript("https://www.paypal.com/sdk/js?client-id=AR0yqFVKBCciOZLry3iOpCyDttbPNQMVLDA_JFndbI7TYGSObahnGwawntiiCd0tDYcT2Iw2HnSsXgX0&vault=true").then(() => {
			paypal.Buttons({
				style: {
					shape: 'rect',
					color: 'blue',
					layout: 'vertical',
					label: 'subscribe',
					
				},
				createSubscription: function(data, actions) {
				  return actions.subscription.create({
					'plan_id': 'P-08699109VD508412VL4UPGFQ'
				  });
				},
				onApprove: function(data, actions) {
				  alert(data.subscriptionID);
				}
			}).render('#paypal-button-container');
		});

	  }

	  private loadExternalScript(scriptUrl: string) {
		return new Promise((resolve, reject) => {
		  const scriptElement = document.createElement('script')
		  scriptElement.src = scriptUrl
		  scriptElement.onload = resolve
		  document.body.appendChild(scriptElement)
	  })
	}

	// ngAfterViewChecked(): void {
	// 	if (!this.addScript) {
	// 	  this.addPaypalScript().then(() => {
	// 		paypal.Button.render(this.paypalConfig, '#paypal-checkout-btn');
	// 		this.paypalLoad = false;
	// 	  })
	// 	}
	//   }
	  
	//   addPaypalScript() {
	// 	this.addScript = true;
	// 	return new Promise((resolve, reject) => {
	// 	  let scripttagElement = document.createElement('script');    
	// 	  scripttagElement.src = 'https://www.paypalobjects.com/api/checkout.js';
	// 	  scripttagElement.onload = resolve;
	// 	  document.body.appendChild(scripttagElement);
	// 	})
	//   }

	//   getSubscriptions(){
	// 	this.restService.hitApi('users/subscription',{'subscription_id':frequency},'get','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.subscriptionData = responseData.result;
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }

	verifySubscription(){

		//let requestData : any = {'razorpay_payment_id':response.razorpay_payment_id,'razorpay_subscription_id':response.subscription_id,'razorpay_signature':response.razorpay_signature};
		// let generated_signature = hmac_sha256(response.subscription_id + "|" + response.razorpay_payment_id)
		let requestData : any;  
		this.restService.hitApi('users/verify-subscription',requestData,'post','',true)
			.subscribe((result)=>{
				let responseData : any = result;
				if(responseData.statusCode==200){
					localStorage.setItem('userData', JSON.stringify(responseData.result));
					localStorage.setItem('company_id',responseData.result.companyId.toString());
					localStorage.setItem('company_name',responseData.result.companyName);
					localStorage.removeItem('packageId');
					this.router.navigate(['dashboard']);
					this.toastr.success(responseData.message);
					setTimeout(() => {
						location.reload();
					}, 100);
				}else{
					this.toastr.error(responseData.message);
				}
			},(err)=>{
				this.toastr.error(err.error.message);
				// this.errorMessage = err.error.message;
		  })
  }

  





paypalSuccess(subscriptionID){
	this.restService.hitApi('paypalSuccess',{'subscriptionID':subscriptionID},'post','',true)
	.subscribe((result)=>{
		let responseData : any = result;
		if(responseData.statusCode==200){
			localStorage.setItem('userData', JSON.stringify(responseData.result));
			localStorage.setItem('company_id',responseData.result.companyId.toString());
			localStorage.setItem('company_name',responseData.result.companyName);
			localStorage.removeItem('packageId');
			this.router.navigate(['dashboard']);
			setTimeout(() => {
				location.reload();
			}, 100);
			this.toastr.success(responseData.message);
			
		}else{
			this.toastr.error(responseData.message);
		}
	},(err)=>{
		console.log(err);
		let errorData : any = err;
		this.toastr.error(errorData.error.message);
	})
}

paypalCancel(){
	this.restService.hitApi('paypalCancel',{},'get','',true)
	.subscribe((result)=>{
		let responseData : any = result;
		if(responseData.statusCode==200){
			this.toastr.success(responseData.message);
		}else{
			this.toastr.error(responseData.message);
		}
	},(err)=>{
		console.log(err);
		let errorData : any = err;
		this.toastr.error(errorData.error.message);
	})
}
  


	toggle_eyes(){
		if($('input[name=password]').prop("type")=='password'){
			$('.fa').removeClass('fa-eye-slash');
			$('.fa').addClass('fa-eye');
			$('input[name=password]').prop("type", "text");
		}else{
			$('.fa').addClass('fa-eye-slash');
			$('.fa').removeClass('fa-eye');
			$('input[name=password]').prop("type", "password");
		}
	}

}
