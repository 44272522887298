import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';

// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { PreservedSearchStateService } from '../services/search/preserved-search-state.service';


// import { MatDialog } from '@angular/material';
// import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
import { Data } from '../ui-components/select-dropdown/Data';
declare const $: any;
@Component({
	selector: 'app-testplans',
	templateUrl: './testplans.component.html',
	styleUrls: ['./testplans.component.scss'],
})

export class TestplansComponent implements OnInit, OnDestroy {
	userData: any = JSON.parse(localStorage.getItem("userData"));
	testplanCreateForm: FormGroup;
	testplanCreateFormSubmit: boolean;
	testPlanMainData: any
	testplanData: any = [];
	pid: any = localStorage.getItem('projectSelected'); isOther: boolean;
	;
	selectedTestplan: any;
	userList: any;
	userDropDownList: Data[];
	projectData: any;


	sortOrder: any = 'desc';
	sortByField: any = 'id'
	searchString: any = "";

	pageEvent: PageEvent;
	datasource: null;
	pageIndex: number = 0;
	pageSize: number = 10;
	length: number = 0;
	isLoading: boolean = false;
	isCreateDialogShow: boolean = false;
	isOwner: boolean;
	isCompanyAdmin: boolean;
	isProjectManager: boolean;

	isNoSearchResults: boolean = false;
	isNoData: boolean = false;

	constructor(
		private restService: RestService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private preserveSearch: PreservedSearchStateService) {
		// Check for route params


		this.isOwner = localStorage.getItem("isOwner") == 'true' ? true : false;
		this.isCompanyAdmin = localStorage.getItem("isCompanyAdmin") == 'true' ? true : false;
		this.isProjectManager = localStorage.getItem("isProjectManager") == 'true' ? true : false;
		this.isOther = localStorage.getItem("isOther") == 'true' ? true : false;

	}

	ngOnInit() {
		this.recoverLastSearchValue();
		if (this.pid && this.pid !== null && this.pid !== undefined) {
			if (this.pageEvent == null) {
				this.pageEvent = new PageEvent();
				this.pageEvent.length = this.length;
				this.pageEvent.pageIndex = this.pageIndex;
				this.pageEvent.pageSize = this.pageSize;
				console.log("APi before", this.pageEvent.pageIndex);
			}


			this.isLoading = true;
			this.getTestplans(this.pageEvent);
			//this.getProjectDetail();

		}

		this.createForm(null);

	}

	ngOnDestroy() {
		this.preserveSearch.searchTestPlanState = {
			searchValue: this.searchString,
			pageEvent: this.pageEvent,
			results: this.testplanData,
			resultsLength: this.testplanData.length,
		};
	}

	recoverLastSearchValue() {
		const lastSearch = this.preserveSearch.searchTestPlanState;
		if (lastSearch) {
			this.searchString = lastSearch.searchValue;
			this.testplanData = lastSearch.results;
			this.pageEvent = lastSearch.pageEvent;


			//this.resultsLength = lastSearch.resultsLength;
		}


	}

	// getProjectDetail() {

	// 	if (localStorage.getItem("isOwner") == "true") {
	// 		this.isOwner = true
	// 	} else {
	// 		this.isOwner = false
	// 	}

	// 	if (localStorage.getItem("isCompanyAdmin") == "true") {
	// 		this.isCompanyAdmin = true
	// 	} else {
	// 		this.isCompanyAdmin = false
	// 	}

	// 	if (localStorage.getItem("isProjectManager") == "true") {
	// 		this.isProjectManager = true
	// 	} else {
	// 		this.isProjectManager = false
	// 	}

	// 	if (localStorage.getItem("isOther") == "true") {
	// 		this.isOther = true
	// 	} else {
	// 		this.isOther = false
	// 	}

	// 	// this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', true)
	// 	// 	.subscribe((result) => {
	// 	// 		let responseData: any = result;
	// 	// 		if (responseData.statusCode == 200) {
	// 	// 			this.projectData = responseData.result;

	// 	// 			if (this.userData) {
	// 	// 				var isOwner = localStorage.getItem('isOwner');
	// 	// 				if (isOwner == "true") {
	// 	// 					this.isOwner = true;
	// 	// 					this.isCompanyAdmin = false;
	// 	// 					this.isProjectManager = false;
	// 	// 					this.isOther = false;
	// 	// 				} else {
	// 	// 					if (this.userData.role == 1) {
	// 	// 						this.isOwner = false;
	// 	// 						this.isCompanyAdmin = true;
	// 	// 						this.isProjectManager = false;
	// 	// 						this.isOther = false;
	// 	// 					} else if (this.userData.role == 2) {
	// 	// 						if (this.projectData && this.projectData.projectUsersProject) {
	// 	// 							this.projectData.projectUsersProject.forEach(element => {
	// 	// 								if (element.userId == this.userData.id) {
	// 	// 									if (element.userRoleId == 1) {
	// 	// 										this.isProjectManager = true;
	// 	// 										this.isOther = false;
	// 	// 									} else {
	// 	// 										this.isProjectManager = false;
	// 	// 										this.isOther = true;
	// 	// 									}
	// 	// 								}
	// 	// 							});
	// 	// 							this.isOther = false;

	// 	// 						} else {
	// 	// 							this.isOther = true;
	// 	// 						}
	// 	// 						this.isOwner = false;
	// 	// 						this.isCompanyAdmin = false;
	// 	// 					} else {
	// 	// 						this.isOther = true;
	// 	// 						this.isOwner = false;
	// 	// 						this.isProjectManager = false;
	// 	// 						this.isCompanyAdmin = false;
	// 	// 					}
	// 	// 				}

	// 	// 			}
	// 	// 		} else {
	// 	// 			// this.toastr.error(responseData.message);
	// 	// 		}
	// 	// 	}, (err) => {
	// 	// 		console.log(err);
	// 	// 		let errorData: any = err;
	// 	// 		// this.toastr.error(errorData.error.message);
	// 	// 	})
	// }

	getUsers() {
		this.getUserList([2]);
	}

	getUserList(value) {
		this.restService.hitApi('project/projectUsers', { 'projectId': this.pid, escape: value }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.userList = responseData.result;
					this.makeDropDownList();
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	makeDropDownList() {
		this.userDropDownList = [];
		var unassignedUser = new Data('Unassigned', '');
		this.userDropDownList.push(unassignedUser);
		this.getAssignee(unassignedUser);

		if (this.userList.length) {
			this.userList.forEach(element => {
				var data = new Data(element.name, element.userId);
				this.userDropDownList.push(data);
			});
		}
	}

	createForm(data) {
		if (data === null) {
			this.testplanCreateForm = this.formBuilder.group({
				'name': ['', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
				'assigneeName': ['Unassigned'],
				'assignee': [''],
			});
		}

	}

	createTestplan() {
		if (this.testplanCreateForm.invalid) {
			this.testplanCreateFormSubmit = true;
			return;
		} else {
			let apiname = (this.testplanCreateForm.value.releaseId) ? 'testplan/update' : 'testplan/create'
			this.testplanCreateForm.value.projectId = this.pid;
			this.restService.hitApi(apiname, this.testplanCreateForm.value, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.getTestplans(this.pageEvent);
						$('#create_testplan_popup_web').modal('hide');
						this.resetForm();
						this.toastr.success(responseData.message);
						// this.files = [];
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}
	}


	sortData(field) {
		this.sortByField = field;
		this.sortOrder = this.sortOrder == 'asc' ? 'desc' : 'asc';
		this.getTestplans(this.pageEvent);
	}

	searchData() {
		//this.searchdata = {};
		this.searchString = $('#search_name').val();
		if (this.searchString.length >= 2 || this.searchString.length == 0) {
			this.pageEvent = new PageEvent();
			this.pageEvent.length = 0;
			this.pageEvent.pageIndex = 0;
			this.pageEvent.pageSize = 10;

			this.getTestplans(this.pageEvent);
		}
	}

	getTestplans(event?: PageEvent) {
		let postdata = {
			"projectId": this.pid,
			"sort": {
				"column": this.sortByField,
				"direction": this.sortOrder
			},
			"page": event.pageIndex + 1,
			"size": event.pageSize,
			"q": this.searchString
		};
		this.restService.hitApi('testplan/list', postdata, 'post', '', true)
			.subscribe((result) => {

				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.testplanData = (responseData.result.total > 0) ? responseData.result.data : [];
					this.testPlanMainData = responseData.result;

					if (responseData.result.currentPage != 0) {
						this.pageIndex = responseData.result.currentPage - 1;
					}

					this.pageSize = responseData.result.perPage;
					this.length = responseData.result.total;
					this.pageEvent = new PageEvent();
					this.pageEvent.length = this.length;
					this.pageEvent.pageIndex = this.pageIndex;
					this.pageEvent.pageSize = this.pageSize;
					this.checkIfDataHasComes();
					this.setIndexArray();
				} else {
					this.toastr.error(responseData.message);
				}
				this.isLoading = false;
			}, (err) => {
				this.isLoading = false;
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	setIndexArray() {
		for (let i = 0; i < this.testplanData.length; i++) {
			if (this.pageIndex == 0) {
				this.testplanData[i].tableIndex = i + 1;
			} else {
				this.testplanData[i].tableIndex = (this.pageSize * this.pageIndex) + i + 1;
			}
		}
	}

	checkIfDataHasComes() {
		if (this.testplanData.length == 0) {
			if (this.searchString != "" && this.searchString != null) {
				this.isNoSearchResults = true;
				this.isNoData = false;
			} else {
				this.isNoData = true;
				this.isNoSearchResults = false;
			}
		} else {
			this.isNoData = false;
			this.isNoSearchResults = false;
		}
	}

	deleteTestplan(tpid) {
		var index;
		for (var i = 0; i < this.testplanData.length; i++) {
			if (this.testplanData[i].id == tpid) {
				index = i;
				break;
			}
		}
		this.restService.hitApi('testplan/delete', { 'testPlanId': tpid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				$('#delete-record').modal('hide');
				if (responseData.statusCode == 200) {
					if (index != -1) {
						this.testplanData.splice(index, 1);
						if (this.testplanData.length == 0) {
							this.pageEvent = new PageEvent();
							this.pageEvent.length = this.length;
							this.pageEvent.pageIndex = 0;
							this.pageEvent.pageSize = this.pageSize;

							this.getTestplans(this.pageEvent);
						}
						this.testPlanMainData.total = this.testPlanMainData.total - 1;
						this.length = this.testPlanMainData.total;

					}

					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	getAssignee(obj: Data) {
		if (obj) {
			this.testplanCreateForm.get('assigneeName').setValue(obj.name);
			this.testplanCreateForm.get('assignee').setValue(obj.value);
		}

	}

	resetForm() {
		this.testplanCreateForm.reset();
		this.isCreateDialogShow = false;
	}
	goToTestPlanDeatils(entityId) {
		let navigationExtras: NavigationExtras = {
			queryParams: {
				backUrl: '/testplan'

			}
		};
		this.router.navigate(['/testplan/' + entityId], navigationExtras);

	}


}

