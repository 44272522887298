import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';

// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { PreservedSearchStateService } from '../services/search/preserved-search-state.service';


// import { MatDialog } from '@angular/material';
// import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
declare const $: any;
@Component({
	selector: 'app-testcases',
	templateUrl: './testcases.component.html',
	styleUrls: ['./testcases.component.scss'],
})

export class TestcasesComponent implements OnInit, OnDestroy {
	userData: any = JSON.parse(localStorage.getItem("userData"));
	testcaseCreateForm: FormGroup;
	testcaseData: any = [];
	pid: any;
	selectedTestcase: any;
	userList: any;
	projectData: any;
	sortOrder: any = 'asc';
	sortByField: any = 'id'
	searchdata: any;
	searchString: string = "";
	isShowMore: boolean = false;

	pageEvent: PageEvent;
	datasource: null;
	pageIndex: number = 0;
	pageSize: number = 10;
	length: number = 0;
	isLoading: boolean = false;
	isCreateDialogShow: boolean = true;

	isNoSearchResults: boolean = false;
	isNoData: boolean = false;
	total: number = 0;
	showForm: boolean = true;


	constructor(
		private restService: RestService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private preserveSearch: PreservedSearchStateService) {

		this.pid = localStorage.getItem('projectSelected');

	}

	ngOnInit() {

		this.recoverLastSearchValue();

		if (this.pid && this.pid !== null && this.pid !== undefined) {
			if (this.pageEvent == null) {
				this.pageEvent = new PageEvent();
				this.pageEvent.length = this.length;
				this.pageEvent.pageIndex = this.pageIndex;
				this.pageEvent.pageSize = this.pageSize;
				console.log("APi before", this.pageEvent.pageIndex);
			}

			this.isLoading = true;
			this.gettestcases(this.pageEvent);
			this.getProjectDetail();
			this.getUserList();
		}

		this.createForm(null);

		$(document).ready(() => {
			let that = this;
			$('.htmlEditor').summernote({
				height: '150px',
				focus: true,
				disableDragAndDrop: true,
				dialogsInBody: true,
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
				callbacks: {
					onPaste: function (e) {
						var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
						e.preventDefault();
						document.execCommand('insertText', false, bufferText);
					},
					onImageUpload: function (data) {
						data.pop();
					}
				}
			});

			$('#summernote').summernote({
				dialogsInBody: true
			});

			/*$('.htmlEditor').summernote({
				height: '100px',
				focus: true
			});*/
		});
		// $('.moreless-button').click(function () {
		// 	$('.moretext').slideToggle();
		// 	if ($('.moreless-button').text() == "Show Less") {
		// 		$(this).text("Show more fields")
		// 	} else {
		// 		$(this).text("Show Less")
		// 	}
		// });

	}

	ngOnDestroy() {
		this.preserveSearch.searchTestCaseState = {
			searchValue: this.searchString,
			pageEvent: this.pageEvent,
			results: this.testcaseData,
			resultsLength: this.testcaseData.length,
		};
	}

	recoverLastSearchValue() {
		const lastSearch = this.preserveSearch.searchTestCaseState;
		if (lastSearch) {
			this.searchString = lastSearch.searchValue;
			this.testcaseData = lastSearch.results;
			this.pageEvent = lastSearch.pageEvent;


			//this.resultsLength = lastSearch.resultsLength;
		}


	}


	getProjectDetail() {
		// this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', true)
		// 	.subscribe((result) => {
		// 		let responseData: any = result;
		// 		if (responseData.statusCode == 200) {
		// 			this.projectData = responseData.result;

		// 		} else {
		// 			// this.toastr.error(responseData.message);
		// 		}
		// 	}, (err) => {
		// 		console.log(err);
		// 		let errorData: any = err;
		// 		this.toastr.error('Error', errorData.error.message);
		// 		// this.toastr.error(errorData.error.message);
		// 	})
	}

	getUserList() {
		// this.restService.hitApi('project/userList?projectId=' + this.pid + '&isAvailable=0', {}, 'get', '', false)
		// 	.subscribe((result) => {
		// 		let responseData: any = result;
		// 		if (responseData.statusCode == 200) {
		// 			this.userList = responseData.result.data;
		// 		} else {
		// 			// this.toastr.error(responseData.message);
		// 		}
		// 	}, (err) => {
		// 		console.log(err);
		// 		let errorData: any = err;
		// 		this.toastr.error('Error', errorData.error.message);
		// 		// this.toastr.error(errorData.error.message);
		// 	})
	}

	createForm(data) {
		if (data === null) {
			this.testcaseCreateForm = this.formBuilder.group({
				'name': ['', [Validators.required, Validators.maxLength(150)]],
				'description': [''],
				'preConditions': [''],
				'steps': [''],
				'testData': [''],
				'expectedResults': [''],

			});
		}

	}

	createTestcase() {
		let apiname = (this.testcaseCreateForm.value.releaseId) ? 'testCase/update' : 'testCase/create'
		this.testcaseCreateForm.value.projectId = this.pid;
		this.testcaseCreateForm.value.description = $('#testcase_description').val();
		this.testcaseCreateForm.value.preConditions = $('#testcase_preconditions').val();
		this.testcaseCreateForm.value.steps = $('#testcase_steps').val();
		this.testcaseCreateForm.value.testData = $('#testcase_testdata').val();
		this.testcaseCreateForm.value.expectedResults = $('#testcase_expectedresult').val();
		if (this.testcaseCreateForm.invalid) {
			return false;
		} else {
			this.restService.hitApi(apiname, this.testcaseCreateForm.value, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					console.error('responseData', responseData);
					if (responseData.statusCode == 200) {
						this.closeModal();
						this.toastr.success(responseData.message);
						this.gettestcases(this.pageEvent);

					} else {
						this.toastr.error('Error', responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					console.log(errorData);
					this.toastr.error('Error', errorData.error.message);
					//this.toastr.error(errorData.error.message);
				})
		}
	}


	sortData(field) {
		this.sortByField = field;
		this.sortOrder = this.sortOrder == 'asc' ? 'desc' : 'asc';
		this.gettestcases(this.pageEvent);
	}

	searchData() {

		this.searchString = $('#search_name').val();
		console.log("I am Searching");
		// this.searchdata = {};

		// if($('#search_id').val()!=""){
		// 	this.searchdata.id = $('#search_id').val().replace(/TC/i,'');
		// }
		// if($('#search_name').val()!=""){
		// 	this.searchdata.name = $('#search_name').val();
		// }
		if (this.searchString.length >= 2 || this.searchString.length == 0) {
			this.pageEvent = new PageEvent();
			this.pageEvent.length = 0;
			this.pageEvent.pageIndex = 0;
			this.pageEvent.pageSize = 10;

			this.gettestcases(this.pageEvent);
		}
	}

	gettestcases(event?: PageEvent) {
		let postdata = {
			"projectId": this.pid,
			"sort": {
				"column": this.sortByField,
				"direction": this.sortOrder
			},
			"page": event.pageIndex + 1,
			"size": event.pageSize,
			"q": this.searchString
		};
		console.log()
		this.restService.hitApi('testCase/list', postdata, 'post', '', true)
			.subscribe((result) => {
				this.isLoading = false;
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.total = (responseData.result.total > 0) ? responseData.result.total : 0;
					this.testcaseData = (responseData.result.total > 0) ? responseData.result.data : [];
					if (responseData.result.currentPage != 0) {
						this.pageIndex = responseData.result.currentPage - 1;
					}
					this.pageSize = responseData.result.perPage;
					this.length = responseData.result.total;
					this.pageEvent = new PageEvent();
					this.pageEvent.length = this.length;
					this.pageEvent.pageIndex = this.pageIndex;
					this.pageEvent.pageSize = this.pageSize;

					console.log("pageSize:-" + this.pageSize + "," + "length:-" + this.length);
					this.checkIfDataHasComes();
					this.setIndexArray();
				} else {
					this.toastr.error('Error', responseData.message);
					//this.toastr.error(responseData.message);
				}
			}, (err) => {
				this.isLoading = false;
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	setIndexArray() {
		for (let i = 0; i < this.testcaseData.length; i++) {
			if (this.pageIndex == 0) {
				this.testcaseData[i].tableIndex = i + 1;
			} else {
				this.testcaseData[i].tableIndex = (this.pageSize * this.pageIndex) + i + 1;
			}
		}
	}

	checkIfDataHasComes() {
		if (this.testcaseData.length == 0) {
			if (this.searchString != "" && this.searchString != null) {
				this.isNoSearchResults = true;
				this.isNoData = false;
			} else {
				this.isNoData = true;
				this.isNoSearchResults = false;
			}
		} else {
			this.isNoData = false;
			this.isNoSearchResults = false;
		}
	}

	deleteTestcase(tpid) {
		var index;
		for (var i = 0; i < this.testcaseData.length; i++) {
			if (this.testcaseData[i].id == tpid) {
				index = i;
				break;
			}
		}
		$('#delete-record').modal('hide');
		this.restService.hitApi('testCase/delete', { 'testCaseId': tpid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					if (index != -1) {
						this.testcaseData.splice(index, 1);
						if (this.testcaseData.length == 0) {
							this.pageEvent = new PageEvent();
							this.pageEvent.length = this.length;
							this.pageEvent.pageIndex = 0;
							this.pageEvent.pageSize = this.pageSize;

							this.gettestcases(this.pageEvent);
						}
						this.total = this.total - 1;
						this.length = this.total;
					}
					//$('#delete-record').modal('hide');
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	showMore() {
		this.isShowMore = true;
		$('.moretext').slideDown();

	}

	showLess() {
		this.isShowMore = false;
		$('.moretext').slideUp();

	}

	closeModal() {
		$('#create_testcase_popup_web').modal('hide');
		this.isCreateDialogShow = false;
		setTimeout(() => {
			this.resetForm();
			this.isCreateDialogShow = true;
			this.setHeightOfSummarNote();
		});
	}
	resetForm() {
		this.testcaseCreateForm.reset();
		$('#testcase_description').summernote("reset");
		$('#testcase_preconditions').summernote("reset");
		$('#testcase_steps').summernote("reset");
		$('#testcase_testdata').summernote("reset");
		$('#testcase_expectedresult').summernote("reset");
		this.showLess()

		// this.showForm = false;
		// setTimeout(() => {

		// 	this.showForm = true;
		// 	this.setHeightOfSummarNote();
		// });

	}

	setHeightOfSummarNote() {
		// $('.htmlEditor').summernote({
		// 	dialogsInBody: true
		// 	//,airMode: true
		// });
		$(document).ready(function () {
			$('.htmlEditor').summernote({
				height: '150px',
				focus: true,
				disableDragAndDrop: true,
				dialogsInBody: true,
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
				callbacks: {
					onPaste: function (e) {
						var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
						e.preventDefault();
						document.execCommand('insertText', false, bufferText);
					},
					onImageUpload: function (data) {
						data.pop();
					}
				}
			});

			$('#summernote').summernote({
				dialogsInBody: true
			});
		});
	}



}

