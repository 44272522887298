import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {NgForm} from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';

// Services
import { RestService } from '../services/rest/rest.service';
declare const $:any;
@Component({
	selector: 'app-discussion-board',
	templateUrl: './discussion-board.component.html',
	styleUrls: ['./discussion-board.component.scss'],
})

export class DiscussionBoardComponent implements OnInit {
	discussionData : any;
	userData : any;
	projectData : any;
	pid : any;
	companiesData : any;
	cur_company_id : any;
	isLoading: boolean = false;

	constructor(
		private restService : RestService, 
		private toastr: ToastrService,
		private route : ActivatedRoute,
		private router : Router) { 
			this.userData = JSON.parse(localStorage.getItem("userData"));
			// this.pid = JSON.parse(localStorage.getItem("projectData")).id;
			// this.route.params.subscribe(params => {
			// 	this.pid = params['pid'];
			// 	if (this.pid && this.pid != null && this.pid !== undefined) {
			// 		this.getProjectDetail();
			// 	}
			// });
		}
	
	ngOnInit() {
		if (!localStorage.getItem('userData')) {
			this.router.navigate(['/login']);
		}

		// this.restService.getCompanies();
		this.companiesData = JSON.parse(localStorage.getItem('companyData'));
		this.cur_company_id = localStorage.getItem('company_id');
		
		this.isLoading = true;
		this.getProjectsList();

		$(document).ready(function() {
			$('#list').click(function(event){event.preventDefault();$('#products .item').addClass('list-group-item');});
			$('#grid').click(function(event){event.preventDefault();$('#products .item').removeClass('list-group-item');$('#products .item').addClass('grid-group-item');});
		});
	}

	getProjectsList(){
		
		this.restService.hitApi('discussion/projects',{},'get','',true)
		.subscribe((result)=>{
			
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.projectData = responseData.result.data;
			}else{
				// this.toastr.error(responseData.message);
			}
			this.isLoading = false;
		},(err)=>{
			this.isLoading = false;
			console.log(err);
			let errorData : any = err;
			// this.toastr.error(errorData.error.message);
		})
	}

	
	changeFavouriteStatus(pid,value){
		
		let status : any = value==1?0:1;
		this.restService.hitApi('discussion/favourite',{'projectId':pid,'favStatus':status},'post','',true)
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.toastr.success(responseData.message);
				this.getProjectsList();
			}else{
				this.toastr.error(responseData.message);
			}
		},(err)=>{
			console.log(err);
			let errorData : any = err;
			this.toastr.error(errorData.error.message);
		})
	}

	// getProjectDetail(){
	// 	this.restService.hitApi('project/details',{'projectId':this.pid},'post','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.projectData = responseData.result;
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }

	// getProjectDiscussion(){
	// 	this.restService.hitApi('projectThread/list',{'projectId':this.pid},'post','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.discussionData = responseData.result;
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }
}

