import { setValue } from '@syncfusion/ej2-base';
import { Component, OnInit } from '@angular/core';
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Data } from '../ui-components/select-dropdown/Data';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateConverterService } from '../services/date-formatter/date-converter.service';
import * as moment from 'moment';
import { E } from 'node_modules1111/@angular/cdk/keycodes';
import { AST_False } from 'node_modules1111/terser/tools/terser';
import { forkJoin } from 'rxjs';



declare const $: any;

export const MY_FORMATS = {
	parse: {
		dateInput: 'LL'
	},
	display: {
		dateInput: 'DD-MM-YYYY',
		monthYearLabel: 'YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'YYYY'
	}
};


@Component({
	selector: 'app-timesheet',
	templateUrl: './timesheet.component.html',
	styleUrls: ['./timesheet.component.scss'],
	providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
	{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
	]
})
export class TimesheetComponent implements OnInit {

	taskCreateForm: FormGroup;
	projectList: any = [];
	taskList: any = [];
	filteredProjects: any = [];
	projectDropDownList: any = [];
	searchProject: string = "";
	searchTask: string = "";
	searchSelectTask: string = "";
	isCreateDialogShow: boolean = true;
	taskCreateFormSubmit: boolean = false;
	addTimeSheetForm: FormGroup;
	filteredTask: any[] = [];
	selectedProjectId: any;
	perPage: any = 20;
	taskHashMap: Map<String, any[]> = new Map();
	dayHashMap: Map<any, String> = new Map();
	taskDropdownList: any[] = [];
	isDropdownShown: boolean = false;
	todayDate = moment(new Date()).format('DD-MM-YYYY')
	maskTime = [/[1-9]/, /\d/, ':', /\d/, /\d/]
	timePeriodModel;
	comment;
	isAddTimeFormSubmitted: boolean = false;
	sortOrder: any = 'desc';
	sortByField: any = 'id'
	currentRunningTask: any;
	selectedTimeSheet: any
	isAddTimeFormSubmit: boolean = false;
	timesheetHashMap: Map<any, any> = new Map();


	constructor(private restService: RestService,
		private toastr: ToastrService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private dateFormaterService: DateConverterService) {

		this.getProjectList()
		this.getTaskList();
		this.createTaskForm()
		this.createAddTimeForm();

		this.getTaskLogList()
		this.getRunningTasDetails();
		// this.selectedProjectId = localStorage.getItem('projectSelected') ? localStorage.getItem('projectSelected') : null;
		// if (this.selectedProjectId) {

		// }
		this.dayHashMap = this.dateFormaterService.getDayHashMap();

	}

	ngOnInit() {
		$(document).ready(function () {
			$("#hrm").click(function (e) {
				// Do something
				e.stopPropagation();
			});
		})

		/*function toggleIcon(e) {
			$(e.target)
				.prev('.panel-heading')
				.find(".more-less")
				.toggleClass('glyphicon-plus glyphicon-minus');
		}
		$('.panel-group').on('hidden.bs.collapse', toggleIcon);
		$('.panel-group').on('shown.bs.collapse', toggleIcon);*/

		/*$(document).ready(function(){
		  $('.dropdown').click(function(e){
			$(this).find('.dropdown-menu').toggleClass('open');
			$($(e.target).find('.down-caret').toggleClass('open-caret'));
			e.preventDefault();
			e.stopPropagation();
			$(document).click(function(){
			  $('.dropdown-menu').removeClass('open');
			  $('.down-caret').removeClass('open-caret');
			});
		  });
		});*/

	}

	createTaskForm() {
		this.taskCreateForm = this.formBuilder.group({
			'title': ['', [Validators.required, Validators.minLength(1), Validators.maxLength(150)]],
			'description': [''],
			'assignee': [''],
			'sprintId': [''],
			'estHours': [''],
			'estMins': [''],
			'projectId': ['', [Validators.required]],
			'projectName': ['', [Validators.required]],
			'assigneeName': ['Unassigned', ''],
			'sprintName': ['', ''],
			'attachments': []
		});
	}
	createAddTimeForm() {
		this.addTimeSheetForm?.reset();
		this.addTimeSheetForm = this.formBuilder.group({
			'id': [''],
			'projectId': ['', [Validators.required]],
			'projectName': ['', []],
			'taskId': ['', [Validators.required]],
			'taskName': ['', []],
			'sprintId': ['', []],
			'sprintName': ['', []],
			'logDateObj': ['', [Validators.required]],
			'logDate': ['', [Validators.required]],
			'logHours': ['', [Validators.required]],
			'logMinute': ['', [Validators.required]],
			'comment': ['', []],


		});
	}

	createTask() {
		console.log("form data: ", this.taskCreateForm)
		let apiname = 'projectTask/create';
		if (this.taskCreateForm.invalid) {
			this.taskCreateFormSubmit = true;
			return;
		} else {
			this.restService.hitApi(apiname, this.taskCreateForm.value, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.closeModal();
						this.toastr.success(responseData.message);
						// this.setAutoSuggest(null);
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}
	}

	createTimeForm() {
		this.taskCreateForm = this.formBuilder.group({
			'title': ['', [Validators.required, Validators.minLength(1), Validators.maxLength(150)]],
			'description': [''],
			'assignee': [''],
			'sprintId': [''],
			'estHours': [''],
			'estMins': [''],
			'projectId': ['', [Validators.required]],
			'projectName': ['', [Validators.required]],
			'assigneeName': ['Unassigned', ''],
			'sprintName': ['', ''],
			'attachments': []
		});
	}

	closeModal() {
		$('#timesheet_create_task').modal('hide');
		$('#timesheet_add_recent_task').modal('hide');
		this.isCreateDialogShow = false;
		this.isAddTimeFormSubmit = false;
		this.taskCreateFormSubmit = false;
		setTimeout(() => {
			this.taskCreateForm.reset();
			this.isCreateDialogShow = true;
			this.addTimeSheetForm.reset();
			this.addTimeSheetForm.get('logDate').setValue('');
			this.addTimeSheetForm.get('logDateObj').setValue('');

			this.filteredProjects.forEach(element => {
				element.checked = false;
			});

			this.filteredTask.forEach(element => {
				element.checked = false;
			});
		});

	}

	getProject(obj: Data) {
		if (obj) {
			this.taskCreateForm.get('projectName').setValue(obj.name);
			this.taskCreateForm.get('projectId').setValue(obj.value);
		} else {
			this.taskCreateForm.get('projectName').setValue(null);
			this.taskCreateForm.get('projectId').setValue(null);

		}

		console.log("this.obj, ", obj)
		console.log("this.taskCreateForm.value, ", this.taskCreateForm.value)
	}

	makeProjectDropDownList() {
		this.projectDropDownList = [];
		if (this.filteredProjects.length) {
			this.filteredProjects.forEach(element => {
				var data = new Data(element.name, element.id);
				this.projectDropDownList.push(data);
			});
		}
	}

	searchProjects() {
		this.searchProject = $('#search_project').val();

		console.log("	this.searchProject	this.searchProject: ", this.searchProject)
		this.filteredProjects = this.projectList.filter(function (element) {
			return element.name.toLowerCase() == this.searchProject.toLowerCase();
		});

	}
	searchTasks() {
		this.searchTask = $('#search_task').val();

		this.getTaskList()

	}
	searchTaskProjects() {
		this.searchProject = $('#search_task_project').val();

		console.log("	this.searchProject	this.searchProject: ", this.searchProject)
		this.filteredProjects = this.projectList.filter(function (element) {
			return element.name.toLowerCase() == this.searchProject.toLowerCase();
		});

	}
	searchSelectTasks($event) {
		if ($event) {
			$event.stopPropagation();
		}
		this.searchTask = $('#search_select_task').val();

		var params = {
			title: this.searchTask
		};

		this.restService.hitApi('task-log/task-list', params, 'get', '', true)
			.subscribe((result) => {
				this.isDropdownShown = true;
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.taskDropdownList = responseData.result;
					if (this.taskDropdownList && this.taskDropdownList.length > 0) {
						if (this.currentRunningTask) {
							this.taskDropdownList.forEach(el => {
								if (el.id == this.currentRunningTask.taskId) {
									this.setSelectedTask(el, $event);
								}
							})
						}
					}
				} else {
					// this.toastr.error(responseData.replyMessage);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			});

	}


	getProjectList() {
		this.restService.hitApi('project/project-list', {}, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.projectList = responseData.result;
					console.log('projectList', this.projectList);
					this.filteredProjects = responseData.result;
					this.makeProjectDropDownList();
				} else {
					// this.toastr.error(responseData.replyMessage);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			});
	}

	getTaskList() {
		var params = {
			title: this.searchTask
		};
		if (this.addTimeSheetForm?.get('projectId')?.value != undefined && this.addTimeSheetForm?.get('projectId')?.value != null) {
			params['taskProjectId'] = this.addTimeSheetForm?.get('projectId')?.value;
		}
		this.restService.hitApi('task-log/task-list', params, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.taskList = responseData.result;
					this.filteredTask = responseData.result;
				} else {
					// this.toastr.error(responseData.replyMessage);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			});
	}

	getTaskLogList() {

		var params = {
			column: this.sortByField,
			direction: this.sortOrder
		};

		this.restService.hitApi('task-log', params, 'get', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.taskHashMap = new Map();
					this.timesheetHashMap = new Map();
					this.makeListDataHashMap(responseData.result);
				} else {
					// this.toastr.error(responseData.replyMessage);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			});
	}
	makeListDataHashMap(dataMap: Map<any, any>) {
		if (dataMap) {
			var taskHashMap = new Map();
			taskHashMap.set('This Week', new Map())
			taskHashMap.set('Last Week', new Map())

			// For this week
			var startOfWeek = moment().startOf('week').toDate();
			var endOfWeek = moment().endOf('week').toDate();
			this.timesheetHashMap.set({ startDate: startOfWeek, endDate: endOfWeek }, []);

			// 	//For Last Week
			var lastweekFrom = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() - 7);
			var lastweekTo = new Date(lastweekFrom.getFullYear(), lastweekFrom.getMonth(), lastweekFrom.getDate() + 7);

			this.timesheetHashMap.set({ startDate: lastweekFrom, endDate: lastweekTo }, []);

			var otherDatesMap = new Map();

			Object.keys(dataMap).forEach(function (key: any, index) {

				var array = dataMap[key];

				if (array && array.length > 0) {

					var logDate: any = Date.parse(array[0].logDate);
					if (logDate && logDate >= startOfWeek && logDate < endOfWeek) {


						if (taskHashMap.has('This Week')) {
							var innerMap = taskHashMap.get('This Week');
							innerMap.set(logDate, array)
						} else {
							var innerMap: any = new Map();
							innerMap.set(logDate, array)
							taskHashMap.set('This Week', innerMap)
						}
					} else if (logDate && logDate >= lastweekFrom && logDate < lastweekTo) {
						if (taskHashMap.has('Last Week')) {
							var innerMap = taskHashMap.get('Last Week');
							innerMap.set(logDate, array)
							var values = Array.from(innerMap.values());
							console.log(values)

						} else {
							var innerMap: any = new Map();
							innerMap.set(logDate, array)
							taskHashMap.set('Last Week', innerMap)
						}
					} else {
						otherDatesMap.set(logDate, array);
					}
				}


			});
			console.log("otherDatesMap", otherDatesMap);
			if (otherDatesMap && otherDatesMap.size > 0) {
				let array = []
				otherDatesMap.forEach((value, key) => {
					array.push(value[0]);
				})


				if (array && array.length > 0) {
					array.sort(function (a: any, b: any) {
						var c: any = new Date(a.logDate);
						var d: any = new Date(b.logDate);
						return c - d
					});
					for (var i = 0; i < array.length; i++) {

						var weekNumber = moment(moment(array[i].logDate).toDate(), "DD-MM-YYYY").isoWeek();
						var d = (1 + (weekNumber - 1) * 7); // 1st of January + 7 days for each week
						var date1 = new Date(moment(array[i].logDate).toDate().getFullYear(), 0, (d + 1));
						var date2 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate() + 6)

						var dateRange = moment(date1).format('MMM DD') + ' - ' + moment(date2).format('MMM DD')
						if (!taskHashMap.has(dateRange)) {
							var innerMap: any = new Map();
							taskHashMap.set(dateRange, innerMap)
							this.timesheetHashMap.set({ startDate: date1, endDate: date2 }, []);
							var logDate: any = Date.parse(array[i].logDate);
							var items = [array[i]];
							innerMap.set(logDate, items);

						} else {
							var logDate: any = Date.parse(array[i].logDate);
							if (!taskHashMap.get(dateRange).has(logDate)) {
								var items: any[] = [array[i]]
								taskHashMap.get(dateRange).set(logDate, items);

							} else {
								taskHashMap.get(dateRange).get(logDate).push(array[i]);
							}

							// var logDate: any = Date.parse(array[i].logDate);
							// if (taskHashMap.get(dateRange).has(logDate)){
							// 	taskHashMap.get(dateRange).get(logDate).push(array[i])
							// }else{

							// }
							// var innerMap: any = new Map();
							// taskHashMap.set(dateRange, innerMap)

							// if (taskHashMap.get(dateRange).has(logDate)) {
							// 	taskHashMap.get(dateRange).get(logDate).push(array[i])
							// } else {
							// 	var items = [array[i]];
							// 	taskHashMap.get(dateRange).set(logDate, items)
							// }
						}

					}
					console.log('taskHashMap', taskHashMap);

					console.log('timesheetHashMap', this.timesheetHashMap);



				}




			}
			// if (otherDatesMap && otherDatesMap.size > 0) {

			// 	var mapAsc = new Map([...otherDatesMap.entries()].sort());
			// 	console.log(mapAsc)
			// 	var startKeyByIndex = Array.from(mapAsc.keys())[0]; // Get key by index. I selected "0".
			// 	var firstArray = otherDatesMap.get(startKeyByIndex);
			// 	var secondArray = null;
			// 	if (mapAsc.size > 1) {
			// 		var endKeyByIndex = Array.from(mapAsc.keys())[mapAsc.size - 1]; // Get key by index. I selected "0".
			// 		secondArray = mapAsc.get(endKeyByIndex)
			// 	}
			// 	var key = ""
			// 	if (firstArray && firstArray.length > 0) {
			// 		var logDateStr = firstArray[0]?.logDate;
			// 		if (logDateStr) {
			// 			var logDate = new Date(logDateStr);
			// 			key = key + moment(logDate).format('MMM DD');
			// 			if (secondArray && secondArray.length > 0) {
			// 				var endLogDateStr = secondArray[0]?.logDate;
			// 				if (endLogDateStr) {
			// 					var endLogDate = new Date(endLogDateStr);
			// 					key = key + ' - ' + moment(endLogDate).format('MMM DD');
			// 				}
			// 			}
			// 		}
			// 		taskHashMap.set(key, mapAsc);

			// 	}


			// }

			var hashmapArray = Array.from(taskHashMap.keys())
			this.taskHashMap = taskHashMap

			//this.taskHashMap = taskHashMap;
			console.log('this.taskHashMap', this.taskHashMap);
			this.makeTimeSheetTabData();
		}
		// if (list && list.length > 0) {

		// 	var thisWeekList = [];
		// 	var lastWeekList = [];
		// 	var weekList = [];

		// 	// For this week
		// 	var startOfWeek = moment().startOf('week').toDate();
		// 	var endOfWeek = moment().endOf('week').toDate();

		// 	//For Last Week
		// 	var today = new Date();
		// 	var lastweekFrom = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() - 7);
		// 	var lastweekTo = new Date(lastweekFrom.getFullYear(), lastweekFrom.getMonth(), lastweekFrom.getDate() + 7);

		// 	console.log("start", startOfWeek)
		// 	console.log("end", endOfWeek)
		// 	console.log("last week from", lastweekFrom)
		// 	console.log("last week to", lastweekTo)

		// 	list.forEach(element => {
		// 		if (element.logDate) {
		// 			var logDate: any = Date.parse(element.logDate);
		// 			if (logDate && logDate >= startOfWeek && logDate < endOfWeek) {
		// 				thisWeekList.push(element);
		// 			} else if (logDate && logDate >= lastweekFrom && logDate < lastweekTo) {
		// 				lastWeekList.push(element);
		// 			} else {
		// 				weekList.push(element)
		// 			}
		// 		}
		// 	});


		// 	console.log("thisWeekList", thisWeekList)
		// 	console.log("lastweekList", lastWeekList)
		// 	console.log("weekList", weekList)

		// 	var today = new Date();
		// 	var yesterday = today.setDate(today.getDate() - 1);

		// 	var weekListInnerHashmap = new Map<Object, any[]>();
		// 	if (thisWeekList && thisWeekList.length > 0) {
		// 		thisWeekList.forEach(obj => {

		// 		})
		// 	}

		// }




	}
	makeTimeSheetTabData() {
		if (this.timesheetHashMap && this.timesheetHashMap.size > 0) {
			let addApiRequests: any[] = [];

			for (const [key, value] of this.timesheetHashMap.entries()) {
				var startDate = moment(key['startDate']).format('YYYY-MM-DD')
				var endDate = moment(key['endDate']).format('YYYY-MM-DD')
				let request = this.restService.hitApi('task-log/time-sheet-date', { startDate: startDate, endDate: endDate }, 'post', '', true);
				addApiRequests.push(request);
			}


			forkJoin(addApiRequests).subscribe((response: any) => {
				console.log("response of image>>>", response)
				if (response.length > 0) {
					response.forEach(element => {
						if (element?.result.length > 0) {
							element.result.forEach(element2 => {
								element2['days'] = new Map();
								element2['total'] = 0;
								for (const [key, value] of this.timesheetHashMap.entries()) {
									var logDate = new Date(element2.timeSheet[0]?.logDate);
									logDate.setHours(0, 0, 0, 0)
									if (logDate.getTime() >= key?.startDate.getTime() && logDate.getTime() <= key?.endDate.getTime()) {
										if (element2.timeSheet && element2.timeSheet.length > 0) {
											element2.timeSheet.forEach(timeSheet => {
												var logDate = new Date(timeSheet?.logDate)
												var day = logDate.getDay();
												if (element2['days'].has(day)) {
													var total = element2['days'].get(day) + timeSheet.logMinute;
													element2['days'].delete(day)
													element2['days'].set(day, total);
												} else {
													element2['days'].set(day, timeSheet.logMinute)
												}

												element2['total'] = element2['total'] + timeSheet.logMinute;
											});




										}
										value.push(element2);
									}



								}
							});
						}
					});
					for (const [key, value] of this.timesheetHashMap.entries()) {
						if (value && value.length > 0) {
							var totalTiming = 0;
							value?.forEach(element => {
								totalTiming = totalTiming + element.total;
							});

							value[0]['totalTiming'] = this.getHoursFromMinuite(totalTiming);
						}

					}
					console.log("this.timesheetHashMap ", this.timesheetHashMap);


				}
			}, error => {
				//this.loading = false;
			}, () => {

				// if (deletedAttachments.length > 0) {
				// 	//DeletedFile
				// 	this.deleteFiles(deletedAttachments);

				// } else {
				// 	this.saveProfile();
				// }
			})



		}

	}

	getTimeSheetKeyFormat(key) {
		var startOfWeek = moment().startOf('week').toDate();
		var endOfWeek = moment().endOf('week').toDate();

		var lastweekFrom = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() - 7);
		var lastweekTo = new Date(lastweekFrom.getFullYear(), lastweekFrom.getMonth(), lastweekFrom.getDate() + 7);

		if ((key.startDate >= startOfWeek && key.startDate <= endOfWeek)) {
			return 'This Week'
		} else if ((key.startDate >= lastweekFrom && key.startDate <= lastweekTo)) {
			return 'Last Week'
		} else {
			return moment(key.startDate).format('MMM DD') + ' - ' + moment(key.endDate).format('MMM DD')
		}



	}

	getDateRangeOfWeek(weekNo, year) {
		var d = (1 + (weekNo - 1) * 7); // 1st of January + 7 days for each week
		var date1 = new Date(year, 0, (d + 1));
		var date2 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate() + 6)
		return moment(date1).format('MMM DD') + ' - ' + moment(date2).format('MMM DD')
	};
	getTaskListFromhashMap(dataMap: Map<any, any>) {
		var array = []
		dataMap.forEach((values, keys) => {
			values.forEach(element => {
				if (element.logDate) {
					element['logDateObj'] = new Date(element.logDate);
				}
				array.push(element);
			});

		})
		return array;
	}
	getOuterTotalTime(outerMap) {
		var totalMinutes = 0;
		if (outerMap) {
			console.log("outerMap", outerMap);

			outerMap.forEach((values, keys) => {
				if (values && values.length > 0) {
					values.forEach(element => {
						if (element.logMinute) {
							totalMinutes = totalMinutes + element.logMinute
						}
					});

				}
			})
			return this.getHoursFromMinuite(totalMinutes)

			// for (const [key, value] of outerMap.entr) {
			// 	var array: any = value;
			// 	if (array && array.length > 0) {
			// 		array.forEach(element => {
			// 			if (element.logMinute) {
			// 				totalMinutes = totalMinutes + element.logMinute
			// 			}
			// 		});
			// 		return this.getHoursFromMinuite(totalMinutes)
			// 	}
			// }

		}

	}
	getOuterTotalTimeByArray(values) {
		var totalMinutes = 0;
		if (values && values.length > 0) {
			values.forEach(element => {
				if (element.logMinute) {
					totalMinutes = totalMinutes + element.logMinute
				}
			});
			return this.getHoursFromMinuite(totalMinutes)


		}
	}
	getOuterDateByArray(values) {
		var strDate = '';

		if (values && values.length > 0) {

			if (values[0].logDate) {
				var date = new Date(values[0].logDate);
				if (this.isToday(date)) {
					strDate = moment(date).format('MMM DD')

				} else if (this.isYesterDay(date)) {
					strDate = moment(date).format('MMM DD')
				} else {
					strDate = moment(date).format('MMM DD')
				}
			}
		}
		return strDate;

	}

	getOuterDayByArray(values) {
		var strDay = '';

		if (values && values.length > 0) {
			if (values[0].logDate) {

				var date = new Date(values[0].logDate);
				if (this.isToday(date)) {
					strDay = 'TODAY';

				} else if (this.isYesterDay(date)) {
					strDay = 'YESTERDAY';
				} else {
					const dayOfWeek = new Date(values[0].logDate).getDay();
					strDay = isNaN(dayOfWeek) ? null :
						['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek];
				}
			}

		}
		return strDay;

	}

	isToday = (someDate: Date) => {
		const today = new Date()
		return someDate.getDate() == today.getDate() &&
			someDate.getMonth() == today.getMonth() &&
			someDate.getFullYear() == today.getFullYear()
	}
	isYesterDay = (someDate: Date) => {
		var yestetrdayDate = new Date();
		yestetrdayDate.setDate(yestetrdayDate.getDate() - 1);
		return someDate.getDate() == yestetrdayDate.getDate() &&
			someDate.getMonth() == yestetrdayDate.getMonth() &&
			someDate.getFullYear() == yestetrdayDate.getFullYear()
	}

	getHoursFromMinuite(totalMinutes) {
		var strTotalTime = '';
		if (totalMinutes > 0) {
			var hours = Math.floor(totalMinutes / 60);
			var minutes = totalMinutes % 60;

			strTotalTime = strTotalTime + hours.toString() + 'h' + ' ' + minutes.toString() + 'm'
		} else {
			strTotalTime = '0h 0m'
		}
		return strTotalTime;
	}
	setProject(project) {
		if (project) {

			this.addTimeSheetForm.get('projectId').setValue(project?.id)
			this.addTimeSheetForm.get('projectName').setValue(project?.name)

			this.filteredProjects.forEach(element => {
				if (element.id == project?.id) {
					element.checked = true;
				} else {
					element.checked = false;
				}
			});

			this.getTaskList();

		}
	}
	setTaskProject(project) {
		if (project) {

			this.taskCreateForm.get('projectId').setValue(project?.id)
			this.taskCreateForm.get('projectName').setValue(project?.name)

			this.filteredProjects.forEach(element => {
				if (element.id == project?.id) {
					element.checked = true;
				} else {
					element.checked = false;
				}
			});


		}
	}

	setTask(task) {
		if (task) {

			this.addTimeSheetForm.get('taskId').setValue(task?.id)
			this.addTimeSheetForm.get('taskName').setValue(task?.title)

			this.filteredTask.forEach(element => {
				if (element.id == task?.id) {
					element.checked = true;
				} else {
					element.checked = false;
				}
			});

		}
	}
	setSelectedTask(task, $event) {
		if (this.addTimeSheetForm == undefined) {
			this.createAddTimeForm()
		}
		if ($event) {
			$event.stopPropagation();
		}
		if (task) {

			this.addTimeSheetForm.get('taskId').setValue(task?.id)
			this.addTimeSheetForm.get('taskName').setValue(task?.title)
			this.addTimeSheetForm.get('projectId').setValue(task?.projectId)
			this.addTimeSheetForm.get('projectName').setValue(task?.projectName)
			this.searchTask = task?.title;

			this.taskDropdownList.forEach(element => {
				if (element.id == task?.id) {
					element.checked = true;
				} else {
					element.checked = false;
				}
			});
			this.isDropdownShown = false;

		}
	}
	setComment($event) {
		if (this.addTimeSheetForm == undefined) {
			this.createAddTimeForm()
		}
		$event.stopPropagation();
		this.isDropdownShown = false;
		this.addTimeSheetForm.get('comment').setValue($event.target.value);
	}

	setValue(value, field) {
		if (field == 'logHours') {
			if (value != undefined && value != null && value != '') {
				this.addTimeSheetForm.get(field).setValue(parseInt(value));
			} else {
				this.addTimeSheetForm.get(field).setValue('');

			}
		} else if (field == 'logMinute') {
			if (value != undefined && value != null && value != '') {
				this.addTimeSheetForm.get(field).setValue(parseInt(value));
			} else {
				this.addTimeSheetForm.get(field).setValue('');

			}

		} else {
			if (value != undefined && value != null && value != '') {
				this.addTimeSheetForm.get(field).setValue(value);
			} else {
				this.addTimeSheetForm.get(field).setValue('');

			}

		}
	}
	setDateValue($event) {
		console.log($event);
		var date = $event.target.value;
		this.addTimeSheetForm.get('logDateObj').setValue(date);
		if (date) {
			var strDate = moment(date).format('DD-MM-YYYY');
			if (strDate) {
				this.addTimeSheetForm.get('logDate').setValue(strDate);
			}
		}
	}
	addTime() {
		this.isAddTimeFormSubmit = true;
		console.log(this.addTimeSheetForm);
		var totalMinuites = 0
		if (this.addTimeSheetForm.get('logHours').value != undefined && this.addTimeSheetForm.get('logHours').value != null && this.addTimeSheetForm.get('logHours').value != '') {
			totalMinuites = totalMinuites + (this.addTimeSheetForm.get('logHours').value * 60);
		}
		if (this.addTimeSheetForm.get('logMinute').value != undefined && this.addTimeSheetForm.get('logMinute').value != null && this.addTimeSheetForm.get('logMinute').value != '') {
			totalMinuites = totalMinuites + this.addTimeSheetForm.get('logMinute').value;
		}
		var postData = {
			projectId: this.addTimeSheetForm.get('projectId').value,
			taskId: this.addTimeSheetForm.get('taskId').value,
			logDate: this.addTimeSheetForm.get('logDate').value,
			logMinute: totalMinuites,
			comment: this.addTimeSheetForm.get('comment').value,
		}
		if (this.addTimeSheetForm.invalid) {
			return false;
		} else {
			this.restService.hitApi('task-log', postData, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					this.isAddTimeFormSubmit = false;
					if (responseData.statusCode == 200) {

						this.toastr.success(responseData?.message);
						$('#add_timesheet').modal('hide')
						this.closeModal();
						this.getTaskLogList();


					} else {
						this.toastr.error(responseData?.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					//this.toastr.error(errorData.error.message);
				});
		}

	}
	hideDropdown($event) {
		if ($event.target == $event.currentTarget) {
			this.isDropdownShown = false;
		}
	}

	startTimer() {
		this.addTimeSheetForm?.get('comment').setValue(this.comment);
		this.addTimeSheetForm?.get('logDate').setValue(this.todayDate);
		var totalMinuites = 0
		if (this.timePeriodModel && this.timePeriodModel.length > 0) {
			var splitArr = this.timePeriodModel.split(':');
			if (splitArr.length > 0) {
				var hours = splitArr[0];
				if (hours) {
					totalMinuites = totalMinuites + (hours * 60);
				}
				var minuites = splitArr[1];
				if (minuites) {
					totalMinuites = totalMinuites + parseInt(minuites);
				}

			}
		}
		console.log("start timer", this.addTimeSheetForm);

		var postData = {
			projectId: this.addTimeSheetForm.get('projectId').value,
			taskId: this.addTimeSheetForm.get('taskId').value,
			logDate: this.addTimeSheetForm.get('logDate').value,
			logMinute: totalMinuites,
			comment: this.addTimeSheetForm.get('comment').value,
			isToday: 1
		}

		this.restService.hitApi('task-log', postData, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getTaskLogList();
					this.getRunningTasDetails();
					//this.executeTimer(responseData?.result?.id)
				} else {
					this.toastr.error(responseData?.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				//this.toastr.error(errorData.error.message);
			});
	}
	endTimer() {
		var postData = {
			id: this.currentRunningTask?.id,
			taskEndTime: true,
		}
		this.restService.hitApi('task-log/running-start-end', postData, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.toastr.success(responseData?.message);
					this.getRunningTasDetails();
				} else {
					this.toastr.error(responseData?.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				//this.toastr.error(errorData.error.message);
			});
	}
	setTimePeriod(value) {
		this.timePeriodModel = value;
	}

	saveAndAddMore() {
		var totalMinuites = 0
		if (this.addTimeSheetForm.get('logHours').value != undefined && this.addTimeSheetForm.get('logHours').value != null && this.addTimeSheetForm.get('logHours').value != '') {
			totalMinuites = totalMinuites + (this.addTimeSheetForm.get('logHours').value * 60);
		}
		if (this.addTimeSheetForm.get('logMinute').value != undefined && this.addTimeSheetForm.get('logMinute').value != null && this.addTimeSheetForm.get('logMinute').value != '') {
			totalMinuites = totalMinuites + this.addTimeSheetForm.get('logMinute').value;
		}
		var postData = {
			projectId: this.addTimeSheetForm.get('projectId').value,
			taskId: this.addTimeSheetForm.get('taskId').value,
			logDate: this.addTimeSheetForm.get('logDate').value,
			logMinute: totalMinuites,
			comment: this.addTimeSheetForm.get('comment').value,
		}
		this.restService.hitApi('task-log', postData, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.toastr.success(responseData?.message);
					this.closeModal();
					this.getTaskLogList();


				} else {
					this.toastr.error(responseData?.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				//this.toastr.error(errorData.error.message);
			});
	}
	sortData(field) {
		this.sortByField = field;
		this.sortOrder = this.sortOrder == 'asc' ? 'desc' : 'asc';
		this.getTaskLogList();
	}

	getRunningTasDetails() {
		this.restService.hitApi('task-log/running-time-sheet-detail', null, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.currentRunningTask = responseData?.result;
					if (this.currentRunningTask) {
						if (this.currentRunningTask?.comment) {
							this.comment = this.currentRunningTask.comment
						}
						if (this.currentRunningTask?.logMinute) {
							var hours = (this.currentRunningTask?.logMinute / 60);
							var rhours = Math.floor(hours);
							var minutes = (hours - rhours) * 60;
							var rminutes = Math.round(minutes);
							var hoursStr = (rhours.toString()).length > 1 ? (rhours.toString()) : '0' + rhours.toString() + ':';
							var minuiteStr = (rminutes.toString()).length > 1 ? (rminutes.toString()) : '0' + rminutes.toString();
							this.timePeriodModel = hoursStr + minuiteStr;
						}
						if (this.currentRunningTask?.logDate) {
							var date = new Date(this.currentRunningTask?.logDate)
							this.currentRunningTask.logDate = moment(date).format('DD-MM-YYYY')
						}
						this.searchSelectTasks(null)
					} else {
						this.searchTask = '';
						this.comment = '';
						this.timePeriodModel = null;
					}

				} else {
					this.toastr.error(responseData?.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				//this.toastr.error(errorData.error.message);
			});
	}
	deleteTime(task) {
		if (task.timeSheet && task.timeSheet.length > 0) {
			var count = task.timeSheet.length;
			task.timeSheet.forEach(timeSheet => {
				this.restService.hitApi('task-log/' + timeSheet?.id, null, 'delete', '', true)
					.subscribe((result) => {
						let responseData: any = result;
						if (responseData.statusCode == 200) {
							count--;
							if (count == 0) {
								this.toastr.success(responseData?.message);
								this.selectedTimeSheet = null;
								$('#delete-time-task').modal('hide')
								$('#delete-timesheet').modal('hide')
								this.getTaskLogList();
							}

						} else {
							this.toastr.error(responseData?.message);
						}
					}, (err) => {
						console.log(err);
						let errorData: any = err;
						//this.toastr.error(errorData.error.message);
					});
			});
		}

	}
	makeEditForm(task) {
		if (task) {
			var date = new Date(task.logDate);
			this.addTimeSheetForm?.get('logDate').setValue(moment(date).format('DD-MM-YYYY'));
			this.addTimeSheetForm?.get('id').setValue(task.id);
			this.addTimeSheetForm?.get('taskId').setValue(task.taskId);
			if (task.logMinute) {
				var hours = (task.logMinute / 60);
				var rhours = Math.floor(hours);
				var minutes = (hours - rhours) * 60;
				var rminutes = Math.round(minutes);
				this.addTimeSheetForm?.get('logHours').setValue(rhours);
				this.addTimeSheetForm?.get('logMinute').setValue(rminutes);
			}
			this.addTimeSheetForm?.get('comment').setValue(task.comment);

		}
	}
	updateTime() {
		this.isAddTimeFormSubmit = true;
		console.log(this.addTimeSheetForm);
		var totalMinuites = 0
		if (this.addTimeSheetForm.get('logHours').value != undefined && this.addTimeSheetForm.get('logHours').value != null && this.addTimeSheetForm.get('logHours').value != '') {
			totalMinuites = totalMinuites + (this.addTimeSheetForm.get('logHours').value * 60);
		}
		if (this.addTimeSheetForm.get('logMinute').value != undefined && this.addTimeSheetForm.get('logMinute').value != null && this.addTimeSheetForm.get('logMinute').value != '') {
			totalMinuites = totalMinuites + this.addTimeSheetForm.get('logMinute').value;
		}
		var id = this.addTimeSheetForm.get('id').value
		var postData = {
			taskId: this.addTimeSheetForm.get('taskId').value,
			logDate: this.addTimeSheetForm.get('logDate').value,
			logMinute: totalMinuites,
			comment: this.addTimeSheetForm.get('comment').value,
		}
		if (this.addTimeSheetForm.invalid && !id) {
			return false;
		} else {
			this.restService.hitApi('task-log/' + id, postData, 'put', '', true)
				.subscribe((result) => {
					this.isAddTimeFormSubmit = false;
					let responseData: any = result;
					if (responseData.statusCode == 200) {

						this.toastr.success(responseData?.message);
						$('#edit_time_task').modal('hide')
						this.closeModal();
						this.getTaskLogList();


					} else {
						this.toastr.error(responseData?.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					//this.toastr.error(errorData.error.message);
				});
		}
	}
	getDaysByKey(key) {
		var array = []
		if (key == 'This Week') {
			var startOfWeek = moment().startOf('week').toDate();
			var secondOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 1);
			var thirdOfWeek = new Date(secondOfWeek.getFullYear(), secondOfWeek.getMonth(), secondOfWeek.getDate() + 1);
			var forthOfWeek = new Date(thirdOfWeek.getFullYear(), thirdOfWeek.getMonth(), thirdOfWeek.getDate() + 1);
			var fifthOfWeek = new Date(forthOfWeek.getFullYear(), forthOfWeek.getMonth(), forthOfWeek.getDate() + 1);
			var sixthOfWeek = new Date(fifthOfWeek.getFullYear(), fifthOfWeek.getMonth(), fifthOfWeek.getDate() + 1);
			var endOfWeek = moment().endOf('week').toDate();

			var obj1 = {
				day: this.dayHashMap.get(startOfWeek.getDay()),
				date: moment(startOfWeek).format('MMM DD')
			}
			var obj2 = {
				day: this.dayHashMap.get(secondOfWeek.getDay()),
				date: moment(secondOfWeek).format('MMM DD')
			}
			var obj3 = {
				day: this.dayHashMap.get(thirdOfWeek.getDay()),
				date: moment(thirdOfWeek).format('MMM DD')
			}
			var obj4 = {
				day: this.dayHashMap.get(forthOfWeek.getDay()),
				date: moment(forthOfWeek).format('MMM DD')
			}
			var obj5 = {
				day: this.dayHashMap.get(fifthOfWeek.getDay()),
				date: moment(fifthOfWeek).format('MMM DD')
			}
			var obj6 = {
				day: this.dayHashMap.get(sixthOfWeek.getDay()),
				date: moment(sixthOfWeek).format('MMM DD')
			}
			var obj7 = {
				day: this.dayHashMap.get(endOfWeek.getDay()),
				date: moment(endOfWeek).format('MMM DD')
			}
			array.push(obj1, obj2, obj3, obj4, obj5, obj6, obj7)

		} else if (key == 'Last Week') {
			var startOfWeek = moment().startOf('week').toDate();
			var lastweekFrom = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() - 7);
			var secondOfWeek = new Date(lastweekFrom.getFullYear(), lastweekFrom.getMonth(), lastweekFrom.getDate() + 1);
			var thirdOfWeek = new Date(secondOfWeek.getFullYear(), secondOfWeek.getMonth(), secondOfWeek.getDate() + 1);
			var forthOfWeek = new Date(thirdOfWeek.getFullYear(), thirdOfWeek.getMonth(), thirdOfWeek.getDate() + 1);
			var fifthOfWeek = new Date(forthOfWeek.getFullYear(), forthOfWeek.getMonth(), forthOfWeek.getDate() + 1);
			var sixthOfWeek = new Date(fifthOfWeek.getFullYear(), fifthOfWeek.getMonth(), fifthOfWeek.getDate() + 1);
			var lastweekTo = new Date(sixthOfWeek.getFullYear(), sixthOfWeek.getMonth(), sixthOfWeek.getDate() + 1);

			var obj1 = {
				day: this.dayHashMap.get(lastweekFrom.getDay()),
				date: moment(lastweekFrom).format('MMM DD')
			}
			var obj2 = {
				day: this.dayHashMap.get(secondOfWeek.getDay()),
				date: moment(secondOfWeek).format('MMM DD')
			}
			var obj3 = {
				day: this.dayHashMap.get(thirdOfWeek.getDay()),
				date: moment(thirdOfWeek).format('MMM DD')
			}
			var obj4 = {
				day: this.dayHashMap.get(forthOfWeek.getDay()),
				date: moment(forthOfWeek).format('MMM DD')
			}
			var obj5 = {
				day: this.dayHashMap.get(fifthOfWeek.getDay()),
				date: moment(fifthOfWeek).format('MMM DD')
			}
			var obj6 = {
				day: this.dayHashMap.get(sixthOfWeek.getDay()),
				date: moment(sixthOfWeek).format('MMM DD')
			}
			var obj7 = {
				day: this.dayHashMap.get(lastweekTo.getDay()),
				date: moment(lastweekTo).format('MMM DD')
			}
			array.push(obj1, obj2, obj3, obj4, obj5, obj6, obj7)
		} else {
			var innerHashMap = this.taskHashMap.get(key);
			var firstValue = innerHashMap.values().next().value;
			if (firstValue && firstValue.length > 0) {
				var logDate = new Date(firstValue[0]?.logDate);
				var weekNumber = moment(moment(logDate).toDate(), "DD-MM-YYYY").isoWeek();
				var d = (1 + (weekNumber - 1) * 7); // 1st of January + 7 days for each week
				var date1 = new Date(moment(logDate).toDate().getFullYear(), 0, (d + 1));
				var secondOfWeek = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate() + 1);
				var thirdOfWeek = new Date(secondOfWeek.getFullYear(), secondOfWeek.getMonth(), secondOfWeek.getDate() + 1);
				var forthOfWeek = new Date(thirdOfWeek.getFullYear(), thirdOfWeek.getMonth(), thirdOfWeek.getDate() + 1);
				var fifthOfWeek = new Date(forthOfWeek.getFullYear(), forthOfWeek.getMonth(), forthOfWeek.getDate() + 1);
				var sixthOfWeek = new Date(fifthOfWeek.getFullYear(), fifthOfWeek.getMonth(), fifthOfWeek.getDate() + 1);
				var lastweekTo = new Date(sixthOfWeek.getFullYear(), sixthOfWeek.getMonth(), sixthOfWeek.getDate() + 1);

				var obj1 = {
					day: this.dayHashMap.get(date1.getDay()),
					date: moment(date1).format('MMM DD')
				}
				var obj2 = {
					day: this.dayHashMap.get(secondOfWeek.getDay()),
					date: moment(secondOfWeek).format('MMM DD')
				}
				var obj3 = {
					day: this.dayHashMap.get(thirdOfWeek.getDay()),
					date: moment(thirdOfWeek).format('MMM DD')
				}
				var obj4 = {
					day: this.dayHashMap.get(forthOfWeek.getDay()),
					date: moment(forthOfWeek).format('MMM DD')
				}
				var obj5 = {
					day: this.dayHashMap.get(fifthOfWeek.getDay()),
					date: moment(fifthOfWeek).format('MMM DD')
				}
				var obj6 = {
					day: this.dayHashMap.get(sixthOfWeek.getDay()),
					date: moment(sixthOfWeek).format('MMM DD')
				}
				var obj7 = {
					day: this.dayHashMap.get(lastweekTo.getDay()),
					date: moment(lastweekTo).format('MMM DD')
				}
				array.push(obj1, obj2, obj3, obj4, obj5, obj6, obj7)
			}
		}
		return array;
	}
	getTime(task, day) {
		if (task.logDateObj) {
			var taskDay = task.logDateObj.getDay();
			if (day == taskDay) {
				if (task.logMinute) {
					var hours = (task.logMinute / 60);
					var rhours = Math.floor(hours);
					var minutes = (hours - rhours) * 60;
					var rminutes = Math.round(minutes);
					var hoursStr = ((rhours.toString()).length > 1 ? (rhours.toString()) : ('0' + rhours.toString())) + ':';
					var minuiteStr = (rminutes.toString()).length > 1 ? (rminutes.toString()) : '0' + rminutes.toString();
					return hoursStr + minuiteStr;

				}

			} else {
				return '-';
			}
		} else {
			return '-';
		}

	}
	getTimeSheetTime(task, day) {
		var value = '-'
		if (task.days && task.days.size > 0) {
			var logMinute = task.days?.get(day);
			if (logMinute > 0) {
				var hours = (logMinute / 60);
				var rhours = Math.floor(hours);
				var minutes = (hours - rhours) * 60;
				var rminutes = Math.round(minutes);
				var hoursStr = ((rhours.toString()).length > 1 ? (rhours.toString()) : ('0' + rhours.toString())) + ':';
				var minuiteStr = (rminutes.toString()).length > 1 ? (rminutes.toString()) : '0' + rminutes.toString();
				value = hoursStr + minuiteStr;
			}
		}

		return value;
	}
	changeTime($event, task, day) {
		if ($event.keyCode === 13) {
			// Cancel the default action, if needed
			$event.preventDefault();
			// Trigger the button element with a click
			if (task.timeSheet && task.timeSheet.length > 0) {
				var selectedObj = null;
				for (var i = 0; i < task.timeSheet.length; i++) {
					var logDate = new Date(task.timeSheet[i].logDate);
					var taskDay = logDate.getDay();
					if (taskDay == day) {
						selectedObj = task.timeSheet[i];
						break;
					}

				}
				if (selectedObj) {
					console.log($event.target.value)
					var value = $event.target.value;
					var totalMinuites = 0;
					var hours = 0;
					var minuites = 0;
					var hoursInMinuites = 0;
					if (value != '') {
						var splitArr = value.split(':');
						if (splitArr.length > 0) {
							hours = parseInt(splitArr[0]);
							if (splitArr.length > 1) {
								var minuites = parseInt(splitArr[1]);
							}

							hoursInMinuites = hours > 0 ? (hours * 60) : 0;
							totalMinuites = hoursInMinuites + minuites;


						}


					}
					var id = selectedObj.id
					var postData = {
						taskId: selectedObj.taskId,
						logDate: selectedObj.logDate,
						logMinute: totalMinuites,
						comment: selectedObj.comment,
					}

					this.restService.hitApi('task-log/' + id, postData, 'put', '', true)
						.subscribe((result) => {
							let responseData: any = result;
							if (responseData.statusCode == 200) {
								this.toastr.success(responseData?.message);
								this.getTaskLogList();


							} else {
								this.toastr.error(responseData?.message);
							}
						}, (err) => {
							console.log(err);
							let errorData: any = err;
							//this.toastr.error(errorData.error.message);
						});
				}

			}

		}

	}
	getTotalTime(task) {
		if (task.total) {
			var hours = (task.total / 60);
			var rhours = Math.floor(hours);
			var minutes = (hours - rhours) * 60;
			var rminutes = Math.round(minutes);
			var hoursStr = ((rhours.toString()).length > 1 ? (rhours.toString()) : ('0' + rhours.toString())) + ':';
			var minuiteStr = (rminutes.toString()).length > 1 ? (rminutes.toString()) : '0' + rminutes.toString();
			return hoursStr + minuiteStr;

		} else {
			return '-'
		}

	}
	downloadCsv() {
		this.restService.getCsvFile('task-log/export-time-sheet', { "sort": this.sortByField, "direction": this.sortOrder }, true);
	}
	saveMoreRecentTask() {
		let apiname = 'projectTask/create';
		if (this.taskCreateForm.invalid) {
			this.taskCreateFormSubmit = true;
			return;
		} else {
			this.restService.hitApi(apiname, this.taskCreateForm.value, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.toastr.success(responseData.message);
						this.isCreateDialogShow = false;
						setTimeout(() => {
							this.taskCreateForm.reset();
							this.isCreateDialogShow = true;
						});
						// this.setAutoSuggest(null);
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}
	}
	getOuterTotalTimeForTimesheet(key) {
		var totalMinutes = 0;
		if (this.timesheetHashMap && this.timesheetHashMap.size > 0) {
			var tasks = this.timesheetHashMap.get(key);
			tasks.forEach(element => {
				if (element.total) {
					totalMinutes = totalMinutes + element.logMinute
				}
			});
			return this.getHoursFromMinuite(totalMinutes)


		}
	}
}



