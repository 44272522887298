import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common'

import {
  NativeDateAdapter, DateAdapter,
  MAT_DATE_FORMATS
} from '@angular/material';
import { formatDate } from '@angular/common';
import * as moment from 'moment';

export const PICK_FORMATS = {
  parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
};

export class PickDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      return formatDate(date, 'yyyy-MM-dd', this.locale);;
    } else {
      return date.toDateString();
    }
  }
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: PickDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }
  ]
})
export class DatePickerComponent implements OnInit {
  @Input()
  label: string;
  @Input()
  format: string;
  @Input()
  dateValue: any;
  @Output() handleDateChangeEvent = new EventEmitter();

  //moment = require('moment');


  constructor() {
    this.dateValue = new Date(this.dateValue);
    // this.dateValue = this.datepipe.transform(this.dateValue, 'yyyy-MM-dd');
    // console.log(this.dateValue);
  }

  ngOnInit() {

    console.log(this.dateValue);
  }

  inputEvent(event) {
    //var date = moment(event.value).format(this.format);
  }
  changeEvent(event) {
    console.log(event.value);
    this.handleDateChangeEvent.emit(event.value);

  }


}
