import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RestService } from '../services/rest/rest.service';
import { ValidationService } from '../services/config/config.service';
import { MyErrorStateMatcher } from '../MyErrorStateMatcher';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'angular2-cookie/services/cookies.service';
import { BroadcastService } from '../services/broadcast/broadcast.service';

declare const $: any;
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	providers: [CookieService]
})
export class LoginComponent implements OnInit {

	loginForm: FormGroup;
	userData: any = JSON.parse(localStorage.getItem('uchoosecarUserData'))
	showErrorMessage: any = "";
	matcher = new MyErrorStateMatcher();

	constructor(
		private restService: RestService,
		private formBuilder: FormBuilder,
		private router: Router,
		private _cookieService: CookieService,
		private toastr: ToastrService,
		private broadcastService: BroadcastService
	) {

		this.loginForm = this.formBuilder.group({
			email: [this._cookieService.get('email') ? this._cookieService.get('email') : '', [Validators.required, ValidationService.emailValidator]],
			password: [this._cookieService.get('password') ? this._cookieService.get('password') : '', [Validators.required, ValidationService.passwordValidator]], //ValidationService.passwordValidator
			rememberme: [this._cookieService.get('rememberme') ? this._cookieService.get('rememberme') : false],
			recaptchaToken: ['', [Validators.required]],
		});
	}

	ngOnInit() {
		if (localStorage.getItem('userData') && JSON.parse(localStorage.getItem('userData')).subscriptionId) {
			// if (localStorage.getItem('userData')){
			// let userData = JSON.parse(localStorage.getItem('userData'))
			// userData.role==1?this.router.navigate(['/user-management']):this.router.navigate(['/discussion-board']);
			//this.router.navigate(['/dashboard'])
		}


		$('.show-pwd').click(function () {
			console.log('hi');
			if ($(this).hasClass('disabled')) {
				$(this).removeClass('disabled');
				$(this).prev().attr('type', 'text');
			} else {
				$(this).addClass('disabled');
				$(this).prev().attr('type', 'password');
			}
		})

		$(".toggle-password").click(function () {

			$(this).toggleClass("fa-eye fa-eye-slash");
			var input = $($(this).attr("toggle"));
			if (input.attr("type") == "password") {
				input.attr("type", "text");
			} else {
				input.attr("type", "password");
			}
		});
	}

	showOptions(event) {
		console.log(event.checked); //true or false
	}

	doLogin() {

		//console.log("login form", this.loginForm);
		localStorage.removeItem('userData');
		localStorage.removeItem("projectData");
		localStorage.removeItem('companyData');
		localStorage.removeItem('company_id');
		localStorage.removeItem('company_name');
		localStorage.removeItem('projectSelected');


		this.loginForm.value.deviceInfo = { "id": "web" }

		console.log(this.loginForm.value.rememberme);
		if (this.loginForm.value.rememberme) {
			this._cookieService.put('email', this.loginForm.value.email);
			this._cookieService.put('password', this.loginForm.value.password);
			this._cookieService.put('rememberme', this.loginForm.value.rememberme);
		} else {
			this._cookieService.put('email', '');
			this._cookieService.put('password', '');
			this._cookieService.put('rememberme', '');
		}

		this.restService.hitApi('login', this.loginForm.value, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				console.log(responseData);
				if (responseData.statusCode == 200) {
					localStorage.setItem('userData', JSON.stringify(responseData.result));
					if (responseData.result.role == 1) {
						if (responseData.result.userId == responseData.result.ownerId) {
							localStorage.setItem('isOwner', "true");
						} else {
							localStorage.setItem('isOwner', "false");
						}
					}

					localStorage.setItem('company_id', responseData.result.companyId.toString());
					localStorage.setItem('company_name', responseData.result.companyName);
					console.log(responseData.result.companyId);
					if (responseData.result.projectId != null && responseData.result.projectId != "null" && responseData.result.projectId != '') {
						localStorage.setItem('projectSelected', responseData.result.projectId);
					}
					//localStorage.setItem('projectSelected', responseData.result.projectId);
					console.log("projectSelected:", localStorage.getItem('projectSelected'));

					if (responseData.result.planStatus == true) {
						$('#pay-alert').modal('show');
					} else {
						this.broadcastService.boradcast("FATCH_USER_DATA_GO_TO_DASHBOARD", responseData.result)
					}

					// if(responseData.result.isCompanyAdmin){
					// 	this.router.navigate(['user-management']);
					// }else{
					// 	this.router.navigate(['discussion-board']);
					// }
					// this.setActiveProject();
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				this.toastr.error(err.error.message);
			})
	}

	goToPayment() {
		$('#pay-alert').modal('hide');
		this.router.navigate(['outer-pricing']);
	}

	onSubmit(token) {
		this.loginForm.get('recaptchaToken').setValue(token);
	}


	setActiveProject() {
		this.restService.hitApi('project/list', {}, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					if (!responseData.result.length) {
						this.router.navigate(['user-management']);
					} else {
						localStorage.setItem("projectData", JSON.stringify(responseData.result[0]));
						this.router.navigate(['user-management']);
					}

				} else {
					// this.toastr.error(responseData.replyMessage);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.replyMessage);
			})
	}

	toggle_eyes() {
		if ($('input[name=password]').prop("type") == 'password') {
			$('.fa').removeClass('fa-eye-slash');
			$('.fa').addClass('fa-eye');
			$('input[name=password]').prop("type", "text");
		} else {
			$('.fa').addClass('fa-eye-slash');
			$('.fa').removeClass('fa-eye');
			$('input[name=password]').prop("type", "password");
		}
	}

	closeModal() {

	}

}
