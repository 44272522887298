import { values } from 'node_modules1111/@types/lodash/ts3.1';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute, RoutesRecognized, NavigationExtras } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { RouteEventsService } from '../services/route-events.service';
import { RouteInterceptorService } from '../services/route-interceptor.service';
import { Location } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';
import { BroadcastService } from '../services/broadcast/broadcast.service';
import { FileUploadService } from "../file-upload-service";
import { HttpEvent, HttpEventType } from '@angular/common/http';



// import { MatDialog } from '@angular/material';
// import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
declare const $: any;
@Component({
	selector: 'app-testcase',
	templateUrl: './testcase.component.html',
	styleUrls: ['./testcase.component.scss'],
})

export class TestcaseComponent implements OnInit {
	userData: any = JSON.parse(localStorage.getItem("userData"));
	testcaseCreateForm: FormGroup;
	testcaseData: any = [];
	pid: any = localStorage.getItem('projectSelected');
	tcid: any;
	testCaseId: any;
	selectedRelease: any;
	projectListData: any;
	projectData: any;
	projectSprintList: any;
	userInfo: any;
	files: any = [];
	imgArr: any = [];
	descriptionFiles: any = [];
	descriptionImgArr: any = [];
	sprintArr: any = [];
	fb: FormBuilder;
	backButtonText;

	releaseSprintDisabled: boolean = true;
	jqueryRef: any = $;
	endDateStart: any;
	todat_date: any = new Date();
	addCommentForm: FormGroup;
	editCommentForm: FormGroup;
	testCaseType = new FormControl();
	testCaseTypeList = [{ name: 'External', value: 1 }, { name: 'Internal', value: 2 }];
	isAddCommentInputShown: any = true;
	isOwner: boolean;
	isCompanyAdmin: boolean;
	isProjectManager: boolean;
	routerHistory: string[];
	tabNum: any = 1;
	previousUrl: string;
	isOther: boolean;
	deletedDescriptionItems: any[] = [];
	deletedAttachmentItems: any[] = [];
	constructor(
		private restService: RestService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private routeEventsService: RouteEventsService,
		private routeInterceptorService: RouteInterceptorService,
		private broadcastService: BroadcastService,
		private fileUploadService: FileUploadService,
		private location: Location) {
		// Check for route params

		this.fb = formBuilder;

		this.isOwner = localStorage.getItem("isOwner") == 'true' ? true : false;
		this.isCompanyAdmin = localStorage.getItem("isCompanyAdmin") == 'true' ? true : false;
		this.isProjectManager = localStorage.getItem("isProjectManager") == 'true' ? true : false;
		this.isOther = localStorage.getItem("isOther") == 'true' ? true : false;

		// if (this.pid && this.pid !== null && this.pid !== undefined) {
		// 	this.getProjectDetail();
		// }
		this.route.params.subscribe(params => {
			//this.pid = params['pid'];

			this.tcid = params['tcid'];
			if (this.tcid && this.tcid !== null && this.tcid !== undefined) {
				this.getTestcase();
			}
		});
		var params = this.router.getCurrentNavigation().extras.state;
		console.log("this.params", params);
		this.tabNum = params?.data['queryParams']?.['tab'];
		console.log("this.tabNum", this.tabNum);

		this.router.events
			.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
			.subscribe((events: RoutesRecognized[]) => {
				this.previousUrl = events[0].urlAfterRedirects;
				console.log('previous url', events[0].urlAfterRedirects);
				console.log('current url', events[1].urlAfterRedirects);
			});

		if (this.userData) {
			this.getUserprofile();
		}

		console.log(this.userData);
		this.createForm(null);

		this.broadcastService.subscribe("RELOAD_ENTITY", (id) => {
			this.tcid = id
			this.pid = localStorage.getItem('projectSelected');
			if (this.pid && this.tcid && this.tcid !== null && this.tcid !== undefined) {
				this.getTestcase();
			}
		});
	}

	ngOnInit() {

		$(document).ready(() => {
			let that = this;
			$('.htmlEditor').summernote({
				height: '150px',
				focus: true,
				disableDragAndDrop: true,
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
				callbacks: {
					onPaste: function (e) {
						var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
						e.preventDefault();
						document.execCommand('insertText', false, bufferText);
					},
					onImageUpload: function (data) {
						data.pop();
					}
				}
			});


			$('.date input').datepicker({
				format: 'yyyy-mm-dd',
				autoclose: true,
				todayHighlight: true
			}).on('changeDate', function (e) {
				$('.datepicker').hide();
				if (e.currentTarget.classList[1] == 'startDate') {
					var minDate = new Date(e.date.valueOf());
					$('.endDate').datepicker('setStartDate', minDate);
				}
				that.updateTestcase(e.currentTarget.classList[1], e.format('yyyy-mm-dd'));
			});
		});

		$(document).mouseup(function (e) {
			if (!$(".type-work").is(e.target) && $(".type-work").has(e.target).length === 0 && !$(".type-work-open").is(e.target) && $(".type-work-open").has(e.target).length === 0) {
				$(".type-work-open").slideUp('fast');
			}
		});

	}

	createForm(comment) {
		if (comment) {
			this.editCommentForm = this.fb.group({
				'comment': [comment],
			});
		} else {
			this.addCommentForm = this.fb.group({
				'comment': [''],
			});
			this.editCommentForm = this.fb.group({
				'comment': [''],
			});
		}
		console.log('this.editCommentForm');
		console.log(this.editCommentForm);


	}

	getUserprofile() {
		this.restService.hitApi('user/profile', { 'userId': this.userData.id }, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.userInfo = responseData.result;
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	// getProjectDetail() {
	// 	this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', true)
	// 		.subscribe((result) => {
	// 			let responseData: any = result;
	// 			if (responseData.statusCode == 200) {
	// 				this.projectData = responseData.result;
	// 				if (this.userData) {
	// 					var isOwner = localStorage.getItem('isOwner');
	// 					if (isOwner == "true") {
	// 						this.isOwner = true;
	// 						this.isCompanyAdmin = false;
	// 						this.isProjectManager = false;
	// 						this.isOther = false;
	// 					} else {
	// 						if (this.userData.role == 1) {
	// 							this.isOwner = false;
	// 							this.isCompanyAdmin = true;
	// 							this.isProjectManager = false;
	// 							this.isOther = false;
	// 						} else if (this.userData.role == 2) {
	// 							if (this.projectData && this.projectData.projectUsersProject) {
	// 								this.projectData.projectUsersProject.forEach(element => {
	// 									if (element.userId == this.userData.id) {
	// 										if (element.userRoleId == 1) {
	// 											this.isProjectManager = true;
	// 											this.isOther = false;
	// 										} else {
	// 											this.isProjectManager = false;
	// 											this.isOther = true;
	// 										}
	// 									}
	// 								});
	// 								this.isOther = false;

	// 							} else {
	// 								this.isOther = true;
	// 							}
	// 							this.isOwner = false;
	// 							this.isCompanyAdmin = false;
	// 						} else {
	// 							this.isOther = true;
	// 							this.isOwner = false;
	// 							this.isProjectManager = false;
	// 							this.isCompanyAdmin = false;
	// 						}
	// 					}
	// 					// if (this.userData.isCompanyAdmin == 1) {
	// 					// 	if (this.userData.ownerId) {
	// 					// 		this.isOwner = true;
	// 					// 		this.isCompanyAdmin = false;
	// 					// 		this.isProjectManager = false;
	// 					// 	} else {
	// 					// 		this.isOwner = false;
	// 					// 		this.isCompanyAdmin = true;
	// 					// 		this.isProjectManager = false;
	// 					// 	}
	// 					// } else {
	// 					// 	if (this.projectData && this.projectData.projectUsersProject) {
	// 					// 		this.projectData.projectUsersProject.forEach(element => {
	// 					// 			if (element.userId == this.userData.id) {
	// 					// 				if (element.userRoleId == 1) {
	// 					// 					this.isProjectManager = true;

	// 					// 				} else {
	// 					// 					this.isProjectManager = false;
	// 					// 				}
	// 					// 			}
	// 					// 		});

	// 					// 	}
	// 					// 	this.isOwner = false;
	// 					// 	this.isCompanyAdmin = false;
	// 					// }
	// 				}
	// 			} else {
	// 				// this.toastr.error(responseData.message);
	// 			}
	// 		}, (err) => {
	// 			console.log(err);
	// 			let errorData: any = err;
	// 			this.toastr.error(errorData.error.message);
	// 		})
	// }

	// getProjectList() {
	// 	this.restService.hitApi('discussion/projects',{},'get','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.projectListData = responseData.result.data;
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }

	// changeProject(pid){
	// 	localStorage.setItem('projectSelected',pid)
	// 	$('.project-title-list').toggle("slide");
	// 	this.router.navigateByUrl('/project/'+pid+'/releases');
	// }

	toggleList(ele) {
		// alert(ele);
		this.descriptionFiles = [];
		var elid = '';
		if (ele == 'description') {
			elid = '#testcase_description';
			$('#testcase_description').summernote('code', this.testcaseData.description);
			if (this.testcaseData.descriptionAttachments && this.testcaseData.descriptionAttachments.length > 0) {
				this.testcaseData.descriptionAttachments.forEach(element => {
					this.descriptionFiles.push({ id: element.id, name: element.displayName, serverFileName: element.fileName, image: element.fileTitle, isProgressBarShown: false });
					//obj.imgArr.push(element.displayName);
				});
				// 	}
			} else {
				//$('.' + ele + '_' + obj.id).slideUp("fast");
				this.descriptionFiles = [];
				this.descriptionImgArr = [];

			}
		} else if (ele == 'preConditions') {
			elid = '#testcase_preConditions';
			$('.preConditions .htmlEditor').summernote('code', this.testcaseData.preConditions);
		} else if (ele == 'steps') {
			elid = '#testcase_steps'
			$('.steps .htmlEditor').summernote('code', this.testcaseData.steps);
		} else if (ele == 'testData') {
			elid = '#testcase_testData'
			$('.testData .htmlEditor').summernote('code', this.testcaseData.testData);
		} else if (ele == 'expectedResults') {
			elid = '#testcase_expectedResults'
			$('.expectedResults .htmlEditor').summernote('code', this.testcaseData.expectedResults);
		}
		$('.' + ele).slideToggle("fast");

		this.setFocus(elid);
	}

	setFocus(id) {
		$(id).summernote({
			focus: true,
		});
	}

	toggleAddCommentList(ele, event, show) {
		let that = this;
		that.files = [];
		that.imgArr = [];
		$('.htmlEditor').summernote({
			height: '150px',
			focus: true,
			disableDragAndDrop: true,
			toolbar: [
				// [groupName, [list of button]]
				['style', ['bold', 'italic', 'underline']],
				['para', ['ul', 'ol', 'paragraph']]
			],
			callbacks: {
				onPaste: function (e) {
					var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
					e.preventDefault();
					document.execCommand('insertText', false, bufferText);
				}
			}
		});

		this.isAddCommentInputShown = show;
		//$('.htmlEditor').summernote('code', '');
		$('#test_case_comment').summernote("reset");
		$('#test_case_comment').summernote('code', '');
		if (event == 'down') {
			$('.' + ele).slideDown("fast");
		} else {
			$('.' + ele).slideUp("fast");

		}
		$('#test_case_comment').summernote({
			focus: true
		});
	}

	toggleUpdateCommentList(ele, event, obj) {
		let that = this;
		obj.files = [];
		obj.imgArr = [];
		$('.htmlEditor').summernote({
			height: '150px',
			focus: true,
			disableDragAndDrop: true,
			toolbar: [
				// [groupName, [list of button]]
				['style', ['bold', 'italic', 'underline']],
				['para', ['ul', 'ol', 'paragraph']]
			],
			callbacks: {
				onPaste: function (e) {
					var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
					e.preventDefault();
					document.execCommand('insertText', false, bufferText);
				}
			}
		});


		if (event == 'down') {
			$('#test_case_edit_comment' + obj.id).summernote("reset");
			$('#test_case_edit_comment' + obj.id).summernote('code', obj.comment);
			$('.' + ele + '_' + obj.id).slideDown("fast");

			if (obj.attachments && obj.attachments.length > 0) {
				obj.attachments.forEach(element => {
					obj.files.push({ id: element.id, name: element.displayName, serverFileName: element.fileName, image: element.fileTitle, isProgressBarShown: false });
					//obj.imgArr.push(element.displayName);
				});
				// 	}
			} else {
				//$('.' + ele + '_' + obj.id).slideUp("fast");
				obj.files = [];
				obj.imgArr = [];

			}
		} else {
			$('.' + ele + '_' + obj.id).slideUp("fast");
			$('#test_case_edit_comment' + obj.id).summernote("reset");
			$('#test_case_edit_comment' + obj.id).code('');

		}

		$('#test_case_edit_comment' + obj.id).summernote({
			focus: true
		});
	}


	showedit() {
		$('.heading-editable h1').hide("fast");
		$('.heading-area').show("fast");
		$('.tick-cancel-input-filed').show("fast");
	}
	hideedit() {
		console.log("testcase title", this.testcaseData?.name);
		$('#testcase_name').val(this.testcaseData?.name);
		$('.heading-editable h1').show("fast");
		$('.heading-area').hide("fast");
		$('.tick-cancel-input-filed').hide("fast");
	}


	updateTestcase(field, value = "") {

		let data: any;
		if (field == 'name') {
			value = $('#testcase_' + field).val();
			if (value != "") {
				if (value != this.testcaseData.name) {
					data = { 'name': value, 'testCaseId': this.testCaseId, 'projectId': this.pid };
				}
			} else {
				this.toastr.error("Test Case name is required");
				return
			}

		} else if (field == 'description') {
			value = $('#testcase_' + field).val();
			data = { 'description': value, 'testCaseId': this.testCaseId, 'projectId': this.pid, 'files': this.descriptionImgArr };
		} else if (field == 'type') {
			data = { 'type': value, 'testCaseId': this.testCaseId, 'projectId': this.pid };
		} else if (field == 'preConditions') {
			value = $('#testcase_' + field).val();
			data = { 'preConditions': value, 'testCaseId': this.testCaseId, 'projectId': this.pid };
		} else if (field == 'steps') {
			value = $('#testcase_' + field).val();
			data = { 'steps': value, 'testCaseId': this.testCaseId, 'projectId': this.pid };
		} else if (field == 'testData') {
			value = $('#testcase_' + field).val();
			data = { 'testData': value, 'testCaseId': this.testCaseId, 'projectId': this.pid };
		} else if (field == 'expectedResults') {
			value = $('#testcase_' + field).val();
			data = { 'expectedResults': value, 'testCaseId': this.testCaseId, 'projectId': this.pid };
		}

		if (data != null) {
			this.restService.hitApi('testCase/update', data, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.toastr.success(responseData.message);
						if (field == 'name') {
							$('.heading-editable h1').show("fast");
							$('.heading-area').hide("fast");
							$('.tick-cancel-input-filed').hide("fast");
						} else if (field == 'type') {
							$('.type-work-open').slideToggle("fast");
						} else if (field == 'description') {
							this.descriptionImgArr = [];
							this.descriptionFiles = [];
							$('.description').slideToggle("fast");
						} else if (field == 'preConditions') {
							$('.preConditions').slideToggle("fast");
						} else if (field == 'steps') {
							$('.steps').slideToggle("fast");
						} else if (field == 'testData') {
							$('.testData').slideToggle("fast");
						} else if (field == 'expectedResults') {
							$('.expectedResults').slideToggle("fast");
						}

						this.getTestcase();
						// $('#create_testplan_popup_web').modal('hide');
						// this.files = [];
					} else {
						// this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					// this.toastr.error(errorData.error.message);
				})
		} else {
			if (field == 'name') {
				$('.heading-editable h1').show("fast");
				$('.heading-area').hide("fast");
				$('.tick-cancel-input-filed').hide("fast");
			}
		}
	}

	getTestcase() {
		this.restService.hitApi('testCase/details', { 'projectId': this.pid, 'testCaseId': this.tcid }, 'post', '', false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.testcaseData = responseData.result;
					if (this.testcaseData.updatedAt != null) {
						//this.testcaseData.updatedAt = new Date(this.testcaseData.updatedAt).toLocaleString();
					}
					this.testCaseId = this.testcaseData.id;
					if (this.testcaseData && this.testcaseData.type) {
						this.testCaseTypeList.forEach(type => {
							if (type.value == this.testcaseData.type) {
								this.testCaseType.setValue(type);
							}
						})
					}
					// this.createForm(this.testcaseData);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	deleteTestcase(tcid) {
		this.restService.hitApi('testCase/delete', { 'testCaseId': tcid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('#delete-record').modal('hide')
					this.toastr.success(responseData.message);
					this.router.navigateByUrl('/testcases');
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	// getProjectSprints(){
	// 	this.restService.hitApi('release-sprint/sprint',{'projectReleaseId':this.tcid},'post','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.projectSprintList = responseData.result;
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }



	checkUncheckAll(ele, classname) {
		// console.log(ele.target.checked)
		var checkboxes = $("." + classname + " input");
		if (ele) {
			for (var i = 0; i < checkboxes.length; i++) {
				// if (checkboxes[i].type == 'checkbox') {
				checkboxes[i].checked = true;
				// }
			}
		} else {
			for (var i = 0; i < checkboxes.length; i++) {
				console.log(i)
				// if (checkboxes[i].type == 'checkbox') {
				checkboxes[i].checked = false;
				// }
			}
		}
	}

	setCheckedVar() {
		this.releaseSprintDisabled = true;
		let that = this;
		$('.release-sprint-checkbox').find('input').each(function () {
			if ($(this).prop("checked")) {
				that.releaseSprintDisabled = false;
			}
		});

	}

	// removeReleaseSprints(){
	// 	this.sprintArr = [];
	// 	let that = this;
	// 	$('.release-sprint-checkbox').find('input').each(function(){
	// 		console.log($(this).val());
	// 		if($(this).prop("checked")){
	// 			that.sprintArr.push($(this).val());
	// 		}
	// 	});

	// 	if(this.sprintArr.length){
	// 		this.restService.hitApi('release-sprint/delete',{'projectReleaseId':this.tcid,'sprintId':this.sprintArr},'post','',true)
	// 		.subscribe((result)=>{
	// 			let responseData : any = result;
	// 			if(responseData.statusCode==200){
	// 				this.getTestcase();
	// 				this.toastr.success(responseData.message);
	// 			}else{
	// 				this.toastr.error(responseData.message);
	// 			}
	// 		},(err)=>{
	// 			console.log(err);
	// 			let errorData : any = err;
	// 			this.toastr.error(errorData.error.message);
	// 		})
	// 	}
	// }

	addReleaseSprint() {

		let that = this;
		$('.sprint-checkbox').find('input').each(function () {
			console.log($(this).val());
			if ($(this).prop("checked")) {
				that.sprintArr.push($(this).val());
			}
		});

		console.log(this.sprintArr);

		this.restService.hitApi('release-sprint/create', { 'projectId': this.pid, 'projectReleaseId': this.tcid, 'sprintId': this.sprintArr }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('#create_add-link_popup_web').modal('hide');
					this.getTestcase();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}



	addComment(obj, field, shown) {
		let data: any;
		let comment: any;
		let apiname: any;
		if (obj?.id) {
			apiname = 'testCase/comment/update';
			comment = $('#test_case_edit_' + field + obj?.id).val();
			data = { 'testCaseCommentId': obj?.id, 'comment': comment, 'files': obj.imgArr, 'projectId': this.pid }
		} else {
			apiname = 'testCase/comment/add';
			comment = $('#test_case_' + field).val();
			data = { 'testCaseId': this.testCaseId, 'comment': comment, 'files': this.imgArr, 'projectId': this.pid }
		}



		this.restService.hitApi(apiname, data, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					if (obj?.id) {
						$('#comment' + obj?.id).show();
						$('#commentinput' + obj?.id).hide();
					} else {
						$('#comment').val('');
						$('#test_case_comment').summernote("reset");
						$('.' + field).slideUp("fast");
						this.isAddCommentInputShown = shown;
					}

					this.files = [];
					this.imgArr = [];
					this.editCommentForm.reset();
					this.addCommentForm.reset();
					this.getTestcase();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	deleteComment(testCaseCommentId) {
		this.restService.hitApi('testCase/comment/delete', { "testCaseCommentId": testCaseCommentId }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getTestcase();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	backToRetun() {
		this.location.back();

		// if (this.previousUrl.includes('/testplan')) {
		// 	var params: NavigationExtras = {
		// 		queryParams: { tab: this.tabNum }
		// 	}
		// 	this.router.navigate([this.previousUrl], {
		// 		state: { data: params }
		// 	});

		// } else {
		// 	this.location.back();

		// }
		// if (this.routeEventsService.previousRoutePath.value) {
		// 	this.router.navigate([this.routeEventsService.previousRoutePath.value]);

		// } else {
		// 	this.router.navigateByUrl('/testcases');
		// }
	}

	getExtensionOfFileType(fileName) {
		if (fileName) {
			var str = fileName.split(".");
			if (str[str.length - 1] == 'jpg' || str[str.length - 1] == 'png' || str[str.length - 1] == 'jpeg' || str[str.length - 1] == 'JPG' || str[str.length - 1] == 'PNG') {
				return 'image'
			} else {
				return 'file'
			}

		}
	}


	removeAttachment(fileObj, index, commentObj) {

		this.deletedAttachmentItems.push(fileObj.serverFileName)

		if (commentObj != undefined && commentObj != null) {
			commentObj.files.splice(index, 1);
			commentObj.imgArr.splice(0, 1);

		} else {
			this.files.splice(index, 1);
			this.imgArr.splice(0, 1);
		}

		//var fileObj = this.files[index];
		// var deleteFileArray = [];
		// var deleteFileArray = [fileObj.serverFileName];
		// if (commentObj) {
		// 	if (fileObj.id) {
		// 		if (deleteFileArray.length > 0) {
		// 			this.deleteAttachment(deleteFileArray);
		// 		}

		// 	} else {
		// 		if (deleteFileArray.length > 0) {
		// 			this.removeAttachmentFromServer(deleteFileArray, true);

		// 		}
		// 	}
		// 	commentObj.files.splice(index, 1);
		// 	commentObj.imgArr.splice(0, 1);


		// } else {
		// 	if (deleteFileArray.length > 0) {
		// 		this.removeAttachmentFromServer(deleteFileArray, true);

		// 	}
		// 	this.files.splice(index, 1);
		// 	this.imgArr.splice(0, 1);
		// }
	}

	removeDescriptionAttachment(fileObj, index) {
		this.deletedDescriptionItems.push(fileObj.serverFileName);
		this.descriptionFiles.splice(index, 1);
		this.descriptionImgArr.splice(index, 1);
	}

	updateDescriptionAttachment() {
		if (this.deletedDescriptionItems && this.deletedDescriptionItems.length > 0) {
			this.removeDescriptionAttachmentFromServer();
		} else {
			this.updateTestcase('description');
		}
	}

	updateAttachment(obj, field) {
		if (this.deletedAttachmentItems && this.deletedAttachmentItems.length > 0) {
			this.removeAttachmentFromServer(obj, field);
		} else {
			this.addComment(obj, field, true);
		}
	}

	removeDescriptionAttachmentFromServer() {

		var json = {
			projectId: this.pid,
			entity: 'testCase',
			files: this.deletedDescriptionItems
		}

		this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.deletedDescriptionItems = [];
					this.updateTestcase('description');
				} else {
					this.toastr.success(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	removeAttachmentFromServer(obj, field) {

		var json = {
			projectId: this.pid,
			entity: 'testCase',
			files: this.deletedAttachmentItems
		}

		this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.deletedDescriptionItems = [];
					this.addComment(obj, field, true);
				} else {
					this.toastr.success(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}



	uploadFile(event) {
		console.log("file event", event);
		var i;
		for (let index = 0; index < event.length; index++) {

			let file: File = event[index];
			i = index;
			if (this.restService.checkFileSize(file, 50)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				formData.append('entity', 'testCase');
				formData.append('entityId', this.tcid);
				formData.append('file', file);

				var reader = new FileReader();
				const this_ = this;
				var obj;
				reader.onload = function (e: any) {
					obj = { serverFileName: file.name, name: file.name, image: e.target.result, isProgressBarShown: true }
					this_.files.push(obj);
				}

				reader.readAsDataURL(file);


				this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
					.subscribe((event: HttpEvent<any>) => {
						switch (event.type) {
							case HttpEventType.Sent:
								console.log('Request has been made!');
								break;
							case HttpEventType.ResponseHeader:
								console.log('Response header has been received!');
								break;
							case HttpEventType.UploadProgress:
								this.files[this.files.length - 1].progress = Math.round(event.loaded / event.total * 100);
								console.log(`Uploaded! ${this.files[this.files.length - 1].progress}%`);
								$('#add_comment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');

								break;
							case HttpEventType.Response:
								let responseData: any = event.body;
								if (responseData.statusCode == 200) {
									this.imgArr.push(responseData.result);
									this.files[this.files.length - 1].serverFileName = responseData.result.fileTitle;
									this.files[this.files.length - 1].displayName = responseData.result.displayName;
									this.files.forEach(element => {
										element.isProgressBarShown = false;
									});
								} else {
									if (responseData.message) {
										this.toastr.error(responseData.message);
									}
								}
								console.log('Upload successfully', event.body);
								setTimeout(() => {
									this.files[this.files.length - 1].progress = 0;
									$('#add_comment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');

								}, 1500);


						}
					})

				//this.files.push({ index: index, localFileName: file.name, serverFileName: null, isProgressBarShown: true });

				// this.files.push(file.name);

				// this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', false, false)
				// 	.subscribe((result) => {
				// 		let responseData: any = result;
				// 		if (responseData.statusCode == 200) {
				// 			this.imgArr.push(responseData.result);
				// 			this_.files[this_.files.length - 1].serverFileName = responseData.result.fileTitle;
				// 			this_.files[this_.files.length - 1].displayName = responseData.result.displayName;
				// 			this.files.forEach(element => {
				// 				element.isProgressBarShown = false;
				// 			});

				// 		} else {
				// 			this.toastr.error(responseData.message);
				// 		}
				// 	}, (err) => {
				// 		console.log(err);
				// 		let errorData: any = err;
				// 		//this.files[index].isProgressBarShown = false;
				// 		this.toastr.error(errorData.error.message);
				// 	})
			}
		}

	}

	uploadCommentAttachment(event, commentObj) {
		var i;
		for (let index = 0; index < event.length; index++) {
			i = index;
			let file: File = event[index];
			if (this.restService.checkFileSize(file, 50)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				formData.append('entity', 'testCase');
				formData.append('entityId', this.tcid);
				formData.append('file', file);

				// const element = event[index];


				var reader = new FileReader();
				const this_ = this;
				var obj;
				reader.onload = function (e: any) {
					obj = { name: file.name, serverFileName: file.name, image: e.target.result, isProgressBarShown: true }
					commentObj.files.push(obj);
				}

				reader.readAsDataURL(file);

				this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
					.subscribe((event: HttpEvent<any>) => {
						switch (event.type) {
							case HttpEventType.Sent:
								console.log('Request has been made!');
								break;
							case HttpEventType.ResponseHeader:
								console.log('Response header has been received!');
								break;
							case HttpEventType.UploadProgress:
								commentObj.files[commentObj.files.length - 1].progress = Math.round(event.loaded / event.total * 100);
								console.log(`Uploaded! ${commentObj.files[commentObj.files.length - 1].progress}%`);
								$('#edit_comment_progress_bar_' + (commentObj.files.length - 1)).attr('aria-valuenow', commentObj.files[commentObj.files.length - 1].progress).css('width', commentObj.files[commentObj.files.length - 1].progress + '%');

								break;
							case HttpEventType.Response:
								let responseData: any = event.body;
								if (responseData.statusCode == 200) {
									commentObj.imgArr.push(responseData.result);
									const fileObj = commentObj.files.find(element => element.name == obj.name);
									fileObj.serverFileName = responseData.result.fileName;
									fileObj.displayName = responseData.result.displayName;
									commentObj.files.forEach(element => {
										element.isProgressBarShown = false;
									});
								} else {
									if (responseData.message) {
										this.toastr.error(responseData.message);
									}
								}
								console.log('User successfully created!', event.body);
								setTimeout(() => {
									commentObj.files[commentObj.files.length - 1].progress = 0;
									$('#edit_comment_progress_bar_' + (commentObj.files.length - 1)).attr('aria-valuenow', commentObj.files[commentObj.files.length - 1].progress).css('width', commentObj.files[commentObj.files.length - 1].progress + '%');

								}, 1500);
						}
					})

				// this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', false, false)
				// 	.subscribe((result) => {
				// 		let responseData: any = result;
				// 		if (responseData.statusCode == 200) {

				// 			commentObj.imgArr.push(responseData.result);
				// 			const fileObj = commentObj.files.find(element => element.name == obj.name);
				// 			fileObj.serverFileName = responseData.result.fileName;
				// 			fileObj.displayName = responseData.result.displayName;

				// 			commentObj.files.forEach(element => {
				// 				element.isProgressBarShown = false;
				// 			});

				// 		} else {
				// 			this.toastr.error(responseData.message);
				// 		}
				// 	}, (err) => {
				// 		console.log(err);
				// 		let errorData: any = err;
				// 		this.toastr.error(errorData.error.message);
				// 	})
			}
		}
	}

	uploadDescriptionFile(event) {
		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];
			if (this.restService.checkFileSize(file, 50)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				formData.append('entity', 'testCase');
				formData.append('entityId', this.tcid);
				formData.append('file', file);
				var reader = new FileReader();
				const this_ = this;
				var obj;
				reader.onload = function (e: any) {
					obj = { serverFileName: file.name, name: file.name, image: e.target.result, isProgressBarShown: true }
					this_.descriptionFiles.push(obj);
				}

				reader.readAsDataURL(file);

				this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
					.subscribe((event: HttpEvent<any>) => {
						switch (event.type) {
							case HttpEventType.Sent:
								console.log('Request has been made!');
								break;
							case HttpEventType.ResponseHeader:
								console.log('Response header has been received!');
								break;
							case HttpEventType.UploadProgress:
								this.descriptionFiles[this.descriptionFiles.length - 1].progress = Math.round(event.loaded / event.total * 100);
								console.log(`Uploaded! ${this.descriptionFiles[this.descriptionFiles.length - 1].progress}%`);
								$('#discription_progress_bar_' + (this.descriptionFiles.length - 1)).attr('aria-valuenow', this.descriptionFiles[this.descriptionFiles.length - 1].progress).css('width', this.descriptionFiles[this.descriptionFiles.length - 1].progress + '%');

								break;
							case HttpEventType.Response:
								let responseData: any = event.body;
								if (responseData.statusCode == 200) {
									this.descriptionImgArr.push(responseData.result);
									this.descriptionFiles[this.descriptionFiles.length - 1].serverFileName = responseData.result.fileTitle;
									this.descriptionFiles[this.descriptionFiles.length - 1].displayName = responseData.result.displayName;
									this_.descriptionFiles.forEach(element => {
										element.isProgressBarShown = false;
									});
									//this.getSprint();
								} else {
									if (responseData.message) {
										this.toastr.error(responseData.message);
									}
								}
								console.log('User successfully created!', event.body);
								setTimeout(() => {
									this.descriptionFiles[this.descriptionFiles.length - 1].progress = 0;
									$('#discription_progress_bar_' + (this.descriptionFiles.length - 1)).attr('aria-valuenow', this.descriptionFiles[this.descriptionFiles.length - 1].progress).css('width', this.descriptionFiles[this.descriptionFiles.length - 1].progress + '%');

								}, 1500);
						}
					})


				// this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', false, false)
				// 	.subscribe((result) => {
				// 		let responseData: any = result;
				// 		if (responseData.statusCode == 200) {
				// 			this.descriptionImgArr.push(responseData.result);
				// 			this.descriptionFiles[this.descriptionFiles.length - 1].serverFileName = responseData.result.fileTitle;
				// 			this.descriptionFiles[this.descriptionFiles.length - 1].displayName = responseData.result.displayName;
				// 			this_.descriptionFiles.forEach(element => {
				// 				element.isProgressBarShown = false;
				// 			});
				// 			//this.getSprint();
				// 		} else {
				// 			this.toastr.error(responseData.message);
				// 		}
				// 	}, (err) => {
				// 		console.log(err);
				// 		let errorData: any = err;
				// 		this.toastr.error(errorData.error.message);
				// 	})
			}
		}

	}
	download(url, fileName, $event) {
		$event.stopPropagation();
		this.restService.downloadFile(url, fileName);
	}
}

