import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';

// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Data } from './../ui-components/select-dropdown/Data';

// import { MatDialog } from '@angular/material';
// import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
import { variableDeclarator } from 'node_modules1111/@babel/types/lib';
declare const $: any;
@Component({
	selector: 'app-sprint-board',
	templateUrl: './sprint-board.component.html',
	styleUrls: ['./sprint-board.component.scss'],
})

export class SprintBoardComponent implements OnInit {
	sprintCreateForm: FormGroup;
	assignToForm: FormGroup;
	selectedSprint: any;
	sprintData: any = [];
	pid: any = localStorage.getItem('projectSelected');
	sid: any = localStorage.getItem('sprintIdSelected') ? localStorage.getItem('sprintIdSelected') : '';
	sname: any = localStorage.getItem('sprintNameSelected') ? localStorage.getItem('sprintNameSelected') : '';

	isOwner: any = JSON.parse(localStorage.getItem("isOwner"));
	isCompanyAdmin: any = JSON.parse(localStorage.getItem("isCompanyAdmin"));
	isProjectManager: any = JSON.parse(localStorage.getItem("isProjectManager"));

	projectListData: any;
	projectData: any;
	sprintList: any;
	allSprintList: any;
	userList: any;

	sprintBoardTodoData: any = [];
	sprintBoardInprogressData: any = [];
	sprintBoardQualitycheckData: any = [];
	sprintBoardDoneData: any = [];
	sprintBoardData: any = [];
	todoList: any = [];
	inProgressList: any = [];
	qualityCheckList: any = [];
	doneList: any = [];
	reopenList: any = [];
	notABugList: any = [];
	jqeryObject = $;
	assignUserList: any = [];
	subs = new Subscription();
	selectedIssue: any;
	isLoading: boolean = false;
	pendingIssuesCount: any = 0;
	toBeCompleteSprintArray: any = [];
	selectedSprintForMoveItem = 0;
	searchdata: any;
	selectAssign: any = {
		name: 'Unassigned',
		value: 0
	}

	constructor(
		private restService: RestService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private dragulaService: DragulaService) {
		// Check for route params

		if (this.pid && this.pid !== null && this.pid !== undefined) {
			this.getProjectDetail();
		}

		// this.route.params.subscribe(params => {
		// 	this.pid = params['pid'];
		// 	if (this.pid && this.pid !== null && this.pid !== undefined) {

		// 		this.getProjectDetail();	
		// 	}
		// });
		this.getProjectList();
		this.getSprintList();
		this.getUserList();
		if (this.sid) {
			this.getSprintBoardList();
			// this.getSprintBoardTodo();
			// this.getSprintBoardInProgress();
			// this.getSprintBoardQuality();
			// this.getSprintBoardDone();
		}

		//drag
		this.subs.add(this.dragulaService.drag("DRAGULA_EVENT")
			// .subscribe(({ name, el, target, source, sibling, sourceModel, targetModel, item }) => {
			.subscribe(({ name, el, source }) => {

				if (source.id == "todo") {

					$('.dragdroparea #inprogress').addClass('active');
					$('.dragdroparea #quality').addClass('active');
					$('.dragdroparea #done').addClass('active');

					$('.discuss-board-overflow-height#inprogress').hide();
					$('.discuss-board-overflow-height#quality').hide();
					$('.discuss-board-overflow-height#done').hide();
				} else if (source.id == "inprogress") {
					$('.dragdroparea #todo').addClass('active');
					$('.dragdroparea #quality').addClass('active');
					$('.dragdroparea #done').addClass('active');

					$('.discuss-board-overflow-height#todo').hide();
					$('.discuss-board-overflow-height#quality').hide();
					$('.discuss-board-overflow-height#done').hide();
				} else if (source.id == "quality") {
					$('.dragdroparea #inprogress').addClass('active');
					$('.dragdroparea #todo').addClass('active');
					$('.dragdroparea #done').addClass('active');

					$('.discuss-board-overflow-height#todo').hide();
					$('.discuss-board-overflow-height#inprogress').hide();
					$('.discuss-board-overflow-height#done').hide();
				} else if (source.id == "done") {
					$('.dragdroparea #inprogress').addClass('active');
					$('.dragdroparea #quality').addClass('active');
					$('.dragdroparea #todo').addClass('active');

					$('.discuss-board-overflow-height#todo').hide();
					$('.discuss-board-overflow-height#inprogress').hide();
					$('.discuss-board-overflow-height#quality').hide();
				}
			})
		);

		this.subs.add(this.dragulaService.cancel("DRAGULA_EVENT")
			// .subscribe(({ name, el, target, source, sibling, sourceModel, targetModel, item }) => {
			.subscribe(({ name, el, source }) => {
				$('.discuss-board-overflow-height#todo').show();
				$('.discuss-board-overflow-height#inprogress').show();
				$('.discuss-board-overflow-height#quality').show();
				$('.discuss-board-overflow-height#done').show();
				if (source.id == "todo") {
					$('.dragdroparea #inprogress').removeClass('active');
					$('.dragdroparea #quality').removeClass('active');
					$('.dragdroparea #done').removeClass('active');

				} else if (source.id == "inprogress") {
					$('.dragdroparea #todo').removeClass('active');
					$('.dragdroparea #quality').removeClass('active');
					$('.dragdroparea #done').removeClass('active');
				} else if (source.id == "quality") {
					$('.dragdroparea #inprogress').removeClass('active');
					$('.dragdroparea #todo').removeClass('active');
					$('.dragdroparea #done').removeClass('active');
				} else if (source.id == "done") {
					$('.dragdroparea #inprogress').removeClass('active');
					$('.dragdroparea #quality').removeClass('active');
					$('.dragdroparea #todo').removeClass('active');
				}
			})
		);
		//drag and drop
		this.subs.add(this.dragulaService.drop("DRAGULA_EVENT")
			// .subscribe(({ name, el, target, source, sibling, sourceModel, targetModel, item }) => {
			.subscribe(({ name, el, source, target }) => {
				console.log(source);
				console.log(target);
				$('.dragdroparea #todo').removeClass('active');
				$('.dragdroparea #inprogress').removeClass('active');
				$('.dragdroparea #quality').removeClass('active');
				$('.dragdroparea #done').removeClass('active');

				$('.discuss-board-overflow-height#todo').show();
				$('.discuss-board-overflow-height#inprogress').show();
				$('.discuss-board-overflow-height#quality').show();
				$('.discuss-board-overflow-height#done').show();

				if (source.id == "todo") {
					$('.dragdroparea #todo').html('');
				} else if (source.id == "inprogress") {
					$('.dragdroparea #inprogress').html('');
				} else if (source.id == "quality") {
					$('.dragdroparea #quality').html('');
				} else if (source.id == "done") {
					$('.dragdroparea #done').html('');
				}

				this.moveEntity(el.id, source, target);
			})
		);
		//drag and drop
	}

	ngOnInit() {

		$(document).ready(function () {
			$('.common-show-hidden-menu-horz').click(function () {
				$('.common-hidden-menu-horz').slideToggle("fast");
			});
			/*setDivHeights();*/
			$('.box').scrollable();
		});

		// height patch

		setInterval(function () { var maxHeight = 0; $("div.content-area").each(function () { if ($(this).height() > maxHeight) { maxHeight = $(this).height(); } }); $("div.content-area").height(maxHeight); }, 500);

		// height patch


		$(document).mouseup(function (e) {
			if (!$(".stop-add-watches").is(e.target) && $(".stop-add-watches").has(e.target).length === 0 && !$(".common-show-hidden-menu-horz").is(e.target) && $(".common-show-hidden-menu-horz").has(e.target).length === 0) {
				$(".stop-add-watches").slideUp("fast");
			}

			// if (!$("#select-sprint-open-dpw").is(e.target) && $("#select-sprint-open-dpw").has(e.target).length === 0 && !$("#select-sprint-dpw").is(e.target) && $("#select-sprint-dpw").has(e.target).length === 0) 
			// {
			// 	$("#select-sprint-open-dpw").slideToggle("fast");
			// }
		});

		this.createFormForAssignUser(null);

	}

	createFormForAssignUser(data) {
		if (data === null) {
			this.assignToForm = this.formBuilder.group({
				'assignName': ['', [Validators.required]],
				'assignId': ['', [Validators.required]],
			});

		} else {
			this.assignToForm = this.formBuilder.group({
				'assignName': [data.releaseName, [Validators.required]],
				'assignId': [data.releaseId, [Validators.required]],
			});
		}

	}

	getProjectDetail() {
		// this.restService.hitApi('project/details',{'projectId':this.pid},'post','',true)
		// .subscribe((result)=>{
		// 	let responseData : any = result;
		// 	if(responseData.statusCode==200){
		// 		this.projectData = responseData.result;
		// 	}else{
		// 		// this.toastr.error(responseData.message);
		// 	}
		// },(err)=>{
		// 	console.log(err);
		// 	let errorData : any = err;
		// 	// this.toastr.error(errorData.error.message);
		// })
	}

	getProjectList() {
		// this.restService.hitApi('discussion/projects',{},'get','',true)
		// .subscribe((result)=>{
		// 	let responseData : any = result;
		// 	if(responseData.statusCode==200){
		// 		this.projectListData = responseData.result.data;
		// 	}else{
		// 		// this.toastr.error(responseData.message);
		// 	}
		// },(err)=>{
		// 	console.log(err);
		// 	let errorData : any = err;
		// 	// this.toastr.error(errorData.error.message);
		// })
	}

	getSprintList() {
		this.restService.hitApi('sprint/sprintListDd', { "projectId": this.pid, "notCompleted": 1 }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					//this.sprintList = responseData.result;
					this.allSprintList = responseData.result;
					let lsprintList = [];

					responseData.result.forEach(element => {
						//if (element.activeSprint) {
						lsprintList.push(element)
						//}
					});

					this.sprintList = lsprintList;

					this.sprintList.forEach(element => {
						if (element.id == this.sid) {
							this.selectedSprint = element
							console.log("this.selectedSprint: ", this.selectedSprint)
							element.isSelected = true;
						} else {
							element.isSelected = false;
						}
					});

					if (this.sid == null || this.sid == undefined || this.sid == '') {
						this.sid = this.sprintList[0].id
						this.updateProjetAndSprint(this.sid)
					}

					this.makeToBeCompleteArray();

				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	makeToBeCompleteArray() {
		this.toBeCompleteSprintArray = [];
		for (let i = 0; i < this.allSprintList.length; i++) {
			let obj = this.allSprintList[i]
			if (this.selectedSprint?.id == obj.id) {
			} else {
				let lobj = { "name": obj.name, "value": obj.id }
				this.toBeCompleteSprintArray.push(lobj)
			}
		}
	}

	deleteSprint() {
		console.log("this.selectedIssue", this.selectedSprint);
		this.restService.hitApi('sprint/delete', { 'sprintId': this.selectedSprint.id }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('#delete-record').modal('hide');
					this.toastr.success(responseData.message);
					this.sprintDeletedRemoveAll();
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	sprintDeletedRemoveAll() {
		localStorage.removeItem('sprintIdSelected');
		localStorage.removeItem('sprintNameSelected');
		this.selectedSprint = null;
		this.getSprintList();
		this.todoList = [];
		this.inProgressList = [];
		this.qualityCheckList = [];
		this.doneList = [];
		this.reopenList = [];
		this.notABugList = [];
	}

	changeProject(pid) {
		localStorage.setItem('projectSelected', pid)
		$('.project-title-list').toggle("slide");
	}

	changeSprint(event) {
		console.log("eventeventevent", event.target.value);
		let lsid = event.target.value;

		if (lsid != "null") {

			this.updateProjetAndSprint(lsid)

		} else {
			this.sid = null;
			this.sname = null;
			this.sprintDeletedRemoveAll();
		}

	}

	updateProjetAndSprint(lsid) {
		this.restService.hitApi('user/updateProject', { 'project_id': this.pid, 'sprint_id': lsid }, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.toastr.success(responseData.message);
						localStorage.setItem('sprintIdSelected', lsid);

						this.sprintList.forEach(element => {
							if (element.id == lsid) {
								this.selectedSprint = element
								localStorage.setItem('sprintNameSelected', element.name);
							}
						});

						this.sid = lsid;

						this.getSprintBoardList();
						this.makeToBeCompleteArray();

					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
	}

	refreshAPI() {
		this.isLoading = true;
		this.getProjectList();
		this.getSprintList();
		this.getUserList();
		this.getSprintBoardList();

		setTimeout(() => {
			this.toastr.success("Refreshed successfully");
		}, 1000);
	}

	searchData() {
		if (this.sid) {
			this.searchdata = $('#search_name').val();
			this.getSprintBoardList();
		} else {
			this.toastr.error("Please select sprint to search issues");
		}

	}

	getSprintBoardList() {
		this.isLoading = true;
		this.sprintBoardData = [];
		this.restService.hitApi('sprint/sprintBoardBacklog', { "projectId": this.pid, "sprintId": this.sid, "keyword": this.searchdata }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.sprintBoardData = responseData.result;

					console.log("this.sprintBoardData: ", this.sprintBoardData);
					this.todoList = [];
					this.inProgressList = [];
					this.qualityCheckList = [];
					this.doneList = [];
					this.reopenList = [];
					this.notABugList = [];

					this.sprintBoardData.backlog.forEach(element => {
						if (element.status == 1) {
							this.todoList.push(element);
						} else if (element.status == 2) {
							this.inProgressList.push(element);
						} else if (element.status == 3) {
							this.qualityCheckList.push(element);
						} else if (element.status == 4) {
							this.doneList.push(element);
						} else if (element.status == 5) {
							this.reopenList.push(element);
						} else if (element.status == 6) {
							this.notABugList.push(element);
						}
					});

					this.pendingIssuesCount = this.todoList.length + this.inProgressList.length + this.qualityCheckList.length + this.reopenList.length + this.notABugList.length


					console.log("this.todoList: ", this.todoList);

				} else {
					// this.toastr.error(responseData.message);
				}
				this.isLoading = false;
			}, (err) => {
				this.isLoading = false;
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	// getSprintBoardTodo(){
	// 	this.sprintBoardTodoData = [];
	// 	this.restService.hitApi('sprints/sprintBoard',{"projectId":this.pid,"sprintId":this.sid,"status":1},'post','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.sprintBoardTodoData = responseData.result;
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }

	// getSprintBoardInProgress(){
	// 	this.sprintBoardInprogressData = [];
	// 	this.restService.hitApi('sprints/sprintBoard',{"projectId":this.pid,"sprintId":this.sid,"status":2},'post','',false)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.sprintBoardInprogressData = responseData.result;
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }

	// getSprintBoardQuality(){
	// 	this.sprintBoardQualitycheckData = [];
	// 	this.restService.hitApi('sprints/sprintBoard',{"projectId":this.pid,"sprintId":this.sid,"status":3},'post','',false)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.sprintBoardQualitycheckData = responseData.result;
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }

	// getSprintBoardDone(){
	// 	this.sprintBoardDoneData = [];
	// 	this.restService.hitApi('sprints/sprintBoard',{"projectId":this.pid,"sprintId":this.sid,"status":4},'post','',false)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.sprintBoardDoneData = responseData.result;
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }

	selectedSprintForMoveIssues(event) {
		if (event != null) {
			this.selectedSprintForMoveItem = event.target.value
		}

		console.log("selectedSprintForMoveItem ", this.selectedSprintForMoveItem);
	}

	getUncloseddata() {
		if (this.pendingIssuesCount > 0) {
			$('#update-status').modal('hide');
			$('#create_close-sprint').modal('show');
		} else {
			$('#update-status').modal('show');
			$('#create_close-sprint').modal('hide');
		}
	}

	closeSprint() {
		let data: any = { 'projectId': this.pid, 'sprintId': this.selectedSprint.id };
		if (this.selectedSprintForMoveItem != 0) {
			data["newSprintId"] = this.selectedSprintForMoveItem
		}

		this.restService.hitApi('sprint/closeSprint', data, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				this.selectedSprintForMoveItem = 0;
				if (responseData.statusCode == 200) {
					$('#create_close-sprint').modal('hide');
					$('#update-status').modal('hide');
					this.getSprintList();
					this.sprintDeletedRemoveAll();
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	getUserList() {
		this.restService.hitApi('project/projectUsers', { "projectId": this.pid }, 'post', '', false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.userList = responseData.result;
					this.assignUserList = [];
					console.log("userList, :", this.userList);
					this.userList.forEach(element => {
						let data = new Data(element.name, element.userId);
						this.assignUserList.push(data);
					});
					console.log("userList, :", this.userList);

				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	assignUser(id, uid, entity, status) {
		if (uid != null && uid != undefined && uid != '') {
			this.restService.hitApi('sprints/sprintBoardUpdateAssignee', { "projectId": this.pid, "userId": uid, "id": id, "entity": entity }, 'post', '', false)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						$('#' + entity + '-' + id).toggle('down');
						this.getSprintBoardList();
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		} else {
			this.toastr.error("Please select user");
		}

	}

	moveEntity(entityId, source, target) {
		let state = entityId.split("-")[0];
		let entity = entityId.split("-")[1];
		let id = entityId.split("-")[2];
		let status: any = 0;
		if (target.id == 'todo')
			status = 1;
		else if (target.id == 'inprogress')
			status = 2;
		else if (target.id == 'quality')
			status = 3;
		else if (target.id == 'done')
			status = 4;


		console.log(target.id);
		console.log(source.id);
		console.log(entity); // 
		console.log(id); //jo move ho rha hai
		this.restService.hitApi('sprints/sprintBoardUpdateStatus', { "projectId": this.pid, "status": status, "entity": entity, "id": id }, 'post', '', false)
			.subscribe((result) => {
				let responseData: any = result;

				if (responseData.statusCode == 200) {
					if (target.id == 'todo') {
						if (source.id == 'inprogress') {
							let index = this.sprintBoardInprogressData[entity].findIndex(us => us.id == id);
							this.sprintBoardTodoData[entity].push(this.sprintBoardInprogressData[entity][index]);
							this.sprintBoardInprogressData[entity].splice(index, 1);
						}
						if (source.id == 'quality') {
							let index = this.sprintBoardQualitycheckData[entity].findIndex(us => us.id == id);
							this.sprintBoardTodoData[entity].push(this.sprintBoardQualitycheckData[entity][index]);
							this.sprintBoardQualitycheckData[entity].splice(index, 1);
						}
						if (source.id == 'done') {
							let index = this.sprintBoardDoneData[entity].findIndex(us => us.id == id);
							this.sprintBoardTodoData[entity].push(this.sprintBoardDoneData[entity][index]);
							this.sprintBoardDoneData[entity].splice(index, 1);
						}
					}
					if (target.id == 'inprogress') {
						if (source.id == 'todo') {
							let index = this.sprintBoardTodoData[entity].findIndex(us => us.id == id);
							this.sprintBoardInprogressData[entity].push(this.sprintBoardTodoData[entity][index]);
							this.sprintBoardTodoData[entity].splice(index, 1);
						}
						if (source.id == 'quality') {
							let index = this.sprintBoardQualitycheckData[entity].findIndex(us => us.id == id);
							this.sprintBoardInprogressData[entity].push(this.sprintBoardQualitycheckData[entity][index]);
							this.sprintBoardQualitycheckData[entity].splice(index, 1);
						}
						if (source.id == 'done') {
							let index = this.sprintBoardDoneData[entity].findIndex(us => us.id == id);
							this.sprintBoardInprogressData[entity].push(this.sprintBoardDoneData[entity][index]);
							this.sprintBoardDoneData[entity].splice(index, 1);
						}
					}
					if (target.id == 'quality') {
						if (source.id == 'todo') {
							let index = this.sprintBoardTodoData[entity].findIndex(us => us.id == id);
							this.sprintBoardQualitycheckData[entity].push(this.sprintBoardTodoData[entity][index]);
							this.sprintBoardTodoData[entity].splice(index, 1);
						}
						if (source.id == 'inprogress') {
							let index = this.sprintBoardInprogressData[entity].findIndex(us => us.id == id);
							this.sprintBoardQualitycheckData[entity].push(this.sprintBoardInprogressData[entity][index]);
							this.sprintBoardInprogressData[entity].splice(index, 1);
						}
						if (source.id == 'done') {
							let index = this.sprintBoardDoneData[entity].findIndex(us => us.id == id);
							this.sprintBoardQualitycheckData[entity].push(this.sprintBoardDoneData[entity][index]);
							this.sprintBoardDoneData[entity].splice(index, 1);
						}
					}
					if (target.id == 'done') {
						if (source.id == 'todo') {
							let index = this.sprintBoardTodoData[entity].findIndex(us => us.id == id);
							this.sprintBoardDoneData[entity].push(this.sprintBoardTodoData[entity][index]);
							this.sprintBoardTodoData[entity].splice(index, 1);
						}
						if (source.id == 'inprogress') {
							let index = this.sprintBoardInprogressData[entity].findIndex(us => us.id == id);
							this.sprintBoardDoneData[entity].push(this.sprintBoardInprogressData[entity][index]);
							this.sprintBoardInprogressData[entity].splice(index, 1);
						}
						if (source.id == 'quality') {
							let index = this.sprintBoardQualitycheckData[entity].findIndex(us => us.id == id);
							this.sprintBoardDoneData[entity].push(this.sprintBoardQualitycheckData[entity][index]);
							this.sprintBoardQualitycheckData[entity].splice(index, 1);
						}
					}

					// if(target.id=='todo' || source.id=='todo')
					// 	this.getSprintBoardTodo();
					// if(target.id=='inprogress' || source.id=='inprogress')
					// 	this.getSprintBoardInProgress();
					// if(target.id=='quality' || source.id=='quality')
					// 	this.getSprintBoardQuality();
					// if(target.id=='done' || source.id=='done')
					// 	this.getSprintBoardDone();
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	getAssign(event) {

		if (event) {
			let id = event.target.value;

			this.selectAssign.value = id

			//if 
			//console.log("selectedId" + obj.value);
			//this.assignToForm.get('assignId').setValue(parseInt(id));
			//this.assignToForm.get('assignName').setValue(parseInt(obj.name));

		}
	}

	closeModal() {
		this.selectAssign.name = "Unassigned"
		this.selectAssign.value = 0
		//this.assignToForm.reset();
		$('#assign_to_user_popup_web').modal('hide');
	}

	setSelectedAssign(obj: any) {
		console.log("assignId ", parseInt(obj.id), " " + obj?.assignee);
		this.selectedIssue = obj;
		// this.assignToForm.get('assignId').setValue(obj.assigneeId);
		// this.assignToForm.get('assignName').setValue(obj?.assignee);

		if (obj.assignee) {
			this.selectAssign.name = obj?.assignee
			this.selectAssign.value = obj?.assigneeId
		}

	}

	makeAssign() {

		var userID = this.selectAssign.value;
		if (this.selectAssign.value == 0) {
			userID = null;
		}
		console.log("userIDuserIDuserID", userID);
		//if (userID != '' && userID != null && userID != undefined && userID != 'NaN') {
		if (this.selectedIssue.assigneeId != userID) {
			var entityType = this.selectedIssue.entityType;
			let id = this.selectedIssue.id;

			console.log("my data: ", this.assignToForm.value, " " + userID, " ", entityType, " ", id);

			if (this.selectedIssue.entityType == "userStory") {
				entityType = "userStories"
			} else if (this.selectedIssue.entityType == "task") {
				entityType = "tasks"
			} else if (this.selectedIssue.entityType == "defect") {
				entityType = "defects"
			} else if (this.selectedIssue.entityType == "testPlan") {
				entityType = "testPlans"
			}

			this.restService.hitApi('sprints/sprintBoardUpdateAssignee', { "projectId": this.pid, "userId": userID, "id": id, "entity": entityType }, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					this.closeModal();
					if (responseData.statusCode == 200) {
						this.getSprintBoardList();
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		} else {
			if (userID == null) {
				this.toastr.error("Please choose user to assign.");
			} else {
				this.toastr.error("This issue is already assign to this user, please choose another");
			}

		}
		// } else {
		// 	this.toastr.error("Please choose user");
		// }

	}

	drop(event: CdkDragDrop<string[]>) {
		console.log("event: ", event);
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			transferArrayItem(event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex);
		}

		console.log("event: ", event);

		let id = null;
		var sprintId = "";
		var entity = "";
		var status = 0;

		if (event.container.data != null) {
			let obj: any = event.container.data[event.currentIndex];
			entity = obj.entityType;
			id = obj.id.toString();
		}

		if (event.container.id != null) {

			if (event.container.id == "reopen" || event.container.id == "notABug") {
				if (entity != "defect") {
					transferArrayItem(event.container.data,
						event.previousContainer.data,
						event.currentIndex,
						event.previousIndex);

					if (event.container.id == "reopen") {
						this.toastr.error("Only bugs are allowed to move into reopen");
					} else {
						this.toastr.error("Only bugs are allowed to move into not a bug");
					}

					return
				}
			}

			if (event.container.id == "todo") {
				status = 1;
			} else if (event.container.id == "inProgress") {
				status = 2;
			} else if (event.container.id == "qualityCheck") {
				status = 3;
			} else if (event.container.id == "done") {
				status = 4;
			} else if (event.container.id == "reopen") {
				status = 5;
			} else if (event.container.id == "notABug") {
				status = 6;
			}

		}

		//userStory, task, defect, testPlan

		let data = { "projectId": this.pid, "status": status, "entity": entity, "id": id }
		console.log("datadatadata, ", data);

		this.restService.hitApi('sprints/sprintBoardUpdateStatus', data, 'post', '', false)
			.subscribe((result) => {
				let responseData: any = result;

				if (responseData.statusCode == 200) {
					// setTimeout(() => {
					// 	this.getSprintBoardList();
					//   }, 200);
					this.pendingIssuesCount = this.todoList.length + this.inProgressList.length + this.qualityCheckList.length + this.reopenList.length + this.notABugList.length
				} else {
					this.toastr.error(responseData.message);
				}

			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})

	}



}

