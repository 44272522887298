import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { ValidationService } from '../services/config/config.service';
import { RestService } from '../services/rest/rest.service';
import { ToastrService } from 'ngx-toastr';
import { WindowRefService } from '../window-ref.service';
import { BroadcastService } from '../services/broadcast/broadcast.service';

// import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';

declare const $: any;

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss'],

	// animations: [routerTransition()],
	// host: { '[@routerTransition]': '' }
})
export class RegisterComponent implements OnInit {
	registerForm: FormGroup;
	errorMessage: string;
	privacyContent: string;
	termsContent: string;

	userEmail: string = "";
	alreadyRegister: boolean;
	userData: any;
	subscription_id: any;
	formSubmit: boolean = false;
	passwordType: string = 'Password'
	isPasswordHide: boolean;
	isFormSubmit: boolean = false;
	selectedPackage: any;
	subscriptionData: any;
	siteKey: string;
	paymentData: any;
	packageTypeData: any;
	oldPasswordInputType: any = 'password';
	newPasswordInputType: any = 'password';
	confirmPasswordInputType: any = 'password';

	// public payPalConfig?: IPayPalConfig;
	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private restService: RestService,
		private toastr: ToastrService,
		// private socialAuthService: AuthService,
		private route: ActivatedRoute,
		// private modalService : NgbModal,
		private winRef: WindowRefService,
		private broadcastService: BroadcastService
	) {
		$("html, body").animate({ scrollTop: 0 }, "slow");
		this.siteKey = this.restService.getCaptchaSiteKey();

		this.router.events.subscribe(() => {
			const navigation = this.router.getCurrentNavigation();
			console.log('navigation.extras.state', navigation.extras.state);
			this.paymentData = navigation.extras.state;
		});

		this.route.queryParams.subscribe(params => {
			const paramemail = params['email'];
			this.userEmail = paramemail;
		});
		if (this.userEmail) {
			console.log("inside", this.userEmail);
			this.registerForm = this.formBuilder.group({
				password: ['', [Validators.required, ValidationService.passwordValidator]],
				confirm_password: ['', [Validators.required, ValidationService.passwordValidator]],
				deviceInfo: ['web'],
			});
		} else {
			this.registerForm = this.formBuilder.group({
				companyName: ['', [Validators.required]],
				name: ['', [Validators.required]],
				email: ['', [Validators.required, ValidationService.emailValidator]],
				password: ['', [Validators.required, ValidationService.passwordValidator]],
				tnc: ['', [Validators.required]],
				recaptchaToken: ['', [Validators.required]],
				deviceInfo: ['web']
			});
			// if (!localStorage.getItem('packageId')) {
			// 	this.router.navigate(['/'])
			// }
			console.log("outside", this.registerForm);
		}

		console.log("packageid====" + localStorage.getItem('packageId'));
		this.selectedPackage = localStorage.getItem('packageId');
	}

	// Check if user already logged in
	ngOnInit() {





		// this.createRzpayOrder('sub_EYuE3FVFXihQxF');
		// localStorage.removeItem('userData');
		// if (localStorage.getItem('userData') && JSON.parse(localStorage.getItem('userData')).subscriptionId) {
		if (localStorage.getItem('userData')) {
			let userData = JSON.parse(localStorage.getItem('userData'))
			this.router.navigate(['/dashboard'])
		}
		this.getPrivacyTerms();

		$('.show-pwd').click(function () {
			console.log('hi');
			if ($(this).hasClass('disabled')) {
				$(this).removeClass('disabled');
				$(this).prev().attr('type', 'text');
			} else {
				$(this).addClass('disabled');
				$(this).prev().attr('type', 'password');
			}
		})

		$(".toggle-password").click(function () {
			$(this).toggleClass("fa-eye fa-eye-slash");
			var input = $($(this).attr("toggle"));
			if (input.attr("type") == "password") {
				input.attr("type", "text");
			} else {
				input.attr("type", "password");
			}
		});
		$(".toggle-confirm-password").click(function () {
			$(this).toggleClass("fa-eye fa-eye-slash");
			var input = $($(this).attr("toggle"));
			if (input.attr("type") == "password") {
				input.attr("type", "text");
			} else {
				input.attr("type", "password");
			}

		})

		if (this.userEmail) {
			this.checkLogin();
		}

		this.getSubscriptions();
		this.getPackageList();
		// $('#razorpayform').submit();
		// this.initConfig();


	}

	getPackageList() {
		this.restService.hitApi('users/packageList', null, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.packageTypeData = responseData.result;
					console.log("this.packageTypeData: ", this.packageTypeData)
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	getSubscriptions() {
		console.log($('#subscription_term').is(':checked'));
		let frequency = $('#subscription_term').is(':checked') ? 1 : 2;
		this.restService.hitApi('users/subscription', { 'frequency': frequency }, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					//	this.subscriptionData = responseData.result;
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	// private initConfig(): void {
	// 	this.payPalConfig = {
	// 	currency: 'EUR',
	// 	clientId: 'sb',
	// 	createOrderOnClient: (data) => <ICreateOrderRequest>{
	// 	  intent: 'CAPTURE',
	// 	  purchase_units: [
	// 		{
	// 		  amount: {
	// 			currency_code: 'EUR',
	// 			value: '9.99',
	// 			breakdown: {
	// 			  item_total: {
	// 				currency_code: 'EUR',
	// 				value: '9.99'
	// 			  }
	// 			}
	// 		  },
	// 		  items: [
	// 			{
	// 			  name: 'Enterprise Subscription',
	// 			  quantity: '1',
	// 			  category: 'DIGITAL_GOODS',
	// 			  unit_amount: {
	// 				currency_code: 'EUR',
	// 				value: '9.99',
	// 			  },
	// 			}
	// 		  ]
	// 		}
	// 	  ]
	// 	},
	// 	advanced: {
	// 	  commit: 'true'
	// 	},
	// 	style: {
	// 	  label: 'paypal',
	// 	  layout: 'vertical'
	// 	},
	// 	onApprove: (data, actions) => {
	// 	  console.log('onApprove - transaction was approved, but not authorized', data, actions);
	// 	  actions.order.get().then(details => {
	// 		console.log('onApprove - you can get full order details inside onApprove: ', details);
	// 	  });
	// 	},
	// 	onClientAuthorization: (data) => {
	// 	  console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
	// 	//   this.showSuccess = true;
	// 	},
	// 	onCancel: (data, actions) => {
	// 	  console.log('OnCancel', data, actions);
	// 	},
	// 	onError: err => {
	// 	  console.log('OnError', err);
	// 	},
	// 	onClick: (data, actions) => {
	// 	  console.log('onClick', data, actions);
	// 	},
	//   };

	// }

	checkLogin() {
		this.restService.hitApi('users/is-user-signed-up', { 'email': this.userEmail }, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.alreadyRegister = responseData.result;
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				// this.errorMessage = err.error.message;
				// this.toastr.error(err.error.message);
			})
	}

	createPassword() {
		this.isFormSubmit = true;
		if (!(this.registerForm.value.email) && this.userEmail) {
			this.registerForm.value.email = this.userEmail;
		}

		this.restService.hitApi('create-password', this.registerForm.value, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					localStorage.setItem('registerResponse', responseData.result);
					localStorage.setItem('userData', JSON.stringify(responseData.result));
					localStorage.setItem('company_id', responseData.result.companyId.toString());
					localStorage.setItem('company_name', responseData.result.companyName);


					if (this.userEmail) {
						this.toastr.success(responseData.message);

						let userData = JSON.parse(localStorage.getItem('userData'));
						//this.broadcastService.boradcast("FATCH_USER_DATA_GO_TO_DASHBOARD", userData)
						this.router.navigate(['/login']);

					}
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				// this.errorMessage = err.error.message;
				this.toastr.error(err.error.message);
			})
	}

	doRegister() {
		this.isFormSubmit = true;
		localStorage.removeItem('userData');
		localStorage.removeItem("projectData");
		localStorage.removeItem('companyData');
		localStorage.removeItem('company_id');
		localStorage.removeItem('company_name');
		localStorage.removeItem('projectSelected');
		localStorage.clear();


		if (!(this.registerForm.value.email) && this.userEmail) {
			this.registerForm.value.email = this.userEmail;
		} else {
			this.registerForm.value.packageId = localStorage.getItem('packageId');
		}

		if (this.paymentData) {
			console.log("this.paymentData.value: ", this.paymentData)
			if (this.paymentData.package == "monthly") {
				this.registerForm.value.packageId = this.packageTypeData["monthly"].id
			} else if (this.paymentData.package == "yearly") {
				this.registerForm.value.packageId = this.packageTypeData["yearly"].id
			} else {
				this.registerForm.value.packageId = this.packageTypeData["free"].id
			}

			this.registerForm.value.minStorage = this.paymentData.minStorage
			this.registerForm.value.minUsers = this.paymentData.minUsers
		} else {
			this.registerForm.value.packageId = this.packageTypeData["free"].id
		}

		console.log("this.registerForm.value: ", this.registerForm.value)

		this.restService.hitApi('signUp', this.registerForm.value, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					localStorage.setItem('registerResponse', responseData.result);
					localStorage.setItem('userData', JSON.stringify(responseData.result));
					localStorage.setItem('company_id', responseData.result.companyId.toString());
					localStorage.setItem('company_name', responseData.result.companyName);

					// this.restService.getCompanies();

					if (this.userEmail) {
						//this.router.navigate(['dashboard']);
						this.toastr.success(responseData.message);
						// setTimeout(() => {
						// 	location.reload();
						// }, 100);

						let userData = JSON.parse(localStorage.getItem('userData'));
						//this.broadcastService.boradcast("FATCH_USER_DATA_GO_TO_DASHBOARD", userData)
						this.router.navigate(['/login']);
						// setTimeout(() => {
						// 	location.reload();
						// }, 100);
					}
					else {
						this.userData = JSON.parse(localStorage.getItem('userData'));
						this.setSubscription();
						// this.router.navigate(['user-management']);
						// this.router.navigate(['payment']);
					}
					// this.router.navigate(['dashboard']);



				} else {
					grecaptcha.reset();
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				grecaptcha.reset();
				// this.errorMessage = err.error.message;
				this.toastr.error(err.error.message);
			})


		// this.getRecaptchaToken().then(v => {

		// 	this.registerForm.get('recaptchaToken').setValue(v);
		// 	this.restService.hitApi('signUp', this.registerForm.value, 'post', '', true)
		// 		.subscribe((result) => {
		// 			let responseData: any = result;
		// 			if (responseData.statusCode == 200) {
		// 				// localStorage.setItem('registerResponse', responseData.result);
		// 				localStorage.setItem('userData', JSON.stringify(responseData.result));
		// 				localStorage.setItem('company_id', responseData.result.companyId.toString());
		// 				localStorage.setItem('company_name', responseData.result.companyName);

		// 				// this.restService.getCompanies();

		// 				if (this.userEmail) {
		// 					this.router.navigate(['dashboard']);
		// 					this.toastr.success(responseData.message);
		// 					setTimeout(() => {
		// 						location.reload();
		// 					}, 100);
		// 				}
		// 				else {
		// 					this.userData = JSON.parse(localStorage.getItem('userData'));
		// 					this.setSubscription();
		// 					// this.router.navigate(['user-management']);
		// 					// this.router.navigate(['payment']);
		// 				}
		// 				// this.router.navigate(['dashboard']);



		// 			} else {
		// 				this.toastr.error(responseData.message);
		// 			}
		// 		}, (err) => {
		// 			// this.errorMessage = err.error.message;
		// 			this.toastr.error(err.error.message);
		// 		})



		// });


	}

	onSubmit(token) {
		this.registerForm.get('recaptchaToken').setValue(token);
	}

	callApiForToken() {
		return new Promise(function (resolve, reject) {
			grecaptcha.ready(function () {
				grecaptcha.execute('6Lda-6caAAAAANaqQsn1PUA7sg25-Ll_bh1zsIzY', { action: 'signUp' }).then(function (token) {
					resolve(token);

				});

			});

		});
	}

	getRecaptchaToken = async function () {
		var tokenValue = await this.callApiForToken();
		return tokenValue;


	}


	setSubscription() {
		//users/set-subscription-package

		this.restService.hitApi('users/set-subscription-package', null, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					if (responseData.result.paymentPopup == true) {
						this.subscription_id = responseData.result.id;
						this.subscriptionData = responseData.result
						this.createRzpayOrder(this.subscription_id);
						$('#razorpayform').submit();

						console.log("this.subscriptionData: ", this.subscriptionData)
					} else {
						localStorage.removeItem('packageId')
						let userData = JSON.parse(localStorage.getItem('userData'));
						//this.broadcastService.boradcast("FATCH_USER_DATA_GO_TO_DASHBOARD", userData)
						// setTimeout(() => {
						// 	location.reload();
						// }, 100);

						this.router.navigate(['/login']);

						// this.router.navigate(['dashboard']);
						// setTimeout(() => {
						// 	location.reload();
						// }, 100);
						this.toastr.success(responseData.message);
					}

					// if (responseData.result.id) {
					// 	this.subscription_id = responseData.result.id;
					// 	this.createRzpayOrder(this.subscription_id);
					// 	$('#razorpayform').submit();
					// 	// this.router.navigate(['payment']);
					// } else {
					// 	localStorage.removeItem('packageId')
					// 	this.router.navigate(['dashboard']);
					// 	setTimeout(() => {
					// 		location.reload();
					// 	}, 100);
					// 	this.toastr.success(responseData.message);
					// }
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				// this.errorMessage = err.error.message;
				this.toastr.error(err.error.message);
			})
	}



	/*public socialSignIn(socialPlatform : string) {
		let socialPlatformProvider;
		if(socialPlatform == "google"){
		  socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
		} 
		this.socialAuthService.signIn(socialPlatformProvider).then(
			(userData) => {
			console.log(socialPlatform+" sign in data : " , userData);
			let socialUserData : any = userData;
			console.log(socialUserData.id);
			let registerData = {
				'socialId' : socialUserData.id,
				'socialToken' : socialUserData.token,
				'email' : socialUserData.email,
				'name' : socialUserData.name,
				'image' : socialUserData.image,
				'deviceInfo':'web'
			}
			this.restService.hitApi('socialLogin',registerData,'post','',true)
				.subscribe((result)=>{
					let responseData : any = result;
					if(responseData.statusCode==200){
						localStorage.setItem('userData', JSON.stringify(responseData.result));
						this.router.navigate(['/project-add/']);
						// this.toastr.success('Success', 'Register Successfully');
					}else{
						this.errorMessage = responseData.message;
					}
				},(err)=>{
					this.errorMessage = err.error.message;
			})

			}
		);
	}*/

	getPrivacyTerms() {
		this.restService.hitApi('termsConditions', {}, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.privacyContent = responseData.result;
				} else {
					this.errorMessage = responseData.message;
				}
			}, (err) => {
				this.errorMessage = err.error.message;
			});

		this.restService.hitApi('privacyPolicy', {}, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.termsContent = responseData.result;
				} else {
					this.errorMessage = responseData.message;
				}
			}, (err) => {
				this.errorMessage = err.error.message;
			})
	}

	// openModal(content){
	// 	this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
	// 		// this.assocUsers = this.projectListData[0].projectUsersProject;
	// 		}, (reason) => {
	// 		// this.inviteForm.reset();
	// 	});
	// }

	// closeModal(){
	// 	this.modalService.dismissAll();
	// }





	createRzpayOrder(data) {
		//console.log(data);
		this.payWithRazor();
	}

	payWithRazor() {

		console.log("this.subscriptionData ", this.subscriptionData)
		const options: any = {
			//key: 'rzp_test_ycqUFb222gYfqs', //dev test
			key: 'rzp_live_7a5MZfvNIYtULv', // prod key
			amount: this.subscriptionData.order.amount, // amount should be in paise format to display Rs 1255 without decimal point
			currency: this.subscriptionData.order.currency,
			name: 'Trakk.in', // company name or product name
			description: '',  // product description
			image: 'https://i.imgur.com/PIIAjMe.png', // company logo or product image
			// subscription_id: val,
			order_id: this.subscriptionData.order.id,
			save: 1,// order_id created by you in backend
			modal: {
				// We should prevent closing of the form when esc key is pressed.
				escape: false,
			},
			notes: {
				// include notes if any
			},
			theme: {
				color: '#0c238a'
			}
		};
		options.handler = ((response, error) => {
			//   options.response = response;
			console.log("razo pay resonse:", response);
			//   console.log(options);


			let requestData: any = { 'trakk_order_id': this.subscriptionData.order.id, 'razorpay_payment_id': response.razorpay_payment_id, 'razorpay_order_id': response.razorpay_order_id, 'razorpay_signature': response.razorpay_signature };
			//   let generated_signature = hmac_sha256(response.subscription_id + "|" + response.razorpay_payment_id)
			this.restService.hitApi('users/verify-signature', requestData, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						console.log("success got from verify-signature")
						// localStorage.setItem('userData', JSON.stringify(responseData.result));
						// localStorage.setItem('company_id', responseData.result.companyId.toString());
						// localStorage.setItem('company_name', responseData.result.companyName);
						// localStorage.removeItem('packageId');

						// this.router.navigate(['dashboard']);
						// setTimeout(() => {
						// 	location.reload();
						// }, 100);

						let userData = JSON.parse(localStorage.getItem('userData'));
						//this.broadcastService.boradcast("FATCH_USER_DATA_GO_TO_DASHBOARD", userData)
						this.broadcastService.boradcast("showLoading");

						this.router.navigate(['/login']);

						// setTimeout(() => {
						// 	location.reload();
						// }, 100);

						this.toastr.success(responseData.message);
					} else {
						this.errorMessage = responseData.message;
						//this.toastr.error(this.errorMessage);
					}
				}, (err) => {
					this.errorMessage = err.error.message;
					//this.toastr.error(this.errorMessage);
				})
			// call your backend api to verify payment signature & capture transaction
			// let userdata : any = localStorage.getItem('registerResponse');
			// localStorage.setItem('userData', JSON.stringify(userdata));
			// localStorage.setItem('company_id',userdata.companyId.toString());
			// localStorage.setItem('company_name',userdata.companyName);
			// this.router.navigate(['/']);
			// this.toastr.success(userdata.message);
		});
		options.modal.ondismiss = (() => {
			// handle the case when user closes the form while transaction is in progress
			console.log('Transaction cancelled.');
			//localStorage.clear();
			//this.toastr.error("Payment Transaction is failed. You can siginin now and pay again.");
			this.showAleart()
			setTimeout(() => {
				this.restService.clearAllAndGoToLogin();
			}, 100);

		});
		const rzp = new this.winRef.nativeWindow.Razorpay(options);
		rzp.open();
	}

	showAleart() {
		this.toastr.success("You have been successfully signed up. Login to continue ...");
	}

	toggle_eyes(inputType) {
		// if ($('input[name=password]').prop("type") == 'password') {
		// 	$('.fa').removeClass('fa-eye-slash');
		// 	$('.fa').addClass('fa-eye');
		// 	$('input[name=password]').prop("type", "text");
		// } else {
		// 	$('.fa').addClass('fa-eye-slash');
		// 	$('.fa').removeClass('fa-eye');
		// 	$('input[name=password]').prop("type", "password");
		// }

		if (inputType == 1) {
			if (this.newPasswordInputType == 'password') {
				this.newPasswordInputType = 'text'
			} else {
				this.newPasswordInputType = 'password'
			}
		} else if (inputType == 2) {
			if (this.confirmPasswordInputType == 'password') {
				this.confirmPasswordInputType = 'text'
			} else {
				this.confirmPasswordInputType = 'password'
			}
		}


	}

	isPasswordVisible() {
		if (this.passwordType == 'Password') {
			this.passwordType = 'text';
			this.isPasswordHide = false;
		} else {
			this.passwordType = 'Password';
			this.isPasswordHide = true;
		}
	}

}


