import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WindowRefService } from '../window-ref.service';

import { RestService } from '../services/rest/rest.service';
import { CookieService } from 'angular2-cookie/services/cookies.service';
declare const $:any;
@Component({
	selector: 'app-subscription-failed',
	templateUrl: './subscription-failed.component.html',
	styleUrls: ['./subscription-failed.component.scss'],
	providers:[CookieService]
})
export class SubscriptionFailedComponent implements OnInit {
	
	userData : any = JSON.parse(localStorage.getItem("userData"));
	constructor(
		private router: Router, 
		private restService: RestService,
		private toastr : ToastrService,
		private winRef : WindowRefService
	) {
		
	}

	ngOnInit() {
		if (!localStorage.getItem('userData')){
			this.router.navigate(['/login']);
		}
		console.log(this.userData);
	}

	
	

}
