import { Component, OnInit } from '@angular/core';
import { RouteEventsService } from '../services/route-events.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contact-us-home',
  templateUrl: './contact-us-home.component.html',
  styleUrls: ['./contact-us-home.component.scss']
})
export class ContactUsHomeComponent implements OnInit {

  constructor(private router: Router,
		private route: ActivatedRoute,
    private routeEventsService: RouteEventsService,) { }

  ngOnInit() {
  }

  backToRetun() {
		this.router.navigate([this.routeEventsService.previousRoutePath.value]);
	}

}
