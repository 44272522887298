import { PageEvent } from '@angular/material/paginator';
import { Injectable } from '@angular/core';


interface PreservedSearchState {
  searchValue: string;
  pageEvent: PageEvent;
  results: any[];
  resultsLength: number;
}

interface PreservedFilterScreenState {
  searchValue: string;
  projectId: any;
  pageEvent: PageEvent;
  selectedType: any;
  selectedStatus: any;
  selectedAssignee: any;
  selectedReporter: any;
  selectedPriority: any;
  selectedDateType: any;
  assigneeList: any[];
  reporterList: any[];
  typeList: any[],
  statusList: any[],
  priorityList: any[],
  createdAt: any,
  updatedAt: any

}

interface PreservedTabState {
  tab: number
}
interface PreservedRedirectUrlState {
  url: string;
  data: any;
}
interface PreservedAdminDashboardState {

  accountTransactionPageEvent: PageEvent;
  projectListPageEvent: PageEvent;
}
interface PreservedUserDashboardState {
  defectReportPageEvent: PageEvent;
  assignedToMePageEvent: PageEvent;
  watchedIssuePageEvent: PageEvent;
  selectedSprint: any;
}
interface PreservedClientDashboardState {
  assigneeByPageEvent: PageEvent,
  assignedMePageEvent: PageEvent
}


@Injectable({
  providedIn: 'root'
})
export class PreservedSearchStateService {
  private lastDefectFilter: PreservedSearchState;
  private lastTaskFilter: PreservedSearchState;
  private lastTestCaseFilter: PreservedSearchState;
  private lastTestPlanFilter: PreservedSearchState;
  private lastUserStoryFilter: PreservedSearchState;
  private lastSprintFilter: PreservedSearchState;
  private lastReleaseFilter: PreservedSearchState;
  private lastUserFilter: PreservedSearchState;
  private lastProjectFilter: PreservedSearchState;
  private lastTestPlanTabSatate: PreservedTabState;
  private lastFilterScreenState: PreservedFilterScreenState;
  private redirectUrlState: PreservedRedirectUrlState;
  private lastAdminDashboardState: PreservedAdminDashboardState;
  private lastUserDashboardState: PreservedUserDashboardState;
  private lastClientDashboardState: PreservedClientDashboardState;




  get searchDefectState(): PreservedSearchState {
    return this.lastDefectFilter;
  }

  set searchDefectState(lastSearch: PreservedSearchState) {
    this.lastDefectFilter = lastSearch;
  }
  get searchTaskState(): PreservedSearchState {
    return this.lastTaskFilter;
  }

  set searchTaskState(lastSearch: PreservedSearchState) {
    this.lastTaskFilter = lastSearch;
  }
  get searchTestCaseState(): PreservedSearchState {
    return this.lastTestCaseFilter;
  }

  set searchTestCaseState(lastSearch: PreservedSearchState) {
    this.lastTestCaseFilter = lastSearch;
  }
  get searchTestPlanState(): PreservedSearchState {
    return this.lastTestPlanFilter;
  }

  set searchTestPlanState(lastSearch: PreservedSearchState) {
    this.lastTestPlanFilter = lastSearch;
  }
  get searchUserStoryState(): PreservedSearchState {
    return this.lastUserStoryFilter;
  }

  set searchUserStoryState(lastSearch: PreservedSearchState) {
    this.lastUserStoryFilter = lastSearch;
  }
  get searchSprintState(): PreservedSearchState {
    return this.lastSprintFilter;
  }

  set searchSprintState(lastSearch: PreservedSearchState) {
    this.lastSprintFilter = lastSearch;
  }
  get searchReleaseState(): PreservedSearchState {
    return this.lastReleaseFilter;
  }

  set searchReleaseState(lastSearch: PreservedSearchState) {
    this.lastReleaseFilter = lastSearch;
  }
  get searchUserState(): PreservedSearchState {
    return this.lastUserFilter;
  }

  set searchUserState(lastSearch: PreservedSearchState) {
    this.lastUserFilter = lastSearch;
  }
  get searchProjectState(): PreservedSearchState {
    return this.lastProjectFilter;
  }

  set searchProjectState(lastSearch: PreservedSearchState) {
    this.lastProjectFilter = lastSearch;
  }

  get searchTestPlanTabState(): PreservedTabState {
    return this.lastTestPlanTabSatate;
  }

  set searchTestPlanTabState(lastSearch: PreservedTabState) {
    this.lastTestPlanTabSatate = lastSearch;
  }

  get searchFilterState(): PreservedFilterScreenState {
    return this.lastFilterScreenState;
  }

  set searchFilterState(lastSearch: PreservedFilterScreenState) {
    this.lastFilterScreenState = lastSearch;
  }
  get redirectLastUrlState(): PreservedRedirectUrlState {
    return this.redirectUrlState;
  }

  set redirectLastUrlState(lastSearch: PreservedRedirectUrlState) {
    this.redirectUrlState = lastSearch;
  }
  get searchAdminDashboardState(): PreservedAdminDashboardState {
    return this.lastAdminDashboardState;
  }

  set searchAdminDashboardState(lastSearch: PreservedAdminDashboardState) {
    this.lastAdminDashboardState = lastSearch;
  }
  get searchUserDashboardState(): PreservedUserDashboardState {
    return this.lastUserDashboardState;
  }

  set searchUserDashboardState(lastSearch: PreservedUserDashboardState) {
    this.lastUserDashboardState = lastSearch;
  }
  get searchClientDashboardState(): PreservedClientDashboardState {
    return this.lastClientDashboardState;
  }

  set searchClientDashboardState(lastSearch: PreservedClientDashboardState) {
    this.lastClientDashboardState = lastSearch;
  }

  clearFilterState() {
    this.lastFilterScreenState = {
      searchValue: null,
      projectId: null,
      pageEvent: null,
      selectedType: null,
      selectedStatus: null,
      selectedAssignee: null,
      selectedReporter: null,
      selectedPriority: null,
      selectedDateType: null,
      assigneeList: [],
      reporterList: [],
      typeList: [],
      statusList: [],
      priorityList: [],
      createdAt: null,
      updatedAt: null,




    };
  }
  clearAllSate() {
    this.lastDefectFilter = {
      searchValue: null,
      pageEvent: null,
      results: [],
      resultsLength: 0,
    };
    this.lastTaskFilter = {
      searchValue: null,
      pageEvent: null,
      results: [],
      resultsLength: 0,
    };
    this.lastTestCaseFilter = {
      searchValue: null,
      pageEvent: null,
      results: [],
      resultsLength: 0,
    };
    this.lastTestPlanFilter = {
      searchValue: null,
      pageEvent: null,
      results: [],
      resultsLength: 0,
    };
    this.lastUserStoryFilter = {
      searchValue: null,
      pageEvent: null,
      results: [],
      resultsLength: 0,
    };
    this.lastSprintFilter = {
      searchValue: null,
      pageEvent: null,
      results: [],
      resultsLength: 0,
    };
    this.lastReleaseFilter = {
      searchValue: null,
      pageEvent: null,
      results: [],
      resultsLength: 0,
    };
    this.lastUserFilter = {
      searchValue: null,
      pageEvent: null,
      results: [],
      resultsLength: 0,
    };
    this.lastProjectFilter = {
      searchValue: null,
      pageEvent: null,
      results: [],
      resultsLength: 0,
    };
    this.lastTestPlanTabSatate = {
      tab: 0
    };

    this.lastFilterScreenState = {
      searchValue: null,
      projectId: null,
      pageEvent: null,
      selectedType: null,
      selectedStatus: null,
      selectedAssignee: null,
      selectedReporter: null,
      selectedPriority: null,
      selectedDateType: null,
      assigneeList: [],
      reporterList: [],
      typeList: [],
      statusList: [],
      priorityList: [],
      createdAt: null,
      updatedAt: null,




    };
    this.lastFilterScreenState = null;

    this.lastAdminDashboardState = {
      accountTransactionPageEvent: null,
      projectListPageEvent: null
    }
    this.lastUserDashboardState = {
      defectReportPageEvent: null,
      assignedToMePageEvent: null,
      watchedIssuePageEvent: null,
      selectedSprint: null
    }
    this.lastClientDashboardState = {
      assigneeByPageEvent: null,
      assignedMePageEvent: null,
    }
    this.redirectLastUrlState = {
      url: null,
      data: null
    };


  }




  constructor() { }
}
