import { Component, OnInit, OnDestroy } from '@angular/core';
import { RestService } from '../services/rest/rest.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { PageEvent } from '@angular/material/paginator';
import { PreservedSearchStateService } from '../services/search/preserved-search-state.service';


declare const $: any;

@Component({
  selector: 'app-dashboard-company-admin',
  templateUrl: './dashboard-company-admin.component.html',
  styleUrls: ['./dashboard-company-admin.component.scss']
})
export class DashboardCompanyAdminComponent implements OnInit, OnDestroy {


  adminDashboardData: any;
  monthList = [{ name: 'Jan', fullName: 'January', value: 1, totalUsers: '0', year: null, invoiceCharge: null }, { name: 'Feb', fullName: 'February', value: 2, totalUsers: '0', year: null, invoiceCharge: null }, { name: 'Mar', fullName: 'March', value: 3, totalUsers: '0', year: null, invoiceCharge: null }, { name: 'Apr', fullName: 'April', value: 4, totalUsers: '0', year: null, invoiceCharge: null }, { name: 'May', fullName: 'May', value: 5, totalUsers: '0', year: null, invoiceCharge: null }, { name: 'Jun', fullName: 'June', value: 6, totalUsers: '0', year: null, invoiceCharge: null }, { name: 'July', fullName: 'July', value: 7, totalUsers: '0', year: null, invoiceCharge: null }
    , { name: 'Aug', fullName: 'August', value: 8, totalUsers: '0', year: null, invoiceCharge: null }, { name: 'Sep', fullName: 'September', value: 9, totalUsers: '0', year: null, invoiceCharge: null }, { name: 'Oct', fullName: 'October', value: 10, totalUsers: '0', year: null, invoiceCharge: null }, { name: 'Nov', fullName: 'November', value: 11, totalUsers: '0', year: null, invoiceCharge: null }, { name: 'Dec', fullName: 'December', value: 12, totalUsers: '0', year: null, invoiceCharge: null }];
  lastFiveMonthList = [];
  filteredList = [];
  lastMonthDate: Date;
  secondLastMonthDate: Date;
  thirdLastMonthDate: Date;
  forthLastMonthDate: Date;
  fiveLastMonthDate: Date;
  userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : '';
  projectList = [];
  userChartData = [];
  userChartOptions;
  storageChartData = [];
  storageChartOption;
  projectData: any;
  accountTransactionData: any;

  projectListPageEvent: PageEvent;
  projectListPageIndex: number = 0;
  projectListPageSize: number = 10;
  projectListLength: number = 0;

  accountTransactionListPageEvent: PageEvent;
  accountTransactionListPageIndex: number = 0;
  accountTransactionListPageSize: number = 10;
  accountTransactionListLength: number = 0;

  title = 'myHighchart';
  highcharts = Highcharts;

  data = [{
    data: [100, 130, 120, 140, 120, 190, 110]
  }];


  chartOptions = {
    chart: {
      type: "column"
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    },
    yAxis: {
      // categories: ['0', '50', '100', '150', '200']
    },

    series: this.data
  };
  adminDashboardTransactions: any = [];
  projectId: any;
  isOwner: String;


  constructor(public restService: RestService, private toastr: ToastrService, private router: Router, private preserveSearch: PreservedSearchStateService) {
    this.projectId = localStorage.getItem('projectSelected');
    console.log("localhost 2222,", localStorage)
    console.log("localhost userData,", localStorage.getItem('userData'))

    this.isOwner = localStorage.getItem('isOwner');;
    var userData = JSON.parse(localStorage.getItem('userData'));
    if (userData?.id == userData.ownerId) {
      this.isOwner = "true";
    } else {
      this.isOwner = "false";
    }

  }

  ngOnInit() {
    this.recoverLastSearchValue();
    $(function () {
      $("#bars li .bar").each(function (key, bar) {
        var percentage = $(this).data('percentage');
        console.log('percentage', percentage);

        $(this).animate({
          'height': percentage + '%'
        }, 1000);
      });
    });
    // this.makeLastFiveMonthList();
    //this.fetchData();
    if (this.projectListPageEvent == null) {
      this.projectListPageEvent = new PageEvent();
      this.projectListPageEvent.length = this.projectListLength;
      this.projectListPageEvent.pageIndex = this.projectListPageIndex;
      this.projectListPageEvent.pageSize = this.projectListPageSize;
    }

    if (this.accountTransactionListPageEvent == null) {
      this.accountTransactionListPageEvent = new PageEvent();
      this.accountTransactionListPageEvent.length = this.accountTransactionListLength;
      this.accountTransactionListPageEvent.pageIndex = this.accountTransactionListPageIndex;
      this.accountTransactionListPageEvent.pageSize = this.accountTransactionListPageSize;
    }

    this.getAdminProjectList(this.projectListPageEvent);
    this.getAdminDashBoard();
    this.getAdminDashBoardUserChart();
    this.getAdminDashBoardStorageChart();
    if (this.isOwner == 'true') {
      this.getAccountTransactionData(this.accountTransactionListPageEvent);

    }
  }

  ngOnDestroy() {
    this.preserveSearch.searchAdminDashboardState = {
      accountTransactionPageEvent: this.accountTransactionListPageEvent,
      projectListPageEvent: this.projectListPageEvent,

    };
  }

  recoverLastSearchValue() {
    const lastSearch = this.preserveSearch.searchAdminDashboardState;
    if (lastSearch) {
      this.accountTransactionListPageEvent = lastSearch.accountTransactionPageEvent;
      this.projectListPageEvent = lastSearch.projectListPageEvent;


      //this.resultsLength = lastSearch.resultsLength;
    }


  }

  getAdminProjectList(event) {
    this.restService.hitApi('project/adminDashboardProjectChart', {
      "page": event.pageIndex + 1,
      "size": event.pageSize,
    }, 'post', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          //this.projectList = responseData.result;

          this.projectData = responseData.result;
          this.projectList = this.projectData.data;

          if (responseData.result.currentPage != 0) {
            this.projectListPageIndex = responseData.result.currentPage - 1;
          }

          this.projectListPageSize = responseData.result.perPage;
          if (responseData.result.total > 0) {
            this.projectListLength = responseData.result.total;
          }

          this.projectListPageEvent = new PageEvent();
          this.projectListPageEvent.length = this.projectListLength;
          this.projectListPageEvent.pageIndex = this.projectListPageIndex;
          this.projectListPageEvent.pageSize = this.projectListPageSize;

          console.log("admin this.projectList", this.projectList);

          // this.projectData.data.forEach(element => {
          //   this.projectList.push(element);
          // });
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        this.toastr.error(errorData.error.message);
      });
  }


  getAdminDashBoard() {
    this.restService.hitApi('project/adminDashboardNew', null, 'post', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.adminDashboardData = responseData.result;
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        this.toastr.error(errorData.error.message);
      });
  }

  getAdminDashBoardUserChart() {
    this.restService.hitApi('project/adminDashboardUserChart', null, 'post', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {

          var userData = responseData.result;
          var data = [];
          var months = [];
          this.sortArray(userData);
          userData.forEach(ele => {
            data.push(ele.users);
            months.push(ele.month);
          })

          this.userChartData = [{
            showInLegend: false,
            data: data
          }]
          this.userChartOptions = {
            chart: {
              type: "column"
            },
            title: {
              text: ''
            },
            xAxis: {
              title: {
                text: 'Month',
              },
              categories: months
            },
            yAxis: {
              title: {
                text: 'Users',
              },
              // categories: ['0', '50', '100', '150', '200']
            },
            tooltip: {
              pointFormat: "Users: {point.y}"
            },
            legend: {
              enabled: false
            },
            series: this.userChartData,
            credits: {
              enabled: false
            }
          };

        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        this.toastr.error(errorData.error.message);
      });
  }

  getAdminDashBoardStorageChart() {
    this.restService.hitApi('project/adminDashboardStorageChart', null, 'post', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {

          var storageData = responseData.result;
          storageData.sort(function (a, b) {
            return b.year - a.year
          })
          var data = [];
          var months = [];
          //this.sortArray(storageData);
          storageData.reverse();
          storageData.forEach(ele => {
            var storage = parseFloat(ele.storage.toFixed(2))
            data.push(storage);
            months.push(ele.month);
          })
          this.storageChartData = [{
            showInLegend: false,
            data: data
          }]
          this.storageChartOption = {
            chart: {
              type: "column"
            },
            title: {
              text: ''
            },
            xAxis: {
              title: {
                text: 'Month',
              },
              categories: months
            },
            yAxis: {
              title: {
                text: 'Storage (MB)',
              },
            },
            tooltip: {

              pointFormat: "Storage: {point.y:.2f} MB"
            },

            legend: {
              enabled: false
            },
            series: this.storageChartData,
            credits: {
              enabled: false
            }
          };

        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        this.toastr.error(errorData.error.message);
      });
  }

  sortArray(arr) {
    const months = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'];
    const sorter = (a, b) => {
      return months.indexOf(a.month) - months.indexOf(b.month);
    };
    return arr.sort(sorter);
  }
  getAccountTransactionData(event) {
    this.restService.hitApi('project/adminDashboardTransaction', {
      "page": event.pageIndex + 1,
      "size": event.pageSize
    }, 'post', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.accountTransactionData = responseData.result;
          this.adminDashboardTransactions = this.accountTransactionData.data;

          if (responseData.result.currentPage != 0) {
            this.accountTransactionListPageIndex = responseData.result.currentPage - 1;
          }

          this.accountTransactionListPageSize = responseData.result.perPage;
          if (responseData.result.total > 0) {
            this.accountTransactionListLength = responseData.result.total;
          }

          this.accountTransactionListPageEvent = new PageEvent();
          this.accountTransactionListPageEvent.length = this.accountTransactionListLength;
          this.accountTransactionListPageEvent.pageIndex = this.accountTransactionListPageIndex;
          this.accountTransactionListPageEvent.pageSize = this.accountTransactionListPageSize;

          // this.accountTransactionData.data.forEach(element => {
          //   this.adminDashboardTransactions.push(element);
          // });
          //this.adminDashboardTransactions = responseData.result;

        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        this.toastr.error(errorData.error.message);
      });
  }

  // fetchData() {
  //   this.restService.hitApi('project/adminDashboard', { 'projectId': localStorage.getItem('projectSelected') }, 'post', '', true)
  //     .subscribe((result) => {
  //       let responseData: any = result;
  //       if (responseData.statusCode == 200) {
  //         this.adminDashboardData = responseData.result;
  //         if (this.adminDashboardData.usersCreatedPerMonth && this.adminDashboardData.usersCreatedPerMonth.length > 0) {
  //           this.adminDashboardData.usersCreatedPerMonth.forEach(element => {
  //             this.lastFiveMonthList.forEach(month => {
  //               if (month.value == element.month) {
  //                 month.totalUsers = element.totalUser.toString();
  //               }
  //             })

  //           });
  //           this.animateBar(this.lastFiveMonthList);

  //           console.log('lastFiveMonthList', this.lastFiveMonthList);

  //         }
  //         if (this.adminDashboardData.invoiceChargedPerMonth && this.adminDashboardData.invoiceChargedPerMonth.length > 0) {
  //           this.adminDashboardData.invoiceChargedPerMonth.forEach(element => {
  //             this.lastFiveMonthList.forEach(month => {
  //               if (month.value == element.month) {
  //                 month.invoiceCharge = element.amount;
  //                 this.filteredList.push(month);
  //               }
  //             })

  //           });
  //         }

  //       }
  //     }, (err) => {
  //       console.log(err);
  //       let errorData: any = err;
  //       this.toastr.error(errorData.error.message);
  //     });

  // }

  animateBar(lastFiveMonthList) {
    $(function () {
      $("#bars li .bar").each(function (key, bar) {
        var percentage = lastFiveMonthList[key].totalUsers;
        console.log('percentage', percentage);

        $(this).animate({
          'height': percentage + '%'
        }, 1000);
      });
    });
  }

  makeLastFiveMonthList() {
    var todayDate = new Date();

    this.lastMonthDate = new Date(todayDate.getFullYear(), 0, 1);
    this.secondLastMonthDate = new Date(todayDate.getFullYear(), -1, 1);
    this.thirdLastMonthDate = new Date(todayDate.getFullYear(), -2, 1);
    this.forthLastMonthDate = new Date(todayDate.getFullYear(), -3, 1);
    this.fiveLastMonthDate = new Date(todayDate.getFullYear(), -4, 1);


    var first = this.monthList[0];
    first.year = this.lastMonthDate.getFullYear();
    var secondLast = this.monthList[this.monthList.length - 1];
    secondLast.year = this.secondLastMonthDate.getFullYear();
    var thirdLast = this.monthList[this.monthList.length - 2];
    thirdLast.year = this.thirdLastMonthDate.getFullYear();
    var fourthLast = this.monthList[this.monthList.length - 3];
    fourthLast.year = this.forthLastMonthDate.getFullYear();
    var fivthLast = this.monthList[this.monthList.length - 4];
    fivthLast.year = this.fiveLastMonthDate.getFullYear();

    this.lastFiveMonthList.push(first, secondLast, thirdLast, fourthLast, fivthLast);

  }

  roundNumber(num) {
    if (num > 0) {
      return Math.round(num) + '%';

    } else {
      return 0 + '%';
    }
  }
  goToProject(project) {
    this.router.navigateByUrl("project-view/" + project.id);
  }
  getMonth(dateStr) {
    if (dateStr != undefined && dateStr != null && dateStr != '') {
      let d = new Date(dateStr);
      let name = '';
      for (var i = 0; i < this.monthList.length; i++) {
        //Mconsole.log("month", d.getMonth() + 1);
        if (this.monthList[i].value == d.getMonth() + 1) {
          name = name + this.monthList[i].fullName;
          break;
        }
      }
      name = name + ' ' + d.getDay()
      return name;
    }

  }
}
