import { Component, OnInit, Input } from '@angular/core';
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from '../app.component';
import { element } from 'node_modules1111/@syncfusion/ej2-base/e2e';
import { AnyTxtRecord } from 'dns';
import { DateConverterService } from '../services/date-formatter/date-converter.service';
import { WindowRefService } from '../window-ref.service';
import { BroadcastService } from '../services/broadcast/broadcast.service';

declare const $: any;
@Component({
  selector: 'app-price-slider-test',
  templateUrl: './price-slider-test.component.html',
  styleUrls: ['./price-slider-test.component.scss']
})
export class PriceSliderTestComponent implements OnInit {
  userData: any = JSON.parse(localStorage.getItem("userData"));
  userCountData: any;
  storageCountData: any = [];
  isLoading: boolean = false;
  employeesObj: any;

  selectedNumberOfUsers: any;
  selectedStorage: any;
  minStroage: any;
  maxStroage: any;
  minUser: any;
  maxUser: any;
  minStorage: any;
  maxStorage: any;
  userPrice: any;
  stroagePrice: any;
  stape1Price: any;
  stape2Price: any;
  stape3Price: any;
  stape4Price: any;
  stape5Price: any;
  step1IsActive: boolean = true;
  step2IsActive: boolean = false;
  step3IsActive: boolean = false;
  step4IsActive: boolean = false;
  step5IsActive: boolean = false;
  localStorageArray: any;

  grandTotal: any;
  isYearly: boolean = false;
  planDetails: any;
  planDetails1: any;
  planDetails2: any;
  freePlanData: any = {
    status: ''
  }

  discountForYearly: any;
  veryBasicTotalAmount: any = {
    price: '',
    users: '',
    stroage: ''
  }

  accountInfo: any;
  packageTypeData: any;

  subscriptionData: any;
  errorMessage: string;
  isFreeTrial: boolean;

  @Input()
  isFromSetting: boolean;

  @Input()
  isFromLogin: boolean;

  constructor(private restService: RestService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private appComponent: AppComponent,
    private dateService: DateConverterService,
    private winRef: WindowRefService,
    private broadcastService: BroadcastService) {

  }


  ngOnInit() {
    console.log("isFromSettingisFromSetting", this.isFromSetting)
    this.getPriceData();
    this.getStrogeData();
    this.getPlanDetails();
    this.getFreePlan();
    this.getAppDeatils();

    if (this.isFromSetting) {
      this.getAccountDetails()
      this.getPackageList()
    }
  }

  ngOnDestroy() {
    $('#upgrade-plan').modal('hide');
  }

  getAccountDetails() {
    this.restService.hitApi('companyUser/accountDetail', {}, 'post', '', false)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.accountInfo = responseData.result;
          this.setPlanData();
          console.log("this.accountInfo: ", this.accountInfo);
        } else {
          this.toastr.error(responseData.message);
        }
        this.isLoading = false;
      }, (err) => {
        this.isLoading = false;
        let errorData: any = err;
        this.toastr.error(errorData.err.message);
      })
  }

  getPackageList() {
    this.restService.hitApi('users/packageList', null, 'get', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.packageTypeData = responseData.result;
          this.setPlanData();
          console.log("this.packageTypeData: ", this.packageTypeData)
        } else {
          this.toastr.error(responseData.message);
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        this.toastr.error(errorData.err.message);
      })
  }

  getAppDeatils() {
    this.restService.hitApi('users/appDetail', { "keyword": "yearly_discount" }, 'post', '', true)
      .subscribe((result) => {
        this.isLoading = false;
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          console.log("responseData.result appDetail", responseData.result)
          //freePlanData = responseData.result;
          this.discountForYearly = responseData.result.value
          console.log("responseData.result discountForYearly", this.discountForYearly)
        } else {
          this.toastr.error(responseData.message);
        }

      }, (err) => {
        this.isLoading = false;
        console.log(err);
        let errorData: any = err;
      })
  }

  getFreePlan() {
    this.restService.hitApi('users/freePackageDetail', null, 'get', '', true)
      .subscribe((result) => {
        this.isLoading = false;
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.freePlanData = responseData.result;
          console.log("this.freePlanData: ", this.freePlanData)
        } else {
          this.toastr.error(responseData.message);
        }

      }, (err) => {
        this.isLoading = false;
        console.log(err);
        let errorData: any = err;
      })
  }

  getPlanDetails() {
    this.isLoading = true;
    this.restService.hitApi('users/features', null, 'get', '', true)
      .subscribe((result) => {
        this.isLoading = false;
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.planDetails = responseData.result;
          this.planDetails1 = [];
          this.planDetails2 = [];

          for (let i = 0; i < this.planDetails.length; i++) {
            if (i % 2 == 0) {
              this.planDetails1.push(this.planDetails[i])
            } else {
              this.planDetails2.push(this.planDetails[i])
            }
          }

        } else {
          this.toastr.error(responseData.message);
        }

      }, (err) => {
        this.isLoading = false;
        console.log(err);
        let errorData: any = err;
      })
  }

  getPriceData() {
    this.restService.hitApi('users/userPackage', null, 'post', '', true)
      .subscribe((result) => {
        this.isLoading = false;
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          console.log("responseData.result", responseData.result)
          this.userCountData = responseData.result;
          this.setUserData();
        } else {
          this.toastr.error(responseData.message);
        }

      }, (err) => {
        this.isLoading = false;
        console.log(err);
        let errorData: any = err;
      })

  }

  getStrogeData() {
    this.restService.hitApi('users/storagePackage', null, 'post', '', true)
      .subscribe((result) => {
        this.isLoading = false;
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          console.log("responseData.result storageCountData", responseData.result)
          this.storageCountData = responseData.result;
          this.setStroageData();
        } else {
          this.toastr.error(responseData.message);
        }

      }, (err) => {
        this.isLoading = false;
        console.log(err);
        let errorData: any = err;
      })

  }

  setUserData() {
    this.selectedNumberOfUsers = this.userCountData.maxUser1;
    this.minUser = this.userCountData.maxUser1;
    this.maxUser = this.userCountData.maxUserRange5;
    this.userPrice = Number(this.userCountData?.minCost);

    this.setTotalAmount();

    this.veryBasicTotalAmount.price = this.grandTotal;
    this.veryBasicTotalAmount.users = this.userCountData.maxUser1;

    if (this.isFromSetting) {
      this.setPlanData();
    }
  }

  userUpdated(event) {
    console.log("i am user called, ", event)

    let minimumCost = Number(this.userCountData?.minCost);
    this.userPrice = minimumCost;

    this.step2IsActive = false;
    this.step3IsActive = false;
    this.step4IsActive = false;
    this.step5IsActive = false;

    if (event >= this.userCountData.minUserRange1) {
      let value = Number(this.userCountData.maxUserRange1) - Number(this.userCountData.maxUser1)
      this.stape1Price = Number(this.userCountData.costRange1) * value
      if (event <= this.userCountData.maxUserRange1) {
        this.stape1Price = Number(this.userCountData.costRange1) * (event - this.userCountData.maxUser1)
      }

      this.userPrice = this.userPrice + Number(this.stape1Price)

    }

    if (event >= this.userCountData.minUserRange2) {
      let value = Number(this.userCountData.maxUserRange2) - Number(this.userCountData.maxUserRange1)
      this.stape2Price = Number(this.userCountData.costRange2) * value
      if (event <= this.userCountData.maxUserRange2) {
        let diff = this.userCountData.minUserRange2 - 1
        this.stape2Price = Number(this.userCountData.costRange2) * (event - diff)
      }

      this.userPrice = this.userPrice + Number(this.stape2Price)

      this.step2IsActive = true;
      this.step3IsActive = false;
      this.step4IsActive = false;
      this.step5IsActive = false;
    }

    if (event >= this.userCountData.minUserRange3) {
      let value = Number(this.userCountData.maxUserRange3) - Number(this.userCountData.maxUserRange2)
      this.stape3Price = Number(this.userCountData.costRange3) * value
      if (event <= this.userCountData.maxUserRange3) {
        let diff = this.userCountData.minUserRange3 - 1
        this.stape3Price = Number(this.userCountData.costRange3) * (event - diff)
      }

      this.userPrice = this.userPrice + Number(this.stape3Price)

      this.step2IsActive = true;
      this.step3IsActive = true;
      this.step4IsActive = false;
      this.step5IsActive = false;

    }

    if (event >= this.userCountData.minUserRange4) {
      let value = Number(this.userCountData.maxUserRange4) - Number(this.userCountData.maxUserRange3)
      this.stape4Price = Number(this.userCountData.costRange4) * value
      if (event <= this.userCountData.maxUserRange4) {
        let diff = this.userCountData.minUserRange4 - 1
        this.stape4Price = Number(this.userCountData.costRange4) * (event - diff)
      }

      this.userPrice = this.userPrice + Number(this.stape4Price)

      this.step2IsActive = true;
      this.step3IsActive = true;
      this.step4IsActive = true;
      this.step5IsActive = false;

    }

    if (event >= this.userCountData.minUserRange5) {
      let value = Number(this.userCountData.maxUserRange5) - Number(this.userCountData.maxUserRange4)
      this.stape5Price = Number(this.userCountData.costRange5) * value
      if (event <= this.userCountData.maxUserRange5) {
        let diff = this.userCountData.minUserRange5 - 1
        this.stape5Price = Number(this.userCountData.costRange5) * (event - diff)
      }

      this.userPrice = this.userPrice + Number(this.stape5Price)

      this.step2IsActive = true;
      this.step3IsActive = true;
      this.step4IsActive = true;
      this.step5IsActive = true;
    }

    this.setTotalAmount();

  }

  setStroageData() {

    if (this.storageCountData[0]) {
      this.storageCountData[0].isActive = true;
      this.selectedStorage = Number(this.storageCountData[0].storageMax);
      this.minStroage = Number(this.storageCountData[0].storageMax);
      this.stroagePrice = Number(this.storageCountData[0].storageMin) * Number(this.storageCountData[0].cost);
    }

    let endObj = this.storageCountData[this.storageCountData.length - 1]

    if (endObj) {
      this.maxStorage = Number(endObj.storageMax)
    }

    this.localStorageArray = [];
    for (let i = 0; i < this.storageCountData.length; i++) {
      if (i != 0) {
        this.localStorageArray.push(this.storageCountData[i])
      }
    }

    this.setTotalAmount();
    this.veryBasicTotalAmount.price = this.grandTotal;
    this.veryBasicTotalAmount.stroage = this.minStroage;

    if (this.isFromSetting) {
      this.setPlanData();
    }
  }

  // public static function calculateStorageCost($storage)
  //   {
  //       $cost = 0;
  //       $storageCost = StoragePackages::select('id', 'cost', 'storage_min', 'storage_max')->get()->toArray();

  //       if(isset($storageCost[0])) {
  //           $cost = $storageCost[0]['storage_min'] * $storageCost[0]['cost'];
  //           unset($storageCost[0]);
  //       }

  //       if($storageCost) {
  //           foreach ($storageCost as $item) {
  //               //dd($storage, $item['storage_max']);
  //               /*if($storage <= $item['storage_max']) {
  //                   $cost += $item['cost'] * ($item['storage_max'] - $item['storage_min'] + 1);
  //               }*/

  //               //dd($storage, $item['storage_min']);
  //               if($storage >= $item['storage_min']) {
  //                   $cost_range = $item['cost'] * (($item['storage_max'] - $item['storage_min']) + 1);
  //                   if($storage <= $item['storage_max']) {
  //                       $cost_range = $item['cost'] * ($storage - ($item['storage_min'] - 1));
  //                   }

  //                   $cost = $cost + $cost_range;
  //               }
  //           }
  //       }

  //       return number_format($cost, 2, '.', '');
  //   }

  strogeUpdated(event) {
    console.log("i am stroge called, ", event)
    //storageCountData

    if (this.storageCountData[0]) {
      this.stroagePrice = Number(this.storageCountData[0].storageMin) * Number(this.storageCountData[0].cost);
    }

    if (this.localStorageArray) {
      this.localStorageArray.forEach(element => {
        if (event >= element.storageMin) {
          var cost_range = element.cost * ((element.storageMax - element.storageMin) + 1);
          element.isActive = true;
          if (event <= element.storageMax) {
            cost_range = element.cost * (event - (element.storageMin - 1));
          }

          this.stroagePrice = this.stroagePrice + cost_range;
        } else {
          element.isActive = false;
        }

      })
    }

    this.setTotalAmount();

  }

  switchTo() {
    this.isYearly = !this.isYearly
    this.setTotalAmount()
  }

  setTotalAmount() {
    this.grandTotal = this.userPrice + this.stroagePrice;
    if (this.isYearly) {
      this.grandTotal = this.grandTotal * 12
    } else {
      this.grandTotal = this.userPrice + this.stroagePrice;
    }
  }

  getYearlyDiscountedAmout() {
    return this.grandTotal - (this.grandTotal * this.discountForYearly / 100)
  }

  payNow() {
    var user = this.selectedNumberOfUsers
    if (user < this.userCountData.maxUser1) {
      user = this.userCountData.maxUser1
    }

    this.router.navigateByUrl('/register', { state: { package: this.isYearly ? "yearly" : "monthly", minStorage: this.selectedStorage, minUsers: user } });
  }

  tryFree() {
    if (this.freePlanData.status == 1) {
      this.router.navigateByUrl('/register', { state: { package: "free", minStorage: this.freePlanData.maxData, minUsers: this.freePlanData.noOfUsers } });

    } else {
      this.toastr.error("Sorry, free trail not available, please try to pay and use Trakk.in");
    }
  }

  setPlanData() {
    if (this.accountInfo) {
      this.selectedNumberOfUsers = this.accountInfo.minUsers
      this.selectedStorage = this.accountInfo.minStorage
      this.userUpdated(this.accountInfo.minUsers);
      this.strogeUpdated(this.accountInfo.minStorage)

      this.accountInfo.endOn = this.dateService.convertInDDMMYYYFormate(this.accountInfo.endDate);
      this.accountInfo.startOn = this.dateService.convertInDDMMYYYFormate(this.accountInfo.startDate);

      if (this.packageTypeData) {
        var currentPlan = ""
        if (this.packageTypeData.free.id == this.accountInfo.packageId) {
          currentPlan = this.packageTypeData.free.packageName
          this.isFreeTrial = true;
        } else if (this.packageTypeData.monthly.id == this.accountInfo.packageId) {
          currentPlan = this.packageTypeData.monthly.packageName
          this.isYearly = false;
        } else if (this.packageTypeData.yearly.id == this.accountInfo.packageId) {
          currentPlan = this.packageTypeData.yearly.packageName
          this.isYearly = true;
        }

        this.accountInfo.currentPlan = currentPlan;
      }

      this.setTotalAmount();
      if (!this.accountInfo.totalPrice) {
        this.accountInfo.totalPrice = this.grandTotal
      }
    }
  }

  updatePlan() {
    this.setSubscription();
  }

  setSubscription() {
    var user = this.selectedNumberOfUsers
    if (user < this.userCountData.maxUser1) {
      user = this.userCountData.maxUser1
    }

    const params = {
      "newUser": user,
      "newStorage": this.selectedStorage,
    }

    if (this.packageTypeData) {
      if (!this.isYearly) {
        params["package_id"] = this.packageTypeData["monthly"].id
      } else {
        params["package_id"] = this.packageTypeData["yearly"].id
      }
    }

    console.log("paramsparams", params)

    this.restService.hitApi('users/set-subscription-package', params, 'post', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          if (responseData.result.paymentPopup == true) {
            this.subscriptionData = responseData.result
            this.payWithRazor();
            console.log("this.subscriptionData: ", this.subscriptionData)
          }

        } else {
          this.toastr.error(responseData.message);
        }
      }, (err) => {
        this.toastr.error(err.error.message);
      })
  }

  payWithRazor() {

    console.log("this.subscriptionData ", this.subscriptionData)

    const options: any = {
      //key: 'rzp_test_ycqUFb222gYfqs', //dev test
      key: 'rzp_live_7a5MZfvNIYtULv', // prod key
      amount: this.subscriptionData.order.amount, // amount should be in paise format to display Rs 1255 without decimal point
      currency: this.subscriptionData.order.currency,
      name: 'Trakk.in', // company name or product name
      description: '',  // product description
      image: 'https://i.imgur.com/PIIAjMe.png', // company logo or product image
      // subscription_id: val,
      order_id: this.subscriptionData.order.id,
      save: 1,// order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a'
      }
    };
    options.handler = ((response, error) => {
      //   options.response = response;
      console.log(response);
      //   console.log(options);
      alert("Your payment is under processing please do not refresh the screen or click back button");

      let requestData: any = { 'trakk_order_id': this.subscriptionData.order.id, 'razorpay_payment_id': response.razorpay_payment_id, 'razorpay_order_id': response.razorpay_order_id, 'razorpay_signature': response.razorpay_signature };
      //   let generated_signature = hmac_sha256(response.subscription_id + "|" + response.razorpay_payment_id)
      this.restService.hitApi('users/verify-signature', requestData, 'post', '', true)
        .subscribe((result) => {
          let responseData: any = result;
          if (responseData.statusCode == 200) {
            console.log("success got from verify-signature")
            // this.router.navigate(['dashboard']);
            // setTimeout(() => {
            //   
            // 	location.reload();
            // }, 100);

            let userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : '';
            userData.planStatus = false;
            localStorage.setItem('userData', JSON.stringify(userData));
            this.toastr.success(responseData.message);
            this.broadcastService.boradcast("FATCH_USER_DATA_GO_TO_DASHBOARD", this.userData);

            // setTimeout(() => {
            //   location.reload();
            // }, 100);

            // setTimeout(() => {
            // 	location.reload();
            // }, 100);

          } else {
            this.errorMessage = responseData.message;
            this.toastr.error(this.errorMessage);
          }
        }, (err) => {
          this.errorMessage = err.error.message;
          this.toastr.error(this.errorMessage);
        })

    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

  contactUs() {
    if (this.isFromSetting) {
      this.router.navigate(['contact-us-home']);
    } else {
      this.router.navigate(['contact-us']);
    }

  }

  closeModal() {

  }


}
