import { Component, OnInit, ChangeDetectorRef,ChangeDetectionStrategy } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { RestService } from '../services/rest/rest.service';
import { ValidationService } from '../services/config/config.service';
import { ToastrService } from 'ngx-toastr';
declare const $:any;
@Component({
	selector: 'app-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
	contactForm: FormGroup;
	freePlanData: any = {
		status: ''
	}
	public captchaIsLoaded = false;
	public captchaSuccess = false;
	public captchaIsExpired = false;
	public captchaResponse?: string;
	public readonly siteKey = '6Ldxzq8ZAAAAAHvWrUY7-VfGwbCDAOp3FiyHzeVU';

	constructor(
		private router: Router, 
		private restService: RestService,
		private formBuilder : FormBuilder,
		private toastr : ToastrService,
		private cdr: ChangeDetectorRef
	) {
		$("html, body").animate({ scrollTop: 0 }, "slow");
		this.contactForm = this.formBuilder.group({
			name: ['', [Validators.required]],
			mobile: [''],
			email: ['', [Validators.required, ValidationService.emailValidator]],
			message: [''],
			recaptcha: ['', Validators.required]
		});
	}

	ngOnInit() {
		
		// if (localStorage.getItem('userData')){
		// 	this.router.navigate(['/dashboard']);
		// }

		// $('document').ready(function () {  
		//   // Sticky header function
		//   $(window).scroll(function() {
		//       if ($(this).scrollTop() > 1){
		//       $('header').addClass("sticky");
		//       }
		//       else{
		//       $('header').removeClass("sticky");
		//       }
		//   });
		// });

		this.getFreePlan();
	}

	getFreePlan() {
		this.restService.hitApi('users/freePackageDetail',null,'get','',false)
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.freePlanData = responseData.result;
				console.log("this.freePlanData: ", this.freePlanData)
			} else {
				// this.toastr.error(responseData.replyMessage);
			}
		},(err)=>{
			console.log(err);
			let errorData : any = err;
			// this.toastr.error(errorData.error.replyMessage);
		})
	}

	tryforfree(){

	}

	onSubmit(token) {
		this.contactForm.get('recaptcha').setValue(token);
	}

	doContact(){
		this.restService.hitApi('users/contactus',this.contactForm.value,'post','',true)
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.contactForm.reset();
				this.toastr.success(responseData.message);
			}else{
				this.toastr.error(responseData.message);
			}
		},(err)=>{
			console.log(err);
			let errorData : any = err;
			// this.toastr.error(errorData.error.replyMessage);
		})
	}


	handleReset(): void {
		this.captchaSuccess = false;
		this.captchaResponse = undefined;
		this.captchaIsExpired = false;
		this.cdr.detectChanges();
	  }
	
	  handleSuccess(captchaResponse: string): void {
		this.captchaSuccess = true;
		this.captchaResponse = captchaResponse;
		this.captchaIsExpired = false;
		this.cdr.detectChanges();
	  }
	
	  handleLoad(): void {
		this.captchaIsLoaded = true;
		this.captchaIsExpired = false;
		this.cdr.detectChanges();
	  }
	
	  handleExpire(): void {
		this.captchaSuccess = false;
		this.captchaIsExpired = true;
		this.cdr.detectChanges();
	  }

}
