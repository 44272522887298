import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateConverterService } from '../services/date-formatter/date-converter.service';
import { IMyDpOptions } from 'mydatepicker';
import { BroadcastService } from '../services/broadcast/broadcast.service';
import { FileUploadService } from "../file-upload-service";
import { HttpEvent, HttpEventType } from '@angular/common/http';

import * as _moment from 'moment';

const moment = _moment;

export const MY_FORMATS = {
	parse: {
		dateInput: 'DD-MM-YYYY',
	},
	display: {
		dateInput: 'DD-MM-YYYY',
		monthYearLabel: 'MMMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY'
	},
};

// import { MatDialog } from '@angular/material';
// import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
import { nullableTypeAnnotation, nullLiteral } from 'node_modules1111/@babel/types/lib';
import { Moment } from 'moment';
declare const $: any;


@Component({
	selector: 'app-release',
	templateUrl: './release.component.html',
	styleUrls: ['./release.component.scss'],
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},

		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	]
})

export class ReleaseComponent implements OnInit {
	userData: any = JSON.parse(localStorage.getItem("userData"));
	isOwner: any = JSON.parse(localStorage.getItem("isOwner"));
	isCompanyAdmin: any = JSON.parse(localStorage.getItem("isCompanyAdmin"));
	isProjectManager: any = JSON.parse(localStorage.getItem("isProjectManager"));

	releaseCreateForm: FormGroup;
	releaseData: any = [];
	pid: any = localStorage.getItem('projectSelected');
	rid: any;
	releaseId: any;
	selectedRelease: any;
	projectListData: any;
	projectData: any;
	projectSprintList: any;
	shortCode: any;
	userInfo: any;
	files: any = [];
	imgArr: any = [];
	descriptionFiles: any = [];
	descriptionImgArr: any = [];
	sprintArr: any = [];
	releaseStatus = new FormControl();
	releaseStatusList = [{ name: 'Unreleased', value: 0 }, { name: 'Released', value: 1 }]

	releaseSprintDisabled: boolean = true;
	jqueryRef: any = $;
	endDateStart: any;
	todat_date: any = new Date();
	addCommentForm: FormGroup;
	editCommentForm: FormGroup;
	fb: FormBuilder;
	minDueDate: any;
	minEndDate: any;
	maxStartDate: any;
	allSprintChecked: boolean;
	allAssociatedSprintChecked: boolean;
	panelOpenState: boolean = true;
	dueDate: any;
	dueDateForm = new FormControl();
	commentValue = '';
	deletedDescriptionItems: any[] = [];
	deletedAttachmentItems: any[] = [];

	public myDueDatePickerOptions: IMyDpOptions = {
		// other options...
		dateFormat: 'dd-mm-yyyy',
		disableUntil: { year: 0, month: 0, day: 0 },
		disableSince: { year: 0, month: 0, day: 0 }

	};


	public myStartDatePickerOptions: IMyDpOptions = {
		// other options...
		dateFormat: 'dd-mm-yyyy',
		disableUntil: { year: 0, month: 0, day: 0 },
		disableSince: { year: 0, month: 0, day: 0 }

	};

	public myEndDatePickerOptions: IMyDpOptions = {
		// other options
		dateFormat: 'dd-mm-yyyy',
		disableUntil: { year: 0, month: 0, day: 0 },
		disableSince: { year: 0, month: 0, day: 0 }
	};
	isAddCommentInputShown: boolean = true;
	isOther: boolean;

	constructor(
		private restService: RestService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private broadcastService: BroadcastService,
		private toastr: ToastrService, private dateService: DateConverterService, private fileUploadService: FileUploadService) {
		this.fb = formBuilder;
		// Check for route params
		if (this.pid && this.pid !== null && this.pid !== undefined) {
			this.getProjectDetail();
		}

		this.route.params.subscribe(params => {
			//this.pid = params['pid'];

			this.rid = params['rid'];
			console.log("rid", params['rid'])
			if (this.rid && this.rid !== null && this.rid !== undefined) {
				console.log("Release api call")
				this.getRelease();
			}
		});

		if (this.userData) {
			this.getUserprofile();
		}

		this.broadcastService.subscribe("RELOAD_ENTITY", (id) => {
			this.rid = id
			this.pid = localStorage.getItem('projectSelected');
			if (this.pid && this.rid && this.rid !== null && this.rid !== undefined) {
				this.getRelease();
			}
		});

		this.shortCode = localStorage.getItem('projectShortCode');
		this.createForm(null);

	}

	ngOnInit() {

		$(document).ready(() => {
			let that = this;

			$('.htmlEditor').summernote({
				height: '150px',
				focus: true,
				disableDragAndDrop: true,
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
				callbacks: {
					onPaste: function (e) {
						var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
						e.preventDefault();
						document.execCommand('insertText', false, bufferText);
					}
				}
			});

			$('#release_edit_comment109').summernote({
				height: '150px',
				focus: true,
				disableDragAndDrop: true,
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
			});

			$('#summernote').summernote({
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline', 'clear']],
					['font', ['strikethrough', 'superscript', 'subscript']],
					['fontsize', ['fontsize']],
					['color', ['color']],
					['para', ['ul', 'ol', 'paragraph']],
					['height', ['height']]
				]
			});

			$(".input-group.date").datepicker({
				autoclose: true,
				todayHighlight: true
			})

			$('.date input').datepicker({
				format: 'dd-mm-yyyy',
				autoclose: true,
				todayHighlight: true,
			}).on('changeDate', function (e) {
				$('.datepicker').hide();
				if (e.currentTarget.classList[1] == 'startDate') {
					console.log(e.date.valueOf());
					var minDate = new Date(e.date.valueOf());
					$('.endDate').datepicker('setStartDate', minDate);
					$('.endDate').datepicker('minDate', minDate);
				} else if (e.currentTarget.classList[1] == 'endDate') {
					var maxDate = new Date(e.date.valueOf());
					$('.startDate').datepicker('setEndDate', maxDate);
					$('.startDate').datepicker('maxDate', maxDate);
				}
				that.updateRelease(e.currentTarget.classList[1], e.format('dd-mm-yyyy'));
			});

		});

		$(document).mouseup(function (e) {
			if (!$(".work-status-anchor").is(e.target) && $(".work-status-anchor").has(e.target).length === 0 && !$(".progress-work-st").is(e.target) && $(".progress-work-st").has(e.target).length === 0) {
				$(".progress-work-st").slideUp('fast');
			}
		});

		// this.createForm(null);
		// this.getProjectList();
	}

	createForm(comment) {
		if (comment) {
			this.editCommentForm = this.fb.group({
				'comment': [comment],
			});
		} else {
			this.addCommentForm = this.fb.group({
				'comment': [''],
			});
			this.editCommentForm = this.fb.group({
				'comment': [''],
			});
		}
		console.log('this.editCommentForm');
		console.log(this.editCommentForm);


	}

	getUserprofile() {
		this.restService.hitApi('user/profile', { 'userId': this.userData.id }, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.userInfo = responseData.result;
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	getProjectDetail() {

		if (localStorage.getItem("isOwner") == "true") {
			this.isOwner = true
		} else {
			this.isOwner = false
		}

		if (localStorage.getItem("isCompanyAdmin") == "true") {
			this.isCompanyAdmin = true
		} else {
			this.isCompanyAdmin = false
		}

		if (localStorage.getItem("isProjectManager") == "true") {
			this.isProjectManager = true
		} else {
			this.isProjectManager = false
		}

		if (localStorage.getItem("isOther") == "true") {
			this.isOther = true
		} else {
			this.isOther = false
		}
		// this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', true)
		// 	.subscribe((result) => {
		// 		let responseData: any = result;
		// 		if (responseData.statusCode == 200) {
		// 			this.projectData = responseData.result;

		// 			if (this.userData) {
		// 				var isOwner = localStorage.getItem('isOwner');
		// 				if (isOwner == "true") {
		// 					this.isOwner = true;
		// 					this.isCompanyAdmin = false;
		// 					this.isProjectManager = false;
		// 					this.isOther = false;
		// 				} else {
		// 					if (this.userData.role == 1) {
		// 						this.isOwner = false;
		// 						this.isCompanyAdmin = true;
		// 						this.isProjectManager = false;
		// 						this.isOther = false;
		// 					} else if (this.userData.role == 2) {
		// 						if (this.projectData && this.projectData.projectUsersProject) {
		// 							this.projectData.projectUsersProject.forEach(element => {
		// 								if (element.userId == this.userData.id) {
		// 									if (element.userRoleId == 1) {
		// 										this.isProjectManager = true;
		// 										this.isOther = false;
		// 									} else {
		// 										this.isProjectManager = false;
		// 										this.isOther = true;
		// 									}
		// 								}
		// 							});
		// 							this.isOther = false;

		// 						} else {
		// 							this.isOther = true;
		// 						}
		// 						this.isOwner = false;
		// 						this.isCompanyAdmin = false;
		// 					} else {
		// 						this.isOther = true;
		// 						this.isOwner = false;
		// 						this.isProjectManager = false;
		// 						this.isCompanyAdmin = false;
		// 					}
		// 				}
		// 			}

		// 		} else {
		// 			// this.toastr.error(responseData.replyMessage);
		// 		}
		// 	}, (err) => {
		// 		console.log(err);
		// 		let errorData: any = err;
		// 		// this.toastr.error(errorData.error.replyMessage);
		// 	})
	}

	// dueDateChange(type: string, event: MatDatepickerInputEvent<Date>) {
	// 	console.log("eventeventevent", event);
	// 	let dueDate = this.dueDateForm.value;
	// 	if (dueDate != null) {
	// 		let dueDateString = this.dateService.convertInDDMMYYYFormate(dueDate);
	// 		this.updateRelease("dueDate", dueDateString);
	// 	}
	// }

	dueDateChange(event) {
		var strDate;
		if (event.epoc > 0) {
			var date = event.jsdate;
			strDate = this.dateService.convertInDDMMYYYFormate(date)
			this.releaseData.dueDate = { date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }, formatted: strDate, jsdate: date };;
			//this.myEndDatePickerOptions.disableUntil = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }

		} else {
			this.releaseData.dueDate = null;
			strDate = null;

		}
		this.updateRelease('dueDate', strDate);


	}
	startDateChange(event) {
		var strDate;
		if (event.epoc > 0) {
			var date = event.jsdate;
			strDate = this.dateService.convertInDDMMYYYFormate(date)
			this.releaseData.fromDate = { date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }, formatted: strDate, jsdate: date };;
			//this.myEndDatePickerOptions.disableUntil = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }

		} else {
			this.releaseData.fromDate = null;
			strDate = null;

		}
		this.disableEndUntil(event.jsdate);
		this.updateRelease('startDate', strDate);

	}
	endDateChange(event) {
		var strDate;
		if (event.epoc > 0) {
			var date = event.jsdate;
			strDate = this.dateService.convertInDDMMYYYFormate(date)
			this.releaseData.toDate = { date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }, formatted: strDate, jsdate: date };;
			//this.myEndDatePickerOptions.disableUntil = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }

		} else {
			this.releaseData.toDate = null;
			strDate = null;

		}
		this.disableStartSince(event.jsdate);
		this.updateRelease('endDate', strDate);

	}


	// getProjectList() {
	// 	this.restService.hitApi('discussion/projects',{},'get','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.projectListData = responseData.result.data;
	// 		}else{
	// 			// this.toastr.error(responseData.replyMessage);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.replyMessage);
	// 	})
	// }

	// changeProject(pid){
	// 	localStorage.setItem('projectSelected',pid)
	// 	$('.project-title-list').toggle("slide");
	// 	this.router.navigateByUrl('/project/'+pid+'/releases');
	// }

	toggleList(ele) {
		this.descriptionFiles = [];
		// alert(ele);
		if (ele == 'description') {
			$('#release_description').summernote('code', this.releaseData.description);
		}
		$('.' + ele).slideToggle("fast");
		if (this.releaseData.descriptionAttachments && this.releaseData.descriptionAttachments.length > 0) {
			this.releaseData.descriptionAttachments.forEach(element => {
				this.descriptionFiles.push({ id: element.id, name: element.displayName, serverFileName: element.fileName, image: element.fileTitle, isProgressBarShown: false });
				//obj.imgArr.push(element.displayName);
			});
			// 	}
		} else {
			//$('.' + ele + '_' + obj.id).slideUp("fast");
			this.descriptionFiles = [];
			this.descriptionImgArr = [];

		}
	}

	toggleAddCommentList(ele, event, show) {
		let that = this;
		that.files = [];
		that.imgArr = [];
		$('.htmlEditor').summernote({
			height: '150px',
			focus: true,
			disableDragAndDrop: true,
			toolbar: [
				// [groupName, [list of button]]
				['style', ['bold', 'italic', 'underline']],
				['para', ['ul', 'ol', 'paragraph']]
			],
			callbacks: {
				onPaste: function (e) {
					var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
					e.preventDefault();
					document.execCommand('insertText', false, bufferText);
				}
			}
		});

		this.isAddCommentInputShown = show;
		$('.htmlEditor').summernote('code', '');
		$('#release_comment').summernote("reset");
		//$('#release_comment').code('');

		if (event == 'down') {
			$('.' + ele).slideDown("fast");
		} else {
			$('.' + ele).slideUp("fast");
		}
		$('#release_comment').summernote({
			focus: true,
		});

	}

	toggleUpdateCommentList(ele, event, obj) {
		let that = this;
		obj.files = [];
		obj.imgArr = [];
		$('.htmlEditor').summernote({
			height: '150px',
			focus: true,
			disableDragAndDrop: true,
			toolbar: [
				// [groupName, [list of button]]
				['style', ['bold', 'italic', 'underline']],
				['para', ['ul', 'ol', 'paragraph']]
			],
			callbacks: {
				onPaste: function (e) {
					var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
					e.preventDefault();
					document.execCommand('insertText', false, bufferText);
				}
			}
		});

		$('.htmlEditor').summernote('code', obj.comment);
		if (event == 'down') {

			$('#release_edit_comment' + obj.id).summernote("reset");
			console.log('.htmlEditor comment', obj.comment);
			$('#release_edit_comment' + obj.id).summernote('code', obj.comment);

			$('.' + ele + '_' + obj.id).slideDown("fast");
			if (obj.attachments && obj.attachments.length > 0) {
				obj.attachments.forEach(element => {
					obj.files.push({ id: element.id, name: element.displayName, serverFileName: element.fileName, image: element.fileTitle, isProgressBarShown: false });
					//obj.imgArr.push(element.displayName);
				});
				// 	}
			} else {
				//$('.' + ele + '_' + obj.id).slideUp("fast");
				obj.files = [];
				obj.imgArr = [];

			}
		} else {
			$('.' + ele + '_' + obj.id).slideUp("fast");
			$('#release_edit_comment' + obj.id).summernote("reset");
			$('#release_edit_comment' + obj.id).code('');
		}

		$('#release_edit_comment' + obj.id).summernote({
			focus: true,
		});

	}
	showedit() {
		if (this.isOwner || this.isProjectManager || this.isCompanyAdmin) {
			$('.heading-editable h1').hide("fast");
			$('.heading-area').show("fast");
			$('.tick-cancel-input-filed').show("fast");
		}

	}
	hideedit() {
		$('.heading-editable h1').show("fast");
		$('.heading-area').hide("fast");
		$('.tick-cancel-input-filed').hide("fast");
	}

	// createForm(data) {
	// 	if (data === null) {
	// 		this.releaseCreateForm = this.formBuilder.group({
	// 			'name': ['', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
	// 			'description': [''],
	// 			'fromDate':[''],
	// 			'endDate':[''],
	// 			// 'status':['',[Validators.required]],
	// 			'attachments':[]
	// 		});

	// 		let date = new Date();
	// 		this.releaseCreateForm.patchValue({fromDate: {
	// 		date: {
	// 			year: date.getFullYear(),
	// 			month: date.getMonth() + 1,
	// 			day: date.getDate()}
	// 		}});
	// 		this.releaseCreateForm.patchValue({endDate: {
	// 			date: {
	// 				year: date.getFullYear(),
	// 				month: date.getMonth() + 1,
	// 				day: date.getDate()}
	// 		}});

	// 	}else{
	// 		this.releaseCreateForm = this.formBuilder.group({
	// 			'name': [data.name, [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
	// 			'description': [''],
	// 			'fromDate':[data.fromDate],
	// 			'endDate':[data.toDate],
	// 			'releaseId':[data.id],
	// 			// 'status':[data.status,[Validators.required]],
	// 			'attachments':[],
	// 			'oldattachments':[data.attachments]
	// 		});
	// 		let fromdate = new Date(data.fromDate);
	// 		this.releaseCreateForm.patchValue({fromDate: {
	// 			date: {
	// 				year: fromdate.getFullYear(),
	// 				month: fromdate.getMonth() + 1,
	// 				day: fromdate.getDate()}
	// 		}});
	// 		let todate = new Date(data.toDate);
	// 		this.releaseCreateForm.patchValue({endDate: {
	// 			date: {
	// 				year: todate.getFullYear(),
	// 				month: todate.getMonth() + 1,
	// 				day: todate.getDate()}
	// 		}});
	// 	}

	// }


	updateRelease(field, value = "") {

		let data: any;
		if (field == 'name') {
			value = $('#release_' + field).val();
			if (value != "") {
				if (value != this.releaseData.name) {
					data = { 'name': value, 'releaseId': this.releaseId, 'projectId': this.pid };
				}
			} else {
				this.toastr.error("Release name is required");
				return
			}
		} else if (field == 'status') {
			if (value != this.releaseData.status) {
				data = { 'status': value, 'releaseId': this.releaseId, 'projectId': this.pid };
			}
		} else if (field == 'startDate') {
			if (value != this.releaseData.previousFromDate) {
				data = { 'startDate': value, 'releaseId': this.releaseId, 'projectId': this.pid };
			}
		} else if (field == 'endDate') {
			if (value != this.releaseData.previousToDate) {
				data = { 'endDate': value, 'releaseId': this.releaseId, 'projectId': this.pid };
			}
		} else if (field == 'dueDate') {
			if (value != this.releaseData.previousDueDate) {
				data = { 'dueDate': value, 'releaseId': this.releaseId, 'projectId': this.pid };
			}
		} else if (field == 'description') {
			value = $('#release_' + field).val();
			data = {
				'description': value, 'releaseId': this.releaseId, 'projectId': this.pid,
				'files': this.descriptionImgArr
			};
		}

		console.log("datadatadatadatadata", data)
		if (data) {
			this.restService.hitApi('release/updateField', data, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.toastr.success(responseData.message);
						if (field == 'name') {
							$('.heading-editable h1').show("fast");
							$('.heading-area').hide("fast");
							$('.tick-cancel-input-filed').hide("fast");
						} else if (field == 'status') {
							$('.progress-work-st-js').slideUp("fast");
						} else if (field == 'startDate') {
							$('.datepicker').hide();
						} else if (field == 'description') {
							this.descriptionImgArr = [];
							this.descriptionFiles = [];
							$('.description').slideUp("fast");
						}

						this.getRelease();
						// $('#create_release_popup_web').modal('hide');
						// this.files = [];
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(err.error.message);
				})
		} else {
			if (field == 'name') {
				$('.heading-editable h1').show("fast");
				$('.heading-area').hide("fast");
				$('.tick-cancel-input-filed').hide("fast");
			}
		}

	}

	// updateRelease(){
	// 	this.releaseCreateForm.value.projectId = this.pid;
	// 	this.releaseCreateForm.value.fromDate = this.releaseCreateForm.value.fromDate.date.year+"-"+this.releaseCreateForm.value.fromDate.date.month+"-"+this.releaseCreateForm.value.fromDate.date.day;
	// 	this.releaseCreateForm.value.endDate = this.releaseCreateForm.value.endDate.date.year+"-"+this.releaseCreateForm.value.endDate.date.month+"-"+this.releaseCreateForm.value.endDate.date.day;
	// 	this.releaseCreateForm.value.attachments = this.imgArr;
	// 	this.restService.hitApi('release/update',this.releaseCreateForm.value,'post','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.getRelease();
	// 			$('#create_release_popup_web').modal('hide');
	// 			this.toastr.success(responseData.message);
	// 			this.files = [];
	// 		}else{
	// 			// this.toastr.error(responseData.replyMessage);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.replyMessage);
	// 	})	
	// }

	getRelease() {
		console.log("release/details");
		this.restService.hitApi('release/details', { 'releaseId': this.rid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.releaseData = responseData.result;
					console.log("this.releaseDatathis.releaseData", this.releaseData);
					this.releaseId = this.releaseData.id;

					if (this.releaseData && (this.releaseData.status != 'undefined' || this.releaseData.status != null)) {
						this.releaseStatusList.forEach(status => {
							if (this.releaseData.status == status.value) {
								this.releaseStatus.setValue(status);
							}
						})
					}
					if (this.releaseData.dueDate) {
						var d = Date.parse(this.releaseData.dueDate);
						var dueDate = new Date(d);
						var date: string = this.dateService.convertInDDMMYYYFormate(dueDate);
						//this.sprintData.toDate = date;
						this.releaseData.previousDueDate = date;
						this.releaseData.dueDate = { date: { year: dueDate.getFullYear(), month: dueDate.getMonth() + 1, day: dueDate.getDate() }, formatted: date, jsdate: dueDate };
						//var date: string = this.dateService.convertInDDMMYYYFormate(this.releaseData.dueDate);
						//this.releaseData.dueDate = date;
						// console.log("this.releaseData.dueDate", this.releaseData.dueDate);

						// let date1 = new Date(this.releaseData.dueDate);
						// this.dueDateForm.setValue(date1);

					}
					if (this.releaseData.toDate) {
						var d = Date.parse(this.releaseData.toDate);
						var toDate = new Date(d);
						var date: string = this.dateService.convertInDDMMYYYFormate(toDate);
						this.releaseData.previousToDate = date;
						this.releaseData.toDate = { date: { year: toDate.getFullYear(), month: toDate.getMonth() + 1, day: toDate.getDate() }, formatted: date, jsdate: toDate };
						this.disableStartSince(this.releaseData.toDate.jsdate);


					}

					if (this.releaseData.fromDate) {
						var d = Date.parse(this.releaseData.fromDate);
						var fromDate = new Date(d);
						var date: string = this.dateService.convertInDDMMYYYFormate(fromDate);
						this.releaseData.previousFromDate = date;
						this.releaseData.fromDate = { date: { year: fromDate.getFullYear(), month: fromDate.getMonth() + 1, day: fromDate.getDate() }, formatted: date, jsdate: fromDate };
						this.disableEndUntil(this.releaseData.fromDate.jsdate);

					}

					if (this.releaseData.dueDate) {
						var date: string = this.dateService.convertInDDMMYYYFormate(this.releaseData.dueDate);
						this.releaseData.dueDate = date;
					}

					console.log(this.releaseStatus);
					// $('.endDate').datepicker('setStartDate', new Date(this.releaseData.fromDate));
					// this.createForm(this.releaseData);
				} else {
					console.log("responseData.replyMessage", responseData);
					this.toastr.error(responseData);
					this.toastr.error(responseData.replyMessage);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(err.error.message);
			})
	}

	deleteRelease(rid) {
		this.restService.hitApi('release/delete', { 'releaseId': rid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('#delete-record').modal('hide')
					this.toastr.success(responseData.message);
					//this.router.navigateByUrl('/releases');
					this.router.navigate(['releases']);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	getProjectSprints() {
		this.restService.hitApi('release-sprint/sprint', { 'projectReleaseId': this.releaseId }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.projectSprintList = responseData.result;
				} else {
					// this.toastr.error(responseData.replyMessage);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.replyMessage);
			})
	}

	uploadFile(event) {
		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];
			if (this.restService.checkFileSize(file, 50)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				formData.append('entity', 'release');
				formData.append('entityId', this.rid);
				formData.append('file', file);
				var reader = new FileReader();
				const this_ = this;
				var obj;
				reader.onload = function (e: any) {
					obj = { serverFileName: file.name, name: file.name, image: e.target.result, isProgressBarShown: true }
					this_.files.push(obj);
				}

				reader.readAsDataURL(file);

				this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
					.subscribe((event: HttpEvent<any>) => {
						switch (event.type) {
							case HttpEventType.Sent:
								console.log('Request has been made!');
								break;
							case HttpEventType.ResponseHeader:
								console.log('Response header has been received!');
								break;
							case HttpEventType.UploadProgress:
								this.files[this.files.length - 1].progress = Math.round(event.loaded / event.total * 100);
								console.log(`Uploaded! ${this.files[this.files.length - 1].progress}%`);
								$('#add_comment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');

								break;
							case HttpEventType.Response:
								let responseData: any = event.body;
								if (responseData.statusCode == 200) {
									this.imgArr.push(responseData.result);
									this.files[this.files.length - 1].serverFileName = responseData.result.fileTitle;
									this.files[this.files.length - 1].displayName = responseData.result.displayName;
									this.files.forEach(element => {
										element.isProgressBarShown = false;
									});
								} else {
									if (responseData.message) {
										this.toastr.error(responseData.message);
									}
								}
								console.log('Upload successfully', event.body);
								setTimeout(() => {
									this.files[this.files.length - 1].progress = 0;
									$('#add_comment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');

								}, 1500);


						}
					})



				// this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', false, false)
				// 	.subscribe((result) => {
				// 		let responseData: any = result;
				// 		if (responseData.statusCode == 200) {
				// 			this.imgArr.push(responseData.result);
				// 			this.files[this.files.length - 1].serverFileName = responseData.result.fileTitle;
				// 			this.files[this.files.length - 1].displayName = responseData.result.displayName;
				// 			this_.files.forEach(element => {
				// 				element.isProgressBarShown = false;
				// 			});
				// 			//this.getSprint();
				// 		} else {
				// 			this.toastr.error(responseData.message);
				// 		}
				// 	}, (err) => {
				// 		console.log(err);
				// 		let errorData: any = err;
				// 		this.toastr.error(errorData.error.message);
				// 	})
			}
		}

	}

	uploadCommentAttachment(event, commentObj) {
		var i;
		for (let index = 0; index < event.length; index++) {
			i = index;
			let file: File = event[index];
			if (this.restService.checkFileSize(file, 50)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				formData.append('entity', 'release');
				formData.append('entityId', this.rid);
				formData.append('file', file);

				// const element = event[index];


				var reader = new FileReader();
				const this_ = this;
				var obj;
				reader.onload = function (e: any) {
					obj = { name: file.name, serverFileName: file.name, image: e.target.result, isProgressBarShown: true }
					commentObj.files.push(obj);
				}

				reader.readAsDataURL(file);

				this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
					.subscribe((event: HttpEvent<any>) => {
						switch (event.type) {
							case HttpEventType.Sent:
								console.log('Request has been made!');
								break;
							case HttpEventType.ResponseHeader:
								console.log('Response header has been received!');
								break;
							case HttpEventType.UploadProgress:
								commentObj.files[commentObj.files.length - 1].progress = Math.round(event.loaded / event.total * 100);
								console.log(`Uploaded! ${commentObj.files[commentObj.files.length - 1].progress}%`);
								$('#edit_comment_progress_bar_' + (commentObj.files.length - 1)).attr('aria-valuenow', commentObj.files[commentObj.files.length - 1].progress).css('width', commentObj.files[commentObj.files.length - 1].progress + '%');

								break;
							case HttpEventType.Response:
								let responseData: any = event.body;
								if (responseData.statusCode == 200) {
									commentObj.imgArr.push(responseData.result);
									commentObj.files[commentObj.files.length - 1].serverFileName = responseData.result.fileTitle;
									commentObj.files[commentObj.files.length - 1].displayName = responseData.result.displayName;
									commentObj.files.forEach(element => {
										element.isProgressBarShown = false;
									});
								} else {
									if (responseData.message) {
										this.toastr.error(responseData.message);
									}
								}
								setTimeout(() => {
									commentObj.files[commentObj.files.length - 1].progress = 0;
									$('#edit_comment_progress_bar_' + (commentObj.files.length - 1)).attr('aria-valuenow', commentObj.files[commentObj.files.length - 1].progress).css('width', commentObj.files[commentObj.files.length - 1].progress + '%');

								}, 1500);
						}
					})

				// this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', false, false)
				// 	.subscribe((result) => {
				// 		let responseData: any = result;
				// 		if (responseData.statusCode == 200) {

				// 			commentObj.imgArr.push(responseData.result);
				// 			//const fileObj = commentObj.files.find(element => element.name == obj.name);
				// 			commentObj.files[commentObj.files.length - 1].serverFileName = responseData.result.fileTitle;
				// 			commentObj.files[commentObj.files.length - 1].displayName = responseData.result.displayName;
				// 			commentObj.files.forEach(element => {
				// 				element.isProgressBarShown = false;
				// 			});

				// 		} else {
				// 			this.toastr.error(responseData.message);
				// 		}
				// 	}, (err) => {
				// 		console.log(err);
				// 		let errorData: any = err;
				// 		this.toastr.error(errorData.error.message);
				// 	})
			}
		}
	}

	uploadDescriptionFile(event) {
		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];
			if (this.restService.checkFileSize(file, 50)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				formData.append('entity', 'release');
				formData.append('entityId', this.rid);
				formData.append('file', file);
				var reader = new FileReader();
				const this_ = this;
				var obj;
				reader.onload = function (e: any) {
					obj = { serverFileName: file.name, name: file.name, image: e.target.result, isProgressBarShown: true }
					this_.descriptionFiles.push(obj);
				}

				reader.readAsDataURL(file);


				this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
					.subscribe((event: HttpEvent<any>) => {
						switch (event.type) {
							case HttpEventType.Sent:
								console.log('Request has been made!');
								break;
							case HttpEventType.ResponseHeader:
								console.log('Response header has been received!');
								break;
							case HttpEventType.UploadProgress:
								this.descriptionFiles[this.descriptionFiles.length - 1].progress = Math.round(event.loaded / event.total * 100);
								console.log(`Uploaded! ${this.descriptionFiles[this.descriptionFiles.length - 1].progress}%`);
								$('#discription_progress_bar_' + (this.descriptionFiles.length - 1)).attr('aria-valuenow', this.descriptionFiles[this.descriptionFiles.length - 1].progress).css('width', this.descriptionFiles[this.descriptionFiles.length - 1].progress + '%');

								break;
							case HttpEventType.Response:
								let responseData: any = event.body;
								if (responseData.statusCode == 200) {
									this.descriptionImgArr.push(responseData.result);
									this.descriptionFiles[this.descriptionFiles.length - 1].serverFileName = responseData.result.fileTitle;
									this.descriptionFiles[this.descriptionFiles.length - 1].displayName = responseData.result.displayName;
									this_.descriptionFiles.forEach(element => {
										element.isProgressBarShown = false;
									});
									//this.getSprint();
								} else {
									if (responseData.message) {
										this.toastr.error(responseData.message);
									}
								}
								console.log('User successfully created!', event.body);
								setTimeout(() => {
									this.descriptionFiles[this.descriptionFiles.length - 1].progress = 0;
									$('#discription_progress_bar_' + (this.descriptionFiles.length - 1)).attr('aria-valuenow', this.descriptionFiles[this.descriptionFiles.length - 1].progress).css('width', this.descriptionFiles[this.descriptionFiles.length - 1].progress + '%');

								}, 1500);
						}
					})
				// this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', false, false)
				// 	.subscribe((result) => {
				// 		let responseData: any = result;
				// 		if (responseData.statusCode == 200) {
				// 			this.descriptionImgArr.push(responseData.result);
				// 			this.descriptionFiles[this.descriptionFiles.length - 1].serverFileName = responseData.result.fileTitle;
				// 			this.descriptionFiles[this.descriptionFiles.length - 1].displayName = responseData.result.displayName;
				// 			this_.descriptionFiles.forEach(element => {
				// 				element.isProgressBarShown = false;
				// 			});
				// 			//this.getSprint();
				// 		} else {
				// 			this.toastr.error(responseData.message);
				// 		}
				// 	}, (err) => {
				// 		console.log(err);
				// 		let errorData: any = err;
				// 		this.toastr.error(errorData.error.message);
				// 	})
				// }
			}
		}

	}

	getExtensionOfFileType(fileName) {
		if (fileName) {
			var str = fileName.split(".");
			if (str[str.length - 1] == 'jpg' || str[str.length - 1] == 'png' || str[str.length - 1] == 'jpeg' || str[str.length - 1] == 'JPG' || str[str.length - 1] == 'PNG') {
				return 'image'
			} else {
				return 'file'
			}

		}
	}
	// uploadFile(event) {
	// 	for (let index = 0; index < event.length; index++) {
	// 		let file : File = event[index];
	// 		const formData: FormData = new FormData();
	// 		formData.append('projectId', this.pid);
	// 		formData.append('file', file);
	// 		this.files.push(file.name);

	// 		this.restService.hitApi('release/upload-file',formData,'post','',true,false)
	// 			.subscribe((result)=>{
	// 				let responseData : any = result;
	// 				if(responseData.statusCode==200){
	// 					this.imgArr.push(responseData.result);
	// 				}else{
	// 					this.toastr.error(responseData.message);
	// 				}
	// 			},(err)=>{
	// 				console.log(err);
	// 				let errorData : any = err;
	// 				this.toastr.error(errorData.error.message);
	// 		})
	// 	}

	// }


	checkUncheckAll(ele, classname) {
		// console.log(ele.target.checked)
		var checkboxes = $("." + classname + " input");
		if (ele) {
			for (var i = 0; i < checkboxes.length; i++) {
				// if (checkboxes[i].type == 'checkbox') {
				checkboxes[i].checked = true;
				// }
			}
		} else {
			for (var i = 0; i < checkboxes.length; i++) {
				console.log(i)
				// if (checkboxes[i].type == 'checkbox') {
				checkboxes[i].checked = false;
				// }
			}
		}
	}

	selectonlyOne(selected) {
		console.log(selected)
		$('.table-multiple-checkboxes input:checkbox').removeAttr('checked');
		$('#' + selected).prop("checked", true);
	}

	setCheckedVar() {
		this.releaseSprintDisabled = true;
		let that = this;
		$('.release-sprint-checkbox').find('input').each(function () {
			if ($(this).prop("checked")) {
				that.releaseSprintDisabled = false;
			}
		});

	}

	isAbleToEditComment(user) {

		// 		companyUserImage: "https://qa-trakk-in.s3-ap-southeast-2.amazonaws.com/company_user_profile/thumb_72341202103041614858356.png"
		// companyUserRoleId: 1
		// id: 1409
		// name: "govind3333"
		// projectUserRoleId: 4

		console.log("useruseruser ", user);
		if (this.isOwner || this.isCompanyAdmin) {
			return true;
		} else if (user.id == this.userData.id) {
			return true;
		}

		return false;
	}

	removeReleaseSprints() {
		this.sprintArr = [];
		if (this.releaseData?.releaseSprint) {
			this.releaseData?.releaseSprint.forEach(element => {
				if (element.checked) {
					this.sprintArr.push(element.id);
				}
			});
		}
		// let that = this;
		// $('.release-sprint-checkbox').find('input').each(function () {
		// 	console.log($(this).val());
		// 	if ($(this).prop("checked")) {
		// 		that.sprintArr.push($(this).val());
		// 	}
		// });

		if (this.sprintArr.length) {
			this.restService.hitApi('release-sprint/delete', { 'projectReleaseId': this.releaseId, 'sprintId': this.sprintArr }, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.allAssociatedSprintChecked = false;
						this.getRelease();
						this.toastr.success(responseData.message);
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		} else {
			this.toastr.error('Please select sprint to remove.');
		}
	}

	addReleaseSprint() {
		this.sprintArr = [];
		this.projectSprintList.forEach(element => {
			if (element.checked) {
				this.sprintArr.push(element.id);
			}
		});


		console.log(this.sprintArr);

		if (this.sprintArr.length > 0) {
			this.restService.hitApi('release-sprint/create', { 'projectId': this.pid, 'projectReleaseId': this.releaseId, 'sprintId': this.sprintArr }, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						$('#create_add-link_popup_web').modal('hide');
						this.allSprintChecked = false;

						this.getRelease();
						this.toastr.success(responseData.message);
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		} else {
			this.toastr.error("Please select sprint to link");
		}

	}

	deleteCommentAttachment(files) {

		var json = {
			projectId: this.pid,
			entity: 'release',
			files: files
		}

		this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					//this.getTestplan();
				} else {

				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}


	removeAttachment(fileObj, index, commentObj) {
		//var fileObj = this.files[index];

		this.deletedAttachmentItems.push(fileObj.serverFileName)

		if (commentObj != undefined && commentObj != null) {
			commentObj.files.splice(index, 1);
			commentObj.imgArr.splice(0, 1);

		} else {
			this.files.splice(index, 1);
			this.imgArr.splice(0, 1);
		}
	}
	removeDescriptionAttachment(fileObj, index) {

		this.deletedDescriptionItems.push(fileObj.serverFileName);
		this.descriptionFiles.splice(index, 1);
		this.descriptionImgArr.splice(index, 1);
		//var fileObj = this.files[index];
		// console.log("fileObj", fileObj);
		// var deleteFileArray = [];
		// var deleteFileArray = [fileObj.serverFileName];
		// if (fileObj.id) {
		// 	if (deleteFileArray.length > 0) {
		// 		this.deleteCommentAttachment(deleteFileArray);
		// 	}

		// } else {
		// 	//var deleteFileArray = [fileObj.serverFileName];
		// 	if (deleteFileArray.length > 0) {
		// 		this.removeAttachmentFromServer(deleteFileArray, true);

		// 	}


		// }
		// this.descriptionFiles.splice(index, 1);
		// this.descriptionImgArr.splice(0, 1);

	}

	updateDescriptionAttachment() {
		if (this.deletedDescriptionItems && this.deletedDescriptionItems.length > 0) {
			this.removeDescriptionAttachmentFromServer();
		} else {
			this.updateRelease('description');
		}
	}

	updateAttachment(obj, field) {
		if (this.deletedAttachmentItems && this.deletedAttachmentItems.length > 0) {
			this.removeAttachmentFromServer(obj, field);
		} else {
			this.addComment(obj, field, true);
		}
	}

	removeDescriptionAttachmentFromServer() {

		var json = {
			projectId: this.pid,
			entity: 'release',
			files: this.deletedDescriptionItems
		}

		this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.deletedDescriptionItems = [];
					this.updateRelease('description');
				} else {
					this.toastr.success(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}



	removeAttachmentFromServer(obj, field) {
		var json = {
			projectId: this.pid,
			entity: 'release',
			files: this.deletedAttachmentItems
		}
		this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.deletedAttachmentItems = [];
					if (obj && field) {
						this.addComment(obj, field, true);
					}
				} else {
					this.toastr.success(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}


	deleteAttachment(aid, index) {
		this.restService.hitApi('projectTask/delete-file', { "attachmentId": aid, "projectId": this.pid }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.releaseCreateForm.value.oldattachments.splice(index, 1);
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}



	addComment(obj, field, shown) {

		let data: any;
		let comment: any;
		let apiname: any;

		if (obj?.id) {
			apiname = 'release/comment/update';
			comment = $('#release_edit_' + field + obj?.id).val();
			data = { 'releaseCommentId': obj?.id, 'comment': comment, 'files': obj.imgArr, 'projectId': this.pid }
		} else {
			apiname = 'release/comment/add';
			comment = $('#release_' + field).val();
			data = { 'releaseId': this.releaseId, 'comment': comment, 'files': this.imgArr, 'projectId': this.pid }
		}


		this.restService.hitApi(apiname, data, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					if (obj?.id) {
						$('#comment' + obj?.id).show();
						$('#commentinput' + obj?.id).hide();
					} else {
						$('#comment').val('');

						$('#release_' + field).val('');
						this.commentValue = '';
						$('#release_comment').summernote("reset");
						//this.toggleAddCommentList(field, 'up', true);
						$('.' + field).slideUp("fast");
						this.isAddCommentInputShown = shown;

					}

					this.files = [];
					this.imgArr = [];
					if (obj?.id) {
						this.editCommentForm.reset();
					} else {
						this.addCommentForm.reset();
					}
					this.getRelease();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	deleteComment(releaseCommentId) {
		this.restService.hitApi('release/comment/delete', { "releaseCommentId": releaseCommentId }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getRelease();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	isUserImage() {
		if (this.userData.companyUserImage != null) {
			return true;
		}

		return false;
	}

	updateAllSprint() {
		this.allSprintChecked = this.projectSprintList != null && this.projectSprintList.every(t => t.checked);
	}

	someSprintComplete(): boolean {
		if (this.projectSprintList == null) {
			return false;
		}
		return this.projectSprintList.filter(t => t.checked).length > 0 && !this.allSprintChecked;
	}

	setAllSprint(checked: boolean) {
		this.allSprintChecked = checked;
		if (this.projectSprintList == null) {
			return;
		}
		this.projectSprintList.forEach(t => t.checked = checked);
	}

	updateAllAssociatedSprint() {
		this.allAssociatedSprintChecked = this.releaseData?.releaseSprint != null && this.releaseData?.releaseSprint.every(t => t.checked);
	}

	someAssociatedSprintComplete(): boolean {
		if (this.releaseData?.releaseSprint == null) {
			return false;
		}
		return this.releaseData?.releaseSprint.filter(t => t.checked).length > 0 && !this.allAssociatedSprintChecked;
	}

	setAllAssociatedSprint(checked: boolean) {
		this.allAssociatedSprintChecked = checked;
		if (this.releaseData?.releaseSprint == null) {
			return;
		}
		this.releaseData?.releaseSprint.forEach(t => t.checked = checked);
	}

	disableEndUntil(startDate) {
		if (startDate) {
			startDate.setDate(startDate.getDate() - 1);
			let copy = this.getCopyOfEndDateOptions();
			copy.disableUntil = {
				year: startDate.getFullYear(),
				month: startDate.getMonth() + 1,
				day: startDate.getDate()
			};
			this.myEndDatePickerOptions = copy;
		} else {
			let copy = this.getCopyOfEndDateOptions();
			copy.disableUntil = {
				year: 0,
				month: 0,
				day: 0
			};
			this.myEndDatePickerOptions = copy;
		}

	}

	// Returns copy of myOptions
	getCopyOfEndDateOptions(): IMyDpOptions {
		return JSON.parse(JSON.stringify(this.myEndDatePickerOptions));
	}

	disableStartSince(endDate) {
		if (endDate) {
			endDate.setDate(endDate.getDate() - 1);
			let copy = this.getCopyOfStartDateOptions();
			copy.disableSince = {
				year: endDate.getFullYear(),
				month: endDate.getMonth() + 1,
				day: endDate.getDate()
			};
			this.myStartDatePickerOptions = copy;
		} else {
			let copy = this.getCopyOfStartDateOptions();
			copy.disableSince = {
				year: 0,
				month: 0,
				day: 0
			};
			this.myStartDatePickerOptions = copy;
		}

	}

	// Returns copy of myOptions
	getCopyOfStartDateOptions(): IMyDpOptions {
		return JSON.parse(JSON.stringify(this.myStartDatePickerOptions));
	}

	download(url, fileName, $event) {
		$event.stopPropagation()
		this.restService.downloadFile(url, fileName);
	}

}

