import { Data } from './../ui-components/select-dropdown/Data';
import { Component, OnInit, Directive, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
// import * as moment from 'moment';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ValidationService } from '../services/config/config.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogOverviewExampleDialog } from './dialog-overview-example-dialog.component';
import { PageEvent } from '@angular/material/paginator';
import { PreservedSearchStateService } from '../services/search/preserved-search-state.service';


declare const $: any;

@Component({
	selector: 'app-user-management',
	templateUrl: './user-management.component.html',
	styleUrls: ['./user-management.component.scss'],

})

export class UserManagementComponent implements OnInit, OnDestroy {
	userListData: any = [];
	fetchingData: boolean = true;
	userData: any = JSON.parse(localStorage.getItem("userData"));

	searchUserForm: FormGroup;
	roleTypes;

	assocProjects: any;
	selectUser: any;
	// usercount : any;

	sortOrder: any = 'desc';
	sortByField: any = 'id'

	fileData: File = null;
	userCreateForm: FormGroup;
	selectedForDelete: any;
	validateFile: any = "";


	companiesData: any;
	cur_company_id: any;
	userCreateFormSubmit: boolean = false;
	showCreateUserPopup: boolean = false;
	showEditUserPopup: boolean = false;
	errorInFile: any = {};
	roles: Data[];

	pageEvent: PageEvent;
	datasource: any;
	pageIndex: number = 0;
	pageSize: number = 10;
	length: number = 0;
	searchString: string = "";
	isLoading: boolean = false;
	isCreateUserDialogShow: boolean = false;

	isNoSearchResults: boolean = false;
	isNoData: boolean = false;

	constructor(
		public restService: RestService,
		private toastr: ToastrService,
		private router: Router,
		// private route : ActivatedRoute,
		private formBuilder: FormBuilder,
		// private dialog : MatDialog,
		private modalService: NgbModal,
		public dialog: MatDialog,
		private preserveSearch: PreservedSearchStateService) {

		this.searchUserForm = this.formBuilder.group({
			'search': ['', [Validators.required]]
		});
		this.setRoles;

		console.log("userData", this.userData);
		console.log(this.roles);
	}

	ngOnInit() {
		// this.restService.getCompanies();
		this.recoverLastSearchValue();


		$(document).ready(function () {

			$('.horizontal-dot').click(function () {

			});

			$('.work-status-anchor').click(function (e) {
				$('.progress-work-st-js').slideToggle("fast");
			});
			$('.edit-role-work-status-anchor').click(function (e) {
				$('.edit-role-progress-work-st-js').slideToggle("fast");
			});


			$('#move-calender-boot').on('shown.bs.modal', () => {

				$('#calendar').datepicker({
					dateFormat: 'dd-mm-yy',
					minDate: '+5d',
					changeMonth: true,
					changeYear: true,
					altField: "#idTourDateDetailsHidden",
					altFormat: "yy-mm-dd"
				});


				// $('#calendar').fullCalendar({
				//   header: {
				// 	left: 'prev,next today',
				// 	center: 'title',
				// 	right: 'month,agendaWeek,agendaDay'
				//   },
				//   defaultDate: '2020-05-20',
				//   defaultView: 'month',
				//   editable: true,
				//   events: []
				// });    
			})
		});

		this.cur_company_id = localStorage.getItem('company_id');
		if (this.pageEvent == null) {
			this.pageEvent = new PageEvent();
			this.pageEvent.length = this.length;
			this.pageEvent.pageIndex = this.pageIndex;
			this.pageEvent.pageSize = this.pageSize;
			console.log("APi before", this.pageEvent.pageIndex)

		}


		this.createForm(null);
		this.isLoading = true;
		this.getUserList(this.pageEvent);
		//<<<<<<< HEAD
		if (!this.restService.isAdmin(this.userData)) {
			this.router.navigate(['/dashboard']);
			// =======
			// 		if (!this.restService.isAdmin(this.userData)) {
			// 			this.router.navigate(['/discussion-board']);
			// >>>>>>> 4d828de819c12162b69aa69c47d6f8c0a011edfd
		}


		// this.getActivites();

	}

	ngOnDestroy() {
		this.preserveSearch.searchUserState = {
			searchValue: this.searchString,
			pageEvent: this.pageEvent,
			results: this.userListData,
			resultsLength: this.userListData.length,
		};
	}

	recoverLastSearchValue() {
		const lastSearch = this.preserveSearch.searchUserState;
		if (lastSearch) {
			this.searchString = lastSearch.searchValue;
			this.userListData = lastSearch.results;
			this.pageEvent = lastSearch.pageEvent;
		}


	}


	createForm(data) {
		if (data === null) {
			this.setRoles();
			this.userCreateForm = this.formBuilder.group({
				'roleName': ['', [Validators.required]],
				'roleType': ['', [Validators.required]],
				'name': ['', [Validators.required, Validators.maxLength(50)]],
				'emailAddress': ['', [Validators.required, ValidationService.emailValidator]],
				'mobile': ['', [ValidationService.mobileValidator]],
				'companyUserId': ['']
			});
			this.showCreateUserPopup = true;
			console.log('this.showCreateUserPopup');
			console.log(this.showCreateUserPopup);

		} else {
			console.log("my data>>>>", data);
			var role = '';
			if (data.roleType == 1 || data.role == 1) {
				this.setRolesAgainForEdit();
				role = this.roleTypes[0];
				data.roleType = 1;
			} else if (data.roleType == 2 || data.role == 2) {
				this.setRolesAgainForEdit();
				role = this.roleTypes[1];
				data.roleType = 2;
			} else if (data.roleType == 3 || data.role == 3) {
				this.setRoles();
				role = this.roleTypes[2];
				data.roleType = 3;
			}

			this.userCreateForm = this.formBuilder.group({
				'roleName': [role, [Validators.required]],
				'roleType': [data.roleType, [Validators.required]],
				'name': [data.companyUserName, [Validators.required]],
				'emailAddress': [data.email, [Validators.required, ValidationService.emailValidator]],
				'mobile': [data.companyUserMobile, [ValidationService.mobileValidator]],
				'companyUserId': [data.companyUserId]
			});

			this.showEditUserPopup = true;
			console.log('this.showEditUserPopup');
			console.log(this.showEditUserPopup);
		}
		console.log('this.userCreateForm');
		console.log(this.userCreateForm);
	}

	setRoles() {
		this.roleTypes = ['Company Admin', 'Company User', 'Client'];
		this.roles = [];
		for (var i = 0; i < this.roleTypes.length; i++) {
			var role = this.roleTypes[i];
			var data;
			if (role == 'Company Admin') {
				data = new Data(role, "1");

			} else if (role == 'Company User') {
				data = new Data(role, "2");
			} else if (role == "Client") {
				data = new Data(role, "3");
			}
			this.roles.push(data);
		}
	}

	setRolesAgainForEdit() {
		this.roleTypes = ['Company Admin', 'Company User'];
		this.roles = [];
		for (var i = 0; i < this.roleTypes.length; i++) {
			var role = this.roleTypes[i];
			var data;
			if (role == 'Company Admin') {
				data = new Data(role, "1");

			} else if (role == 'Company User') {
				data = new Data(role, "2");
			}
			this.roles.push(data);
		}
	}

	resetUserCreateForm() {
		this.createForm(null);
	}

	sortData(field) {
		this.sortByField = field;
		this.sortOrder = this.sortOrder == 'asc' ? 'desc' : 'asc';
		this.getUserList(this.pageEvent);
	}


	//projectList
	getUserList(event?: PageEvent) {
		//this.searchUserForm.reset();

		let postdata = {
			"sort": {
				"column": this.sortByField,
				"direction": this.sortOrder
			},
			"page": event.pageIndex + 1,
			"size": event.pageSize,
			"q": this.searchString
		};
		console.log("APi After", event.pageIndex)
		this.restService.hitApi('companyUser/list', postdata, 'post', '', true)
			.subscribe((result) => {
				this.isLoading = false;
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					console.log("responseData.result", responseData.result)
					this.userListData = responseData.result.data;
					this.datasource = responseData.result;
					if (responseData.result.currentPage != 0) {
						this.pageIndex = responseData.result.currentPage - 1;
					}

					this.pageSize = responseData.result.perPage;
					this.length = responseData.result.total;
					this.pageEvent = new PageEvent();
					this.pageEvent.length = this.length;
					this.pageEvent.pageIndex = this.pageIndex;
					this.pageEvent.pageSize = this.pageSize;

					this.setIndexArray();
					this.checkIfDataHasComes();
					// this.usercount = responseData.result.data.length;
				} else {
					this.fetchingData = false;
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				this.isLoading = false;
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	checkIfDataHasComes() {
		if (this.userListData.length == 0) {
			if (this.searchString != "" && this.searchString != null) {
				this.isNoSearchResults = true;
				this.isNoData = false;
			} else {
				this.isNoData = true;
				this.isNoSearchResults = false;
			}
		} else {
			this.isNoData = false;
			this.isNoSearchResults = false;
		}
	}

	searchUser(field, e) {
		this.searchString = $('#search_name').val();
		console.log("i am searching", this.searchString);
		console.log(this.pageEvent);
		if (this.searchString.length >= 2 || this.searchString.length == 0) {
			this.pageEvent = new PageEvent();
			this.pageEvent.length = 0;
			this.pageEvent.pageIndex = 0;
			this.pageEvent.pageSize = 10;
			this.getUserList(this.pageEvent);

		}

		// let searchdata: any = { "search": {} };

		// if ($('#search_name').val() != "") {
		// 	searchdata.search.companyUserName = $('#search_name').val();
		// }
		// if ($('#search_email').val() != "") {
		// 	searchdata.search.email = $('#search_email').val();
		// }
		// if ($('#search_role').val() != "") {
		// 	searchdata.search.role = $('#search_role').val();
		// }
		// if ($('#search_mobile').val() != "") {
		// 	searchdata.search.companyUserMobile = $('#search_mobile').val();
		// }

		// let postdata = {
		// 	"sort": {
		// 		"column": this.sortByField,
		// 		"direction": this.sortOrder
		// 	},
		// 	"page": event.pageIndex,
		// 	"size": event.pageSize
		// };


		// let searchdata: any = { "q": $('#search_name').val() };

		// let postdata = {
		// 	"sort": {
		// 		"column": this.sortByField,
		// 		"direction": this.sortOrder
		// 	},
		// 	"page": event.pageIndex + 1,
		// 	"size": event.pageSize
		// };

		// this.restService.hitApi('companyUser/list', searchdata, 'post', '', true)
		// 	.subscribe((result) => {
		// 		let responseData: any = result;
		// 		if (responseData.statusCode == 200) {
		// 			this.userListData = responseData.result.data;
		// 		} else {
		// 			this.fetchingData = false;
		// 		}
		// 	}, (err) => {
		// 		console.log(err);
		// 		let errorData: any = err;
		// 		// this.toastr.error(errorData.error.message);
		// 	})
	}

	setIndexArray() {
		for (let i = 0; i < this.userListData.length; i++) {
			if (this.pageIndex == 0) {
				this.userListData[i].tableIndex = i + 1;
			} else {
				this.userListData[i].tableIndex = (this.pageSize * this.pageIndex) + i + 1;
			}
		}
	}

	addUser() {

		if (this.userCreateForm.controls['roleName'] != null) {
			if (this.userCreateForm.controls['roleName'].value == 'Company Admin') {
				this.userCreateForm.get('roleType').setValue(1);
			} else if (this.userCreateForm.controls['roleName'].value == 'Company User') {
				this.userCreateForm.get('roleType').setValue(2);
			} else if (this.userCreateForm.controls['roleName'].value == "Client") {
				this.userCreateForm.get('roleType').setValue(3);
			}

		}
		console.log(this.userCreateForm);


		if (this.userCreateForm.invalid) {
			this.userCreateFormSubmit = true;

			return;
		} else {
			this.restService.hitApi('companyUser/create', this.userCreateForm.value, 'post', '', true)
				.subscribe((result) => {

					let responseData: any = result;
					console.log("responseData.message", responseData.message)
					if (responseData.statusCode == 200) {
						$('#create-single-multiple-user').modal('hide');
						this.closeModal();
						this.userCreateForm.reset();
						this.getUserList(this.pageEvent);
						this.toastr.success(responseData.message);
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
					console.log("responseData.message errorData", errorData.error.message)
				})
		}
	}


	updateUser() {
		if (this.userCreateForm.invalid) {
			this.userCreateFormSubmit = true;
			return;
		} else {
			this.restService.hitApi('companyUser/update', this.userCreateForm.value, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						$('#profile-edit-user').modal('hide');
						this.closeModal();
						this.userCreateForm.reset();
						this.getUserList(this.pageEvent);
						this.toastr.success(responseData.message);
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}
	}



	// openEditUser(data){
	// 	console.log(data);
	// 	this.createForm(data);
	// 	$('#profile-edit-user').modal('show');

	// }

	openModal(userArr, modal) {
		this.selectedForDelete = userArr;
		$('#' + modal).modal({ backdrop: 'static', keyboard: false });
	}

	closeModal() {
		this.userCreateForm.reset();
		this.userCreateFormSubmit = false;
		this.showCreateUserPopup = false;
		this.showEditUserPopup = false;
		this.fileData = null;
		this.validateFile = "";
	}

	deleteUser(uid) {
		var index;
		for (var i = 0; i < this.userListData.length; i++) {
			if (this.userListData[i].id == uid) {
				index = i;
				break;
			}
		}
		this.restService.hitApi('companyUser/delete', { 'companyUserId': uid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('#delete-user').modal('hide');
					if (index != -1) {
						this.userListData.splice(index, 1);
						if (this.userListData.length == 0) {
							this.pageEvent = new PageEvent();
							this.pageEvent.length = this.length;
							this.pageEvent.pageIndex = 0;
							this.pageEvent.pageSize = this.pageSize;

							this.getUserList(this.pageEvent);
						}
						this.length = this.length - 1;
					}
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	roleSelected(role) {
		$('.progress-work-st-js').slideUp();
		$('.edit-role-progress-work-st-js').slideUp();

		if (role == 'Company Admin') {
			this.userCreateForm.get('roleType').setValue(1);
		} else if (role == 'Company User') {
			this.userCreateForm.get('roleType').setValue(2);
		} else if (role == "Client") {
			this.userCreateForm.get('roleType').setValue(3);
		}
		this.userCreateForm.get('roleName').setValue(role);

		console.log(this.userCreateForm);
	}

	get f() {
		return this.userCreateForm.controls;
	}

	// showAssociatedProjects(data){
	// 	this.restService.hitApi('companyUser/projectList',{'userId':data.id},'get','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.selectUser = data;
	// 			this.assocProjects = responseData.result;
	// 			// this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
	// 			// 	// this.assocUsers = this.projectListData[0].projectUsersProject;
	// 			//   }, (reason) => {
	// 			// 	// this.inviteForm.reset();
	// 			// });
	// 		}else{
	// 			this.fetchingData = false;
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// 	// this.assocProjects = data;

	// }

	// closeModal(){
	// 	// this.modalService.dismissAll();
	// 	this.selectUser = "";
	// }

	removeAccociatedProject(puid, uid, urid) {
		// const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
		// 	width: '350px',
		// 	data: "Do you really want to remove this project?"
		//   });
		//   dialogRef.afterClosed().subscribe(result => {
		// 	if(result) {
		// 	  this.restService.hitApi('companyUser/removeProjectUser',{'projectUserId':puid,'userId':uid,'userRoleId':urid},'post','',true)
		// 		  .subscribe((result)=>{
		// 			  let responseData : any = result;
		// 			  if(responseData.statusCode==200){
		// 				  this.showAssociatedProjects(this.selectUser);
		// 				this.toastr.success(responseData.message);
		// 			  }else{
		// 				  this.toastr.error(responseData.message);
		// 			  }
		// 		  },(err)=>{
		// 			  console.log(err);
		// 			  let errorData : any = err;
		// 			  this.toastr.error(errorData.error.message);
		// 		  })
		// 	}
		//   });
	}


	// openUpdateRole(uid){
	// 	$("#selectRole"+uid).show();
	// 	$("#showRole"+uid).hide();
	// }

	// updateRole(uid){
	// 	let roleType = $("#selectRole"+uid+" select").val();
	// 	this.restService.hitApi('companyUser/update',{'companyUserId':uid,'roleType':roleType},'post','',true)
	// 		.subscribe((result)=>{
	// 			let responseData : any = result;
	// 			if(responseData.statusCode==200){
	// 				$("#selectRole"+uid).show();
	// 				$("#showRole"+uid).hide();
	// 				this.getUserList();
	// 				this.toastr.success(responseData.message);
	// 			}else{
	// 				this.toastr.error(responseData.message);
	// 			}
	// 		},(err)=>{
	// 			console.log(err);
	// 			let errorData : any = err;
	// 			this.toastr.error(errorData.error.message);
	// 	})
	// }



	// updateRoleInProject(puid,uid,urid){
	// 	let roleType = $("#selectRole"+puid+" select").val();
	// 	this.restService.hitApi('companyUser/updateProjectUser',{'projectUserId':puid,'userId':uid,'userRoleId':roleType},'post','',true)
	// 		.subscribe((result)=>{
	// 			let responseData : any = result;
	// 			if(responseData.statusCode==200){
	// 				$("#selectRole"+puid).show();
	// 				$("#showRole"+puid).hide();
	// 				this.showAssociatedProjects(this.selectUser);
	// 				this.toastr.success(responseData.message);
	// 			}else{
	// 				this.toastr.error(responseData.message);
	// 			}
	// 		},(err)=>{
	// 			console.log(err);
	// 			let errorData : any = err;
	// 			this.toastr.error(errorData.error.message);
	// 	})
	// }


	// resendInvitation(uid){
	// 	this.restService.hitApi('companyUser/resendInvite',{'companyUserId':uid},'post','',true)
	// 		.subscribe((result)=>{
	// 			let responseData : any = result;
	// 			if(responseData.statusCode==200){
	// 				this.toastr.success(responseData.message);
	// 			}else{
	// 				this.toastr.error(responseData.message);
	// 			}
	// 		},(err)=>{
	// 			console.log(err);
	// 			let errorData : any = err;
	// 			this.toastr.error(errorData.error.message);
	// 	})
	// }

	// openCloseFilterBox(){
	// 	$("#filter-import-box").toggle();
	// }

	// closeImport(){
	// 	$("#filter-import-box").hide();
	// }

	selectCsvFile(event) {
		this.fileData = event[0];
		this.validateCSVFile();
	}

	validateCSVFile() {

		if (this.fileData != null && this.fileData != undefined) {
			let file: File = event[0];
			const formData: FormData = new FormData();
			formData.append('csvImport', this.fileData);

			this.restService.hitApi('companyUser/company-user-import-validation', formData, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200 || responseData.status_code == 200) {
						this.validateFile = responseData.result;
						this.toastr.success(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		} else {
			this.toastr.error("Please select CSV file to upload mutiple users");
		}

	}

	importUsers() {
		if (this.validateFile != "" && this.validateFile != null && this.validateFile != undefined) {
			this.restService.hitApi('companyUser/user-csv-import', { 'csvImport': this.validateFile }, 'post', '', true, false)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						$('#create-single-multiple-user').modal('hide');
						this.toastr.success(responseData.message);
						this.getUserList(this.pageEvent);
						this.validateFile = "";
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		} else {
			this.toastr.error("Please select CSV file to upload mutiple users");
		}
	}

	getRoleType(obj: Data) {
		if (obj) {
			console.log("selectedRole" + obj.name);
			this.userCreateForm.get('roleType').setValue(parseInt(obj.value));
			this.userCreateForm.get('roleName').setValue(parseInt(obj.name));

			console.log(this.userCreateForm);
		}

	}

	openDialog(): void {
		const dialogRef = this.dialog.open(DialogOverviewExampleDialog);

		dialogRef.afterClosed().subscribe(result => {
			console.log(`Dialog result: ${result}`);
		});
	}

	isRoleDisable() {
		if (this.userData.ownerId != undefined && this.userData.ownerId == this.userData.userId || this.userCreateForm.get('roleType').value == 1) {
			console.log("yes it is same", this.userData.ownerId)
			return false
		} else if (this.userCreateForm.get('roleType').value == 3) {
			return true
		}

		return false
	}




}


