import { Pipe, PipeTransform } from '@angular/core';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Pipe({
  name: 'searchPipe'
})
export class SearchPipe implements PipeTransform {

  // transform(items: any[], filter): any {
  //   if (!items || !filter) {
  //     return items;
  //   }

  //   // filter items array, items which match and return true will be
  //   // kept, false will be filtered out
  //   return items.filter(item => item.searchKey.indexOf(filter.value) !== -1);
  // }

  transform(value: any, args?: any, key?: any): any {
    if (!value) return null;
    if (!args) return value;

    args = args.toLowerCase();

    return value.filter(function (item) {
      console.log("search item", JSON.stringify(item));
      console.log("search pipe", item.searchValue.toLowerCase().match(args));
      return item.searchValue.toLowerCase().match(args);
      //return item.userName.indexOf(args) !== -1
      //return JSON.stringify(item).toLowerCase().includes(args);
    });
  }

}
