import { Component, OnInit } from '@angular/core';

declare const $: any;

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})

export class DashboardComponent implements OnInit {
	userData: any;

	constructor() {
		this.userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : '';
	}

	ngOnInit() {

	}

}


