import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';

// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Data } from './../ui-components/select-dropdown/Data';
import { PreservedSearchStateService } from '../services/search/preserved-search-state.service';


// import { MatDialog } from '@angular/material';
// import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
declare const $: any;
@Component({
	selector: 'app-sprints',
	templateUrl: './sprints.component.html',
	styleUrls: ['./sprints.component.scss'],
})

export class SprintsComponent implements OnInit, OnDestroy {
	userData: any = JSON.parse(localStorage.getItem("userData"));
	sprintCreateForm: FormGroup;
	// projectData: any;
	sprintsData: any = [];
	sprintsMainData: any;
	releaseList: any = [];
	pid: any;
	rid: any;
	selectedSprint: any;

	files: any = [];
	imgArr: any = [];
	projectListData: any;
	projectData: any;
	shortCode: any;


	sortOrder: any = 'desc';
	sortByField: any = 'id'

	pageEvent: PageEvent;
	datasource: null;
	pageIndex: number = 0;
	pageSize: number = 10;
	length: number = 0;
	searchString: string = "";
	sprintCreateFormSubmit: boolean = false;
	isLoading: boolean = false;
	isShowMore: boolean = false;
	isCreateDialogShow: boolean = false;
	isProjectUser: boolean = false;

	isNoSearchResults: boolean = false;
	isNoData: boolean = false;

	// date: Date = new Date();


	// public myDatePickerOptions: IMyDpOptions = {
	//     dateFormat: 'dd-mm-yyyy',
	// 	disableUntil:{year: this.date.getFullYear(), month: this.date.getMonth()+1, day: this.date.getDate()-1}
	// };
	// public myDatePickerOptions2: IMyDpOptions = {
	//     dateFormat: 'dd-mm-yyyy',
	// 	disableUntil:{year: this.date.getFullYear(), month: this.date.getMonth()+1, day: this.date.getDate()-1}
	// };

	constructor(
		private restService: RestService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private preserveSearch: PreservedSearchStateService) {
		// Check for route params

		this.pid = localStorage.getItem('projectSelected')
		this.shortCode = localStorage.getItem('projectShortCode');

	}

	ngOnInit() {
		this.recoverLastSearchValue();

		if (this.pid && this.pid !== null && this.pid !== undefined) {
			if (this.pageEvent == null) {
				this.pageEvent = new PageEvent();
				this.pageEvent.length = this.length;
				this.pageEvent.pageIndex = this.pageIndex;
				this.pageEvent.pageSize = this.pageSize;
				console.log("APi before", this.pageEvent.pageIndex)
			}

			//this.isLoading = true;
			this.getProjectDetail();
		}

		let that = this;
		$(document).ready(() => {

			setTimeout(() => {
				$('.searchDate').datepicker({
					format: 'dd-mm-yyyy',
					autoclose: true,
					todayHighlight: true,
					clearBtn: true
				}).on('changeDate', function (e) {
					$('.datepicker').hide();
					that.searchData();
				});
			}, 10);
		});
		// this.getProjectList();
		this.isLoading = true;
		this.getSprints(this.pageEvent);
		this.createForm(null);
	}

	ngOnDestroy() {
		this.preserveSearch.searchSprintState = {
			searchValue: this.searchString,
			pageEvent: this.pageEvent,
			results: this.sprintsData,
			resultsLength: this.sprintsData.length,
		};
	}

	recoverLastSearchValue() {
		const lastSearch = this.preserveSearch.searchSprintState;
		if (lastSearch) {
			this.searchString = lastSearch.searchValue;
			this.sprintsData = lastSearch.results;
			this.pageEvent = lastSearch.pageEvent;


			//this.resultsLength = lastSearch.resultsLength;
		}


	}

	getProjectDetail() {

		if (localStorage.getItem("isOwner") == "true") {
			this.isProjectUser = false
		}

		if (localStorage.getItem("isCompanyAdmin") == "true") {
			this.isProjectUser = false
		}

		if (localStorage.getItem("isProjectManager") == "true") {
			this.isProjectUser = false
		}

		if (localStorage.getItem("isOther") == "true") {
			this.isProjectUser = true
		} else {
			this.isProjectUser = true
		}

		// this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', true)
		// 	.subscribe((result) => {
		// 		let responseData: any = result;
		// 		if (responseData.statusCode == 200) {
		// 			this.projectData = responseData.result;
		// 			var isOwner = localStorage.getItem("isOwner");
		// 			if (isOwner == "true") {
		// 				this.isProjectUser = false;
		// 			} else {
		// 				if (this.userData.role == 1) {
		// 					this.isProjectUser = false;
		// 				} else {
		// 					if (this.projectData && this.projectData.projectUsersProject) {
		// 						this.projectData.projectUsersProject.forEach(element => {
		// 							if (element.userId == this.userData.id) {
		// 								if (element.userRoleId == 1) {
		// 									this.isProjectUser = false;
		// 								} else {
		// 									this.isProjectUser = true;
		// 								}
		// 							}
		// 						});


		// 					} else {
		// 						this.isProjectUser = false;
		// 					}
		// 				}
		// 			}

		// 			console.log("this.projectData.userRoleId, ", this.projectData.userRoleId);
		// 		} else {
		// 			// this.toastr.error(responseData.message);
		// 		}
		// 	}, (err) => {
		// 		console.log(err);
		// 		let errorData: any = err;
		// 		// this.toastr.error(errorData.error.message);
		// 	})
	}

	// getProjectList() {
	// 	this.restService.hitApi('discussion/projects',{},'get','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.projectListData = responseData.result.data;
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }

	// changeProject(pid){
	// 	localStorage.setItem('projectSelected',pid)
	// 	$('.project-title-list').toggle("slide");
	// 	this.router.navigateByUrl('/project/'+pid+'/sprints');
	// 	setTimeout(() => {
	// 		this.getSprints();
	// 	}, 500);

	// }

	createForm(data) {
		if (data == null) {
			this.sprintCreateForm = this.formBuilder.group({
				'name': ['', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
				'goal': [''],
				// 'fromDate':['',[Validators.required]],
				// 'toDate':[''],
				'releaseName': [''],
				'releaseId': [''],
				// 'status':['',[Validators.required]],
				'attachments': []
			});

			// let date = new Date();
			// this.sprintCreateForm.patchValue({fromDate: {
			// date: {
			// 	year: date.getFullYear(),
			// 	month: date.getMonth() + 1,
			// 	day: date.getDate()}
			// }});
			// this.sprintCreateForm.patchValue({toDate: {
			// 	date: {
			// 		year: date.getFullYear(),
			// 		month: date.getMonth() + 1,
			// 		day: date.getDate()}
			// }});

		} else {
			this.sprintCreateForm = this.formBuilder.group({
				'name': [data.name, [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
				'goal': [data.description],
				// 'fromDate':[data.fromDate,[Validators.required]],
				// 'toDate':[data.toDate],
				'releaseName': [data.releaseName],
				'releaseId': [data.releaseId],
				'sprintId': [data.id],
				// 'status':[data.status,[Validators.required]],
				'attachments': [],
				'oldattachments': [data.projectsProjectFile]
			});
			// let fromdate = new Date(data.fromDate);
			// this.sprintCreateForm.patchValue({fromDate: {
			// 	date: {
			// 		year: fromdate.getFullYear(),
			// 		month: fromdate.getMonth() + 1,
			// 		day: fromdate.getDate()}
			// }});
			// let todate = new Date(data.toDate);
			// this.sprintCreateForm.patchValue({toDate: {
			// 	date: {
			// 		year: todate.getFullYear(),
			// 		month: todate.getMonth() + 1,
			// 		day: todate.getDate()}
			// }});
		}

	}

	// onDateChanged(event: IMyDateModel) {
	// 	// event properties are: event.date, event.jsdate, event.formatted and event.epoc
	// 	console.log(event.date);
	// 	this.myDatePickerOptions2 = {
	// 		disableUntil:event.date
	// 	};
	// }

	createSprint() {

		if (this.sprintCreateForm.invalid) {
			this.sprintCreateFormSubmit = true;
			return;
		} else {
			console.log(this.sprintCreateForm.value);
			let apiname = (this.sprintCreateForm.value.sprintId) ? 'sprint/update' : 'sprint/create'
			this.sprintCreateForm.value.projectId = this.pid;

			// this.sprintCreateForm.value.fromDate = this.sprintCreateForm.value.fromDate.date.year+"-"+this.sprintCreateForm.value.fromDate.date.month+"-"+this.sprintCreateForm.value.fromDate.date.day;
			// this.sprintCreateForm.value.toDate = this.sprintCreateForm.value.toDate.date.year+"-"+this.sprintCreateForm.value.toDate.date.month+"-"+

			this.sprintCreateForm.value.toDate.date.day;
			this.sprintCreateForm.value.attachments = this.imgArr;
			this.sprintCreateForm.value.description = $('#summernote').val();
			this.restService.hitApi(apiname, this.sprintCreateForm.value, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						$('#summernote').summernote("reset");
						this.closeModal();
						this.createForm(null);
						this.getSprints(this.pageEvent);
						$('#create_release_popup_web').modal('hide');
						this.toastr.success(responseData.message);
						this.files = [];
						this.imgArr = [];
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}

	}

	closeModal() {
		this.sprintCreateForm.reset();
		this.sprintCreateFormSubmit = false;
		this.isCreateDialogShow = false;
		this.showLess();
	}

	sortData(field) {
		this.sortByField = field;
		this.sortOrder = this.sortOrder == 'asc' ? 'desc' : 'asc';
		this.getSprints(this.pageEvent);
	}

	// sortData(field){
	// 	this.sortByField = field;
	// 	this.sortOrder = this.sortOrder=='asc'?'desc':'asc';
	// 	let postdata = {"sort": {
	// 		"column": this.sortByField,
	// 		"direction": this.sortOrder,
	// 		"projectId":this.pid
	// 	}};
	// 	this.restService.hitApi('sprint/list',postdata,'post','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.sprintsData = (responseData.result.total>0)?responseData.result.data:[];
	// 		}else{
	// 			this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }

	searchData() {

		this.searchString = $('#search_name').val();
		console.log("i am searching", this.searchString);
		console.log(this.pageEvent);
		if (this.searchString.length >= 2 || this.searchString.length == 0) {
			this.pageEvent = new PageEvent();
			this.pageEvent.length = 0;
			this.pageEvent.pageIndex = 0;
			this.pageEvent.pageSize = 10;
			this.getSprints(this.pageEvent);

		}

		// let searchdata : any = {"projectId":this.pid,"search":{}};

		// if($('#search_id').val()!=""){
		// 	searchdata.search.id = $('#search_id').val().replace(/SID/i,'');
		// }
		// if($('#search_name').val()!=""){
		// 	searchdata.search.name = $('#search_name').val();
		// }
		// if($('#search_releaseTitle').val()!=""){
		// 	searchdata.search.releaseTitle = $('#search_releaseTitle').val();
		// }
		// if($('#search_fromDate').val()!=""){
		// 	searchdata.search.fromDate = $('#search_fromDate').val();
		// }
		// if($('#search_status').val()!=""){
		// 	searchdata.search.status = $('#search_status').val();
		// }

		// this.restService.hitApi('sprint/list',searchdata,'post','',true)
		// .subscribe((result)=>{
		// 	let responseData : any = result;
		// 	if(responseData.statusCode==200){
		// 		this.sprintsData = (responseData.result.total>0)?responseData.result.data:[];
		// 	}else{
		// 		this.toastr.error(responseData.message);
		// 	}
		// },(err)=>{
		// 	console.log(err);
		// 	let errorData : any = err;
		// 	// this.toastr.error(errorData.error.message);
		// })
	}

	getSprints(event?: PageEvent) {
		// this.restService.hitApi('release/list-dropdown?projectId=' + this.pid, '', 'get', '', false)
		// 	.subscribe((result) => {

		// 		let responseData: any = result;
		// 		if (responseData.statusCode == 200) {
		// 			this.releaseList = responseData.result
		// 		} else {
		// 			this.toastr.error(responseData.message);
		// 		}
		// 	}, (err) => {
		// 		console.log(err);
		// 		let errorData: any = err;
		// 		// this.toastr.error(errorData.error.message);
		// 	})

		let postdata = {
			"projectId": this.pid,
			"sort": {
				"column": this.sortByField,
				"direction": this.sortOrder
			},
			"page": event.pageIndex + 1,
			"size": event.pageSize,
			"q": this.searchString
		};

		this.restService.hitApi('sprint/list', postdata, 'post', '', true)
			.subscribe((result) => {
				this.isLoading = false;
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.sprintsMainData = responseData.result;
					this.sprintsData = (responseData.result.total > 0) ? responseData.result.data : [];

					this.datasource = responseData.result;
					if (responseData.result.currentPage != 0) {
						this.pageIndex = responseData.result.currentPage - 1;
					}

					this.pageSize = responseData.result.perPage;
					if (responseData.result.total > 0) {
						this.length = responseData.result.total;
					}

					this.pageEvent = new PageEvent();
					this.pageEvent.length = this.length;
					this.pageEvent.pageIndex = this.pageIndex;
					this.pageEvent.pageSize = this.pageSize;


					console.log("this.lengththis.length", this.length);
					this.setIndexArray();
					this.checkIfDataHasComes();
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				this.isLoading = false;
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	checkIfDataHasComes() {
		if (this.sprintsData.length == 0) {
			if (this.searchString != "" && this.searchString != null) {
				this.isNoSearchResults = true;
				this.isNoData = false;
			} else {
				this.isNoData = true;
				this.isNoSearchResults = false;
			}
		} else {
			this.isNoData = false;
			this.isNoSearchResults = false;
		}
	}

	deleteSprint(rid) {
		var index;
		for (var i = 0; i < this.sprintsData.length; i++) {
			if (this.sprintsData[i].id == rid) {
				index = i;
				break;
			}
		}
		this.restService.hitApi('sprint/delete', { 'sprintId': rid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					if (index != -1) {
						this.sprintsData.splice(index, 1);
						if (this.sprintsData.length == 0) {
							this.pageEvent = new PageEvent();
							this.pageEvent.length = this.length;
							this.pageEvent.pageIndex = 0;
							this.pageEvent.pageSize = this.pageSize;

							this.getSprints(this.pageEvent);
						}
						this.sprintsMainData.total = this.sprintsMainData.total - 1;
						this.length = this.sprintsMainData.total;
					}

					$('#delete-record').modal('hide')
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	blockSprint(sid, blockstatus) {
		let isBlocked = (blockstatus == 1) ? 0 : 1;
		this.restService.hitApi('sprint/block', { 'sprintId': sid, 'isBlocked': isBlocked }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getSprints(this.pageEvent);
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	getRoleType(obj: Data) {
		if (obj) {
			console.log("selectedRole" + obj.name);
			console.log("selectedId" + obj.id);
			this.sprintCreateForm.get('releaseId').setValue(parseInt(obj.id));
			this.sprintCreateForm.get('releaseName').setValue(parseInt(obj.name));

			console.log(this.sprintCreateForm);
		}

	}


	removeAttachment(index) {
		this.files.splice(index, 1)
	}

	deleteAttachment(aid, index) {
		this.restService.hitApi('sprints/delete-file', { "attachmentId": aid, "projectId": this.pid }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.sprintCreateForm.value.oldattachments.splice(index, 1);
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	//Image upload
	uploadFile(event) {
		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];
			const formData: FormData = new FormData();
			formData.append('projectId', this.pid);
			formData.append('file', file);
			this.files.push(file.name);

			this.restService.hitApi('sprints/upload-file', formData, 'post', '', true, false)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.imgArr.push(responseData.result);
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}

	}

	isBlocked() {
		this.toastr.error('Sprint is blocked, please unblock first.');
	}

	setIndexArray() {
		for (let i = 0; i < this.sprintsData.length; i++) {
			if (this.pageIndex == 0) {
				this.sprintsData[i].tableIndex = i + 1;
			} else {
				this.sprintsData[i].tableIndex = (this.pageSize * this.pageIndex) + i + 1;
			}
		}
	}

	showMore() {
		this.isShowMore = true;
		$('.moretext').slideDown();

	}

	showLess() {
		this.isShowMore = false;
		$('.moretext').slideUp();

	}

	/*openCloseFilterBox(box){
		if(box!="filter-column-box") $("#filter-column-box").hide();
		$("#"+box).toggle();
	}

	selectColumn(e){
		console.log(e);
		// $("#custom-select-option-box").show();
		if (e.target.checked == true) {
			this.showColumns.push(e.target.defaultValue);
			console.log(this.showColumns);
		} else {
			// $(obj).parent().css("background", '#FFF');
			this.showColumns.splice(this.showColumns.indexOf(e.target.defaultValue),1);
			console.log(this.showColumns);
		}
	}

	isSelected(value: string): boolean {
		// console.log(this.showColumns.indexOf(value));
		return this.showColumns.indexOf(value) >= 0;
	}

	

	

	
	*/

}

