import { FormControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { MyErrorStateMatcher } from './../../MyErrorStateMatcher';
import { Component, OnInit, Input, Self, Optional, forwardRef } from '@angular/core';


@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit, ControlValueAccessor {

  @Input()
  label: string;

  @Input()
  required: boolean;

  @Input() type: 'text' | 'email' | 'password' = 'text';
  @Input() isEyeButton;
  @Input() disabled: boolean;
  @Input() ngAutoFocus: boolean;
  @Input() errors: any = null;
  @Input() maxLength: any = null;

  validators;
  value: any = '';
  control: FormControl;
  onChange: () => void;
  onTouched: () => void;


  constructor(@Self() @Optional()
  private ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
      setTimeout(() => {
        this.control = ngControl.control as FormControl;
        console.log('this.control');
        console.log(this.control);
      })

    }

  }


  ngOnInit() {
    console.log(this.ngControl.valueAccessor);
  }

  ngOnDestroy(): void {
    //throw new Error('Method not implemented.');
    this.control = null;
  }

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.value = value;
    console.log("writeValue called")

  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    console.log("setDisabledState called")
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChange = fn;
    console.log("registerOnChange called")
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    //this.onTouched = fn;
    console.log("registerOnTouched called")
  }


}
