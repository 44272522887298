import { setValue } from '@syncfusion/ej2-base';
import { filter } from 'rxjs/operators';
import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';

// Services
import { RestService } from '../services/rest/rest.service';

import { PreservedSearchStateService } from '../services/search/preserved-search-state.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

// import { MatDialog } from '@angular/material';
// import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
import { Data } from '../ui-components/select-dropdown/Data';
import { nullableTypeAnnotation } from 'node_modules1111/@babel/types/lib';
import { InputComponent } from '../ui-components/input/input.component';
import { identity } from 'node_modules1111/@types/lodash/ts3.1';
declare const $: any;
@Component({
	selector: 'app-defects',
	templateUrl: './defects.component.html',
	styleUrls: ['./defects.component.scss']
})

export class DefectsComponent implements OnInit, OnDestroy {
	@ViewChild(InputComponent, { static: false }) child: InputComponent;
	@ViewChild('searchInput', { static: false }) searchInput: ElementRef;


	userData: any = JSON.parse(localStorage.getItem("userData"));
	defectCreateForm: FormGroup;
	defectsData: any = [];
	defectsDataList: any = [];
	pid: any = localStorage.getItem('projectSelected')
	rid: any;

	selectedDefect: any;

	files: any = [];
	imgArr: any = [];
	projectListData: any;
	projectData: any;

	userList: any;
	filterUserList = [];
	sprintList: any;
	userstoryList: any;
	testplanList: any;
	testcaseList: any;
	pageEvent: PageEvent;
	datasource: null;
	pageIndex: number = 0;
	pageSize: number = 10;
	length: number = 0;
	showForm: boolean = true;
	sprintDataList: Data[] = [];
	//shortCode: any;


	priorityList = [{ name: 'Critical', value: "1" }, { name: 'High', value: "2" }, { name: 'Medium', value: "3" }, { name: 'Low', value: "4" }];
	assigneeList = [];

	sortOrder: any = 'desc';
	sortByField: any = 'id'
	searchString: any = "";
	uploading: boolean = false;
	pageNumber: number = 1;
	typeList: { name: string; value: string; }[];
	isLoading: boolean = false;
	isShowMore: boolean = false;
	submitDisable: boolean = false;
		
	isCreateDialogShow: boolean = true;
	isOwner: boolean;
	isCompanyAdmin: boolean;
	isProjectManager: boolean;

	isNoSearchResults: boolean = false;
	isNoData: boolean = false;
	isOther: boolean;
	selectedTypeName: any;
	projectType: any;

	constructor(
		private restService: RestService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private preserveSearch: PreservedSearchStateService) {

		if (this.userData.role == 3) {
			this.typeList = [{ name: 'External', value: "1" }];
		} else {
			this.typeList = [{ name: 'Internal', value: "2" }, { name: 'External', value: "1" }];
		}

		this.pid = localStorage.getItem('projectSelected')

		console.log("this.pid>> ", this.pid)
		this.selectedTypeName = this.typeList[0].name;

		this.isOwner = localStorage.getItem("isOwner") == 'true' ? true : false;
		this.isCompanyAdmin = localStorage.getItem("isCompanyAdmin") == 'true' ? true : false;
		this.isProjectManager = localStorage.getItem("isProjectManager") == 'true' ? true : false;
		this.isOther = localStorage.getItem("isOther") == 'true' ? true : false;
		this.projectType = localStorage.getItem("projectType");


		// Check for route params
		// this.route.params.subscribe(params => {
		// 	this.pid = params['pid'];


		// });

		//this.shortCode = localStorage.getItem('projectShortCode');


	}

	ngOnInit() {

		this.recoverLastSearchValue();

		if (this.pid && this.pid !== null && this.pid !== undefined && this.pid !== 'null') {
			if (this.pageEvent == null) {
				this.pageEvent = new PageEvent();
				this.pageEvent.length = this.length;
				this.pageEvent.pageIndex = this.pageIndex;
				this.pageEvent.pageSize = this.pageSize;

			}

			console.log("APi before", this.pageEvent.pageIndex);
			//this.getProjectDetail();
			this.getDefects(this.pageEvent);

			// MARK Govind - i have move these API to resolve project not selected issue
			this.isLoading = true;
			//this.getProjectSprints();
		}

		let that = this;


		/*$(document).ready(function () {
			$('.htmlEditor').summernote({
				height: '150px',
				focus: true,
				disableDragAndDrop: true,
				toolbar: [
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
				callbacks: {
					onPaste: function (e) {
						var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
						e.preventDefault();
						document.execCommand('insertText', false, bufferText);
					}
				}
			});

		});*/

		$(document).mouseup(function (e) {
			if (!$(".work-status-anchor").is(e.target) && $(".work-status-anchor").has(e.target).length === 0 && !$(".progress-work-st").is(e.target) && $(".progress-work-st").has(e.target).length === 0) {
				$(".progress-work-st").slideUp('fast');
			}
			if (!$(".assign-user-work").is(e.target) && $(".assign-user-work").has(e.target).length === 0 && !$(".assign-user-work-open").is(e.target) && $(".assign-user-work-open").has(e.target).length === 0) {
				$(".assign-user-work-open").slideUp('fast');
			}
			if (!$(".priority-work").is(e.target) && $(".priority-work").has(e.target).length === 0 && !$(".priority-work-open").is(e.target) && $(".priority-work-open").has(e.target).length === 0) {
				$(".priority-work-open").slideUp('fast');
			}
			if (!$(".type-work").is(e.target) && $(".type-work").has(e.target).length === 0 && !$(".type-work-open").is(e.target) && $(".type-work-open").has(e.target).length === 0) {
				$(".type-work-open").slideUp('fast');
			}
		});


		$(document).ready(() => {
			// var el = document.getElementById('search_name') as HTMLInputElement;
			// el.value = this.searchString;
			$('.htmlEditor').summernote({
				height: '150px',
				focus: true,
				disableDragAndDrop: true,
				dialogsInBody: true,
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
				callbacks: {
					onPaste: function (e) {
						var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
						e.preventDefault();
						document.execCommand('insertText', false, bufferText);
					},
					onImageUpload: function (data) {
						data.pop();
					}
				}
			});

			$('#summernote').summernote({
				dialogsInBody: true
			});


			/*$('.htmlEditor').summernote({
				height: '100px',
				focus: true
			});*/



			setTimeout(() => {
				$('.searchDate').datepicker({
					format: 'yyyy-mm-dd',
					autoclose: true,
					todayHighlight: true,
					clearBtn: true
				}).on('changeDate', function (e) {
					$('.datepicker').hide();
					that.searchData();
				});
				$('#search_name').val(this.searchString);
			}, 10);
		});
		// this.getProjectList();



		//this.getUserList(null);
		this.createForm(null);
	}

	ngOnDestroy() {
		this.preserveSearch.searchDefectState = {
			searchValue: this.searchString,
			pageEvent: this.pageEvent,
			results: this.defectsDataList,
			resultsLength: this.defectsDataList.length,
		};
	}

	recoverLastSearchValue() {
		const lastSearch = this.preserveSearch.searchDefectState;
		if (lastSearch) {
			this.searchString = lastSearch.searchValue;
			this.defectsDataList = lastSearch.results;
			this.pageEvent = lastSearch.pageEvent;


			//this.resultsLength = lastSearch.resultsLength;
		}


	}

	closeModal() {
		$('#create_release_popup_web').modal('toggle');
		this.isCreateDialogShow = false;
		setTimeout(() => {
			this.resetForm();
			this.isCreateDialogShow = true;
			this.setHeightOfSummarNote();
		});
	}

	closeDeletePopup() {
		$('#delete -record').modal('toggle');
	}

	resetForm() {

		this.defectCreateForm.reset();
		this.defectCreateForm.get('priority').setValue(this.priorityList[2].value);
		this.defectCreateForm.get('priorityName').setValue(this.priorityList[2].name);
		this.defectCreateForm.get('typeName').setValue(this.typeList[0].name);
		this.defectCreateForm.get('type').setValue(this.typeList[0].value);
		this.defectCreateForm.get('name').setValue(null);
		this.defectCreateForm.get('assignee').setValue(null);
		this.defectCreateForm.get('assigneeName').setValue('Unassigned');
		this.defectCreateForm.get('assigneeRole').setValue('');

		$('#summernote').summernote("reset");
		this.isShowMore = false;
		this.imgArr = [];
		this.files = []
		this.showLess();

		console.log("form after reset", this.defectCreateForm);

	}


	// getProjectDetail() {
	// 	this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', true)
	// 		.subscribe((result) => {
	// 			let responseData: any = result;
	// 			if (responseData.statusCode == 200) {
	// 				this.projectData = responseData.result;
	// 				if (this.userData) {
	// 					var isOwner = localStorage.getItem('isOwner');
	// 					if (isOwner == "true") {
	// 						this.isOwner = true;
	// 						this.isCompanyAdmin = false;
	// 						this.isProjectManager = false;
	// 						this.isOther = false;
	// 					} else {
	// 						if (this.userData.role == 1) {
	// 							this.isOwner = false;
	// 							this.isCompanyAdmin = true;
	// 							this.isProjectManager = false;
	// 							this.isOther = false;
	// 						} else if (this.userData.role == 2) {
	// 							if (this.projectData && this.projectData.projectUsersProject) {
	// 								this.projectData.projectUsersProject.forEach(element => {
	// 									if (element.userId == this.userData.id) {
	// 										if (element.userRoleId == 1) {
	// 											this.isProjectManager = true;
	// 											this.isOther = false;
	// 										} else {
	// 											this.isProjectManager = false;
	// 											this.isOther = true;
	// 										}
	// 									}
	// 								});
	// 								this.isOther = false;

	// 							} else {
	// 								this.isOther = true;
	// 							}
	// 							this.isOwner = false;
	// 							this.isCompanyAdmin = false;
	// 						} else {
	// 							this.isOther = true;
	// 							this.isOwner = false;
	// 							this.isProjectManager = false;
	// 							this.isCompanyAdmin = false;
	// 						}
	// 					}
	// 					// if (this.userData.isCompanyAdmin == 1) {
	// 					// 	if (this.userData.ownerId) {
	// 					// 		this.isOwner = true;
	// 					// 		this.isCompanyAdmin = false;
	// 					// 		this.isProjectManager = false;
	// 					// 	} else {
	// 					// 		this.isOwner = false;
	// 					// 		this.isCompanyAdmin = true;
	// 					// 		this.isProjectManager = false;
	// 					// 	}
	// 					// } else {
	// 					// 	if (this.projectData && this.projectData.projectUsersProject) {
	// 					// 		this.projectData.projectUsersProject.forEach(element => {
	// 					// 			if (element.userId == this.userData.id) {
	// 					// 				if (element.userRoleId == 1) {
	// 					// 					this.isProjectManager = true;

	// 					// 				} else {
	// 					// 					this.isProjectManager = false;
	// 					// 				}
	// 					// 			}
	// 					// 		});

	// 					// 	}
	// 					// 	this.isOwner = false;
	// 					// 	this.isCompanyAdmin = false;
	// 					// }
	// 				}
	// 			} else {
	// 				// this.toastr.error(responseData.message);
	// 			}
	// 		}, (err) => {
	// 			console.log(err);
	// 			let errorData: any = err;
	// 			// this.toastr.error(errorData.error.message);
	// 		})
	// }

	// getProjectList() {
	// 	this.restService.hitApi('discussion/projects',{},'get','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.projectListData = responseData.result.data;
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }

	// changeProject(pid){
	// 	localStorage.setItem('projectSelected',pid)
	// 	$('.project-title-list').toggle("slide");
	// 	this.router.navigateByUrl('/project/'+pid+'/defects');
	// 	setTimeout(() => {
	// 		this.getDefects();
	// 	}, 500);

	// }

	createForm(data) {
		// if (this.typeList[0].name == 'Internal') {
		// 	this.getUserList([2]);
		// } else {
		// 	this.getUserList(null);
		// }
		this.imgArr = [];
		this.files = [];
		if (data === null) {
			this.defectCreateForm = this.formBuilder.group({
				'name': ['', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
				'typeName': [this.typeList[0].name, [Validators.required]],
				'type': [this.typeList[0].value, [Validators.required]],
				'sprintId': [''],
				'sprintName': [''],
				'assignee': [''],
				'assigneeName': ['Unassigned'],
				'assigneeRole': [''],
				'description': [''],
				'priority': [this.priorityList[2].value],
				'priorityName': [this.priorityList[2].name],
				'affectVersion': [''],
				'estHours': [''],
				'attachments': []

			});
			this.defectCreateForm.get('typeName').setValue(this.typeList[0].name);
			this.defectCreateForm.get('type').setValue(this.typeList[0].value);
			console.log("create defect form", this.defectCreateForm);

		} else {
			// let estHours = data.estHours?data.estHours.split("."):'';
			// let actualHours = data.actualHours?data.actualHours.split("."):'';
			// let spendHours = data.spendHours?data.spendHours.split("."):'';
			// this.defectCreateForm = this.formBuilder.group({
			// 	'defectId': [data.id],
			// 	'name': [data.name, [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
			// 	'type': [data.description],
			// 	'description': [data.description],
			// 	'priority':[data.priority,[Validators.required]],
			// 	'affectVersion':[data.foundVersion],
			// 	'fixVersion':[data.fixVersion],
			// 	'owner':[data.assignee,[Validators.required]],
			// 	'estHr':[estHours[0],[Validators.pattern('[0-9]*')]],
			// 	'estMin':[estHours[1],[Validators.pattern('[0-9]*'),Validators.max(60)]],
			// 	'actualHr':[actualHours[0],[Validators.pattern('[0-9]*')]],
			// 	'actualMin':[actualHours[1],[Validators.pattern('[0-9]*'),Validators.max(60)]],
			// 	'spendHr':[spendHours[0],[Validators.pattern('[0-9]*')]],
			// 	'spendMin':[spendHours[1],[Validators.pattern('[0-9]*'),Validators.max(60)]],
			// 	'sprintId':[data.sprintId],
			// 	'projectTestPlanId':[data.projectTestPlanId],
			// 	'projectTestCaseId':[data.projectTestCaseId],
			// 	'userStoryId':[data.userstoryId],
			// 	'attachments':[],
			// 	'oldattachments':[data.projectsProjectFile]
			// });
			// let fromdate = new Date(data.fromDate);
			// this.defectCreateForm.patchValue({fromDate: {
			// 	date: {
			// 		year: fromdate.getFullYear(),
			// 		month: fromdate.getMonth() + 1,
			// 		day: fromdate.getDate()}
			// }});
			// let todate = new Date(data.toDate);
			// this.defectCreateForm.patchValue({endDate: {
			// 	date: {
			// 		year: todate.getFullYear(),
			// 		month: todate.getMonth() + 1,
			// 		day: todate.getDate()}
			// }});
		}
	}

	getUsers() {
		if (this.typeList[0].name == 'Internal') {
			this.getUserList([2]);
		} else {
			this.getUserList(null);
		}
	}


	createDefect() {
		this.submitDisable = true;

		let apiname = (this.defectCreateForm.value.defectId) ? 'defect/update' : 'defect/create'
		this.defectCreateForm.value.projectId = this.pid;
		this.defectCreateForm.value.estHours = this.defectCreateForm.value.estHr + "." + this.defectCreateForm.value.estMin;
		this.defectCreateForm.value.actualHours = this.defectCreateForm.value.actualHr + "." + this.defectCreateForm.value.actualMin;
		this.defectCreateForm.value.spendHours = this.defectCreateForm.value.spendHr + "." + this.defectCreateForm.value.spendMin;
		this.defectCreateForm.value.attachments = this.imgArr;
		this.defectCreateForm.value.description = $('#summernote').val();

		//this.defectCreateForm.value.description = $('#defect_description').val()
		console.log(this.defectCreateForm.value);
		if (this.defectCreateForm.invalid) {
			this.submitDisable = false;
			return false;
		} else {
			this.restService.hitApi(apiname, this.defectCreateForm.value, 'post', '', true)
				.subscribe((result) => {
					this.submitDisable = false;
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.toastr.success(responseData.message);
						this.closeModal();
						this.getDefects(this.pageEvent);
						// this.defectCreateForm.reset();
						this.files = [];
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					this.submitDisable = false;
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}
	}


	sortData(field) {
		this.sortByField = field;
		this.sortOrder = this.sortOrder == 'asc' ? 'desc' : 'asc';
		this.getDefects(this.pageEvent);
	}

	searchData() {
		this.searchString = $('#search_name').val();
		// if ($('#search_id').val() != "") {
		// 	this.searchdata.id = $('#search_id').val().replace(/DEF/i, '');
		// }
		// if ($('#search_name').val() != "") {
		// 	this.searchdata.name = $('#search_name').val();
		// }
		// if ($('#search_priority').val() != "") {
		// 	this.searchdata.priority = $('#search_priority').val();
		// }
		// if ($('#search_assignee').val() != "") {
		// 	this.searchdata.assignee = $('#search_assignee').val();
		// }
		// if ($('#search_status').val() != "") {
		// 	this.searchdata.status = $('#search_status').val();
		// }
		// if ($('#search_createdBy').val() != "") {
		// 	this.searchdata.createdBy = $('#search_createdBy').val();
		// }
		// if ($('#search_createdAt').val() != "") {
		// 	this.searchdata.createdAt = $('#search_createdAt').val();
		// }
		if (this.searchString.length >= 3 || this.searchString.length == 0) {
			this.pageEvent = new PageEvent();
			this.pageEvent.length = 0;
			this.pageEvent.pageIndex = 0;
			this.pageEvent.pageSize = 10;
			this.getDefects(this.pageEvent);
		}

		// console.log(this.searchdata.id);
		// this.defectsDataList = this.defectsDataList.filter((def)=>{
		// 	return def.id = this.searchdata.id;
		// });

		// this.defectsDataList.filter((def)=> {
		// 	//return hero.franchise == “Marvel”;
		// 	return def.id = this.searchdata.id;
		// });
	}

	getDefects(event: PageEvent) {
		let postdata = {
			"projectId": this.pid,
			"sort": {
				"column": this.sortByField,
				"direction": this.sortOrder
			},
			"q": this.searchString,
			// "page": this.pageNumber
			"page": event.pageIndex + 1,
			"size": event.pageSize,

		};
		// this.defectsDataList = [];
		// this.defectsData = [];
		this.restService.hitApi('defect/list', postdata, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {


					this.defectsData = (responseData.result.total > 0) ? responseData.result : '';
					this.defectsDataList = (responseData.result.total > 0) ? responseData.result.data : [];

					if (responseData.result.currentPage != 0) {
						this.pageIndex = responseData.result.currentPage - 1;
					}

					this.pageSize = responseData.result.perPage;
					this.length = responseData.result.total;
					this.pageEvent = new PageEvent();
					this.pageEvent.length = this.length;
					this.pageEvent.pageIndex = this.pageIndex;
					this.pageEvent.pageSize = this.pageSize;
					this.checkIfDataHasComes();
					this.setIndexArray();
					// if(responseData.result.total>0){
					// responseData.result.data.forEach(element => {
					// 	this.defectsDataList.push(element);
					// });
					// }

				} else {
					this.toastr.error(responseData.message);
				}
				this.isLoading = false;
			}, (err) => {
				this.isLoading = false;
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	checkIfDataHasComes() {
		if (this.defectsDataList.length == 0) {
			if (this.searchString != "" && this.searchString != null) {
				this.isNoSearchResults = true;
				this.isNoData = false;
			} else {
				this.isNoData = true;
				this.isNoSearchResults = false;
			}
		} else {
			this.isNoData = false;
			this.isNoSearchResults = false;
		}
	}

	setIndexArray() {
		for (let i = 0; i < this.defectsDataList.length; i++) {
			if (this.pageIndex == 0) {
				this.defectsDataList[i].tableIndex = i + 1;
			} else {
				this.defectsDataList[i].tableIndex = (this.pageSize * this.pageIndex) + i + 1;
			}

		}
	}

	getUserList(value) {
		if (this.pid != '' && this.pid != null && this.pid != "null" && this.pid != undefined) {
			this.restService.hitApi('project/projectUsers', { 'projectId': this.pid, escape: value }, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.userList = responseData.result;

						this.makeAssigneeList();
						console.log(this.userList);
					} else {
						// this.toastr.error(responseData.message);
					}
				}, (err) => {
					this.isLoading = false;
					console.log(err);
					let errorData: any = err;
					// this.toastr.error(errorData.error.message);
				})
		}

	}


	makeAssigneeList() {
		this.assigneeList = [];
		if (this.userList.length) {
			this.assigneeList = [];
			this.assigneeList.push(new Data('Unassigned', '', null));
			this.userList.forEach(element => {
				var data = new Data(element.name, element.userId, element.projectRole);
				this.assigneeList.push(data);
			});
		}
		console.log("make assignee", this.assigneeList);
	}
	getProjectSprints() {
		if (this.pid != '' && this.pid != null && this.pid != "null" && this.pid != undefined) {
			this.restService.hitApi('defect/projectSprints', { 'projectId': this.pid, notCompleted: 1 }, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.sprintList = responseData.result;
						this.sprintDataList = []
						this.sprintList.forEach(element => {
							let obj = new Data(element.name, element.id);
							this.sprintDataList.push(obj);
						});
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					// this.toastr.error(errorData.error.message);
				})
		}
	}

	onSelectSprint(e) {
		let sprintId = e.srcElement.value;
		this.restService.hitApi('defect/userStorySprint', { 'projectId': this.pid, 'sprintId': sprintId }, 'post', '', false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.userstoryList = responseData.result;
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.errmessage);
			})

		this.restService.hitApi('defect/testPlaneSprint', { 'projectId': this.pid, 'sprintId': sprintId }, 'post', '', false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.testplanList = (responseData.result.total > 0) ? responseData.result.data : [];
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.errmessage);
			})
	}

	onSelectTestplan(e) {
		let sprintId = e.srcElement.value;
		this.restService.hitApi('defect/userStorySprint', { 'projectId': this.pid, 'sprintId': sprintId }, 'post', '', false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.testcaseList = responseData.result;
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.errmessage);
			})
	}

	deleteDefect(did) {
		console.log(did);
		this.restService.hitApi('defect/delete', { 'defectId': did }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getDefects(this.pageEvent);
					$('#delete-record').modal('hide')
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	blockRelease(rid, blockstatus) {
		let isBlocked = (blockstatus == 1) ? 0 : 1;
		this.restService.hitApi('defect/block', { 'releaseId': rid, 'isBlocked': isBlocked }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getDefects(this.pageEvent);
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	//Image upload
	uploadFile(event) {
		if (event.length > 0) {
			this.uploading = true;

		} else {
			this.uploading = false;

		}
		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];
			const formData: FormData = new FormData();
			formData.append('projectId', this.pid);
			formData.append('file', file);
			this.files.push({ index: index, localFileName: file.name, serverFileName: null });
			this.restService.hitApi('defect/uploadFile', formData, 'post', '', true, false)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.uploading = false;
						this.files[index].serverFileName = responseData.result;
						this.imgArr.push(responseData.result);
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}

	}




	removeAttachment(index) {
		var file = this.files[index];
		var deleteFileArray = [file.serverFileName];
		this.removeAttachmentFromServer(deleteFileArray, index);
		// this.files.splice(index, 1)
	}

	removeAttachmentFromServer(files, index) {
		var json = {
			projectId: this.pid,
			entity: 'defect',
			files: files
		}
		this.restService.hitApi('project/deleteUploadedFiles', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.files.splice(index, 1);
					if (files && this.imgArr) {
						var removeIndex = null;
						files.forEach(element1 => {
							for (var i = 0; i < this.imgArr.length; i++) {
								if (this.imgArr[i] == element1) {
									removeIndex = i;
									break
								}
							}


						});

					}
					if (removeIndex != null) {
						this.imgArr.splice(removeIndex, 1);

					}
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			});
	}


	deleteAttachment(aid, index) {
		this.restService.hitApi('defect/deleteFileDefect', { "attachmentId": aid, "projectId": this.pid }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.defectCreateForm.value.oldattachments.splice(index, 1);
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	onScroll() {
		console.log('scrolled!!' + this.defectsData.lastPage + "===" + this.pageNumber);
		if (this.defectsData.lastPage > this.pageNumber) {
			this.pageNumber++;
			this.getDefects(this.pageEvent);
		}
	}

	getType(event) {
		if (event.value) {
			this.defectCreateForm.get('type').setValue(parseInt(event.value));
			this.defectCreateForm.get('typeName').setValue(event.name);
			if (event.name == 'External') {
				this.getUserList(null)
			} else {

				this.getUserList([2])
			}
			this.checkForAssignValue(event.name);
		}


	}
	getAssignee(obj: Data) {
		if (obj) {
			if (obj.value != '') {
				this.defectCreateForm.get('assignee').setValue(parseInt(obj.value));

			}
			this.defectCreateForm.get('assigneeName').setValue(parseInt(obj.name));
			this.defectCreateForm.get('assigneeName').setValue(parseInt(obj.id));

		}

	}
	getPriority(obj: Data) {
		if (obj) {
			this.defectCreateForm.get('priority').setValue(parseInt(obj.value));
			this.defectCreateForm.get('priorityName').setValue(parseInt(obj.name));

		}

	}
	showMore() {
		this.isShowMore = true;
		$('.moretext').slideDown();

	}

	showLess() {
		this.isShowMore = false;
		$('.moretext').slideUp();

	}

	setHeightOfSummarNote() {

		$(document).ready(function () {
			$('.htmlEditor').summernote({
				height: '150px',
				focus: true,

				disableDragAndDrop: true,
				dialogsInBody: true,
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
				callbacks: {
					onPaste: function (e) {
						var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
						e.preventDefault();
						document.execCommand('insertText', false, bufferText);
					},
					onImageUpload: function (data) {
						data.pop();
					}
				}
			});

			$('#summernote').summernote({
				dialogsInBody: true
			});

		})
		this.isCreateDialogShow = true;

		// $('.htmlEditor').summernote({
		// 	dialogsInBody: true
		// 	//,airMode: true
		// });
	}

	checkForAssignValue(type) {
		if (this.defectCreateForm.get('assignee').value && this.defectCreateForm.get('assignee').value) {
			if (type == 'Internal') {
				if (this.defectCreateForm.get('assigneeRole').value && this.defectCreateForm.get('assigneeRole').value == 2) {
					this.defectCreateForm.get('assignee').setValue('');
					this.defectCreateForm.get('assigneeName').setValue('Unassigned');
					this.defectCreateForm.get('assigneeRole').setValue('');

				} else {
					if (!this.defectCreateForm.get('assigneeRole').value) {
						this.defectCreateForm.get('assignee').setValue('');
						this.defectCreateForm.get('assigneeName').setValue('Unassigned');
						this.defectCreateForm.get('assigneeRole').setValue('');
					}

				}
			} else {
				if (this.defectCreateForm.get('assigneeRole').value && this.defectCreateForm.get('assigneeRole').value != 2) {
					this.defectCreateForm.get('assignee').setValue('');
					this.defectCreateForm.get('assigneeName').setValue('Unassigned');
					this.defectCreateForm.get('assigneeRole').setValue('');

				} else {
					if (!this.defectCreateForm.get('assigneeRole').value) {
						this.defectCreateForm.get('assignee').setValue('');
						this.defectCreateForm.get('assigneeName').setValue('Unassigned');
						this.defectCreateForm.get('assigneeRole').setValue('');
					}

				}
			}

		}
	}

	getSprintType(obj: Data) {
		if (obj != null) {
			this.defectCreateForm.get("sprintId").setValue(obj.value);
			this.defectCreateForm.get("sprintName").setValue(obj.name);
		} else {
			this.defectCreateForm.get("sprintId").setValue(null);
			this.defectCreateForm.get("sprintName").setValue(null);
		}
	}

	// navigateOnDetailPage(id) {

	// 	this.router.navigateByUrl('/defect/' + id, { state: { 'page_id': this.pageIndex + 1 } });


	// 	// const state = { 'page_id': this.pageIndex + 1 }
	// 	// const title = ''
	// 	// const url = '/defect/' + id;

	// 	// history.pushState(state, title, url);
	// 	// this.router.navigateByUrl(url);



	// 	// var extras = {
	// 	// 	page: this.pageEvent
	// 	// }
	// 	// this.router.navigateByUrl('/defect/' + id, { state: extras });

	// 	// this.router.navigate(['/defect/' + id], {
	// 	// 	state: {
	// 	// 		data: {
	// 	// 			pageEvent: this.pageEvent
	// 	// 		}
	// 	// 	}
	// 	// });

	// }



}

