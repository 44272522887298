import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { RestService } from '../services/rest/rest.service';
import { ToastrService } from 'ngx-toastr';
import { BroadcastService } from '../services/broadcast/broadcast.service';

@Component({
  selector: 'app-project-company-switch',
  templateUrl: './project-company-switch.component.html',
  styleUrls: ['./project-company-switch.component.scss']
})
export class ProjectCompanySwitchComponent implements OnInit {

  entityData: any;

  constructor(
            private restService: RestService,
            private router: Router,
            private route: ActivatedRoute,
            private toastr: ToastrService,
            private broadcastService: BroadcastService ) { 

      var params = this.router.getCurrentNavigation().extras.state
      this.entityData = params?.data['queryParams']?.responseData?.result
      console.log("entityData", this.entityData)

      if (!this.entityData?.companyId) {
        this.router.navigate(['dashboard']);
      }
  }

  ngOnInit() {
    
  }

  switch() {
    if (this.entityData?.companyId) {
      this.restService.hitApi('user/updateCompany', { "cur_company_id": this.entityData?.companyId }, 'post', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          localStorage.clear();
          localStorage.removeItem('projectSelected');
          localStorage.setItem('company_id', responseData.result.companyId);
          localStorage.setItem('company_name', responseData.result.companyName);
          localStorage.setItem('userData', JSON.stringify(responseData.result));

          if (responseData.result.id == responseData.result.ownerId) {
            localStorage.setItem('isOwner', "true");
          } else {
            localStorage.setItem('isOwner', "false");
          }

          this.broadcastService.boradcast("FETCH_PROJECT_LIST");

          let userData = responseData.result
          let selectedProjectId = userData && userData.projectId ? userData.projectId : '';
          if (selectedProjectId != null && selectedProjectId != 'null' && selectedProjectId != '') {
            localStorage.setItem('projectSelected', selectedProjectId);
          }

          this.broadcastService.boradcast("SWITCH_COMPANY", responseData.result.companyId);

          this.switchProject()
          
        } else {
           this.toastr.error(responseData.message);
        }
      }, (err) => {
        this.toastr.error(err.error.message);
      })
    } else {
      this.router.navigate(['dashboard']);
    }
    
  }

  switchProject() {
    if (this.entityData?.projectId) {
      let projectId = this.entityData?.projectId
      this.restService.hitApi('user/updateProject', { 'project_id': projectId }, 'post', '', true)
			.subscribe((result) => {
				console.log("user/updateProject", result)
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					localStorage.setItem('userData', JSON.stringify(responseData.result));
					localStorage.removeItem('sprintIdSelected');
					localStorage.removeItem('sprintNameSelected');
          localStorage.setItem('projectSelected', projectId);
          this.broadcastService.boradcast("SWITCH_PROJECT", projectId);
          this.broadcastService.boradcast("SET_USER_DATA");

          // if (that.router.url.includes('/release')) {
          //   that.router.navigate(['/releases'])
          // } else if (that.router.url.includes('/sprint')) {
          //   that.router.navigate(['/sprints'])
          // } else if (that.router.url.includes('/userstory')) {
          //   that.router.navigate(['/userstories'])
          // } else if (that.router.url.includes('/task')) {
          //   that.router.navigate(['/tasks'])
          // } else if (that.router.url.includes('/defect')) {
          //   that.router.navigate(['/defects'])
          // } else if (that.router.url.includes('/testplan')) {
          //   that.router.navigate(['/testplans'])
          // } else if (that.router.url.includes('/testcase')) {
          //   that.router.navigate(['/testcases'])
          // }

          setTimeout(() => {
            this.broadcastService.boradcast("RELOAD_ENTITY", this.entityData?.entityId);
            if (this.entityData?.entityName == "release") {
              this.router.navigate(['/release/'+this.entityData?.entityId]);
            } else if (this.entityData?.entityName == "sprint") {
              this.router.navigate(['/sprint/'+this.entityData?.entityId]);
            } else if (this.entityData?.entityName == "userstory") {
              this.router.navigate(['/userstory/'+this.entityData?.entityId]);
            } else if (this.entityData?.entityName == "task") {
              this.router.navigate(['/task/'+this.entityData?.entityId]);
            } else if (this.entityData?.entityName == "defect") {
              this.router.navigate(['/defect/'+this.entityData?.entityId]);
            } else if (this.entityData?.entityName == "testplan") {
              this.router.navigate(['/testplan/'+this.entityData?.entityId]);
            } else if (this.entityData?.entityName == "testcase") {
              this.router.navigate(['/testcase/'+this.entityData?.entityId]);
            }

          });

				} else {
					console.log("responseData.message", responseData.message);
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
    }
    
  }

}
