import { setValue } from '@syncfusion/ej2-base';
import { filter, map } from 'rxjs/operators';
import { DateAdapter } from '@angular/material/core';
import { Component, OnInit, Type, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';

// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { DateConverterService } from '../services/date-formatter/date-converter.service';
import { IMyDpOptions } from 'mydatepicker'
import { RouteEventsService } from '../services/route-events.service';
import { BroadcastService } from '../services/broadcast/broadcast.service';


// import { MatDialog } from '@angular/material';
// import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';


declare const $: any;
@Component({
	selector: 'app-defect',
	templateUrl: './defect.component.html',
	styleUrls: ['./defect.component.scss'],
})

export class DefectComponent implements OnInit {
	userData: any = JSON.parse(localStorage.getItem("userData"));
	releaseCreateForm: FormGroup;
	// projectData: any;
	defectData: any;
	pid: any = localStorage.getItem('projectSelected');
	did: any;
	defectId: any;

	selectedDefect: any;
	projectListData: any;
	projectData: any;
	projectSprintList: any;
	userInfo: any;
	files: any = [];
	imgArr: any = [];
	descriptionFiles: any = [];
	descriptionImgArr: any = [];
	sprintArr: any = [];
	userList: any;
	watchesUserList: any;
	assignee = new FormControl();
	sprintControl = new FormControl();
	defectStatus = new FormControl();
	priority = new FormControl();
	type = new FormControl();
	addCommentForm: FormGroup;
	editCommentForm: FormGroup;
	allWatchChecked: boolean = false;
	fb: FormBuilder;
	panelOpenState = true;
	shortCode: any;
	commentValue = '';
	selectedTab = 1;
	backButtonText;
	isClientNotEditable: boolean = false;


	releaseSprintDisabled: boolean = true;
	jqueryRef: any = $;
	selectedAttachment: any;

	defectStatusList = [{ name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }, { name: 'Completed', value: 4 }, { name: 'Reopen', value: 5 }, { name: 'Not A Bug', value: 6 }]
	priorityList = [{ name: 'Critical', value: 1 }, { name: 'High', value: 2 }, { name: 'Medium', value: 3 }, { name: 'Low', value: 4 }]
	typeList: { name: string; value: string; }[];
	deletedDescriptionItems: any[] = [];
	deletedAttachmentItems: any[] = [];


	public myDueDatePickerOptions: IMyDpOptions = {
		// other options...
		dateFormat: 'dd-mm-yyyy',
		disableUntil: { year: 0, month: 0, day: 0 },
		disableSince: { year: 0, month: 0, day: 0 }

	};
	isAddCommentInputShown: boolean = true;
	isOwner: boolean;
	isCompanyAdmin: boolean;
	isProjectManager: boolean;
	isOther: boolean;
	isReadOnlyDescriptionshown: boolean = true;
	listState: any;
	projectType: any;

	constructor(
		private restService: RestService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private _location: Location,
		private routeEventsService: RouteEventsService, private broadcastService: BroadcastService,
		private sanitizer: DomSanitizer, private dateService: DateConverterService,
		private _changeDetectorRef: ChangeDetectorRef) {

		console.log(this.router.getCurrentNavigation().extras.state);
		// if (this.routeEventsService.previousRoutePath.value.includes('/dashboard')) {
		// 	this.backButtonText = 'Back To Dashboard';
		// } else {
		// 	this.backButtonText = 'Back To Bugs List'
		// }

		// this.listState = this.route.paramMap.pipe(map(() => history.state.data));
		// console.log("History state", this.listState);


		if (this.userData.role == 3) {
			this.typeList = [{ name: 'External', value: "1" }];
		} else {
			this.typeList = [{ name: 'Internal', value: "2" }, { name: 'External', value: "1" }];
		}

		this.fb = formBuilder;


		this.isOwner = localStorage.getItem("isOwner") == 'true' ? true : false;
		this.isCompanyAdmin = localStorage.getItem("isCompanyAdmin") == 'true' ? true : false;
		this.isProjectManager = localStorage.getItem("isProjectManager") == 'true' ? true : false;
		this.isOther = localStorage.getItem("isOther") == 'true' ? true : false;
		this.projectType = localStorage.getItem("projectType");

		// Check for route params
		// if (this.pid && this.pid !== null && this.pid !== undefined) {
		// 	this.getProjectDetail();
		// }

		this.route.params.subscribe(params => {
			//this.pid = params['pid'];

			this.did = params['did'];
			if (this.pid && this.did && this.did !== null && this.did !== undefined) {
				this.getDefect();
			}
		});

		if (this.userData) {
			this.getUserList(null);
			this.getUserprofile();

		}

		this.shortCode = localStorage.getItem('projectShortCode');

		this.broadcastService.subscribe("RELOAD_ENTITY", (did) => {
			this.did = did
			this.pid = localStorage.getItem('projectSelected');
			if (this.pid && this.did && this.did !== null && this.did !== undefined) {
				this.getDefect();
			}
		});

	}

	ngOnInit() {

		this._location.onUrlChange((url: string, state: unknown) => {
			console.log("Location changes to " + url);
			console.log(state);
		})
		let that = this;
		$(document).ready(function () {
			$('.htmlEditor').summernote({
				height: '150px',
				focus: true,
				disableDragAndDrop: true,
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
				callbacks: {
					onPaste: function (e) {
						var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
						e.preventDefault();
						document.execCommand('insertText', false, bufferText);
					},
					onImageUpload: function (data) {
						data.pop();
					},
					onfocus: function (e) {
						alert('focus');
					}
				}
			});

			$(".input-group.date").datepicker({
				autoclose: true,
				todayHighlight: true
			})

			$('.date input').datepicker({
				format: 'dd-mm-yyyy',
				autoclose: true,
				todayHighlight: true,
			})
				.on('changeDate', function (e) {
					console.log("event", e);
					$('.datepicker').hide();
					that.updateDefect('dueDate', e.format('dd-mm-yyyy'));
				})

			// $(".dueDate").datepicker().keyup(function (e) {
			// 	// allow delete key (46) to clear the field
			// 	if (e.keyCode == 46)
			// 		$(this).val("");

			// 	// backspace key (8) causes 'page back' in IE8 when text field
			// 	// does not have focus, Bad IE!!
			// 	if (e.keyCode == 8)
			// 		e.stopPropagation();

			// 	that.updateDefect('dueDate');
			// });
		});

		$(document).mouseup(function (e) {
			if (!$(".work-status-anchor").is(e.target) && $(".work-status-anchor").has(e.target).length === 0 && !$(".progress-work-st").is(e.target) && $(".progress-work-st").has(e.target).length === 0) {
				$(".progress-work-st").slideUp('fast');
			}
			if (!$(".assign-user-work").is(e.target) && $(".assign-user-work").has(e.target).length === 0 && !$(".assign-user-work-open").is(e.target) && $(".assign-user-work-open").has(e.target).length === 0) {
				$(".assign-user-work-open").slideUp('fast');
			}
			if (!$(".priority-work").is(e.target) && $(".priority-work").has(e.target).length === 0 && !$(".priority-work-open").is(e.target) && $(".priority-work-open").has(e.target).length === 0) {
				$(".priority-work-open").slideUp('fast');
			}
			if (!$(".type-work").is(e.target) && $(".type-work").has(e.target).length === 0 && !$(".type-work-open").is(e.target) && $(".type-work-open").has(e.target).length === 0) {
				$(".type-work-open").slideUp('fast');
			}
		});


		this.createCommentForm(null);
		this.createForm(null);
		this.getProjectList();
	}



	getUserprofile() {
		this.restService.hitApi('user/profile', { 'userId': this.userData.id }, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.userInfo = responseData.result;
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	backClicked() {
		//this._location.
		this._location.back();

	}

	// getProjectDetail() {
	// 	this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', true)
	// 		.subscribe((result) => {
	// 			let responseData: any = result;
	// 			if (responseData.statusCode == 200) {
	// 				this.projectData = responseData.result;


	// 				if (this.userData) {
	// 					var isOwner = localStorage.getItem('isOwner');
	// 					if (isOwner == "true") {
	// 						this.isOwner = true;
	// 						this.isCompanyAdmin = false;
	// 						this.isProjectManager = false;
	// 						this.isOther = false;
	// 					} else {
	// 						if (this.userData.role == 1) {
	// 							this.isOwner = false;
	// 							this.isCompanyAdmin = true;
	// 							this.isProjectManager = false;
	// 							this.isOther = false;
	// 						} else if (this.userData.role == 2) {
	// 							if (this.projectData && this.projectData.projectUsersProject) {
	// 								this.projectData.projectUsersProject.forEach(element => {
	// 									if (element.userId == this.userData.id) {
	// 										if (element.userRoleId == 1) {
	// 											this.isProjectManager = true;
	// 											this.isOther = false;
	// 										} else {
	// 											this.isProjectManager = false;
	// 											this.isOther = true;
	// 										}
	// 									}
	// 								});
	// 								this.isOther = false;

	// 							} else {
	// 								this.isOther = true;
	// 							}
	// 							this.isOwner = false;
	// 							this.isCompanyAdmin = false;
	// 						} else {
	// 							this.isOther = true;
	// 							this.isOwner = false;
	// 							this.isProjectManager = false;
	// 							this.isCompanyAdmin = false;
	// 						}
	// 					}


	// 					if (this.projectData?.type == 2) {
	// 						if (!this.isOther) {
	// 							this.defectStatusList = [{ name: 'Backlog', value: 0 }, { name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }, { name: 'Completed', value: 4 }, { name: 'Reopen', value: 5 }, { name: 'Not A Bug', value: 6 }];
	// 						} else {
	// 							this.defectStatusList = [{ name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }, { name: 'Completed', value: 4 }, { name: 'Reopen', value: 5 }, { name: 'Not A Bug', value: 6 }];
	// 						}
	// 					} else {
	// 						this.defectStatusList = [{ name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }, { name: 'Completed', value: 4 }, { name: 'Reopen', value: 5 }, { name: 'Not A Bug', value: 6 }];
	// 					}



	// 					// if (this.userData.isCompanyAdmin == 1) {


	// 					// 	if (isOwner == "true") {
	// 					// 		this.isOwner = true;
	// 					// 		this.isCompanyAdmin = false;
	// 					// 		this.isProjectManager = false;
	// 					// 		this.isOther = false;


	// 					// 	} else {
	// 					// 		this.isOwner = false;
	// 					// 		this.isCompanyAdmin = true;
	// 					// 		this.isProjectManager = false;
	// 					// 		this.isOther = false;
	// 					// 	}
	// 					// } else {
	// 					// 	if (this.projectData && this.projectData.projectUsersProject) {
	// 					// 		this.projectData.projectUsersProject.forEach(element => {
	// 					// 			if (element.userId == this.userData.id) {
	// 					// 				if (element.userRoleId == 1) {
	// 					// 					this.isProjectManager = true;
	// 					// 					this.isOther = false;
	// 					// 				} else {
	// 					// 					this.isProjectManager = false;
	// 					// 					this.isOther = true;
	// 					// 				}
	// 					// 			}
	// 					// 		});
	// 					// 		this.isOther = false;

	// 					// 	} else {
	// 					// 		this.isOther = true;
	// 					// 	}
	// 					// 	this.isOwner = false;
	// 					// 	this.isCompanyAdmin = false;

	// 					// }
	// 				}


	// 			} else {
	// 				// this.toastr.error(responseData.message);
	// 			}
	// 		}, (err) => {
	// 			console.log(err);
	// 			let errorData: any = err;
	// 			// this.toastr.error(errorData.error.message);
	// 		})
	// }

	getProjectList() {
		this.restService.hitApi('discussion/projects', {}, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.projectListData = responseData.result.data;
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	changeProject(pid) {
		localStorage.setItem('projectSelected', pid)
		$('.project-title-list').toggle("slide");
		this.router.navigateByUrl('/project/' + pid + '/releases');
	}

	createForm(data) {
		if (data === null) {
			this.releaseCreateForm = this.formBuilder.group({
				'name': ['', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
				'description': [''],
				'fromDate': ['', [Validators.required]],
				'endDate': [''],
				'status': ['', [Validators.required]],
				'attachments': []
			});

			let date = new Date();
			this.releaseCreateForm.patchValue({
				fromDate: {
					date: {
						year: date.getFullYear(),
						month: date.getMonth() + 1,
						day: date.getDate()
					}
				}
			});
			this.releaseCreateForm.patchValue({
				endDate: {
					date: {
						year: date.getFullYear(),
						month: date.getMonth() + 1,
						day: date.getDate()
					}
				}
			});

		} else {
			this.releaseCreateForm = this.formBuilder.group({
				'name': [data.name, [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
				'description': [''],
				'fromDate': [data.fromDate, [Validators.required]],
				'endDate': [data.toDate],
				'releaseId': [data.id],
				'status': [data.status, [Validators.required]],
				'attachments': [],
				'oldattachments': [data.attachments]
			});
			let fromdate = new Date(data.fromDate);
			this.releaseCreateForm.patchValue({
				fromDate: {
					date: {
						year: fromdate.getFullYear(),
						month: fromdate.getMonth() + 1,
						day: fromdate.getDate()
					}
				}
			});
			let todate = new Date(data.toDate);
			this.releaseCreateForm.patchValue({
				endDate: {
					date: {
						year: todate.getFullYear(),
						month: todate.getMonth() + 1,
						day: todate.getDate()
					}
				}
			});
		}

	}

	createCommentForm(comment) {
		if (comment) {
			this.editCommentForm = this.fb.group({
				'comment': [comment],
			});
		} else {
			this.addCommentForm = this.fb.group({
				'comment': [''],
			});
			this.editCommentForm = this.fb.group({
				'comment': [''],
			});
		}
		console.log('this.editCommentForm');
		console.log(this.editCommentForm);


	}

	// updateDefect(){
	// 	this.releaseCreateForm.value.projectId = this.pid;
	// 	this.releaseCreateForm.value.attachments = this.imgArr;
	// 	this.restService.hitApi('defect/update',this.releaseCreateForm.value,'post','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.getDefect();
	// 			$('#create_release_popup_web').modal('hide');
	// 			this.toastr.success(responseData.message);
	// 			this.files = [];
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})	
	// }

	updateDefect(field, value = "") {
		console.log("value: ", value);

		let data: any;
		if (field == 'name') {
			value = $('#defect_' + field).val();
			if (value != this.defectData.name) {
				data = { 'name': value, 'defectId': this.defectId, 'projectId': this.pid };
			}
		} else if (field == 'description') {
			value = $('#defect_' + field).val();
			data = { 'description': value, 'defectId': this.defectId, 'projectId': this.pid, 'files': this.descriptionImgArr };
		} else if (field == 'status') {
			if (value != this.defectData.status) {
				data = { 'status': value, 'defectId': this.defectId, 'projectId': this.pid };

			}
		} else if (field == 'sprintId') {
			if (value != this.defectData.sprintId) {
				data = { 'sprintId': value, 'defectId': this.defectId, 'projectId': this.pid };
			}
		} else if (field == 'priority') {
			if (value != this.defectData.priority) {
				data = { 'priority': value, 'defectId': this.defectId, 'projectId': this.pid };
			}
		} else if (field == 'type') {
			if (parseInt(value) != this.defectData.type) {
				this.checkForAssignValue(value);
				data = { 'type': value, 'defectId': this.defectId, 'projectId': this.pid };
			}

		} else if (field == 'assignee') {
			if (value == '0') {
				if (this.defectData.assignee != null) {
					data = { 'assignee': value, 'defectId': this.defectId, 'projectId': this.pid };
				} else {
					data = null;
				}
			} else {
				if (value != this.defectData.assignee) {
					data = { 'assignee': value, 'defectId': this.defectId, 'projectId': this.pid };

				}
			}

		}
		// else if(field=='reporter'){
		// 	data = {'reporter':value,'defectId' : this.defectId,'projectId':this.pid};
		// }
		else if (field == 'dueDate') {
			if (value != this.defectData.previousDueDate) {
				data = { 'dueDate': value, 'defectId': this.defectId, 'projectId': this.pid };
			}
		} else if (field == 'affectVersion') {
			value = $('#defect_' + field).val();
			if (value != this.defectData.affectVersion) {
				data = { 'affectVersion': value, 'defectId': this.defectId, 'projectId': this.pid };
			}
		} else if (field == 'fixVersion') {
			value = $('#defect_' + field).val();
			if (value != this.defectData.fixVersion) {
				data = { 'fixVersion': value, 'defectId': this.defectId, 'projectId': this.pid };
			}
		} else if (field == 'estHours') {
			value = $('#defect_' + field).val();
			if (value != this.defectData.estHours) {
				data = { 'estHours': value, 'defectId': this.defectId, 'projectId': this.pid };
			}
		} else if (field == 'estMins') {
			value = $('#defect_' + field).val();
			if (value != this.defectData.estMins) {
				data = { 'estMins': value, 'defectId': this.defectId, 'projectId': this.pid };
			}
		} else if (field == 'loggedHours') {
			let value1 = $('#defect_loggedHours').val();
			let value2 = $('#defect_loggedMins').val();

			data = { 'loggedHours': value1, 'loggedMins': value2, 'defectId': this.defectId, 'projectId': this.pid };

		}


		// else if(field=='endDate'){
		// 	data = {'endDate':value,'defectId' : this.defectId,'projectId':this.pid};
		// }else if(field=='dueDate'){
		// 	data = {'dueDate':value,'defectId' : this.defectId,'projectId':this.pid};
		// }
		if (data != null) {
			this.restService.hitApi('defect/update', data, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.toastr.success(responseData.message);
						if (field == 'name') {
							$('.heading-editable h1').show("fast");
							$('.heading-area').hide("fast");
							$('.tick-cancel-input-filed').hide("fast");
						} else if (field == 'description') {
							this.descriptionFiles = [];
							this.descriptionImgArr = [];
							$('.description').slideToggle("fast");
							this.isReadOnlyDescriptionshown = true;

						} else if (field == 'status') {
							$('.progress-work-st-js').slideUp('fast');
						} else if (field == 'priority') {
							$('.priority-work-open').slideToggle("fast");
						} else if (field == 'type') {
							$('.type-work-open').slideToggle("fast");

						} else if (field == 'assignee') {
							$('.assign-user-work-open').slideToggle("fast");
						} else if (field == 'loggedHours') {
							$('#defect_loggedHours').val('');
							$('#defect_loggedMins').val('');
							$('#hours-logged').modal("hide");
						}
						// else if(field=='reporter'){
						// 	$('.reporter-work-open').slideToggle("fast");
						// }



						this.getDefect();
						// $('#create_release_popup_web').modal('hide');
						// this.files = [];
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		} else {
			if (field == 'name') {
				$('.heading-editable h1').show("fast");
				$('.heading-area').hide("fast");
				$('.tick-cancel-input-filed').hide("fast");
			}
		}

	}
	checkForAssignValue(type) {
		if (type == 2) {

			if (this.assignee.value && this.assignee.value.projectRole && this.assignee.value.projectRole == 2) {
				this.defectData.assignee = this.userList[0];
				this.updateDefect('assignee', "0")

				if (this.watchesUserList) {
					this.watchesUserList.forEach(element => {
						if (element.role == 3 && element.checked) {
							element.checked = false;
						}
					});
					this.addRemoveWatch();
				}


			}
			//this.getUserList([2]);
		} else {
			if (this.assignee.value && this.assignee.value.projectRole && this.assignee.value.projectRole != 2) {
				this.defectData.assignee = this.userList[0];
				this.updateDefect('assignee', "0")

				if (this.watchesUserList) {
					this.watchesUserList.forEach(element => {
						if (element.role != 3 && element.checked) {
							element.checked = false;
						}
					});
					this.addRemoveWatch();
				}


			}
			//this.getUserList([1, 3, 4, 5]);
		}


	}


	getUserList(value) {
		this.restService.hitApi('project/projectUsers', { 'projectId': this.pid, escape: value }, 'post', '', false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {

					var list = responseData.result;
					this.userList = [{ userId: 0, name: 'Unassigned' }];
					list.forEach(element => {
						this.userList.push(element);
					});

					if (this.defectData && this.defectData.assignee && this.defectData.assigneeName) {
						this.userList.forEach(element => {
							if (element.userId == this.defectData.assignee) {
								this.assignee.setValue(element);

							}
						});
					} else {
						this.assignee.setValue(this.userList[0]);
					}

				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	getDefect() {
		var isProjectIsSame = this.restService.getIsSelectedProjectIsSame();
		if (isProjectIsSame) {
			this.restService.hitApi('defect/details', { 'defectId': this.did }, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.defectData = responseData.result;
						this.defectId = this.defectData.id;

						if (this.defectData.dueDate) {
							var d = Date.parse(this.defectData.dueDate);
							var dueDate = new Date(d);
							var date: string = this.dateService.convertInDDMMYYYFormate(dueDate);
							this.defectData.previousDueDate = date;
							this.defectData.dueDate = { date: { year: dueDate.getFullYear(), month: dueDate.getMonth() + 1, day: dueDate.getDate() }, formatted: date, jsdate: dueDate };
						}
						if (this.defectData.description) {
							$(document).ready(function () {
								console.log(document.getElementById("edit-description"));
							})
							//document.getElementById("edit-description").innerHTML = this.defectData.description;
						}

						if (this.defectData && this.defectData.sprintId) {
							this.projectSprintList?.forEach(element => {
								if (element.id == this.projectSprintList.sprintId) {
									this.sprintControl.setValue(element);

								}
							});
						}
						if (this.defectData.estMins <= 0) {
							$('#defect_estMins').val(null);

						}

						if (this.projectData?.type == 2) {
							if (!this.isOther) {
								this.defectStatusList = [{ name: 'Backlog', value: 0 }, { name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }, { name: 'Completed', value: 4 }, { name: 'Reopen', value: 5 }, { name: 'Not A Bug', value: 6 }];
							} else {
								this.defectStatusList = [{ name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }, { name: 'Completed', value: 4 }, { name: 'Reopen', value: 5 }, { name: 'Not A Bug', value: 6 }];
							}
						} else {
							this.defectStatusList = [{ name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }, { name: 'Completed', value: 4 }, { name: 'Reopen', value: 5 }, { name: 'Not A Bug', value: 6 }];
						}
						if (this.defectData.status) {
							for (var i = 0; i < this.defectStatusList.length; i++) {
								if (this.defectStatusList[i].value == this.defectData.status) {
									this.defectStatus.setValue(this.defectStatusList[i]);
									break;
								}
							}

						} else {
							this.defectStatus.setValue(this.defectStatusList[0]);
						}
						if (this.defectData.priority) {
							this.priorityList.forEach(element => {
								if (element.value == this.defectData.priority) {
									this.priority.setValue(element);

								}
							})
						}
						if (this.userList && this.userList.length && this.defectData && this.defectData.assignee && this.defectData.assigneeName) {
							this.userList.forEach(element => {
								if (element.userId == this.defectData.assignee) {
									this.assignee.setValue(element);

								}
							});
						} else {
							//Nothing do

							//this.assignee.setValue(this.userList[0]);
						}
						if (this.defectData.type) {
							var strDefectTypeValue = this.defectData.type.toString();
							this.typeList.forEach(element => {
								if (element.value == strDefectTypeValue) {
									this.type.setValue(element);

								}
							})
							if (this.defectData.type == 2) {
								//this.watchesUserList = this.watchesUserList.filter(el => el.role != 3);

								this.getUserList([2]);
							} else {
								this.getUserList(null);
							}
						}
						console.log(this.type);
						console.log(this.defectStatus);

						if (this.userData) {
							if (this.userData.role == 3 && this.userData.id != this.defectData.reporter) {
								this.isClientNotEditable = true;
							} else {
								this.isClientNotEditable = false;
							}
						}

						this.getWatchesUserList();
						this.getProjectSprints();
						// this.createForm(this.releaseData);
					} else {
						// this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}


	}

	deleteDefect(did) {
		console.log(did);
		this.restService.hitApi('defect/delete', { 'defectId': did }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('#delete-record').modal('hide')
					this.toastr.success(responseData.message);
					this.router.navigateByUrl('/defects');
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	getProjectSprints() {
		this.restService.hitApi('defect/projectSprints	', { 'projectId': this.pid, notCompleted: 1 }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.projectSprintList = responseData.result;

					if (this.defectData && this.defectData.sprintId) {
						this.projectSprintList.forEach(element => {
							if (element.id == this.defectData.sprintId) {
								this.sprintControl.setValue(element);

							}
						});
					}

				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	uploadFile(event) {
		var i;
		for (let index = 0; index < event.length; index++) {
			const this_ = this;
			i = this_.files.length + 1;
			let file: File = event[index];
			const formData: FormData = new FormData();
			formData.append('projectId', this.pid);
			formData.append('entity', 'defect');
			formData.append('entityId', this.defectId);
			formData.append('file', file);

			var reader = new FileReader();

			var obj;

			reader.onload = function (e: any) {
				obj = { serverFileName: file.name, name: file.name, image: e.target.result, isProgressBarShown: true }
				this_.files.push(obj);

			}

			reader.readAsDataURL(file);


			this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', true, false)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.imgArr.push(responseData.result);
						this.files[this.files.length - 1].serverFileName = responseData.result.fileName;
						this.files[this.files.length - 1].displayName = responseData.result.displayName;
						console.log("index of obj", this.files[this.files.length - 1]);
						this.files.forEach(element => {
							element.isProgressBarShown = false;
						});

					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}

	}

	uploadCommentAttachment(event, commentObj) {
		var i;
		for (let index = 0; index < event.length; index++) {
			i = index;
			let file: File = event[index];
			const formData: FormData = new FormData();
			formData.append('projectId', this.pid);
			formData.append('entity', 'defect');
			formData.append('entityId', this.did);
			formData.append('file', file);

			// const element = event[index];


			var reader = new FileReader();
			const this_ = this;
			var obj;
			reader.onload = function (e: any) {
				obj = { name: file.name, serverFileName: file.name, image: e.target.result, isProgressBarShown: true }
				commentObj.files.push(obj);
			}

			reader.readAsDataURL(file);

			this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', false, false)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {

						commentObj.imgArr.push(responseData.result);
						commentObj.files[commentObj.files.length - 1].serverFileName = responseData.result.fileName;
						commentObj.files[commentObj.files.length - 1].displayName = responseData.result.displayName;
						commentObj.files.forEach(element => {
							element.isProgressBarShown = false;
						});

					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}
	}

	uploadDescriptionFile(event) {
		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];
			const formData: FormData = new FormData();
			formData.append('projectId', this.pid);
			formData.append('entity', 'defect');
			formData.append('entityId', this.did);
			formData.append('file', file);
			var reader = new FileReader();
			const this_ = this;
			var obj;
			reader.onload = function (e: any) {
				obj = { serverFileName: file.name, name: file.name, image: e.target.result, isProgressBarShown: true }
				this_.descriptionFiles.push(obj);
			}

			reader.readAsDataURL(file);


			this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', true, false)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.descriptionImgArr.push(responseData.result);
						this.descriptionFiles[this.descriptionFiles.length - 1].serverFileName = responseData.result.fileName;
						this.descriptionFiles[this.descriptionFiles.length - 1].displayName = responseData.result.displayName;
						this_.descriptionFiles.forEach(element => {
							element.isProgressBarShown = false;
						});
						//this.getSprint();
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}

	}


	saveFile() {
		var fileArray = [];
		if (this.files) {
			this.files.forEach(element => {
				if (element.displayName) {
					fileArray.push({ 'fileTitle': element.serverFileName, 'displayName': element.displayName });
				}
				else {
					fileArray.push(element.serverFileName);
				}
			});
		}
		if (fileArray.length > 0) {
			var json = {
				projectId: this.pid,
				entity: "defect",
				entityId: this.defectId,
				files: fileArray
			}

			this.restService.hitApi('project/justSaveFiles', json, 'post', '', true, false)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.getDefect();
					} else {
						if (responseData.message) {
							this.toastr.error(responseData.message);
						}
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					if (errorData.error.message) {
						this.toastr.error(errorData.error.message);
					}
				})
		}

	}



	checkUncheckAll(ele, classname) {
		// console.log(ele.target.checked)
		var checkboxes = $("." + classname + " input");
		if (ele) {
			for (var i = 0; i < checkboxes.length; i++) {
				// if (checkboxes[i].type == 'checkbox') {
				checkboxes[i].checked = true;
				// }
			}
		} else {
			for (var i = 0; i < checkboxes.length; i++) {
				console.log(i)
				// if (checkboxes[i].type == 'checkbox') {
				checkboxes[i].checked = false;
				// }
			}
		}
	}

	setCheckedVar() {
		this.releaseSprintDisabled = true;
		let that = this;
		$('.release-sprint-checkbox').find('input').each(function () {
			if ($(this).prop("checked")) {
				that.releaseSprintDisabled = false;
			}
		});

	}

	removeReleaseSprints() {
		this.sprintArr = [];
		this.sprintArr.push(this.defectData?.projectsSprint.id);
		// let that = this;
		// $('.release-sprint-checkbox').find('input').each(function () {
		// 	console.log($(this).val());
		// 	if ($(this).prop("checked")) {
		// 		that.sprintArr.push($(this).val());
		// 	}
		// });

		if (this.sprintArr.length) {
			this.restService.hitApi('defect/associateBug', { 'projectId': this.pid, 'defectId': this.defectId, 'entity': 'sprint', 'entity_id': this.sprintArr, 'set': 0 }, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.getDefect();
						this.toastr.success(responseData.message);
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}
	}

	addReleaseSprint() {
		this.sprintArr = [];
		this.projectSprintList.forEach(element => {
			if (element.checked) {
				this.sprintArr.push(element.id);
			}
		});
		// let that = this;
		// $('.sprint-checkbox').find('input').each(function () {
		// 	console.log($(this).val());
		// 	if ($(this).prop("checked")) {
		// 		that.sprintArr.push($(this).val());
		// 	}
		// });

		console.log(this.sprintArr);

		this.restService.hitApi('defect/associateBug', { 'projectId': this.pid, 'defectId': this.defectId, 'entity': 'sprint', 'entity_id': this.sprintArr, 'set': 1 }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('#create_add-link_popup_web').modal('hide');
					this.getDefect();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	selectonlyOne(selected) {
		console.log(selected)
		$('.table-multiple-checkboxes input:checkbox').removeAttr('checked');
		$('#' + selected).prop("checked", true);
	}


	removeAttachment(fileObj, index, commentObj) {
		this.deletedAttachmentItems.push(fileObj.serverFileName)

		if (commentObj != undefined && commentObj != null) {
			commentObj.files.splice(index, 1);
			commentObj.imgArr.splice(0, 1);

		} else {
			this.files.splice(index, 1);
			this.imgArr.splice(0, 1);
		}

	}

	removeAll() {
		this.deletedAttachmentItems = [];
		this.files.forEach(element => {
			this.deletedAttachmentItems.push(element.serverFileName);
		});

		this.removeAttachmentFromServer(null, null, true);
	}

	removeDescriptionAttachment(fileObj, index) {
		this.deletedDescriptionItems.push(fileObj.serverFileName);
		this.descriptionFiles.splice(index, 1);
		this.descriptionImgArr.splice(index, 1);
	}

	updateDescriptionAttachment() {
		if (this.deletedDescriptionItems && this.deletedDescriptionItems.length > 0) {
			this.removeDescriptionAttachmentFromServer();
		} else {
			this.updateDefect('description');
		}
	}

	updateAttachment(obj, field) {
		if (this.deletedAttachmentItems && this.deletedAttachmentItems.length > 0) {
			this.removeAttachmentFromServer(obj, field, true);
		} else {
			this.addComment(obj, field, true);
		}
	}

	removeDescriptionAttachmentFromServer() {

		var json = {
			projectId: this.pid,
			entity: 'defect',
			files: this.deletedDescriptionItems
		}

		this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.deletedDescriptionItems = [];
					this.updateDefect('description');
				} else {
					this.toastr.success(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	removeAttachmentFromServer(obj, field, isShowing) {
		var json = {
			projectId: this.pid,
			entity: 'defect',
			files: this.deletedAttachmentItems
		}

		this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.deletedDescriptionItems = [];
					if (obj != null && field != null) {
						this.addComment(obj, field, isShowing);
					}
				} else {
					this.toastr.success(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	deleteCommentAttachment(files) {
		var json = {
			projectId: this.pid,
			entity: 'defect',
			files: files
		}

		this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					//this.getTestplan();
				} else {

				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}


	deleteAttachment(aid, index) {
		this.restService.hitApi('defect/deleteFile', { "attachmentId": aid, "projectId": this.pid }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getDefect();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})

	}


	toggleList(ele) {

		this.isReadOnlyDescriptionshown = !this.isReadOnlyDescriptionshown

		this.descriptionFiles = [];
		if (ele == 'description') {

			$('#defect_description').summernote('code', this.defectData.description);
			// $('#defect_description').summernote('editor.focus');
		}
		if (this.defectData.descriptionAttachments && this.defectData.descriptionAttachments.length > 0) {
			this.defectData.descriptionAttachments.forEach(element => {
				this.descriptionFiles.push({ id: element.id, name: element.displayName, serverFileName: element.fileName, image: element.fileTitle, isProgressBarShown: false });
				//obj.imgArr.push(element.displayName);
			});
			// 	}
		} else {
			//$('.' + ele + '_' + obj.id).slideUp("fast");
			this.descriptionFiles = [];
			this.descriptionImgArr = [];

		}
		$('.' + ele).slideToggle("fast");
		this.initializeDescriptionSummarNote();

	}


	initializeDescriptionSummarNote() {

		$('#defect_description').summernote({
			focus: true,
		});

		// $('.htmlEditor').summernote({
		// 	dialogsInBody: true
		// 	//,airMode: true
		// });
	}

	toggleAddCommentList(ele, event, show) {
		let that = this;
		that.files = [];
		that.imgArr = [];
		$('#defect_comment').summernote({
			height: '150px',
			focus: true,
			disableDragAndDrop: true,
			toolbar: [
				// [groupName, [list of button]]
				['style', ['bold', 'italic', 'underline']],
				['para', ['ul', 'ol', 'paragraph']]
			],
			callbacks: {
				onPaste: function (e) {
					var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
					e.preventDefault();
					document.execCommand('insertText', false, bufferText);
				},
				onImageUpload: function (data) {
					data.pop();
				}
			}
		});

		this.isAddCommentInputShown = show;
		//$('.htmlEditor').summernote('code', '');
		$('#defect_comment').summernote("reset");
		$('#defect_comment').summernote('code', '');
		if (event == 'down') {
			$('.' + ele).slideDown("fast");
		} else {
			$('.' + ele).slideUp("fast");

		}

		$('#defect_comment').summernote({
			focus: true,
		});


	}

	toggleUpdateCommentList(ele, event, obj) {

		obj.files = [];
		obj.imgArr = [];
		let that = this;
		$('#defect_edit_comment' + obj.id).summernote({
			height: '150px',
			focus: true,
			disableDragAndDrop: true,
			toolbar: [
				// [groupName, [list of button]]
				['style', ['bold', 'italic', 'underline']],
				['para', ['ul', 'ol', 'paragraph']]
			],
			callbacks: {
				onPaste: function (e) {
					var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
					e.preventDefault();
					document.execCommand('insertText', false, bufferText);
				},
				onImageUpload: function (data) {
					data.pop();
				}
			}
		});

		if (event == 'down') {
			$('#defect_edit_comment' + obj.id).summernote("reset");
			$('#defect_edit_comment' + obj.id).summernote('code', obj.comment);
			$('.' + ele + '_' + obj.id).slideDown("fast");
			if (obj.attachments && obj.attachments.length > 0) {
				obj.attachments.forEach(element => {
					obj.files.push({ id: element.id, name: element.displayName, serverFileName: element.fileName, image: element.fileTitle, isProgressBarShown: false });
					//obj.imgArr.push(element.displayName);
				});
				// 	}
			} else {
				//$('.' + ele + '_' + obj.id).slideUp("fast");
				obj.files = [];
				obj.imgArr = [];

			}
		} else {
			$('.' + ele + '_' + obj.id).slideUp("fast");
			$('#defect_edit_comment' + obj.id).summernote("reset");
			$('#defect_edit_comment' + obj.id).code('');

		}
		$('#defect_edit_comment' + obj.id).summernote({
			focus: true,
		});
	}


	showedit() {
		if (!this.isClientNotEditable) {
			$('.heading-editable h1').hide("fast");
			$('.heading-area').show("fast");
			$('.tick-cancel-input-filed').show("fast");
		}
	}
	hideedit() {
		$('#defect_name').val(this.defectData?.name);
		$('.heading-editable h1').show("fast");
		$('.heading-area').hide("fast");
		$('.tick-cancel-input-filed').hide("fast");
	}

	addComment(obj, field, shown) {

		let data: any;
		let comment: any;
		let apiname: any;
		if (obj?.id) {
			apiname = 'defect/comment/update';
			comment = $('#defect_edit_' + field + obj?.id).val();
			data = { 'bugCommentId': obj?.id, 'comment': comment, 'files': obj.imgArr, 'projectId': this.pid }

		} else {
			apiname = 'defect/comment/create';
			comment = $('#defect_' + field).val();
			data = { 'projectBugId': this.defectId, 'comment': comment, 'files': this.imgArr, 'projectId': this.pid }

		}



		this.restService.hitApi(apiname, data, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					if (obj?.id) {
						$('#comment' + obj?.id).show();
						$('#commentinput' + obj?.id).hide();
					} else {
						$('#defect_' + field).val('');
						this.commentValue = '';
						$('#defect_comment').summernote("reset");
						$('#defect_comment').summernote('code', '');
						//this.toggleAddCommentList(field, 'up', true);
						$('.' + field).slideUp("fast");
						this.isAddCommentInputShown = shown;
					}
					this.files = [];
					this.imgArr = [];
					if (obj?.id) {
						this.editCommentForm.reset();
					} else {
						this.addCommentForm.reset();
					}
					this.getDefect();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	deleteComment(bugCommentId) {
		this.restService.hitApi('defect/comment/delete', { "bugCommentId": bugCommentId }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getDefect();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	getWatchesUserList() {
		this.restService.hitApi('project/watchlist', { 'projectId': this.pid, 'entity': 'defect', 'resourceId': this.defectId }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.watchesUserList = responseData.result;
					if (this.defectData.type) {
						if (this.defectData.type == 2) {
							this.watchesUserList = this.watchesUserList.filter(el => el.role != 3);
						}
					}

					if (this.watchesUserList.length) {
						this.watchesUserList.forEach(element => {
							if (element.watcher == element.userId) {
								element.checked = true;
							} else {
								element.checked = false;
							}
							if (this.userData) {
								if (this.userData.role == 2 || this.userData.role == 3) {
									if (element.role == 1) {
										element.isRemoved = false;
									} else {
										if (element.userId != this.userData.id) {
											element.isRemoved = false;
										} else {
											element.isRemoved = true;
										}
									}
								} else {
									if (!this.isOwner) {
										if (element.isCompanyOwner == 1) {
											if (element.checked) {
												element.isRemoved = false;
											} else {
												element.isRemoved = true;
											}
										} else {
											element.isRemoved = true;

										}
									} else {
										element.isRemoved = true;

									}
								}
							}
						});
					}
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	addRemoveWatch() {
		let selectedCheckboxArr: any = [];
		if (this.watchesUserList.length) {
			this.watchesUserList.forEach(element => {
				if (element.checked) {
					selectedCheckboxArr.push(element.userId);
				}
			});
		}

		this.restService.hitApi('project/updateWatchlist', { 'projectId': this.pid, 'entity': 'defect', 'resourceId': this.defectId, "userId": selectedCheckboxArr, "action": 'manage' }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getWatchesUserList();
					this.getDefect();
					$('#watches_popup_web').modal('hide');
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	AddRemoveCurrentUserWatcher(action) {
		this.restService.hitApi('project/updateWatchlist', { 'projectId': this.pid, 'entity': 'defect', 'resourceId': this.defectId, "action": action }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('.stop-add-watches').slideUp('fast');
					this.getDefect();
					this.getWatchesUserList();
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	someWatchComplete(): boolean {
		if (this.watchesUserList == null) {
			return false;
		}
		return this.watchesUserList.filter(t => t.checked).length > 0 && !this.allWatchChecked;
	}

	updateAllUserWatch() {
		this.allWatchChecked = this.watchesUserList != null && this.watchesUserList.every(t => t.checked);
	}


	setAllWatched(checked: boolean) {
		this.allWatchChecked = checked;
		if (this.watchesUserList == null) {
			return;
		}
		this.watchesUserList.forEach(t => t.checked = checked);
	}

	unCheckRemainingSprint(sprint) {

		if (this.projectSprintList && this.projectSprintList.length) {
			this.projectSprintList.forEach(element => {
				if (element.id != sprint.id) {
					element.checked = false;
				}
			});
		}
	}
	sprintSelected() {
		var isSprintSelected = false;
		if (this.projectSprintList && this.projectSprintList.length > 0) {
			for (var i = 0; i < this.projectSprintList.length; i++) {
				if (this.projectSprintList[i].checked == true) {
					isSprintSelected = true;
					break;
				} else {
					isSprintSelected = false;
				}
			}
		}

		return isSprintSelected;
	}

	uploadSaveFile(event) {

		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];
			const formData: FormData = new FormData();
			formData.append('projectId', this.pid);
			formData.append('entity', 'defect');
			formData.append('entityId', this.defectData.id);
			formData.append('file', file);
			this.files.push(file.name);

			this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', true, false)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {

						this.getDefect();

						//this.imgArr.push(responseData.result);
					} else {
						//this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}



	}
	download(url, fileName, $event) {
		$event.stopPropagation();
		this.restService.downloadFile(url, fileName);
	}

	dueDateChange(event) {
		var strDate;
		if (event.epoc > 0) {
			var date = event.jsdate;
			strDate = this.dateService.convertInDDMMYYYFormate(date)
			this.defectData.dueDate = { date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }, formatted: strDate, jsdate: date };;
			//this.myEndDatePickerOptions.disableUntil = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }

		} else {
			this.defectData.dueDate = null;
			strDate = null;

		}
		this.updateDefect('dueDate', strDate);


	}

	getExtensionOfFileType(fileName) {
		if (fileName) {
			var str = fileName.split(".");
			if (str[str.length - 1] == 'jpg' || str[str.length - 1] == 'png' || str[str.length - 1] == 'jpeg' || str[str.length - 1] == 'JPG' || str[str.length - 1] == 'PNG') {
				return 'image'
			} else {
				return 'file'
			}

		}
	}

	backToRetun() {
		// var hasHistory = this.router.navigated;
		// console.log('hasHistory', hasHistory);
		// if (hasHistory) {
		// 	this._location.back();
		// } else {
		// 	this.router.navigateByUrl('/defects');
		// }
		// // this._location.back();
		console.log("this.routeEventsService.previousRoutePath.value", this.routeEventsService.previousRoutePath.value);
		//this.router.navigate([this.routeEventsService.previousRoutePath.value]);
		if (this._location.path() == this.routeEventsService.previousRoutePath.value) {
			this.router.navigateByUrl('/defects');

		} else {
			this._location.back();

		}
	}

	resetHoursValue() {
		$('#defect_loggedHours').val(null);
		$('#defect_loggedMins').val(null);
	}

	allWatchDisabled() {
		var isDisable = false;
		if (this.watchesUserList && this.watchesUserList.length > 0) {
			for (var i = 0; i < this.watchesUserList.length; i++) {
				if (!this.watchesUserList[i].isRemoved) {
					isDisable = true;
					break;
				}
			}

		}
		return isDisable;
	}

}

