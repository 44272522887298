import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { RestService } from './services/rest/rest.service';
import { Router, NavigationExtras } from '@angular/router';




@Injectable({
    providedIn: 'root'
})
export class FileUploadService {
    constructor(private http: HttpClient, private toastr: ToastrService, private restService: RestService, private router: Router) {

    }
    uploadFile(formData: any, url: any): Observable<any> {
        var apiUrl = this.restService.getApiUrl();
        let accessToken = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).accessToken : '';
        let companyId = localStorage.getItem('company_id') ? localStorage.getItem('company_id') : '';
        let projectId = localStorage.getItem('projectSelected') ? localStorage.getItem('projectSelected') : null;

        let headers;
        if (projectId != null && projectId != 'null' && projectId != '') {
            headers = new HttpHeaders({ 'deviceType': 'WEB2', 'accessToken': accessToken, 'companyId': companyId, 'projectId': projectId, 'Accept': 'application/json' });

        } else {
            headers = new HttpHeaders({ 'deviceType': 'WEB2', 'accessToken': accessToken, 'companyId': companyId, 'Accept': 'application/json' });

        }

        return this.http.post(apiUrl + url, formData, {
            headers: headers,
            reportProgress: true,
            observe: 'events'
        }).pipe(
            catchError(error => {
                let errorMessage = '';
                if (error.error instanceof ErrorEvent) {
                    // Get client-side error
                    errorMessage = error.error.message;
                    //this.tostr.error(errorMessage)
                } else {
                    // Get server-side error
                    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                    if (error.status == 202) {
                        this.router.navigate(['/']);
                    } else if (error.status == 401) {
                        this.toastr.error(error.error.message);
                        this.restService.logoutAPI();
                        this.restService.clearAllAndGoToLogin();
                    } else if (error.status == 500) {
                        this.toastr.error("Server Error");
                    } else if (error.status == 422) {
                        this.router.navigate(['common-error'], { queryParams: { statusCode: 422 } });
                    } else if (error.status == 401 || error.status == 404) {
                        this.router.navigate(['common-error'], { queryParams: { statusCode: 404 } });
                    } else if (error.status == 402) {
                        this.router.navigate(['plan-expire']);
                    } else {
                        this.toastr.error(errorMessage);
                    }
                    //this.tostr.error(errorMessage)
                }
                console.log(errorMessage);
                return throwError(errorMessage);

            })
        )
    }

}