import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { DateConverterService } from '../services/date-formatter/date-converter.service';
import { IMyDpOptions } from 'mydatepicker';
import { RouteEventsService } from '../services/route-events.service';
import { Location } from '@angular/common';
import { BroadcastService } from '../services/broadcast/broadcast.service';
import { FileUploadService } from "../file-upload-service";
import { HttpEvent, HttpEventType } from '@angular/common/http';

// import { MatDialog } from '@angular/material';
// import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
declare const $: any;
@Component({
    selector: 'app-userstory',
    templateUrl: './userstory.component.html',
    styleUrls: ['./userstory.component.scss'],
})

export class UserstoryComponent implements OnInit {
    userData: any = JSON.parse(localStorage.getItem("userData"));
    userstoryCreateForm: FormGroup;
    userStoryData: any;
    userList: any = [];
    pid: any = localStorage.getItem('projectSelected');
    usid: any;
    userStoryId;
    selectedRelease: any;
    projectListData: any;
    projectData: any;
    projectSprintList: any;
    files: any = [];
    imgArr: any = [];
    descriptionFiles: any = [];
    descriptionImgArr: any = [];
    sprintArr: any = [];
    userInfo: any;
    sprintList: any;
    watchesUserList: any;
    isUserDitailReadOnly: boolean = false;

    // linkTabs : any = ['Parent','Child','Sprint','Task','Defect','Test Case','Test Run']
    linkTabs: any = ['Parent', 'Child', 'Sprint']
    getUnassociatedData: any = [];
    getUnassociatedTaskData: any = [];
    getUnassociatedSprintData: any = [];

    unassociatedData: any;
    selectedEntity: any;
    selectedCheckboxArr: any = [];
    jqueryRef: any = $;
    userStoryStatus = new FormControl();
    assignee = new FormControl();
    sprintControl = new FormControl();
    addCommentForm: FormGroup;
    editCommentForm: FormGroup;
    subTaskForm: FormGroup;
    selectedSubTask: any;
    parentPanelOpenState: boolean = false;
    childPanelOpenState: boolean = false;
    sprintPanelOpenState: boolean = false;
    allUserStoryChecked: boolean = false;
    allUnassociatedUserStoryChecked: boolean = false;
    allWatchChecked: boolean = false;
    isSubTaskCreateDialogShow: boolean = false;

    userStoryStatusList = [{ name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }, { name: 'Completed', value: 4 }]
    selectedDueDate: string = '';
    userStoryCopyObj: any;
    isOwner: boolean;
    isCompanyAdmin: boolean;
    isProjectManager: boolean;
    selectedTab: any = 1;
    selectedAttachment: any;
    backButtonText;
    deletedDescriptionItems: any[] = [];
    deletedAttachmentItems: any[] = [];

    public myDueDatePickerOptions: IMyDpOptions = {
        // other options...
        dateFormat: 'dd-mm-yyyy',
        disableUntil: { year: 0, month: 0, day: 0 },
        disableSince: { year: 0, month: 0, day: 0 }

    };
    isAddCommentInputShown: boolean = true;
    isOther: boolean;


    constructor(
        private restService: RestService,
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private toastr: ToastrService, private dateService: DateConverterService,
        private routeEventsService: RouteEventsService,
        private broadcastService: BroadcastService,
        private location: Location,
        private fileUploadService: FileUploadService) {

        // if (this.routeEventsService.previousRoutePath.value.includes('/userstories')) {
        //     this.backButtonText = 'Back To User Stories';
        // } else if (this.routeEventsService.previousRoutePath.value.includes('/dashboard')) {
        //     this.backButtonText = 'Back To Dashboard';
        // } else {
        //     this.backButtonText = 'Back'
        // }

        if (this.pid && this.pid !== null && this.pid !== undefined) {
            this.getProjectDetail();
        }
        // Check for route params
        this.route.params.subscribe(params => {
            //this.pid = params['pid'];

            this.usid = params['usid'];
            if (this.usid && this.usid !== null && this.usid !== undefined) {
                this.getUserstory();

            }
        });


        console.log("this.pid", this.pid);
        if (this.userData) {
            this.getUserprofile();
        }

        this.getUserList();
        this.createCommentForm(null);
        this.createSubTaskForm(null);

        this.broadcastService.subscribe("RELOAD_ENTITY", (id) => {
            this.usid = id
            this.pid = localStorage.getItem('projectSelected');
            if (this.pid && this.usid && this.usid !== null && this.usid !== undefined) {
                this.getUserstory();
            }
        });

    }
    createCommentForm(comment) {
        if (comment) {
            this.editCommentForm = this.formBuilder.group({
                'comment': [comment],
            });
        } else {
            this.addCommentForm = this.formBuilder.group({
                'comment': [''],
            });
            this.editCommentForm = this.formBuilder.group({
                'comment': [''],
            });
        }

    }

    createSubTaskForm(subTask) {
        console.log(subTask);
        if (subTask) {
            this.subTaskForm = this.formBuilder.group({
                'id': [subTask.id],
                'name': [subTask.name, [Validators.required]]
            })
        } else {
            this.subTaskForm = this.formBuilder.group({
                'id': [null],
                'name': ['', [Validators.required]]
            })
        }

        console.log("subTask", this.subTaskForm);


    }

    ngOnInit() {
        let that = this;
        $(document).ready(function () {
            // $('.project-title-head').click(function() {
            //  $('.project-title-list').toggle("slide");
            // });

            $('.htmlEditor').summernote({
                height: '150px',
                focus: true,
                disableDragAndDrop: true,
                toolbar: [
                    // [groupName, [list of button]]
                    ['style', ['bold', 'italic', 'underline']],
                    ['para', ['ul', 'ol', 'paragraph']]
                ],
                callbacks: {
                    onPaste: function (e) {
                        var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
                        e.preventDefault();
                        document.execCommand('insertText', false, bufferText);
                    }
                }
            });

            $('.editor-textarea-comment').summernote({
                height: '150px'
            });

            $(".input-group.date").datepicker({
                autoclose: true,
                todayHighlight: true
            }).on('hide', function (e) {
                $('.date input').datepicker('update', that.selectedDueDate)
            })


            $('.date input').datepicker({
                format: 'dd-mm-yyyy',
                autoclose: true,
                todayHighlight: true
            }).on('changeDate', function (e) {
                $('.datepicker').hide();
                that.updateUserStory('dueDate', e.format('dd-mm-yyyy'));
            }).on('hide', function (e) {
                $('.date input').datepicker('update', that.selectedDueDate)
            })
            //$('.dueDatePicker').datepicker('update', new Date('21-02-2021'));



        });

        $(document).mouseup(function (e) {
            if (!$('.work-status-anchor').is(e.target) && $('.work-status-anchor').has(e.target).length === 0 && !$('.progress-work-st').is(e.target) && $('.progress-work-st').has(e.target).length === 0) {
                $(".progress-work-st").slideUp("fast");
            }
            if (!$('.assign-reporter-work').is(e.target) && $('.assign-reporter-work').has(e.target).length === 0 && !$('.assign-reporter-work-ope').is(e.target) && $('.assign-reporter-work-ope').has(e.target).length === 0) {
                $(".assign-reporter-work-open").slideUp("fast");
            }
            if (!$('.sprint-reporter-work').is(e.target) && $('.sprint-reporter-work').has(e.target).length === 0 && !$('.sprint-reporter-work-ope').is(e.target) && $('.sprint-reporter-work-ope').has(e.target).length === 0) {
                $(".sprint-reporter-work-open").slideUp("fast");
            }


        });

        // this.createForm(null);
        // this.getProjectList();
        this.getUserList();
    }



    getUserprofile() {
        this.restService.hitApi('user/profile', { 'userId': this.userData.id }, 'get', '', true)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    this.userInfo = responseData.result;
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                // this.toastr.error(errorData.error.message);
            })
    }

    getProjectDetail() {
        if (localStorage.getItem("isOwner") == "true") {
            this.isOwner = true
        } else {
            this.isOwner = false
        }

        if (localStorage.getItem("isCompanyAdmin") == "true") {
            this.isCompanyAdmin = true
        } else {
            this.isCompanyAdmin = false
        }

        if (localStorage.getItem("isProjectManager") == "true") {
            this.isProjectManager = true
        } else {
            this.isProjectManager = false
        }

        if (localStorage.getItem("isOther") == "true") {
            this.isOther = true
        } else {
            this.isOther = false
        }
        // this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', true)
        //     .subscribe((result) => {
        //         let responseData: any = result;
        //         if (responseData.statusCode == 200) {
        //             this.projectData = responseData.result;
        //             if (this.projectData?.type == 2) {
        //                 this.userStoryStatusList = [{ name: 'Backlog', value: 0 }, { name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }, { name: 'Completed', value: 4 }];
        //             } else {
        //                 this.userStoryStatusList = [{ name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }, { name: 'Completed', value: 4 }];
        //             }
        //             if (this.userData) {
        //                 var isOwner = localStorage.getItem('isOwner');
        //                 if (isOwner == "true") {
        //                     this.isOwner = true;
        //                     this.isCompanyAdmin = false;
        //                     this.isProjectManager = false;
        //                     this.isOther = false;
        //                 } else {
        //                     if (this.userData.role == 1) {
        //                         this.isOwner = false;
        //                         this.isCompanyAdmin = true;
        //                         this.isProjectManager = false;
        //                         this.isOther = false;
        //                     } else if (this.userData.role == 2) {
        //                         if (this.projectData && this.projectData.projectUsersProject) {
        //                             this.projectData.projectUsersProject.forEach(element => {
        //                                 if (element.userId == this.userData.id) {
        //                                     if (element.userRoleId == 1) {
        //                                         this.isProjectManager = true;
        //                                         this.isOther = false;
        //                                     } else {
        //                                         this.isProjectManager = false;
        //                                         this.isOther = true;
        //                                     }
        //                                 }
        //                             });
        //                             this.isOther = false;

        //                         } else {
        //                             this.isOther = true;
        //                         }
        //                         this.isOwner = false;
        //                         this.isCompanyAdmin = false;
        //                     } else {
        //                         this.isOther = true;
        //                         this.isOwner = false;
        //                         this.isProjectManager = false;
        //                         this.isCompanyAdmin = false;
        //                     }
        //                 }
        //                 // if (this.userData.isCompanyAdmin == 1) {
        //                 //     if (this.userData.ownerId) {
        //                 //         this.isOwner = true;
        //                 //         this.isCompanyAdmin = false;
        //                 //         this.isProjectManager = false;
        //                 //     } else {
        //                 //         this.isOwner = false;
        //                 //         this.isCompanyAdmin = true;
        //                 //         this.isProjectManager = false;
        //                 //     }
        //                 // } else {
        //                 //     if (this.projectData && this.projectData.projectUsersProject) {
        //                 //         this.projectData.projectUsersProject.forEach(element => {
        //                 //             if (element.userId == this.userData.id) {
        //                 //                 if (element.userRoleId == 1) {
        //                 //                     this.isProjectManager = true;

        //                 //                 } else {
        //                 //                     this.isProjectManager = false;
        //                 //                 }
        //                 //             }
        //                 //         });

        //                 //     }
        //                 //     this.isOwner = false;
        //                 //     this.isCompanyAdmin = false;
        //                 // }
        //             }
        //         } else {
        //             // this.toastr.error(responseData.message);
        //         }
        //     }, (err) => {
        //         console.log(err);
        //         let errorData: any = err;
        //         // this.toastr.error(errorData.error.message);
        //     })
    }

    getUserList() {
        this.restService.hitApi('project/projectUsers', { 'projectId': this.pid, escape: [2] }, 'post', '', false)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    var list = responseData.result;
                    this.userList = [{ userId: null, name: 'Unassigned' }];
                    list.forEach(element => {
                        this.userList.push(element);
                    });

                    if (this.userStoryData && this.userStoryData.assignee && this.userStoryData.userId) {
                        this.userList.forEach(element => {
                            if (element.userId == this.userStoryData.userId) {
                                this.assignee.setValue(element);

                            }
                        });
                        console.log('assignee', this.assignee);
                    } else {
                        this.assignee.setValue(this.userList[0]);
                    }
                } else {
                    // this.toastr.error(responseData.message);
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                // this.toastr.error(errorData.error.message);
            })
    }

    // getProjectList() {
    //  this.restService.hitApi('discussion/projects',{},'get','',true)
    //  .subscribe((result)=>{
    //      let responseData : any = result;
    //      if(responseData.statusCode==200){
    //          this.projectListData = responseData.result.data;
    //      }else{
    //          // this.toastr.error(responseData.message);
    //      }
    //  },(err)=>{
    //      console.log(err);
    //      let errorData : any = err;
    //      // this.toastr.error(errorData.error.message);
    //  })
    // }

    // changeProject(pid){
    //  localStorage.setItem('projectSelected',pid)
    //  $('.project-title-list').toggle("slide");
    //  this.router.navigateByUrl('/project/'+pid+'/releases');
    // }

    // createForm(data) {
    //  if (data === null) {
    //      this.userstoryCreateForm = this.formBuilder.group({
    //          'summary': ['', [Validators.required]],
    //          'description': [''],
    //          'assignedTo':['',[Validators.required]],
    //          'estHr':['',[Validators.pattern('[0-9]*')]],
    //          'actualHr':['',[Validators.pattern('[0-9]*')]],
    //          'estMin':['',[Validators.pattern('[0-9]*'),Validators.max(60)]],
    //          'actualMin':['',[Validators.pattern('[0-9]*'),Validators.max(60)]],
    //          'sprintId':['',[Validators.required]],
    //          'storyPoints':[''],
    //          'status':['',[Validators.required]],
    //          'attachments':[]
    //      });

    //  }else{

    //      let actualHoursArr = data.actualHours.split(".");
    //      let estHoursArr = data.estHours.split(".");
    //      this.userstoryCreateForm = this.formBuilder.group({
    //          'userStoryId':[data.id],
    //          'summary': [data.summary, [Validators.required]],
    //          'description': [data.description],
    //          'assignedTo':[data.userId,[Validators.required]],
    //          'estHr':[estHoursArr[0],[Validators.pattern('[0-9]*')]],
    //          'estMin':[estHoursArr[1],[Validators.pattern('[0-9]*'),Validators.max(60)]],
    //          'actualHr':[actualHoursArr[0],[Validators.pattern('[0-9]*')]],
    //          'actualMin':[actualHoursArr[1],[Validators.pattern('[0-9]*'),Validators.max(60)]],
    //          'sprintId':[data.sprintId,[Validators.required]],
    //          'storyPoints':[data.storyPoints],
    //          'status':[data.status,[Validators.required]],
    //          'attachments':[],
    //          'oldattachments':[data.projectsProjectFile]
    //      });
    //  }

    // }

    toggleList(ele) {
        this.descriptionFiles = [];
        if (!this.isUserDitailReadOnly) {
            if ('description') {
                $('#userstory_description').summernote('code', this.userStoryData.description);
            }
            if (this.userStoryData.descriptionAttachments && this.userStoryData.descriptionAttachments.length > 0) {
                this.userStoryData.descriptionAttachments.forEach(element => {
                    this.descriptionFiles.push({ id: element.id, name: element.displayName, serverFileName: element.fileName, image: element.fileTitle, isProgressBarShown: false });
                    //obj.imgArr.push(element.displayName);
                });
                // 	}
            } else {
                //$('.' + ele + '_' + obj.id).slideUp("fast");
                this.descriptionFiles = [];
                this.descriptionImgArr = [];

            }
            $('.' + ele).slideToggle("fast");

        }
    }


    toggleAddCommentList(ele, event, show) {
        this.files = [];
        this.imgArr = [];
        $('.htmlEditor').summernote({
            height: '150px',
            focus: true,
            disableDragAndDrop: true,
            toolbar: [
                // [groupName, [list of button]]
                ['style', ['bold', 'italic', 'underline']],
                ['para', ['ul', 'ol', 'paragraph']]
            ],
            callbacks: {
                onPaste: function (e) {
                    var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
                    e.preventDefault();
                    document.execCommand('insertText', false, bufferText);
                }
            }
        });

        this.isAddCommentInputShown = show;
        $('.htmlEditor').summernote('code', '');
        $('#userstory_comment').summernote("reset");
        //$('#userstory_comment').code('');
        if (event == 'down') {
            $('.' + ele).slideDown("fast");
        } else {
            $('.' + ele).slideUp("fast");

        }

        $('#userstory_comment').summernote({
            focus: true,
        });
    }

    toggleUpdateCommentList(ele, event, obj) {

        obj.files = [];
        obj.imgArr = [];

        $('.htmlEditor').summernote({
            height: '150px',
            focus: true,
            disableDragAndDrop: true,
            toolbar: [
                // [groupName, [list of button]]
                ['style', ['bold', 'italic', 'underline']],
                ['para', ['ul', 'ol', 'paragraph']]
            ],
            callbacks: {
                onPaste: function (e) {
                    var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
                    e.preventDefault();
                    document.execCommand('insertText', false, bufferText);
                }
            }
        });

        $('.htmlEditor').summernote('code', obj.comment);
        if (event == 'down') {

            $('.' + ele + '_' + obj.id).slideDown("fast");
            $('#userstory_edit_comment' + obj.id).summernote("reset");
            $('#userstory_edit_comment' + obj.id).summernote('code', obj.comment);
            if (obj.attachments && obj.attachments.length > 0) {
                obj.attachments.forEach(element => {
                    obj.files.push({ id: element.id, name: element.displayName, serverFileName: element.fileName, image: element.fileTitle, isProgressBarShown: false });
                    //obj.imgArr.push(element.displayName);
                });
                // 	}
            } else {
                //$('.' + ele + '_' + obj.id).slideUp("fast");
                obj.files = [];
                obj.imgArr = [];

            }
        } else {

            $('.' + ele + '_' + obj.id).slideUp("fast");
            $('#userstory_edit_comment' + obj.id).summernote("reset");
            $('#userstory_edit_comment' + obj.id).summernote('code', '');

        }
    }

    showedit() {
        if (!this.isUserDitailReadOnly) {
            $('.heading-editable h1').hide("fast");
            $('.heading-area').show("fast");
            $('.tick-cancel-input-filed').show("fast");
        }

    }
    hideedit() {
        if (!this.isUserDitailReadOnly) {
            $('.heading-editable h1').show("fast");
            $('.heading-area').hide("fast");
            $('.tick-cancel-input-filed').hide("fast");
        }
    }

    // getSprintList(){
    //  this.restService.hitApi('userStory/sprintList',{'projectId':this.pid},'post','',false)
    //  .subscribe((result)=>{
    //      let responseData : any = result;
    //      if(responseData.statusCode==200){
    //          this.sprintList = responseData.result;
    //      }else{
    //          // this.toastr.error(responseData.message);
    //      }
    //  },(err)=>{
    //      console.log(err);
    //      let errorData : any = err;
    //      // this.toastr.error(errorData.error.message);
    //  })
    // }

    getUserstory() {
        this.restService.hitApi('userStory/details', { 'userStoryId': this.usid }, 'post', '', true)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    this.userStoryData = responseData.result;
                    this.userStoryId = this.userStoryData.id;
                    if (this.userStoryData.status == 4) {
                        this.isUserDitailReadOnly = true;
                    } else {
                        this.isUserDitailReadOnly = false;
                    }
                    if (this.userStoryData.dueDate) {
                        var d = Date.parse(this.userStoryData.dueDate);
                        var dueDate = new Date(d);
                        var date: string = this.dateService.convertInDDMMYYYFormate(dueDate);
                        this.userStoryData.previousDueDate = date;
                        this.userStoryData.dueDate = { date: { year: dueDate.getFullYear(), month: dueDate.getMonth() + 1, day: dueDate.getDate() }, formatted: date, jsdate: dueDate };


                    }

                    if (this.userStoryData.status) {
                        if (this.userStoryData && this.userStoryData.userStoryTask && this.userStoryData.userStoryTask.length > 0) {
                            var isAllTaskCompleted = false;
                            this.userStoryData.userStoryTask.forEach(element => {
                                if (element.status == 4) {
                                    isAllTaskCompleted = true;
                                } else {
                                    isAllTaskCompleted = false;
                                }
                            });
                            console.log("isAllTaskCompleted", isAllTaskCompleted)
                            if (!isAllTaskCompleted) {
                                if (this.projectData.type == 2) {
                                    this.userStoryStatusList = [{ name: 'Backlog', value: 0 }, { name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }]

                                } else {
                                    this.userStoryStatusList = [{ name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }]

                                }

                            }
                            console.log("userStoryStatusList", this.userStoryStatusList);
                        } else {
                            if (this.projectData.type == 2) {
                                this.userStoryStatusList = [{ name: 'Backlog', value: 0 }, { name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }, { name: 'Completed', value: 4 }]

                            } else {
                                this.userStoryStatusList = [{ name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }, { name: 'Completed', value: 4 }]

                            }
                        }
                    }
                    if (this.userStoryData.status) {
                        this.userStoryStatusList.forEach(element => {
                            if (element.value == this.userStoryData.status) {
                                this.userStoryStatus.setValue(element);

                            }
                        })
                    } else {
                        this.userStoryStatus.setValue(this.userStoryStatusList[0]);
                    }



                    if (this.userStoryData && this.userStoryData.assignee && this.userStoryData.userId) {
                        this.userList?.forEach(element => {
                            if (element.userId == this.userStoryData.userId) {
                                this.assignee.setValue(element);

                            }
                        });
                    } else {
                        this.assignee.setValue(this.userList[0]);
                    }
                    if (this.userStoryData && this.userStoryData.sprintId) {
                        this.sprintList?.forEach(element => {
                            if (element.id == this.userStoryData.sprintId) {
                                this.sprintControl.setValue(element);

                            }
                        });
                    }

                    console.log('sprint', this.sprintControl);
                    console.log('assignee', this.assignee);
                    console.log(this.userStoryStatus);
                    // this.createForm(this.userStoryData);
                    this.getUnassociatedTask(this.userStoryData.sprintId);
                    this.getUnassociated();
                    this.getUnassociatedSprint();
                } else {
                    // this.toastr.error(responseData.message);
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                // this.toastr.error(errorData.error.message);
            })
    }
    checkNumeric(field, value = "") {
        value = $('#userstory_' + field).val();

        if (value.length > 2) {
            return false;
        }
    }

    updateUserStory(field, value = "") {
        let data: any;
        if (field == 'name') {
            value = $('#userstory_' + field).val();
            if (value != "") {
                if (value != this.userStoryData.summary) {
                    data = { 'summary': value, 'userStoryId': this.userStoryId, 'projectId': this.pid };
                }
            } else {
                this.toastr.error("User Story name is required");
                return
            }

        } else if (field == 'description') {
            value = $('#userstory_' + field).val();
            data = { 'description': value, 'userStoryId': this.userStoryId, 'projectId': this.pid, 'files': this.descriptionImgArr };
        } else if (field == 'sprintId') {
            if (value != this.userStoryData.sprintId) {
                data = { 'sprintId': value, 'userStoryId': this.userStoryId, 'projectId': this.pid };
            }
        } else if (field == 'assignedTo') {
            if (value != this.userStoryData.userId) {
                data = { 'assignedTo': value, 'userStoryId': this.userStoryId, 'projectId': this.pid };
            }
        } else if (field == 'status') {
            if (value != this.userStoryData.status) {
                data = { 'status': value, 'userStoryId': this.userStoryId, 'projectId': this.pid };
            }
        } else if (field == 'dueDate') {
            if (value != this.userStoryData.previousDueDate) {
                data = { 'dueDate': value, 'userStoryId': this.userStoryId, 'projectId': this.pid };

            }
        } else if (field == 'storyPoints') {
            value = $('#userstory_' + field).val();
            if (value != this.userStoryData.storyPoints) {
                data = { 'storyPoints': value, 'userStoryId': this.userStoryId, 'projectId': this.pid };

            }
        } else if (field == 'estHours') {
            value = $('#userstory_' + field).val();
            data = { 'estHours': value, 'userStoryId': this.userStoryId, 'projectId': this.pid };
        } else if (field == 'estMins') {
            value = $('#userstory_' + field).val();
            data = { 'estMins': value, 'userStoryId': this.userStoryId, 'projectId': this.pid };
        } else if (field == 'loggedHours') {
            let value1 = $('#userStory_loggedHours').val();
            let value2 = $('#userStory_loggedMins').val();
            data = { 'loggedHours': value1, 'loggedMins': value2, 'userStoryId': this.userStoryId, 'projectId': this.pid };
        }
        // else if(field=='estHours'){
        //  value = $('#userstory_'+field).val();
        //  data = {'estHours':value,'userStoryId' : this.usid,'projectId':this.pid};
        // }else if(field=='estMins'){
        //  value = $('#userstory_'+field).val();
        //  data = {'estMins':value,'userStoryId' : this.usid,'projectId':this.pid};
        // }

        else if (field == 'endDate') {
            data = { 'endDate': value, 'userStoryId': this.userStoryId, 'projectId': this.pid };
        } else if (field == 'duration') {
            data = { 'duration': value, 'userStoryId': this.userStoryId, 'projectId': this.pid };
        }

        if (data != null) {
            if (field == 'storyPoints' && data.storyPoints != null && data.storyPoints != '' && data.storyPoints <= 0) {
                this.toastr.error("Story point should be greater than 0");
            } else {

                this.restService.hitApi('userStory/update', data, 'post', '', true)
                    .subscribe((result) => {
                        let responseData: any = result;
                        if (responseData.statusCode == 200) {
                            this.toastr.success(responseData.message);
                            if (field == 'name') {
                                $('.heading-editable h1').show("fast");
                                $('.heading-area').hide("fast");
                                $('.tick-cancel-input-filed').hide("fast");
                            } else if (field == 'status') {
                                $('.progress-work-st-js').slideUp("fast");
                            } else if (field == 'dueDate') {
                                $('.datepicker').hide();
                            } else if (field == 'description') {
                                this.descriptionFiles = [];
                                this.descriptionImgArr = [];
                                $('.description').slideToggle("fast");
                            } else if (field == 'sprintId') {
                                $('.sprint-reporter-work-open').slideUp("fast");
                            } else if (field == 'assignedTo') {
                                $('.assign-reporter-work-open').slideUp("fast");
                            }


                            this.getUserstory();
                            // $('#create_release_popup_web').modal('hide');
                            // this.files = [];
                        } else {
                            this.toastr.error(responseData.message);
                        }
                    }, (err) => {
                        console.log(err);
                        let errorData: any = err;
                        this.toastr.error(err.error.message);
                    })
            }
        } else {
            if (field == 'name') {
                $('.heading-editable h1').show("fast");
                $('.heading-area').hide("fast");
                $('.tick-cancel-input-filed').hide("fast");
            }
        }
    }

    // updateUserStory(){
    //  // let apiname = (this.userstoryCreateForm.value.userStoryId)?'userStory/update':'userStory/create'
    //  this.userstoryCreateForm.value.projectId = this.pid;
    //  this.userstoryCreateForm.value.estHours = this.userstoryCreateForm.value.estHr+"."+this.userstoryCreateForm.value.estMin;
    //  this.userstoryCreateForm.value.actualHours = this.userstoryCreateForm.value.actualHr+"."+this.userstoryCreateForm.value.actualMin;
    //  this.userstoryCreateForm.value.attachments = this.imgArr;
    //  console.log(this.userstoryCreateForm.value);
    //  this.restService.hitApi('userStory/update',this.userstoryCreateForm.value,'post','',true)
    //  .subscribe((result)=>{
    //      let responseData : any = result;
    //      if(responseData.statusCode==200){
    //          this.userstoryCreateForm.reset();
    //          this.getUserstory();
    //          $('#add_userstory_popup_web').modal('hide');
    //          this.toastr.success(responseData.message);
    //          this.files = [];
    //      }else{
    //          this.toastr.error(responseData.message);
    //      }
    //  },(err)=>{
    //      console.log(err);
    //      let errorData : any = err;
    //      this.toastr.error(errorData.error.message);
    //  })
    // }

    deleteUserstory() {
        this.restService.hitApi('userStory/delete', { 'userStoryId': this.userStoryId }, 'post', '', true)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    $('#delete-record').modal('hide')
                    this.toastr.success(responseData.message);
                    this.backToRetun();
                    //this.router.navigateByUrl('/project/' + this.pid + '/userstories');
                } else {
                    this.toastr.error(responseData.message);
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                this.toastr.error(errorData.error.message);
            })
    }



    // deleteUserStory(rid){
    //  this.restService.hitApi('userStory/delete',{'userStoryId':rid},'post','',true)
    //      .subscribe((result)=>{
    //          let responseData : any = result;
    //          if(responseData.statusCode==200){
    //          this.toastr.success(responseData.message);
    //          this.router.navigateByUrl('/project/'+this.pid+'/releases');
    //          }else{
    //              this.toastr.error(responseData.message);
    //          }
    //      },(err)=>{
    //          console.log(err);
    //          let errorData : any = err;
    //          this.toastr.error(errorData.error.message);
    //  })
    // }

    // getProjectSprints(){
    //  this.restService.hitApi('release-sprint/sprint',{'projectReleaseId':this.usid},'post','',true)
    //  .subscribe((result)=>{
    //      let responseData : any = result;
    //      if(responseData.statusCode==200){
    //          this.projectSprintList = responseData.result;
    //      }else{
    //          // this.toastr.error(responseData.message);
    //      }
    //  },(err)=>{
    //      console.log(err);
    //      let errorData : any = err;
    //      // this.toastr.error(errorData.error.message);
    //  })
    // }

    // uploadFile(event) {
    //  for (let index = 0; index < event.length; index++) {
    //      let file : File = event[index];
    //      const formData: FormData = new FormData();
    //      formData.append('projectId', this.pid);
    //      formData.append('file', file);
    //      this.files.push(file.name);

    //      this.restService.hitApi('userStory/upload-file',formData,'post','',true,false)
    //          .subscribe((result)=>{
    //              let responseData : any = result;
    //              if(responseData.statusCode==200){
    //                  this.imgArr.push(responseData.result);
    //              }else{
    //                  this.toastr.error(responseData.message);
    //              }
    //          },(err)=>{
    //              console.log(err);
    //              let errorData : any = err;
    //              this.toastr.error(errorData.error.message);
    //      })
    //  }

    // }





    removeReleaseSprints() {
        this.sprintArr = [];
        let that = this;
        $('.release-sprint-checkbox').find('input').each(function () {
            console.log($(this).val());
            if ($(this).prop("checked")) {
                that.sprintArr.push($(this).val());
            }
        });

        console.log(this.sprintArr);

        this.restService.hitApi('release-sprint/delete', { 'projectReleaseId': this.userStoryId, 'sprintId': this.sprintArr }, 'post', '', true)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    this.getUserstory();
                    this.toastr.success(responseData.message);
                } else {
                    this.toastr.error(responseData.message);
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                this.toastr.error(errorData.error.message);
            })
    }

    addReleaseSprint() {

        let that = this;
        $('.sprint-checkbox').find('input').each(function () {
            console.log($(this).val());
            if ($(this).prop("checked")) {
                that.sprintArr.push($(this).val());
            }
        });

        console.log(this.sprintArr);

        this.restService.hitApi('release-sprint/create', { 'projectId': this.pid, 'projectReleaseId': this.userStoryId, 'sprintId': this.sprintArr }, 'post', '', true)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    $('#create_add-link_popup_web').modal('hide');
                    this.getUserstory();
                    this.toastr.success(responseData.message);
                } else {
                    this.toastr.error(responseData.message);
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                this.toastr.error(errorData.error.message);
            })
    }

    //Image upload
    uploadFile(event) {
        console.log("file event", event);
        for (let index = 0; index < event.length; index++) {
            let file: File = event[index];
            if (this.restService.checkFileSize(file, 50)) {
                const formData: FormData = new FormData();
                formData.append('projectId', this.pid);
                formData.append('entity', 'userStory');
                formData.append('entityId', this.userStoryId);
                formData.append('file', file);
                var reader = new FileReader();
                const this_ = this;
                var obj;
                reader.onload = function (e: any) {
                    obj = { serverFileName: file.name, name: file.name, image: e.target.result, isProgressBarShown: true }
                    this_.files.push(obj);
                }

                reader.readAsDataURL(file);
                // this.files.push(file.name);

                this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
                    .subscribe((event: HttpEvent<any>) => {
                        switch (event.type) {
                            case HttpEventType.Sent:
                                console.log('Request has been made!');
                                break;
                            case HttpEventType.ResponseHeader:
                                console.log('Response header has been received!');
                                break;
                            case HttpEventType.UploadProgress:
                                this.files[this.files.length - 1].progress = Math.round(event.loaded / event.total * 100);
                                console.log(`Uploaded! ${this.files[this.files.length - 1].progress}%`);
                                $('#add_comment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');

                                break;
                            case HttpEventType.Response:
                                let responseData: any = event.body;
                                if (responseData.statusCode == 200) {
                                    this.imgArr.push(responseData.result);
                                    this.files[this.files.length - 1].serverFileName = responseData.result.fileTitle;
                                    this.files[this.files.length - 1].displayName = responseData.result.displayName;
                                    this.files.forEach(element => {
                                        element.isProgressBarShown = false;
                                    });
                                } else {
                                    if (responseData.message) {
                                        this.toastr.error(responseData.message);
                                    }
                                }
                                console.log('Upload successfully', event.body);
                                setTimeout(() => {
                                    this.files[this.files.length - 1].progress = 0;
                                    $('#add_comment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');

                                }, 1500);


                        }
                    })

                // this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', false, false)
                //     .subscribe((result) => {
                //         let responseData: any = result;
                //         if (responseData.statusCode == 200) {
                //             this.imgArr.push(responseData.result);
                //             this.files[this.files.length - 1].serverFileName = responseData.result.fileTitle;
                //             this.files[this.files.length - 1].displayName = responseData.result.displayName;
                //             this.files.forEach(element => {
                //                 element.isProgressBarShown = false;
                //             });
                //         } else {
                //             this.toastr.error(responseData.message);
                //         }
                //     }, (err) => {
                //         console.log(err);
                //         let errorData: any = err;
                //         this.toastr.error(errorData.error.message);
                //     })
            }
        }

    }


    uploadCommentAttachment(event, commentObj) {
        var i;
        for (let index = 0; index < event.length; index++) {
            i = index;
            let file: File = event[index];
            if (this.restService.checkFileSize(file, 50)) {
                const formData: FormData = new FormData();
                formData.append('projectId', this.pid);
                formData.append('entity', 'userStory');
                formData.append('entityId', this.usid);
                formData.append('file', file);

                // const element = event[index];


                var reader = new FileReader();
                const this_ = this;
                var obj;
                reader.onload = function (e: any) {
                    obj = { name: file.name, serverFileName: file.name, image: e.target.result, isProgressBarShown: true }
                    commentObj.files.push(obj);
                }

                reader.readAsDataURL(file);

                this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
                    .subscribe((event: HttpEvent<any>) => {
                        switch (event.type) {
                            case HttpEventType.Sent:
                                console.log('Request has been made!');
                                break;
                            case HttpEventType.ResponseHeader:
                                console.log('Response header has been received!');
                                break;
                            case HttpEventType.UploadProgress:
                                commentObj.files[commentObj.files.length - 1].progress = Math.round(event.loaded / event.total * 100);
                                console.log(`Uploaded! ${commentObj.files[commentObj.files.length - 1].progress}%`);
                                $('#edit_comment_progress_bar_' + (commentObj.files.length - 1)).attr('aria-valuenow', commentObj.files[commentObj.files.length - 1].progress).css('width', commentObj.files[commentObj.files.length - 1].progress + '%');

                                break;
                            case HttpEventType.Response:
                                let responseData: any = event.body;
                                if (responseData.statusCode == 200) {
                                    commentObj.imgArr.push(responseData.result);
                                    commentObj.files[commentObj.files.length - 1].serverFileName = responseData.result.fileTitle;
                                    commentObj.files[commentObj.files.length - 1].displayName = responseData.result.displayName;
                                    commentObj.files.forEach(element => {
                                        element.isProgressBarShown = false;
                                    });
                                } else {
                                    if (responseData.message) {
                                        this.toastr.error(responseData.message);
                                    }
                                }
                                setTimeout(() => {
                                    commentObj.files[commentObj.files.length - 1].progress = 0;
                                    $('#edit_comment_progress_bar_' + (commentObj.files.length - 1)).attr('aria-valuenow', commentObj.files[commentObj.files.length - 1].progress).css('width', commentObj.files[commentObj.files.length - 1].progress + '%');

                                }, 1500);
                        }
                    })

                // this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', false, false)
                //     .subscribe((result) => {
                //         let responseData: any = result;
                //         if (responseData.statusCode == 200) {

                //             commentObj.imgArr.push(responseData.result);
                //             commentObj.files[commentObj.files.length - 1].serverFileName = responseData.result.fileTitle;
                //             commentObj.files[commentObj.files.length - 1].displayName = responseData.result.displayName;
                //             commentObj.files.forEach(element => {
                //                 element.isProgressBarShown = false;
                //             });

                //         } else {
                //             this.toastr.error(responseData.message);
                //         }
                //     }, (err) => {
                //         console.log(err);
                //         let errorData: any = err;
                //         this.toastr.error(errorData.error.message);
                //     })
            }
        }
    }

    uploadDescriptionFile(event) {
        for (let index = 0; index < event.length; index++) {
            let file: File = event[index];
            if (this.restService.checkFileSize(file, 50)) {
                const formData: FormData = new FormData();
                formData.append('projectId', this.pid);
                formData.append('entity', 'userStory');
                formData.append('entityId', this.usid);
                formData.append('file', file);
                var reader = new FileReader();
                const this_ = this;
                var obj;
                reader.onload = function (e: any) {
                    obj = { serverFileName: file.name, name: file.name, image: e.target.result, isProgressBarShown: true }
                    this_.descriptionFiles.push(obj);
                }

                reader.readAsDataURL(file);

                this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
                    .subscribe((event: HttpEvent<any>) => {
                        switch (event.type) {
                            case HttpEventType.Sent:
                                console.log('Request has been made!');
                                break;
                            case HttpEventType.ResponseHeader:
                                console.log('Response header has been received!');
                                break;
                            case HttpEventType.UploadProgress:
                                this.descriptionFiles[this.descriptionFiles.length - 1].progress = Math.round(event.loaded / event.total * 100);
                                console.log(`Uploaded! ${this.descriptionFiles[this.descriptionFiles.length - 1].progress}%`);
                                $('#discription_progress_bar_' + (this.descriptionFiles.length - 1)).attr('aria-valuenow', this.descriptionFiles[this.descriptionFiles.length - 1].progress).css('width', this.descriptionFiles[this.descriptionFiles.length - 1].progress + '%');

                                break;
                            case HttpEventType.Response:
                                let responseData: any = event.body;
                                if (responseData.statusCode == 200) {
                                    this.descriptionImgArr.push(responseData.result);
                                    this.descriptionFiles[this.descriptionFiles.length - 1].serverFileName = responseData.result.fileTitle;
                                    this.descriptionFiles[this.descriptionFiles.length - 1].displayName = responseData.result.displayName;
                                    this_.descriptionFiles.forEach(element => {
                                        element.isProgressBarShown = false;
                                    });
                                    //this.getSprint();
                                } else {
                                    if (responseData.message) {
                                        this.toastr.error(responseData.message);
                                    }
                                }
                                console.log('User successfully created!', event.body);
                                setTimeout(() => {
                                    this.descriptionFiles[this.descriptionFiles.length - 1].progress = 0;
                                    $('#discription_progress_bar_' + (this.descriptionFiles.length - 1)).attr('aria-valuenow', this.descriptionFiles[this.descriptionFiles.length - 1].progress).css('width', this.descriptionFiles[this.descriptionFiles.length - 1].progress + '%');

                                }, 1500);
                        }
                    })


                // this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', false, false)
                //     .subscribe((result) => {
                //         let responseData: any = result;
                //         if (responseData.statusCode == 200) {
                //             this.descriptionImgArr.push(responseData.result);
                //             this.descriptionFiles[this.descriptionFiles.length - 1].serverFileName = responseData.result.fileTitle;
                //             this.descriptionFiles[this.descriptionFiles.length - 1].displayName = responseData.result.displayName;
                //             this_.descriptionFiles.forEach(element => {
                //                 element.isProgressBarShown = false;
                //             });
                //             //this.getSprint();
                //         } else {
                //             this.toastr.error(responseData.message);
                //         }
                //     }, (err) => {
                //         console.log(err);
                //         let errorData: any = err;
                //         this.toastr.error(errorData.error.message);
                //     })
            }
        }

    }
    saveFile() {

        var fileArray = [];
        if (this.files) {
            this.files.forEach(element => {
                if (element.displayName) {
                    fileArray.push({ 'fileTitle': element.serverFileName, 'displayName': element.displayName });
                }
                else {
                    fileArray.push(element.serverFileName);
                }
            });
        }
        if (fileArray.length > 0) {
            var json = {
                projectId: this.pid,
                entity: "userStory",
                entityId: this.userStoryId,
                files: fileArray
            }

            this.restService.hitApi('project/justSaveFiles', json, 'post', '', true, false)
                .subscribe((result) => {
                    let responseData: any = result;
                    if (responseData.statusCode == 200) {
                        this.getUserstory();
                    } else {
                        this.toastr.error(responseData.message);
                    }
                }, (err) => {
                    console.log(err);
                    let errorData: any = err;
                    this.toastr.error(errorData.error.message);
                })

        }

    }
    // removeAttachment(fileObj, index, commentObj) {
    //     var deleteFileArray = [];
    //     var deleteFileArray = [fileObj.serverFileName];
    //     if (commentObj) {
    //         if (fileObj.id) {
    //             if (deleteFileArray.length > 0) {
    //                 this.deleteCommentAttachment(deleteFileArray);
    //             }

    //         } else {
    //             if (deleteFileArray.length > 0) {
    //                 this.removeAttachmentFromServer(deleteFileArray, true);

    //             }
    //         }
    //         commentObj.files.splice(index, 1);
    //         commentObj.imgArr.splice(0, 1);


    //     } else {
    //         if (deleteFileArray.length > 0) {
    //             this.removeAttachmentFromServer(deleteFileArray, true);

    //         }
    //         this.files.splice(index, 1);
    //         this.imgArr.splice(0, 1);
    //     }
    //     // var file = this.files[index];
    //     // var deleteFileArray = [file.serverFileName];
    //     // this.removeAttachmentFromServer(deleteFileArray, true);
    //     // this.files.splice(index, 1)
    // }

    removeAll() {
        this.deletedAttachmentItems = [];
        this.files.forEach(element => {
            this.deletedAttachmentItems.push(element.serverFileName);
        });

        if (this.deletedAttachmentItems.length > 0) {
            this.removeAttachmentFromServer(null, null);
        }
    }

    removeDescriptionAttachment(fileObj, index) {

        this.deletedDescriptionItems.push(fileObj.serverFileName);
        this.descriptionFiles.splice(index, 1);
        this.descriptionImgArr.splice(index, 1);

        //var fileObj = this.files[index];
        // var deleteFileArray = [];
        // var deleteFileArray = [fileObj.serverFileName];
        // if (fileObj.id) {
        //     if (deleteFileArray.length > 0) {
        //         this.deleteCommentAttachment(deleteFileArray);
        //     }

        // } else {
        //     var deleteFileArray = [fileObj.serverFileName];
        //     if (deleteFileArray.length > 0) {
        //         this.removeAttachmentFromServer(deleteFileArray, false);

        //     }


        // }
        // this.descriptionFiles.splice(index, 1);
        // this.descriptionImgArr.splice(0, 1);
    }

    updateDescriptionAttachment() {
        if (this.deletedDescriptionItems && this.deletedDescriptionItems.length > 0) {
            this.removeDescriptionAttachmentFromServer();
        } else {
            this.updateUserStory('description');
        }
    }

    updateAttachment(obj, field) {
        if (this.deletedAttachmentItems && this.deletedAttachmentItems.length > 0) {
            this.removeAttachmentFromServer(obj, field);
        } else {
            this.addComment(obj, field);
        }
    }


    removeDescriptionAttachmentFromServer() {

        var json = {
            projectId: this.pid,
            entity: 'sprint',
            files: this.deletedDescriptionItems
        }

        this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    this.deletedDescriptionItems = [];
                    this.updateUserStory('description');
                } else {
                    this.toastr.success(responseData.message);
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                this.toastr.error(errorData.error.message);
            })
    }

    removeAttachment(fileObj, index, commentObj) {

        this.deletedAttachmentItems.push(fileObj.serverFileName)

        if (commentObj != undefined && commentObj != null) {
            commentObj.files.splice(index, 1);
            commentObj.imgArr.splice(0, 1);

        } else {
            this.files.splice(index, 1);
            this.imgArr.splice(0, 1);
        }

    }

    removeAttachmentFromServer(obj, field) {
        var json = {
            projectId: this.pid,
            entity: 'sprint',
            entityId: this.usid,
            files: this.deletedAttachmentItems
        }

        this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    this.deletedAttachmentItems = [];
                    if (obj && field) {
                        this.addComment(obj, field);
                    }
                } else {
                    this.toastr.success(responseData.message);
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                this.toastr.error(errorData.error.message);
            })
    }


    deleteCommentAttachment(files) {
        var json = {
            projectId: this.pid,
            entity: 'userStory',
            files: files
        }

        this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    //this.getTestplan();
                } else {

                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                this.toastr.error(errorData.error.message);
            })
    }


    deleteAttachment(aid, index = "") {
        this.restService.hitApi('userStory/delete-file', { "attachmentId": aid, "projectId": this.pid, "userStoryId": this.userStoryId }, 'post', '', true, false)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    if (index) {
                        this.userstoryCreateForm.value.oldattachments.splice(index, 1);
                    } else {
                        this.getUserstory();
                    }
                    this.toastr.success(responseData.message);
                } else {
                    this.toastr.error(responseData.message);
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                this.toastr.error(errorData.error.message);
            })
    }

    removeUserStorySprint() {

    }

    checkUncheckAll(ele, classname) {
        // alert()
        if (classname == 'Parent') {
            var checkboxes = $(".userstory-Parent-checkbox input");
        } else if (classname == 'Child') {
            var checkboxes = $(".userstory-Child-checkbox input");
        } else {
            var checkboxes = $("." + classname + " input");
        }

        if (ele) {
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = true;
            }
        } else {
            for (var i = 0; i < checkboxes.length; i++) {
                console.log(i)
                checkboxes[i].checked = false;
            }
        }
    }

    getUnassociatedTask(sprintId) {
        this.restService.hitApi('userStory/getUserStoryTask', { "projectId": this.pid, "userStoryId": this.userStoryId }, 'post', '', true, false)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    this.getUnassociatedTaskData = responseData.result;
                } else {
                    this.toastr.error(responseData.message);
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                this.toastr.error(errorData.error.message);
            })
    }
    getUnassociatedSprint() {
        this.restService.hitApi('userStory/sprintList', { "projectId": this.pid, notCompleted: 1 }, 'post', '', true, false)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    this.getUnassociatedSprintData = responseData.result;
                    this.sprintList = responseData.result;

                    if (this.userStoryData && this.userStoryData.sprintId) {
                        this.sprintList.forEach(element => {
                            if (element.id == this.userStoryData.sprintId) {
                                this.sprintControl.setValue(element);

                            }
                        });
                    }
                } else {
                    this.toastr.error(responseData.message);
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                this.toastr.error(errorData.error.message);
            })
    }

    getUnassociated() {

        this.restService.hitApi('userStory/userStories', { "projectId": this.pid, "userStoryId": this.userStoryId }, 'post', '', true, false)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    this.getUnassociatedData = responseData.result;
                } else {
                    this.toastr.error(responseData.message);
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                this.toastr.error(errorData.error.message);
            })
    }

    openUnAssociatedList(entity) {
        console.log(entity);
        this.selectedEntity = entity;
        if (entity == 'Parent' || entity == 'Child') {
            this.unassociatedData = this.getUnassociatedData;
            console.log("this.unassociatedData", this.unassociatedData);
        } else if (entity == 'Sprint') {
            this.unassociatedData = this.getUnassociatedSprintData;
        } else if (entity == 'Task') {
            this.unassociatedData = this.getUnassociatedTaskData;
        } else if (entity == 'Defect') {
            this.unassociatedData = this.getUnassociatedData['tasks'];
        } else if (entity == 'Test Run') {
            this.unassociatedData = this.getUnassociatedData['projectTestPlanes'];
        }
    }

    closeLinkModal() {
        this.selectedCheckboxArr = [];
        this.unassociatedData = [];
        $('.table-multiple-checkboxes input:checkbox').removeAttr('checked');
    }

    selectonlyOne(selected) {
        console.log(selected)
        $('.table-multiple-checkboxes input:checkbox').removeAttr('checked');
        $('#' + selected).prop("checked", true);
    }

    unCheckRemainingUserStory(userStory) {

        if (this.unassociatedData && this.unassociatedData.length) {
            this.unassociatedData.forEach(element => {
                if (element.id != userStory.id) {
                    element.checked = false;
                }
            });
        }
    }

    associateToSprint(entity) {
        this.selectedCheckboxArr = [];
        let type: any = "";
        if (entity == 'Parent') type = 'parent';
        else if (entity == 'Child') type = 'child';
        else if (entity == 'User Stories') type = 'userStories';
        else if (entity == 'Defect') type = 'defects';
        else if (entity == 'Test Run') type = 'projectTestPlanes';
        let that = this;

        this.unassociatedData.forEach(element => {
            if (element.checked) {
                that.selectedCheckboxArr.push(element.id);
            }
        });
        // $('.sprint-unassociated-checkbox').find('input').each(function () {
        // 	if ($(this).prop("checked")) {
        // 		that.selectedCheckboxArr.push($(this).val());
        // 	}
        // });

        let apiname: any = "";
        let data: any = "";
        if (entity == 'Sprint') {
            apiname = "userStory/updateUserStorySprint";
            data = { "userStoryId": this.userStoryId, "sprintId": this.selectedCheckboxArr[0] };
        } else if (entity == 'Parent') {
            apiname = "userStory/updateParent";
            data = { "projectId": this.pid, "userStoryId": this.userStoryId, "parentId": this.selectedCheckboxArr[0] };
        } else if (entity == 'Child') {
            apiname = "userStory/setUserStory";
            data = { "projectId": this.pid, "userStoryId": this.userStoryId, "type": type, "ids": this.selectedCheckboxArr };
        }



        this.restService.hitApi(apiname, data, 'post', '', true, false)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    $('#create_add-link_popup_web').modal('hide');
                    this.selectedCheckboxArr = [];
                    this.unassociatedData = [];
                    this.allUserStoryChecked = false;
                    this.allUnassociatedUserStoryChecked = false;
                    //$('.table-multiple-checkboxes input:checkbox').removeAttr('checked');
                    this.getUserstory();
                    this.getUnassociated();
                    this.resetAllUserStory();

                    this.toastr.success(responseData.message);
                } else {
                    this.toastr.error(responseData.message);
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                this.toastr.error(errorData.error.message);
            })

    }

    unassociateToSprint(entity) {
        this.selectedCheckboxArr = [];
        console.log(entity);
        let entityname: any = "";
        if (entity == 'Parent') entityname = "parent";
        else if (entity == 'Child') entityname = "child";
        else if (entity == 'User Stories') entityname = "userStories";
        else if (entity == 'Task') entityname = "task";

        // let chechbox = 'userstory-' + entity.split(" ").join("") + '-checkbox';
        // let that = this;
        // $('.' + chechbox).find('input').each(function () {
        // 	if ($(this).prop("checked")) {
        // 		that.selectedCheckboxArr.push($(this).val());
        // 	}
        // });
        let apiname: any;
        let data: any;
        let dataselected: boolean = false;

        if (entity == 'Sprint') {
            apiname = "userStory/updateUserStorySprint";
            data = { "userStoryId": this.userStoryId, "sprintId": "" };
            this.restService.hitApi(apiname, data, 'post', '', true, false)
                .subscribe((result) => {
                    let responseData: any = result;
                    if (responseData.statusCode == 200) {
                        this.selectedCheckboxArr = [];
                        this.unassociatedData = [];
                        //$('.table-multiple-checkboxes input:checkbox').removeAttr('checked');
                        this.getUserstory();
                        this.getUnassociated();
                        this.toastr.success(responseData.message);
                    } else {
                        this.toastr.error(responseData.message);
                    }
                }, (err) => {
                    console.log(err);
                    let errorData: any = err;
                    this.toastr.error(errorData.error.message);
                })
        } else if (entity == 'Parent') {
            apiname = "userStory/updateParent";
            data = { "projectId": this.pid, "userStoryId": this.userStoryId, "parentId": "" };
            this.restService.hitApi(apiname, data, 'post', '', true, false)
                .subscribe((result) => {
                    let responseData: any = result;
                    if (responseData.statusCode == 200) {
                        this.selectedCheckboxArr = [];
                        this.unassociatedData = [];
                        //$('.table-multiple-checkboxes input:checkbox').removeAttr('checked');
                        this.getUserstory();
                        this.getUnassociated();
                        this.toastr.success(responseData.message);
                    } else {
                        this.toastr.error(responseData.message);
                    }
                }, (err) => {
                    console.log(err);
                    let errorData: any = err;
                    this.toastr.error(errorData.error.message);
                })
        } else if (entity == 'Child' || entity == 'Task') {

            this.userStoryData.childUserStories.forEach(element => {
                if (element.checked) {
                    this.selectedCheckboxArr.push(element.id);
                }
            });

            apiname = "userStory/unsetUserStory";
            data = { "projectId": this.pid, "userStoryId": this.userStoryId, "type": entityname, "ids": this.selectedCheckboxArr };

            if (this.selectedCheckboxArr.length) {
                this.restService.hitApi(apiname, data, 'post', '', true, false)
                    .subscribe((result) => {
                        let responseData: any = result;
                        if (responseData.statusCode == 200) {
                            this.selectedCheckboxArr = [];
                            this.allUserStoryChecked = false;
                            this.allUnassociatedUserStoryChecked = false;
                            this.unassociatedData = [];
                            //$('.table-multiple-checkboxes input:checkbox').removeAttr('checked');
                            this.getUserstory();
                            this.getUnassociated();
                            this.toastr.success(responseData.message);
                        } else {
                            this.toastr.error(responseData.message);
                        }
                    }, (err) => {
                        console.log(err);
                        let errorData: any = err;
                        this.toastr.error(errorData.error.message);
                    })
            } else {
                this.toastr.error('Please select ' + entity + ' to remove.');
            }

        }





    }



    addSubTask(subtaskid = "") {
        console.log(this.subTaskForm);
        let apiname = '';
        let data: any;
        if (subtaskid) {
            apiname = 'userStory/task/update';
            data = { 'userStoryTaskId': subtaskid, 'name': this.subTaskForm.get('name').value }
        } else {
            apiname = 'userStory/task/add';
            data = { 'userStoryId': this.userStoryId, 'name': this.subTaskForm.get('name').value }
        }

        this.restService.hitApi(apiname, data, 'post', '', true, false)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    if (subtaskid) {
                        $('#edit_sub_task_web').modal('hide');
                    } else {
                        $('#create_sub_task_web').modal('hide');
                    }
                    this.closeModel();
                    this.getUserstory();
                    this.toastr.success(responseData.message);
                } else {
                    this.toastr.error(responseData.message);
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                this.toastr.error(errorData.error.message);
            })
    }

    deleteSubTask(userStoryTaskId) {
        this.restService.hitApi('userStory/task/delete', { 'userStoryTaskId': userStoryTaskId }, 'post', '', true, false)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    this.getUserstory();
                    this.toastr.success(responseData.message);
                } else {
                    this.toastr.error(responseData.message);
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                this.toastr.error(errorData.error.message);
            })
    }



    addComment(obj, field) {


        let data: any;
        let comment: any;
        let apiname: any;

        if (obj?.id) {
            apiname = 'userStory/updateComment';
            comment = $('#userstory_edit_' + field + obj?.id).val();
            data = { 'userStoryCommentId': obj?.id, 'comment': comment, 'files': obj.imgArr, 'projectId': this.pid }
        } else {
            apiname = 'userStory/createComment';
            comment = $('#userstory_' + field).val();
            data = { 'userStoryId': this.userStoryId, 'comment': comment, 'files': this.imgArr, 'projectId': this.pid }
        }


        this.restService.hitApi(apiname, data, 'post', '', true, false)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    if (obj?.id) {
                        $('#comment' + obj?.id).show();
                        $('#commentinput' + obj?.id).hide();
                    } else {
                        $('#comment').val('');
                        $('userstory_comment').summernote("reset");
                    }
                    this.files = [];
                    this.imgArr = [];
                    this.isAddCommentInputShown = true;
                    if (obj?.id) {
                        this.editCommentForm.reset();
                    } else {
                        this.addCommentForm.reset();
                    }
                    this.getUserstory();
                    this.toastr.success(responseData.message);
                } else {
                    this.toastr.error(responseData.message);
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                this.toastr.error(errorData.error.message);
            })
    }

    deleteComment(userStoryCommentId) {
        this.restService.hitApi('userStory/deleteComment', { "userStoryCommentId": userStoryCommentId }, 'post', '', true, false)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    this.getUserstory();
                    this.toastr.success(responseData.message);
                } else {
                    this.toastr.error(responseData.message);
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                this.toastr.error(errorData.error.message);
            })
    }


    getWatchesUserList() {
        this.restService.hitApi('project/watchlist', { 'projectId': this.pid, 'entity': 'userstory', 'resourceId': this.userStoryId }, 'post', '', true)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    this.watchesUserList = responseData.result;
                    if (this.watchesUserList.length) {
                        this.watchesUserList.forEach(element => {
                            if (element.watcher == element.userId) {
                                element.checked = true;
                            } else {
                                element.checked = false;
                            }

                            if (this.userData) {
                                if (this.userData.role == 2 || this.userData.role == 3) {
                                    if (element.role == 1) {
                                        element.isRemoved = false;
                                    } else {
                                        if (element.userId != this.userData.id) {
                                            element.isRemoved = false;
                                        } else {
                                            element.isRemoved = true;
                                        }
                                    }
                                } else {
                                    if (!this.isOwner) {
                                        if (element.isCompanyOwner == 1) {
                                            if (element.checked) {
                                                element.isRemoved = false;
                                            } else {
                                                element.isRemoved = true;
                                            }

                                        } else {
                                            element.isRemoved = true;

                                        }
                                    } else {
                                        element.isRemoved = true;

                                    }
                                }
                            }
                        });
                    }
                } else {
                    // this.toastr.error(responseData.message);
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                // this.toastr.error(errorData.error.message);
            })
    }

    addRemoveWatch() {
        let selectedCheckboxArr: any = [];
        if (this.watchesUserList.length) {
            this.watchesUserList.forEach(element => {
                if (element.checked) {
                    selectedCheckboxArr.push(element.userId);
                }
            });
        }


        this.restService.hitApi('project/updateWatchlist', { 'projectId': this.pid, 'entity': 'userstory', 'resourceId': this.userStoryId, "userId": selectedCheckboxArr, "action": 'manage' }, 'post', '', true, false)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    this.getWatchesUserList();
                    this.getUserstory();
                    $('#watches_popup_web').modal('hide');
                } else {
                    this.toastr.error(responseData.message);
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                this.toastr.error(errorData.error.message);
            })
    }

    AddRemoveCurrentUserWatcher(action) {
        this.restService.hitApi('project/updateWatchlist', { 'projectId': this.pid, 'entity': 'userstory', 'resourceId': this.userStoryId, "action": action }, 'post', '', true, false)
            .subscribe((result) => {
                let responseData: any = result;
                if (responseData.statusCode == 200) {
                    $('.stop-add-watches').slideUp('fast');
                    this.getUserstory();
                    this.getWatchesUserList();
                } else {
                    this.toastr.error(responseData.message);
                }
            }, (err) => {
                console.log(err);
                let errorData: any = err;
                this.toastr.error(errorData.error.message);
            })
    }

    updateAllUserStory() {
        this.allUserStoryChecked = this.userStoryData?.childUserStories != null && this.userStoryData?.childUserStories.every(t => t.checked);
    }

    someUserStoryComplete(): boolean {
        if (this.userStoryData?.childUserStories == null) {
            return false;
        }
        return this.userStoryData?.childUserStories.filter(t => t.checked).length > 0 && !this.allUserStoryChecked;
    }

    setAllUserStory(checked: boolean) {
        this.allUserStoryChecked = checked;
        if (this.userStoryData?.childUserStories == null) {
            return;
        }
        this.userStoryData?.childUserStories.forEach(t => t.checked = checked);
    }
    resetAllUserStory() {
        if (this.unassociatedData) {
            this.unassociatedData.forEach(element => {
                element.checked = false;
            });
        }

    }

    updateAllUnAssociateUserStory() {
        this.allUnassociatedUserStoryChecked = this.unassociatedData != null && this.unassociatedData.every(t => t.checked);
    }

    someUnAssociateUserStoryComplete() {
        if (this.unassociatedData == null) {
            return false;
        }
        return this.unassociatedData.filter(t => t.checked).length > 0 && !this.allUnassociatedUserStoryChecked;
    }

    setAllUnAssociateUserStory(checked: boolean) {
        this.allUnassociatedUserStoryChecked = checked;
        if (this.unassociatedData == null) {
            return;
        }
        this.unassociatedData.forEach(t => t.checked = checked);
    }

    someWatchComplete(): boolean {
        if (this.watchesUserList == null) {
            return false;
        }
        return this.watchesUserList.filter(t => t.checked).length > 0 && !this.allWatchChecked;
    }

    updateAllUserWatch() {
        this.allWatchChecked = this.watchesUserList != null && this.watchesUserList.every(t => t.checked);
    }


    setAllWatched(checked: boolean) {
        this.allWatchChecked = checked;
        if (this.watchesUserList == null) {
            return;
        }
        this.watchesUserList.forEach(t => t.checked = checked);
    }

    closeModel() {
        this.subTaskForm.reset();
        this.isSubTaskCreateDialogShow = false;
    }


    download(url, fileName, $event) {
        $event.stopPropagation()
        this.restService.downloadFile(url, fileName);
    }

    getExtensionOfFileType(fileName) {
        if (fileName) {
            var str = fileName.split(".");
            if (str[str.length - 1] == 'jpg' || str[str.length - 1] == 'png' || str[str.length - 1] == 'jpeg' || str[str.length - 1] == 'JPG' || str[str.length - 1] == 'PNG') {
                return 'image'
            } else {
                return 'file'
            }

        }
    }

    dueDateChange(event) {
        var strDate;
        if (event.epoc > 0) {
            var date = event.jsdate;
            strDate = this.dateService.convertInDDMMYYYFormate(date)
            this.userStoryData.dueDate = { date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }, formatted: strDate, jsdate: date };;
            //this.myEndDatePickerOptions.disableUntil = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }

        } else {
            this.userStoryData.dueDate = null;
            strDate = null;

        }
        this.updateUserStory('dueDate', strDate);


    }
    backToRetun() {
        this.location.back();
    }

    allWatchDisabled() {
        var isDisable = false;
        if (this.watchesUserList && this.watchesUserList.length > 0) {
            for (var i = 0; i < this.watchesUserList.length; i++) {
                if (!this.watchesUserList[i].isRemoved) {
                    isDisable = true;
                    break;
                }
            }

        }
        return isDisable;
    }

}


