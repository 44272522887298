import { FormGroupDirective, FormControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';


export class MyErrorStateMatcher implements ErrorStateMatcher {

    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        console.log("control in error");
        console.log(control);
        const isSubmitted = form && form.submitted;
        console.log(!!(control && control.invalid && (control.dirty || control.touched || isSubmitted)));
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }

    constructor() { }
}