import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginTwoComponent } from './login/login-two.component';
import { LoginComponent } from './login/login.component';

import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectViewComponent } from './project-view/project-view.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DiscussionBoardComponent } from './discussion-board/discussion-board.component';
import { DiscussionsComponent } from './discussions/discussions.component';
import { DiscussionComponent } from './discussion/discussion.component';
import { LandingComponent } from './landing/landing.component';
import { PricingComponent } from './pricing/pricing.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReleasesComponent } from './releases/releases.component';
import { ReleaseComponent } from './release/release.component';
import { SprintsComponent } from './sprints/sprints.component';
import { SprintComponent } from './sprint/sprint.component';
import { UserstoriesComponent } from './userstories/userstories.component';
import { UserstoryComponent } from './userstory/userstory.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { UpdateCreditCardComponent } from './credit-card/credit-card.component';
import { InvoiceSettingComponent } from './invoice-setting/invoice-setting.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { TasksComponent } from './tasks/tasks.component';
import { TaskComponent } from './task/task.component';
import { SettingsComponent } from './settings/settings.component';
import { SprintBoardComponent } from './sprint-board/sprint-board.component';
import { KanbanBoardComponent } from './kanban-board/kanban-board.component';
import { BacklogComponent } from './backlog/backlog.component';
import { DefectsComponent } from './defects/defects.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { DefectComponent } from './defect/defect.component';
import { TermsComponent } from './terms/terms.component';
import { TestplansComponent } from './testplans/testplans.component';
import { TestplanComponent } from './testplan/testplan.component';
import { TestcasesComponent } from './testcases/testcases.component';
import { TestcaseComponent } from './testcase/testcase.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
//import { PaymentComponent } from './payment/payment.component';
import { SubscriptionFailedComponent } from './subscription-failed/subscription-failed.component';
import { SearchComponent } from './search/search.component';
import { SerachFilterComponent } from './serach-filter/serach-filter.component';
import { DashboardCompanyAdminComponent } from './dashboard-company-admin/dashboard-company-admin.component';
import { DashboardCompanyUserComponent } from './dashboard-company-user/dashboard-company-user.component';
import { CompanyComponent } from './company/company.component';
import { CompanySelectionComponent } from './company-selection/company-selection.component';
import { DontAccessIssueComponent } from './dont-access-issue/dont-access-issue.component';
import { CommonErrorComponent } from './common-error/common-error.component';
import { AddNewDiscussionComponent } from './add-new-discussion/add-new-discussion.component';
import { DiscussionThreadComponent } from './discussion-thread/discussion-thread.component';
import { TestPriceCalculatorComponent } from './test-price-calculator/test-price-calculator.component';
import { TrakkLoaderComponent } from './trakk-loader/trakk-loader.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SearchNotFoundComponent } from './search-not-found/search-not-found.component';
import { nullableTypeAnnotation } from 'node_modules1111/@babel/types/lib';
import { PriceSliderTestComponent } from './price-slider-test/price-slider-test.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PlanExpireComponent } from './plan-expire/plan-expire.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ContactUsHomeComponent } from './contact-us-home/contact-us-home.component';
import { OuterPricingComponent } from './outer-pricing/outer-pricing.component';
import { ProjectCompanySwitchComponent } from './project-company-switch/project-company-switch.component';
import { TimesheetComponent } from './timesheet/timesheet.component';

const routes: Routes = [
  // { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '', component: LandingComponent },
  { path: 'test-price-calculator', component: TestPriceCalculatorComponent },
  { path: 'trakk-loader', component: TrakkLoaderComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'subscription', component: SubscriptionComponent },
  { path: 'login', component: LoginTwoComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'under-construction', component: UnderConstructionComponent },
  { path: 'reset-password/:vlink', component: ResetPasswordComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: TermsComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'price-slider-test', component: PriceSliderTestComponent },
  { path: 'plan-expire', component: PlanExpireComponent },


  { path: 'user-management', component: UserManagementComponent },
  { path: 'user-profile', component: UserProfileComponent },
  { path: 'user-profile/:userid', component: UserProfileComponent },

  { path: 'myaccount', component: MyaccountComponent },
  { path: 'update-credit-card', component: UpdateCreditCardComponent },
  { path: 'invoice-setting', component: InvoiceSettingComponent },
  { path: 'invoices', component: InvoicesComponent },

  { path: 'dont-access-issue', component: DontAccessIssueComponent },

  { path: 'common-error', component: CommonErrorComponent },
  //{ path: 'add-new-discussion', component: AddNewDiscussionComponent },


  { path: 'project-management', component: ProjectListComponent },
  { path: 'project-view/:pid', component: ProjectViewComponent },

  // { path: 'discussion-board', component: DiscussionBoardComponent },

  { path: 'discussions', component: DiscussionsComponent },
  { path: 'project/:pid/discussions', component: DiscussionsComponent },
  { path: 'project/:pid/discussions/new', component: AddNewDiscussionComponent },
  { path: 'project/:pid/discussions/edit/:did', component: AddNewDiscussionComponent },
  { path: 'project/:pid/discussion/:did', component: DiscussionComponent },

  { path: 'project/:pid/discussion-thread/:did', component: DiscussionThreadComponent },


  { path: 'releases', component: ReleasesComponent },
  { path: 'release/:rid', component: ReleaseComponent },

  // { path: 'project/:pid/releases', component: ReleasesComponent },
  // { path: 'project/:pid/release/:rid', component: ReleaseComponent },

  { path: 'sprints', component: SprintsComponent },
  { path: 'sprint/:sid', component: SprintComponent },

  { path: 'backlog', component: BacklogComponent },
  { path: 'project/:pid/backlog', component: BacklogComponent },

  { path: 'sprint-board', component: SprintBoardComponent },
  { path: 'project/:pid/sprint-board', component: SprintBoardComponent },

  { path: 'kanban-board', component: KanbanBoardComponent },
  { path: 'project/:pid/kanban-board', component: KanbanBoardComponent },

  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: 'search-not-found', component: SearchNotFoundComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'header', component: HeaderComponent },
  { path: 'footer', component: FooterComponent },

  { path: 'userstories', component: UserstoriesComponent },
  //{ path: 'project/:pid/userstories', component: UserstoriesComponent },
  { path: 'userstory/:usid', component: UserstoryComponent },

  { path: 'tasks', component: TasksComponent },
  // { path: 'project/:pid/tasks', component: TasksComponent },
  { path: 'task/:tid', component: TaskComponent },
  { path: 'userstory/:usid/task/:tid', component: TaskComponent },


  { path: 'defects', component: DefectsComponent },
  //{ path: 'project/:pid/defects', component: DefectsComponent },
  { path: 'defect/:did', component: DefectComponent },

  { path: 'tasks', component: TasksComponent },
  { path: 'project/:pid/tasks', component: TasksComponent },
  { path: 'project/:pid/task/:tid', component: TaskComponent },

  // { path: 'tasks', component: TasksComponent },
  // { path: 'project/:pid/tasks', component: TasksComponent },
  // { path: 'project/:pid/task/:tid', component: TaskComponent },
  // { path: 'project/:pid/userstory/:usid/task/:tid', component: TaskComponent },

  { path: 'testplans', component: TestplansComponent },
  // { path: 'project/:pid/testplans', component: TestplansComponent },
  { path: 'testplan/:tpid', component: TestplanComponent },

  { path: 'testcases', component: TestcasesComponent },
  //{ path: 'project/:pid/testcases', component: TestcasesComponent },
  { path: 'testcase/:tcid', component: TestcaseComponent },

  { path: 'settings', component: SettingsComponent },
  //{ path: 'payment', component: PaymentComponent },

  { path: 'subscription-failed', component: SubscriptionFailedComponent },
  { path: 'search', component: SearchComponent },

  { path: 'search-filter', component: SerachFilterComponent },
  { path: 'dashboard-company-admin', component: DashboardCompanyAdminComponent },

  { path: 'company', component: CompanyComponent },
  { path: 'company-selection', component: CompanySelectionComponent },
  { path: 'contact-us-home', component: ContactUsHomeComponent },
  { path: 'outer-pricing', component: OuterPricingComponent },
  { path: 'project-company-switch', component: ProjectCompanySwitchComponent },
  { path: 'timesheet', component: TimesheetComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

