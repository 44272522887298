import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
declare const $:any;
@Component({
	selector: 'app-invoices',
	templateUrl: './invoices.component.html',
	styleUrls: ['./invoices.component.scss'],
})

export class InvoicesComponent implements OnInit {
	
	userData : any = JSON.parse(localStorage.getItem("userData"));
	invoicesData : any;
	token : any = JSON.parse(localStorage.getItem('userData')).accessToken;
	isLoading: boolean = false;
	constructor(
		public restService : RestService, 
		private toastr: ToastrService,
		private router : Router,
		private route : ActivatedRoute
		) { 
			this.isLoading = true;
			this.getInvoices();
		}
	
	ngOnInit() {
		
	}

	getInvoices(){
		this.restService.hitApi('users/invoices',{},'get','',true)
      .subscribe((result)=>{
		
        let responseData : any = result;
        if(responseData.statusCode==200){
          this.invoicesData = responseData.result;
        }else{
          // this.toastr.error(responseData.message);
		}
		this.isLoading = false;
      },(err)=>{
		this.isLoading = false;
        console.log(err);
        let errorData : any = err;
        // this.toastr.error(errorData.error.message);
      });
	}
	
}


