import { element } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { Data } from './../ui-components/select-dropdown/Data';

// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup, Form, FormControl } from '@angular/forms';
import { DateConverterService } from '../services/date-formatter/date-converter.service';
import { RouteEventsService } from '../services/route-events.service';
import { Location } from '@angular/common';
import { BroadcastService } from '../services/broadcast/broadcast.service';
import { FileUploadService } from "../file-upload-service";
import { HttpEvent, HttpEventType } from '@angular/common/http';

// import { MatDialog } from '@angular/material';
// import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
declare const $: any;
@Component({
	selector: 'app-task',
	templateUrl: './task.component.html',
	styleUrls: ['./task.component.scss'],
})

export class TaskComponent implements OnInit {
	taskCreateForm: FormGroup;
	taskCommentForm: FormGroup;
	taskData: any;
	pid: any = localStorage.getItem('projectSelected');
	usid: any;
	tid: any;
	taskId: any;
	selectedTask: any;
	projectListData: any;
	projectData: any;
	projectSprintList: any;
	files: any = [];
	imgArr: any = [];
	descriptionFiles: any = [];
	descriptionImgArr: any = [];
	sprintArr: any = [];
	users: any;
	userInfo: any;
	statusData: any;
	userStories: any;
	userData: any = JSON.parse(localStorage.getItem("userData"))
	jqueryRef: any = $;
	watchesUserList: any;
	allWatchChecked: boolean = false;
	addCommentForm: FormGroup;
	editCommentForm: FormGroup;
	taskStatus: FormControl = new FormControl();
	assignee: FormControl = new FormControl();
	backButtonText;
	//shortCode: any;
	sprintList: any;
	sprintDataList: Data[] = [];
	sprintControl = new FormControl();

	taskStatusList = [{ name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }, { name: 'Completed', value: 4 }]
	isOwner: boolean;
	isCompanyAdmin: boolean;
	isProjectManager: boolean;
	selectedTab = 1;
	parentUserStory;
	isTaskDitailReadOnly: boolean = false;
	deletedDescriptionItems: any[] = [];
	deletedAttachmentItems: any[] = [];


	public myDueDatePickerOptions: IMyDpOptions = {
		// other options...
		dateFormat: 'dd-mm-yyyy',
		disableUntil: { year: 0, month: 0, day: 0 },
		disableSince: { year: 0, month: 0, day: 0 }

	};
	isAddCommentInputShown: boolean = true;
	isOther: boolean = false;


	// date: Date = new Date();
	// public myDatePickerOptions: IMyDpOptions = {
	// 	dateFormat: 'dd-mm-yyyy',
	// 	openSelectorTopOfInput : true,
	// 	disableUntil:{year: this.date.getFullYear(), month: this.date.getMonth()+1, day: this.date.getDate()-1}
	// };
	// public myDatePickerOptions2: IMyDpOptions = {
	// 	dateFormat: 'dd-mm-yyyy',
	// 	openSelectorTopOfInput : true,
	// 	disableUntil:{year: this.date.getFullYear(), month: this.date.getMonth()+1, day: this.date.getDate()-1}
	// };

	constructor(
		private restService: RestService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private dateService: DateConverterService,
		private broadcastService: BroadcastService,
		private location: Location,
		private fileUploadService: FileUploadService) {

		// if (this.routeEventsService.previousRoutePath.value.includes('/tasks')) {
		// 	this.backButtonText = 'Back To Tasks List';
		// } else if (this.routeEventsService.previousRoutePath.value.includes('/dashboard')) {
		// 	this.backButtonText = 'Back To Dashboard';
		// } else {
		// 	this.backButtonText = 'Back'
		// }

		if (this.pid && this.pid !== null && this.pid !== undefined) {
			this.getProjectDetail();
		}
		// Check for route params
		this.route.params.subscribe(params => {
			//this.pid = params['pid'];

			this.usid = params['usid'];
			this.tid = params['tid'];
			if (this.tid && this.tid !== null && this.tid !== undefined) {
				this.getTask();
			}
		});

		console.log(this.router.url);
		if (!localStorage.getItem('userData')) {
			localStorage.setItem('urlSession', this.router.url)
			this.router.navigate(['/login']);
		} else {
			this.userList();
			this.userStoryList();
			// this.statusList();
		}

		if (this.userData) {
			this.getUserprofile();
		}

		//this.shortCode = localStorage.getItem('projectShortCode');

		this.broadcastService.subscribe("RELOAD_ENTITY", (id) => {
			this.tid = id
			this.pid = localStorage.getItem('projectSelected');
			if (this.pid && this.tid && this.tid !== null && this.tid !== undefined) {
				this.getTask();
			}
		});

	}

	ngOnInit() {
		let that = this;
		$(document).ready(function () {
			$('.htmlEditor').summernote({
				height: '150px',
				focus: true,
				disableDragAndDrop: true,
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
				callbacks: {
					onPaste: function (e) {
						var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
						e.preventDefault();
						document.execCommand('insertText', false, bufferText);
					}
				}
			});

			$('.editor-textarea-comment').summernote({
				height: '150px'
			});

			$('.date input').datepicker({
				format: 'dd-mm-yyyy',
			}).on('changeDate', function (e) {
				$('.datepicker').hide();
				that.updateTask('dueDate', e.format('dd-mm-yyyy'));
			});
		});

		$(document).mouseup(function (e) {
			if (!$(".work-status-anchor").is(e.target) && $(".work-status-anchor").has(e.target).length === 0 && !$(".progress-work-st").is(e.target) && $(".progress-work-st").has(e.target).length === 0) {
				$(".progress-work-st").slideUp("fast");
			}
			if (!$('.assign-reporter-work').is(e.target) && $('.assign-reporter-work').has(e.target).length === 0 && !$('.assign-reporter-work-open').is(e.target) && $('.assign-reporter-work-open').has(e.target).length === 0) {
				$('.assign-reporter-work-open').slideUp("fast");
			}
		});

		this.createCommentForm(null);
		this.createForm(null);
		this.createCommentForm(null);
		this.getSprintList();



		// this.getProjectList();
	}

	getUserprofile() {
		this.restService.hitApi('user/profile', { 'userId': this.userData.id }, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.userInfo = responseData.result;
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}



	getProjectDetail() {

		if (localStorage.getItem("isOwner") == "true") {
			this.isOwner = true
		} else {
			this.isOwner = false
		}

		if (localStorage.getItem("isCompanyAdmin") == "true") {
			this.isCompanyAdmin = true
		} else {
			this.isCompanyAdmin = false
		}

		if (localStorage.getItem("isProjectManager") == "true") {
			this.isProjectManager = true
		} else {
			this.isProjectManager = false
		}

		if (localStorage.getItem("isOther") == "true") {
			this.isOther = true
		} else {
			this.isOther = false
		}

		// this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', true)
		// 	.subscribe((result) => {
		// 		let responseData: any = result;
		// 		if (responseData.statusCode == 200) {
		// 			this.projectData = responseData.result;
		// 			if (this.projectData?.type == 2) {
		// 				this.taskStatusList = [{ name: 'Backlog', value: 0 }, { name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }, { name: 'Completed', value: 4 }];
		// 			} else {
		// 				this.taskStatusList = [{ name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }, { name: 'Completed', value: 4 }];
		// 			}
		// 			if (this.userData) {
		// 				if (this.userData.isCompanyAdmin == 1) {
		// 					if (this.userData.ownerId) {
		// 						this.isOwner = true;
		// 						this.isCompanyAdmin = false;
		// 						this.isProjectManager = false;
		// 					} else {
		// 						this.isOwner = false;
		// 						this.isCompanyAdmin = true;
		// 						this.isProjectManager = false;
		// 					}
		// 				} else {
		// 					if (this.projectData && this.projectData.projectUsersProject) {
		// 						this.projectData.projectUsersProject.forEach(element => {
		// 							if (element.userId == this.userData.id) {
		// 								if (element.userRoleId == 1) {
		// 									this.isProjectManager = true;

		// 								} else {
		// 									this.isProjectManager = false;
		// 								}
		// 							}
		// 						});

		// 					}
		// 					this.isOwner = false;
		// 					this.isCompanyAdmin = false;
		// 				}
		// 			}
		// 		} else {
		// 			// this.toastr.error(responseData.message);
		// 		}
		// 	}, (err) => {
		// 		console.log(err);
		// 		let errorData: any = err;
		// 		// this.toastr.error(errorData.error.message);
		// 	})
	}

	// getProjectList() {
	// 	this.restService.hitApi('discussion/projects',{},'get','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.projectListData = responseData.result.data;
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }

	// changeProject(pid){
	// 	localStorage.setItem('projectSelected',pid)
	// 	$('.project-title-list').toggle("slide");
	// 	this.router.navigateByUrl('/company/'+pid+'/tasks');
	// }

	toggleList(ele) {
		this.descriptionFiles = [];
		if (!this.isTaskDitailReadOnly) {
			if ('description') {
				$('#task_description').summernote('code', this.taskData.description);
			}
			$('.' + ele).slideToggle("fast");
			if (this.taskData.descriptionAttachments && this.taskData.descriptionAttachments.length > 0) {
				this.taskData.descriptionAttachments.forEach(element => {
					this.descriptionFiles.push({ id: element.id, name: element.displayName, serverFileName: element.fileName, image: element.fileTitle, isProgressBarShown: false });
					//obj.imgArr.push(element.displayName);
				});
				// 	}
			} else {
				//$('.' + ele + '_' + obj.id).slideUp("fast");
				this.descriptionFiles = [];
				this.descriptionImgArr = [];

			}
		}
	}



	showedit() {
		if (!this.isTaskDitailReadOnly) {
			$('.heading-editable h1').hide("fast");
			$('.heading-area').show("fast");
			$('.tick-cancel-input-filed').show("fast");
		}
	}
	hideedit() {
		$('.heading-editable h1').show("fast");
		$('.heading-area').hide("fast");
		$('.tick-cancel-input-filed').hide("fast");
	}

	userList() {


		this.restService.hitApi('project/projectUsers', {
			projectId: this.pid,
			escape: [2]
		}, 'post', '', false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {

					var list = responseData.result;
					this.users = [{ userId: 0, name: 'Unassigned' }];
					list.forEach(element => {
						this.users.push(element);
					});

					if (this.taskData && this.taskData.assignee && this.users) {
						this.users.forEach(element => {
							if (this.taskData.assignee != null) {
								if (element.userId == this.taskData.assignee) {
									this.assignee.setValue(element);

								}
							} else {
								this.assignee.setValue(this.users[0]);

							}

						});
						console.log('assignee', this.assignee);
					} else {
						this.assignee.setValue(this.users[0]);
					}

					this.taskData.previousAssignee = this.assignee?.value.userId;



					// this.setAutoSuggest(null);
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	userStoryList() {
		this.restService.hitApi('userStory/list', { 'projectId': this.pid }, 'post', '', false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.userStories = responseData.result.data;
					if (this.userStories) {
						for (var i = 0; i < this.userStories.length; i++) {
							var element = this.userStories[i];
							if (element.id == this.usid) {
								this.parentUserStory = element;
								break;
							}
						}
						if (this.parentUserStory && this.parentUserStory.dueDate) {
							var d = Date.parse(this.parentUserStory.dueDate);
							var dueDate = new Date(d);

							if (dueDate) {
								this.disableEndUntil(dueDate);
							}
							if (this.parentUserStory.status == 4) {
								this.isTaskDitailReadOnly = true;
							} else {
								this.isTaskDitailReadOnly = false;
							}
						}
					}
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	// statusList(){
	// 	this.restService.hitApi('status/list',{},'post','',false)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.statusData = responseData.result.data;
	// 		}else{
	// 			this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		this.toastr.error(errorData.error.message);
	// 	})
	// }

	createForm(data) {

		this.taskCreateForm = this.formBuilder.group({
			'title': [data ? data.title : '', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
			'userStoryId': [data ? data.userstoryId : ''],
			'description': [data ? data.description : ''],
			'comment': [data ? data.comment : ''],
			'sDate': [data ? data.startDate : ''],
			'eDate': [data ? data.endDate : ''],
			'projectTaskId': [data ? data.id : ''],
			'status': [data ? data.status : 1, [Validators.required]],
			'attachments': [''],
			'assignee': [data ? data.assignee : ''],
			'oldattachments': [data ? data.attachments : ''],
			'sprintId': [data ? data.sprintId : ''],
			'sprintName': [data ? data.sprintName : ''],
		});


		// if(data){
		// 	let startDate = new Date(data.startDate);
		// 	this.taskCreateForm.patchValue({sDate: {
		// 		date: {
		// 			year: startDate.getFullYear(),
		// 			month: startDate.getMonth() + 1,
		// 			day: startDate.getDate()}
		// 	}});
		// 	let endDate = new Date(data.endDate);
		// 	this.taskCreateForm.patchValue({eDate: {
		// 		date: {
		// 			year: endDate.getFullYear(),
		// 			month: endDate.getMonth() + 1,
		// 			day: endDate.getDate()}
		// 	}});

		// }

	}

	createCommentForm(comment) {
		if (comment) {
			this.editCommentForm = this.formBuilder.group({
				'comment': [comment],
			});
		} else {
			this.addCommentForm = this.formBuilder.group({
				'comment': [''],
			});
			this.editCommentForm = this.formBuilder.group({
				'comment': [''],
			});
		}


	}

	// createCommentForm(data){
	// 	console.log(data);
	// 	$(window).scrollTop($('#comment').offset().top);
	// 	this.taskCommentForm = this.formBuilder.group({
	// 		'taskCommentId':[data?data.id:''],
	// 		'comment':[data?data.comment:'',[Validators.required]]
	// 	});
	// }

	// onDateChanged(event: IMyDateModel) {
	// 	console.log(event.date);
	// 	this.myDatePickerOptions2 = {
	// 		disableUntil:event.date
	// 	};
	// }

	updateTask(field, value = "") {

		let data: any;
		let apiname: any;
		let entityname: any;
		if (this.usid) {
			apiname = 'userStory/task/update';
		} else {
			apiname = 'projectTask/update';
		}
		console.log("assignee", this.assignee);

		if (this.usid) {
			if (field == 'title') {
				value = $('#task_' + field).val();
				if (value != "") {
					if (value != this.taskData.name) {
						data = { 'name': value, 'userStoryTaskId': this.taskId, 'projectId': this.pid };
					}
				} else {
					this.toastr.error("Task name is required");
					return
				}

			} else if (field == 'description') {
				value = $('#task_' + field).val();
				data = { 'description': value, 'userStoryTaskId': this.taskId, 'projectId': this.pid, 'files': this.descriptionImgArr };
			} else if (field == 'sprintId') {
				if (value != this.taskData.sprintId) {
					data = { 'sprintId': value, 'userStoryTaskId': this.taskId, 'projectId': this.pid };
				}
			} else if (field == 'assignee') {
				if (value != this.taskData.previousAssignee) {
					data = { 'assignee': [value], 'userStoryTaskId': this.taskId, 'projectId': this.pid };
				}

			} else if (field == 'dueDate') {
				if (this.taskData.previousDueDate) {
					if (value != this.taskData.previousDueDate) {
						data = { 'dueDate': value, 'userStoryTaskId': this.taskId, 'projectId': this.pid };
					}
				} else {
					data = { 'dueDate': value, 'userStoryTaskId': this.taskId, 'projectId': this.pid };
				}
			} else if (field == 'status') {
				if (value != this.taskData.status) {
					data = { 'status': value, 'userStoryTaskId': this.taskId, 'projectId': this.pid };
				}
			} else if (field == 'estHours') {
				value = $('#task_' + field).val();
				if (value != this.taskData.estHours) {
					data = { 'estHours': value, 'userStoryTaskId': this.taskId, 'projectId': this.pid };
				}
			} else if (field == 'estMins') {
				value = $('#task_' + field).val();
				if (value != this.taskData.estMins) {

					data = { 'estMins': value, 'userStoryTaskId': this.taskId, 'projectId': this.pid };
				}
			} else if (field == 'loggedHours') {
				let value1 = $('#task_loggedHours').val();
				let value2 = $('#task_loggedMins').val();
				data = { 'loggedHours': value1 ? value1 : 0, 'loggedMins': value2 ? value2 : 0, 'userStoryTaskId': this.taskId, 'projectId': this.pid };

			}
		} else {
			if (field == 'title') {
				value = $('#task_' + field).val();
				if (value != "") {
					if (value != this.taskData.name) {
						data = { 'title': value, 'projectTaskId': this.taskId, 'projectId': this.pid };
					}
				} else {
					this.toastr.error("Task name is required");
					return
				}
			} else if (field == 'description') {
				value = $('#task_' + field).val();
				data = { 'description': value, 'projectTaskId': this.taskId, 'projectId': this.pid, 'files': this.descriptionImgArr };
			} else if (field == 'sprintId') {
				if (value != this.taskData.sprintId) {
					data = { 'sprintId': value, 'projectTaskId': this.taskId, 'projectId': this.pid };
				}
			} else if (field == 'assignee') {
				if (value != this.taskData.previousAssignee) {
					data = { 'assignee': [value], 'projectTaskId': this.taskId, 'projectId': this.pid };
				}
			} else if (field == 'dueDate') {
				if (this.taskData.previousDueDate) {
					if (value != this.taskData.previousDueDate) {
						data = { 'dueDate': value, 'projectTaskId': this.taskId, 'projectId': this.pid };
					}
				} else {
					data = { 'dueDate': value, 'projectTaskId': this.taskId, 'projectId': this.pid };
				}

			} else if (field == 'status') {
				if (value != this.taskData.status) {
					data = { 'status': value, 'projectTaskId': this.taskId, 'projectId': this.pid };
				}
			} else if (field == 'estHours') {
				value = $('#task_' + field).val();
				if (value != this.taskData.estHours) {
					data = { 'estHours': value, 'projectTaskId': this.taskId, 'projectId': this.pid };
				}
			} else if (field == 'estMins') {
				value = $('#task_' + field).val();
				if (value != this.taskData.estMins) {
					data = { 'estMins': value, 'projectTaskId': this.taskId, 'projectId': this.pid };
				}
			} else if (field == 'loggedHours') {
				let value1 = $('#task_loggedHours').val();
				let value2 = $('#task_loggedMins').val();
				data = { 'loggedHours': value1 ? value1 : 0, 'loggedMins': value2 ? value2 : 0, 'projectTaskId': this.taskId, 'projectId': this.pid };
			}
		}


		if (data != null) {
			this.restService.hitApi(apiname, data, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.toastr.success(responseData.message);
						if (field == 'title') {
							$('.heading-editable h1').show("fast");
							$('.heading-area').hide("fast");
							$('.tick-cancel-input-filed').hide("fast");
						} else if (field == 'description') {
							this.descriptionImgArr = [];
							this.descriptionFiles = [];
							$('.description').slideToggle("fast");
						} else if (field == 'status') {
							$('.progress-work-st-js').hide("fast");
						} else if (field == 'sprintId') {
							$('.sprint-reporter-work-open').slideToggle("fast");
						} else if (field == 'assignee') {
							$('.assign-reporter-work-open').slideToggle("fast");
						} else if (field == 'loggedHours') {
							$('#task_loggedHours').val('');
							$('#task_loggedMins').val('');
							$('#hours-logged').modal("hide");
						} else if (field == 'dueDate') {
							$('.datepicker').hide();
						}


						this.getTask();
						// $('#create_release_popup_web').modal('hide');
						// this.files = [];
					} else {
						// this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					// this.toastr.error(errorData.error.message);
				})
		} else {
			if (field == 'name') {
				$('.heading-editable h1').show("fast");
				$('.heading-area').hide("fast");
				$('.tick-cancel-input-filed').hide("fast");
			}
		}

	}

	getTask() {
		let apiname: any = '';
		let data: any;
		if (this.usid) {
			apiname = 'userStory/task/detail';
			data = { 'projectId': this.pid, 'userStoryTaskId': this.tid }
		} else {
			apiname = 'projectTask/details';
			data = { 'projectId': this.pid, 'projectTaskId': this.tid };
		}
		this.restService.hitApi(apiname, data, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.taskData = responseData.result;
					this.taskId = this.taskData.id;
					if (this.usid) {
						if (this.taskData.status == 4) {
							this.isTaskDitailReadOnly = true;
						} else {
							this.isTaskDitailReadOnly = false;
						}
					} else {
						this.isTaskDitailReadOnly = false;
					}


					if (this.taskData.dueDate) {
						var d = Date.parse(this.taskData.dueDate);
						var dueDate = new Date(d);
						var date: string = this.dateService.convertInDDMMYYYFormate(dueDate);
						this.taskData.previousDueDate = date;
						this.taskData.dueDate = { date: { year: dueDate.getFullYear(), month: dueDate.getMonth() + 1, day: dueDate.getDate() }, formatted: date, jsdate: dueDate };


					}

					if (this.taskData.status) {
						this.taskStatusList.forEach(element => {
							if (element.value == this.taskData.status) {
								this.taskStatus.setValue(element);

							}
						})
					} else {
						this.taskStatus.setValue(this.taskStatusList[0]);
					}

					if (this.taskData.estMins <= 0) {
						$('#task_estMins').val(null);

					}
					if (this.taskData && this.taskData.assignee && this.users) {
						this.users.forEach(element => {
							if (this.taskData.assignee != null) {
								if (element.userId == this.taskData.assignee) {
									this.assignee.setValue(element);

								}
							} else {
								this.assignee.setValue(this.users[0]);

							}

						});
					} else {
						this.assignee.setValue(this.users[0]);
					}

					if (this.taskData && this.taskData.sprintId) {
						this.projectSprintList?.forEach(element => {
							if (element.id == this.projectSprintList.sprintId) {
								this.sprintControl.setValue(element);

							}
						});
					}

					// if (this.taskData && this.taskData.projectTaskUser && this.users) {
					// 	this.users.forEach(element => {
					// 		if (this.taskData.projectTaskUser[0].userId == null) {
					// 			if (element.userId == this.taskData.projectTaskUser[0].userId) {
					// 				this.assignee.setValue(element);

					// 			}
					// 		} else {
					// 			this.assignee.setValue(this.users[0]);

					// 		}

					// 	});
					// 	console.log('assignee', this.assignee);
					// } else {
					// 	if (this.taskData && this.taskData.assignee) {
					// 		this.users.forEach(element => {
					// 			if (element.userId == this.taskData.assignee) {
					// 				this.assignee.setValue(element);

					// 			}
					// 		});
					// 	} else {
					// 		if (this.users && this.users.length > 0) {
					// 			this.assignee.setValue(this.users[0]);
					// 		}
					// 	}
					// }

					this.taskData.previousAssignee = this.assignee?.value.userId;

					if (this.taskData && this.taskData.sprintId) {
						this.sprintList.forEach(element => {
							if (element.id == this.taskData.sprintId) {
								this.sprintControl.setValue(element);

							}
						});
					}

					this.createForm(this.taskData);
					this.getSprintList();
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	deleteTask(tid) {
		let apiname: any = '';
		let data: any;
		if (this.usid) {
			apiname = 'userStory/task/delete';
			data = { 'userStoryTaskId': tid };
		} else {
			apiname = 'projectTask/delete';
			data = { 'projectTaskId': tid };
		}
		this.restService.hitApi(apiname, data, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.toastr.success(responseData.message);
					$('#delete-record').modal('hide');
					if (this.usid) {
						this.router.navigateByUrl('/project/' + this.pid + '/userstory/' + this.usid);
					} else {
						this.router.navigateByUrl('/tasks');
					}
				} else {
					$('#delete-record').modal('hide');
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				$('#delete-record').modal('hide');
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	getProjectSprints() {
		this.restService.hitApi('task/projectSprints', { 'projectId': this.pid, notCompleted: 1 }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.projectSprintList = responseData.result.data;

					if (this.taskData && this.taskData.sprintId) {
						this.projectSprintList.forEach(element => {
							if (element.id == this.taskData.sprintId) {
								this.sprintControl.setValue(element);
							}
						});
					}

				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	unCheckRemainingSprint(sprint) {

		if (this.sprintList && this.sprintList.length) {
			this.sprintList.forEach(element => {
				if (element.id != sprint.id) {
					element.checked = false;
				}
			});
		}
	}

	sprintSelected() {
		var isSprintSelected = false;
		if (this.sprintList && this.sprintList.length > 0) {
			for (var i = 0; i < this.sprintList.length; i++) {
				if (this.sprintList[i].checked == true) {
					isSprintSelected = true;
					break;
				} else {
					isSprintSelected = false;
				}
			}
		}

		return isSprintSelected;
	}

	getSprintList() {
		this.restService.hitApi('userStory/sprintList', { 'projectId': this.pid, notCompleted: 1 }, 'post', '', false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.sprintList = responseData.result;
					//sprintDataList
					this.sprintList.forEach(element => {
						let obj = new Data(element.name, element.id);
						this.sprintDataList.push(obj);
					});

					if (this.taskData && this.taskData.sprintId) {
						this.sprintList.forEach(element => {
							if (element.id == this.taskData.sprintId) {
								this.sprintControl.setValue(element);

							}
						});
					}

				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	getSprintType(obj: Data) {
		if (obj != null) {
			this.taskCreateForm.get("sprintId").setValue(obj.value);
			this.taskCreateForm.get("sprintName").setValue(obj.name);
		}
	}


	checkUncheckAll(ele, classname = '') {
		if (classname) {
			var checkboxes = $("." + classname + " input");
		} else {
			var checkboxes = $(".multiple-users-email-checkboxes input");
		}

		if (ele) {
			for (var i = 0; i < checkboxes.length; i++) {
				// if (checkboxes[i].type == 'checkbox') {
				checkboxes[i].checked = true;
				// }
			}
		} else {
			for (var i = 0; i < checkboxes.length; i++) {
				console.log(i)
				// if (checkboxes[i].type == 'checkbox') {
				checkboxes[i].checked = false;
				// }
			}
		}
	}


	// removeReleaseSprints() {
	// 	console.log("coming");
	// 	let that = this;
	// 	$('.sprint-checkbox').find('input').each(function () {
	// 		console.log($(this).val());
	// 		if ($(this).prop("checked")) {
	// 			that.sprintArr.push($(this).val());
	// 		}
	// 	});

	// 	console.log(this.sprintArr);

	// 	this.restService.hitApi('release-sprint/delete', { 'releaseSprintId': this.taskId }, 'post', '', true)
	// 		.subscribe((result) => {
	// 			let responseData: any = result;
	// 			if (responseData.statusCode == 200) {
	// 				this.getTask();
	// 				this.toastr.success(responseData.message);
	// 			} else {
	// 				this.toastr.error(responseData.message);
	// 			}
	// 		}, (err) => {
	// 			console.log(err);
	// 			let errorData: any = err;
	// 			this.toastr.error(errorData.error.message);
	// 		})
	// }


	uploadFile(event) {
		console.log("file event", event);
		var i;
		for (let index = 0; index < event.length; index++) {

			let file: File = event[index];
			i = index;
			if (this.restService.checkFileSize(file, 50)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				if (this.usid) {
					formData.append('entity', 'userStoryTask');
				} else {
					formData.append('entity', 'task');
				}
				formData.append('entityId', this.taskId);
				formData.append('file', file);

				var reader = new FileReader();
				const this_ = this;
				var obj;
				reader.onload = function (e: any) {
					obj = { serverFileName: file.name, name: file.name, image: e.target.result, isProgressBarShown: true }
					this_.files.push(obj);
				}

				reader.readAsDataURL(file);

				//this.files.push({ index: index, localFileName: file.name, serverFileName: null, isProgressBarShown: true });

				// this.files.push(file.name);

				this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
					.subscribe((event: HttpEvent<any>) => {
						switch (event.type) {
							case HttpEventType.Sent:
								console.log('Request has been made!');
								break;
							case HttpEventType.ResponseHeader:
								console.log('Response header has been received!');
								break;
							case HttpEventType.UploadProgress:
								this.files[this.files.length - 1].progress = Math.round(event.loaded / event.total * 100);
								console.log(`Uploaded! ${this.files[this.files.length - 1].progress}%`);
								$('#add_comment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');

								break;
							case HttpEventType.Response:
								let responseData: any = event.body;
								if (responseData.statusCode == 200) {
									this.imgArr.push(responseData.result);
									this.files[this.files.length - 1].serverFileName = responseData.result.fileTitle;
									this.files[this.files.length - 1].displayName = responseData.result.displayName;
									this.files.forEach(element => {
										element.isProgressBarShown = false;
									});
								} else {
									if (responseData.message) {
										this.toastr.error(responseData.message);
									}
								}
								console.log('Upload successfully', event.body);
								setTimeout(() => {
									this.files[this.files.length - 1].progress = 0;
									$('#add_comment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');

								}, 1500);


						}
					})

				// this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', false, false)
				// 	.subscribe((result) => {
				// 		let responseData: any = result;
				// 		if (responseData.statusCode == 200) {
				// 			this.imgArr.push(responseData.result);
				// 			this.files[this.files.length - 1].serverFileName = responseData.result.fileTitle;
				// 			this.files[this.files.length - 1].displayName = responseData.result.displayName;
				// 			this.files.forEach(element => {
				// 				element.isProgressBarShown = false;
				// 			});

				// 		} else {
				// 			this.toastr.error(responseData.message);
				// 		}
				// 	}, (err) => {
				// 		console.log(err);
				// 		let errorData: any = err;
				// 		//this.files[index].isProgressBarShown = false;
				// 		this.toastr.error(errorData.error.message);
				// 	})
			}
		}

	}

	uploadCommentAttachment(event, commentObj) {
		var i;
		for (let index = 0; index < event.length; index++) {
			i = index;
			let file: File = event[index];
			if (this.restService.checkFileSize(file, 50)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				if (this.usid) {
					formData.append('entity', 'userStoryTask');
				} else {
					formData.append('entity', 'task');
				}
				formData.append('entityId', this.taskId);
				formData.append('file', file);

				// const element = event[index];


				var reader = new FileReader();
				const this_ = this;
				var obj;
				reader.onload = function (e: any) {
					obj = { name: file.name, serverFileName: file.name, image: e.target.result, isProgressBarShown: true }
					commentObj.files.push(obj);
				}

				reader.readAsDataURL(file);
				this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
					.subscribe((event: HttpEvent<any>) => {
						switch (event.type) {
							case HttpEventType.Sent:
								console.log('Request has been made!');
								break;
							case HttpEventType.ResponseHeader:
								console.log('Response header has been received!');
								break;
							case HttpEventType.UploadProgress:
								commentObj.files[commentObj.files.length - 1].progress = Math.round(event.loaded / event.total * 100);
								console.log(`Uploaded! ${commentObj.files[commentObj.files.length - 1].progress}%`);
								$('#edit_comment_progress_bar_' + (commentObj.files.length - 1)).attr('aria-valuenow', commentObj.files[commentObj.files.length - 1].progress).css('width', commentObj.files[commentObj.files.length - 1].progress + '%');

								break;
							case HttpEventType.Response:
								let responseData: any = event.body;
								if (responseData.statusCode == 200) {
									commentObj.imgArr.push(responseData.result);
									commentObj.files[commentObj.files.length - 1].serverFileName = responseData.result.fileTitle;
									commentObj.files[commentObj.files.length - 1].displayName = responseData.result.displayName;
									commentObj.files.forEach(element => {
										element.isProgressBarShown = false;
									});
								} else {
									if (responseData.message) {
										this.toastr.error(responseData.message);
									}
								}
								console.log('User successfully created!', event.body);
								setTimeout(() => {
									commentObj.files[commentObj.files.length - 1].progress = 0;
									$('#edit_comment_progress_bar_' + (commentObj.files.length - 1)).attr('aria-valuenow', commentObj.files[commentObj.files.length - 1].progress).css('width', commentObj.files[commentObj.files.length - 1].progress + '%');

								}, 1500);
						}
					})

				// this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', false, false)
				// 	.subscribe((result) => {
				// 		let responseData: any = result;
				// 		if (responseData.statusCode == 200) {

				// 			commentObj.imgArr.push(responseData.result);
				// 			commentObj.files[commentObj.files.length - 1].serverFileName = responseData.result.fileTitle;
				// 			commentObj.files[commentObj.files.length - 1].displayName = responseData.result.displayName;

				// 			commentObj.files.forEach(element => {
				// 				element.isProgressBarShown = false;
				// 			});

				// 		} else {
				// 			this.toastr.error(responseData.message);
				// 		}
				// 	}, (err) => {
				// 		console.log(err);
				// 		let errorData: any = err;
				// 		this.toastr.error(errorData.error.message);
				// 	})
			}
		}
	}
	uploadDescriptionFile(event) {
		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];
			if (this.restService.checkFileSize(file, 50)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				if (this.usid) {
					formData.append('entity', 'userStoryTask');
				} else {
					formData.append('entity', 'task');
				}
				formData.append('entityId', this.tid);
				formData.append('file', file);
				var reader = new FileReader();
				const this_ = this;
				var obj;
				reader.onload = function (e: any) {
					obj = { serverFileName: file.name, name: file.name, image: e.target.result, isProgressBarShown: true }
					this_.descriptionFiles.push(obj);
				}

				reader.readAsDataURL(file);

				this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
					.subscribe((event: HttpEvent<any>) => {
						switch (event.type) {
							case HttpEventType.Sent:
								console.log('Request has been made!');
								break;
							case HttpEventType.ResponseHeader:
								console.log('Response header has been received!');
								break;
							case HttpEventType.UploadProgress:
								this.descriptionFiles[this.descriptionFiles.length - 1].progress = Math.round(event.loaded / event.total * 100);
								console.log(`Uploaded! ${this.descriptionFiles[this.descriptionFiles.length - 1].progress}%`);
								$('#discription_progress_bar_' + (this.descriptionFiles.length - 1)).attr('aria-valuenow', this.descriptionFiles[this.descriptionFiles.length - 1].progress).css('width', this.descriptionFiles[this.descriptionFiles.length - 1].progress + '%');

								break;
							case HttpEventType.Response:
								let responseData: any = event.body;
								if (responseData.statusCode == 200) {
									this.descriptionImgArr.push(responseData.result);
									this.descriptionFiles[this.descriptionFiles.length - 1].serverFileName = responseData.result.fileTitle;
									this.descriptionFiles[this.descriptionFiles.length - 1].displayName = responseData.result.displayName;
									this_.descriptionFiles.forEach(element => {
										element.isProgressBarShown = false;
									});
									//this.getSprint();
								} else {
									if (responseData.message) {
										this.toastr.error(responseData.message);
									}
								}
								setTimeout(() => {
									this.descriptionFiles[this.descriptionFiles.length - 1].progress = 0;
									$('#discription_progress_bar_' + (this.descriptionFiles.length - 1)).attr('aria-valuenow', this.descriptionFiles[this.descriptionFiles.length - 1].progress).css('width', this.descriptionFiles[this.descriptionFiles.length - 1].progress + '%');

								}, 1500);
						}
					})


				// this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', false, false)
				// 	.subscribe((result) => {
				// 		let responseData: any = result;
				// 		if (responseData.statusCode == 200) {
				// 			this.descriptionImgArr.push(responseData.result);
				// 			this.descriptionFiles[this.descriptionFiles.length - 1].serverFileName = responseData.result.fileTitle;
				// 			this.descriptionFiles[this.descriptionFiles.length - 1].displayName = responseData.result.displayName;
				// 			this_.descriptionFiles.forEach(element => {
				// 				element.isProgressBarShown = false;
				// 			});
				// 			//this.getSprint();
				// 		} else {
				// 			this.toastr.error(responseData.message);
				// 		}
				// 	}, (err) => {
				// 		console.log(err);
				// 		let errorData: any = err;
				// 		this.toastr.error(errorData.error.message);
				// 	})
			}
		}

	}


	saveFile() {
		var fileArray = [];
		if (this.files) {
			this.files.forEach(element => {
				if (element.displayName) {
					fileArray.push({ 'fileTitle': element.serverFileName, 'displayName': element.displayName });
				}
				else {
					fileArray.push(element.serverFileName);
				}
			});
		}
		if (fileArray.length > 0) {
			var json = {
				projectId: this.pid,
				entity: this.usid ? "userStoryTask" : "task",
				entityId: this.taskId,
				files: fileArray
			}

			this.restService.hitApi('project/justSaveFiles', json, 'post', '', true, false)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.getTask();
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})

		}
	}
	removeAll() {
		this.deletedAttachmentItems = [];
		this.files.forEach(element => {
			this.deletedAttachmentItems.push(element.serverFileName);
		});

		if (this.deletedAttachmentItems.length > 0) {
			this.removeAttachmentFromServer(null, null);
		}
	}

	removeDescriptionAttachment(fileObj, index) {
		this.deletedDescriptionItems.push(fileObj.serverFileName);
		this.descriptionFiles.splice(index, 1);
		this.descriptionImgArr.splice(index, 1);

	}
	updateDescriptionAttachment() {
		if (this.deletedDescriptionItems && this.deletedDescriptionItems.length > 0) {
			this.removeDescriptionAttachmentFromServer();
		} else {
			this.updateTask('description');
		}
	}

	updateAttachment(obj, field) {
		if (this.deletedAttachmentItems && this.deletedAttachmentItems.length > 0) {
			this.removeAttachmentFromServer(obj, field);
		} else {
			this.addComment(obj, field);
		}
	}

	removeDescriptionAttachmentFromServer() {

		var json = {
			projectId: this.pid,
			entity: this.usid ? 'userStoryTask' : 'task',
			files: this.deletedDescriptionItems
		}

		this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.deletedDescriptionItems = [];
					this.updateTask('description');
				} else {
					this.toastr.success(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	removeAttachment(fileObj, index, commentObj) {
		this.deletedAttachmentItems.push(fileObj.serverFileName)

		if (commentObj != undefined && commentObj != null) {
			commentObj.files.splice(index, 1);
			commentObj.imgArr.splice(0, 1);

		} else {
			this.files.splice(index, 1);
			this.imgArr.splice(0, 1);
		}

	}

	removeAttachmentFromServer(obj, field) {
		var json = {
			projectId: this.pid,
			entity: this.usid ? 'userStoryTask' : 'task',
			files: this.deletedAttachmentItems
		}

		this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.deletedDescriptionItems = [];
					if (obj && field) {
						this.addComment(obj, field);
					}
				} else {
					this.toastr.success(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	deleteCommentAttachment(files) {
		var json = {
			projectId: this.pid,
			entity: this.usid ? 'userStoryTask' : 'task',
			files: files
		}

		this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
				} else {

				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}



	deleteAttachment(aid, index) {
		var url = this.usid ? 'userStory/task/delete-file' : 'projectTask/delete-file';
		this.restService.hitApi(url, { "attachmentId": aid, "projectId": this.pid }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					if (index) {
						this.taskCreateForm.value.oldattachments.splice(index, 1);
					}
					this.getTask();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.success(responseData.message);
				}
			})
	}



	addComment(obj, field) {

		let data: any;
		let comment: any;
		let apiname: any;

		if (obj?.id) {
			if (this.usid) {
				apiname = 'userStory/task/comment/update';
			} else {
				apiname = 'projectTask/comment/update';
			}
			comment = $('#task_edit_' + field + obj?.id).val();
			data = { 'taskCommentId': obj?.id, 'comment': comment, 'files': obj.imgArr, 'projectId': this.pid }

		} else {
			if (this.usid) {
				apiname = 'userStory/task/comment/add';
			} else {
				apiname = 'projectTask/comment/create';
			}
			comment = $('#task_' + field).val();
			data = { 'projectTaskId': this.taskId, 'comment': comment, 'files': this.imgArr, 'projectId': this.pid }

		}


		if (comment == '') {
			this.toastr.error('Please enter comment.');
		} else {

			this.restService.hitApi(apiname, data, 'post', '', true, false)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						if (obj?.id) {
							$('#comment' + obj?.id).show();
							$('#commentinput' + obj?.id).hide();

						} else {
							$('#task_comment').val('');
							$('#task_comment').summernote("reset");
						}
						this.isAddCommentInputShown = true;
						if (obj?.id) {
							this.editCommentForm.reset();
						} else {
							this.addCommentForm.reset();
						}
						this.files = [];
						this.imgArr = [];
						this.getTask();
						this.toastr.success(responseData.message);
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}
	}

	deleteComment(taskCommentId) {
		let apiname: any;
		if (this.usid) {
			apiname = 'userStory/task/comment/delete';
		} else {
			apiname = 'projectTask/comment/delete';
		}
		this.restService.hitApi(apiname, { "taskCommentId": taskCommentId }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getTask();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}


	// addComment(){
	// 	this.taskCommentForm.value.projectTaskId = this.tid;
	// 	let apiname = this.taskCommentForm.value.taskCommentId?'projectTask/comment/update':'projectTask/comment/create';
	// 	this.restService.hitApi(apiname,this.taskCommentForm.value,'post')
	// 	.subscribe((result)=>{

	// 		let responseData:any = result;
	// 		if(responseData.statusCode==200){
	// 			this.taskCommentForm.reset();
	// 			this.getTask();
	// 			this.toastr.success(responseData.message);
	// 		}else{
	// 			this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		let errorData : any = err;
	// 		this.toastr.error(errorData.err.message);
	// 	})
	// }

	// deleteComment(cid){
	// 	this.restService.hitApi('projectTask/comment/delete',{'taskCommentId':cid},'post')
	// 	.subscribe((result)=>{
	// 		let responseData:any = result;
	// 		if(responseData.statusCode==200){
	// 			this.getTask();
	// 			this.toastr.success(responseData.message);
	// 		}else{
	// 			this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		let errorData : any = err;
	// 		this.toastr.error(errorData.err.message);
	// 	})
	// }

	getWatchesUserList() {
		let data: any;
		if (this.usid) {
			data = { 'projectId': this.pid, 'entity': 'user_story_task', 'resourceId': this.taskId }
		} else {
			data = { 'projectId': this.pid, 'entity': 'task', 'resourceId': this.taskId }
		}
		this.restService.hitApi('project/watchlist', data, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.watchesUserList = responseData.result;
					if (this.watchesUserList.length) {
						this.watchesUserList.forEach(element => {
							if (element.watcher == element.userId) {
								element.checked = true;
							} else {
								element.checked = false;
							}

							if (this.userData) {
								if (this.userData.role == 2 || this.userData.role == 3) {
									if (element.role == 1) {
										element.isRemoved = false;
									} else {
										if (element.userId != this.userData.id) {
											element.isRemoved = false;
										} else {
											element.isRemoved = true;
										}
									}
								} else {
									if (!this.isOwner) {
										if (element.isCompanyOwner == 1) {
											if (element.checked) {
												element.isRemoved = false;
											} else {
												element.isRemoved = true;
											}

										} else {
											element.isRemoved = true;

										}
									} else {
										element.isRemoved = true;

									}
								}
							}
						});
					}
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	addRemoveWatch() {
		let selectedCheckboxArr: any = [];
		if (this.watchesUserList.length) {
			this.watchesUserList.forEach(element => {
				if (element.checked) {
					selectedCheckboxArr.push(element.userId);
				}
			});
		}

		let data: any;
		if (this.usid) {
			data = { 'projectId': this.pid, 'entity': 'user_story_task', 'resourceId': this.taskId, "userId": selectedCheckboxArr, "action": 'manage' }
		} else {
			data = { 'projectId': this.pid, 'entity': 'task', 'resourceId': this.taskId, "userId": selectedCheckboxArr, "action": 'manage' }
		}

		this.restService.hitApi('project/updateWatchlist', data, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getTask();
					this.getWatchesUserList();
					$('#watches_popup_web').modal('hide');
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	AddRemoveCurrentUserWatcher(action) {
		let data: any;
		if (this.usid) {
			data = { 'projectId': this.pid, 'entity': 'user_story_task', 'resourceId': this.taskId, "action": action }
		} else {
			data = { 'projectId': this.pid, 'entity': 'task', 'resourceId': this.taskId, "action": action }
		}
		this.restService.hitApi('project/updateWatchlist', data, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('.stop-add-watches').slideUp('fast');
					this.getTask();
					this.getWatchesUserList();
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	someWatchComplete(): boolean {
		if (this.watchesUserList == null) {
			return false;
		}
		return this.watchesUserList.filter(t => t.checked).length > 0 && !this.allWatchChecked;
	}

	updateAllUserWatch() {
		this.allWatchChecked = this.watchesUserList != null && this.watchesUserList.every(t => t.checked);
	}


	setAllWatched(checked: boolean) {
		this.allWatchChecked = checked;
		if (this.watchesUserList == null) {
			return;
		}
		this.watchesUserList.forEach(t => t.checked = checked);
	}

	download(url, fileName, $event) {
		$event.stopPropagation()
		this.restService.downloadFile(url, fileName);
	}

	dueDateChange(event) {
		var strDate;
		if (event.epoc > 0) {
			var date = event.jsdate;
			strDate = this.dateService.convertInDDMMYYYFormate(date)
			this.taskData.dueDate = { date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }, formatted: strDate, jsdate: date };;
			//this.myEndDatePickerOptions.disableUntil = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }

		} else {
			this.taskData.dueDate = null;
			strDate = null;

		}
		this.updateTask('dueDate', strDate);


	}


	toggleAddCommentList(ele, event, show) {
		let that = this;
		that.files = [];
		that.imgArr = [];
		$('.htmlEditor').summernote({
			height: '150px',
			focus: true,
			disableDragAndDrop: true,
			toolbar: [
				// [groupName, [list of button]]
				['style', ['bold', 'italic', 'underline']],
				['para', ['ul', 'ol', 'paragraph']]
			],
			callbacks: {
				onPaste: function (e) {
					var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
					e.preventDefault();
					document.execCommand('insertText', false, bufferText);
				}
			}
		});

		this.isAddCommentInputShown = show;
		$('.htmlEditor').summernote('code', '');
		$('#task_comment').summernote("reset");
		//$('#task_comment').code('');
		if (event == 'down') {
			$('.' + ele).slideDown("fast");
		} else {
			$('.' + ele).slideUp("fast");
		}
		$('#task_comment').summernote({
			focus: true,
		});
	}

	toggleUpdateCommentList(ele, event, obj) {
		let that = this;
		obj.files = [];
		obj.imgArr = [];
		$('.htmlEditor').summernote({
			height: '150px',
			focus: true,
			disableDragAndDrop: true,
			toolbar: [
				// [groupName, [list of button]]
				['style', ['bold', 'italic', 'underline']],
				['para', ['ul', 'ol', 'paragraph']]
			],
			callbacks: {
				onPaste: function (e) {
					var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
					e.preventDefault();
					document.execCommand('insertText', false, bufferText);
				}
			}
		});

		$('.htmlEditor').summernote('code', obj.comment);
		if (event == 'down') {

			$('.' + ele + '_' + obj.id).slideDown("fast");
			$('#task_edit_comment' + obj.id).summernote("reset");
			$('#task_edit_comment' + obj.id).summernote('code', obj.comment);
			if (obj.attachments && obj.attachments.length > 0) {
				obj.attachments.forEach(element => {
					obj.files.push({ id: element.id, name: element.displayName, serverFileName: element.fileName, image: element.fileTitle, isProgressBarShown: false });
					//obj.imgArr.push(element.displayName);
				});
				// 	}
			} else {
				//$('.' + ele + '_' + obj.id).slideUp("fast");
				obj.files = [];
				obj.imgArr = [];

			}
		} else {

			$('.' + ele + '_' + obj.id).slideUp("fast");
			$('#task_edit_comment' + obj.id).summernote("reset");
			$('#task_edit_comment' + obj.id).summernote('code', '');

		}
	}

	getExtensionOfFileType(fileName) {
		if (fileName) {
			var str = fileName.split(".");
			if (str[str.length - 1] == 'jpg' || str[str.length - 1] == 'png' || str[str.length - 1] == 'jpeg' || str[str.length - 1] == 'JPG' || str[str.length - 1] == 'PNG') {
				return 'image'
			} else {
				return 'file'
			}

		}
	}

	disableEndUntil(date) {
		if (date) {
			date.setDate(date.getDate() - 1);
			let copy = this.getCopyOfDueDateOptions();
			copy.disableUntil = {
				year: date.getFullYear(),
				month: date.getMonth() + 1,
				day: date.getDate()
			};
			this.myDueDatePickerOptions = copy;
		} else {
			let copy = this.getCopyOfDueDateOptions();
			copy.disableUntil = {
				year: 0,
				month: 0,
				day: 0
			};
			this.myDueDatePickerOptions = copy;
		}

	}

	closeHoursModal() {
		$('#task_loggedHours').val('');
		$('#task_loggedMins').val('');
		$('#hours-logged').modal("hide");
	}

	// Returns copy of myOptions
	getCopyOfDueDateOptions(): IMyDpOptions {
		return JSON.parse(JSON.stringify(this.myDueDatePickerOptions));
	}

	backToRetun() {
		this.location.back();
	}


	resetHoursValue() {
		$('#task_loggedHours').val(null);
		$('#task_loggedMins').val(null);
	}


	allWatchDisabled() {
		var isDisable = false;
		if (this.watchesUserList && this.watchesUserList.length > 0) {
			for (var i = 0; i < this.watchesUserList.length; i++) {
				if (!this.watchesUserList[i].isRemoved) {
					isDisable = true;
					break;
				}
			}

		}
		return isDisable;
	}

	removeReleaseSprints() {
		this.sprintArr = [];
		this.sprintArr.push(this.taskData?.taskSprint.id);

		if (this.sprintArr.length) {
			this.restService.hitApi('projectTask/associateSprint', { 'projectId': this.pid, 'taskId': this.taskId, 'entity': 'sprint', 'entity_id': this.sprintArr, 'set': 0 }, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.getTask();
						this.toastr.success(responseData.message);
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}
	}

	addReleaseSprint() {
		this.sprintArr = [];
		this.sprintList.forEach(element => {
			if (element.checked) {
				this.sprintArr.push(element.id);
			}
		});

		console.log(this.sprintArr);

		this.restService.hitApi('projectTask/associateSprint', { 'projectId': this.pid, 'taskId': this.taskId, 'entity': 'sprint', 'entity_id': this.sprintArr, 'set': 1 }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('#create_add-link_popup_web').modal('hide');
					this.getTask();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

}

