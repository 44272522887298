import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-search-not-found',
  templateUrl: './search-not-found.component.html',
  styleUrls: ['./search-not-found.component.scss']
})
export class SearchNotFoundComponent implements OnInit {

  @Input()
  title: string = " No Search Results"

  @Input()
  message: string;

  constructor() { }

  ngOnInit() {
  }

}
