import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';

// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

// import { MatDialog } from '@angular/material';
// import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
import { PageEvent } from '@angular/material/paginator';
import { InputComponent } from '../ui-components/input/input.component';
import { PreservedSearchStateService } from '../services/search/preserved-search-state.service';


declare const $: any;
@Component({
	selector: 'app-releases',
	templateUrl: './releases.component.html',
	styleUrls: ['./releases.component.scss'],
})

export class ReleasesComponent implements OnInit, OnDestroy {
	@ViewChild(InputComponent, { static: false }) childRef: InputComponent;

	userData: any = JSON.parse(localStorage.getItem("userData"));
	isOwner: any = JSON.parse(localStorage.getItem("isOwner"));
	isCompanyAdmin: any = JSON.parse(localStorage.getItem("isCompanyAdmin"));
	isProjectManager: any = JSON.parse(localStorage.getItem("isProjectManager"));

	releaseCreateForm: FormGroup;
	releaseMainData: any;
	releasesData: any = [];
	pid: any;
	rid: any;
	selectedRelease: any;

	files: any = [];
	imgArr: any = [];
	projectListData: any;
	projectData: any;


	sortOrder: any = 'desc';
	sortByField: any = 'id'
	searchdata: any;
	releaseCreateFormSubmit: boolean = false;

	pageEvent: PageEvent;
	datasource: null;
	pageIndex: number = 0;
	pageSize: number = 10;
	length: number = 0;
	searchString: string = "";
	shortCode: any;
	isLoading: boolean = false;
	isDialogOpen: boolean = false;
	isNoSearchResults: boolean = false;
	isNoData: boolean = false;
	isOther: boolean;

	// date: Date = new Date();


	// public myDatePickerOptions: IMyDpOptions = {
	//     dateFormat: 'dd-mm-yyyy',
	// 	disableUntil:{year: this.date.getFullYear(), month: this.date.getMonth()+1, day: this.date.getDate()-1}
	// };
	// public myDatePickerOptions2: IMyDpOptions = {
	//     dateFormat: 'dd-mm-yyyy',
	// 	disableUntil:{year: this.date.getFullYear(), month: this.date.getMonth()+1, day: this.date.getDate()-1}
	// };

	constructor(
		private restService: RestService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private preserveSearch: PreservedSearchStateService) {
		// Check for route params

		this.pid = localStorage.getItem('projectSelected')
		this.shortCode = localStorage.getItem('projectShortCode');

	}

	ngOnInit() {
		this.recoverLastSearchValue();

		if (this.pid && this.pid !== null && this.pid !== undefined) {
			if (this.pageEvent == null) {
				this.pageEvent = new PageEvent();
				this.pageEvent.length = this.length;
				this.pageEvent.pageIndex = this.pageIndex;
				this.pageEvent.pageSize = this.pageSize;
			}

			this.isLoading = true;
			this.getReleases(this.pageEvent);

			this.getProjectDetail();
		}
		let that = this;
		$(document).ready(() => {

			setTimeout(() => {
				$('.searchDate').datepicker({
					format: 'dd-mm-yyyy',
					autoclose: true,
					todayHighlight: true,
					clearBtn: true
				}).on('changeDate', function (e) {
					$('.datepicker').hide();
					that.searchData();
				});
			}, 10);
		});

		// $(document).mouseup(function(e) 
		// {
		// 	var container1 = $(".project-title-head");
		// 	var container2 = $(".project-title-list");
		// 	console.log(e.target);
		// 	if (!container1.is(e.target) && container1.has(e.target).length === 0 && !container2.is(e.target) && container2.has(e.target).length === 0) 
		// 	{
		// 		$(".project-title-list").hide('down');
		// 	}
		// });
		// this.getProjectList();

		this.createForm(null);
		console.log("abcd", this.releaseCreateForm.get('name'));
	}

	ngOnDestroy() {
		this.preserveSearch.searchReleaseState = {
			searchValue: this.searchString,
			pageEvent: this.pageEvent,
			results: this.releasesData,
			resultsLength: this.releasesData.length,
		};
	}

	recoverLastSearchValue() {
		const lastSearch = this.preserveSearch.searchReleaseState;
		if (lastSearch) {
			this.searchString = lastSearch.searchValue;
			this.releasesData = lastSearch.results;
			this.pageEvent = lastSearch.pageEvent;


			//this.resultsLength = lastSearch.resultsLength;
		}


	}



	getProjectDetail() {

		if (localStorage.getItem("isOwner") == "true") {
			this.isOwner = true
		} else {
			this.isOwner = false
		}

		if (localStorage.getItem("isCompanyAdmin") == "true") {
			this.isCompanyAdmin = true
		} else {
			this.isCompanyAdmin = false
		}

		if (localStorage.getItem("isProjectManager") == "true") {
			this.isProjectManager = true
		} else {
			this.isProjectManager = false
		}

		if (localStorage.getItem("isOther") == "true") {
			this.isOther = true
		} else {
			this.isOther = false
		}



		// this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', true)
		// 	.subscribe((result) => {
		// 		let responseData: any = result;
		// 		if (responseData.statusCode == 200) {
		// 			this.projectData = responseData.result;
		// 			if (this.userData) {
		// 				var isOwner = localStorage.getItem('isOwner');
		// 				if (isOwner == "true") {
		// 					this.isOwner = true;
		// 					this.isCompanyAdmin = false;
		// 					this.isProjectManager = false;
		// 					this.isOther = false;
		// 				} else {
		// 					if (this.userData.role == 1) {
		// 						this.isOwner = false;
		// 						this.isCompanyAdmin = true;
		// 						this.isProjectManager = false;
		// 						this.isOther = false;
		// 					} else if (this.userData.role == 2) {
		// 						if (this.projectData && this.projectData.projectUsersProject) {
		// 							this.projectData.projectUsersProject.forEach(element => {
		// 								if (element.userId == this.userData.id) {
		// 									if (element.userRoleId == 1) {
		// 										this.isProjectManager = true;
		// 										this.isOther = false;
		// 									} else {
		// 										this.isProjectManager = false;
		// 										this.isOther = true;
		// 									}
		// 								}
		// 							});
		// 							this.isOther = false;

		// 						} else {
		// 							this.isOther = true;
		// 						}
		// 						this.isOwner = false;
		// 						this.isCompanyAdmin = false;
		// 					} else {
		// 						this.isOther = true;
		// 						this.isOwner = false;
		// 						this.isProjectManager = false;
		// 						this.isCompanyAdmin = false;
		// 					}
		// 				}

		// 			}
		// 		} else {
		// 			// this.toastr.error(responseData.message);
		// 		}
		// 	}, (err) => {
		// 		console.log(err);
		// 		let errorData: any = err;
		// 		// this.toastr.error(errorData.error.message);
		// 	})
	}

	// getProjectList() {
	// 	this.restService.hitApi('discussion/projects',{},'get','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.projectListData = responseData.result.data;
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }

	// changeProject(pid){
	// 	localStorage.setItem('projectSelected',pid)
	// 	$('.project-title-list').toggle("slide");
	// 	this.router.navigateByUrl('/project/'+pid+'/releases');
	// 	setTimeout(() => {
	// 		this.getReleases();
	// 	}, 500);

	// }

	createForm(data) {
		if (data == null) {
			this.releaseCreateForm = this.formBuilder.group({
				'name': ['', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
			});

		} else {
			this.releaseCreateForm = this.formBuilder.group({
				'name': [data.name, [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
			});

		}

		//this.childRef.initializeControle();

	}

	// onDateChanged(event: IMyDateModel) {
	// 	// event properties are: event.date, event.jsdate, event.formatted and event.epoc
	// 	console.log(event.date);
	// 	this.myDatePickerOptions2 = {
	// 		disableUntil:event.date
	// 	};
	// }

	createRelease() {
		if (this.releaseCreateForm.invalid) {
			this.releaseCreateFormSubmit = true;
			return;
		} else {
			// console.log(this.releaseCreateForm.value);
			let apiname = (this.releaseCreateForm.value.releaseId) ? 'release/update' : 'release/create'
			this.releaseCreateForm.value.projectId = this.pid;
			// this.releaseCreateForm.value.fromDate = this.releaseCreateForm.value.fromDate.date.year+"-"+this.releaseCreateForm.value.fromDate.date.month+"-"+this.releaseCreateForm.value.fromDate.date.day;
			// this.releaseCreateForm.value.endDate = this.releaseCreateForm.value.endDate.date.year+"-"+this.releaseCreateForm.value.endDate.date.month+"-"+this.releaseCreateForm.value.endDate.date.day;
			// this.releaseCreateForm.value.attachments = this.imgArr;
			this.restService.hitApi(apiname, this.releaseCreateForm.value, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.getReleases(this.pageEvent);
						$('#create_release_popup_web').modal('hide');
						this.closeModal();
						this.toastr.success(responseData.message);
						// this.files = [];
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}
	}


	sortData(field) {
		this.sortByField = field;
		this.sortOrder = this.sortOrder == 'asc' ? 'desc' : 'asc';
		this.getReleases(this.pageEvent);
	}

	searchData() {
		let searchdata: any = { "search": {} };
		this.searchString = $('#search_name').val();
		console.log("i am searching", this.searchString);
		if (this.searchString.length >= 2 || this.searchString.length == 0) {
			this.pageEvent = new PageEvent();
			this.pageEvent.length = 0;
			this.pageEvent.pageIndex = 0;
			this.pageEvent.pageSize = 10;
			this.getReleases(this.pageEvent);
		}
	}

	getReleases(event?: PageEvent) {
		let postdata = {
			"projectId": this.pid,
			"sort": {
				"column": this.sortByField,
				"direction": this.sortOrder
			},
			"page": event.pageIndex + 1,
			"size": event.pageSize,
			"q": this.searchString
		};

		// let postdata = {
		// 	"projectId":this.pid,
		// 	"sort": {
		// 	"column": this.sortByField,
		// 	"direction": this.sortOrder
		// 	},
		// 	"search":this.searchdata};
		this.restService.hitApi('release/list', postdata, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.releaseMainData = responseData.result;
					this.releasesData = (responseData.result.total > 0) ? responseData.result.data : [];

					this.datasource = responseData.result;
					if (responseData.result.currentPage != 0) {
						this.pageIndex = responseData.result.currentPage - 1;
					}

					this.pageSize = responseData.result.perPage;
					if (responseData.result.total > 0) {
						this.length = responseData.result.total;
					}
					this.pageEvent = new PageEvent();
					this.pageEvent.length = this.length;
					this.pageEvent.pageIndex = this.pageIndex;
					this.pageEvent.pageSize = this.pageSize;

					this.checkIfDataHasComes();
					this.setIndexArray();
				} else {
					this.toastr.error(responseData.message);
				}
				this.isLoading = false;
			}, (err) => {
				this.isLoading = false;
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	checkIfDataHasComes() {
		if (this.releasesData.length == 0) {
			if (this.searchString != "" && this.searchString != null) {
				this.isNoSearchResults = true;
				this.isNoData = false;
			} else {
				this.isNoData = true;
				this.isNoSearchResults = false;
			}
		} else {
			this.isNoData = false;
			this.isNoSearchResults = false;
		}
	}

	setIndexArray() {
		for (let i = 0; i < this.releasesData.length; i++) {
			if (this.pageIndex == 0) {
				this.releasesData[i].tableIndex = i + 1;
			} else {
				this.releasesData[i].tableIndex = (this.pageSize * this.pageIndex) + i + 1;
			}
		}
	}

	deleteRelease(rid) {
		var index;
		for (var i = 0; i < this.releasesData.length; i++) {
			if (this.releasesData[i].id == rid) {
				index = i;
				break;
			}
		}
		this.restService.hitApi('release/delete', { 'releaseId': rid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					if (index != -1) {
						this.releasesData.splice(index, 1);
						if (this.releasesData.length == 0) {
							this.pageEvent = new PageEvent();
							this.pageEvent.length = this.length;
							this.pageEvent.pageIndex = 0;
							this.pageEvent.pageSize = this.pageSize;

							this.getReleases(this.pageEvent);
						}
						this.releaseMainData.total = this.releaseMainData.total - 1;
						this.length = this.releaseMainData.total;
					}
					//thi

					$('#delete-record').modal('hide');
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	blockRelease(rid, blockstatus) {
		let isBlocked = (blockstatus == 1) ? 0 : 1;
		this.restService.hitApi('release/block', { 'releaseId': rid, 'isBlocked': isBlocked }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getReleases(this.pageEvent);
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	//Image upload
	uploadFile(event) {
		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];
			const formData: FormData = new FormData();
			formData.append('projectId', this.pid);
			formData.append('file', file);
			this.files.push(file.name);

			this.restService.hitApi('release/upload-file', formData, 'post', '', true, false)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.imgArr.push(responseData.result);
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}

	}




	removeAttachment(index) {
		this.files.splice(index, 1)
	}

	deleteAttachment(aid, index) {
		this.restService.hitApi('release/delete-file', { "attachmentId": aid, "projectId": this.pid }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.releaseCreateForm.value.oldattachments.splice(index, 1);
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	isBlocked() {
		this.toastr.error('This release is blocked.');
	}

	closeModal() {
		console.log("close model called");
		this.releaseCreateForm.reset();
		this.releaseCreateFormSubmit = false;
		this.isDialogOpen = false;

	}
	getReleaseDetails(release) {
		this.isLoading = true;
		this.selectedRelease = release;
		this.restService.hitApi('release/details', { 'releaseId': release?.entityId }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.selectedRelease = responseData.result;
				} else {
					console.log("responseData.replyMessage", responseData);
					this.toastr.error(responseData);
					this.toastr.error(responseData.replyMessage);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(err.error.message);
			})
	}

}

