import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { BroadcastService } from '../services/broadcast/broadcast.service';

import { Data } from '../ui-components/select-dropdown/Data';
declare const $: any;

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})

export class CompanyComponent implements OnInit {
  userData: any = JSON.parse(localStorage.getItem("userData"));
  companyForm: FormGroup;
  companiesData: any = {};
  companies: any = [];
  companyName: any;
  companyId: any;
  isLoading: boolean = false;
  upgradePlanAlert: any;

  constructor(
    private restService: RestService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private broadcastService: BroadcastService,
    private toastr: ToastrService) {

    this.isLoading = true;
    this.getCompanyData();

    console.log("company user data", this.userData);
  }

  ngOnInit() {

    this.upgradePlanAlert = null;
    this.broadcastService.subscribe("PLAN_EXPIRED", () => {
      this.upgradePlanAlert = "Your plan has expired" 
    });

    if (this.userData.companyId) {
      var obj = new Data(this.userData.companyId, this.userData.companyName);
      this.createFormForCompany(obj);
    }
    this.createFormForCompany(null);
  }



  createFormForCompany(data) {
    if (data === null) {
      this.companyForm = this.formBuilder.group({
        'name': ['', [Validators.required]],
        'id': ['', [Validators.required]],
      });

    } else {
      this.companyForm = this.formBuilder.group({
        'name': [data.name, [Validators.required]],
        'id': [data.id, [Validators.required]],
      });
    }

  }

  getCompanyData() {
    this.restService.hitApi('user/companies', {}, 'get', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.companyName = localStorage.getItem('company_name');
          this.companyId = localStorage.getItem('company_id');
          this.companiesData = responseData.result;

          console.log("companiesData: ", responseData.result);

          this.companies = [];
          this.companiesData.forEach(element => {
            if (this.companyId != element.id) {
              let data = new Data(element.name, element.id);
              this.companies.push(data);
            }

          });

          this.isLoading = false;
        } else {
          //this.errorProfileMessage = responseData.message;
        }

      }, (err) => {
        this.isLoading = false;
        this.toastr.error(err.error.message);
      })
  }

  getCompany(obj: Data) {

    if (obj) {
      console.log("selectedRole" + obj.name);
      console.log("selectedId" + obj.id);
      this.companyForm.get('id').setValue(parseInt(obj.id));
      this.companyForm.get('name').setValue(obj.name);

      console.log(this.companyForm);
    }
  }

  changeCompany() {
    let id = this.companyForm.value.id;
    console.log("company id", id);
    this.restService.setCompany(id)
  }

  calculateDiff(dateSent) {
		let currentDate = new Date();
		dateSent = new Date(dateSent);
	
		return Math.floor((Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
	
	}

}
