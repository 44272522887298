import { filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';

// import { MatDialog } from '@angular/material';
// import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
import { RouteEventsService } from '../services/route-events.service';
import { RouteInterceptorService } from '../services/route-interceptor.service';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { PreservedSearchStateService } from '../services/search/preserved-search-state.service';
import { BroadcastService } from '../services/broadcast/broadcast.service';
import { FileUploadService } from "../file-upload-service";
import { HttpEvent, HttpEventType } from '@angular/common/http';


declare const $: any;
@Component({
	selector: 'app-testplan',
	templateUrl: './testplan.component.html',
	styleUrls: ['./testplan.component.scss'],
})

export class TestplanComponent implements OnInit, OnDestroy {
	userData: any = JSON.parse(localStorage.getItem("userData"));
	testplanCreateForm: FormGroup;
	testplanData;
	pid: any = localStorage.getItem('projectSelected');
	tpid: any;
	testPlanId: any;
	selectedTestplan: any;
	projectListData: any;
	projectData: any;
	projectSprintList: any;
	files: any = [];
	imgArr: any = [];
	descriptionFiles: any = [];
	descriptionImgArr: any = [];
	testcaseArr: any = [];
	userList: any;
	sprintList: any;
	testplanReport: any;
	testCaseList: any;
	watchesUserList: any;
	allTestCaseChecked: boolean = false;
	allWatchChecked: boolean = false;
	fb: FormBuilder;
	userInfo: any;
	isShowMore: boolean = false;
	isTestPlanReadOnly: boolean = true;
	isAddCommentInputShown: boolean = true;
	backButtonText;

	releaseSprintDisabled: boolean = true;
	jqueryRef: any = $;
	endDateStart: any;
	todat_date: any = new Date();
	addCommentForm: FormGroup;
	editCommentForm: FormGroup;

	assignee = new FormControl();
	qualityCheckStatus = new FormControl();
	sprint = new FormControl();

	progressStatusList = [{ name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }, { name: 'Completed', value: 4 }]
	isOwner: boolean;
	isCompanyAdmin: boolean;
	isProjectManager: boolean;
	testCaseReports = [];
	selectedTestCase;
	routerHistory: any;
	selectedIndex = 0;
	tab: any;
	tabNum: any = 1;
	searchString: any;
	isOther: boolean;
	isSaveDisable: boolean;
	tabActive: number = 0;
	isNotStoreHistory: boolean;
	deletedDescriptionItems: any[] = [];
	deletedAttachmentItems: any[] = [];


	constructor(
		private restService: RestService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private routeEventsService: RouteEventsService,
		private routeInterceptorService: RouteInterceptorService,
		private preserveSearch: PreservedSearchStateService,
		private broadcastService: BroadcastService,
		private fileUploadService: FileUploadService,
		private location: Location) {


		var params = this.router.getCurrentNavigation().extras.state;
		console.log("this.params", params);
		this.tabNum = params?.data['queryParams']?.['tab'];
		console.log("this.tabNum", this.tabNum);

		this.fb = formBuilder;

		this.isOwner = localStorage.getItem("isOwner") == 'true' ? true : false;
		this.isCompanyAdmin = localStorage.getItem("isCompanyAdmin") == 'true' ? true : false;
		this.isProjectManager = localStorage.getItem("isProjectManager") == 'true' ? true : false;
		this.isOther = localStorage.getItem("isOther") == 'true' ? true : false;

		// if (this.pid && this.pid !== null && this.pid !== undefined) {
		// 	this.getProjectDetail();
		// }
		// Check for route params
		this.route.params.subscribe(params => {

			this.tpid = params['tpid'];
			if (this.tpid && this.tpid !== null && this.tpid !== undefined) {
				this.getTestplan();

			}
		});

		if (this.userData) {
			this.getUserprofile();
		}

		this.createForm(null);

		this.broadcastService.subscribe("RELOAD_ENTITY", (id) => {
			this.tpid = id
			this.pid = localStorage.getItem('projectSelected');
			if (this.pid && this.tpid && this.tpid !== null && this.tpid !== undefined) {
				this.getTestplan();
			}
		});

	}

	ngOnInit() {

		this.recoverLastTabValue();
		// this.redirectTab();

		$(document).ready(() => {
			let that = this;
			that.setTabActive(that.tabActive)
			$('.htmlEditor').summernote({
				height: '150px',
				focus: true,
				disableDragAndDrop: true,
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
				callbacks: {
					onPaste: function (e) {
						var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
						e.preventDefault();
						document.execCommand('insertText', false, bufferText);
					},
					onImageUpload: function (data) {
						data.pop();
					}
				}
			});
			// $('.moreless-button').click(function () {
			// 	$('.moretext').slideDown();
			// 	if ($('.moreless-button').text() == "Show Less") {
			// 		$(this).text("Show 2 more fields")
			// 	} else {
			// 		$(this).text("Show Less")
			// 	}
			// });

			$('.date input').datepicker({
				format: 'yyyy-mm-dd',
				autoclose: true,
				todayHighlight: true
			}).on('changeDate', function (e) {
				$('.datepicker').hide();
				if (e.currentTarget.classList[1] == 'startDate') {
					var minDate = new Date(e.date.valueOf());
					$('.endDate').datepicker('setStartDate', minDate);
				}
				that.updateTestplan(e.currentTarget.classList[1], e.format('yyyy-mm-dd'));
			});

			// $('.startDate').datepicker({
			// 	format: 'yyyy-mm-dd',
			// 	// startDate:new Date()
			// }).on('changeDate', (e)=> {
			// 	$('.datepicker').hide();
			// 	$('.endDate').datepicker({
			// 		startDate:new Date(e.format('yyyy-mm-dd'))
			// 	});
			// 	that.updateTestplan(e.currentTarget.classList[1],e.format('yyyy-mm-dd'));

			// });



			// $('.dueDate').datepicker({
			// 	format: 'yyyy-mm-dd',
			// 	startDate:new Date()
			// }).on('changeDate', function(e) {
			// 	$('.datepicker').hide();
			// 	that.updateTestplan(e.currentTarget.classList[1],e.format('yyyy-mm-dd'));
			// });

		});

		$(document).mouseup(function (e) {
			if (!$(".work-status-anchor").is(e.target) && $(".work-status-anchor").has(e.target).length === 0 && !$(".progress-work-st").is(e.target) && $(".progress-work-st").has(e.target).length === 0) {
				$(".progress-work-st").slideUp("fast");
			}
			if (!$('.assign-user-work').is(e.target) && $('.assign-user-work').has(e.target).length === 0 && !$('.assign-user-work-open').is(e.target) && $('.assign-user-work-open').has(e.target).length === 0) {
				$('.assign-user-work-open').slideUp("fast");
			}
			if (!$('.type-work').is(e.target) && $('.type-work').has(e.target).length === 0 && !$('.type-work-open').is(e.target) && $('.type-work-open').has(e.target).length === 0) {
				$('.type-work-open').slideUp("fast");
			}


		});

		// this.createForm(null);
		// this.getProjectList();
	}

	ngOnDestroy() {
		if (this.isNotStoreHistory) {
			this.preserveSearch.searchTestPlanTabState = {
				tab: 0
			}
		} else {
			this.preserveSearch.searchTestPlanTabState = {
				tab: this.tabActive
			};
		}

	}

	recoverLastTabValue() {
		const lastSearch = this.preserveSearch.searchTestPlanTabState;
		if (lastSearch) {
			this.tabActive = lastSearch.tab;

		}


	}


	createForm(comment) {
		if (comment) {
			this.editCommentForm = this.fb.group({
				'comment': [comment],
			});
		} else {
			this.addCommentForm = this.fb.group({
				'comment': [''],
			});
			this.editCommentForm = this.fb.group({
				'comment': [''],
			});
		}
		console.log('this.editCommentForm');
		console.log(this.editCommentForm);


	}

	getUserprofile() {
		this.restService.hitApi('user/profile', { 'userId': this.userData.id }, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.userInfo = responseData.result;
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}


	// getProjectDetail() {
	// 	this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', true)
	// 		.subscribe((result) => {
	// 			let responseData: any = result;
	// 			if (responseData.statusCode == 200) {
	// 				this.projectData = responseData.result;
	// 				if (this.projectData?.type == 2) {
	// 					this.progressStatusList = [{ name: 'Backlog', value: 0 }, { name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }, { name: 'Completed', value: 4 }];
	// 				} else {
	// 					this.progressStatusList = [{ name: 'To Do', value: 1 }, { name: 'In Progress', value: 2 }, { name: 'Quality Check', value: 3 }, { name: 'Completed', value: 4 }];
	// 				}
	// 				if (this.userData) {
	// 					var isOwner = localStorage.getItem('isOwner');
	// 					if (isOwner == "true") {
	// 						this.isOwner = true;
	// 						this.isCompanyAdmin = false;
	// 						this.isProjectManager = false;
	// 						this.isOther = false;
	// 					} else {
	// 						if (this.userData.role == 1) {
	// 							this.isOwner = false;
	// 							this.isCompanyAdmin = true;
	// 							this.isProjectManager = false;
	// 							this.isOther = false;
	// 						} else if (this.userData.role == 2) {
	// 							if (this.projectData && this.projectData.projectUsersProject) {
	// 								this.projectData.projectUsersProject.forEach(element => {
	// 									if (element.userId == this.userData.id) {
	// 										if (element.userRoleId == 1) {
	// 											this.isProjectManager = true;
	// 											this.isOther = false;
	// 										} else {
	// 											this.isProjectManager = false;
	// 											this.isOther = true;
	// 										}
	// 									}
	// 								});
	// 								this.isOther = false;

	// 							} else {
	// 								this.isOther = true;
	// 							}
	// 							this.isOwner = false;
	// 							this.isCompanyAdmin = false;
	// 						} else {
	// 							this.isOther = true;
	// 							this.isOwner = false;
	// 							this.isProjectManager = false;
	// 							this.isCompanyAdmin = false;
	// 						}
	// 					}
	// 					// if (this.userData.isCompanyAdmin == 1) {
	// 					// 	if (this.restService.isOwner(this.userData)) {
	// 					// 		this.isOwner = true;
	// 					// 		this.isCompanyAdmin = false;
	// 					// 		this.isProjectManager = false;
	// 					// 	} else {
	// 					// 		this.isOwner = false;
	// 					// 		this.isCompanyAdmin = true;
	// 					// 		this.isProjectManager = false;
	// 					// 	}
	// 					// } else {
	// 					// 	if (this.projectData && this.projectData.projectUsersProject) {
	// 					// 		this.projectData.projectUsersProject.forEach(element => {
	// 					// 			if (element.userId == this.userData.id) {
	// 					// 				if (element.userRoleId == 1) {
	// 					// 					this.isProjectManager = true;

	// 					// 				} else {
	// 					// 					this.isProjectManager = false;
	// 					// 				}
	// 					// 			}
	// 					// 		});

	// 					// 	}
	// 					// 	this.isOwner = false;
	// 					// 	this.isCompanyAdmin = false;
	// 					// }
	// 				}
	// 			} else {
	// 				// this.toastr.error(responseData.message);
	// 			}
	// 		}, (err) => {
	// 			console.log(err);
	// 			let errorData: any = err;
	// 			// this.toastr.error(errorData.error.message);
	// 		})
	// }

	getSprintList() {
		this.restService.hitApi('userStory/sprintList', { 'projectId': this.pid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.sprintList = responseData.result;
					if (this.testplanData && this.testplanData.sprintId && this.testplanData.sprintName) {
						this.sprintList.forEach(element => {
							if (element.id == this.testplanData.sprintId) {
								this.sprint.setValue(element);

							}
						});
					}
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	getTestcaseList() {

		this.restService.hitApi('testplan/testcase/list', {
			'projectId': this.pid, "testPlanId": this.testPlanId, "sort": {  //optional
				"column": "entityId",
				"direction": "asc"//asc
			}, "q": this.searchString
		}, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.testCaseList = responseData.result;
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	toggleList(ele, event) {
		this.descriptionFiles = [];
		this.isAddCommentInputShown = true;
		$('#testplan_description').summernote('code', this.testplanData.description);
		if (event == 'down') {
			$('.' + ele).slideToggle("fast");
			if (this.testplanData.descriptionAttachments && this.testplanData.descriptionAttachments.length > 0) {
				this.testplanData.descriptionAttachments.forEach(element => {
					this.descriptionFiles.push({ id: element.id, name: element.displayName, serverFileName: element.fileName, image: element.fileTitle, isProgressBarShown: false });
					//obj.imgArr.push(element.displayName);
				});
				// 	}
			} else {
				//$('.' + ele + '_' + obj.id).slideUp("fast");
				this.descriptionFiles = [];
				this.descriptionImgArr = [];

			}
		} else {
			$('.' + ele).slideUp("fast");
		}
		//$('#testplan_description').summernote('editor.saveRange');

		// Editor loses selected range (e.g after blur)

		//$('#testplan_description').summernote('editor.restoreRange');
		$('#testplan_description').summernote({
			focus: true
		});
	}

	toggleAddCommentList(ele, event, show) {

		let that = this;
		that.files = [];
		that.imgArr = [];
		$('.htmlEditor').summernote({
			height: '150px',
			focus: true,
			disableDragAndDrop: true,
			toolbar: [
				// [groupName, [list of button]]
				['style', ['bold', 'italic', 'underline']],
				['para', ['ul', 'ol', 'paragraph']]
			],
			callbacks: {
				onPaste: function (e) {
					var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
					e.preventDefault();
					document.execCommand('insertText', false, bufferText);
				}
			}
		});

		this.isAddCommentInputShown = show;
		$('.htmlEditor').summernote('code', '');
		$('#testplan_comment').summernote("reset");
		if (event == 'down') {

			$('.' + ele).slideDown("fast");
		} else {
			$('.' + ele).slideUp("fast");

		}
		$('#testplan_comment').summernote({
			focus: true
		});
	}

	toggleUpdateCommentList(ele, event, obj) {
		let that = this;
		obj.files = [];
		obj.imgArr = [];
		$('.htmlEditor').summernote({
			height: '150px',
			focus: true,
			disableDragAndDrop: true,
			toolbar: [
				// [groupName, [list of button]]
				['style', ['bold', 'italic', 'underline']],
				['para', ['ul', 'ol', 'paragraph']]
			],
			callbacks: {
				onPaste: function (e) {
					var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
					e.preventDefault();
					document.execCommand('insertText', false, bufferText);
				}
			}
		});

		$('.htmlEditor').summernote('code', obj.comment);
		if (event == 'down') {
			$('.' + ele + '_' + obj.id).slideDown("fast");
			$('#testplan_edit_comment' + obj.id).summernote("reset");
			$('#testplan_edit_comment' + obj.id).summernote('code', obj.comment);

			if (obj.attachments && obj.attachments.length > 0) {
				obj.attachments.forEach(element => {
					obj.files.push({ id: element.id, name: element.displayName, serverFileName: element.fileName, image: element.fileTitle, isProgressBarShown: false });
					//obj.imgArr.push(element.displayName);
				});
				// 	}
			} else {
				//$('.' + ele + '_' + obj.id).slideUp("fast");
				obj.files = [];
				obj.imgArr = [];

			}
		} else {
			$('.' + ele + '_' + obj.id).slideUp("fast");
			$('#testplan_edit_comment' + obj.id).summernote("reset");
			$('#testplan_edit_comment' + obj.id).summernote('code', obj.comment);

		}
		//$('#testplan_edit_comment' + obj.id).summernote('editor.saveRange');

		// Editor loses selected range (e.g after blur)

		//$('#testplan_edit_comment' + obj.id).summernote('editor.restoreRange');
		$('#testplan_edit_comment' + obj.id).summernote({
			focus: true
		});
	}


	showedit() {
		if (!this.isTestPlanReadOnly) {
			$('.heading-editable h1').hide("fast");
			$('.heading-area').show("fast");
			$('.tick-cancel-input-filed').show("fast");
		}

	}
	hideedit() {
		if (!this.isTestPlanReadOnly) {
			$('#testplan_name').val(this.testplanData?.name);
			$('.heading-editable h1').show("fast");
			$('.heading-area').hide("fast");
			$('.tick-cancel-input-filed').hide("fast");
		}
	}


	updateTestplan(field, value = "") {
		console.log("this.qualityCheckStatus");
		console.log(this.qualityCheckStatus);

		let data: any;
		if (field == 'name') {
			value = $('#testplan_' + field).val();
			if (value != "") {
				if (value != this.testplanData.name) {
					data = { 'name': value, 'testPlanId': this.testPlanId, 'projectId': this.pid };
				}
			} else {
				this.toastr.error("Test Plan name is required");
				return
			}

		} else if (field == 'description') {

			value = $('#testplan_' + field).val();
			data = { 'description': value, 'testPlanId': this.testPlanId, 'projectId': this.pid, 'files': this.descriptionImgArr };

		} else if (field == 'status') {
			if (value != this.testplanData.status) {
				data = { 'status': value, 'testPlanId': this.testPlanId, 'projectId': this.pid };
			}
		} else if (field == 'assignee') {
			if (value == '0') {
				if (this.testplanData.assignee != null) {
					data = { 'assignee': value, 'testPlanId': this.testPlanId, 'projectId': this.pid };
				} else {
					data = null;
				}
			} else {
				if (value != this.testplanData.assignee) {
					data = { 'assignee': value, 'testPlanId': this.testPlanId, 'projectId': this.pid };

				}
			}

		} else if (field == 'sprintId') {
			if (value != this.testplanData.sprintId) {
				data = { 'sprintId': value, 'testPlanId': this.testPlanId, 'projectId': this.pid };

			}
		} else if (field == 'expectedResults') {
			value = $('#testplan_' + field).val();

			data = { 'expectedResults': value, 'testPlanId': this.testPlanId, 'projectId': this.pid };
		} else if (field == 'estHours') {
			value = $('#testplan_' + field).val();
			if (value != this.testplanData.estHours) {
				data = { 'estHours': value, 'testPlanId': this.testPlanId, 'projectId': this.pid };
			}
		} else if (field == 'estMins') {
			value = $('#testplan_' + field).val();
			if (value != this.testplanData.estMins) {
				data = { 'estMins': value, 'testPlanId': this.testPlanId, 'projectId': this.pid };
			}
		} else if (field == 'loggedHours') {
			let value1 = $('#testplan_loggedHours').val();
			let value2 = $('#testplan_loggedMins').val();
			data = { 'loggedHours': value1, 'loggedMins': value2, 'testPlanId': this.testPlanId, 'projectId': this.pid };
		}

		if (data != null) {
			this.restService.hitApi('testplan/update', data, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.toastr.success(responseData.message);
						if (field == 'name') {
							$('.heading-editable h1').show("fast");
							$('.heading-area').hide("fast");
							$('.tick-cancel-input-filed').slideToggle("fast");
						} else if (field == 'status') {
							$('.progress-work-st-js').slideToggle("fast");
						} else if (field == 'assignee') {
							$('.assign-user-work-open').slideToggle("fast");
						} else if (field == 'description') {
							this.descriptionFiles = [];
							this.descriptionImgArr = [];
							$('testplan_decription').summernote("reset");
							$('.description').slideToggle("fast");
						} else if (field == 'sprintId') {
							$('.type-work-open').slideToggle("fast");
						} else if (field == 'loggedHours') {
							$('#testplan_loggedHours').val('');
							$('#testplan_loggedMins').val('');
							$('#hours-logged').modal("hide");
						}

						this.getTestplan();
						// $('#create_testplan_popup_web').modal('hide');
						// this.files = [];
					} else {
						// this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					// this.toastr.error(errorData.error.message);
				})
		} else {
			if (field == 'name') {
				$('.heading-editable h1').show("fast");
				$('.heading-area').hide("fast");
				$('.tick-cancel-input-filed').hide("fast");
			}
		}
	}

	getTestplan() {
		this.restService.hitApi('testplan/details', { 'testPlanId': this.tpid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.testplanData = responseData.result;
					this.testPlanId = this.testplanData.id;
					if (this.testplanData.status) {
						if (this.testplanData.status == 4) {
							this.isTestPlanReadOnly = true;
						} else {
							this.isTestPlanReadOnly = false;
						}
						this.progressStatusList.forEach(element => {
							if (element.value == this.testplanData.status) {
								this.qualityCheckStatus.setValue(element);

							}
						})
					} else {
						this.isTestPlanReadOnly = false;
						this.qualityCheckStatus.setValue(this.progressStatusList[0]);
					}

					if (this.userList && this.userList.length && this.testplanData && this.testplanData.assignee && this.testplanData.assigneeName) {
						this.userList.forEach(element => {
							if (element.userId == this.testplanData.assignee) {
								this.assignee.setValue(element);

							}
						});
					}
					if (this.testplanData.estMins <= 0) {
						$('#defect_estMins').val(null);

					}
					console.log("this.isTestPlanReadOnly", this.isTestPlanReadOnly);
					this.getUserList([2]);
					this.getSprintList();
					this.getTestcaseList();
					this.getTestplanReport();
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	deleteTestplan(tpid) {
		this.restService.hitApi('testplan/delete', { 'testPlanId': tpid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('#delete-record').modal('hide')
					this.toastr.success(responseData.message);
					this.router.navigateByUrl('/testplans');
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	getTestplanReport() {
		this.restService.hitApi('testplan/report/details', { 'projectId': this.pid, 'testPlanId': this.tpid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.testplanReport = responseData.result;
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	updateTestcaseResult(result, tcid) {
		let data: any = {
			"projectId": this.pid,
			"testCaseId": tcid,
			"testPlanId": this.testPlanId,
			"status": result
		}
		this.restService.hitApi('testplan/report/update', data, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getTestplanReport();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	resetHoursValue() {
		$('#testplan_loggedHours').val(null);
		$('#testplan_loggedMins').val(null);
	}


	allWatchDisabled() {
		var isDisable = false;
		if (this.watchesUserList && this.watchesUserList.length > 0) {
			for (var i = 0; i < this.watchesUserList.length; i++) {
				if (!this.watchesUserList[i].isRemoved) {
					isDisable = true;
					break;
				}
			}

		}
		return isDisable;
	}

	// getProjectSprints(){
	// 	this.restService.hitApi('release-sprint/sprint',{'projectReleaseId':this.tpid},'post','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.projectSprintList = responseData.result;
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }



	checkUncheckAll(ele, classname) {
		// console.log(ele.target.checked)
		var checkboxes = $("." + classname + " input");
		if (ele) {
			for (var i = 0; i < checkboxes.length; i++) {
				// if (checkboxes[i].type == 'checkbox') {
				checkboxes[i].checked = true;
				// }
			}
		} else {
			for (var i = 0; i < checkboxes.length; i++) {
				console.log(i)
				// if (checkboxes[i].type == 'checkbox') {
				checkboxes[i].checked = false;
				// }
			}
		}
	}

	// setCheckedVar(){
	// 	this.releaseSprintDisabled = true;
	// 	let that = this;
	// 	$('.release-sprint-checkbox').find('input').each(function(){
	// 		if($(this).prop("checked")){
	// 			that.releaseSprintDisabled = false;
	// 		}
	// 	});

	// }

	// removeReleaseSprints(){
	// 	this.testcaseArr = [];
	// 	let that = this;
	// 	$('.release-sprint-checkbox').find('input').each(function(){
	// 		console.log($(this).val());
	// 		if($(this).prop("checked")){
	// 			that.testcaseArr.push($(this).val());
	// 		}
	// 	});

	// 	if(this.testcaseArr.length){
	// 		this.restService.hitApi('release-sprint/delete',{'projectReleaseId':this.tpid,'sprintId':this.testcaseArr},'post','',true)
	// 		.subscribe((result)=>{
	// 			let responseData : any = result;
	// 			if(responseData.statusCode==200){
	// 				this.getTestplan();
	// 				this.toastr.success(responseData.message);
	// 			}else{
	// 				this.toastr.error(responseData.message);
	// 			}
	// 		},(err)=>{
	// 			console.log(err);
	// 			let errorData : any = err;
	// 			this.toastr.error(errorData.error.message);
	// 		})
	// 	}
	// }


	getUserList(value) {
		this.restService.hitApi('project/projectUsers', { 'projectId': this.pid, escape: value }, 'post', '', false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					var list = responseData.result;
					this.userList = [{ userId: 0, name: 'Unassigned' }];
					list.forEach(element => {
						this.userList.push(element);
					});

					if (this.testplanData && this.testplanData.assignee && this.testplanData.assigneeName) {
						this.userList.forEach(element => {
							if (element.userId == this.testplanData.assignee) {
								this.assignee.setValue(element);

							}
						});
					} else {
						this.assignee.setValue(this.userList[0]);
					}

				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	addTestCase() {

		// let that = this;
		// $('.testcase-checkbox').find('input').each(function () {
		// 	console.log($(this).val());
		// 	if ($(this).prop("checked")) {
		// 		that.testcaseArr.push($(this).val());
		// 	}
		// });

		this.testcaseArr = [];
		this.testCaseList.forEach(element => {
			if (element.checked) {
				this.testcaseArr.push(element.id);
			}
		});

		console.log(this.testcaseArr);

		this.restService.hitApi('testplan/testcase/add', { 'projectId': this.pid, 'testPlanId': this.testPlanId, 'testCaseIds': this.testcaseArr }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('#add-test-cases').modal('hide');
					this.getTestplan();
					this.getTestplanReport();
					this.getTestcaseList();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}



	addComment(obj, field) {
		console.log("comment called");
		let data: any;
		let comment: any;
		let apiname: any;
		if (obj?.id) {
			apiname = 'testplan/comment/update';
			comment = $('#testplan_edit_' + field + obj?.id).val();
			data = { 'testPlanCommentId': obj?.id, 'comment': comment, 'files': obj.imgArr, 'projectId': this.pid }
		} else {
			apiname = 'testplan/comment/add';
			comment = $('#testplan_' + field).val();
			data = { 'testPlanId': this.testPlanId, 'comment': comment, 'files': this.imgArr, 'projectId': this.pid }
		}



		this.restService.hitApi(apiname, data, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					if (obj?.id) {
						$('#comment' + obj?.id).show();
						$('#commentinput' + obj?.id).hide();
					} else {
						$('#comment').val('');
						$('testplan_comment').summernote("reset");
					}
					this.isAddCommentInputShown = true;
					this.files = [];
					this.imgArr = [];
					if (obj?.id) {
						this.editCommentForm.reset();
					} else {
						this.addCommentForm.reset();
					}
					this.getTestplan();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	deleteComment(testPlanCommentId) {
		this.restService.hitApi('testplan/comment/delete', { "testPlanCommentId": testPlanCommentId }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getTestplan();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	uploadFile(event) {
		console.log("file event", event);
		var i;
		for (let index = 0; index < event.length; index++) {
			i = index;
			let file: File = event[index];
			if (this.restService.checkFileSize(file, 50)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				formData.append('entity', 'testPlan');
				formData.append('entityId', this.tpid);
				formData.append('file', file);

				var reader = new FileReader();
				const this_ = this;
				var obj;
				reader.onload = function (e: any) {
					obj = { serverFileName: file.name, name: file.name, image: e.target.result, isProgressBarShown: true }
					this_.files.push(obj);
				}

				reader.readAsDataURL(file);

				this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
					.subscribe((event: HttpEvent<any>) => {
						switch (event.type) {
							case HttpEventType.Sent:
								console.log('Request has been made!');
								break;
							case HttpEventType.ResponseHeader:
								console.log('Response header has been received!');
								break;
							case HttpEventType.UploadProgress:
								this.files[this.files.length - 1].progress = Math.round(event.loaded / event.total * 100);
								console.log(`Uploaded! ${this.files[this.files.length - 1].progress}%`);
								$('#add_comment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');

								break;
							case HttpEventType.Response:
								let responseData: any = event.body;
								if (responseData.statusCode == 200) {
									this.imgArr.push(responseData.result);
									this.files[this.files.length - 1].serverFileName = responseData.result.fileTitle;
									this.files[this.files.length - 1].displayName = responseData.result.displayName;
									this.files.forEach(element => {
										element.isProgressBarShown = false;
									});
								} else {
									if (responseData.message) {
										this.toastr.error(responseData.message);
									}
								}
								console.log('Upload successfully', event.body);
								setTimeout(() => {
									this.files[this.files.length - 1].progress = 0;
									$('#add_comment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');

								}, 1500);


						}
					})

				//this.files.push({ index: index, localFileName: file.name, serverFileName: null, isProgressBarShown: true });

				// this.files.push(file.name);

				// this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', false, false)
				// 	.subscribe((result) => {
				// 		let responseData: any = result;
				// 		if (responseData.statusCode == 200) {
				// 			this.imgArr.push(responseData.result);
				// 			this.files[this.files.length - 1].serverFileName = responseData.result.fileTitle;
				// 			this.files[this.files.length - 1].displayName = responseData.result.displayName;
				// 			this.files.forEach(element => {
				// 				element.isProgressBarShown = false;
				// 			});

				// 		} else {
				// 			this.toastr.error(responseData.message);
				// 		}
				// 	}, (err) => {
				// 		console.log(err);
				// 		let errorData: any = err;
				// 		//this.files[index].isProgressBarShown = false;
				// 		this.toastr.error(errorData.error.message);
				// 	})
			}
		}

	}

	uploadCommentAttachment(event, commentObj) {
		var i;
		for (let index = 0; index < event.length; index++) {
			i = index;
			let file: File = event[index];
			if (this.restService.checkFileSize(file, 50)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				formData.append('entity', 'testPlan');
				formData.append('entityId', this.tpid);
				formData.append('file', file);

				// const element = event[index];


				var reader = new FileReader();
				const this_ = this;
				var obj;
				reader.onload = function (e: any) {
					obj = { name: file.name, serverFileName: file.name, image: e.target.result, isProgressBarShown: true }
					commentObj.files.push(obj);
				}

				reader.readAsDataURL(file);
				this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
					.subscribe((event: HttpEvent<any>) => {
						switch (event.type) {
							case HttpEventType.Sent:
								console.log('Request has been made!');
								break;
							case HttpEventType.ResponseHeader:
								console.log('Response header has been received!');
								break;
							case HttpEventType.UploadProgress:
								commentObj.files[commentObj.files.length - 1].progress = Math.round(event.loaded / event.total * 100);
								console.log(`Uploaded! ${commentObj.files[commentObj.files.length - 1].progress}%`);
								$('#edit_comment_progress_bar_' + (commentObj.files.length - 1)).attr('aria-valuenow', commentObj.files[commentObj.files.length - 1].progress).css('width', commentObj.files[commentObj.files.length - 1].progress + '%');

								break;
							case HttpEventType.Response:
								let responseData: any = event.body;
								if (responseData.statusCode == 200) {
									commentObj.imgArr.push(responseData.result);
									const fileObj = commentObj.files.find(element => element.name == obj.name);
									fileObj.serverFileName = responseData.result.fileTitle;
									fileObj.displayName = responseData.result.displayName;
									commentObj.files.forEach(element => {
										element.isProgressBarShown = false;
									});
								} else {
									if (responseData.message) {
										this.toastr.error(responseData.message);
									}
								}
								console.log('User successfully created!', event.body);
								setTimeout(() => {
									commentObj.files[commentObj.files.length - 1].progress = 0;
									$('#edit_comment_progress_bar_' + (commentObj.files.length - 1)).attr('aria-valuenow', commentObj.files[commentObj.files.length - 1].progress).css('width', commentObj.files[commentObj.files.length - 1].progress + '%');

								}, 1500);
						}
					})

				// this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', false, false)
				// 	.subscribe((result) => {
				// 		let responseData: any = result;
				// 		if (responseData.statusCode == 200) {

				// 			commentObj.imgArr.push(responseData.result);
				// 			const fileObj = commentObj.files.find(element => element.name == obj.name);
				// 			fileObj.serverFileName = responseData.result.fileTitle;
				// 			fileObj.displayName = responseData.result.displayName;
				// 			commentObj.files.forEach(element => {
				// 				element.isProgressBarShown = false;
				// 			});

				// 		} else {
				// 			this.toastr.error(responseData.message);
				// 		}
				// 	}, (err) => {
				// 		console.log(err);
				// 		let errorData: any = err;
				// 		this.toastr.error(errorData.error.message);
				// 	})
			}
		}
	}

	uploadDescriptionFile(event) {
		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];
			if (this.restService.checkFileSize(file, 50)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				formData.append('entity', 'testPlan');
				formData.append('entityId', this.tpid);
				formData.append('file', file);
				var reader = new FileReader();
				const this_ = this;
				var obj;
				reader.onload = function (e: any) {
					obj = { serverFileName: file.name, name: file.name, image: e.target.result, isProgressBarShown: true }
					this_.descriptionFiles.push(obj);
				}

				reader.readAsDataURL(file);

				this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
					.subscribe((event: HttpEvent<any>) => {
						switch (event.type) {

							case HttpEventType.UploadProgress:
								this.descriptionFiles[this.descriptionFiles.length - 1].progress = Math.round(event.loaded / event.total * 100);
								console.log(`Uploaded! ${this.descriptionFiles[this.descriptionFiles.length - 1].progress}%`);
								$('#discription_progress_bar_' + (this.descriptionFiles.length - 1)).attr('aria-valuenow', this.descriptionFiles[this.descriptionFiles.length - 1].progress).css('width', this.descriptionFiles[this.descriptionFiles.length - 1].progress + '%');

								break;
							case HttpEventType.Response:
								let responseData: any = event.body;
								if (responseData.statusCode == 200) {
									this.descriptionImgArr.push(responseData.result);
									this.descriptionFiles[this.descriptionFiles.length - 1].serverFileName = responseData.result.fileTitle;
									this.descriptionFiles[this.descriptionFiles.length - 1].displayName = responseData.result.displayName;
									this_.descriptionFiles.forEach(element => {
										element.isProgressBarShown = false;
									});
									//this.getSprint();
								} else {
									if (responseData.message) {
										this.toastr.error(responseData.message);
									}
								}
								setTimeout(() => {
									this.descriptionFiles[this.descriptionFiles.length - 1].progress = 0;
									$('#discription_progress_bar_' + (this.descriptionFiles.length - 1)).attr('aria-valuenow', this.descriptionFiles[this.descriptionFiles.length - 1].progress).css('width', this.descriptionFiles[this.descriptionFiles.length - 1].progress + '%');

								}, 1500);
						}
					})



				// this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', false, false)
				// 	.subscribe((result) => {
				// 		let responseData: any = result;
				// 		if (responseData.statusCode == 200) {
				// 			this.descriptionImgArr.push(responseData.result);
				// 			this.descriptionFiles[this.descriptionFiles.length - 1].serverFileName = responseData.result.fileTitle;
				// 			this.descriptionFiles[this.descriptionFiles.length - 1].displayName = responseData.result.displayName;
				// 			this_.descriptionFiles.forEach(element => {
				// 				element.isProgressBarShown = false;
				// 			});
				// 			//this.getSprint();
				// 		} else {
				// 			this.toastr.error(responseData.message);
				// 		}
				// 	}, (err) => {
				// 		console.log(err);
				// 		let errorData: any = err;
				// 		this.toastr.error(errorData.error.message);
				// 	})
			}
		}

	}

	deleteTestCase(tcid) {
		this.restService.hitApi('testplan/testcase/remove', { "projectId": this.pid, "testPlanId": this.testPlanId, "testCaseIds": [tcid] }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getTestplan();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	getWatchesUserList() {
		this.restService.hitApi('project/watchlist', { 'projectId': this.pid, 'entity': 'testplan', 'resourceId': this.testPlanId }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.watchesUserList = responseData.result;
					if (this.watchesUserList.length) {
						this.watchesUserList.forEach(element => {
							if (element.watcher == element.userId) {
								element.checked = true;
							} else {
								element.checked = false;
							}
							if (this.userData) {
								if (this.userData.role == 2 || this.userData.role == 3) {
									if (element.role == 1) {
										element.isRemoved = false;
									} else {
										if (element.userId != this.userData.id) {
											element.isRemoved = false;
										} else {
											element.isRemoved = true;
										}
									}
								} else {
									console.log("this.isOwnerthis.isOwner: ", this.isOwner)
									if (!this.isOwner) {
										if (element.isCompanyOwner == 1) {
											if (element.checked) {
												element.isRemoved = false;
											} else {
												element.isRemoved = true;
											}
										} else {
											element.isRemoved = true;

										}
									} else {
										element.isRemoved = true;

									}
								}
							}
						});
					}
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	addRemoveWatch() {
		let selectedCheckboxArr: any = [];
		if (this.watchesUserList.length) {
			this.watchesUserList.forEach(element => {
				if (element.checked) {
					selectedCheckboxArr.push(element.userId);
				}
			});
		}
		// $('.user-watch-checkbox').find('input').each(function () {
		// 	if ($(this).prop("checked")) {
		// 		selectedCheckboxArr.push($(this).val());
		// 	}
		// });

		this.restService.hitApi('project/updateWatchlist', { 'projectId': this.pid, 'entity': 'testplan', 'resourceId': this.testPlanId, "userId": selectedCheckboxArr, "action": 'manage' }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getWatchesUserList();
					this.getTestplan();
					$('#watches_popup_web').modal('hide');
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	AddRemoveCurrentUserWatcher(action) {
		this.restService.hitApi('project/updateWatchlist', { 'projectId': this.pid, 'entity': 'testplan', 'resourceId': this.testPlanId, "action": action }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('.stop-add-watches').slideUp('fast');
					this.getTestplan();
					this.getWatchesUserList();
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	updateAllTestCase() {
		this.allTestCaseChecked = this.testCaseList != null && this.testCaseList.every(t => t.checked);
		this.checkIsSaveButtonDisable()
	}

	someComplete(): boolean {
		if (this.testCaseList == null) {
			return false;
		}
		return this.testCaseList.filter(t => t.checked).length > 0 && !this.allTestCaseChecked;
	}

	setAll(checked: boolean) {
		this.allTestCaseChecked = checked;
		if (this.testCaseList == null) {
			return;
		}
		this.testCaseList.forEach(t => t.checked = checked);
		this.checkIsSaveButtonDisable();
	}

	someWatchComplete(): boolean {
		if (this.watchesUserList == null) {
			return false;
		}
		return this.watchesUserList.filter(t => t.checked).length > 0 && !this.allWatchChecked;
	}

	updateAllUserWatch() {
		this.allWatchChecked = this.watchesUserList != null && this.watchesUserList.every(t => t.checked);
	}


	setAllWatched(checked: boolean) {
		this.allWatchChecked = checked;
		if (this.watchesUserList == null) {
			return;
		}
		this.watchesUserList.forEach(t => t.checked = checked);
	}

	resetAllCheckedValues() {
		$('#search_name').val('');
		this.searchString = null;
		this.allTestCaseChecked = false;
		this.testcaseArr = [];

	}
	checkIsSaveButtonDisable() {
		this.isSaveDisable = false;
		if (this.testCaseList && this.testCaseList.length > 0) {
			var filterList = this.testCaseList.filter(el => el.checked);
			if (filterList && filterList.length > 0) {
				this.isSaveDisable = false;
			} else {
				this.isSaveDisable = true;
			}
		}
		//this.getTestcaseList();

	}
	showMore() {
		this.isShowMore = true;
		$('.moretext').slideDown();

	}

	showLess() {
		this.isShowMore = false;
		$('.moretext').slideUp();

	}

	addRemovedTestCase(testCase) {
		var json = {
			projectId: this.pid,
			testCaseId: testCase?.projectTestCaseId,
			testPlanId: this.testPlanId
		}
		this.restService.hitApi('testplan/report/addMappedTestCase', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.toastr.success(responseData.message);
					this.getTestplan();
				} else {
					this.toastr.error(responseData.message);
				}

			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})

	}
	removeTestCase(testCase) {
		var json = {
			projectId: this.pid,
			testCaseId: testCase?.projectTestCaseId,
			testPlanId: this.testPlanId
		}
		this.restService.hitApi('testplan/report/removeMappedTestCase', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.toastr.success(responseData.message);
					this.getTestplan();

				} else {
					this.toastr.error(responseData.message);
				}

			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	getTestCaseReport(testCase) {
		this.selectedTestCase = testCase;
		var json = {
			projectId: this.pid,
			testCaseId: testCase?.projectTestCaseId,
			testPlanId: this.testPlanId
		}
		this.restService.hitApi('testplan/report/getTestCaseReport', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.testCaseReports = responseData.result;
				} else {
					this.toastr.error(responseData.message);
				}

			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	backToRetun() {
		this.isNotStoreHistory = true;
		this.location.back();
		// var lastIndex = this.routerHistory.length - 1;
		// this.routerHistory.splice(lastIndex, 1);
		// var previousUrl = this.routerHistory[this.routerHistory.length - 1];
		// this.router.navigate([previousUrl]);
		// if (this.routeEventsService.previousRoutePath.value == '/dashboard') {
		// 	this.router.navigate([this.routeEventsService.previousRoutePath.value]);
		// } else {
		// 	this.router.navigate(['/testplans']);
		// }

	}

	goToTestCase(entityId) {
		localStorage.setItem('returnTo', "/testplan");
		this.router.navigateByUrl('/testcase/' + entityId);
	}

	getExtensionOfFileType(fileName) {
		if (fileName) {
			var str = fileName.split(".");
			if (str[str.length - 1] == 'jpg' || str[str.length - 1] == 'png' || str[str.length - 1] == 'jpeg' || str[str.length - 1] == 'JPG' || str[str.length - 1] == 'PNG') {
				return 'image'
			} else {
				return 'file'
			}

		}
	}

	removeAttachment(fileObj, index, commentObj) {
		this.deletedAttachmentItems.push(fileObj.serverFileName)

		if (commentObj != undefined && commentObj != null) {
			commentObj.files.splice(index, 1);
			commentObj.imgArr.splice(0, 1);

		} else {
			this.files.splice(index, 1);
			this.imgArr.splice(0, 1);
		}


		// console.log("fileObj", fileObj);
		// //var fileObj = this.files[index];
		// var deleteFileArray = [];
		// var deleteFileArray = [fileObj.serverFileName];
		// if (commentObj) {
		// 	if (fileObj.id) {
		// 		if (deleteFileArray.length > 0) {
		// 			this.deleteAttachment(deleteFileArray);
		// 		}

		// 	} else {
		// 		if (deleteFileArray.length > 0) {
		// 			this.removeAttachmentFromServer(deleteFileArray, true);

		// 		}
		// 	}
		// 	commentObj.files.splice(index, 1);
		// 	commentObj.imgArr.splice(0, 1);


		// } else {
		// 	if (deleteFileArray.length > 0) {
		// 		this.removeAttachmentFromServer(deleteFileArray, true);

		// 	}
		// 	this.files.splice(index, 1);
		// 	this.imgArr.splice(0, 1);
		// }

	}

	removeDescriptionAttachment(fileObj, index) {
		this.deletedDescriptionItems.push(fileObj.serverFileName);
		this.descriptionFiles.splice(index, 1);
		this.descriptionImgArr.splice(index, 1);

	}
	updateDescriptionAttachment() {
		if (this.deletedDescriptionItems && this.deletedDescriptionItems.length > 0) {
			this.removeDescriptionAttachmentFromServer();
		} else {
			this.updateTestplan('description');
		}
	}

	updateAttachment(obj, field) {
		if (this.deletedAttachmentItems && this.deletedAttachmentItems.length > 0) {
			this.removeAttachmentFromServer(obj, field);
		} else {
			this.addComment(obj, field);
		}
	}
	// addDescription() {
	// 	if (this.deletedDescriptionItems && this.deletedDescriptionItems.length > 0) {
	// 		this.deleteUploadedFilesEntity(this.deletedDescriptionItems);
	// 	} else {
	// 		this.updateTestplan('description');
	// 	}
	// }
	// updateCommentAttachment(fileObj, index, commentObj) {
	// 	if (this.deletedAttachmentItems && this.deletedAttachmentItems.length > 0) {
	// 		this.removeAttachmentFromServer(fileObj, index, commentObj);
	// 	} else {
	// 		this.addComment(fileObj, field);
	// 	}
	// }

	removeDescriptionAttachmentFromServer() {

		var json = {
			projectId: this.pid,
			entity: 'testPlan',
			files: this.deletedDescriptionItems
		}

		this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.deletedDescriptionItems = [];
					this.updateTestplan('description');
				} else {
					this.toastr.success(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	removeAttachmentFromServer(obj, field) {

		var json = {
			projectId: this.pid,
			entity: 'testPlan',
			files: this.deletedAttachmentItems
		}

		this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.deletedDescriptionItems = [];
					this.addComment(obj, field);
				} else {
					this.toastr.success(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}
	deleteUploadedFilesEntity(files) {

		var json = {
			projectId: this.pid,
			entity: 'testPlan',
			files: files
		}

		this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					//this.getTestplan();
				} else {

				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	navigateToTestCaseDetail(id, tabNum) {
		var params: NavigationExtras = {
			queryParams: { tab: tabNum }
		}
		this.router.navigate(['/testcase/' + id], {
			state: { data: params }
		});

	}

	searchData() {

		this.searchString = $('#search_name').val();
		this.getTestcaseList();
	}
	setTabActive(activeTab) {
		this.tabActive = activeTab;
		this.redirectTab();

		//event.preventDefault();

	}
	redirectTab() {
		if (this.tabActive == 0) {
			$('#myTabs a[href="#testplan-detail-tab"]').tab('show');
		} else if (this.tabActive == 1) {
			$('#myTabs a[href="#testplan-report-tab"]').tab('show');
		} else {
			$('#myTabs a[href="#testplan-activity-tab"]').tab('show');
		}
	}

	download(url, fileName, $event) {
		$event.stopPropagation();
		this.restService.downloadFile(url, fileName);
	}

}

