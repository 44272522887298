import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WindowRefService } from '../window-ref.service';

import { RestService } from '../services/rest/rest.service';
import { CookieService } from 'angular2-cookie/services/cookies.service';
declare const $: any;
@Component({
	selector: 'app-subscription',
	templateUrl: './subscription.component.html',
	styleUrls: ['./subscription.component.scss'],
	providers: [CookieService]
})
export class SubscriptionComponent implements OnInit {
	loginForm: FormGroup;
	errorMessage: string = "";
	subscriptionData: any = [];
	subscription_id: any;
	constructor(
		private router: Router,
		private restService: RestService,
		private toastr: ToastrService,
		private winRef: WindowRefService
	) {
		//this.getSubscriptions();
	}

	ngOnInit() {
		if (!localStorage.getItem('userData')) {
			this.router.navigate(['/login']);
		} else {
			//this.getSubscriptions();
		}
	}

	getSubscriptions() {
		console.log($('#subscription_term').is(':checked'));
		let frequency = $('#subscription_term').is(':checked') ? 1 : 2;
		this.restService.hitApi('users/subscription', { 'frequency': frequency }, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.subscriptionData = responseData.result;
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	saveSubscription(pkid) {
		this.restService.hitApi('users/set-subscription-package', { 'packageId': pkid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.subscription_id = responseData.result.id;
					console.log(responseData);
					if (responseData.paymentPopup == 1) {
						this.createRzpayOrder(this.subscription_id);
					} else {
						this.getSubscriptions();
						this.toastr.success(responseData.message);
					}
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	createRzpayOrder(data) {
		//console.log(data);
		this.payWithRazor(data);
	}

	payWithRazor(val) {
		const options: any = {
			//key: 'rzp_test_ycqUFb222gYfqs', //dev test
			key: 'rzp_live_7a5MZfvNIYtULv', // prod key
			amount: 20000, // amount should be in paise format to display Rs 1255 without decimal point
			currency: 'INR',
			name: 'Trakk.in', // company name or product name
			description: '',  // product description
			image: 'http://qa.trakk.in/assets/images/new_web_logo.png', // company logo or product image
			subscription_id: val, // order_id created by you in backend
			modal: {
				// We should prevent closing of the form when esc key is pressed.
				escape: false,
			},
			notes: {
				// include notes if any
			},
			theme: {
				color: '#0c238a'
			}
		};
		options.handler = ((response, error) => {

			let requestData: any = { 'razorpay_payment_id': response.razorpay_payment_id, 'razorpay_subscription_id': response.subscription_id, 'razorpay_signature': response.razorpay_signature };
			//   let generated_signature = hmac_sha256(response.subscription_id + "|" + response.razorpay_payment_id)
			this.restService.hitApi('users/verify-subscription', requestData, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						//localStorage.setItem('userData', JSON.stringify(responseData.result));
						//this.router.navigate(['user-management']);
						this.router.navigate(['/login']);
						this.toastr.success(responseData.message);
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					this.toastr.error(err.error.message);
				})
		});
		options.modal.ondismiss = (() => {
			// handle the case when user closes the form while transaction is in progress
			console.log('Transaction cancelled.');
		});
		const rzp = new this.winRef.nativeWindow.Razorpay(options);
		rzp.open();
	}

	cancelSubscription() {
		this.restService.hitApi('users/cancel-subscription-package', {}, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					// this.subscription_id = responseData.result.id;
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}



}
