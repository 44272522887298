import { setValue } from '@syncfusion/ej2-base';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { RestService } from '../services/rest/rest.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { Location } from '@angular/common';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { DateConverterService } from '../services/date-formatter/date-converter.service';
import { PreservedSearchStateService } from '../services/search/preserved-search-state.service';
import { createFalse } from 'node_modules1111/@schematics/angular/third_party/github.com/Microsoft/TypeScript/lib/typescript';
import { stubFalse } from 'node_modules1111/@types/lodash';
import { BroadcastService } from '../services/broadcast/broadcast.service';
import { RouteEventsService } from '../services/route-events.service';
import { DatePipe } from '@angular/common'
import * as moment from 'moment';




const FileSaver = require('file-saver');
declare const $: any;

@Component({
  selector: 'app-serach-filter',
  templateUrl: './serach-filter.component.html',
  styleUrls: ['./serach-filter.component.scss']
})

export class SerachFilterComponent implements OnInit {

  userData: any = JSON.parse(localStorage.getItem("userData"));
  filterData: any;
  filterDataList = [];
  projectListData: any;
  selectedProject: any;
  selectedProjectId: any;
  allStandardIssueType: boolean = false;
  allSubTaskIssueType: boolean = false;
  isUnassigned: boolean = false;
  statusListWithBacklog = [{ name: 'Backlog', value: 0, checked: false }, { name: 'To Do', value: 1, checked: false }, { name: 'In Progress', value: 2, checked: false }, { name: 'Quality Check', value: 3, checked: false }, { name: 'Completed', value: 4, checked: false }, { name: 'Reopen', value: 5, checked: false }, { name: 'Not A Bug', value: 6, checked: false }]
  statusListWithoutBacklog = [{ name: 'To Do', value: 1, checked: false }, { name: 'In Progress', value: 2, checked: false }, { name: 'Quality Check', value: 3, checked: false }, { name: 'Completed', value: 4, checked: false }, { name: 'Reopen', value: 5, checked: false }, { name: 'Not A Bug', value: 6, checked: false }]
  statusList = this.statusListWithoutBacklog
  priorityList = [{ name: 'Critical', value: 1, checked: false }, { name: 'High', value: 2, checked: false }, { name: 'Medium', value: 3, checked: false }, { name: 'Low', value: 4, checked: false }];
  standardIssueTypes = [{ name: 'Bug', value: 'defect', icon: './assets/images/new-bug-icon.svg', checked: false }, { name: 'User Story', value: 'userStory', icon: './assets/images/new-us-icon.svg', checked: false },
  { name: 'Test Plan', value: 'testPlan', icon: './assets/images/new-tp-icon.svg', checked: false }, { name: 'Task', value: 'task', icon: './assets/images/new-task-icon.svg', checked: false }]
  assigneeList: any = [];
  originalAssigneeList: any = [];
  reporterList: any = [];
  originalReporterList: any = [];
  selectedStatus: string;
  selectedPriority: string;
  selectedDateType: string = 'None';
  selectedType: string;
  selectedAssignee: string;
  selectedReporter: string;
  searchQuery: string = '';
  isLoading: boolean = false;
  type: string;
  myOpenIssue: number;
  reportedByMe: number;
  openIssue: number;
  doneIssue: number;
  updateRecently: number;
  resolveRecently: number;
  pageEvent: PageEvent;
  datasource: null;
  pageIndex: number = 0;
  pageSize: number = 10;
  length: number = 0;
  createdAt: any;
  updatedAt: any;
  projectSearchString: any = '';
  assigneeSearchString: any = '';
  reporterSearchString: any = '';
  selectedDateTypeForServer: any = "";
  isDateValid: boolean = true;
  selectedAssignees: any[];
  selectedReporters: any[];
  sortOrder: any = 'desc';
  sortByField: any = 'createdAt';
  title: any = 'All Items'

  public myStartDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'dd-mm-yyyy',
    disableUntil: { year: 0, month: 0, day: 0 },
    disableSince: { year: 0, month: 0, day: 0 }

  };

  public myEndDatePickerOptions: IMyDpOptions = {
    // other options
    dateFormat: 'dd-mm-yyyy',
    disableUntil: { year: 0, month: 0, day: 0 },
    disableSince: { year: 0, month: 0, day: 0 }
  };

  typeList: any[];
  showProjectSelectionPopup: boolean;
  lastSearch: any;
  selectedTypeList: any[];
  selectedStatusList: any[];
  selectedPriorityList: any[];
  isFromMenuSelection: boolean = false;
  isLastFilterStateFetching: boolean = true;
  param: any;
  newQuery: string;

  constructor(private restService: RestService, private toastr: ToastrService, private activatedRoute: ActivatedRoute, private router: Router, private location: Location, private dateService: DateConverterService, private preserveSearch: PreservedSearchStateService, private broadcastService: BroadcastService, private routeEventsService: RouteEventsService, public datepipe: DatePipe) {
    this.selectedProjectId = localStorage.getItem('projectSelected');
    if (this.selectedProjectId == null || this.selectedProjectId == undefined || this.selectedProjectId == '') {
      this.showProjectSelectionPopup = true;

    } else {
      this.showProjectSelectionPopup = false;
    }
    this.selectedType = 'Type';
    this.selectedStatus = 'Status';
    this.selectedPriority = 'Priority';
    this.selectedDateType = 'None';
    this.selectedAssignee = 'Assignee';
    this.selectedReporter = 'Reporter';
    this.projectSearchString = '';
    this.assigneeSearchString = '';
    this.reporterSearchString = '';
    this.selectedDateTypeForServer = "";


    // this.location.onUrlChange((url) => {
    //   console.log("url", url);
    //   if (url.includes('search-filter')) {
    //     console.log("this.routeEventsService.previousRoutePath.value", this.routeEventsService.previousRoutePath.value);
    //     var lastRedirecturl: string = this.routeEventsService.previousRoutePath.value;

    //     // if (lastRedirecturl.includes('search-filter')) {
    //     //   this.lastSearch = null;
    //     //   this.resetValues();
    //     // }

    //   }

    // });

    if (this.userData?.role == 3) {
      this.standardIssueTypes = [{ name: 'Bug', value: 'defect', icon: './assets/images/new-bug-icon.svg', checked: false }]
    }

    this.broadcastService.subscribe("RECOVER_LAST_STATE", () => {
      //this.recoverLastSearchValue();
    });
  }


  ngOnInit() {
    var that = this;
    console.log("ng on init call");
    $(document).ready(function () {
      if (that.showProjectSelectionPopup) {
        $('#project-selection-popup').modal('show');
      }
      $('.date input').datepicker({
        format: 'dd-mm-yyyy',
        autoclose: true,
        todayHighlight: true,
      }).on('changeDate', function (e) {
        $('.datepicker').hide();
      });

      $(".word-limit-select").text(function (index, currentText) {
        var maxLength = $(this).parent().attr('data-maxlength');
        if (currentText.length >= maxLength) {
          return currentText.substr(0, maxLength) + "...";
        } else {
          return currentText
        }
      });

    });

    $(document).on('click.bs.dropdown.data-api', '.dropdown-menu.custom-dropdown-menu.filter-dropdown', function (e) {
      e.stopPropagation();
    });

    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (this.pageEvent == null) {
        this.pageEvent = new PageEvent();
        this.pageEvent.length = this.length;
        this.pageEvent.pageIndex = this.pageIndex;
        this.pageEvent.pageSize = this.pageSize;
      }
      console.log("queryParams", queryParams);
      console.log(":last search", this.lastSearch);
      // do something with the query params
      this.param = queryParams['filter'];
      if (queryParams['Type'] != undefined) {
        this.selectedType = queryParams['Type'];
        this.recoverLastSelectedValues();
      }
      if (queryParams['Status'] != undefined) {
        this.selectedStatus = queryParams['Status'];
        this.recoverLastSelectedValues();
      }
      if (queryParams['Assignee'] != undefined) {
        this.selectedAssignee = queryParams['Assignee'];
        this.recoverLastSelectedValues();
      }
      if (queryParams['Reporter'] != undefined) {
        this.selectedReporter = queryParams['Reporter'];
        this.recoverLastSelectedValues();
      }
      if (queryParams['Priority'] != undefined) {
        this.selectedPriority = queryParams['Priority'];
        this.recoverLastSelectedValues();
      }

      if (queryParams['q'] != undefined) {
        this.searchQuery = queryParams['q']
      }

      if (queryParams['pid'] != undefined) {
        this.selectedProjectId = queryParams['pid']
        this.recoverLastSelectedValues();
      }

      if (queryParams['pageIndex'] != undefined) {
        this.pageEvent.pageIndex = parseInt(queryParams['pageIndex'])

      }
      if (queryParams['pageSize'] != undefined) {
        this.pageEvent.pageSize = parseInt(queryParams['pageSize'])

      }
      if (queryParams['pageLength'] != undefined) {
        this.pageEvent.length = parseInt(queryParams['pageLength'])

      }
      if (queryParams['dateType'] != undefined) {
        this.selectedDateType = queryParams['dateType'];
        if (this.selectedDateType == "None") {
          this.selectedDateTypeForServer = "";
        } else if (this.selectedDateType == "Created At") {
          this.selectedDateTypeForServer = "createdAt";
        } else if (this.selectedDateType == "Updated At") {
          this.selectedDateTypeForServer = "updatedAt";
        }
      }
      if (queryParams['createdAtDate'] != undefined) {
        var date = moment(queryParams['createdAtDate'], 'DD-MM-YYYY').format('YYYY-MM-DD');
        var createdDate = new Date(date)

        // var strDate = this.dateService.convertInDDMMYYYFormate(date)
        this.createdAt = { date: { year: createdDate.getFullYear(), month: createdDate.getMonth() + 1, day: createdDate.getDate() }, formatted: queryParams['createdAtDate'], jsdate: createdDate };;


      }
      if (queryParams['updatedAtDate'] != undefined) {
        var date = moment(queryParams['updatedAtDate'], 'DD-MM-YYYY').format('YYYY-MM-DD');
        var updatedDate = new Date(date)
        // var strDate = this.dateService.convertInDDMMYYYFormate(date)
        this.updatedAt = { date: { year: updatedDate.getFullYear(), month: updatedDate.getMonth() + 1, day: updatedDate.getDate() }, formatted: queryParams['updatedAtDate'], jsdate: updatedDate };;

      }

      this.newQuery = 'filter=' + this.param;
      console.log("param", this.param);
      if (this.param == '1') {
        this.title = 'All items'
      } else {
        this.title = 'All items'
      }
      if (this.param == '2') {
        this.myOpenIssue = 1;
        this.reportedByMe = 0;
        this.openIssue = 0;
        this.doneIssue = 0;
        this.resolveRecently = 0;
        this.updateRecently = 0;
        this.title = 'My open issues'
      } else {
        this.myOpenIssue = 0;
      }
      if (this.param == '3') {
        this.reportedByMe = 1;
        this.myOpenIssue = 0;
        this.openIssue = 0;
        this.doneIssue = 0;
        this.resolveRecently = 0;
        this.updateRecently = 0;
        this.title = 'Reported by me'
      } else {
        this.reportedByMe = 0;
      }
      if (this.param == '4') {
        this.openIssue = 1;
        this.myOpenIssue = 0;
        this.reportedByMe = 0;
        this.doneIssue = 0;
        this.resolveRecently = 0;
        this.updateRecently = 0;
        this.title = 'Open items'
      } else {
        this.openIssue = 0;
      }
      if (this.param == '5') {
        this.doneIssue = 1;
        this.myOpenIssue = 0;
        this.reportedByMe = 0;
        this.openIssue = 0;
        this.resolveRecently = 0;
        this.updateRecently = 0;
        this.title = 'Done items'
      } else {
        this.doneIssue = 0;
      }
      if (this.param == '6') {
        this.resolveRecently = 1;
        this.myOpenIssue = 0;
        this.reportedByMe = 0;
        this.openIssue = 0;
        this.doneIssue = 0;
        this.updateRecently = 0;
        this.title = 'Resolve recently'
      } else {
        this.resolveRecently = 0;
      }
      if (this.param == '7') {
        this.updateRecently = 1;
        this.myOpenIssue = 0;
        this.reportedByMe = 0;
        this.openIssue = 0;
        this.doneIssue = 0;
        this.resolveRecently = 0;
        this.title = 'Update recently'
      } else {
        this.updateRecently = 0;
      }

      this.isLoading = true;

      this.getProjectList();


    });

  }

  ngOnDestroy() {
    this.preserveSearch.searchFilterState = {
      searchValue: this.searchQuery,
      projectId: this.selectedProjectId,
      pageEvent: this.pageEvent,
      selectedType: this.selectedType,
      selectedStatus: this.selectedStatus,
      selectedAssignee: this.selectedAssignee,
      selectedReporter: this.selectedReporter,
      selectedPriority: this.selectedPriority,
      selectedDateType: this.selectedDateType,
      assigneeList: this.getFilterAssigneeList(),
      reporterList: this.getFilterReporterList(),
      typeList: this.getFilterTypeList(),
      statusList: this.getFilterStatus(),
      priorityList: this.getFilterPriority(),
      createdAt: this.createdAt,
      updatedAt: this.updatedAt
    };
  }

  recoverLastSelectedValues() {
    if (this.selectedType != null) {
      if (this.selectedType == 'All Standard Issues Types') {
        this.allStandardIssueType = true;
      } else if (this.selectedType == 'All Sub-Task Issues Types') {
        this.allSubTaskIssueType = true;
      } else {
        this.allStandardIssueType = false;
        this.allSubTaskIssueType = false;

        this.standardIssueTypes.forEach(el => {
          let array = this.selectedType?.split(',')
          if (array != null && array.length > 0) {
            for (let i = 0; i < array.length; i++) {
              if (el.name == array[i]) {
                el.checked = true
                break
              } else {
                el.checked = false
              }
            }
          }
        })
      }
    }
    if (this.selectedStatus != null) {
      this.statusList.forEach(el => {
        let array = this.selectedStatus?.split(',')
        if (array != null && array.length > 0) {
          for (let i = 0; i < array.length; i++) {
            if (el.name == array[i]) {
              el.checked = true
              break
            } else {
              el.checked = false
            }
          }
        }
      })
    }

    if (this.selectedPriority != null) {
      this.priorityList.forEach(el => {
        let array = this.selectedPriority?.split(',')
        if (array != null && array.length > 0) {
          for (let i = 0; i < array.length; i++) {
            if (el.name == array[i]) {
              el.checked = true
              break
            } else {
              el.checked = false
            }
          }
        }
      })
    }

    if (this.selectedDateType == "None") {
      this.selectedDateTypeForServer = "";
    } else if (this.selectedDateType == "Created At") {
      this.selectedDateTypeForServer = "createdAt";
    } else if (this.selectedDateType == "Updated At") {
      this.selectedDateTypeForServer = "updatedAt";
    }
    if (this.selectedAssignee) {
      if (this.preserveSearch.searchFilterState != null) {
        this.selectedAssignees = this.preserveSearch.searchFilterState?.assigneeList;
      } else {
        this.assigneeList.forEach(el => {
          let array = this.selectedAssignee?.split(',')
          if (array != null && array.length > 0) {
            for (let i = 0; i < array.length; i++) {
              if (el.name == array[i]) {
                el.checked = true
                break
              } else {
                el.checked = false
              }
            }
          }
        })
      }
    }
    if (this.selectedReporter) {
      if (this.preserveSearch.searchFilterState != null) {
        this.selectedReporters = this.preserveSearch.searchFilterState?.reporterList;
      } else {
        this.reporterList.forEach(el => {
          let array = this.selectedReporter?.split(',')
          if (array != null && array.length > 0) {
            for (let i = 0; i < array.length; i++) {
              if (el.name == array[i]) {
                el.checked = true
                break
              } else {
                el.checked = false
              }
            }
          }
        })
      }
    }

    if (this.selectedProjectId && this.projectListData && this.projectListData.length > 0) {
      this.projectListData.forEach(element => {
        if (element.id == this.selectedProjectId) {
          this.selectedProject = element;
          element.checked = false;
        }
      });
    }

  }

  recoverLastSearchValue() {
    console.log("preserveSearch1234", this.preserveSearch)
    this.lastSearch = this.preserveSearch.searchFilterState;
    if (this.lastSearch) {
      console.log("lastSearch", this.lastSearch);
      this.searchQuery = this.lastSearch.searchValue,
        this.pageEvent = this.lastSearch.pageEvent,
        this.selectedProjectId = this.lastSearch.projectId;
      this.selectedType = this.lastSearch.selectedType;
      this.selectedStatus = this.lastSearch.selectedStatus;
      this.selectedAssignee = this.lastSearch.selectedAssignee;
      this.selectedReporter = this.lastSearch.selectedReporter;
      this.selectedPriority = this.lastSearch.selectedPriority;
      this.selectedDateType = this.lastSearch.selectedDateType;
      this.selectedAssignees = this.lastSearch.assigneeList;
      this.selectedReporters = this.lastSearch.reporterList;
      this.selectedTypeList = this.lastSearch.typeList
      this.selectedStatusList = this.lastSearch.statusList
      this.selectedPriorityList = this.lastSearch.priorityList,
        this.createdAt = this.lastSearch.createdAt,
        this.updatedAt = this.lastSearch.updatedAt

    }

    if (this.selectedType != null) {
      if (this.selectedType == 'All Standard Issues Types') {
        this.allStandardIssueType = true;
      } else if (this.selectedType == 'All Sub-Task Issues Types') {
        this.allSubTaskIssueType = true;
      } else {
        this.allStandardIssueType = false;
        this.allSubTaskIssueType = false;

        this.standardIssueTypes.forEach(el => {
          let array = this.selectedType?.split(',')
          if (array != null && array.length > 0) {
            for (let i = 0; i < array.length; i++) {
              if (el.name == array[i]) {
                el.checked = true
                break
              } else {
                el.checked = false
              }
            }
          }
        })
      }
    }

    if (this.selectedStatus != null) {
      this.statusList.forEach(el => {
        let array = this.selectedStatus?.split(',')
        if (array != null && array.length > 0) {
          for (let i = 0; i < array.length; i++) {
            if (el.name == array[i]) {
              el.checked = true
              break
            } else {
              el.checked = false
            }
          }
        }
      })
    }
    if (this.selectedPriority != null) {
      this.priorityList.forEach(el => {
        let array = this.selectedPriority?.split(',')
        if (array != null && array.length > 0) {
          for (let i = 0; i < array.length; i++) {
            if (el.name == array[i]) {
              el.checked = true
              break
            } else {
              el.checked = false
            }
          }
        }
      })
    }
    if (this.selectedDateType == "None") {
      this.selectedDateTypeForServer = "";
    } else if (this.selectedDateType == "Created At") {
      this.selectedDateTypeForServer = "createdAt";
    } else if (this.selectedDateType == "Updated At") {
      this.selectedDateTypeForServer = "updatedAt";
    }
  }

  resetProjectSearch() {
    this.projectSearchString = '';
    this.getProjectList();
  }
  resetAssigneeSearch() {
    this.assigneeSearchString = '';
    this.getAssigneeList();
  }
  resetReporterSearch() {
    this.reporterSearchString = '';
    this.getReporterList();
  }

  searchProject() {
    this.projectSearchString = $('#search_project_name').val();
    console.log("i am searching", this.projectSearchString);
    if (this.selectedProjectId != null && this.selectedProjectId != undefined && this.selectedProjectId != '') {
      this.getProjectList();

    }
  }

  getProjectList() {
    let postdata = {
      "q": this.projectSearchString
    };

    this.restService.hitApi('project/project-list', postdata, 'post', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        this.isLoading = false;

        if (responseData.statusCode == 200) {
          this.projectListData = responseData.result;
          console.log('projectListData', this.projectListData);
          if (this.projectListData.length > 0) {
            if (this.selectedProjectId) {
              this.projectListData.forEach(element => {
                if (element.id == this.selectedProjectId) {
                  this.selectedProject = element;
                  element.checked = true;

                  this.setStatusList(element)

                } else {
                  element.checked = false;
                }
              });
              console.log("SELECTED_PROJECT-", this.selectedProject);
              this.getAssigneeList();
              this.getReporterList();
              this.getSearchFilterData(this.pageEvent);


            }

          }
          if (this.selectedProjectId && this.projectListData && this.projectListData.length > 0) {
            this.projectListData.forEach(element => {
              if (element.id == this.selectedProjectId) {
                this.selectedProject = element;
                element.checked = true;
              }
            });
          }

        } else {
          this.isLoading = false;
          this.toastr.error(responseData.message);
        }
      }, (err) => {
        this.isLoading = false;
        console.log(err);
        let errorData: any = err;
        this.toastr.error(errorData.error.message);
      });
  }

  searchAssignee() {
    this.assigneeSearchString = $('#search_assignee_name').val();
    console.log("i am searching", this.assigneeSearchString);
    this.getAssigneeList();
  }

  getAssigneeList() {
    if (this.selectedProjectId) {
      let postdata = {
        'projectId': this.selectedProjectId,
        "q": this.assigneeSearchString
      };

      this.restService.hitApi('project/projectUsers', postdata, 'post', '', false)
        .subscribe((result) => {
          let responseData: any = result;

          if (responseData.statusCode == 200) {
            this.assigneeList = responseData.result;

            if (this.assigneeList) {

              this.assigneeList.forEach(element => {

                if (this.myOpenIssue == 1) {
                  if (element.userId == this.userData.id) {
                    this.selectedAssignee = element.name;
                  }
                }

                let array = this.selectedAssignee?.split(',')
                console.log("arrayarray: ", array)
                if (array != null && array.length > 0) {
                  for (let i = 0; i < array.length; i++) {
                    console.log("arrayarray: element.name ", element.name)
                    console.log("arrayarray: array[i] ", array[i])
                    if (element.name == array[i]) {
                      element.checked = true
                      break
                    } else {
                      element.checked = false
                    }
                  }
                }


              });
            }


          } else {
            this.isLoading = false;
            this.toastr.error(responseData.message);
          }

        }, (err) => {
          this.isLoading = false;
          console.log(err);
          let errorData: any = err;
          this.toastr.error(errorData.error.message);
        })
    }
  }

  searchReporter() {
    this.reporterSearchString = $('#search_reporter_name').val();
    console.log("i am searching", this.reporterSearchString);
    this.getReporterList();
  }

  getReporterList() {
    if (this.selectedProjectId) {
      let postdata = {
        'projectId': this.selectedProjectId,
        "q": this.reporterSearchString
      };
      this.restService.hitApi('project/projectUsers', postdata, 'post', '', false)
        .subscribe((result) => {
          let responseData: any = result;
          if (responseData.statusCode == 200) {
            this.reporterList = responseData.result;
            var lastSelectedReporterList = [];
            if (this.lastSearch != null) {
              lastSelectedReporterList = this.lastSearch.reporterList;
            }
            if (this.reporterList) {
              this.reporterList.forEach(element => {

                let array = this.selectedReporter?.split(',')
                console.log("arrayarray: ", array)
                if (array != null && array.length > 0) {
                  for (let i = 0; i < array.length; i++) {
                    console.log("arrayarray: element.name ", element.name)
                    console.log("arrayarray: array[i] ", array[i])
                    if (element.name == array[i]) {
                      element.checked = true
                      break
                    } else {
                      element.checked = false
                    }
                  }
                }
                if (this.reportedByMe == 1) {
                  if (element.userId == this.userData.id) {
                    this.selectedReporter = element.name;
                    if (this.selectedReporter) {
                      if (element.name == this.selectedReporter) {
                        element.checked = true
                      } else {
                        element.checked = false
                      }
                    }
                  }
                }

              });
            }


          } else {
            this.isLoading = false;
            this.toastr.error(responseData.message);
          }
        }, (err) => {
          this.isLoading = false;
          console.log(err);
          let errorData: any = err;
          this.toastr.error(errorData.error.message);
        })
    }
  }

  startDateChange(event) {
    var strDate;
    if (event.epoc > 0) {
      var date = event.jsdate;
      strDate = this.dateService.convertInDDMMYYYFormate(date)
      this.createdAt = { date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }, formatted: strDate, jsdate: date };;

    } else {
      this.createdAt = null;
      strDate = null;

    }
    this.disableEndUntil(event.jsdate);
    this.resetPageEvent();
    this.makeNewUrl();
    this.getSearchFilterData(this.pageEvent);
    //this.createdAtDateChange(event);
  }

  endDateChange(event) {
    var strDate;
    if (event.epoc > 0) {
      var date = event.jsdate;
      strDate = this.dateService.convertInDDMMYYYFormate(date)
      this.updatedAt = { date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }, formatted: strDate, jsdate: date };;

    } else {
      this.updatedAt = null;
      strDate = null;

    }
    this.disableStartSince(event.jsdate);
    this.resetPageEvent();
    this.makeNewUrl();
    this.getSearchFilterData(this.pageEvent);

    //this.updatedAtDateChange(event);
  }

  disableEndUntil(startDate) {
    if (startDate) {
      startDate.setDate(startDate.getDate() - 1);
      let copy = this.getCopyOfEndDateOptions();
      copy.disableUntil = {
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
        day: startDate.getDate()
      };
      this.myEndDatePickerOptions = copy;
    } else {
      let copy = this.getCopyOfEndDateOptions();
      copy.disableUntil = {
        year: 0,
        month: 0,
        day: 0
      };
      this.myEndDatePickerOptions = copy;
    }

  }

  // Returns copy of myOptions
  getCopyOfEndDateOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myEndDatePickerOptions));
  }

  disableStartSince(endDate) {
    if (endDate) {
      endDate.setDate(endDate.getDate() - 1);
      let copy = this.getCopyOfStartDateOptions();
      copy.disableSince = {
        year: endDate.getFullYear(),
        month: endDate.getMonth() + 1,
        day: endDate.getDate()
      };
      this.myStartDatePickerOptions = copy;
    } else {
      let copy = this.getCopyOfStartDateOptions();
      copy.disableSince = {
        year: 0,
        month: 0,
        day: 0
      };
      this.myStartDatePickerOptions = copy;
    }

  }

  // Returns copy of myOptions
  getCopyOfStartDateOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myStartDatePickerOptions));
  }

  createdAtDateChange(event) {
    var strDate;
    if (event.epoc > 0) {
      var date = event.jsdate;
      strDate = this.dateService.convertInDDMMYYYFormate(date)
      this.createdAt = { date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }, formatted: strDate, jsdate: date };;
    } else {
      this.createdAt = null;
      strDate = null;
    }
    this.resetPageEvent();

    this.getSearchFilterData(this.pageEvent);
  }

  updatedAtDateChange(event) {
    var strDate;
    if (event.epoc > 0) {
      var date = event.jsdate;
      strDate = this.dateService.convertInDDMMYYYFormate(date)
      this.updatedAt = { date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }, formatted: strDate, jsdate: date };;
    } else {
      this.updatedAt = null;
      strDate = null;
    }
    this.resetPageEvent();

    this.getSearchFilterData(this.pageEvent);
  }

  searchData() {
    if (this.searchQuery.length >= 2 || this.searchQuery.length == 0) {
      this.pageEvent = new PageEvent();
      this.pageEvent.length = 0;
      this.pageEvent.pageIndex = 0;
      this.pageEvent.pageSize = 10;

      this.makeNewUrl();

      this.getSearchFilterData(this.pageEvent);
    }
  }

  getSearchFilterData(event?: PageEvent) {
    console.log("page event", event)
    //this.makeNewUrl(event)


    var filterData: any = {};
    filterData.projectId = this.selectedProject?.id;
    //filterData.type = this.getFilterTypeList();
    filterData.assignee = this.getFilterAssigneeList();
    filterData.reporter = this.getFilterReporterList();
    filterData.status = this.getFilterStatus();
    filterData.priority = this.getFilterPriority();
    if (filterData.priority && filterData.priority.length > 0) {
      filterData.type = ['defect'];
    } else {
      filterData.type = this.getFilterTypeList();

    }
    filterData.filterDateBy = this.selectedDateTypeForServer;
    filterData.keyword = this.searchQuery;
    filterData.myOpenIssue = this.myOpenIssue;
    filterData.reportedByMe = this.reportedByMe;
    filterData.openIssue = this.openIssue;
    filterData.doneIssue = this.doneIssue;
    filterData.updateRecently = this.updateRecently;
    filterData.resolveRecently = this.resolveRecently;

    if (this.createdAt) {
      filterData.fromDate = this.createdAt?.formatted
    }

    if (this.updatedAt) {
      filterData.toDate = this.updatedAt?.formatted
    }

    // filterData.fromDate = this.createdAt
    // filterData.toDate = this.updatedAt
    filterData.size = event.pageSize;
    if (this.isUnassigned) {
      filterData.isAssignee = 2;
    } else {
      filterData.isAssignee = null;
    }

    filterData.sortColumn = this.sortByField;
    filterData.sortDirection = this.sortOrder;
    var page = event.pageIndex + 1;

    var date_regex = /^\d{4}-\d{2}-\d{2}$/;
    if (this.createdAt) {
      if (!(date_regex.test(this.createdAt))) {
        this.isDateValid = false;
      } else {
        this.isDateValid = true;
      }

    }
    if (this.updatedAt) {
      if (!(date_regex.test(this.updatedAt))) {
        this.isDateValid = false;
      } else {
        this.isDateValid = true;
      }
    }

    this.isDateValid = true;

    console.log("Created at =", this.createdAt)
    console.log("Updated at =", this.updatedAt)

    if (this.isDateValid) {
      this.restService.hitApi('project/filterIssues?page=' + page, filterData, 'post', '', true)
        .subscribe((result) => {

          let responseData: any = result;
          if (responseData.statusCode == 200) {
            this.filterData = responseData.result;
            console.log("search data", this.filterData);
            if (this.filterData && this.filterData.data) {
              this.filterDataList = this.filterData.data;
              this.filterDataList.forEach(element => {
                if (element.entityType == 'defect') {
                  element.typeImg = './assets/images/new-bug-icon.svg';
                  element.routeUrl = "/defect/" + element.entityId;
                  element.alt = "bug-icon";
                } else if (element.entityType == 'task') {
                  element.typeImg = './assets/images/new-task-icon.svg';
                  element.routeUrl = "/task/" + element.entityId;
                  element.alt = "task-icon";
                } else if (element.entityType == 'subTask') {
                  element.typeImg = './assets/images/new-task-icon.svg';
                  element.routeUrl = "/userstory/" + element.userStoryEntityId + "/task/" + element.entityId;
                  element.alt = "task-icon";
                } else if (element.entityType == 'userStory') {
                  element.typeImg = './assets/images/new-us-icon.svg';
                  element.routeUrl = "/userstory/" + element.entityId;
                  element.alt = "story-icon";
                } else if (element.entityType == 'testPlan') {
                  element.typeImg = './assets/images/new-tp-icon.svg';
                  element.routeUrl = "/testplan/" + element.entityId;
                  element.alt = "test-plan-icon";
                }
              });

              if (responseData.result.currentPage != 0) {
                this.pageIndex = responseData.result.currentPage - 1;
              }

              this.pageSize = responseData.result.perPage;
              this.length = responseData.result.total;
              this.pageEvent = new PageEvent();
              this.pageEvent.length = this.length;
              this.pageEvent.pageIndex = this.pageIndex;
              this.pageEvent.pageSize = this.pageSize;


              console.log("search data", this.filterDataList);
              console.log("selected type", this.selectedType);
              console.log("page event 2", this.pageEvent)

            } else {
              this.toastr.error(responseData.message);
            }
            this.isLoading = false;
          }
        }, (err) => {
          this.isLoading = false;
          console.log(err);
          let errorData: any = err;
          this.toastr.error(errorData.error.message);
        });
    } else {
      return false;
    }

  }

  getCSVFile() {

    var filterData: any = {};
    filterData.projectId = this.selectedProject?.id;
    //filterData.type = this.getFilterTypeList();
    filterData.assignee = this.getFilterAssigneeList();
    filterData.reporter = this.getFilterReporterList();
    filterData.status = this.getFilterStatus();
    filterData.priority = this.getFilterPriority();
    if (filterData.priority && filterData.priority.length > 0) {
      filterData.type = ['defect'];
    } else {
      filterData.type = this.getFilterTypeList();

    }
    filterData.filterDateBy = this.selectedDateTypeForServer;
    filterData.keyword = this.searchQuery;
    filterData.myOpenIssue = this.myOpenIssue;
    filterData.reportedByMe = this.reportedByMe;
    filterData.openIssue = this.openIssue;
    filterData.doneIssue = this.doneIssue;
    filterData.updateRecently = this.updateRecently;
    filterData.resolveRecently = this.resolveRecently;
    filterData.sortColumn = this.sortByField;
    filterData.sortDirection = this.sortOrder;

    if (this.createdAt) {
      filterData.fromDate = this.createdAt?.formatted
    }

    if (this.updatedAt) {
      filterData.toDate = this.updatedAt?.formatted
    }
    if (this.isUnassigned) {
      filterData.isAssignee = 2;
    } else {
      filterData.isAssignee = null;
    }
    filterData.export = 1;


    this.restService.getCsvFile('project/filterIssues', filterData, true)
    // .subscribe((result) => {

    //   let responseData: any = result;
    //   console.log("responseData", responseData)
    //   // let blob = new Blob([responseData], { type: 'text/csv' });
    //   // FileSaver.saveAs(blob, "data.txt");
    // }, (err) => {
    //   this.isLoading = false;
    //   console.log(err);
    //   let errorData: any = err;
    //   this.toastr.error(errorData.error.message);
    // });
  }

  getStatusName(status) {
    this.statusList.forEach(element => {
      if (element.value == status) {
        return element.name;
      }
    });
  }

  getPriorityName(priority) {
    this.priorityList.forEach(element => {
      if (element.value == priority) {
        return element.name;
      }
    });
  }

  getFilterTypeList() {
    var typeList = [];
    if (this.selectedTypeList && this.selectedTypeList.length > 0) {
      typeList = this.selectedTypeList;
    }
    if (this.allStandardIssueType) {
      this.standardIssueTypes.forEach(element => {
        typeList.push(element.value)
      })
    } else if (this.allSubTaskIssueType) {
      typeList.push('subTask')
    } else {
      this.standardIssueTypes.forEach(element => {
        if (element.checked) {
          typeList.push(element.value)
        }

      })
    }
    if (typeList.length > 0) {
      return typeList;
    } else {
      return null;
    }

  }

  getFilterAssigneeList() {
    var assigneeList = [];
    if (this.selectedAssignees && this.selectedAssignees.length > 0) {
      assigneeList = this.selectedAssignees;
    }
    if (this.assigneeList && this.assigneeList.length > 0) {
      this.assigneeList.forEach(element => {
        if (element.checked) {
          if (assigneeList.indexOf(element.userId) == -1) {
            assigneeList.push(element.userId)

          }

        }

      })
    }

    if (this.isUnassigned) {
      assigneeList.push("unassigned")
    }

    if (assigneeList.length > 0) {
      return assigneeList;
    } else {
      return null;
    }

  }
  getFilterReporterList() {
    var reporterList = [];
    if (this.selectedReporters && this.selectedReporters.length > 0) {
      reporterList = this.selectedReporters;
    }
    if (this.reporterList && this.reporterList.length > 0) {
      this.reporterList.forEach(element => {
        if (element.checked) {
          if (reporterList.indexOf(element.userId) == -1) {
            reporterList.push(element.userId)

          }
        }

      })
    }
    if (reporterList.length > 0) {
      return reporterList;
    } else {
      return null;
    }

  }

  getFilterStatus() {
    var statusList = [];
    if (this.selectedStatusList && this.selectedStatusList.length > 0) {
      statusList = this.selectedStatusList;
    }
    if (this.statusList && this.statusList.length > 0) {
      this.statusList.forEach(element => {
        if (element.checked) {
          statusList.push(element.value)
        }

      })
    }
    if (statusList.length > 0) {
      return statusList;
    } else {
      return null;
    }
  }

  getFilterPriority() {
    var priorityList = [];
    if (this.selectedPriorityList && this.selectedPriorityList.length > 0) {
      priorityList = this.selectedPriorityList;
    }
    if (this.priorityList && this.priorityList.length > 0) {
      this.priorityList.forEach(element => {
        if (element.checked) {
          priorityList.push(element.value)
        }

      })
    }
    if (priorityList.length > 0) {
      return priorityList;
    } else {
      return null;
    }
  }

  setSelectedFilter(entity) {
    if (entity == 'Type') {
      this.selectedTypeList = [];
      if (this.allStandardIssueType) {
        this.selectedType = 'All Standard Issues Types';
      } else if (this.allSubTaskIssueType) {
        this.selectedType = 'All Sub-Task Issues Types';
      } else {
        var issueTypes = this.standardIssueTypes.filter(type => type.checked == true);
        if (issueTypes.length > 0) {
          this.selectedType = ''
          for (var i = 0; i < issueTypes.length; i++) {
            this.selectedType += issueTypes[i].name;
            if (i != issueTypes.length - 1) {
              this.selectedType += ',';
            }
          }




        } else {
          this.selectedType = 'Type';
        }

      }

    }
    if (entity == 'Status') {
      var statusList = this.statusList.filter(status => status.checked == true);
      if (statusList.length > 0) {
        this.selectedStatus = ''
        this.selectedStatusList = [];

        for (var i = 0; i < statusList.length; i++) {
          this.selectedStatus += statusList[i].name;
          if (i != statusList.length - 1) {
            this.selectedStatus += ',';
          }
        }


      } else {
        this.selectedStatus = 'Status';
      }

    }
    if (entity == 'Priority') {
      var priorityList = this.priorityList.filter(status => status.checked == true);
      if (priorityList.length > 0) {
        this.selectedPriority = ''
        this.selectedPriorityList = [];
        for (var i = 0; i < priorityList.length; i++) {
          this.selectedPriority += priorityList[i].name;
          if (i != priorityList.length - 1) {
            this.selectedPriority += ',';
          }
        }
        var typeList = [];


      } else {
        this.selectedPriority = 'Priority';
      }

    }

    if (entity == 'Assignee') {


      // else {

      // }

      this.selectedAssignee = ''
      this.selectedAssignees = [];

      var assignees = this.assigneeList.filter(assignee => assignee.checked == true);
      if (assignees.length > 0) {
        var selectedAssigneeNames = ''
        for (var i = 0; i < assignees.length; i++) {
          selectedAssigneeNames += assignees[i].name;
          if (i != assignees.length - 1) {
            selectedAssigneeNames += ',';
          }
        }
        if (this.isUnassigned) {
          selectedAssigneeNames += ',Unassigned';
        }

        this.selectedAssignee = selectedAssigneeNames
      } else if (this.isUnassigned) {
        this.selectedAssignee = 'Unassigned';
      } else {
        this.selectedAssignee = 'Assignee';
      }

    }

    if (entity == 'Reporter') {
      this.selectedReporters = [];
      var reporters = this.reporterList.filter(reporter => reporter.checked == true);
      if (reporters.length > 0) {
        this.selectedReporter = ''
        for (var i = 0; i < reporters.length; i++) {
          this.selectedReporter += reporters[i].name;
          if (i != reporters.length - 1) {
            this.selectedReporter += ',';
          }
        }


      } else {
        this.selectedReporter = 'Reporter';
      }

    }

    this.resetPageEvent();
    this.makeNewUrl();
    this.getSearchFilterData(this.pageEvent);
  }

  makeNewUrl() {

    this.newQuery = "filter=" + this.param;
    if (this.selectedType != null && this.selectedType != 'Type') {
      this.newQuery = this.newQuery + "&Type=" + this.selectedType;
    }
    if (this.selectedStatus != null && this.selectedStatus != '' && this.selectedStatus != 'Status') {
      this.newQuery = this.newQuery + "&Status=" + this.selectedStatus;
    }

    if (this.selectedPriority != null && this.selectedPriority != '' && this.selectedPriority != 'Priority') {
      this.newQuery = this.newQuery + "&Priority=" + this.selectedPriority;
    }
    if (this.selectedAssignee != null && this.selectedAssignee != '' && this.selectedAssignee != 'Assignee') {
      this.newQuery = this.newQuery + "&Assignee=" + this.selectedAssignee;
    }
    if (this.selectedReporter != null && this.selectedReporter != '' && this.selectedReporter != 'Reporter') {
      this.newQuery = this.newQuery + "&Reporter=" + this.selectedReporter;
    }
    if (this.searchQuery != null && this.selectedReporter != '') {
      this.newQuery = this.newQuery + "&q=" + this.searchQuery;
    }
    if (this.selectedProjectId != null && this.selectedProjectId != undefined) {
      this.newQuery = this.newQuery + "&pid=" + this.selectedProjectId;
    }
    if (this.selectedDateType != null && this.selectedDateType != '') {
      this.newQuery = this.newQuery + "&dateType=" + this.selectedDateType;
    }

    if (this.createdAt != null && this.createdAt != undefined && this.createdAt != '') {
      this.newQuery = this.newQuery + "&createdAtDate=" + this.createdAt.formatted;
    }
    if (this.updatedAt != null && this.updatedAt != undefined && this.updatedAt != '') {
      this.newQuery = this.newQuery + "&updatedAtDate=" + this.updatedAt.formatted;
    }
    this.newQuery = this.newQuery + "&pageIndex=" + this.pageEvent.pageIndex;
    this.newQuery = this.newQuery + "&pageSize=" + this.pageEvent.pageSize;
    this.newQuery = this.newQuery + "&pageLength=" + this.pageEvent.length;

    this.location.replaceState('/search-filter', this.newQuery)
  }

  selectDateType(type) {
    if (type == 0) {
      this.selectedDateType = "None"
      this.selectedDateTypeForServer = "";
    } else if (type == 1) {
      this.selectedDateType = "Created At"
      this.selectedDateTypeForServer = "createdAt";
    } else if (type == 2) {
      this.selectedDateType = "Updated At"
      this.selectedDateTypeForServer = "updatedAt";
    }

    this.resetPageEvent();
    this.makeNewUrl();

    this.getSearchFilterData(this.pageEvent);

  }

  setSelectedType(type) {
    if (type) {
      this.type = type;
    } else {
      this.type = null;
    }
    this.setSelectedFilter('Type')

  }

  setStatusList(element) {
    if (this.userData?.role == 3) {
      this.statusList = this.statusListWithoutBacklog
    } else if (element?.type == 2) {
      this.statusList = this.statusListWithBacklog
    } else {
      this.statusList = this.statusListWithoutBacklog
    }
  }
  resetPageEvent() {
    this.pageEvent = new PageEvent();
    this.pageEvent.length = 0;
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = this.pageSize;
  }

  resetAllProjects(project) {
    if (project) {

      this.selectedProject = project?.checked ? project : null;
      this.selectedProjectId = project?.checked ? project.id : null;
      this.projectListData.forEach(element => {
        if (element.id != project.id) {
          element.checked = false;
        }
      });
      this.getAssigneeList();
      this.getReporterList();
      this.getSearchFilterData(this.pageEvent);

    }

    this.setStatusList(project);
    this.makeNewUrl();
  }

  resetValues() {

    if (this.lastSearch == null) {
      this.selectedProjectId = localStorage.getItem('projectSelected');
      this.allStandardIssueType = false;
      this.allSubTaskIssueType = false;

      this.statusList.forEach(elem => {
        elem.checked = false;
      });
      this.standardIssueTypes.forEach(elem => {
        elem.checked = false;
      });
      this.priorityList.forEach(elem => {
        elem.checked = false;
      });

      this.assigneeList = []
      this.reporterList = [];
      this.selectedAssignees = [];
      this.selectedReporters = [];
      this.selectedTypeList = [];
      this.selectedStatusList = [];
      this.selectedPriorityList = [];
      this.createdAt = null;
      this.updatedAt = null;

      this.selectedType = 'Type';
      this.selectedStatus = 'Status';
      this.selectedPriority = 'Priority';
      this.selectedDateType = 'None';
      this.selectedAssignee = 'Assignee';
      this.selectedReporter = 'Reporter';
      this.projectSearchString = '';
      this.assigneeSearchString = '';
      this.reporterSearchString = '';
      this.selectedDateTypeForServer = "";
      this.searchQuery = "";

      this.pageEvent = new PageEvent();
      this.pageEvent.length = 0;
      this.pageEvent.pageIndex = 0;
      this.pageEvent.pageSize = this.pageSize;

      if (this.param != undefined) {
        this.newQuery = "filter=" + this.param;

      } else {
        this.newQuery = ""
      }

      this.location.replaceState('/search-filter', this.newQuery)
      this.getProjectList();
    }


  }

  sortData(field) {
    this.sortByField = field;
    this.sortOrder = this.sortOrder == 'asc' ? 'desc' : 'asc';
    this.getSearchFilterData(this.pageEvent);
  }
  onPageChange(event: PageEvent) {
    this.pageEvent = event;
    this.makeNewUrl();
    this.getSearchFilterData(event);
  }

}

