import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
declare const $:any;
@Component({
	selector: 'app-under-construction',
	templateUrl: './under-construction.component.html',
	styleUrls: ['./under-construction.component.scss']
})
export class UnderConstructionComponent implements OnInit {
	contactForm: FormGroup;
	

	constructor(
		private router: Router
	) {
		$("html, body").animate({ scrollTop: 0 }, "slow");
		
	}

	ngOnInit() {
		if (localStorage.getItem('userData')){
			this.router.navigate(['/dashboard']);
		}
	}

	
}
