import { Component, Input, Output, EventEmitter, Self, Optional, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NgControl, FormGroup, FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MyErrorStateMatcher } from './../../MyErrorStateMatcher';
import { ErrorStateMatcher } from '@angular/material';
import { Data } from './Data';




@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss']
})
export class SelectDropdownComponent implements ControlValueAccessor, OnDestroy, OnChanges {

  @Input()
  values: [];

  @Input()
  label: string;

  @Input()
  required: boolean;

  @Input() disabled: boolean;
  @Input() selectedValue: string;
  @Input() parentForm: FormGroup;
  @Input() errors: any = null;
  @Input() isNoneOptionNotShown: true | false = false;
  @Input() isAllOptionShown: true | false = false;


  @Output() selectionChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();
  @Output() valueChange: EventEmitter<Data> = new EventEmitter<Data>()


  value: Data;
  control: FormControl;
  //matcher = new MyErrorStateMatcher();



  constructor(@Self() @Optional()
  private ngControl: NgControl) {
    if (this.ngControl) {
      console.log("constructor call");
      this.ngControl.valueAccessor = this;
      console.log(this.ngControl.valueAccessor);
      if (ngControl) {
        setTimeout(() => {
          this.control = ngControl.control as FormControl;
        })
      }


    }

  }
  ngOnChanges(val) {
    console.log("change detected");
    console.log(val);
  }


  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  select(value) {
    console.log("Select function call");
    this.valueChange.emit(value);
    this.selectedValue = value?.name;
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  selectionChanged(event: MatSelectChange) {
    console.log("SelectChange function call");
    console.log(event.value);

    this.valueChange.emit(event.value);
    this.onChange(event.value);
    this.onTouched();
    //console.log(this.control);
  }



  readonly errorStateMatcher: ErrorStateMatcher = {
    isErrorState: (ctrl: FormControl) => (ctrl && ctrl.invalid)
  };

  ngOnDestroy(): void {
    //throw new Error('Method not implemented.');
    console.log("ngOnDestroy");
    this.control = null;
  }






}
