import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { RestService } from '../services/rest/rest.service';
import { ToastrService } from 'ngx-toastr';
import {IMyDpOptions, IMyDateModel} from 'mydatepicker';
declare const $:any;
@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
	
	companyTaskAddForm : FormGroup;
	companyTaskData : any;
	statusAddForm: FormGroup;
	statusData : any;
	selectedCompanytask : any;
	selectedStatus : any;

	date: Date = new Date();
	public myDatePickerOptions: IMyDpOptions = {
		dateFormat: 'dd-mm-yyyy',
		openSelectorTopOfInput : true,
		disableUntil:{year: this.date.getFullYear(), month: this.date.getMonth()+1, day: this.date.getDate()-1}
	};
	
	constructor(
		private formBuilder: FormBuilder, 
		private router: Router, 
		private restService: RestService,
		private toastr : ToastrService
		) {
		this.creatStatusForm(null);
		this.createTaskForm(null);
		this.getTasks();
		this.getStatus();
		
	}

	// Check if user already logged in
	ngOnInit() {
		if (!localStorage.getItem('userData')){
			this.router.navigate(['/login']);
		}
	}

	/*------------- Task Section Start -------------------*/

	createTaskForm(data){
		this.companyTaskAddForm = this.formBuilder.group({
			'frequency':[data?data.frequency:"",[Validators.required]],
			'name':[data?data.name:'',[Validators.required]],
			'finYear':[data?data.finYear:'',[Validators.required]],
			'dueDate':[data?data.dueDate:'',[Validators.required]],
			'companyTaskId':[data?data.id:'']
		})

		if(data){
			let dueDate = data?new Date(data.dueDate):new Date();
			this.companyTaskAddForm.patchValue({dueDate: {
				date: {
					year: dueDate.getFullYear(),
					month: dueDate.getMonth() + 1,
					day: dueDate.getDate()}
			}});
		}
	}

	getTasks(){
		this.restService.hitApi('companyTask/list',{},'post')
		.subscribe((result)=>{
			let responseData : any = result;
			this.companyTaskData = responseData.result.data;
		},(err)=>{
			let errorData : any = err;
			this.toastr.error(errorData.err.message)
		})
	}

	addCompanyTask(){
		let apiname = this.companyTaskAddForm.value.companyTaskId?'companyTask/update':'companyTask/create';
		this.companyTaskAddForm.value.dueDate = this.companyTaskAddForm.value.dueDate.date.year+"-"+this.companyTaskAddForm.value.dueDate.date.month+"-"+this.companyTaskAddForm.value.dueDate.date.day;
		this.restService.hitApi(apiname,this.companyTaskAddForm.value,'post')
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.companyTaskAddForm.reset();
				$('#create-task-setting').modal('hide');
				this.getTasks();
				this.toastr.success(responseData.message);
			}else{
				this.toastr.error(responseData.message);
			}
		},(err)=>{
			let errorData : any = err;
			this.toastr.error(errorData.err.message);
		})
	}

	deleteCompanyTask(tid){
		this.restService.hitApi('companyTask/delete',{'companyTaskId':tid},'post')
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.getTasks();
				$("#delete-companyTask").modal('hide');
				this.toastr.success(responseData.message);
			}else{
				this.toastr.error(responseData.message);
			}
		},(err)=>{
			let errorData : any = err;
			this.toastr.error(errorData.err.message);
		})
	}

	/*------------- Task Section End -------------------*/
	

	/*------------- Status Section Start -------------------*/
	creatStatusForm(data){
		this.statusAddForm = this.formBuilder.group({
			'status':[data?data.status:'',[Validators.required]],
			'statusId':[data?data.id:'']
		})
	}

	getStatus(){
		this.restService.hitApi('status/list',{},'post','',true)
		.subscribe((result)=>{
			let responseData : any = result;
			this.statusData = responseData.result.data;
		},(err)=>{
			let errorData : any = err;
			this.toastr.error(errorData.error.message);
		})
		
	}

	addStatus(){
		let apiname = this.statusAddForm.value.statusId?'status/update':'status/create'
		this.restService.hitApi(apiname,this.statusAddForm.value,'post','',true)
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.statusAddForm.reset();
				$("#create-status-setting").modal('hide')
				this.getStatus();
				this.toastr.success(responseData.message);
			}else{
				this.toastr.error(responseData.message);
			}
		},(err)=>{
			let errorData : any = err;
			this.toastr.error(errorData.error.message);
		})
	}

	deleteStatus(sid){
		
		this.restService.hitApi('status/delete',{'statusId':sid},'post')
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.getStatus();
				$("#delete-status").modal('hide');
				this.toastr.success(responseData.message);
			}else{
				this.toastr.error(responseData.message);
			}
		},(err)=>{
			let errorData : any = err;
			this.toastr.error(errorData.err.message);
		})
	}
	/*------------- Status Section End -------------------*/




}
