import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';

import { RestService } from '../services/rest/rest.service';
import { CookieService } from 'angular2-cookie/services/cookies.service';
import { ToastrService } from 'ngx-toastr';
declare const $: any;
@Component({
	selector: 'app-pricing',
	templateUrl: './pricing.component.html',
	styleUrls: ['./pricing.component.scss'],
	providers: [CookieService]
})
export class PricingComponent implements OnInit {
	loginForm: FormGroup;
	errorMessage: string = "";
	subscriptionData: any;
	freePlanData: any;
	constructor(
		private router: Router,
		private restService: RestService,
		private toastr: ToastrService,
	) {
		window.scroll(0, 0);
	}

	ngOnInit() {
		this.getFreePlan();

		$(document).ready(function () {
			$(this).scrollTop(0);
		});
	}

	getFreePlan() {
		this.restService.hitApi('users/freePackageDetail', null, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.freePlanData = responseData.result;
					console.log("this.freePlanData: ", this.freePlanData)
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	tryFree() {
		if (this.freePlanData.status == 1) {
			this.router.navigateByUrl('/register', { state: { package: "free", minStorage: this.freePlanData.maxData, minUsers: this.freePlanData.noOfUsers } });

		} else {
			this.toastr.error("Sorry, free trail not available, please try to pay and use Trakk.in");
		}
	}

}
