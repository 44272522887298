import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'dateFormat' })
export class MomentPipe implements PipeTransform {
    transform(value: Date | moment.Moment, dateFormat: string): any {
		var date = moment.utc(value).format('YYYY-MM-DD HH:mm:ss');
		var stillUtc = moment.utc(date).toDate();
		return moment(stillUtc).local().format(dateFormat);
        // return moment(value).local().format();
    }
}