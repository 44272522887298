import { element } from 'protractor';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { Data } from './../ui-components/select-dropdown/Data';
import { ValidationService } from '../services/config/config.service';
import { PreservedSearchStateService } from '../services/search/preserved-search-state.service';



// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AppComponent } from '../app.component';
import { PageEvent } from '@angular/material/paginator';
import { FormGroupDirective } from '@angular/forms';
import { FileUploadService } from "../file-upload-service";
import { HttpEvent, HttpEventType } from '@angular/common/http';

declare const $: any;
@Component({
	selector: 'app-userstories',
	templateUrl: './userstories.component.html',
	styleUrls: ['./userstories.component.scss'],
	providers: [FormGroupDirective]
})

export class UserstoriesComponent implements OnInit, OnDestroy {
	userData: any = JSON.parse(localStorage.getItem("userData"));
	userstoryCreateForm: FormGroup;
	userstoriesData: any = [];
	pid: any;
	usid: any;
	selectedRelease: any;
	userList: any;
	sprintList: any;
	selectedRecord: any;

	sprintDataList: Data[] = [];
	userDataList: Data[] = [];

	files: any = [];
	imgArr: any = [];
	projectListData: any;
	projectData: any;

	sortByField: any;
	sortOrder: any;
	searchdata: any;


	date: Date = new Date();

	pageEvent: PageEvent;
	datasource: any;
	pageIndex: number = 0;
	pageSize: number = 10;
	length: number = 0;
	searchString: string = "";

	isShowMore: boolean = false;
	isLoading: boolean = false;
	isCreateDialogOpen: boolean = true;
	isOwner: boolean = false;
	isCompanyAdmin: boolean = false;
	isOther: boolean = false;
	isProjectManager: boolean = false;

	isNoSearchResults: boolean = false;
	isNoData: boolean = false;
	isActionColumnShown: boolean = false;;

	constructor(
		private restService: RestService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private appComponent: AppComponent,
		private formDirective: FormGroupDirective,
		private preserveSearch: PreservedSearchStateService,
		private fileUploadService: FileUploadService) {

		this.pid = localStorage.getItem('projectSelected');

		// Check for route params
		// this.route.params.subscribe(params => {
		// 	this.pid = params['pid'];

		// });



	}

	ngOnInit() {

		this.recoverLastSearchValue();

		if (this.pid && this.pid !== null && this.pid !== undefined) {

			if (this.pageEvent == null) {
				this.pageEvent = new PageEvent();
				this.pageEvent.length = this.length;
				this.pageEvent.pageIndex = this.pageIndex;
				this.pageEvent.pageSize = this.pageSize;
				console.log("APi before", this.pageEvent.pageIndex)
			}
			this.isLoading = true;
			this.getProjectDetail();
			this.getUserStories(this.pageEvent);
		}



		$(document).ready(function () {

			let that = this;
			$('.date input').datepicker({
				format: 'yyyy-mm-dd',
				startDate: new Date()
			}).on('changeDate', function (e) {
				$('.datepicker').hide();
				that.updateUserStory('dueDate', e.format('yyyy-mm-dd'));
			});

			$('.htmlEditor').summernote({
				height: '150px',
				focus: true,
				disableDragAndDrop: true,
				dialogsInBody: true,
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
				callbacks: {
					onPaste: function (e) {
						var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
						e.preventDefault();
						document.execCommand('insertText', false, bufferText);
					}
				}
			});

			$('#summernote').summernote({
				dialogsInBody: true
			});

			// $('.moreless-button').click(function () {
			// 	$('.moretext').slideToggle();
			// 	if ($('.moreless-button').text() == "Show Less") {
			// 		$(this).text("Show more fields")
			// 	} else {
			// 		$(this).text("Show Less")
			// 	}
			// });


		});

		$(document).mouseup(function (e) {
			var container1 = $(".project-title-head");
			var container2 = $(".project-title-list");
			console.log(e.target);
			if (!container1.is(e.target) && container1.has(e.target).length === 0 && !container2.is(e.target) && container2.has(e.target).length === 0) {
				$(".project-title-list").hide('down');
			}
		});
		//		this.getProjectList(); MARK: NOT USEFULL
		this.createForm(null);
	}

	ngOnDestroy() {
		this.preserveSearch.searchUserStoryState = {
			searchValue: this.searchString,
			pageEvent: this.pageEvent,
			results: this.userstoriesData,
			resultsLength: this.userstoriesData.length,
		};
	}

	recoverLastSearchValue() {
		const lastSearch = this.preserveSearch.searchUserStoryState;
		if (lastSearch) {
			this.searchString = lastSearch.searchValue;
			this.userstoriesData = lastSearch.results;
			this.pageEvent = lastSearch.pageEvent;


			//this.resultsLength = lastSearch.resultsLength;
		}


	}


	getProjectDetail() {
		if (localStorage.getItem("isOwner") == "true") {
			this.isOwner = true
		} else {
			this.isOwner = false
		}

		if (localStorage.getItem("isCompanyAdmin") == "true") {
			this.isCompanyAdmin = true
		} else {
			this.isCompanyAdmin = false
		}

		if (localStorage.getItem("isProjectManager") == "true") {
			this.isProjectManager = true
		} else {
			this.isProjectManager = false
		}

		if (localStorage.getItem("isOther") == "true") {
			this.isOther = true
		} else {
			this.isOther = false
		}

		// this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', true)
		// 	.subscribe((result) => {
		// 		let responseData: any = result;
		// 		if (responseData.statusCode == 200) {
		// 			this.projectData = responseData.result;

		// 			if (this.userData) {
		// 				var isOwner = localStorage.getItem('isOwner');
		// 				if (isOwner == "true") {
		// 					this.isOwner = true;
		// 					this.isCompanyAdmin = false;
		// 					this.isProjectManager = false;
		// 					this.isOther = false;
		// 				} else {
		// 					if (this.userData.role == 1) {
		// 						this.isOwner = false;
		// 						this.isCompanyAdmin = true;
		// 						this.isProjectManager = false;
		// 						this.isOther = false;
		// 					} else if (this.userData.role == 2) {
		// 						if (this.projectData && this.projectData.projectUsersProject) {
		// 							this.projectData.projectUsersProject.forEach(element => {
		// 								if (element.userId == this.userData.id) {
		// 									if (element.userRoleId == 1) {
		// 										this.isProjectManager = true;
		// 										this.isOther = false;
		// 									} else {
		// 										this.isProjectManager = false;
		// 										this.isOther = true;
		// 									}
		// 								}
		// 							});
		// 							this.isOther = false;

		// 						} else {
		// 							this.isOther = true;
		// 						}
		// 						this.isOwner = false;
		// 						this.isCompanyAdmin = false;
		// 					} else {
		// 						this.isOther = true;
		// 						this.isOwner = false;
		// 						this.isProjectManager = false;
		// 						this.isCompanyAdmin = false;
		// 					}
		// 				}
		// 			}
		// 		} else {
		// 			// this.toastr.error(responseData.message);
		// 		}
		// 	}, (err) => {
		// 		console.log(err);
		// 		let errorData: any = err;
		// 		// this.toastr.error(errorData.error.message);
		// 	})
	}

	// getProjectList() {
	// 	this.restService.hitApi('discussion/projects', {}, 'get', '', true)
	// 		.subscribe((result) => {
	// 			let responseData: any = result;
	// 			if (responseData.statusCode == 200) {
	// 				this.projectListData = responseData.result.data;
	// 			} else {
	// 				// this.toastr.error(responseData.message);
	// 			}
	// 		}, (err) => {
	// 			console.log(err);
	// 			let errorData: any = err;
	// 			// this.toastr.error(errorData.error.message);
	// 		})
	// }

	changeProject(pid) {
		localStorage.setItem('projectSelected', pid)
		$('.project-title-list').toggle("slide");
		this.router.navigateByUrl('/project/' + pid + '/userstories');
		setTimeout(() => {
			this.getUserStories(this.pageEvent);
		}, 500);

	}

	createForm(data) {
		this.imgArr = [];
		this.files = [];
		if (data === null) {
			this.userstoryCreateForm = this.formBuilder.group({
				'summary': ['', [Validators.required, Validators.maxLength(150)]],
				'description': [''],
				'sprintId': [''],
				'sprintName': [''],
				'assignedTo': [''],
				'assignedToName': ['Unassigned'],
				'storyPoints': ['', [ValidationService.greaterThanZero]],
				'dueDate': [''],
				'estHr': ['', [Validators.pattern('[0-9]*')]],
				'estMin': ['', [Validators.pattern('[0-9]*'), Validators.max(60)]],
				// 'actualHr':['',[Validators.pattern('[0-9]*')]],
				// 'actualMin':['',[Validators.pattern('[0-9]*'),Validators.max(60)]],
				// 'status':['',[Validators.required]],
				'attachments': []
			});

		} else {

			// let actualHoursArr = data.actualHours.split(".");
			// let estHoursArr = data.estHours.split(".");
			// this.userstoryCreateForm = this.formBuilder.group({
			// 	'userStoryId':[data.id],
			// 	'summary': [data.summary, [Validators.required]],
			// 	'description': [data.description],
			// 	'assignedTo':[data.userId,[Validators.required]],
			// 	'estHr':[estHoursArr[0],[Validators.pattern('[0-9]*')]],
			// 	'estMin':[estHoursArr[1],[Validators.pattern('[0-9]*'),Validators.max(60)]],
			// 	'actualHr':[actualHoursArr[0],[Validators.pattern('[0-9]*')]],
			// 	'actualMin':[actualHoursArr[1],[Validators.pattern('[0-9]*'),Validators.max(60)]],
			// 	'sprintId':[data.sprintId,[Validators.required]],
			// 	'storyPoints':[data.storyPoints],
			// 	'status':[data.status,[Validators.required]],
			// 	'attachments':[],
			// 	'oldattachments':[data.projectsProjectFile]
			// });
		}

	}



	createUserStory() {
		// let assignee: any = [];
		// assignee[0] = this.userstoryCreateForm.value.assignee;
		// this.userstoryCreateForm.value.assignee = assignee;

		let apiname = (this.userstoryCreateForm.value.userStoryId) ? 'userStory/update' : 'userStory/create'
		this.userstoryCreateForm.value.projectId = this.pid;
		this.userstoryCreateForm.value.estHours = this.userstoryCreateForm.value.estHr + "." + this.userstoryCreateForm.value.estMin;
		this.userstoryCreateForm.value.actualHours = this.userstoryCreateForm.value.actualHr + "." + this.userstoryCreateForm.value.actualMin;
		this.userstoryCreateForm.value.attachments = this.imgArr;
		this.userstoryCreateForm.value.description = $('#summernote').val();
		//console.log("form: ", this.userstoryCreateForm.value);

		console.log("this.userstoryCreateForm, ", this.userstoryCreateForm)

		if (this.userstoryCreateForm.invalid) {
			console.log("form: ", this.userstoryCreateForm);
			return false;
		} else {
			this.restService.hitApi(apiname, this.userstoryCreateForm.value, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.toastr.success(responseData.message);
						this.closeModal();
						this.getUserStories(this.pageEvent);
						this.files = [];
						this.imgArr = [];
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}
	}


	sortData(field) {
		this.sortByField = field;
		this.sortOrder = this.sortOrder == 'asc' ? 'desc' : 'asc';
		this.getUserStories(this.pageEvent);
	}

	searchData() {
		this.searchString = $('#search_name').val();
		console.log("i am searching", this.searchString);
		console.log(this.pageEvent);

		if (this.searchString.length >= 2 || this.searchString.length == 0) {
			this.pageEvent = new PageEvent();
			this.pageEvent.length = 0;
			this.pageEvent.pageIndex = 0;
			this.pageEvent.pageSize = 10;
			this.getUserStories(this.pageEvent);
		}
	}

	getUserStories(event?: PageEvent) {
		console.log("Page Event", event);

		let postdata = {
			"projectId": this.pid,
			"sort": {
				"column": this.sortByField,
				"direction": this.sortOrder
			},
			"search": this.searchdata,
			"page": event.pageIndex + 1,
			"size": event.pageSize,
			"q": this.searchString
		};

		this.restService.hitApi('userStory/list', postdata, 'post', '', true)
			.subscribe((result) => {
				this.isLoading = false;
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.userstoriesData = (responseData.result.total > 0) ? responseData.result.data : [];
					this.datasource = responseData.result;
					if (responseData.result.currentPage != 0) {
						this.pageIndex = responseData.result.currentPage - 1;
					}

					this.pageSize = responseData.result.perPage;
					this.length = responseData.result.total;
					this.pageEvent = new PageEvent();
					this.pageEvent.length = this.length;
					this.pageEvent.pageIndex = this.pageIndex;
					this.pageEvent.pageSize = this.pageSize;

					this.setIndexArray();
					this.checkIfDataHasComes();

					if (this.userstoriesData.length > 0) {
						this.isActionColumnShown = false;
						for (var i = 0; i < this.userstoriesData.length; i++) {
							var element = this.userstoriesData[i];
							if (element?.isBlocked == 0 && (this.userData?.id == element?.createdBy || this.isOwner || this.isCompanyAdmin || this.isProjectManager)) {
								this.isActionColumnShown = true;
								break;
							}
						}
					}

				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				this.isLoading = false;
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
		//this.getUsersList();
	}

	checkIfDataHasComes() {
		if (this.userstoriesData.length == 0) {
			if (this.searchString != "" && this.searchString != null) {
				this.isNoSearchResults = true;
				this.isNoData = false;
			} else {
				this.isNoData = true;
				this.isNoSearchResults = false;
			}
		} else {
			this.isNoData = false;
			this.isNoSearchResults = false;
		}
	}

	setIndexArray() {
		for (let i = 0; i < this.userstoriesData.length; i++) {
			if (this.pageIndex == 0) {
				this.userstoriesData[i].tableIndex = i + 1;
			} else {
				this.userstoriesData[i].tableIndex = (this.pageSize * this.pageIndex) + i + 1;
			}
		}
	}

	getUsersList() {
		this.userDataList = [];
		this.restService.hitApi('project/projectUsers', { 'projectId': this.pid, escape: [2] }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.userList = responseData.result;
					let obj = new Data('Unassigned', '');
					this.userDataList.push(obj);
					this.userList.forEach(element => {
						let obj = new Data(element.name, element.userId);
						this.userDataList.push(obj);
					});

				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})

		this.getSprintList()
	}

	getSprintList() {
		this.sprintDataList = [];
		this.restService.hitApi('userStory/sprintList', { 'projectId': this.pid, notCompleted: 1 }, 'post', '', false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.sprintList = responseData.result;
					//sprintDataList
					this.sprintList.forEach(element => {
						let obj = new Data(element.name, element.id);
						this.sprintDataList.push(obj);
					});
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	deleteUserstory(rid) {
		var index;
		for (var i = 0; i < this.userstoriesData.length; i++) {
			if (this.userstoriesData[i].id == rid) {
				index = i;
				break;
			}
		}

		this.restService.hitApi('userStory/delete', { 'userStoryId': rid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					if (index != -1) {
						this.userstoriesData.splice(index, 1);
						if (this.userstoriesData.length == 0) {
							this.pageEvent = new PageEvent();
							this.pageEvent.length = this.length;
							this.pageEvent.pageIndex = 0;
							this.pageEvent.pageSize = this.pageSize;

							this.getUserStories(this.pageEvent);
						}
						this.datasource.total = this.datasource.total - 1;
						this.length = this.datasource.total;
					}
					//
					$('#delete-record').modal('hide')
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	blockUserstory(usid, blockstatus) {
		let isBlocked = (blockstatus == 1) ? 0 : 1;
		this.restService.hitApi('userStory/updateBlock', { 'userStoryId': usid, 'isBlock': isBlocked }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getUserStories(this.pageEvent);
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	//Image upload
	uploadFile(event) {
		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];
			if (this.restService.checkFileSize(file, 50)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				formData.append('entity', 'userStory');
				formData.append('file', file);
				this.files.push({ index: index, localFileName: file.name, displayName: file.name, serverFileName: null });


				this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
					.subscribe((event: HttpEvent<any>) => {
						switch (event.type) {
							case HttpEventType.Sent:
								console.log('Request has been made!');
								break;
							case HttpEventType.ResponseHeader:
								console.log('Response header has been received!');
								break;
							case HttpEventType.UploadProgress:
								this.files[this.files.length - 1].progress = Math.round(event.loaded / event.total * 100);
								console.log(`Uploaded! ${this.files[this.files.length - 1].progress}%`);
								$('#attachment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');
								$('#old_attachment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');


								break;
							case HttpEventType.Response:
								let responseData: any = event.body;
								if (responseData.statusCode == 200) {
									this.imgArr.push(responseData.result);
									this.files[this.files.length - 1].serverFileName = responseData.result.fileTitle;
									this.files[this.files.length - 1].displayName = responseData.result.displayName;
									this.files.forEach(element => {
										element.isProgressBarShown = false;
									});
								} else {
									if (responseData.message) {
										this.toastr.error(responseData.message);
									}
								}
								console.log('Upload successfully', event.body);
								setTimeout(() => {
									this.files[this.files.length - 1].progress = 0;
									$('#attachment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');
									$('#old_attachment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');


								}, 1500);


						}
					})
				// this.restService.hitApi('userStory/upload-file', formData, 'post', '', true, false)
				// 	.subscribe((result) => {
				// 		let responseData: any = result;
				// 		if (responseData.statusCode == 200) {
				// 			this.files[index].serverFileName = responseData.result;
				// 			this.imgArr.push(responseData.result);
				// 		} else {
				// 			this.toastr.error(responseData.message);
				// 		}
				// 	}, (err) => {
				// 		console.log(err);
				// 		let errorData: any = err;
				// 		this.toastr.error(errorData.error.message);
				// 	})
			}
		}

	}

	removeAttachment(index) {
		var file = this.files[index];
		var deleteFileArray = [file.serverFileName];
		this.removeAttachmentFromServer(deleteFileArray, index);

	}

	removeAttachmentFromServer(files, index) {
		var json = {
			projectId: this.pid,
			entity: 'userStory',
			files: files
		}
		this.restService.hitApi('project/deleteUploadedFiles', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.files.splice(index, 1);

					if (files && this.imgArr) {
						var removeIndex = null;
						files.forEach(element1 => {
							for (var i = 0; i < this.imgArr.length; i++) {
								if (this.imgArr[i] == element1) {
									removeIndex = i;
									break
								}
							}


						});

					}
					if (removeIndex != null) {
						this.imgArr.splice(removeIndex, 1);

					}
				}
			})
	}

	deleteAttachment(aid, index) {
		this.restService.hitApi('release/delete-file', { "attachmentId": aid, "projectId": this.pid }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.userstoryCreateForm.value.oldattachments.splice(index, 1);
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	isBlocked() {
		this.toastr.error('This user story is blocked.');
	}

	getSprintType(obj: Data) {
		if (obj != null) {
			this.userstoryCreateForm.get("sprintId").setValue(obj.value);
			this.userstoryCreateForm.get("sprintName").setValue(obj.name);
		} else {
			this.userstoryCreateForm.get("sprintId").setValue(null);
			this.userstoryCreateForm.get("sprintName").setValue(null);
		}
	}

	getUserType(obj: Data) {
		if (obj != null) {
			console.log("objobj, ", obj)
			this.userstoryCreateForm.get("assignedTo").setValue(obj.value);
			this.userstoryCreateForm.get("assignedToName").setValue(obj.name);
		}
	}

	showMore() {
		this.isShowMore = true;
		$('.moretext').slideDown();

	}

	showLess() {
		this.isShowMore = false;
		$('.moretext').slideUp();

	}

	closeModal() {
		$('#add_userstory_popup_web').modal('hide');
		this.isCreateDialogOpen = false;
		setTimeout(() => {
			this.resetForm();
			this.isCreateDialogOpen = true;
			this.setHeightOfSummarNote();
		});

	}

	resetForm() {
		this.userstoryCreateForm.reset();
		$('#summernote').summernote("reset");
		this.showLess()
	}

	setHeightOfSummarNote() {

		$(document).ready(function () {
			$('.htmlEditor').summernote({
				height: '150px',
				focus: true,
				disableDragAndDrop: true,
				dialogsInBody: true,
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
				callbacks: {
					onPaste: function (e) {
						var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
						e.preventDefault();
						document.execCommand('insertText', false, bufferText);
					}
				}
			});

			$('#summernote').summernote({
				dialogsInBody: true
			});

		})
		this.isCreateDialogOpen = true;


	}

}

