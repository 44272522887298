import { Component, OnInit, OnDestroy } from '@angular/core';
import { RestService } from '../services/rest/rest.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { PreservedSearchStateService } from '../services/search/preserved-search-state.service';
import { BroadcastService } from '../services/broadcast/broadcast.service';





@Component({
  selector: 'app-dashboard-client',
  templateUrl: './dashboard-client.component.html',
  styleUrls: ['./dashboard-client.component.scss']
})
export class DashboardClientComponent implements OnInit, OnDestroy {
  sprintList: any;
  issueStatisticData: any;
  watchedIssueData: any;
  watchedIssueList: any = [];
  assignedMeData: any;
  assignedMeList: any = [];
  activityStreamData: any;
  activityStreamList: any = [];

  sprintName: string = '--Select Sprint--';
  sprintId: any;
  typeSelected: any = 0;
  typeSelectedText: any = '--Type--';
  watchedStatus: any = 0;
  assignToMeStatus: any = 0;
  activityStreamPage: any = 1;
  assignToMePage: any = 1;
  watchListPage: any = 1;
  watchedConfig: any = { currentPage: 1, itemsPerPage: 10, totalItems: 0 };

  // activityConfig : any = {currentPage:1,itemsPerPage: 10,totalItems:0};
  userDashboard: any;
  chart: any = {
    type: 'PieChart',
  }
  chartdata1 = []
  chartdata2 = []
  chartdata3 = []
  chartdata4 = []

  userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : '';
  isLoadMoreActivity = false;
  isLoadMoreAssignToMe = false;
  isLoadMoreWatchList = false;
  entityTypeArray = [{ name: 'Test Plan', value: 'testPlan' }, { name: 'Bug', value: 'defect' }, { name: 'Task', value: 'task' },
  { name: 'User Story', value: 'userStory' }, { name: 'User Story Sub Task', value: 'userStorySubTask' }];
  entityTypeFilterArrayForWatchList: String[];
  entityTypeFilterArrayForAssignToMeList: String[];
  userList: any;
  typeList = [{ name: 'Internal', value: 2 }, { name: 'External', value: 1 }];
  pid: any;
  dashboardData: any;
  userInfo: any;
  isActivityListFetch: boolean = false;
  isWatchMeListFetch: boolean;
  isAssignMeListFetch: boolean;
  issueStatisticAssigneeByData: any;
  issueStatisticAssigneeByList: any = [];
  totalIssueStatisticAssigneeByData = 0;
  issueStatisticAssigneePage = 1;

  assignedMePageEvent: PageEvent;
  assignedMePageIndex: number = 0;
  assignedMePageSize: number = 10;
  assignedMeLength: number = 0;
  assigneeByPageEvent: PageEvent;
  assigneeByPageIndex: number = 0;
  assigneeByPageSize: number = 10;
  assigneeByLength: number = 0;
  isAssignByListRefresh: boolean;
  isAssignMeListRefresh: boolean;
  isIssueStatisticListRefresh: boolean;
  isIssueStatisticAssigneeByLoading: boolean = false;
  isAssignedMeLoading: boolean = false;
  isIssueStatisticsLoading: boolean = false;



  constructor(
    public restService: RestService, private toastr: ToastrService, private router: Router, private preserveSearch: PreservedSearchStateService,
    private broadcastService: BroadcastService) {
    this.pid = localStorage.getItem('projectSelected')

    this.broadcastService.subscribe("PROJECT_ID_GET", (pid) => {
      if (pid != null && pid != undefined && pid != '' && pid != 'null') {
        this.pid = pid;
        //this method call bcause first time api does not call if i comment out this method
        this.getAll();

      }
    });
  }

  ngOnInit() {

    this.recoverLastSearchValue();

    if (this.assignedMePageEvent == null) {
      this.assignedMePageEvent = new PageEvent();
      this.assignedMePageEvent.length = this.assignedMeLength;
      this.assignedMePageEvent.pageIndex = this.assignedMePageIndex;
      this.assignedMePageEvent.pageSize = this.assignedMePageSize;
    }

    if (this.assigneeByPageEvent == null) {
      this.assigneeByPageEvent = new PageEvent();
      this.assigneeByPageEvent.length = this.assigneeByLength;
      this.assigneeByPageEvent.pageIndex = this.assigneeByPageIndex;
      this.assigneeByPageEvent.pageSize = this.assigneeByPageSize;
    }

    if (this.pid != null && this.pid != undefined && this.pid != '' && this.pid != 'null') {
      this.getAll();
    }


  }

  getAll() {
    this.getIssueStatisticsByAssignee(this.assigneeByPageEvent);
    this.assignedMe(this.assignedMePageEvent);
    this.issueStatistics();
  }

  ngOnDestroy() {
    this.preserveSearch.searchClientDashboardState = {
      assigneeByPageEvent: this.assigneeByPageEvent,
      assignedMePageEvent: this.assignedMePageEvent,
    };
  }

  recoverLastSearchValue() {
    const lastSearch = this.preserveSearch.searchClientDashboardState;
    if (lastSearch) {
      this.assigneeByPageEvent = lastSearch.assigneeByPageEvent;
      this.assignedMePageEvent = lastSearch.assignedMePageEvent;
      //this.resultsLength = lastSearch.resultsLength;
    }
  }

  getIssueStatisticsByAssignee(event?: PageEvent) {
    this.isIssueStatisticAssigneeByLoading = true;
    this.restService.hitApi('project/issueStatisticsByAssignee', {
      'projectId': localStorage.getItem('projectSelected'), "page": event.pageIndex + 1,
      "size": event.pageSize
    }, 'post', '', false)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.isIssueStatisticAssigneeByLoading = false;
          this.issueStatisticAssigneeByList = [];
          var mainData = responseData.result;
          if (mainData) {
            var stat = mainData.stat;
            this.issueStatisticAssigneeByData = mainData.users;
          }
          if (this.issueStatisticAssigneeByData) {
            this.issueStatisticAssigneeByData.totalCritical = 0;
            this.issueStatisticAssigneeByData.totalHigh = 0;
            this.issueStatisticAssigneeByData.totalMedium = 0;
            this.issueStatisticAssigneeByData.totalLow = 0;
            var users = this.issueStatisticAssigneeByData.data;
            if (users) {
              users.push({ userId: 0, assigneeName: "Unassigned" })

            }

            if (stat) {
              Object.keys(stat).forEach(obj => {
                var statKey = obj;
                var statKeyValue = stat[obj];
                var priority = statKeyValue.priority;

                if (users && users.length > 0 && priority) {
                  for (var i = 0; i < Object.keys(priority).length; i++) {
                    var priorityObj = Object.keys(priority)[i];
                    users.forEach(element => {
                      if (element.userId == parseInt(statKey)) {
                        if (priorityObj == '1') {
                          element.critical = priority[priorityObj];
                          element.high = 0;
                          element.medium = 0;
                          element.low = 0;
                        } else if (priorityObj == '2') {
                          element.critical = 0;
                          element.high = priority[priorityObj];
                          element.medium = 0;
                          element.low = 0;
                        } else if (priorityObj == '3') {
                          element.critical = 0;
                          element.high = 0;
                          element.medium = priority[priorityObj];
                          element.low = 0;
                        } else if (priorityObj == '4') {
                          element.critical = 0;
                          element.high = 0;
                          element.medium = priority[priorityObj];
                          element.low = priority[priorityObj];
                        }


                      }

                    });


                  }

                }
              });
              users.forEach(element => {
                let nameparts = element.assigneeName.split(" ");
                if (nameparts.length > 1) {
                  element.firstName = nameparts[0];
                  element.lastName = nameparts[1];
                } else {
                  element.firstName = element.assigneeName;
                }
                if (element.critical || element.high || element.medium || element.low) {
                  element.total = element.critical + element.high + element.medium + element.low;
                } else {
                  element.critical = 0;
                  element.high = 0;
                  element.medium = 0;
                  element.low = 0;
                  element.total = element.critical + element.high + element.medium + element.low;
                }
                this.issueStatisticAssigneeByData.totalCritical = this.issueStatisticAssigneeByData.totalCritical + element.critical;
                this.issueStatisticAssigneeByData.totalHigh = this.issueStatisticAssigneeByData.totalHigh + element.high;
                this.issueStatisticAssigneeByData.totalMedium = this.issueStatisticAssigneeByData.totalMedium + element.medium;
                this.issueStatisticAssigneeByData.totalLow = this.issueStatisticAssigneeByData.totalLow + element.low;

                if (mainData.users.currentPage != 0) {
                  this.assigneeByPageIndex = mainData.users.currentPage - 1;
                }

                this.assigneeByPageSize = mainData.users.perPage;
                if (mainData.users.total > 0) {
                  this.assigneeByLength = mainData.users.total + 1;
                }

                this.assigneeByPageEvent = new PageEvent();
                this.assigneeByPageEvent.length = this.assigneeByLength;
                this.assigneeByPageEvent.pageIndex = this.assigneeByPageIndex;
                this.assigneeByPageEvent.pageSize = this.assigneeByPageSize;

                this.issueStatisticAssigneeByList.push(element);
              });
              this.issueStatisticAssigneeByData.grandTotal = this.issueStatisticAssigneeByData.totalCritical + this.issueStatisticAssigneeByData.totalHigh + this.issueStatisticAssigneeByData.totalMedium + this.issueStatisticAssigneeByData.totalLow;
              console.log("this.issueStatisticAssigneeList", this.issueStatisticAssigneeByList);

            }
          }


        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        this.isIssueStatisticAssigneeByLoading = false;

        // this.toastr.error(errorData.error.message);
      });
  }


  assignedMe(event?: PageEvent) {
    this.isAssignedMeLoading = true;
    this.restService.hitApi('project/assignedMe', {
      'projectId': localStorage.getItem('projectSelected'), 'status': this.assignToMeStatus, 'entityType': ['defect'], "page": event.pageIndex + 1,
      "size": event.pageSize
    }, 'post', '', false)
      .subscribe((result) => {
        let responseData: any = result;
        this.isAssignedMeLoading = false;

        if (responseData.statusCode == 200) {
          this.isAssignedMeLoading = false;
          this.assignedMeData = null;
          this.assignedMeList = [];

          if (this.isAssignMeListRefresh) {
            this.assignedMePageEvent = new PageEvent();;
            this.assignedMePageIndex = 0;
            this.assignedMePageSize = 10;
            this.assignedMeLength = 0;
          }
          this.assignedMeData = responseData.result;
          console.log("assignedMeData", this.assignedMeData);

          if (responseData.result.currentPage != 0) {
            this.assignedMePageIndex = responseData.result.currentPage - 1;
          }

          this.assignedMePageSize = responseData.result.perPage;
          if (responseData.result.total > 0) {
            this.assignedMeLength = responseData.result.total;
          }

          this.assignedMePageEvent = new PageEvent();
          this.assignedMePageEvent.length = this.assignedMeLength;
          this.assignedMePageEvent.pageIndex = this.assignedMePageIndex;
          this.assignedMePageEvent.pageSize = this.assignedMePageSize;

          this.assignedMeData.data.forEach(element => {
            this.assignedMeList.push(element);

          });
          console.log("assignedMeList", this.assignedMeList);
        } else {
          this.isAssignedMeLoading = false;
          this.toastr.error(responseData.message);
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        this.isAssignedMeLoading = false;
        this.toastr.error(errorData.error.message);
      })
  }

  issueStatistics() {
    this.isIssueStatisticsLoading = true;
    this.restService.hitApi('project/issueStatistics', { 'projectId': localStorage.getItem('projectSelected') }, 'post', '', false)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.isIssueStatisticsLoading = false;
          this.issueStatisticData = responseData.result;
          if (this.issueStatisticData) {

            if (this.issueStatisticData.todo) {
              this.issueStatisticData.todo.total = this.issueStatisticData.todo.critical + this.issueStatisticData.todo.high + this.issueStatisticData.todo.medium + this.issueStatisticData.todo.low;
            }
            if (this.issueStatisticData.inProcess) {
              this.issueStatisticData.inProcess.total = this.issueStatisticData.inProcess.critical + this.issueStatisticData.inProcess.high + this.issueStatisticData.inProcess.medium + this.issueStatisticData.inProcess.low;
            }
            if (this.issueStatisticData.quality) {
              this.issueStatisticData.quality.total = this.issueStatisticData.quality.critical + this.issueStatisticData.quality.high + this.issueStatisticData.quality.medium + this.issueStatisticData.quality.low;
            }
            if (this.issueStatisticData.done) {
              this.issueStatisticData.done.total = this.issueStatisticData.done.critical + this.issueStatisticData.done.high + this.issueStatisticData.done.medium + this.issueStatisticData.done.low;
            }
            if (this.issueStatisticData.reopen) {
              this.issueStatisticData.reopen.total = this.issueStatisticData.reopen.critical + this.issueStatisticData.reopen.high + this.issueStatisticData.reopen.medium + this.issueStatisticData.reopen.low;
            }
            if (this.issueStatisticData.notBug) {
              this.issueStatisticData.notBug.total = this.issueStatisticData.notBug.critical + this.issueStatisticData.notBug.high + this.issueStatisticData.notBug.medium + this.issueStatisticData.notBug.low;
            }

            this.issueStatisticData.totalCritical = this.issueStatisticData.todo.critical + this.issueStatisticData.inProcess.critical + this.issueStatisticData.quality.critical + this.issueStatisticData.done.critical + this.issueStatisticData.reopen.critical + this.issueStatisticData.notBug.critical;
            this.issueStatisticData.totalHigh = this.issueStatisticData.todo.high + this.issueStatisticData.inProcess.high + this.issueStatisticData.quality.high + this.issueStatisticData.done.high + this.issueStatisticData.reopen.high + this.issueStatisticData.notBug.high;
            this.issueStatisticData.totalMedium = this.issueStatisticData.todo.medium + this.issueStatisticData.inProcess.medium + this.issueStatisticData.quality.medium + this.issueStatisticData.done.medium + this.issueStatisticData.reopen.medium + this.issueStatisticData.notBug.medium;
            this.issueStatisticData.totalLow = this.issueStatisticData.todo.low + this.issueStatisticData.inProcess.low + this.issueStatisticData.quality.low + this.issueStatisticData.done.low + this.issueStatisticData.reopen.low + this.issueStatisticData.notBug.low;
          }

        } else {
          this.isIssueStatisticsLoading = false;
          this.toastr.error(responseData.message);
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        this.isIssueStatisticsLoading = false;
        this.toastr.error(errorData.error.message);
      })
  }

  navigate(entityType, id) {
    var pid = localStorage.getItem('projectSelected');
    var url;
    if (entityType == 'defect') {
      url = '/project/' + pid + '/defect/' + id;
    } else if (entityType == 'task') {
      url = '/project/' + pid + '/task /' + id;
    } else if (entityType == 'userStory' || entityType == 'userstory') {
      url = '/project/' + pid + '/userstory/' + id;
    } else if (entityType == 'testPlan' || entityType == 'testplan') {
      url = '/project/' + pid + '/testplan/' + id;
    } else if (entityType == 'release') {
      url = '/project/' + pid + '/release/' + id;
    } else if (entityType == 'sprint') {
      url = '/project/' + pid + '/sprint/' + id;
    } else if (entityType == 'testCase' || entityType == 'testcase') {
      url = '/project/' + pid + '/testcase/' + id;
    }
    if (url) {
      this.router.navigateByUrl(url);
    }

  }
  refreshAssigneeByList() {
    this.assigneeByLength = 0;
    this.assigneeByPageIndex = 0;
    this.assignedMePageSize = 10;

    this.assigneeByPageEvent = new PageEvent();
    this.assigneeByPageEvent.length = this.assigneeByLength;
    this.assigneeByPageEvent.pageIndex = this.assigneeByPageIndex;
    this.assigneeByPageEvent.pageSize = this.assignedMePageSize;
    this.isAssignByListRefresh = true;
    this.getIssueStatisticsByAssignee(this.assigneeByPageEvent);

  }
  refreshAssignMeList() {
    this.assignedMePageEvent = new PageEvent();
    this.assignedMePageIndex = 0;
    this.assignedMePageSize = 10;
    this.assignedMeLength = 0;
    this.assignedMePageEvent.length = this.assignedMeLength;
    this.assignedMePageEvent.pageIndex = this.assignedMePageIndex;
    this.assignedMePageEvent.pageSize = this.assignedMePageSize;

    this.isAssignMeListRefresh = true;
    this.assignedMe(this.assignedMePageEvent)
  }
  refreshStatistics() {
    this.issueStatistics();
  }
  goToDefect(entityId) {
    localStorage.setItem('returnTo', "/dashboard");
    this.router.navigateByUrl('/defect/' + entityId);
  }
}
