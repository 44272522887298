
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute,  NavigationExtras} from '@angular/router';

import { RestService } from '../services/rest/rest.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  freePlanData: any;
	constructor(
		private router: Router, 
		private restService: RestService,
		private toastr: ToastrService
		) {
		
	}

  ngOnInit() {

	this.getFreePlan();
  	
  }

  getFreePlan() {
		this.restService.hitApi('users/freePackageDetail',null,'get','',true)
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.freePlanData = responseData.result;
				console.log("this.freePlanData: ", this.freePlanData)
			} else {
				// this.toastr.error(responseData.replyMessage);
			}
		},(err)=>{
			console.log(err);
			let errorData : any = err;
			// this.toastr.error(errorData.error.replyMessage);
		})
	}

  tryFree(){
		if (this.freePlanData.status == 1) {
			this.router.navigateByUrl('/register', { state: { package: "free",  minStorage: this.freePlanData.maxData, minUsers:  this.freePlanData.noOfUsers} });
		} else {
			this.toastr.error("Sorry, free trail not available, please try to pay and use Trakk.in");
		}
	}
}
