import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { BroadcastService } from '../services/broadcast/broadcast.service';

@Component({
  selector: 'app-common-error',
  templateUrl: './common-error.component.html',
  styleUrls: ['./common-error.component.scss']
})
export class CommonErrorComponent implements OnInit {

  statusCode: any;
  constructor(private route: ActivatedRoute, private router: Router, private broadcastService: BroadcastService,) { }

  ngOnInit() {
    this.broadcastService.boradcast("hideLoading");
    this.route.queryParams
      .filter(params => params.statusCode)
      .subscribe(params => {
        console.log(params); // { order: "popular" }

        this.statusCode = params.statusCode;
        console.log(this.statusCode); // popular
      }
    );
  }

  // goToHome() {
  //   this.router.navigate(['/dashboard']);
  // }

}
