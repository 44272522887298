import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Data } from './../ui-components/select-dropdown/Data';

// import { MatDialog } from '@angular/material';
// import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
import { variableDeclarator } from 'node_modules1111/@babel/types/lib';
declare const $:any;

@Component({
  selector: 'app-kanban-board',
  templateUrl: './kanban-board.component.html',
  styleUrls: ['./kanban-board.component.scss']
})
export class KanbanBoardComponent implements OnInit {
	sprintCreateForm: FormGroup;
	assignToForm: FormGroup;
	selectedSprint: any;
	sprintData: any = [];
	pid : any;
	
	projectData : any;
	sprintList : any;
	userList: any;

	sprintBoardTodoData: any = [];
	sprintBoardInprogressData: any = [];
	sprintBoardQualitycheckData: any = [];
	sprintBoardDoneData: any = [];
	sprintBoardData: any = [];
	backlogList: any = [];
	todoList: any = [];
	inProgressList: any = [];
	qualityCheckList: any = [];
	doneList: any = [];
	reopenList: any = [];
	notABugList: any = [];
	jqeryObject = $;
	assignUserList: any = [];
	subs = new Subscription();
	selectedIssue: any;
	isLoading: boolean = false;
	searchdata: any;
	selectAssign: any = {
		name: 'Unassigned',
		value: 0
	}

	constructor(
		private restService : RestService, 
		private router : Router,
		private route : ActivatedRoute,
		private formBuilder:FormBuilder,
		private toastr : ToastrService) { 
			// Check for route params
		this.route.params.subscribe(params => {
			this.pid = params['pid'];
			if (this.pid && this.pid !== null && this.pid !== undefined) {
				
				this.getProjectDetail();	
			}
		});
    //this.getUserList();
    this.getSprintBoardList();

	}
	
	ngOnInit() {

		$(document).ready(function() {
			$('.common-show-hidden-menu-horz').click(function() {
				$('.common-hidden-menu-horz').slideToggle("fast");
			});

			/*setDivHeights();*/
			$('.box').scrollable();
		});
		
// height patch

setInterval(function () { var maxHeight = 0;$("div.content-area").each(function(){if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }});$("div.content-area").height(maxHeight);}, 500);

// height patch
		

		$(document).mouseup(function(e) 
		{
			if (!$(".stop-add-watches").is(e.target) && $(".stop-add-watches").has(e.target).length === 0 && !$(".common-show-hidden-menu-horz").is(e.target) && $(".common-show-hidden-menu-horz").has(e.target).length === 0) 
			{
				$(".stop-add-watches").slideUp("fast");
			}

			// if (!$("#select-sprint-open-dpw").is(e.target) && $("#select-sprint-open-dpw").has(e.target).length === 0 && !$("#select-sprint-dpw").is(e.target) && $("#select-sprint-dpw").has(e.target).length === 0) 
			// {
			// 	$("#select-sprint-open-dpw").slideToggle("fast");
			// }
		});

		this.createFormForAssignUser(null);
		
	}

	refreshAPI() {
		this.isLoading = true;
		this.getProjectDetail();
		//this.getUserList();
		this.getSprintBoardList();

		setTimeout(() => {
			this.toastr.success("Refreshed successfully");
		}, 1000);		
	}

	searchData() {
		this.searchdata = $('#search_name').val();
		this.getSprintBoardList();
	}

	createFormForAssignUser(data) {
		if (data === null) {
			this.assignToForm = this.formBuilder.group({
				'assignName': ['', [Validators.required]],
				'assignId': ['', [Validators.required]],
			});

		} else {
			this.assignToForm = this.formBuilder.group({
				'assignName': [data.releaseName, [Validators.required]],
				'assignId': [data.releaseId, [Validators.required]],
			});
		}

	}

	getProjectDetail(){
		// this.restService.hitApi('project/details',{'projectId':this.pid},'post','',true)
		// .subscribe((result)=>{
		// 	let responseData : any = result;
		// 	if(responseData.statusCode==200){
		// 		this.projectData = responseData.result;
		// 	}else{
		// 		// this.toastr.error(responseData.message);
		// 	}
		// },(err)=>{
		// 	console.log(err);
		// 	let errorData : any = err;
		// 	// this.toastr.error(errorData.error.message);
		// })
	}

	changeProject(pid){
		localStorage.setItem('projectSelected',pid)
		$('.project-title-list').toggle("slide");
	}

	getSprintBoardList() {
		this.isLoading = true;
		this.sprintBoardData = [];
		this.restService.hitApi('project/kanban',{"projectId":this.pid, "keyword": this.searchdata},'post','',true)
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.sprintBoardData = responseData.result;

				console.log("this.sprintBoardData: ", this.sprintBoardData);
				this.backlogList = [];
				this.todoList = [];
				this.inProgressList = [];
				this.qualityCheckList = [];
				this.doneList = [];
				this.reopenList = [];
				this.notABugList = [];

				this.sprintBoardData.list.forEach(element => {
					if (element.status == 0) {
						this.backlogList.push(element);
					} else if (element.status == 1) {
						this.todoList.push(element);
					} else if (element.status == 2) {
						this.inProgressList.push(element);
					} else if (element.status == 3) {
						this.qualityCheckList.push(element);
					} else if (element.status == 4) {
						this.doneList.push(element);
					} else if (element.status == 5) {
						this.reopenList.push(element);
					} else if (element.status == 6) {
						this.notABugList.push(element);
					}
				});

				console.log("this.backlogList: ", this.backlogList);

			} else {
				// this.toastr.error(responseData.message);
			}
			this.isLoading = false;
		},(err)=>{
			this.isLoading = false;
			console.log(err);
			let errorData : any = err;
			// this.toastr.error(errorData.error.message);
		})
	}

	getUserList(){
		
		this.restService.hitApi('project/projectUsers',{"projectId":this.pid},'post','',true)
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.userList = responseData.result;
				this.assignUserList = [];
				console.log("userList, :", this.userList);
				console.log("this.selectedIssue", this.selectedIssue)
				if (this.selectedIssue) {
					if (this.selectedIssue.entityType == 'defect') {
						if (this.selectedIssue.type == 1) {
							this.userList.forEach(element => {
								let data = new Data(element.name, element.userId);
								this.assignUserList.push(data);
							});

							return
						} 
					}
				}

				this.userList.forEach(element => {
					if (element.companyRole != 3) {
						let data = new Data(element.name, element.userId);
						this.assignUserList.push(data);
					}
				});

			}else{
				this.toastr.error(responseData.message);
			}
		},(err)=>{
			console.log(err);
			let errorData : any = err;
			// this.toastr.error(errorData.error.message);
		})
	}

	assignUser(id,uid,entity,status){
		
		this.restService.hitApi('sprints/sprintBoardUpdateAssignee',{"projectId":this.pid,"userId":uid,"id":id,"entity":entity},'post','',false)
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
        $('#'+entity+'-'+id).toggle('down');
        this.getSprintBoardList();
			}else{
				// this.toastr.error(responseData.message);
			}
		},(err)=>{
			console.log(err);
			let errorData : any = err;
			// this.toastr.error(errorData.error.message);
		})
	}

	// getAssign(obj: Data) {
		
	// 	if (obj) {
	// 		console.log("selectedRole" + obj.name);
	// 		console.log("selectedId" + obj.value);
	// 		this.assignToForm.get('assignId').setValue(parseInt(obj.value));
	// 		this.assignToForm.get('assignName').setValue(parseInt(obj.name));

	// 		console.log(this.assignToForm);
	// 	}
	// }

	getAssign(event) {
		if (event) {
			let id = event.target.value;
			this.selectAssign.value = id
		}
	}

	closeModal() {
		this.selectAssign.name = "Unassigned"
		this.selectAssign.value = 0
		//this.assignToForm.reset();
		$('#assign_to_user_popup_web').modal('hide');
	}

	setSelectedAssign(obj: any) {
		console.log("assignId ", parseInt(obj.id), " " + obj?.assignee);
		this.selectedIssue=obj; 
		if (obj.assignee) {
			this.selectAssign.name = obj?.assignee
			this.selectAssign.value = obj?.assigneeId
		}

		console.log("this.selectAssign :", this.selectAssign.value)
		console.log("this.selectAssign :", this.selectAssign.name)
		// this.assignToForm.get('assignId').setValue(parseInt(obj.id));
		// this.assignToForm.get('assignName').setValue(obj?.assignee);

		this.getUserList();
	}

	makeAssign() {

		var userID = this.selectAssign.value;
		if (this.selectAssign.value == 0) {
			userID = null;
		}

		console.log("userIDuserIDuserID", userID);
		//if (userID != '' && userID != null && userID != undefined && userID != 'NaN') {
			if (this.selectedIssue.assigneeId != userID) {
				var entityType = this.selectedIssue.entityType;
				let id = this.selectedIssue.id;

				console.log("my data: ", this.assignToForm.value, " " + userID, " " , entityType, " ", id);

				if (this.selectedIssue.entityType == "userStory") {
					entityType = "userStories"
				} else if (this.selectedIssue.entityType == "task") {
					entityType = "tasks"
				} else if (this.selectedIssue.entityType == "defect") {
					entityType = "defects"
				} else if (this.selectedIssue.entityType == "testPlan") {
					entityType = "testPlans"
				}

				this.restService.hitApi('sprints/sprintBoardUpdateAssignee',{"projectId":this.pid,"userId":userID,"id":id,"entity":entityType},'post','',false)
				.subscribe((result)=>{
					let responseData : any = result;
					this.closeModal();
					if(responseData.statusCode==200){
						this.getSprintBoardList();
					} else {
						this.toastr.error(responseData.message);
					}
				},(err)=>{
					console.log(err);
					let errorData : any = err;
					this.toastr.error(errorData.error.message);
				})
			} else {
				if (userID == null) {
					this.toastr.error("Please choose user to assign.");
				} else {
					this.toastr.error("This issue is already assign to this user, please choose another");
				}
			}
		// } else {
		// 	this.toastr.error("Please choose user");
		// }
		
	}
  
	// makeAssign() {

	// 	let userID = this.assignToForm.value.assignId;
	// 	var entityType = this.selectedIssue.entityType;
	// 	let id = this.selectedIssue.id;

	// 	console.log("my data: ", this.assignToForm.value, " " + userID, " " , entityType, " ", id);

	// 	if (this.selectedIssue.entityType == "userStory") {
	// 		entityType = "userStories"
	// 	} else if (this.selectedIssue.entityType == "task") {
	// 		entityType = "tasks"
	// 	} else if (this.selectedIssue.entityType == "defect") {
	// 		entityType = "defects"
	// 	} else if (this.selectedIssue.entityType == "testPlan") {
	// 		entityType = "testPlans"
	// 	}



	// 	this.restService.hitApi('sprints/sprintBoardUpdateAssignee',{"projectId":this.pid,"userId":userID,"id":id,"entity":entityType},'post','',false)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		this.closeModal();
	// 		if(responseData.statusCode==200){
	// 			this.getSprintBoardList();
	// 		} else {
	// 			 this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		this.toastr.error(errorData.error.message);
	// 	})
	// }

	drop(event: CdkDragDrop<string[]>) {
		if (event.previousContainer === event.container) {
		  moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
		  transferArrayItem(event.previousContainer.data,
							event.container.data,
							event.previousIndex,
							event.currentIndex);
		}

		console.log("event: ", event);

		let id = null;
		var sprintId = "";
		var entity = "";
		var status = 0;

		if (event.container.data != null) {
			let obj: any = event.container.data[event.currentIndex];
			entity = obj.entityType;
			id = obj.id.toString();
		}

		if (event.container.id != null) {
			if (event.container.id == "reopen" || event.container.id == "notABug") {
				if (entity != "defect") {
					transferArrayItem(event.container.data,
						event.previousContainer.data,
						event.currentIndex,
						event.previousIndex);
						
						if (event.container.id == "reopen") {
							this.toastr.error("Only bugs are allowed to move into reopen");
						} else {
							this.toastr.error("Only bugs are allowed to move into not a bug");
						}
						
					return
				} 
			}

			if (event.container.id == "backlog") {
				status = 0;
			} else if (event.container.id == "todo") {
				status = 1;
			} else if (event.container.id == "inProgress") {
				status = 2;
			} else if (event.container.id == "qualityCheck") {
				status = 3;
			} else if (event.container.id == "done") {
				status = 4;
			} else if (event.container.id == "reopen") {
				status = 5;
			} else if (event.container.id == "notABug") {
				status = 6;
			}
		}

		//userStory, task, defect, testPlan

		let data = {"projectId":this.pid,"status":status,"entity":entity,"id":id}
		console.log("datadatadata, ", data);

		this.restService.hitApi('sprints/sprintBoardUpdateStatus',data,'post','',false)
		.subscribe((result)=>{
			let responseData : any = result;
			
			// if(responseData.statusCode==200){
			// 	this.getSprintBoardList();
			// }

		},(err)=>{
			console.log(err);
			let errorData : any = err;
			// this.toastr.error(errorData.error.message);
		})

	  }

	

}


