import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup,Validators} from '@angular/forms'
// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-credit-card',
	templateUrl: './credit-card.component.html',
	styleUrls: ['./credit-card.component.scss'],
})

export class UpdateCreditCardComponent implements OnInit {
	
	userData : any = JSON.parse(localStorage.getItem("userData"));
	creditCardForm : FormGroup;
	countries : any = [];
	states : any = [];
	constructor(
		public restService : RestService, 
		private toastr: ToastrService,
		private router : Router,
		private route : ActivatedRoute,
		private formBuilder : FormBuilder
		) {
			this.createForm(null);
			this.getCreditCardDetails();
		}
	
	ngOnInit() {
		
	}

	getCreditCardDetails(){
		this.restService.hitApi('user/userCardDetail',{},'get','',false)
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.countries = responseData.result.countries;
				if(responseData.result.user.state){
					this.onCountryChange(responseData.result.user.country);
				}
				this.createForm(responseData.result);
			}else{
				this.toastr.error(responseData.message);
			}
			
		},(err)=>{
			let errorData : any = err;
			this.toastr.error(errorData.err.message);
		})
	}

	createForm(data){
		this.creditCardForm = this.formBuilder.group({
			'card_holder_name':[data?data.card.cardHolderName:'',[Validators.required]],
			'card_number':[data?data.card.cardNumber:'',[Validators.required]],
			'cvv':['',[Validators.required]],
			'expiry':[data?(data.card.expiryMonth+"/"+data.card.expiryYear):'',[Validators.required]],
			'address':[data?data.user.address:''],
			'city':[data?data.user.city:''],
			'state':[data?data.user.state:''],
			'country':[data?data.user.country:''],
			'zip':[data?data.user.zip:''],
		})
	}

	updateCredtCard(){
		let expiry : any = this.creditCardForm.value.expiry.split("/");
		this.creditCardForm.value.expiry_month = expiry[0];
		this.creditCardForm.value.expiry_year = expiry[1];

		this.restService.hitApi('user/updateCardDetail',this.creditCardForm.value,'post')
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.getCreditCardDetails();
				this.toastr.success(responseData.message);
			}else{
				this.toastr.error(responseData.message);
			}
			
		},(err)=>{
			let errorData : any = err;
			this.toastr.error(errorData.err.message);
		})
	}

	onCountryChange(country_id){
		this.restService.hitApi('user/getStates',{'country_id':country_id},'post','',false)
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.states = responseData.result;
			}else{
				this.toastr.error(responseData.message);
			}
			
		},(err)=>{
			let errorData : any = err;
			this.toastr.error(errorData.err.message);
		})
		
	}

	
	
}


