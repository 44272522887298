import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { Data } from '../ui-components/select-dropdown/Data';
import { BroadcastService } from '../services/broadcast/broadcast.service';
import { PreservedSearchStateService } from '../services/search/preserved-search-state.service';

// import * as moment from 'moment';
declare const $: any;
@Component({
	selector: 'app-project-list',
	templateUrl: './project-list.component.html',
	styleUrls: ['./project-list.component.scss'],
})

export class ProjectListComponent implements OnInit, OnDestroy {
	projectAddForm: FormGroup;


	projectListData: any = [];
	assocUsers: any;
	storageData: any;
	maxData: any;
	fetchingData: boolean = true;
	projectAddFormSubmit: boolean = false;
	userData: any = JSON.parse(localStorage.getItem("userData"));

	listShow: any = 1;
	selectedForDelete: any;
	sortOrder: any = 'desc';
	sortByField: any = 'id'
	companiesData: any;
	cur_company_id: any;
	availability: Boolean;

	pageEvent: PageEvent;
	datasource: any;
	pageIndex: number = 0;
	pageSize: number = 10;
	length: number = 0;
	searchString: string = "";
	isLoading: boolean = false;
	isCreateProjectDialogShow: boolean = true;
	typeList = [{ name: 'Agile', value: "1" }, { name: 'Kanban', value: "2" }];
	isEdit: boolean;

	isNoSearchResults: boolean = false;
	isNoData: boolean = false;
	isOwner: boolean;
	isAdmin: boolean;
	isOther: boolean;

	constructor(
		private restService: RestService,
		private toastr: ToastrService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private broadcastService: BroadcastService,
		private preserveSearch: PreservedSearchStateService
	) {

		this.broadcastService.subscribe("FETCH_PROJECT_LIST", (pid) => {
			this.isLoading = true;
			this.getProjectList(this.pageEvent);
		});

	}

	ngOnInit() {
		this.recoverLastSearchValue();

		$(document).ready(() => {
			$('.htmlEditor').summernote({
				height: '150px',
				focus: true,
				disableDragAndDrop: true,
				dialogsInBody: true,
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
				callbacks: {
					onPaste: function (e) {
						var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
						e.preventDefault();
						document.execCommand('insertText', false, bufferText);
					}
				}
			});

			$('#summernote').summernote({
				dialogsInBody: true
			});

		});

		// this.restService.getCompanies();
		this.companiesData = JSON.parse(localStorage.getItem('companyData'));
		this.cur_company_id = localStorage.getItem('company_id');

		var isOwner = localStorage.getItem('isOwner');
		if (isOwner == "true") {
			this.isOwner = true;
			this.isAdmin = false;
			this.isOther = false;
		} else {
			if (this.userData.role == 1) {
				this.isOwner = false;
				this.isAdmin = true;
				this.isOther = false;
			} else {
				this.isOwner = false;
				this.isAdmin = false;
				this.isOther = true;
			}
		}

		if (this.pageEvent == null) {
			this.pageEvent = new PageEvent();
			this.pageEvent.length = this.length;
			this.pageEvent.pageIndex = this.pageIndex;
			this.pageEvent.pageSize = this.pageSize;
		}

		this.createForm(null);

		this.isLoading = true;
		this.getProjectList(this.pageEvent);

		// this.getTotalStorage();

		// if (!this.restService.isAdmin(this.userData)) {
		// 	this.router.navigate(['/dashboard']);
		// }

	}

	ngOnDestroy() {
		this.preserveSearch.searchProjectState = {
			searchValue: this.searchString,
			pageEvent: this.pageEvent,
			results: this.projectListData,
			resultsLength: this.projectListData.length,
		};
	}

	recoverLastSearchValue() {
		const lastSearch = this.preserveSearch.searchProjectState;
		if (lastSearch) {
			this.searchString = lastSearch.searchValue;
			this.projectListData = lastSearch.results;
			this.pageEvent = lastSearch.pageEvent;
			//this.resultsLength = lastSearch.resultsLength;
		}
	}


	createForm(data) {
		if (data === null) {
			this.projectAddForm = this.formBuilder.group({
				'name': ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
				'description': [''],
				'shortCode': [''],
				'projectId': [''],
				'typeName': [this.typeList[0].name, [Validators.required]],
				'type': [this.typeList[0].value, [Validators.required]],
			});
			this.isEdit = false;
		} else {
			// if (data.description) {
			// 	data.description.replace(/↵/g, '<br/>');
			// }
			$("#summernote").summernote('code', data.description);
			this.projectAddForm = this.formBuilder.group({
				'name': [data.name, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
				'description': [data.description],
				'shortCode': [data.shortCode],
				'projectId': [data.id],
				'typeName': [this.typeList[0].name, [Validators.required]],
				'type': [this.typeList[0].value, [Validators.required]],
			});
			this.isEdit = true;
		}
		console.log("this.projectAddForm when form create ", this.projectAddForm);

	}

	addProject() {
		this.projectAddForm.value.description = $('#summernote').val();
		if (this.projectAddForm.invalid) {
			this.projectAddFormSubmit = true;
			return;
		} else {
			this.projectAddFormSubmit = false;
			1
			let apiname = this.projectAddForm.value.projectId ? 'project/update' : 'project/create';
			// if(this.projectAddForm.value.projectId){
			this.restService.hitApi(apiname, this.projectAddForm.value, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.toastr.success(responseData.message);
						this.closeModal();
						this.getProjectList(this.pageEvent);
						this.broadcastService.boradcast('FETCH_PROJECT_LIST')
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}
		// }else{

		// 	this.restService.hitApi('project/create',this.projectAddForm.value,'post','',true)
		// 	.subscribe((result)=>{
		// 		let responseData : any = result;
		// 		if(responseData.statusCode==200){
		// 			$("#create_project_popup_web").modal('hide');
		// 			this.projectAddForm.reset();
		// 			this.getProjectList();
		// 			this.toastr.success(responseData.message);
		// 		}else{
		// 			this.toastr.error(responseData.message);
		// 		}
		// 	},(err)=>{
		// 		// console.log(err);
		// 		let errorData : any = err;
		// 		this.toastr.error(errorData.error.message);
		// 	})
		// }
	}

	checkAvailability() {
		this.restService.hitApi('project/short-code', { 'customCode': this.projectAddForm.value.shortCode }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.availability = true;
					this.toastr.success(responseData.message);
				} else {
					this.availability = false;
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				this.availability = false;
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	sortData(field) {
		this.sortByField = field;
		this.sortOrder = this.sortOrder == 'asc' ? 'desc' : 'asc';
		this.getProjectList(this.pageEvent);
	}

	//projectList
	getProjectList(event?: PageEvent) {
		let postdata = {
			"sort": {
				"column": this.sortByField,
				"direction": this.sortOrder
			},
			"page": event.pageIndex + 1,
			"size": event.pageSize,
			"q": this.searchString
		};
		this.restService.hitApi('companyUser/projects', postdata, 'post', '', true)
			.subscribe((result) => {

				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.projectListData = responseData.result.data;
					this.datasource = responseData.result;
					if (responseData.result.currentPage != 0) {
						this.pageIndex = responseData.result.currentPage - 1;
					}

					this.pageSize = responseData.result.perPage;
					this.length = responseData.result.total;
					this.pageEvent = new PageEvent();
					this.pageEvent.length = this.length;
					this.pageEvent.pageIndex = this.pageIndex;
					this.pageEvent.pageSize = this.pageSize;
					this.setIndexArray();
					this.checkIfDataHasComes();
				} else {
					this.fetchingData = false;
					// this.toastr.error(responseData.message);
				}
				this.isLoading = false;
			}, (err) => {
				this.isLoading = false;
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	checkIfDataHasComes() {
		if (this.projectListData.length == 0) {
			if (this.searchString != "" && this.searchString != null) {
				this.isNoSearchResults = true;
				this.isNoData = false;
			} else {
				this.isNoData = true;
				this.isNoSearchResults = false;
			}
		} else {
			this.isNoData = false;
			this.isNoSearchResults = false;
		}
	}

	getType(obj: Data) {
		if (obj) {
			this.projectAddForm.get('type').setValue(parseInt(obj.value));
			this.projectAddForm.get('typeName').setValue(parseInt(obj.name));
		}
	}

	setIndexArray() {
		for (let i = 0; i < this.projectListData.length; i++) {
			if (this.pageIndex == 0) {
				this.projectListData[i].tableIndex = i + 1;
			} else {
				this.projectListData[i].tableIndex = (this.pageSize * this.pageIndex) + i + 1;
			}

			if (this.projectListData[i].type == 1) {
				this.projectListData[i].typeName = "Agile"
			} else {
				this.projectListData[i].typeName = "Kanban"
			}
		}
	}

	openEditProject(data) {
		this.createForm(data)
		$('#update_project_popup_web').modal({ backdrop: 'static', keyboard: false });
	}

	openDeleteProject(data) {
		this.selectedForDelete = data;
		$('#delete-project').modal({ backdrop: 'static', keyboard: false });
	}

	deleteProject(pid) {
		var index;
		for (var i = 0; i < this.projectListData.length; i++) {
			if (this.projectListData[i].id == pid) {
				index = i;
				break;
			}
		}

		this.restService.hitApi('project/deleteProject', { 'projectId': pid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('#delete-project').modal('hide');
					let lpid = localStorage.getItem('projectSelected');
					if (pid == lpid) {
						console.log("pid == this.pid")
						this.broadcastService.boradcast("SELETED_PROJECT_DELETED", pid);
						localStorage.removeItem("projectSelected");
					}
					if (index != -1) {
						this.projectListData.splice(index, 1);
						if (this.projectListData.length == 0) {
							this.pageEvent = new PageEvent();
							this.pageEvent.length = this.length;
							this.pageEvent.pageIndex = 0;
							this.pageEvent.pageSize = this.pageSize;

							this.getProjectList(this.pageEvent);

						}
						this.datasource.total = this.datasource.total - 1;
						this.length = this.datasource.total;
					}
					this.broadcastService.boradcast("FETCH_PROJECT_LIST", pid);
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	searchProject() {
		let searchdata: any = { "search": {} };
		this.searchString = $('#search_name').val();
		console.log("i am searching", this.searchString);
		console.log(this.pageEvent);

		if (this.searchString.length >= 2 || this.searchString.length == 0) {
			this.pageEvent = new PageEvent();
			this.pageEvent.length = 0;
			this.pageEvent.pageIndex = 0;
			this.pageEvent.pageSize = 10;
			this.getProjectList(this.pageEvent);

		}



		// if ($('#search_name').val() != "") {
		// 	searchdata.search.name = $('#search_name').val();
		// }
		// if($('#search_shortcode').val()!=""){
		// 	searchdata.search.shortCode = $('#search_shortcode').val();
		// }
		// if($('#search_owner').val()!=""){
		// 	searchdata.search.owner = $('#search_owner').val();
		// }
		// if ($('#search_storage').val() != "") {
		// 	searchdata.search.storage = $('#search_storage').val();
		// }
		// if ($('#search_member').val() != "") {
		// 	searchdata.search.member = $('#search_member').val();
		// }

		// this.restService.hitApi('companyUser/projects', searchdata, 'post', '', true)
		// 	.subscribe((result) => {
		// 		let responseData: any = result;
		// 		if (responseData.statusCode == 200) {
		// 			this.projectListData = responseData.result.data;
		// 		} else {
		// 			this.fetchingData = false;
		// 		}
		// 	}, (err) => {
		// 		console.log(err);
		// 		let errorData: any = err;
		// 		// this.toastr.error(errorData.error.message);
		// 	})
	}



	// getTotalStorage(){
	// 	//companyUser/project-total-storage
	// 	this.restService.hitApi('companyUser/project-total-storage',{},'get','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.storageData = responseData.result;

	// 			if(this.storageData.packageDetails.maxData<1024)
	// 				this.maxData = this.storageData.packageDetails.maxData+" MB";
	// 			else if(this.storageData.packageDetails.maxData>=1024)
	// 				this.maxData = this.storageData.packageDetails.maxData/1024+" GB"

	// 				// if(this.storageData.totalStorage<1024)
	// 			// 	this.storageSpace = this.storageData.totalStorage+" Bytes";
	// 			// else if(this.storageData.totalStorage>=1024 && this.storageData.totalStorage<1048576)
	// 			// 	this.storageSpace = (this.storageData.totalStorage/1024)+" KB";
	// 			// else if(this.storageData.totalStorage>=1024 && this.storageData.totalStorage<1048576)
	// 			// 	this.storageSpace = (this.storageData.totalStorage/1024*2)+" MB";
	// 			// else if(this.storageData.totalStorage>=1024 && this.storageData.totalStorage<1048576)
	// 			// 	this.storageSpace = (this.storageData.totalStorage/1024*3)+" GB";

	// 		}else{
	// 			this.fetchingData = false;
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }



	// showProjectList(status){
	// 	this.listShow = status;
	// 	this.getProjectList();
	// }

	// changeStatus(status,pid){
	// 	// const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
	// 	// 	width: '350px',
	// 	// 	data: (status==1)?"Do you really want to open this project?":"Do you really want to close this project?"
	// 	//   });
	// 	//   dialogRef.afterClosed().subscribe(result => {
	// 	// 	if(result) {
	// 	// 	  this.restService.hitApi('project/updateStatus',{'projectId':pid, 'status':status},'post','',true)
	// 	// 		  .subscribe((result)=>{
	// 	// 			  let responseData : any = result;
	// 	// 			  if(responseData.statusCode==200){
	// 	// 				  this.getProjectList();
	// 	// 				  // this.discussionData = responseData.result;
	// 	// 			  }else{
	// 	// 				  // this.toastr.error(responseData.message);
	// 	// 			  }
	// 	// 		  },(err)=>{
	// 	// 			  console.log(err);
	// 	// 			  let errorData : any = err;
	// 	// 			  // this.toastr.error(errorData.error.message);
	// 	// 		  })
	// 	// 	}
	// 	//   });
	// }

	// openpopup(content,data){
	// 	this.assocUsers = data;
	// 	// this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
	// 	// 	// this.assocUsers = this.projectListData[0].projectUsersProject;
	// 	//   }, (reason) => {
	// 	// 	// this.inviteForm.reset();
	// 	// });
	// }

	// closeModal(){
	// 	this.assocUsers = "";
	// 	// this.modalService.dismissAll();z
	// }


	closeModal() {
		$("#create_project_popup_web").modal('hide');
		this.isCreateProjectDialogShow = false;
		setTimeout(() => {
			this.resetForm();
			this.isCreateProjectDialogShow = true;
			this.setHeightOfSummarNote();
		});


	}
	resetForm() {
		this.projectAddForm.reset();
		this.projectAddForm.get('name').setValue(null);
		this.projectAddForm.get('typeName').setValue(this.typeList[0].name);
		this.projectAddForm.get('type').setValue(this.typeList[0].value);
		$("#summernote").summernote('reset');
	}


	setHeightOfSummarNote() {

		this.isCreateProjectDialogShow = true;

		$(document).ready(function () {
			$('.htmlEditor').summernote({
				height: '150px',
				focus: true,
				disableDragAndDrop: true,
				dialogsInBody: true,
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
				callbacks: {
					onPaste: function (e) {
						var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
						e.preventDefault();
						document.execCommand('insertText', false, bufferText);
					}
				}
			});

			$('#summernote').summernote({
				dialogsInBody: true
			});

		})


	}
	changeProject(pid) {
		this.router.navigateByUrl('/project-view/' + pid);
		this.restService.hitApi('user/updateProject', { 'project_id': pid }, 'post', '', false)
			.subscribe((result) => {
				console.log("user/updateProject", result)
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					//this.toastr.success(responseData.message);
					localStorage.setItem('userData', JSON.stringify(responseData.result));
					if (pid != null && pid != "null") {
						localStorage.setItem('projectSelected', pid);
					}
					//localStorage.setItem('projectSelected', pid);
					localStorage.removeItem('sprintIdSelected');
					localStorage.removeItem('sprintNameSelected');
					this.broadcastService.boradcast("SWITCH_PROJECT", pid);
				} else {
					console.log("responseData.message", responseData.message);
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}
	formatBytes(bytesValue) {
		if (bytesValue >= 1073741824) {
			return (bytesValue / (1073741824)).toFixed(2) + ' GB';
		} else {
			return (bytesValue / (1048576)).toFixed(2) + ' MB';
		}
	}

}

