import { Component, OnInit } from '@angular/core';
import { BroadcastService } from '../services/broadcast/broadcast.service';

@Component({
  selector: 'app-plan-expire',
  templateUrl: './plan-expire.component.html',
  styleUrls: ['./plan-expire.component.scss']
})
export class PlanExpireComponent implements OnInit {

  isOwner: any = JSON.parse(localStorage.getItem("isOwner"));

  constructor(private broadcastService: BroadcastService,) { }

  ngOnInit() {

    this.broadcastService.boradcast("PLAN_EXPIRED");

    // MARK: - beacuse sometime it is not working
    // let userData = JSON.parse(localStorage.getItem("userData"));
    // this.isOwner = userData.isCompanyOwner

    if (this.isOwner == false) {
      let userData = JSON.parse(localStorage.getItem("userData"));
      if (userData.isCompanyOwner == true) {
        this.isOwner = userData.isCompanyOwner
      }
    }

  }

}
