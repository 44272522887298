import { Target } from '@angular-devkit/architect';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ValidationService } from '../services/config/config.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Data } from '../ui-components/select-dropdown/Data';
import { BroadcastService } from '../services/broadcast/broadcast.service';
import { RouteEventsService } from '../services/route-events.service';
import { Location } from '@angular/common';


declare const $: any;

@Component({
	selector: 'app-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss'],
})

export class UserProfileComponent implements OnInit {
	userListData: any;
	fetchingData: boolean = true;
	userData: any = JSON.parse(localStorage.getItem("userData"));
	associatedProjects: any = [];
	allProjects: any = [];
	selectUser: any;
	userid: any;
	userInfo: any;
	selectedForDelete: any;
	selectedForRoleChange: any;
	userCreateForm: FormGroup;
	userProfileForm: FormGroup
	projectList: any;
	companiesData: any;
	cur_company_id: any;
	userIdAssign: any;
	userCreateFormSubmit: boolean = false;
	showCreateUserPopup: boolean = false;
	showEditUserPopup: boolean = false;
	roleTypes;
	roleTypesForOwner = [{ name: 'Company Owner', value: 1 }];
	roles;
	permissionLevels;
	searchData: any = "";
	isLoading = false;
	isOwner = false;
	commingFrom = '';
	isBackButtonShown: boolean = true;
	backButtonText;
	selectedProject: any;
	isEditProjectsPopupShow : boolean = false;

	constructor(
		public restService: RestService,
		private toastr: ToastrService,
		private _location: Location,
		// private router : Router,
		// private formBuilder : FormBuilder,
		// private route : ActivatedRoute
		// ) { 

		// 	this.route.params.subscribe(params => {
		// 		this.userid = params['userid'];
		// 		this.userIdAssign = this.userid;
		// 	});
		// }

		private router: Router,
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private broadcastService: BroadcastService,
		private routeEventsService: RouteEventsService
	) {
		console.log("route", this.routeEventsService.previousRoutePath.value);



		this.roleTypes = [{ name: 'Company Admin', value: 1 }, { name: 'Company User', value: 2 }, { name: 'Client', value: 3 }];
		this.route.params.subscribe(params => {
			this.userid = params['userid'];
			this.userIdAssign = this.userid;

			if (this.userid) {
				this.isBackButtonShown = true;
			} else {
				this.isBackButtonShown = false;
			}
			if (this.routeEventsService.previousRoutePath.value.includes('/user-management')) {
				this.backButtonText = 'Back To Users List';
			} else if (this.routeEventsService.previousRoutePath.value.includes('/project-view')) {
				this.backButtonText = 'Back To Project';
			} else {
				this.backButtonText = 'Back'
			}
		});

		// this.roles = [];
		// for (var i = 0; i < this.roleTypes.length; i++) {
		// 	var role = this.roleTypes[i];
		// 	var data;
		// 	if (role == 'Company Admin') {
		// 		data = new Data(role, "1");

		// 	} else if (role == 'Company User') {
		// 		data = new Data(role, "2");
		// 	} else if (role == "Client") {
		// 		data = new Data(role, "3");
		// 	}
		// 	this.roles.push(data);
		// }

		this.permissionLevels = [{
			name: 'Project Manager',
			value: 1
		}, {
			name: 'Company User',
			value: 3
		}];

		this.createForm(null);


		// console.log("this.userid"+this.userid);
	}

	ngOnInit() {
		if (localStorage.getItem('returnTo')) {
			console.log("localStorage.getItem('returnTo')", localStorage.getItem('returnTo'));
			this.commingFrom = localStorage.getItem('returnTo') ? localStorage.getItem('returnTo') : '';
		}

		// if(!this.restService.isAdmin(this.userData)){
		// 	this.router.navigate(['/discussion-board']);
		// }

		// this.restService.getCompanies();
		// this.companiesData = JSON.parse(localStorage.getItem('companyData'));
		// this.cur_company_id = localStorage.getItem('company_id');



		//   this.showAssociatedProjects();

		if (this.userid) {
			this.getUserprofile();
		} else {
			// console.log(this.userData);
			// this.userInfo = this.userData;
			this.userIdAssign = this.userData.id;
			this.getUserprofile();
			this.getAvailableProjects();
			this.getAssociatedProjects();
		}
		this.createForm(null);

		$(document).ready(function () {
			$('.edit-user-role-work-status-anchor').click(function (e) {
				$('.edit-user-role-progress-work-st-js').slideToggle("fast");
			});
		});


	}


	createForm(data) {
		if (data === null) {
			this.setRoles();
			this.userCreateForm = this.formBuilder.group({
				'roleName': [''],
				'roleType': ['', [Validators.required]],
				'name': ['', [Validators.required]],
				'emailAddress': ['', [Validators.required, ValidationService.emailValidator]],
				'mobile': [''],
				'companyUserId': [''],
				'role': ['']
			});
			this.showCreateUserPopup = true;
			this.showEditUserPopup = false;

		} else {
			console.log(data);
			var roleName = '';
			var role;
			if (data.roleType == 1 || data.role == 1) {
				roleName = this.roleTypes[0].name;
				role = this.roleTypes[0];
				data.roleType = 1;
				this.setRolesAgainForEdit();
				if (this.isOwner) {
					this.setRolesForOwnerForEdit();
				}

			} else if (data.roleType == 2 || data.role == 2) {
				roleName = this.roleTypes[1].name;
				role = this.roleTypes[1]
				data.roleType = 2;
				this.setRolesAgainForEdit();

			} else if (data.roleType == 3 || data.role == 3) {
				roleName = this.roleTypes[2].name;
				role = this.roleTypes[2]
				data.roleType = 3;
				this.setRoles()

			}
			this.userCreateForm = this.formBuilder.group({
				'roleName': [roleName, [Validators.required]],
				'roleType': [data.roleType, [Validators.required]],
				'name': [data.name, [Validators.required]],
				'emailAddress': [data.email, [Validators.required, ValidationService.emailValidator]],
				'mobile': [data.mobile],
				'companyUserId': [data.companyUserId],
				'role': [role, [Validators.required]]
			});
			this.showEditUserPopup = true;
			this.showCreateUserPopup = false;

			console.log("createFormofUserProfile:", this.userCreateForm);
		}


	}

	setRoles() {
		this.roleTypes = [{ name: 'Company Admin', value: 1 }, { name: 'Company User', value: 2 }, { name: 'Client', value: 3 }];
	}

	setRolesAgainForEdit() {
		this.roleTypes = [{ name: 'Company Admin', value: 1 }, { name: 'Company User', value: 2 }];
	}

	setRolesForOwnerForEdit() {
		this.roleTypes = [{ name: 'Company Owner', value: 1 }];
	}


	getUserprofile() {
		this.isLoading = true;
		this.restService.hitApi('user/profile', { 'userId': this.userIdAssign }, 'get', '', true)
			.subscribe((result) => {
				this.isLoading = false;
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					console.log("userInfo result", responseData.result);

					//this.createForm(responseData.result);
					this.userInfo = responseData.result;
					this.userCreateForm.get('companyUserId').setValue(this.userInfo.companyUserId)
					console.log("this.userInfo.userId:", this.userInfo);
					console.log("this.userData.ownerId: ", this.userData.ownerId);
					if (this.userInfo.isCompanyOwner) {
						this.isOwner = true;
					}

					this.getAssociatedProjects();
					this.getAvailableProjects();
				} else {
					this.fetchingData = false;
				}
			}, (err) => {
				this.isLoading = false;
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	closeSureModal() {
		console.log("this.selectedProject.id", this.selectedProject.id)
		
		this.allProjects.forEach(element => {
			
			if (this.selectedProject.id == element.id) {
				element.checked = true
				console.log("element.id", element.id)
			}
		});

		console.log("all projects", this.allProjects)

		$("#make-sure").modal('hide');
	}

	closeModal() {
		console.log("this.userInfo.roleType", this.userInfo.role);
		this.userCreateForm.reset();
		this.userCreateForm.get("companyUserId").setValue(this.userInfo.companyUserId);
		this.userCreateForm.get("roleType").setValue(this.userInfo.roleType);

		if (this.userInfo?.role == 2) {
			this.allProjects.forEach(element => {
				//element.checked = false;
				if (element.userRoleId) {
					//console.log("i am in loop", element.userRoleId);
				} else {
					element.checked = false;
				}
			});
		}

		this.showEditUserPopup = false;
		this.showCreateUserPopup = false;

		//searchProject() {

		if ($('#project_search_name').val()) {
			$('#project_search_name').val('')
			this.searchProject()
		}
	}

	closeModalOfRole() {
		this.getUserprofile();
	}


	// getUserprofile() {
	// 	this.restService.hitApi('user/profile', { 'userId': this.userid }, 'get', '', true)
	// 		.subscribe((result) => {
	// 			let responseData: any = result;
	// 			if (responseData.statusCode == 200) {
	// 				this.createForm(responseData.result);
	// 				this.userInfo = responseData.result;
	// 				this.getAssociatedProjects();
	// 				this.getAvailableProjects();
	// 			} else {
	// 				this.fetchingData = false;
	// 			}
	// 		}, (err) => {
	// 			console.log(err);
	// 			let errorData: any = err;
	// 			// this.toastr.error(errorData.error.message);
	// 		})
	// }

	searchProject() {

		this.searchData = "&q=" + $('#project_search_name').val();
		// if ($('#search_role').val() != "") {
		// 	searchdata += "&p=" + $('#search_role').val();
		// }

		// let userId = this.userInfo.id;
		this.getAssociatedProjects();
		this.getAvailableProjects();
	}

	search(event, searchBy, value) {

		let searchdata: any = "";
		// if (event != null) {
		// 	if (searchBy == 'name') {
		// 		searchdata += "&q=" + event.target.value;
		// 	} else {
		// 		searchdata += "&p=" + event.value;
		// 	}
		// }

		if (event != null) {
			if (searchBy == 'name') {
				if (value) {
					searchdata += "&q=" + value;
				} else {
					if (event.target.value != undefined) {
						searchdata += "&q=" + event.target.value;
					}
				}

			} else {
				if (event.target.value != undefined) {
					searchdata += "&p=" + event.target.value;
				}
			}
		}


		this.restService.hitApi('project/list?userId=' + this.userIdAssign + '&isAvailable=0' + searchdata, {}, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.associatedProjects = responseData.result;
				} else {
					this.fetchingData = false;
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}
	getAssociatedProjects() {
		var url;
		if (this.searchData) {
			url = 'project/list?userId=' + this.userIdAssign + '&isAvailable=0' + this.searchData;
		} else {
			url = 'project/list?userId=' + this.userIdAssign + '&isAvailable=0';
		}
		// let userId = this.userInfo.id;
		this.restService.hitApi(url, {}, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.associatedProjects = responseData.result;
					this.associatedProjects.forEach(element => {
						element.checked = true;

						// if (element.userRoleId == 4) {
						// 	element.roleName = 'Company Admin';
						// } if (element.userRoleId == 1) {
						// 	element.roleName = 'Project Admin';
						// } if (element.userRoleId == 3) {
						// 	element.roleName = 'External';
						// } if (element.userRoleId == 2) {
						// 	element.roleName = 'Users';
						// }


					});
					this.mergeProjects();
				} else {
					this.fetchingData = false;
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})


		// 	let userId = this.userInfo.id;
		// 	this.restService.hitApi('project/list?userId=' + userId + '&isAvailable=0' + searchdata, {}, 'get', '', true)
		// 		.subscribe((result) => {
		// 			let responseData: any = result;
		// 			if (responseData.statusCode == 200) {
		// 				this.associatedProjects = responseData.result;
		// 			} else {
		// 				this.fetchingData = false;
		// 			}
		// 		}, (err) => {
		// 			console.log(err);
		// 			let errorData: any = err;
		// 			// this.toastr.error(errorData.error.message);
		// 		})
		// }

		// getAssociatedProjects() {
		// 	let userId = this.userInfo.id;
		// 	this.restService.hitApi('project/list?userId=' + userId + '&isAvailable=0', {}, 'get', '', false)
		// 		.subscribe((result) => {
		// 			let responseData: any = result;
		// 			if (responseData.statusCode == 200) {
		// 				this.associatedProjects = responseData.result;
		// 			} else {
		// 				this.fetchingData = false;
		// 				// this.toastr.error(responseData.message);
		// 			}
		// 		}, (err) => {
		// 			console.log(err);
		// 			let errorData: any = err;
		// 			// this.toastr.error(errorData.error.message);
		// 		})
		// this.assocProjects = data;

	}

	getAvailableProjects() {
		console.log(this.userInfo);
		var url;
		if (this.searchData) {
			url = 'project/list?userId=' + this.userIdAssign + '&isAvailable=1' + this.searchData;
		} else {
			url = 'project/list?userId=' + this.userIdAssign + '&isAvailable=1';
		}
		// let userId = this.userInfo.id;
		this.restService.hitApi(url, {}, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.projectList = responseData.result;
					this.projectList.forEach(element => {
						element.checked = false;
					});
					this.mergeProjects();
				} else {
					this.fetchingData = false;
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	addProjectToUser(pid) {
		// let userId = this.userInfo.id;
		this.restService.hitApi('companyUser/addProjectUser', { 'userId': this.userIdAssign, 'projectId': pid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getAssociatedProjects();
					this.getAvailableProjects();
				} else {
					this.fetchingData = false;
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})

	}

	removeProjectFromUser(pid) {
		// let userId = this.userInfo.id;
		this.restService.hitApi('companyUser/removeProjectUser', { 'userId': this.userIdAssign, 'projectId': pid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.selectedProject = null;
					this.getAssociatedProjects();
					this.getAvailableProjects();
					this.broadcastService.boradcast('FETCH_PROJECT_LIST');
				} else {
					this.fetchingData = false;
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}


	updateUserRole(pid, roleId) {
		// let userId = this.userInfo.id;
		if (this.userInfo?.role == 2) {
			this.restService.hitApi('companyUser/addProjectUser', { 'userId': this.userIdAssign, 'projectId': pid }, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.restService.hitApi('companyUser/updateProjectUser', { 'userId': this.userIdAssign, 'projectId': pid, 'userRoleId': roleId }, 'post', '', true)
							.subscribe((result) => {
								let responseData: any = result;
								if (responseData.statusCode == 200) {
									this.getAssociatedProjects();
									this.getAvailableProjects();
									this.toastr.success(responseData.message);
								} else {
									this.fetchingData = false;
									this.toastr.error(responseData.message);
								}
							}, (err) => {
								console.log(err);
								let errorData: any = err;
								this.toastr.error(errorData.error.message);
							})
					} else {
						this.fetchingData = false;
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
				})
		} else {
			this.restService.hitApi('companyUser/updateProjectUser', { 'userId': this.userIdAssign, 'projectId': pid, 'userRoleId': roleId }, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.getAssociatedProjects();
						this.getAvailableProjects();
						this.toastr.success(responseData.message);
					} else {
						this.fetchingData = false;
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}

	}


	resendInvitation() {
		this.restService.hitApi('companyUser/resendInvite', { 'companyUserId': this.userInfo.companyUserId }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	updateUserProfile(field, value) {
		let postdata: any;
		let apiname: any;
		if (this.userIdAssign && field == 'is_email_notification') {
			postdata = { 'userId': this.userIdAssign, 'is_email_notification': value };
			apiname = "user/profile"
			if (this.userInfo?.isEmailNotification != value) {
				this.updateProfile(apiname, postdata);
			}

		}

		if (this.userIdAssign && field == 'role') {
			postdata = { 'companyUserId': this.userInfo.companyUserId, 'roleType': value, 'name': this.userInfo.name, 'mobile': this.userInfo.mobile };
			apiname = "companyUser/update"
			if (this.userInfo?.role != value) {
				this.updateProfile(apiname, postdata);
			}

		}

	}

	updateProfile(apiname, postData) {
		this.restService.hitApi(apiname, postData, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.toastr.success("Setting successfully updated");
					$('#role-change-user').modal('hide');
					this.getUserprofile();
					this.getAssociatedProjects();

				} else {
					// this.errorProfileMessage = responseData.message;
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				// this.errorProfileMessage = err.error.message;
				this.toastr.error(err.error.message);
			})
	}

	openEditUser(userData) {
		this.createForm(userData);
		$('#profile-edit-user').modal('show');
	}


	// updateUser(){
	// 	//companyUser/update

	// 	this.restService.hitApi('updateCompanyUserProfile',this.userCreateForm.value,'post','',true)
	// 		.subscribe((result)=>{
	// 			let responseData : any = result;
	// 			if(responseData.statusCode==200){
	// 				$('#profile-edit-user').modal('hide');

	// 				this.getUserprofile();
	// 				this.toastr.success(responseData.message);
	// 			}else{
	// 				this.toastr.error(responseData.message);
	// 			}
	// 		},(err)=>{
	// 			console.log(err);
	// 			let errorData : any = err;
	// 			this.toastr.error(errorData.error.message);
	// 	})
	updateUser() {
		if (this.userCreateForm.invalid) {
			this.userCreateFormSubmit = true;
			return;
		} else {
			this.restService.hitApi('companyUser/update', this.userCreateForm.value, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						$('#profile-edit-user').modal('hide');

						if (this.isMyProfile()) {
							let savedUserName = this.userData?.companyUserName

							if (this.userCreateForm.get('name').value != savedUserName) {
								this.userData.companyUserName = this.userCreateForm.get('name').value
								localStorage.setItem('userData', JSON.stringify(this.userData));
							}
						}

						this.getUserprofile();
						this.toastr.success(responseData.message);
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}

	}

	openDeleteUser(userData, modal) {
		this.selectedForDelete = userData;
		$('#' + modal).modal({ backdrop: 'static', keyboard: false });
	}

	openRoleChangeUser(userData, modal, type) {
		if (this.userInfo?.role != type) {
			this.selectedForRoleChange = type;
			$('#' + modal).modal({ backdrop: 'static', keyboard: false });
		}

	}

	deleteUser(uid) {
		this.restService.hitApi('companyUser/delete', { 'companyUserId': uid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('#delete-user').modal('hide');
					this.router.navigate(['user-management']);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	uploadFile(event) {

		if (!this.isMyProfile()) {
			return
		}

		let file: File = event[0];
		const formData: FormData = new FormData();
		formData.append('companyUserId', this.userInfo.companyUserId);
		formData.append('image', file);


		this.restService.hitApi('companyUser/profileImage', formData, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					// localStorage.setItem('userData', JSON.stringify(responseData.result));
					// this.userInfo = JSON.parse(localStorage.getItem("userData"));

					//this.broadcastService.boradcast("SELETED_PROJECT_DELETED");
					if (this.isMyProfile()) {
						this.broadcastService.boradcast("PROFILE_UPDATED");
					}

					this.getUserprofile();
					this.toastr.success(responseData.message);
				} else {
					// =======
					// 		this.restService.hitApi('companyUser/profileImage', formData, 'post', '', true)
					// 			.subscribe((result) => {
					// 				let responseData: any = result;
					// 				if (responseData.statusCode == 200) {
					// 					localStorage.setItem('userData', JSON.stringify(responseData.result));
					// 					this.userInfo = JSON.parse(localStorage.getItem("userData"));
					// 					console.log(this.userInfo);
					// 				} else {
					// >>>>>>> 4d828de819c12162b69aa69c47d6f8c0a011edfd
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	confirmBeforeRemove(project) {
		this.selectedProject = project
		$("#edit-project").modal('hide');
		$("#make-sure").modal('show');
	}

	roleSelected(role) {
		$('.edit-role-progress-work-st-js').slideUp();

		if (role == 'Company Admin') {
			this.userCreateForm.get('roleType').setValue(1);
		} else if (role == 'Company User') {
			this.userCreateForm.get('roleType').setValue(2);
		} else if (role == "Client") {
			this.userCreateForm.get('roleType').setValue(3);
		}
		this.userCreateForm.get('roleName').setValue(role);

		console.log(this.userCreateForm);
	}

	getRoleType(obj: Data) {
		if (obj) {
			console.log("selectedRole" + obj.name);
			this.userCreateForm.get('roleType').setValue(parseInt(obj.value));
			this.userCreateForm.get('roleName').setValue(parseInt(obj.name));
		}

	}

	get f() {
		return this.userCreateForm.controls;
	}

	isAbleToEditProjectMembership() {
		console.log("isAbleToEditProjectMembership")
		if (this.userData.companyUserId == this.userInfo.companyUserId) {
			console.log("myfrofile")
			if (this.userInfo.role == 1) {
				console.log("myfrofile admin")
				return true;
			} else {
				console.log("myfrofile non admin")
				return false;
			}
		} else {
			if (this.isOwner) {
				return false;
			} else {
				return true;
			}
		}
	}

	isMyProfile() {
		console.log("userData", this.userData);
		if (this.userData.companyUserId == this.userInfo.companyUserId) {
			return true;
		}

		return false;
	}

	keyPress(event: any) {
		const pattern = /[0-9\+\-\ ]/;

		let inputChar = String.fromCharCode(event.charCode);
		if (event.keyCode != 8 && !pattern.test(inputChar)) {
			event.preventDefault();
		}
	}

	updateProjectList(project) {

		if (project.checked == true) {
			project.checked = true;
		} else {
			project.checked = false;
		}
		if (this.associatedProjects.indexOf(project) != -1) {
			var index = this.associatedProjects.indexOf(project);
			this.associatedProjects.splice(index, 1);
		} else {
			if (this.userInfo?.role != 2) {
				this.associatedProjects.push(project);
			}
		}

		if (project.checked) {
			if (this.userInfo?.role != 2) {
				this.addProjectToUser(project.id)
			}
		} else {
			this.removeProjectFromUser(project.id)
		}

		$("#make-sure").modal('hide');
	}

	removeProjectList(project) {

		project.checked = false;
		if (this.associatedProjects.indexOf(project) != -1) {
			var index = this.associatedProjects.indexOf(project);
			this.associatedProjects.splice(index, 1);
		}

		this.removeProjectFromUser(project.id)
	}

	mergeProjects() {
		this.allProjects = [];
		if (this.associatedProjects.length > 0) {
			this.associatedProjects.forEach(element => {
				this.allProjects.push(element);
			});
		}

		if (this.projectList && this.projectList.length > 0) {
			this.projectList.forEach(element => {
				this.allProjects.push(element);
			});
		}

		this.allProjects?.sort(function (a, b) {
			return a.name.localeCompare(b.name);
		})
		this.allProjects?.sort((a, b) => b.checked - a.checked);

	}

	isRoleDisable() {
		if (this.isOwner) {
			if (this.isMyProfile()) {
				return true
			}
			return false
		} else if (this.userCreateForm.get('roleType').value == 1) {
			if (this.isMyProfile()) {
				return true
			}
			return false
		} else if (this.userCreateForm.get('roleType').value == 3) {
			return true
		} else if (this.userCreateForm.get('roleType').value == 2) {
			if (this.isMyProfile()) {
				return true
			}
		}

		return false
	}

	isAdminEnable() {
		if (this.userCreateForm.get('roleType').value == 3 || this.userData.ownerId != undefined && this.userData.ownerId == this.userData.userId) {
			return false
		}

		return true
	}

	backToRetun() {
		this._location.back();
		//this.router.navigate([this.routeEventsService.previousRoutePath.value]);

		// if (localStorage.getItem('returnTo') != '') {
		// 	//console.log("localStorage.getItem('returnTo')" , JSON.parse(localStorage.getItem('returnTo')));
		// 	this.router.navigate([localStorage.getItem('returnTo')]);
		// 	localStorage.setItem('returnTo', "");
		// } else {
		//this.router.navigate(['/user-management']);
		//}
	}
}


