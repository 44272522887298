import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outer-pricing',
  templateUrl: './outer-pricing.component.html',
  styleUrls: ['./outer-pricing.component.scss']
})
export class OuterPricingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

}
