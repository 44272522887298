import { Component, OnInit, ChangeDetectorRef,ChangeDetectionStrategy } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { RestService } from '../services/rest/rest.service';
import { ValidationService } from '../services/config/config.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  contactForm: FormGroup;
  public captchaIsLoaded = false;
	public captchaSuccess = false;
	public captchaIsExpired = false;
	public captchaResponse?: string;
	public readonly siteKey = '6Ldxzq8ZAAAAAHvWrUY7-VfGwbCDAOp3FiyHzeVU';

  constructor(
		private router: Router, 
		private restService: RestService,
		private formBuilder : FormBuilder,
		private toastr : ToastrService,
		private cdr: ChangeDetectorRef
	) {
		//$("html, body").animate({ scrollTop: 0 }, "slow");
		this.contactForm = this.formBuilder.group({
			name: ['', [Validators.required]],
			mobile: [''],
			email: ['', [Validators.required, ValidationService.emailValidator]],
			message: [''],
			recaptcha: ['', Validators.required]
		});
	}

  ngOnInit() {
  }

  onSubmit(token) {
		this.contactForm.get('recaptcha').setValue(token);
	}

	doContact(){
		this.restService.hitApi('users/contactus',this.contactForm.value,'post','',true)
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.contactForm.reset();
				this.handleReset();
				this.toastr.success(responseData.message);
			}else{
				this.toastr.error(responseData.message);
			}
		},(err)=>{
			console.log(err);
			let errorData : any = err;
			this.toastr.error(errorData.error.message);
		})
	}


	handleReset(): void {
		this.captchaSuccess = false;
		this.captchaResponse = undefined;
		this.captchaIsExpired = false;
		this.cdr.detectChanges();
	}
	
	handleSuccess(captchaResponse: string): void {
      this.captchaSuccess = true;
      this.captchaResponse = captchaResponse;
      this.captchaIsExpired = false;
      this.cdr.detectChanges();
	}
	
	handleLoad(): void {
      this.captchaIsLoaded = true;
      this.captchaIsExpired = false;
      this.cdr.detectChanges();
	}
	
	handleExpire(): void {
      this.captchaSuccess = false;
      this.captchaIsExpired = true;
      this.cdr.detectChanges();
	}

}
