import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
// Services
import { RestService } from '../services/rest/rest.service';
import { element } from 'protractor';
declare const $: any;

import * as _moment from 'moment';

const moment = _moment;


@Component({
	selector: 'app-discussions',
	templateUrl: './discussions.component.html',
	styleUrls: ['./discussions.component.scss'],
})

export class DiscussionsComponent implements OnInit {
	discussionAddForm: FormGroup;
	discussionData: any = [];
	updateData: any = [];
	attachmentData: any = [];
	linksData: any = [];
	userData: any;
	projectData: any;
	pid: any;
	userInfo: any;
	usersArr: any[] = [];
	files: any = [];
	imgArr: any = [];
	linksArr: any = [];
	selectAll: boolean = false;
	selectNone: boolean = false;
	throttle = 300;
	scrollDistance = 1;
	scrollUpDistance = 2;

	linkInputArr = new FormArray([new FormControl('')]);
	projectListData: any;
	jqueryRef: any = $;
	listByDatePage = 1;
	updateListPageNumber: number = 1;
	totalUpdateListCount: number;
	discussionListPageNumber: number = 1;
	totalDiscussionListCount: number;
	attachmentListPageNumber: number = 1;
	totalAttachmentListCount: number;
	linkListPageNumber: number = 1;
	totalLinkListCount: number;
	updateDataList: any = [];
	selectedAttachment: any;
	selectedLink: any;
	isLoadMore: boolean;
	isAttachmentDeleteButtonShown: boolean

	isLoading: boolean = false;
	isCreateDialogShow: boolean = false;
	isResetUpdateList: boolean;
	isResetDiscussionList: boolean;
	isResetAttachmentList: boolean;
	isNoUpdateData: boolean = false;
	isNoDiscussionData: boolean = false;
	isNoAttachmentData: boolean = false;
	discussionCount: number = 0;
	attachmentCount: number = 0;

	/*editorConfig: AngularEditorConfig = {
		editable: true,
		  spellcheck: true,
		  height: '150px',
		  minHeight: '0',
		  maxHeight: 'auto',
		  width: 'auto',
		  minWidth: '0',
		  translate: 'yes',
		  enableToolbar: false,
		  showToolbar: true,
		  placeholder: 'Enter message here...',
		  defaultParagraphSeparator: '',
		  defaultFontName: '',
		  defaultFontSize: '1',
		sanitize: true,
		toolbarPosition: 'top',
		toolbarHiddenButtons: [
			[
			  'undo',
			  'redo',
			  'strikeThrough',
			  'subscript',
			  'superscript',
			//   'justifyLeft',
			//   'justifyCenter',
			//   'justifyRight',
			//   'justifyFull',
			  'indent',
			  'outdent',
			//   'insertUnorderedList',
			  //'insertOrderedList',
			  'heading',
			  'fontName'
			],
			[
			  
			  'textColor',
			  'backgroundColor',
			  'customClasses',
			//   'link',
			//   'unlink',
			  'insertImage',
			  'insertVideo',
			  'insertHorizontalRule',
			  'removeFormat',
			  'toggleEditorMode'
			]
		]
		
	};*/

	constructor(
		private formBuilder: FormBuilder,
		private restService: RestService,
		private toastr: ToastrService,
		private route: ActivatedRoute,
		private router: Router) {
		this.userData = JSON.parse(localStorage.getItem("userData"));
		// this.pid = JSON.parse(localStorage.getItem("projectData")).id;
		this.route.params.subscribe(params => {
			this.pid = params['pid'];
			if (this.pid && this.pid != null && this.pid !== undefined) {
				this.isLoading = true;
				this.getProjectDetail();
				this.getCount();
				this.getProjectUpdate();
				//this.getProjectDiscussion();
				//this.getAttachments();
				//this.getLinks();
			}
		});
		if (this.userData) {
			this.getUserprofile();
		}
	}

	ngOnInit() {
		console.log(this.userData);
		if (!localStorage.getItem('userData')) {
			this.router.navigate(['/login']);
		}

		// this.getProjectList();
		this.createForm(null);

		$(document).ready(function () {
			// $('.project-title-head').click(function() {
			// 	$('.project-title-list').toggle("slide");
			// });
			$('.htmlEditor').summernote({
				height: '100px'
			});
		});

		$(document).mouseup(function (e) {
			var container1 = $(".project-title-head");
			var container2 = $(".project-title-list");
			console.log(e.target);
			if (!container1.is(e.target) && container1.has(e.target).length === 0 && !container2.is(e.target) && container2.has(e.target).length === 0) {
				$(".project-title-list").hide('down');
			}
		});



	}

	getUserprofile() {
		this.restService.hitApi('user/profile', { 'userId': this.userData.id }, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.userInfo = responseData.result;
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	createForm(data) {
		if (data === null) {
			this.discussionAddForm = this.formBuilder.group({
				title: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
				comment: ['', [Validators.required]],
				client_display: [''],
				rad1: ['1']
			});
		} else {
			this.discussionAddForm = this.formBuilder.group({
				title: [data.title, [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
				comment: [data.comment],
				client_display: [data.client_display],
				rad1: [data.rad1]
			});
		}
	}

	getProjectDetail() {
		this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.projectData = responseData.result;

					console.log("projectData", this.projectData);
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}
	getCount() {
		this.restService.hitApi('discussion/count', { 'projectId': this.pid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.discussionCount = responseData.result.discussion;
					this.attachmentCount = responseData.result.attachment;
				}

			}, (err) => {
				this.updateData = [];
				let errorData: any = err;
				this.checkIfUpdateDataHasComes();

				// this.toastr.error(errorData.error.message);
			})

	}

	getProjectUpdate() {

		this.restService.hitApi('discussion/listByDate?page=' + this.updateListPageNumber, { 'projectId': this.pid }, 'post', '', true)
			.subscribe((result) => {
				this.isLoading = false;
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					if (this.isResetUpdateList) {
						this.updateData = [];
						this.updateDataList = [];
					}

					var updateArr = responseData.result.data;
					if (updateArr && updateArr.length > 0) {
						updateArr.forEach(element => {
							this.updateData.push(element);
						});
						updateArr.forEach(element => {
							element.forEach(element2 => {
								this.updateDataList.push(element2);
							});

						});
						console.log("updateDataList:", this.updateDataList)
						console.log("updateDataList length:", this.updateDataList.length)
					}
					this.isLoadMore = false;
					this.totalUpdateListCount = responseData.result.total;
					console.log("totalUpdateListCount:", this.totalUpdateListCount)
					// this.updateData = responseData.result.data;
					// console.log("this.updateData", this.updateData);

				} else {
					this.isLoading = false;
					this.updateData = [];
					// this.toastr.error(responseData.message);
				}
				this.checkIfUpdateDataHasComes();

			}, (err) => {
				this.isLoading = false;
				this.updateData = [];
				let errorData: any = err;
				this.checkIfUpdateDataHasComes();

				// this.toastr.error(errorData.error.message);
			})

	}

	getProjectDiscussion() {

		this.restService.hitApi('discussion/list?page=' + this.discussionListPageNumber, { 'projectId': this.pid }, 'post', '', true)
			.subscribe((result) => {
				this.isLoading = false;
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					if (this.isResetDiscussionList) {
						this.discussionData = [];
					}
					var desArr = responseData.result.data;
					if (desArr && desArr.length > 0) {
						desArr.forEach(element => {
							this.discussionData.push(element);
						});
					}
					// if (!this.discussionData) {
					// 	this.discussionData = responseData.result.data;

					// } else {
					// 	this.discussionData.(responseData.result.data);
					// }
					this.isLoadMore = false;
					this.totalDiscussionListCount = responseData.result.total;
					console.log("discussion" + this.discussionListPageNumber, this.discussionData);
				} else {
					this.discussionData = [];
				}
				this.checkIfDiscussionDataHasComes();
			}, (err) => {
				this.isLoading = false;
				this.discussionData = [];
				let errorData: any = err;
				this.checkIfDiscussionDataHasComes();
				// this.toastr.error(errorData.error.message);
			})



		// this.getAttachments();
		// this.getLinks();

	}

	getAttachments() {

		this.restService.hitApi('discussion/attachments/list?page=' + this.attachmentListPageNumber, { 'projectId': this.pid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					if (this.isResetAttachmentList) {
						this.attachmentData = [];
					}
					var attachmentArr = responseData.result.data;
					if (attachmentArr && attachmentArr.length > 0) {
						attachmentArr.forEach(element => {
							this.attachmentData.push(element);
						});
						this.attachmentData.forEach(element => {
							if (this.projectData?.userRoleId != 1 && element?.userId == this.userData?.id) {
								element.isDeleteButtonShown = this.checkIsDeleteButtonShown(element.createdAt);

							} else {
								element.isDeleteButtonShown = true;
							}

						});
					}

					this.isLoadMore = false;
					this.totalAttachmentListCount = responseData.result.total;
				} else {
					this.attachmentData = [];
				}
				this.checkIAttachmentDataHasComes();
			}, (err) => {
				this.attachmentData = [];
				let errorData: any = err;
				this.checkIAttachmentDataHasComes();
				// this.toastr.error(errorData.error.message);
			})


	}

	checkIfUpdateDataHasComes() {
		if (this.updateData.length == 0) {
			this.isNoUpdateData = true;
		} else {
			this.isNoUpdateData = false;
		}
	}

	checkIfDiscussionDataHasComes() {
		if (this.discussionData.length == 0) {
			this.isNoDiscussionData = true;
		} else {
			this.isNoDiscussionData = false;
		}
	}

	checkIAttachmentDataHasComes() {
		if (this.attachmentData.length == 0) {
			this.isNoAttachmentData = true;
		} else {
			this.isNoAttachmentData = false;
		}
	}


	getLinks() {
		this.restService.hitApi('discussion/references/list?page=' + this.linkListPageNumber, { 'projectId': this.pid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					var linkArr = responseData.result.list.data;
					if (linkArr.length > 0) {
						linkArr.forEach(element => {
							this.linksData.push(element);
						});
						this.linksData.forEach(element => {
							if (element.projectThreadPostLinks.length > 0) {
								element.projectThreadPostLinks.forEach(link => {
									link.isDeleteButtonShown = this.checkIsDeleteButtonShown(link.createdAt);
								});
							}

						});
					}
					this.isLoadMore = false;
					this.totalLinkListCount = responseData.result.list.total;
				} else {
					this.linksData = [];
				}
			}, (err) => {
				this.linksData = [];
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}



	addDiscussion() {
		let that = this;

		// $('.multiple-users-email-checkboxes').find('input').each(function () {
		// 	if ($(this).prop("checked")) {
		// 		that.usersArr.push($(this).val());
		// 	}
		// });

		// $('.link-content').find('input').each(function () {
		// 	if ($(this).val()) {
		// 		that.linksArr.push($(this).val());
		// 	}
		// });

		//createThread
		console.log(this.discussionAddForm.value);
		//this.discussionAddForm.value.comment = $('.htmlEditor').val();
		this.discussionAddForm.value.users = this.usersArr;
		this.discussionAddForm.value.projectId = this.pid;
		this.discussionAddForm.value.client_display = this.discussionAddForm.value.client_display ? 0 : 1;
		this.discussionAddForm.value.upload_files_create = this.imgArr;
		this.discussionAddForm.value.references = this.linkInputArr.value.filter(el => el != "");
		console.log(this.discussionAddForm.value);
		if (this.discussionAddForm.invalid) {
			return false;
		} else {
			this.restService.hitApi('discussion/create', this.discussionAddForm.value, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						$("#create_discuss_pop_up").modal('hide');
						this.files = [];
						this.toastr.success(responseData.message);
						this.refreshAllList();
						this.getProjectUpdate();
						this.getProjectDiscussion();
						this.getAttachments();
						this.getLinks();
						$('.htmlEditor').summernote('code', '');
						this.closeModel()

						this.selectAll = false;
						this.selectNone = false;
						this.usersArr = [];
						this.emailRadioChange(false);

					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);

				})

		}

	}


	//Image upload
	uploadFile(event) {
		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];
			const formData: FormData = new FormData();
			formData.append('projectId', this.pid);
			formData.append('imageFolder', 'thread_image/');
			formData.append('image', file);

			// const element = event[index];
			this.files.push(file.name);

			this.restService.hitApi('discussion/attachments', formData, 'post', '', true, false)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.imgArr.push(responseData.result);

					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}

	}


	selectUser() {
		var checkboxes = $(".multiple-users-email-checkboxes input").length;
		var checkedCheckboxes = $(".multiple-users-email-checkboxes input:checked").length;

		if (checkboxes == checkedCheckboxes)
			$('#checkAll').prop('checked', true);
		else {
			$('#checkAll').prop('checked', false);
		}

		if (checkedCheckboxes > 0)
			$('#uncheckAll').prop('checked', false);
		else
			$('#uncheckAll').prop('checked', true);
	}

	checkUncheckAll(ele) {
		var checkboxes = $(".multiple-users-email-checkboxes input");
		if (ele) {
			for (var i = 0; i < checkboxes.length; i++) {
				// if (checkboxes[i].type == 'checkbox') {
				checkboxes[i].checked = true;
				// }
			}
		} else {
			for (var i = 0; i < checkboxes.length; i++) {
				console.log(i)
				// if (checkboxes[i].type == 'checkbox') {
				checkboxes[i].checked = false;
				// }
			}
		}
	}

	public addMoreField() {
		this.linkInputArr.push(new FormControl(''));
		console.log('linkInputArr', this.linkInputArr);

		// console.log(num);
		// if (num > 0 && $.inArray(num + 1, this.linkInputArr) < 0) {
		// 	this.linkInputArr.push("");
		// }
		// console.log(this.linkInputArr);
	}

	removeField(index) {
		this.linkInputArr.removeAt(index);
	}

	removeAttachment(index) {
		this.files.splice(index, 1)
	}



	deleteAttachment(aid) {
		this.restService.hitApi('discussion/attachments/delete', { "projectThreadPostImageId": aid, "projectId": this.pid }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('#delete-attachment').modal('hide');
					this.toastr.success(responseData.message);
					this.attachmentListPageNumber = 1;
					this.attachmentData = [];
					this.getAttachments();
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	deleteLink(lid) {
		this.restService.hitApi('discussion/references/delete', { "projectThreadPostLinkId": lid, "projectId": this.pid }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('#delete-link').modal('hide');
					this.toastr.success(responseData.message);
					this.linkListPageNumber = 1;
					this.linksData = [];
					this.getLinks();
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	emailRadioChange(isSelect: boolean) {
		this.usersArr = [];
		if (this.projectData.projectUsersProject) {
			this.projectData.projectUsersProject.forEach(element => {
				element.checked = isSelect;
				if (isSelect) {
					this.usersArr.push(element?.usersUser?.id);
				}
			});
		}
	}
	onRadioButtonChange() {
		this.usersArr = [];
		this.selectAll = false;
		this.selectNone = false;
		if (this.projectData.projectUsersProject) {
			this.projectData.projectUsersProject.forEach(element => {
				element.checked = false;

			});
		}
	}

	userSelect(event, user) {
		user.checked = event.checked;
		var allChecked;
		if (this.projectData.projectUsersProject) {
			console.log(this.projectData.projectUsersProject);
			this.projectData.projectUsersProject.forEach(element => {
				console.log("element -> ", element);
				allChecked = element.checked ? element.checked : false;

			});
			console.log("allChecked -> ", allChecked);
			if (allChecked) {
				this.selectAll = true;
				this.selectNone = false;
			} else {
				this.selectAll = false;
				this.selectNone = false;
			}
		}
		this.usersArr.push(user?.usersUser?.id);
		console.log("usersArr -> ", this.usersArr);
	}
	getExtensionOfFile(fileName) {
		if (fileName) {
			var str = fileName.split(".");
			return str[str.length - 1];
		}
	}
	getDisplayNameOfFile(fileName) {

		if (fileName) {
			var extension = this.getExtensionOfFile(fileName);
			if (extension) {
				var str = fileName.split(extension);
				return str[0];
			}


		}
	}
	getExtensionOfFileType(fileName) {
		if (fileName) {
			var str = fileName.split(".");
			if (str[str.length - 1] == 'jpg' || str[str.length - 1] == 'png' || str[str.length - 1] == 'jpeg' || str[str.length - 1] == 'JPG' || str[str.length - 1] == 'PNG') {
				return 'image'
			} else {
				return 'file'
			}

		}
	}

	addLink(event) {
		console.log('value', event);
	}

	showMoreUpdates() {
		this.isLoadMore = true;
		if (this.updateData.length < this.totalUpdateListCount) {
			this.updateListPageNumber++;
			this.getProjectUpdate();
		}

	}


	showMoreDiscussion() {
		this.isLoadMore = true;
		if (this.discussionData.length < this.totalDiscussionListCount) {
			this.discussionListPageNumber++;
			this.getProjectDiscussion();
		}

	}
	showMoreAttachments() {
		this.isLoadMore = true;
		if (this.attachmentData.length < this.totalAttachmentListCount) {
			this.attachmentListPageNumber++;
			this.getAttachments();
		}
	}
	showMoreLinks() {
		this.isLoadMore = true;
		if (this.linksData.length < this.totalLinkListCount) {
			this.linkListPageNumber++;
			this.getLinks();
		}
	}

	onUp(ev) {
		// console.log("scrolled up!", ev);
		// const start = this.sum;
		// this.sum += 20;
		// this.prependItems(start, this.sum);

		// this.direction = "up";
	}
	resetValues() {
		this.selectAll = false;
		this.selectNone = false;
		this.usersArr = [];
		this.emailRadioChange(false);
	}

	refreshAllList() {
		this.updateData = [];
		this.updateDataList = [];
		this.discussionData = [];
		this.attachmentData = [];
		this.linksData = [];
		this.updateListPageNumber = 1;
		this.discussionListPageNumber = 1;
		this.attachmentListPageNumber = 1;
		this.linkListPageNumber = 1;
	}

	checkIsDeleteButtonShown(date) {
		var createdDate = new Date(date);
		createdDate.setHours(createdDate.getHours() + 5);
		createdDate.setMinutes(createdDate.getMinutes() + 30);

		var diff = new Date().getTime() - createdDate.getTime();
		console.log("diff", diff);

		var seconds = Math.floor((diff) / 1000);
		var minutes = Math.floor(seconds / 60);
		var hours = Math.floor(minutes / 60);
		var days = Math.floor(hours / 24);

		hours = hours - (days * 24);
		minutes = minutes - (days * 24 * 60) - (hours * 60);
		seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

		console.log("days", days);
		console.log("hours", hours);
		console.log("minutes", minutes);
		console.log("seconds", seconds);

		if (days > 0 || hours > 0 || minutes >= 15) {
			return false;
		} else {
			return true;

		}
	}

	download(url, fileName) {
		console.log("download called");
		this.restService.downloadFile(url, fileName);
	}

	closeModel() {
		this.discussionAddForm.reset();
		this.isCreateDialogShow = false;
	}

	callProjectUpdateApi() {
		this.isResetUpdateList = true;
		// this.updateData = [];
		// this.updateDataList = [];
		this.updateListPageNumber = 1;
		this.getProjectUpdate();
	}
	callProjectDiscussionApi() {
		this.isResetDiscussionList = true;
		this.discussionListPageNumber = 1;
		this.getProjectDiscussion();

	}
	callProjectAttachmentApi() {
		this.isResetAttachmentList = true;
		this.attachmentListPageNumber = 1;
		this.getAttachments();

	}





	// getProjectList() {
	// 	this.restService.hitApi('discussion/projects',{},'get','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.projectListData = responseData.result.data;
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }

	// changeProject(pid){
	// 	localStorage.setItem('projectSelected',pid)
	// 	$('.project-title-list').toggle("slide");
	// 	this.router.navigateByUrl('/project/'+pid+'/discussions');
	// }
}









