import { RouteEventsService } from './services/route-events.service';
import { RouteInterceptorService } from './services/route-interceptor.service';
import { InvalidJsonCharacterException } from '@angular-devkit/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { RecaptchaModule } from "ng-recaptcha";
import { NgxMaskModule, IConfig } from 'ngx-mask'


// import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import { DpDatePickerModule } from 'ng2-date-picker';
// import { NgDatepickerModule } from 'ng2-datepicker';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { MyDatePickerModule } from 'mydatepicker';
import { TimeAgoPipe } from 'time-ago-pipe';

import { NgxCaptchaModule } from 'ngx-captcha';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { NgxPayPalModule } from 'ngx-paypal';
import { NgxPaginationModule } from 'ngx-pagination';


// import {TableModule} from 'primeng/table';



import { ToastrModule } from 'ngx-toastr';
import { NgpSortModule } from "ngp-sort-pipe";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DragulaModule } from 'ng2-dragula';
import { Ng2PageScrollModule } from 'ng2-page-scroll';
import { HighchartsChartComponent } from 'highcharts-angular';
import { GoogleChartsModule } from 'angular-google-charts';

//App Modules / Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


//Services
import { RestService } from './services/rest/rest.service';
import { PreservedSearchStateService } from './services/search/preserved-search-state.service';
import { DateConverterService } from './services/date-formatter/date-converter.service';
import { RemoveHTMLPipe } from './pipes/removehtml.pipe';
import { specialPipe } from './pipes/removespecial.pipe';
import { MomentPipe } from './pipes/MomentPipe';
import { JsonFilterByPipe } from './pipes/jsonFilterPipe'

//Components
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { LoginTwoComponent } from './login/login-two.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectViewComponent } from './project-view/project-view.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DiscussionBoardComponent } from './discussion-board/discussion-board.component';
import { DiscussionsComponent } from './discussions/discussions.component';
import { DiscussionComponent } from './discussion/discussion.component';
import { PricingComponent } from './pricing/pricing.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReleasesComponent } from './releases/releases.component';
import { SprintsComponent } from './sprints/sprints.component';
import { ReleaseComponent } from './release/release.component';
import { SprintComponent } from './sprint/sprint.component';
import { UserstoriesComponent } from './userstories/userstories.component';
import { UserstoryComponent } from './userstory/userstory.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { UpdateCreditCardComponent } from './credit-card/credit-card.component';
import { InvoiceSettingComponent } from './invoice-setting/invoice-setting.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { TasksComponent } from './tasks/tasks.component';
import { TaskComponent } from './task/task.component';
import { SettingsComponent } from './settings/settings.component';
import { KanbanBoardComponent } from './kanban-board/kanban-board.component';
import { SprintBoardComponent } from './sprint-board/sprint-board.component';
import { BacklogComponent } from './backlog/backlog.component';
import { DefectsComponent } from './defects/defects.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { DefectComponent } from './defect/defect.component';
import { TermsComponent } from './terms/terms.component';
import { TestplansComponent } from './testplans/testplans.component';
import { TestplanComponent } from './testplan/testplan.component';
import { TestcasesComponent } from './testcases/testcases.component';
import { TestcaseComponent } from './testcase/testcase.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { PaymentComponent } from './payment/payment.component';
import { SubscriptionFailedComponent } from './subscription-failed/subscription-failed.component';
import { SearchComponent } from './search/search.component';
import { MatSelectModule } from '@angular/material/select';
import { SelectDropdownComponent } from './ui-components/select-dropdown/select-dropdown.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogOverviewExampleDialog } from './user-management/dialog-overview-example-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { InputComponent } from './ui-components/input/input.component';
import { TextareaComponent } from './ui-components/textarea/textarea.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule, HasTabIndexCtor } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePickerComponent } from './ui-components/date-picker/date-picker.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DragDropDirective } from './directives/drag-drop.directive';


import { PrimaryButtonComponent } from './ui-components/primary-button/primary-button.component';
import { PaginatorComponent } from './ui-components/paginator/paginator.component';
import { FocusDirective } from './directives/focus.directive';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { YYY_mm_dd_DATE_FORMATS } from './directives/date-formats';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SerachFilterComponent } from './serach-filter/serach-filter.component';
import { DashboardCompanyAdminComponent } from './dashboard-company-admin/dashboard-company-admin.component';
import { DashboardCompanyUserComponent } from './dashboard-company-user/dashboard-company-user.component';
import { DashboardClientComponent } from './dashboard-client/dashboard-client.component';
import { CompanyComponent } from './company/company.component';
import { SearchPipe } from './pipes/search-pipe.pipe'
import { DatePipe } from '@angular/common';
import { BroadcastService } from './services/broadcast/broadcast.service';
import { DontAccessIssueComponent } from './dont-access-issue/dont-access-issue.component';
import { CommonErrorComponent } from './common-error/common-error.component';
import { AddNewDiscussionComponent } from './add-new-discussion/add-new-discussion.component';
import { DiscussionThreadComponent } from './discussion-thread/discussion-thread.component';
import { TestPriceCalculatorComponent } from './test-price-calculator/test-price-calculator.component';
import { TrakkLoaderComponent } from './trakk-loader/trakk-loader.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SearchNotFoundComponent } from './search-not-found/search-not-found.component';
import { PriceSliderTestComponent } from './price-slider-test/price-slider-test.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PlanExpireComponent } from './plan-expire/plan-expire.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ContactUsHomeComponent } from './contact-us-home/contact-us-home.component';
import { OuterPricingComponent } from './outer-pricing/outer-pricing.component';
import { CompanySelectionComponent } from './company-selection/company-selection.component';
import { DateAgoExamplePipe } from './pipes/date-ago-example.pipe';
import { ProjectCompanySwitchComponent } from './project-company-switch/project-company-switch.component';

import { TimesheetComponent } from './timesheet/timesheet.component';

const maskConfig: Partial<IConfig> = {
  validation: true,
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    LandingComponent,
    PricingComponent,
    SubscriptionComponent,
    ResetPasswordComponent,
    TermsComponent,
    ContactUsComponent,
    UnderConstructionComponent,

    UserManagementComponent,
    DashboardComponent,
    UserProfileComponent,
    ProjectListComponent,
    ProjectViewComponent,
    ChangePasswordComponent,
    DiscussionBoardComponent,
    DiscussionsComponent,
    DiscussionComponent,
    ReleasesComponent,
    ReleaseComponent,
    SprintsComponent,
    SprintComponent,
    UserstoriesComponent,
    UserstoryComponent,
    TasksComponent,
    TaskComponent,
    MyaccountComponent,
    UpdateCreditCardComponent,
    InvoiceSettingComponent,
    InvoicesComponent,
    SettingsComponent,
    BacklogComponent,
    KanbanBoardComponent,
    SprintBoardComponent,
    DefectsComponent,
    DefectComponent,
    TestplansComponent,
    TestplanComponent,
    TestcasesComponent,
    TestcaseComponent,
    PaymentComponent,
    SubscriptionFailedComponent,
    SearchComponent,
    TimeAgoPipe,
    RemoveHTMLPipe,
    specialPipe,
    MomentPipe,
    HighchartsChartComponent,
    JsonFilterByPipe,
    SelectDropdownComponent,
    DialogOverviewExampleDialog,
    InputComponent,
    PrimaryButtonComponent,
    PaginatorComponent,
    FocusDirective,
    TextareaComponent,
    DatePickerComponent,
    SerachFilterComponent,
    DashboardCompanyAdminComponent,
    DashboardCompanyUserComponent,
    DashboardClientComponent,
    CompanyComponent,
    SearchPipe,
    DontAccessIssueComponent,
    CommonErrorComponent,
    AddNewDiscussionComponent,
    DiscussionThreadComponent,
    TestPriceCalculatorComponent,
    TrakkLoaderComponent,
    PageNotFoundComponent,
    SearchNotFoundComponent,
    PriceSliderTestComponent,
    PrivacyPolicyComponent,
    PlanExpireComponent,
    AboutUsComponent,
    ContactFormComponent,
    HeaderComponent,
    FooterComponent,
    ContactUsHomeComponent,
    OuterPricingComponent,
    CompanySelectionComponent,
    DateAgoExamplePipe,
    LoginTwoComponent,
    ProjectCompanySwitchComponent,
    DragDropDirective,
    TimesheetComponent
  ],
  imports: [
    HttpClientModule,
    AngularEditorModule,
    Ng4LoadingSpinnerModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgpSortModule,
    AngularDateTimePickerModule,
    MyDatePickerModule,
    // RichTextEditorAllModule,
    Ng2PageScrollModule,
    NgxCaptchaModule,
    RecaptchaModule,
    // NgxPayPalModule,
    NgxPaginationModule,
    // TableModule,
    GoogleChartsModule,
    MatSelectModule,
    MatDialogModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatTableModule,
    MatTabsModule,
    MatRippleModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatMenuModule,
    MatRadioModule,
    MatExpansionModule,
    DragDropModule,
    InfiniteScrollModule,
    DragulaModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgxMaskModule.forRoot(maskConfig),

    // InfiniteScrollModule,
  ],

  providers: [
    RestService,
    PreservedSearchStateService,
    DateConverterService,
    DatePipe,
    BroadcastService,
    RouteEventsService,
    RouteInterceptorService,
    TrakkLoaderComponent,
    { provide: MAT_DATE_FORMATS, useValue: YYY_mm_dd_DATE_FORMATS }
  ],
  bootstrap: [AppComponent]

})
export class AppModule { }

platformBrowserDynamic().bootstrapModule(AppModule);

