import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { ValidationService } from '../services/config/config.service';

@Component({
	selector: 'app-invoice-setting',
	templateUrl: './invoice-setting.component.html',
	styleUrls: ['./invoice-setting.component.scss'],
})

export class InvoiceSettingComponent implements OnInit {
	
	userData : any = JSON.parse(localStorage.getItem("userData"));
	invoiceForm : FormGroup;
	constructor(
		public restService : RestService, 
		private toastr: ToastrService,
		private router : Router,
		private route : ActivatedRoute,
		private formBuilder : FormBuilder
		) { 
			this.createForm(null);
			this.getUserDetails();
			
		}
	
	ngOnInit() {
		
	}

	getUserDetails(){
		this.restService.hitApi('user/getUserDetail',{},'get','',false)
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.createForm(responseData.result);
			}else{
				this.toastr.error(responseData.message);
			}
			
		},(err)=>{
			let errorData : any = err;
			this.toastr.error(errorData.err.message);
		})
	}

	createForm(data){
		this.invoiceForm = this.formBuilder.group({
			'invoice_email':[data?data.invoiceEmail:'',[Validators.required,  ValidationService.emailValidator]],
			'custom_note':[data?data.customNote:'']
		})
	}

	changeInvoiceSetting(){
		this.restService.hitApi('user/updateUserDetail',this.invoiceForm.value,'post')
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.getUserDetails();
				this.toastr.success(responseData.message);
				setTimeout(() => {
					this.router.navigate(['myaccount']);
				}, 50);
				
			}else{
				this.toastr.error(responseData.message);
			}
			
		},(err)=>{
			let errorData : any = err;
			this.toastr.error(errorData.err.message);
		})
	}

	
	
}


