import { filter } from 'rxjs/operators';
import { Component, ViewChild } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { RestService } from './services/rest/rest.service';
import { ToastrService } from 'ngx-toastr';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Location } from '@angular/common';
import { Data } from './ui-components/select-dropdown/Data';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { BroadcastService } from './services/broadcast/broadcast.service';
import { RouteEventsService } from './services/route-events.service';
import { RouteInterceptorService } from './services/route-interceptor.service';
import { PreservedSearchStateService } from './services/search/preserved-search-state.service';




declare const $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;

  activityForm: FormGroup;
  active: string;
  companiesData: any = {};
  companyName: any;
  companyId: any;
  userData: any;
  selectedProjectId: any = "";
  addEditActivity: boolean = false;
  closeActivityListData: any;
  todayActivityListData: any;
  pendingActivityListData: any;
  projectListData: any;
  projectData: any;
  jqueryRef: any = $;
  watchesUserList: any;
  resourceId: any;
  entity: any;
  userInfo: any;
  placeHolderImgUrl: string = "../assets/images/new-user-avtar.svg";
  activeItem: string;
  projectAddForm: FormGroup;
  projectAddFormSubmit: boolean = false;
  isCreateProjectDialogShow: boolean = false;
  typeList = [{ name: 'Agile', value: "1" }, { name: 'Kanban', value: "2" }];
  filter: number = 1;
  showLoader: boolean = false;
  upgradePlanAlert: any;
  selectedCompanyId: any;
  localObj: any;
  alreadyRegister: any = null;

  isEntityIsCalling = false
  isPlanExpire = false;

  constructor(
    private restService: RestService,
    private router: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private location: Location, private broadcastService: BroadcastService,
    private spinnerService: Ng4LoadingSpinnerService,
    private routeEventsService: RouteEventsService,
    private routeInterceptorService: RouteInterceptorService,
    private preserveSearch: PreservedSearchStateService) {


    this.selectedProjectId = localStorage.getItem("projectSelected");
    console.log("SelectedProjectId call", this.selectedProjectId);

    console.log('router.events', router.events);
    router.events.forEach((event) => {

      if (event instanceof RoutesRecognized) {
        if (this.selectedProjectId != null && this.selectedProjectId != 'null' && this.selectedProjectId != '') {
          localStorage.setItem('projectSelected', this.selectedProjectId);
        }

        this.routeChanged(event.url);
        if (event.url.includes("email")) {
          var splitArr = event.url.split("email=");
          if (splitArr.length > 1) {
            if (this.alreadyRegister != null) {
              this.routeChanged(event.url);
            } else {
              this.checkUserRegister(splitArr[1], event.url);

            }

          } else {
            this.routeChanged(event.url);
          }
        } else {
          this.routeChanged(event.url);
        }


        // else {
        //   localStorage.setItem('projectSelected', null);
        // }
        this.routeChanged(event.url);
      }

    });
    this.broadcastService.subscribe("FETCH_PROJECT_LIST", (pid) => {
      this.spinnerService.show();
      this.getProjectList();
    });

    this.broadcastService.subscribe("SWITCH_PROJECT", (pid) => {
      this.selectedProjectId = pid;
      this.getProjectDetail();
      this.getProjectList();
      this.localObj = this;
    });

    this.broadcastService.subscribe("SWITCH_COMPANY", (id) => {
      this.selectedCompanyId = id;
      console.log("SWITCH_COMPANY this.selectedCompanyId ", this.selectedCompanyId)
      this.localObj = this
    });

    this.broadcastService.subscribe("SELETED_PROJECT_DELETED", (pid) => {
      // alert("SELETED_PROJECT_DELETED");
      this.projectData = null;
      if (this.selectedProjectId == pid) {
        this.selectedProjectId = null;
        localStorage.removeItem("projectSelected");
      }

      this.userData.projectId = null;
      localStorage.setItem('userData', JSON.stringify(this.userData));

      console.log("JSON.stringify(this.userData), ", JSON.stringify(this.userData))
      //alert("projectSelected" + this.selectedProjectId);
    });

    this.broadcastService.subscribe("PROFILE_UPDATED", () => {
      this.getUserprofile();
    });

    this.broadcastService.subscribe("showLoading", (pid) => {
      this.showLoader = true;
    });

    this.broadcastService.subscribe("hideLoading", (pid) => {
      this.showLoader = false;
    });

    this.broadcastService.subscribe("FATCH_USER_DATA_GO_TO_DASHBOARD", (data) => {
      this.getUserProfileAndGoToDashboard(data);
    });
    this.broadcastService.subscribe("SET_SELECTED_PROJECT_ID_TO_NULL", () => {
      this.selectedProjectId = null;
    });

    this.broadcastService.subscribe("SET_USER_DATA", () => {
      if (localStorage.getItem('userData')) {
        this.userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : '';
        console.log("userData after if", JSON.parse(localStorage.getItem('userData')));
      }
    });

  }

  ngOnInit() {
    this.resetHistories(null);

    this.localObj = this;
    var that = this.localObj;

    document.addEventListener("visibilitychange", this.checkPageActive.bind(this, document))

    // document.addEventListener("visibilitychange", function() {
    //   if (document.hidden) {
    //     //do whatever you want
    //     console.log("you Hidden");
    //   }
    //   else {
    //     //do whatever you want
    //     console.log("you SHOWN");
    //    if (localStorage.getItem('userData')) {
    //     let userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : '';
    //     let newSelectedProjectId = userData.projectId
    //     let newSelectedCompanyId = userData.companyId
    //     console.log("that.selectedProjectId" , that.selectedProjectId)
    //     console.log("newSelectedProjectId" , newSelectedProjectId)
    //     console.log("that.selectedCompanyId" , that.selectedCompanyId)
    //     console.log("newSelectedCompanyId" , newSelectedCompanyId)

    //     if (that.selectedCompanyId && newSelectedCompanyId && that.selectedCompanyId != newSelectedCompanyId) {
    //       that.restService.setCompany(newSelectedCompanyId, true)
    //     } else if (that.selectedProjectId && newSelectedProjectId && that.selectedProjectId != newSelectedProjectId) {
    //       console.log("both are not matched")

    //       if (that.router.url.includes('/release')) {
    //         that.router.navigate(['/releases'])
    //       } else if (that.router.url.includes('/sprint')) {
    //         that.router.navigate(['/sprints'])
    //       } else if (that.router.url.includes('/userstory')) {
    //         that.router.navigate(['/userstories'])
    //       } else if (that.router.url.includes('/task')) {
    //         that.router.navigate(['/tasks'])
    //       } else if (that.router.url.includes('/defect')) {
    //         that.router.navigate(['/defects'])
    //       } else if (that.router.url.includes('/testplan')) {
    //         that.router.navigate(['/testplans'])
    //       } else if (that.router.url.includes('/testcase')) {
    //         that.router.navigate(['/testcases'])
    //       }

    //       setTimeout(() => {
    //         window.location.reload();
    //       });

    //     }
    //    }
    //   }
    // });

    $(document).ready(function () {

      let that = this;

      $('.htmlEditor').summernote({
        height: '150px',
        focus: true,
        disableDragAndDrop: true,
        dialogsInBody: true,
        toolbar: [
          // [groupName, [list of button]]
          ['style', ['bold', 'italic', 'underline']],
          ['para', ['ul', 'ol', 'paragraph']]
        ],
        callbacks: {
          onPaste: function (e) {
            var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
            e.preventDefault();
            document.execCommand('insertText', false, bufferText);
          }
        }
      });

      $('#summernote').summernote({
        dialogsInBody: true
      });

    });

    this.createForm(null);

    this.projectAddForm = this.formBuilder.group({
      'name': ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      'description': [''],
      'shortCode': [''],
      'projectId': [''],
      'typeName': [this.typeList[0].name, [Validators.required]],
      'type': [this.typeList[0].value, [Validators.required]],
    });

    setTimeout(() => {
      this.resetForm();
      this.isCreateProjectDialogShow = true;
      this.setHeightOfSummarNote();
    });

    console.log('localStorage before if', localStorage);

    console.log("userData before if", JSON.parse(localStorage.getItem('userData')));
    console.log("userData before if withoutr parse", localStorage.getItem('userData'));

    if (localStorage.getItem('userData')) {
      this.userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : '';
      console.log("userData after if", JSON.parse(localStorage.getItem('userData')));

    }

    console.log(this.userData);
    this.selectedProjectId = this.userData && this.userData.projectId ? this.userData.projectId : '';
    this.selectedCompanyId = this.userData && this.userData.companyId ? this.userData.companyId : '';
    console.log("that.selectedCompanyId this.userData", this.selectedCompanyId)

    let selectedSprintId = this.userData && this.userData.sprintId ? this.userData.sprintId : '';
    if (this.selectedProjectId != null && this.selectedProjectId != 'null' && this.selectedProjectId != '') {
      localStorage.setItem('projectSelected', this.selectedProjectId);
    }

    localStorage.setItem('sprintIdSelected', selectedSprintId);
    console.log('localStorage', localStorage);
    console.log('this.userData', this.userData);

    if (this.userData?.planStatus != true) {
      this.getUserprofile();
      this.getProjectList();
      if (this.selectedProjectId) {
        console.log("project alredy selected");
        this.getProjectDetail();
      }
      this.isPlanExpire = false;
    }

    if (this.selectedProjectId != null && this.selectedProjectId != 'null' && this.selectedProjectId != '') {
      this.broadcastService.boradcast("PROJECT_ID_GET", this.selectedProjectId);
    }

    this.upgradePlanAlert = null;
    this.broadcastService.subscribe("PLAN_EXPIRED", () => {
      this.upgradePlanAlert = "Your plan has expired";
      this.isPlanExpire = true;
    });

    $(document).ready(function () {

      // $('.show-hidden-menu').click(function() {
      //   $('.hidden-menu').slideToggle("fast");
      // });

      // $('.search-show-hidden-menu').click(function() {
      //   $('.search-hidden-menu').slideToggle("fast");
      // });

      // $('.project-show-hidden-menu').click(function() {
      //   $('.project-hidden-menu').slideToggle("fast");
      // });


      var owl = $('.owl-carousel');
      owl.owlCarousel({
        items: 2,
        loop: true,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 2000,
        autoplayHoverPause: true,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          1000: {
            items: 2
          }
        }
      });
      $('.play').on('click', function () {
        owl.trigger('play.owl.autoplay', [1000])
      })
      $('.stop').on('click', function () {
        owl.trigger('stop.owl.autoplay')
      })


    });

    function showMenu() {
      console.log('showMenu');
      $(".leftMostMenu .sidebar-menu").addClass('leftSidebarMenuExpanded');
      $(".leftMostMenu").removeClass('col-md-2-menu');
      $(".leftMostMenu").addClass('col-md-1-menu');
      $(".centralMainDisplay").removeClass('col-md-10-menu');
      $(".centralMainDisplay").addClass('col-md-11-menu');
      $("button.leftMenuToggleButtonShow").removeClass('hidden');
      $("button.leftMenuToggleButtonHide").addClass('hidden');
      $("#menuIsClosed").val(2);
      return false;
    }

    function hideMenu() {
      console.log('hideMenu');
      $(".leftMostMenu .sidebar-menu").removeClass('leftSidebarMenuExpanded');
      $(".leftMostMenu").removeClass('col-md-1-menu');

      $(".leftMostMenu").addClass('no-width');
      $(".centralMainDisplay").removeClass('col-md-11-menu');
      $(".centralMainDisplay").addClass('col-md-10-menu');
      $("button.leftMenuToggleButtonHide").removeClass('hidden');
      $("button.leftMenuToggleButtonShow").addClass('hidden');
      $("#menuIsClosed").val(3);
      setTimeout('$(".leftMostMenu").addClass("col-md-2-menu");', 300);
      setTimeout('$(".leftMostMenu").removeClass("no-width");', 400);
      return false;
    }
    $(document).ready(function (e) {
      var leftMenuToggleButton = $("a.leftMenuToggleButton");
      var rightMenuToggleButton = $("a.rightMenuToggleButton");
      var leftMostMenu = $(".leftMostMenu");
      var centralMainDisplay = $(".centralMainDisplay");
      var rightAsideBar = $(".rightAsideBar");

      $("button.leftMenuToggleButtonShow").click(function (event) {
        event.stopPropagation();
        event.preventDefault();
        return hideMenu();
      });

      $("button.leftMenuToggleButtonHide").click(function (event) {
        event.stopPropagation();
        event.preventDefault();
        return showMenu();
      });

    });


    $(document).mouseup(function (e) {
      if (!$(".common-show-hidden-menu").is(e.target) && $(".common-show-hidden-menu").has(e.target).length === 0 && !$(".common-hidden-menu").is(e.target) && $(".common-hidden-menu").has(e.target).length === 0) {
        $(".common-hidden-menu").slideUp("fast");
      }
      if (!$(".common-show-hidden-menu-horz").is(e.target) && $(".common-show-hidden-menu-horz").has(e.target).length === 0 && !$(".common-hidden-menu-horz").is(e.target) && $(".common-hidden-menu-horz").has(e.target).length === 0) {
        $(".common-hidden-menu-horz").slideUp("fast");
      }
      if (!$(".reporter-work").is(e.target) && $(".reporter-work").has(e.target).length === 0 && !$(".release-work-open").is(e.target) && $(".release-work-open").has(e.target).length === 0) {
        $(".release-work-open").slideUp("fast");
      }

      if (!$(".show-hidden-menu").is(e.target) && $(".show-hidden-menu").has(e.target).length === 0 && !$(".hidden-menu").is(e.target) && $(".hidden-menu").has(e.target).length === 0) {
        $('.hidden-menu').slideUp("fast");
      }

      if (!$(".project-show-hidden-menuu").is(e.target) && $(".project-show-hidden-menu").has(e.target).length === 0 && !$(".project-hidden-menu").is(e.target) && $(".project-hidden-menu").has(e.target).length === 0) {
        $('.project-hidden-menu').slideUp("fast");
      }

      if (!$(".search-show-hidden-menu").is(e.target) && $(".search-show-hidden-menu").has(e.target).length === 0 && !$(".search-hidden-menu").is(e.target) && $(".search-hidden-menu").has(e.target).length === 0) {
        $('.search-hidden-menu').slideUp("fast");
      }

    });




  }

  checkUserRegister(userEmail, url) {
    this.restService.hitApi('users/is-user-signed-up', { 'email': userEmail }, 'get', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.alreadyRegister = responseData.result;
          if (this.alreadyRegister == true) {
            // this.routeChanged(url)
          } else {
            this.router.navigateByUrl('register?email=' + userEmail);
          }
        } else {
          // this.toastr.error(responseData.message);
        }
      }, (err) => {
        // this.errorMessage = err.error.message;
        // this.toastr.error(err.error.message);
      })
  }

  checkPageActive(doc) {
    console.log("this ka matlab", this)

    if (document.hidden) {
      //do whatever you want
      console.log("you Hidden 1");
    } else {
      //do whatever you want
      console.log("you SHOWN 1");

      if (localStorage.getItem('userData')) {
        let userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : '';
        let newSelectedProjectId = userData.projectId
        let newSelectedCompanyId = userData.companyId
        console.log("that.selectedProjectId", this.selectedProjectId)
        console.log("newSelectedProjectId", newSelectedProjectId)
        console.log("that.selectedCompanyId", this.selectedCompanyId)
        console.log("newSelectedCompanyId", newSelectedCompanyId)

        if (this.selectedCompanyId && newSelectedCompanyId && this.selectedCompanyId != newSelectedCompanyId) {
          this.restService.setCompany(newSelectedCompanyId, true)
        } else if (newSelectedProjectId && this.selectedProjectId != newSelectedProjectId) {
          console.log("both are not matched")

          if (this.router.url.includes('/release')) {
            this.router.navigate(['/releases'])
          } else if (this.router.url.includes('/sprint')) {
            this.router.navigate(['/sprints'])
          } else if (this.router.url.includes('/userstory')) {
            this.router.navigate(['/userstories'])
          } else if (this.router.url.includes('/task')) {
            this.router.navigate(['/tasks'])
          } else if (this.router.url.includes('/defect')) {
            this.router.navigate(['/defects'])
          } else if (this.router.url.includes('/testplan')) {
            this.router.navigate(['/testplans'])
          } else if (this.router.url.includes('/testcase')) {
            this.router.navigate(['/testcases'])
          } else if (this.router.url.includes('/project-view')) {
            this.router.navigate(['/project-management'])
          }

          setTimeout(() => {
            window.location.reload();
          });

        }

      }

    }
  }

  addProject() {
    this.projectAddForm.value.description = $('#summernote').val();
    if (this.projectAddForm.invalid) {
      this.projectAddFormSubmit = true;
      return;
    } else {

      let apiname = this.projectAddForm.value.projectId ? 'project/update' : 'project/create';
      // if(this.projectAddForm.value.projectId){
      this.restService.hitApi(apiname, this.projectAddForm.value, 'post', '', true)
        .subscribe((result) => {
          let responseData: any = result;
          if (responseData.statusCode == 200) {
            this.projectAddFormSubmit = false;
            this.toastr.success(responseData.message);
            $("#summernote").summernote('reset');
            $("#create_project_popup_web").modal('hide');
            this.closeModal();
            // this.projectAddForm.reset();
            this.getProjectList();
          } else {
            this.toastr.error(responseData.replyMessage);
          }
        }, (err) => {
          let errorData: any = err;
          this.toastr.error(errorData.error.message);
        })
    }

  }

  search(event) {
    console.log(event.target.value);
  }

  openOnMouseOver() {
    //this.clickMenuTrigger.openMenu();

    this.trigger.openMenu();
  }

  showclose(element) {
    $('.' + element).slideToggle("fast");
  }

  createForm(data) {

    if (data === null) {
      this.activityForm = this.formBuilder.group({
        'activity': ['', [Validators.required]]
      })
    } else {
      this.activityForm = this.formBuilder.group({
        'activity': [data.activity, [Validators.required]],
        'activityId': [data.id]
      })

    }


  }



  getUserprofile() {
    if (this.userData) {
      this.restService.hitApi('user/profile', { 'userId': this.userData.id }, 'get', '', true)
        .subscribe((result) => {
          let responseData: any = result;
          if (responseData.statusCode == 200) {
            this.userInfo = responseData.result;
            if (this.userInfo.isCompanyOwner == 1) {
              localStorage.setItem('isOwner', "true");
              this.upgradePlanAlert = null;
              this.getAccountDetails();
            }
          }
        }, (err) => {
          console.log(err);
          let errorData: any = err;
          // this.toastr.error(errorData.error.message);
        })

    }

  }

  getUserProfileAndGoToDashboard(data) {
    if (data) {
      this.userData = data;
      if (data.id != undefined && data.id != null) {
        if (data.id == data.ownerId) {
          localStorage.setItem('isOwner', "true");
        } else {
          localStorage.setItem('isOwner', "false");
        }
      } else {
        localStorage.setItem('isOwner', "false");
      }

      console.log("app comp id: ", data.id)
      console.log("app comp ownerId: ", data.ownerId)

      console.log("app comp: ", localStorage.getItem('isOwner'))

      this.selectedProjectId = null;
      let selectedSprintId = null;
      localStorage.removeItem("projectSelected");
      localStorage.removeItem("sprintIdSelected");
      this.userInfo = null;
      this.projectData = null;
      this.router.navigate(['dashboard']);

      // if (this.preserveSearch.redirectLastUrlState.url != null) {
      //   this.router.navigateByUrl(this.preserveSearch.redirectLastUrlState.url);
      // } else {
      //   this.router.navigate(['dashboard']);

      // }
      this.getProjectList();
      setTimeout(() => {
        this.ngOnInit();
      }, 50);

      // setTimeout(() => {
      //   this.restService.hitApi('user/profile', { 'userId': data.id }, 'get', '', true)
      //   .subscribe((result) => {
      //     this.broadcastService.boradcast("hideLoading");
      //     let responseData: any = result;
      //     if (responseData.statusCode == 200) {
      //       this.userInfo = responseData.result;
      //       if (this.userInfo.isCompanyOwner == 1) {
      //         localStorage.setItem('isOwner', "true");
      //       }

      //       this.getProjectList();
      //       this.router.navigate(['dashboard']);

      //     } else {
      //       this.toastr.error(responseData.message);
      //     }
      //   }, (err) => {
      //     this.broadcastService.boradcast("hideLoading");
      //     console.log(err);
      //     let errorData: any = err;
      //     //this.toastr.error(errorData.error.message);
      //   })
      // }, 50);


    }

  }

  // MARK: - Check is entity is allowed

  getEntityDetails(splitURL, forActive) {
    if (this.userData) {
      let entityId = splitURL[2]

      if (this.selectedProjectId != null && this.selectedProjectId != 'null' && this.selectedProjectId != '') {
        this.restService.hitApi('users/entityDetail', { "entityId": entityId }, 'post', '', true)
          .subscribe((result) => {

            let responseData: any = result;
            if (responseData.statusCode == 200) {

              if (responseData.result.isAuthenticate) {
                let projectID = responseData.result.projectId
                let companyId = responseData.result.companyId

                if (companyId != this.userData?.companyId) {
                  this.active = forActive
                  // send to company switch

                  // this.preserveSearch.redirectLastUrlState = {url: splitURL, data: responseData}
                  // this.router.navigate(['/company-selection']);
                  // this.active = '/company-selection';

                } else if (this.selectedProjectId != projectID) {
                  // send to switch project 
                  this.switchProjectAndSendToEntity(projectID, forActive, splitURL)
                } else {
                  this.active = forActive
                }
              } else {
                this.router.navigate(['common-error'], { queryParams: { statusCode: 422 } });
              }

            } else {
              this.toastr.error(responseData.message);
            }
          }, (err) => {
            console.log(err);
            let errorData: any = err;
            this.toastr.error(errorData.error.message);
          });
      } else {

        console.log("this.router.urlthis.router.url", this.router.url)

        this.toastr.error("Please select project from projects section");

        setTimeout(() => {
          if (this.router.url.includes('/release')) {
            this.router.navigate(['/releases'])
          } else if (this.router.url.includes('/sprint')) {
            this.router.navigate(['/sprints'])
          } else if (this.router.url.includes('/userstory')) {
            this.router.navigate(['/userstories'])
          } else if (this.router.url.includes('/task')) {
            this.router.navigate(['/tasks'])
          } else if (this.router.url.includes('/defect')) {
            this.router.navigate(['/defects'])
          } else if (this.router.url.includes('/testplan')) {
            this.router.navigate(['/testplans'])
          } else if (this.router.url.includes('/testcase')) {
            this.router.navigate(['/testcases'])
          }
        });

      }

    } else {
      this.checkIsUserLogin()
    }
  }

  switchProjectAndSendToEntity(projectId, forActive, splitURL) {

    console.log("project_id", projectId)
    this.restService.hitApi('user/updateProject', { 'project_id': projectId }, 'post', '', true)
      .subscribe((result) => {
        console.log("user/updateProject", result)
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          localStorage.setItem('userData', JSON.stringify(responseData.result));
          localStorage.removeItem('sprintIdSelected');
          localStorage.removeItem('sprintNameSelected');
          this.selectedProjectId = projectId
          if (this.selectedProjectId != null && this.selectedProjectId != 'null' && this.selectedProjectId != '') {
            localStorage.setItem('projectSelected', this.selectedProjectId);
          }

          this.restService.hitApi('project/details', { 'projectId': projectId }, 'post', '', true)
            .subscribe((result) => {
              let responseData: any = result;
              if (responseData.statusCode == 200) {
                this.projectData = responseData.result;
                console.log("selected project details " + responseData.result);
                this.setProjectDetailsToLocalStroage();

                this.active = forActive;
                let currentUrl = splitURL[1] + "/" + splitURL[2]

                this.broadcastService.boradcast("RELOAD_ENTITY", splitURL[2]);

              } else {
                this.toastr.error(responseData.message);
              }
            }, (err) => {
              console.log(err);
              let errorData: any = err;
              this.toastr.error(errorData.error.message);
            })

        } else {
          console.log("responseData.message", responseData.message);
          this.toastr.error(responseData.message);
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        this.toastr.error(errorData.error.message);
      })

  }

  checkIsUserLogin() {
    if (this.userData == null || this.userData == undefined || this.userData == '' || this.userData == 'null') {
      this.router.navigate(['login']);
    }

  }

  routeChanged(val) {

    console.log("route change call");
    console.log("route URl: ", val);
    console.log("rounte split: ", val.split("/"));


    if (!this.selectedProjectId) {
      this.selectedProjectId = localStorage.getItem('projectSelected');
      console.log("selctedProjectidBeforeProject list call", this.selectedProjectId);
    }



    if (val) {
      let spliturl = val.split("/");
      var shortCode: any;
      if (spliturl.length >= 2) {
        if (spliturl[2]) {
          var shortCodeSplitArray = spliturl[2].split('-');
          if (shortCodeSplitArray.length > 0) {
            shortCode = shortCodeSplitArray[0];
          }
          console.log("short code on route", shortCode);
        }

        if (val.indexOf('reset-password') > 0) {
          this.active = '/reset-password';
        } else if (spliturl[1] == 'user-profile') {
          this.active = '/user-management';
          this.checkIsUserLogin()
        } else if (spliturl[1] == 'project-view') {
          this.active = '/project-management';
          this.checkIsUserLogin()
        } else if (spliturl[1] == 'releases') {
          this.active = '/releases';
          this.checkIsUserLogin()
        } else if (spliturl[1] == 'price-slider-test') {
          this.active = '/price-slider-test';
        } else if (spliturl[1] == 'company-selection') {
          this.active = '/company-selection';
          //this.checkIsUserLogin()
        }
        else if (spliturl[1] == 'project') {
          this.selectedProjectId = spliturl[2];
          this.entity = spliturl[3];
          this.resourceId = spliturl[4];
          if (spliturl[3] == 'discussions' || spliturl[3] == 'discussion-thread') {
            this.active = '/discussions';
            this.checkIsUserLogin();
          }
          else if (spliturl[3] == 'releases' || spliturl[3] == 'release') {
            this.active = '/releases';
            this.checkIsUserLogin();
          }

          else if (spliturl[3] == 'sprints' || spliturl[3] == 'sprint') {
            this.active = '/sprints';
            this.checkIsUserLogin()
          }

          else if (spliturl[3] == 'backlog' || spliturl[3] == 'backlog') {
            this.active = '/backlog';
            this.checkIsUserLogin()
          }

          else if (spliturl[3] == 'userstories' || spliturl[3] == 'userstories') {
            this.active = '/userstories';
            this.checkIsUserLogin()
          }

          else if (spliturl[3] == 'tasks' || spliturl[3] == 'task') {
            this.active = '/tasks';
            this.checkIsUserLogin()
          }

          else if (spliturl[3] == 'defects' || spliturl[3] == 'defect') {
            this.active = '/defects';
            this.checkIsUserLogin()
          }

          else if (spliturl[3] == 'testplans' || spliturl[3] == 'testplan') {
            this.active = '/testplans';
            this.checkIsUserLogin()
          }

          else if (spliturl[3] == 'testcases' || spliturl[3] == 'testcase') {
            this.active = '/testcases';
            this.checkIsUserLogin()
          }

          else if (spliturl[3] == 'sprint-board') {
            this.active = '/sprint-board';
            this.checkIsUserLogin()
          }

          else if (spliturl[3] == 'kanban-board') {
            this.active = '/kanban-board';
            this.checkIsUserLogin()
          }

          this.preserveSearch.redirectLastUrlState = {
            url: '/project/' + this.selectedProjectId + '/' + this.entity + "/" + this.resourceId, data: null
          }

        } else if (val.includes('/register')) {
          this.active = '/register';
        } else if (val.includes('/login')) {
          this.active = '/login';
        } else if (val.includes('/dashboard')) {
          this.active = '/dashboard';
          this.checkIsUserLogin()
        } else if (val.includes('/search-filter')) {
          this.active = '/search-filter';
          this.checkIsUserLogin()
        } else if (val.includes('/discussions') || val.includes('/discussion-thread')) {
          this.active = '/discussions';
          this.checkIsUserLogin()

        } else if (val.includes('/releases') || val.includes('/release')) {
          if (val.includes('/release') && spliturl[2]) {
            if (shortCode) {
              this.restService.setIsSelectedProjectIsSame(shortCode);
            }
            this.getEntityDetails(spliturl, '/releases')
          } else {
            this.active = '/releases';
          }

          this.checkIsUserLogin()
        }

        else if (val.includes('/sprints') || val.includes('/sprint')) {
          if (val.includes('/sprint') && spliturl[2]) {
            if (shortCode) {
              this.restService.setIsSelectedProjectIsSame(shortCode);
            }
            this.getEntityDetails(spliturl, '/sprints')
          } else {
            this.active = '/sprints';
          }

          this.checkIsUserLogin()
        }

        else if (val.includes('/backlog')) {
          this.active = '/backlog';
          this.checkIsUserLogin()
        }

        else if (val.includes('/userstories') || val.includes('/userstory')) {
          if (val.includes('/userstory') && spliturl[2]) {
            if (shortCode) {
              this.restService.setIsSelectedProjectIsSame(shortCode);
            }
            this.getEntityDetails(spliturl, '/userstories')
          } else {
            this.active = '/userstories';
          }

          this.checkIsUserLogin()
        }

        else if (val.includes('/tasks') || val.includes('/task')) {
          if (val.includes('/task') && spliturl[2]) {
            if (shortCode) {
              this.restService.setIsSelectedProjectIsSame(shortCode);
            }
            this.getEntityDetails(spliturl, '/tasks')
          } else {
            this.active = '/tasks';
          }

          this.checkIsUserLogin()
        }

        else if (val.includes('/defects') || val.includes('/defect')) {
          if (val.includes('/defect') && spliturl[2]) {
            if (shortCode) {
              this.restService.setIsSelectedProjectIsSame(shortCode);
            }
            this.getEntityDetails(spliturl, '/defects')
          } else {
            this.active = '/defects';
          }

          this.checkIsUserLogin()
        }

        else if (val.includes('/testplans') || val.includes('/testplan')) {
          if (val.includes('/testplan') && spliturl[2]) {
            if (shortCode) {
              this.restService.setIsSelectedProjectIsSame(shortCode);
            }
            this.getEntityDetails(spliturl, '/testplans')
          } else {
            this.active = '/testplans';
          }

          this.checkIsUserLogin()
        }

        else if (val.includes('/testcases') || val.includes('/testcase')) {
          if (val.includes('/testcase') && spliturl[2]) {
            if (shortCode) {
              this.restService.setIsSelectedProjectIsSame(shortCode);
            }
            this.getEntityDetails(spliturl, '/testcases')
          } else {
            this.active = '/testcases';
          }

          this.checkIsUserLogin()
        }

        else if (val.includes('sprint-board')) {
          this.active = '/sprint-board';
          this.checkIsUserLogin()
        }

        else if (val.includes('kanban-board')) {
          this.active = '/kanban-board';
          this.checkIsUserLogin()
        }
        else if (val.includes('company-selection')) {
          this.active = '/company-selection';
          //this.checkIsUserLogin()
        }

        else if (val.includes('plan-expire')) {
          this.active = '/plan-expire';
          this.checkIsUserLogin()
        }

        else if (val.includes('project-company-switch')) {
          this.active = '/project-company-switch'
          this.checkIsUserLogin()
        }

        else {
          this.active = val;
        }
      } else {
        this.active = val;
      }
    }


    console.log('active', this.active);


    //TODO: loop infinity code while plan expire

    // if (this.active != '/' && this.active != '/#features' && this.active != '/#pricing' && this.active != '/pricing' && this.active != '/login' && this.active != '/register' && this.active != '/forgot-password' && this.active != '/reset-password' && this.active != '/terms' && this.active != '/O:' && this.active != '/contact-us' && this.active != '/under-construction' && this.active != '/payment' && this.active != '/subscription-failed' && this.active != '/subscription' && this.active != '/price-slider-test' && this.active != '/privacy-policy' && this.active != '/about-us') {
    //   this.getProjectList();

    //   this.restService.hitApi('user/companies', {}, 'get', '', true)
    //     .subscribe((result) => {
    //       let responseData: any = result;
    //       if (responseData.statusCode == 200) {
    //         this.companyName = localStorage.getItem('company_name');
    //         this.companyId = localStorage.getItem('company_id');
    //         this.companiesData = responseData.result;
    //       } else {
    //         //this.errorProfileMessage = responseData.message;
    //       }
    //     }, (err) => {
    //       //this.errorProfileMessage = err.error.message;
    //     })



    // } else {
    //   //alert("abcghshjs")
    //   this.companiesData = "";
    //   this.projectListData = "";
    // }

  }

  getProjectList() {
    if (this.userData) {
      this.restService.hitApi('project/project-list', {}, 'post', '', true)
        .subscribe((result) => {
          let responseData: any = result;
          if (responseData.statusCode == 200) {
            this.projectListData = responseData.result;
            console.log('projectListData', this.projectListData);
            if (this.projectListData.length == 1) {
              this.selectedProjectId = this.projectListData[0].id;
              if (this.selectedProjectId != null && this.selectedProjectId != 'null' && this.selectedProjectId != '') {
                this.broadcastService.boradcast("PROJECT_ID_GET", this.selectedProjectId);
              }

              console.log("selctedProjectidAfterProject list call", this.selectedProjectId);
              if (this.projectListData[0].id != null && this.projectListData[0].id != 'null' && this.projectListData[0].id != '') {
                localStorage.setItem('projectSelected', this.projectListData[0].id);
              }
              localStorage.setItem('projectShortCode', this.projectListData[0].shortCode);
              console.log("localStoreageAfterProject list call", localStorage);

              if (this.selectedProjectId) {
                this.getProjectDetail();
              }
            }
          } else {
            // this.toastr.error(responseData.replyMessage);
          }
        }, (err) => {
          console.log(err);
          let errorData: any = err;
          // this.toastr.error(errorData.error.message);
        });
    }
  }

  changeProject(pid) {
    if (this.selectedProjectId != pid) {
      console.log("API calling user/updateProject")
      // if (pid != localStorage.getItem('projectSelected')) {
      console.log("API calling user/updateProject")
      this.restService.hitApi('user/updateProject', { 'project_id': pid }, 'post', '', true)
        .subscribe((result) => {
          console.log("user/updateProject", result)
          let responseData: any = result;
          if (responseData.statusCode == 200) {
            this.toastr.success(responseData.message);
            localStorage.setItem('userData', JSON.stringify(responseData.result));
          } else {
            this.toastr.error(responseData.message);
          }

        }, (err) => {
          console.log(err);
          let errorData: any = err;
          this.toastr.error(errorData.error.message);
        })
      $('.project-hidden-menu').slideToggle('fast')
      //$(".dropdown").dropdown('toggle')


      if (pid != null && pid != 'null' && pid != '') {
        localStorage.setItem('projectSelected', pid);
      }

      this.selectedProjectId = pid;
      this.getProjectDetail();

      localStorage.removeItem('sprintIdSelected');
      localStorage.removeItem('sprintNameSelected');
    }
    this.router.navigateByUrl('/project-view/' + pid);

    // setTimeout(() => {
    //   window.location.reload();
    // }, 100);

    // if(this.active=='/releases' || this.active=='/sprints' || this.active=='/userstories' || this.active=='/tasks' || this.active=='/backlog' || this.active=='/discussions' || this.active=='/defects' || this.active=='/testplans'){
    //   this.router.navigateByUrl('/project/'+pid+this.active);
    // }else if(this.active=='/sprint-board'){
    //   localStorage.removeItem('sprintIdSelected');
    //   localStorage.removeItem('sprintNameSelected')
    //   this.router.navigateByUrl('/project/'+pid+this.active);
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 100);
    // }
    // else if(this.active=='/dashboard'){
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 100);
    // }
    // else{
    //   this.router.navigateByUrl(this.active);
    // }

    // } else {
    //   this.router.navigateByUrl('/project-view/' + pid);
    // }

  }
  getProjectDetail() {
    this.restService.hitApi('project/details', { 'projectId': this.selectedProjectId }, 'post', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.projectData = responseData.result;
          console.log("govind " + responseData.result);
          this.setProjectDetailsToLocalStroage()
        } else {
          // this.toastr.error(responseData.replyMessage);
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        // this.toastr.error(errorData.error.message);
      })
  }

  setProjectDetailsToLocalStroage() {

    localStorage.setItem("projectShortCode", this.projectData.shortCode);
    this.restService.setIsSelectedProjectIsSame(this.projectData.shortCode);
    localStorage.setItem("projectType", this.projectData.type);
    console.log("projectCode: " + localStorage.getItem("projectShortCode"));

    var isOwner = false;
    var isCompanyAdmin = false;
    var isProjectManager = false;
    var isOther = false;

    if (this.userData) {
      if (this.userData.isCompanyAdmin == 1) {
        if (this.userData.ownerId == this.userData.id) {
          isOwner = true;
        } else {
          isOwner = false;
        }
      } else {
        isOwner = false;
      }
    }

    if (isOwner) {
      localStorage.setItem("isOwner", "true");
    } else {
      localStorage.setItem("isOwner", "false");
    }

    if (this.userData) {
      if (isOwner) {
        isCompanyAdmin = false;
        isProjectManager = false;
        isOther = false;
      } else {
        if (this.userData.role == 1) {
          isCompanyAdmin = true;
          isProjectManager = false;
          isOther = false;
        } else if (this.userData.role == 2) {
          if (this.projectData && this.projectData.projectUsersProject) {
            this.projectData.projectUsersProject.forEach(element => {
              if (element.userId == this.userData.id) {
                if (element.userRoleId == 1) {
                  isProjectManager = true;
                  isOther = false;
                } else {
                  isProjectManager = false;
                  isOther = true;
                }
              }
            });
            isOther = false;

          } else {
            isOther = true;
          }
          isCompanyAdmin = false;
        } else {
          isOther = true;
          isProjectManager = false;
          isCompanyAdmin = false;
        }
      }

    }

    if (isCompanyAdmin) {
      localStorage.setItem("isCompanyAdmin", "true");
    } else {
      localStorage.setItem("isCompanyAdmin", "false");
    }

    if (isProjectManager) {
      localStorage.setItem("isProjectManager", "true");
    } else {
      localStorage.setItem("isProjectManager", "false");
    }

    if (isOther) {
      localStorage.setItem("isOther", "true");
    } else {
      localStorage.setItem("isOther", "false");
    }

    this.suffeleProjectList(this.projectData.id);
  }

  suffeleProjectList(id) {
    var newList = [];
    if (this.projectListData && this.projectListData.length > 0) {
      newList = this.projectListData.filter(el => el.id == id);
      this.projectListData.forEach(element => {
        if (element.id != id) {
          newList.push(element);
        }
      });
    }
    console.log("newList", newList);
    this.projectListData = newList;

  }

  closemenu() {
    $('.hidden-menu').slideToggle("fast");
    this.restService.logoutAPI();
  }

  openNav() {
    $(".rightSideBrief").hide();
    $(".rightSideDetails").show();
    document.getElementById("main").style.marginRight = (($(".rightSideDetails").width() - $(".rightAsideBar").width()) + 18) + 'px';
    document.body.style.backgroundColor = "white";
    this.getActivites();
  }

  getType(obj: Data) {
    if (obj) {
      this.projectAddForm.get('type').setValue(parseInt(obj.value));
      this.projectAddForm.get('typeName').setValue(parseInt(obj.name));
    }
  }

  getActivites() {
    this.restService.hitApi('activity/list', { 'status': 0 }, 'get', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.closeActivityListData = responseData.result.data;
        } else {
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        // this.toastr.error(errorData.error.message);
      })

    this.restService.hitApi('activity/list', { 'status': 1 }, 'get', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.todayActivityListData = responseData.result.data;
        } else {
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        // this.toastr.error(errorData.error.message);
      })

    this.restService.hitApi('activity/list', { 'status': 2 }, 'get', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.pendingActivityListData = responseData.result.data;
        } else {
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        // this.toastr.error(errorData.error.message);
      })
  }

  addActivity() {
    $('.horizontal-dot-box-open').hide("down");
    let apiname = (this.activityForm.value.activityId) ? 'activity/update' : 'activity/create';
    this.activityForm.value.targetDate = new Date();
    this.restService.hitApi(apiname, this.activityForm.value, 'post', '', false)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.addEditActivity = false;
          this.activityForm.reset();
          this.getActivites();
          this.toastr.success(responseData.message);
        } else {
          this.toastr.error(responseData.message);
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        this.toastr.error(errorData.error.message);
      })
  }

  deleteActivity(aid) {

    $('.horizontal-dot-box-open').hide("down");
    this.restService.hitApi('activity/delete', { 'activityId': aid }, 'post', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.getActivites();
          this.toastr.success(responseData.message);
        } else {
          this.toastr.error(responseData.message);
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        this.toastr.error(errorData.error.message);
      })
  }

  closeActivity(aid) {
    $('.horizontal-dot-box-open').hide("down");
    this.restService.hitApi('activity/update', { 'activityId': aid, 'status': 0 }, 'post', '', true)
      .subscribe((result) => {
        let responseData: any = result;
        if (responseData.statusCode == 200) {
          this.getActivites();
          this.toastr.success(responseData.message);
        } else {
          this.toastr.error(responseData.message);
        }
      }, (err) => {
        console.log(err);
        let errorData: any = err;
        this.toastr.error(errorData.error.message);
      })
  }

  openActivityOptions(id) {
    $('#horizontal-dot-box-open-' + id).toggle("down");
  }

  setActiveItem(menuItem: any) {
    this.activeItem = menuItem;
    console.log(menuItem)
    if (menuItem == 'My Profile') {
      this.broadcastService.boradcast('My Profile');

    }
  }

  changePage(filter: number): void {
    this.preserveSearch.clearAllSate();
    this.filter = filter
    this.router.navigate(['/search-filter'], { queryParams: { 'filter': filter } });
    this.active = '/search-filter';
    //this.location.go(`/search-filter?filter=${filter}`);

  }



  // MARK: - Account deatils

  calculateDiff(dateSent) {
    let currentDate = new Date();
    dateSent = new Date(dateSent);

    return Math.floor((Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));

    //return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) ) /(1000 * 60 * 60 * 24));
  }

  getAccountDetails() {
    if (this.userData) {
      let isOwner = localStorage.getItem("isOwner");
      if (isOwner) {
        this.restService.hitApi('companyUser/accountDetail', {}, 'post', '', false)
          .subscribe((result) => {
            let responseData: any = result;
            if (responseData.statusCode == 200) {

              // this.accountInfo = responseData.result;
              //console.log("this.accountInfo: ", this.accountInfo);
              //this.accountInfo.endOn = this.dateService.convertInDDMMYYYFormate(this.accountInfo.endDate);
              let diffrance = this.calculateDiff(responseData.result.endDate)
              console.log("diffrancediffrance", diffrance)
              if (diffrance < 1) {
                this.upgradePlanAlert = "Your plan has expired"
                this.isPlanExpire = true;
                this.router.navigate(['plan-expire']);
              } else if (diffrance <= 5) {
                this.upgradePlanAlert = "Your plan will expire in next " + diffrance + " days"
                this.isPlanExpire = false;
              } else {
                this.upgradePlanAlert = null;
                this.isPlanExpire = false;
              }

              // if (this.upgradePlanAlert) {
              //   this.router.navigate(['plan-expire']);
              // }

            } else {
              this.upgradePlanAlert = null;
              this.isPlanExpire = false;
              this.toastr.error(responseData.message);
            }
          }, (err) => {
            this.upgradePlanAlert = null;
            this.isPlanExpire = false;
            let errorData: any = err;
            this.toastr.error(errorData.err.message);
          })
      } else {
        this.upgradePlanAlert = null;
        this.isPlanExpire = false;
      }
    } else {
      this.upgradePlanAlert = null;
      this.isPlanExpire = false;
    }
  }

  closeModal() {
    $("#create_project_popup_web").modal('hide');
    this.isCreateProjectDialogShow = false;
    setTimeout(() => {
      this.resetForm();
      this.isCreateProjectDialogShow = true;
      this.setHeightOfSummarNote();
    });

  }


  resetForm() {
    this.projectAddForm.reset();
    this.projectAddForm.get('name').setValue(null);
    this.projectAddForm.get('typeName').setValue(this.typeList[0].name);
    this.projectAddForm.get('type').setValue(this.typeList[0].value);
    $("#summernote").summernote('reset');
  }


  setHeightOfSummarNote() {

    this.isCreateProjectDialogShow = true;

    $(document).ready(function () {
      $('.htmlEditor').summernote({
        height: '150px',
        focus: true,
        disableDragAndDrop: true,
        dialogsInBody: true,
        toolbar: [
          // [groupName, [list of button]]
          ['style', ['bold', 'italic', 'underline']],
          ['para', ['ul', 'ol', 'paragraph']]
        ],
        callbacks: {
          onPaste: function (e) {
            var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
            e.preventDefault();
            document.execCommand('insertText', false, bufferText);
          }
        }
      });

      $('#summernote').summernote({
        dialogsInBody: true
      });

    })


  }

  resetHistories(url) {
    if (url == "/search-filter") {
      this.filter = 1
    }

    this.preserveSearch.clearAllSate();

    if (url) {
      this.router.navigateByUrl(url);

    }
  }


}


/*$('.trakk-navigation li a').click( function(){
    if ( $(this).hasClass('current') ) {
        $(this).removeClass('current');
    } else {
        $('li a.current').removeClass('current');
        $(this).addClass('current');
    }
});*/
