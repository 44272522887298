import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { ValidationService } from '../services/config/config.service';
// import { AuthService, GoogleLoginProvider} from 'angular-6-social-login';
import { RestService } from '../services/rest/rest.service';

import { ToastrService } from 'ngx-toastr';
declare const $: any;
@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
	resetForm: FormGroup;
	verification_link : any;
	errorMessage: any = null;
	isLoading : boolean = false;
	oldPasswordInputType: any = 'password';
	newPasswordInputType: any = 'password';
	confirmPasswordInputType: any = 'password';
	
	constructor(
		private formBuilder: FormBuilder, 
		private router: Router, 
		private route: ActivatedRoute, 
		private restService: RestService,
		private toastr: ToastrService) {
		this.resetForm = this.formBuilder.group({
			'password': ['', [Validators.required,ValidationService.passwordValidator]],
			'confirm_password': ['', [Validators.required,ValidationService.passwordValidator]]
		});
	}

	// Check if user already logged in
	ngOnInit() {
		if (localStorage.getItem('userData')){
			// let userData = JSON.parse(localStorage.getItem('userData'))
			// userData.role==1?this.router.navigate(['/user-management']):this.router.navigate(['/discussion-board']);
			this.router.navigate(['/dashboard']);
		}
		this.route.params.subscribe(params => {
			this.verification_link = params['vlink'];
			this.verifyLink();
		});
	}

	goToForgotPassword() {
		this.router.navigate(['/forgot-password']);
	}

	verifyLink() {
		this.isLoading = true;
		let resetFormNew = this.formBuilder.group({
			'verification_link': [this.verification_link]
		});

		
		this.restService.hitApi('resetPasswordLinkStatus',resetFormNew.value,'post','',true)
		.subscribe((result)=>{
			this.isLoading = false;
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.errorMessage = null;
			}else{
				this.errorMessage = responseData.message;
			}
		},(err)=>{
			this.isLoading = false;
			console.log(err);
			this.errorMessage = err.error.message;
			//this.toastr.error(err.error.message);
		})
	}

	doReset(){
		this.resetForm.value.verification_link = this.verification_link;
		this.restService.hitApi('resetPassword',this.resetForm.value,'post','',true)
		.subscribe((result)=>{
			let responseData : any = result;
			
			if(responseData.statusCode==200){
				this.router.navigate(['login']);
				this.toastr.success(responseData.message);
			}else{
				this.toastr.error(responseData.message);
			}
		},(err)=>{
			console.log(err);
			this.toastr.error(err.error.message);
		})
	}

	eye(event) {
		event.stopPropagation();
	}

	toggle_eyes(inputType) {
		if (inputType == 1) {
			if (this.oldPasswordInputType == 'password') {
				this.oldPasswordInputType = 'text'
			} else {
				this.oldPasswordInputType = 'password'
			}
		} else if (inputType == 2) {
			if (this.newPasswordInputType == 'password') {
				this.newPasswordInputType = 'text'
			} else {
				this.newPasswordInputType = 'password'
			}
		} else if (inputType == 3) {
			if (this.confirmPasswordInputType == 'password') {
				this.confirmPasswordInputType = 'text'
			} else {
				this.confirmPasswordInputType = 'password'
			}
		}
	}

	

}
