
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute,  NavigationExtras} from '@angular/router';

import { RestService } from '../services/rest/rest.service';
import { ToastrService } from 'ngx-toastr';

declare const $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	freePlanData: any;
	constructor(
		private router: Router, 
		private restService: RestService,
		private toastr: ToastrService
		) {
		
	}


  ngOnInit() {

  	$('document').ready(function () {  
  	  $(window).scroll(function() {
  	      if ($(this).scrollTop() > 1){
  	      $('header').addClass("sticky");
  	      }
  	      else{
  	      $('header').removeClass("sticky");
  	      }
  	  });
  	});

	this.getFreePlan();
  	
  }

  getFreePlan() {
		this.restService.hitApi('users/freePackageDetail',null,'get','',true)
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.freePlanData = responseData.result;
				console.log("this.freePlanData: ", this.freePlanData)
			} else {
				// this.toastr.error(responseData.replyMessage);
			}
		},(err)=>{
			console.log(err);
			let errorData : any = err;
			// this.toastr.error(errorData.error.replyMessage);
		})
	}

	tryFree(){
		if (this.freePlanData.status == 1) {
			this.router.navigateByUrl('/register', { state: { package: "free",  minStorage: this.freePlanData.maxData, minUsers:  this.freePlanData.noOfUsers} });
		} else {
			this.toastr.error("Sorry, free trail not available, please try to pay and use Trakk.in");
		}
	}

}
