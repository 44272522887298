import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common'
import * as moment from 'moment';



@Injectable()

export class DateConverterService {
    constructor(public datepipe: DatePipe) {

    }

    convertUTCDateToLocalDate(date) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate;
    }

    convertInDDMMYYYFormate(date) {
        var newDate = new Date(date);
        if (newDate instanceof Date) {
            let latest_date = this.datepipe.transform(newDate, 'dd-MM-yyyy');
            return latest_date;
        }

    }
    convertInYYYYMMDDFormate(strDate) {
        return moment(strDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
        // var newDate = new Date(date);
        // let latest_date = this.datepipe.transform(newDate, 'dd-MM-yyyy');
        // return latest_date;
    }

    getDateFromDay(date, days) {
        var endDate = date;
        return endDate.setDate(endDate.getDate() + days);

    }
    getDayHashMap() {
        var dayHashMap: Map<any, String> = new Map();
        dayHashMap.set(0, 'Sun');
        dayHashMap.set(1, 'Mon');
        dayHashMap.set(2, 'Tue');
        dayHashMap.set(3, 'Wed');
        dayHashMap.set(4, 'Thu');
        dayHashMap.set(5, 'Fri');
        dayHashMap.set(6, 'Sat');

        return dayHashMap;

    }
}