import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
// Services
import { RestService } from '../services/rest/rest.service';
declare const $:any;

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
})

export class SearchComponent implements OnInit {
	searchData : any;
	userData : any;
	constructor(
		private formBuilder: FormBuilder,
		private restService : RestService, 
		private toastr: ToastrService,
		private route : ActivatedRoute,
		private router : Router) { 
			this.userData = JSON.parse(localStorage.getItem("userData"));
			// this.pid = JSON.parse(localStorage.getItem("projectData")).id;
			// this.route.params.subscribe(params => {
			// 	this.pid = params['pid'];
			// 	if (this.pid && this.pid != null && this.pid !== undefined) {
			// 		this.getProjectDetail();
			// 		this.getProjectUpdate();
			// 		this.getProjectDiscussion();
			// 		this.getAttachments();
			// 		this.getLinks();
			// 	}
			// });
		}
	
	ngOnInit() {
		console.log(this.userData);
		if (!localStorage.getItem('userData')) {
			this.router.navigate(['/login']);
		}
	}


	getProjectDiscussion(){
		this.restService.hitApi('search',{},'post','',true)
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.searchData = responseData.result.data;
			}else{
				this.searchData = [];
			}
		},(err)=>{
			this.searchData = [];
			let errorData : any = err;
			// this.toastr.error(errorData.error.message);
		})
	}

	
}

