import { values } from 'node_modules1111/@types/lodash/ts3.1';
import { getValue } from '@syncfusion/ej2-base';
import { filter, findIndex } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BroadcastService } from '../services/broadcast/broadcast.service';
import * as moment from "moment";
import { Location } from '@angular/common';




// Services
import { RestService } from '../services/rest/rest.service';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { provideRoutes } from 'node_modules1111/@angular/router/router';
declare const $: any;
@Component({
	selector: 'app-project-view',
	templateUrl: './project-view.component.html',
	styleUrls: ['./project-view.component.scss'],
})

export class ProjectViewComponent implements OnInit {
	projectData: any = {};
	discussionData: any = {};
	editproject: boolean = false;
	projectEditForm: FormGroup;
	pid: any;
	unassociatedUsers: any;
	associatedUsers: any;
	allUsers: any = [];
	selectedForDelete: any;
	selected = 'domain';
	userData: any = JSON.parse(localStorage.getItem("userData"));
	page = 1;
	permissionLevels = [{
		name: 'Company Owner',
		value: 5
	}, {
		name: 'Company Admin',
		value: 4
	}, {
		name: 'Project Manager',
		value: 1
	}, {
		name: 'Company User',
		value: 3
	}, {
		name: 'Client',
		value: 2
	}]

	companiesData: any;
	cur_company_id: any;
	isOwner: boolean;
	isCompanyAdmin: boolean;
	isOther: boolean;
	isProjectManager: boolean;
	isAllSelected: boolean;
	searchName;
	selectedPermiossionRole;
	userSearch: any = '';
	isActionColumnShown: boolean;
	constructor(
		private restService: RestService,
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private toastr: ToastrService,
		private router: Router,
		private broadcastService: BroadcastService,
		private location: Location) {
		// this.projectEditForm = this.formBuilder.group({
		// 	name : ['', [Validators.required]],
		// 	description: ['',]
		// });
		console.log("UserData in project view: ", this.userData);
		this.route.params.subscribe(params => {
			this.pid = params['pid'];
		});
	}

	ngOnInit() {

		this.router.routeReuseStrategy.shouldReuseRoute = () => false;

		$(document).ready(() => {
			$('.heading-editable h1').show("fast");
			$('.heading-area').hide("fast");
			$('.tick-cancel-input-filed').hide("fast");
		})

		this.fetchAllData();


		// NOTE - Commnet out this is not usefully it give infanite loop
		// this.router.events.pipe(
		// 	filter((event: RouterEvent) => event instanceof NavigationEnd)
		// ).subscribe(() => {
		// 	if (this.userData != undefined && this.userData != '') {
		// 		this.fetchAllData();
		// 	}
		// });

		// this.getProjectDetail();
		// this.getProjectUnassociatedUser();
		// this.getProjectAssociatedUser();

		// this.restService.getCompanies();
	}
	fetchAllData() {
		this.getProjectDetail();

	}
	toggleClass(indexOfelement) {

		for (var i = 0; i < this.associatedUsers.length; i++) {
			var className = ".edit-role-progress-work-st-js_" + i
			if (i == indexOfelement) {
				$(className).slideToggle();
			} else {
				$(className).slideUp();
			}

		}
	}

	getProjectDetail() {
		this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.projectData = responseData.result;
					if (this.projectData.createdAt) {
						var theDate = moment.utc(this.projectData.createdAt).toDate();
						this.projectData.createdAt = theDate.toDateString();

					}
					if (this.userData) {
						var isOwner = localStorage.getItem('isOwner');
						if (isOwner == "true") {
							this.isOwner = true;
							this.isCompanyAdmin = false;
							this.isProjectManager = false;
							this.isOther = false;
						} else {
							if (this.userData.role == 1) {
								this.isOwner = false;
								this.isCompanyAdmin = true;
								this.isProjectManager = false;
								this.isOther = false;
							} else if (this.userData.role == 2) {
								if (this.projectData && this.projectData.projectUsersProject) {
									this.projectData.projectUsersProject.forEach(element => {
										if (element.userId == this.userData.id) {
											if (element.userRoleId == 1) {
												this.isProjectManager = true;
												this.isOther = false;
											} else {
												this.isProjectManager = false;
												this.isOther = true;
											}
										}
									});
									this.isOther = false;

								} else {
									this.isOther = true;
								}
								this.isOwner = false;
								this.isCompanyAdmin = false;
							} else {
								this.isOther = true;
								this.isOwner = false;
								this.isProjectManager = false;
								this.isCompanyAdmin = false;
							}
						}




						// if (this.userData.isCompanyAdmin == 1) {


						// 	if (isOwner == "true") {
						// 		this.isOwner = true;
						// 		this.isCompanyAdmin = false;
						// 		this.isProjectManager = false;
						// 		this.isOther = false;


						// 	} else {
						// 		this.isOwner = false;
						// 		this.isCompanyAdmin = true;
						// 		this.isProjectManager = false;
						// 		this.isOther = false;
						// 	}
						// } else {
						// 	if (this.projectData && this.projectData.projectUsersProject) {
						// 		this.projectData.projectUsersProject.forEach(element => {
						// 			if (element.userId == this.userData.id) {
						// 				if (element.userRoleId == 1) {
						// 					this.isProjectManager = true;
						// 					this.isOther = false;
						// 				} else {
						// 					this.isProjectManager = false;
						// 					this.isOther = true;
						// 				}
						// 			}
						// 		});
						// 		this.isOther = false;

						// 	} else {
						// 		this.isOther = true;
						// 	}
						// 	this.isOwner = false;
						// 	this.isCompanyAdmin = false;

						// }
					}

					if (this.projectData.type == 1) {
						this.projectData.typeName = "Agile"
					} else {
						this.projectData.typeName = "Kanban"
					}

					if (this.projectData.description) {
						var des = this.projectData.description;
						des = des.replace(/↵/g, '<br/>');
						console.log("des", des);
						this.projectData.description = des;
						console.log("projectData", this.projectData);
					}
					this.getProjectUnassociatedUser();
					this.getProjectAssociatedUser();
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	getProjectUnassociatedUser() {
		this.restService.hitApi('project/userList?projectId=' + this.pid + '&isAvailable=1', {}, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;

				if (responseData.statusCode == 200) {
					this.unassociatedUsers = responseData.result;
					this.unassociatedUsers.forEach(element => {
						element.checked = false;
						element.isRemovable = true;
						element.searchValue = element.userName;

					});
					this.mergeUsers();

				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	setRoleValue(event) {

		this.selectedPermiossionRole = event.target.value;
	}

	searchProjectAssociatedUser() {

		let searchdata: any = "";
		if (this.searchName != undefined && this.searchName != null && this.searchName != "null") {
			searchdata += "&q=" + this.searchName;

		}

		if (this.selectedPermiossionRole != undefined && this.selectedPermiossionRole != null && this.selectedPermiossionRole != "null") {
			searchdata += "&p=" + this.selectedPermiossionRole;
		}
		// if (serachBy == "name") {
		// 	searchdata += "&q=" + event.target.value;
		// }

		// if (serachBy == "role") {
		// 	if (event != undefined) {
		// 		searchdata += "&p=" + event.value;
		// 	} else {
		// 		searchdata += "&p=";
		// 	}

		// }

		// if (event != null) {
		// 	if (serachBy == 'name') {
		// 		if (value) {
		// 			searchdata += "&q=" + value;
		// 		} else {
		// 			if (event.target.value != undefined && event.target.value != null && event.target.value != "null") {
		// 				searchdata += "&q=" + event.target.value;
		// 			}
		// 		}



		// 	} else {
		// 		if (event.target.value != undefined && event.target.value != null && event.target.value != "null") {
		// 			searchdata += "&p=" + event.target.value;
		// 		}
		// 	}
		// }

		this.restService.hitApi('project/userList?projectId=' + this.pid + '&isAvailable=0' + searchdata, {}, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.associatedUsers = responseData.result;
					this.associatedUsers.forEach(element => {
						element.checked = true;
						element.searchValue = element.userName;
						if (element.userRoleId == 1) {
							element.roleName = 'Project Manager';
							if (this.isOwner || this.isCompanyAdmin || this.isProjectManager) {
								element.isRemovable = true;
							} else {
								element.isRemovable = false;
							}
						} else if (element.userRoleId == 3) {
							element.roleName = 'Company User';
							if (this.isOwner || this.isCompanyAdmin || this.isProjectManager) {
								element.isRemovable = true;
							} else {
								element.isRemovable = false;
							}
						} else if (element.userRoleId == 4) {
							if (element.isCompanyOwner == 1) {
								element.roleName = 'Company Owner';
								if (this.isOwner) {
									element.isRemovable = true;
								} else {
									element.isRemovable = false;
								}
							} else {
								element.roleName = 'Company Admin';
								if (this.isOwner || this.isCompanyAdmin) {
									element.isRemovable = true;
								} else {
									element.isRemovable = false;
								}
							}

						} else if (element.userRoleId == 2) {
							element.roleName = 'Client';
							if (this.isOwner || this.isCompanyAdmin) {
								element.isRemovable = true;
							} else {
								element.isRemovable = false;
							}

						}
						// if (this.isOwner) {
						// 	element.isRemovable = true;
						// } else if (this.isCompanyAdmin) {
						// 	if (element.userRoleId == 4) {
						// 		if (element.isCompanyOwner == 1) {
						// 			element.isRemovable = false;

						// 		} else {
						// 			element.isRemovable = true;

						// 		}
						// 	} else if (element.userRoleId == 1 || element.userRoleId == 3 || element.userRoleId == 2) {
						// 		element.isRemovable = true;
						// 	} else {
						// 		element.isRemovable = false;

						// 	}
						// } else if (this.isProjectManager) {
						// 	if (element.userRoleId == 1 || element.userRoleId == 3) {
						// 		element.isRemovable = true;
						// 	} else if (element.userRoleId == 4) {
						// 		element.isRemovable = false;

						// 	} else if (element.userRoleId == 2) {
						// 		element.isRemovable = false;

						// 	}
						// }


					});
					var filterArray = this.associatedUsers.filter(el => el.isRemovable == true);
					if (filterArray.length > 0) {
						this.isActionColumnShown = true;
					} else {
						this.isActionColumnShown = false;
					}
					this.mergeUsers();
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}
	searchProjectUnAssociatedUser() {

		console.log("iiam called searchProjectUnAssociatedUser")
		let searchdata: any = "";
		let searchValue;

		if ($('#project_search_name').val() != "") {
			searchValue = $('#project_search_name').val();
			searchdata += "&q=" + $('#project_search_name').val();
		}
		// if ($('#search_role').val() != "") {
		// 	searchdata += "&p=" + $('#search_role').val();
		// }
		this.restService.hitApi('project/userList?projectId=' + this.pid + '&isAvailable=1' + searchdata, {}, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.unassociatedUsers = responseData.result;
					this.unassociatedUsers.forEach(element => {
						element.checked = false;
						element.isRemovable = true;
						element.searchValue = element.userName;

						// if(this.isOwner || this.isCompanyAdmin){
						// 	element.isAdded = true;
						// }else if(this.isProjectManager){
						// 	if (element.companyRole != 1){
						// 		element.isAdded = true;
						// 	}
						// }

					});
					this.mergeUsers();
					this.searchProjectAssociatedUser();

				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}


	getProjectAssociatedUser() {
		this.restService.hitApi('project/userList?projectId=' + this.pid + '&isAvailable=0', {}, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.isAllSelected = true;
					this.associatedUsers = responseData.result;
					console.log("associateUser");
					console.log(this.associatedUsers);
					this.allUsers = [];
					this.associatedUsers.forEach(element => {
						element.checked = true;
						element.searchValue = element.userName;
						if (element.userRoleId == 1) {
							element.roleName = 'Project Manager';
							if (this.isOwner || this.isCompanyAdmin || this.isProjectManager) {
								element.isRemovable = true;
							} else {
								element.isRemovable = false;
							}
						} else if (element.userRoleId == 3) {
							element.roleName = 'Company User';
							if (this.isOwner || this.isCompanyAdmin || this.isProjectManager) {
								element.isRemovable = true;
							} else {
								element.isRemovable = false;
							}
						} else if (element.userRoleId == 4) {
							if (element.isCompanyOwner == 1) {
								element.roleName = 'Company Owner';
								if (this.isOwner) {
									element.isRemovable = true;
								} else {
									element.isRemovable = false;
								}
							} else {
								element.roleName = 'Company Admin';
								if (this.isOwner || this.isCompanyAdmin) {
									element.isRemovable = true;
								} else {
									element.isRemovable = false;
								}
							}

						} else if (element.userRoleId == 2) {
							element.roleName = 'Client';
							if (this.isOwner || this.isCompanyAdmin) {
								element.isRemovable = true;
							} else {
								element.isRemovable = false;
							}

						}

						// if (this.isOwner) {
						// 	element.isRemovable = true;
						// } else if (this.isCompanyAdmin) {
						// 	if (element.userRoleId == 4) {
						// 		if (element.isCompanyOwner == 1) {
						// 			element.isRemovable = false;

						// 		} else {
						// 			element.isRemovable = true;

						// 		}
						// 	} else if (element.userRoleId == 1 || element.userRoleId == 3 || element.userRoleId == 2) {
						// 		element.isRemovable = true;
						// 	} else {
						// 		element.isRemovable = false;

						// 	}
						// } else if (this.isProjectManager) {
						// 	if (element.userRoleId == 1 || element.userRoleId == 3) {
						// 		element.isRemovable = true;
						// 	} else if (element.userRoleId == 4) {
						// 		element.isRemovable = false;

						// 	} else if (element.userRoleId == 2) {
						// 		element.isRemovable = false;

						// 	}
						// } else {
						// 	element.isRemovable = false;
						// }
						var filterArray = this.associatedUsers.filter(el => el.isRemovable == true);
						if (filterArray.length > 0) {
							this.isActionColumnShown = true;
						} else {
							this.isActionColumnShown = false;
						}


					});
					console.log("Associate users after set", this.associatedUsers);
					this.mergeUsers();
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	addUserToProject(uid) {

		this.restService.hitApi('companyUser/addProjectUser', { "userId": uid, "projectId": this.pid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.toastr.success(responseData.message);
					this.getProjectAssociatedUser();
					this.getProjectUnassociatedUser();
					this.getProjectDetail();
					$("#permission_level").val("null");
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	removeUserFromProject(uid) {

		this.restService.hitApi('companyUser/removeProjectUser', { "userId": uid, "projectId": this.pid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.toastr.success(responseData.message);
					this.getProjectAssociatedUser();
					this.getProjectUnassociatedUser();
					this.getProjectDetail();
					$("#permission_level").val("null");
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	updateUserRole(user) {
		let userId = user.userId;
		let userRoleId = user.userRoleId;

		if (user.companyRole == 2) {
			const index = this.associatedUsers.findIndex(user => user.userId === userId);
			if (index > -1) {
				this.restService.hitApi('companyUser/updateProjectUser', { 'projectId': this.pid, 'userRoleId': userRoleId, 'userId': userId }, 'post', '', true)
					.subscribe((result) => {
						let responseData: any = result;
						if (responseData.statusCode == 200) {
							this.toastr.success(responseData.message);
							this.getProjectAssociatedUser();
							this.getProjectUnassociatedUser();
							$("#permission_level").val("null");

							//$("#permission_level")[0].selectedIndex = 0;


							// $("#permission_level").prop()

						} else {
							this.toastr.error(responseData.message);
						}
					}, (err) => {
						console.log(err);
						let errorData: any = err;
						this.toastr.error(errorData.error.message);
					})
			} else {

				this.restService.hitApi('companyUser/addProjectUser', { "userId": userId, "projectId": this.pid }, 'post', '', true)
					.subscribe((result) => {
						let responseData: any = result;
						if (responseData.statusCode == 200) {
							this.restService.hitApi('companyUser/updateProjectUser', { 'projectId': this.pid, 'userRoleId': userRoleId, 'userId': userId }, 'post', '', true)
								.subscribe((result) => {
									let responseData: any = result;
									if (responseData.statusCode == 200) {
										this.toastr.success(responseData.message);
										this.getProjectAssociatedUser();
										this.getProjectUnassociatedUser();
										$("#permission_level").val("null");

										//$("#permission_level")[0].selectedIndex = 0;


										// $("#permission_level").prop()

									} else {
										this.toastr.error(responseData.message);
									}
								}, (err) => {
									console.log(err);
									let errorData: any = err;
									this.toastr.error(errorData.error.message);
								})

						} else {
							this.toastr.error(responseData.message);
						}
					}, (err) => {
						console.log(err);
						let errorData: any = err;
						this.toastr.error(errorData.error.message);
					})
			}

		} else {
			this.restService.hitApi('companyUser/updateProjectUser', { 'projectId': this.pid, 'userRoleId': userRoleId, 'userId': userId }, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.toastr.success(responseData.message);
						this.getProjectAssociatedUser();
						this.getProjectUnassociatedUser();
						$("#permission_levelctBox").val("null");

					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}


	}

	openDeleteProject(data) {
		this.selectedForDelete = data;
		$('#delete-project').modal('show');
	}

	deleteProject(pid) {
		$('#delete-project').modal('hide');
		this.restService.hitApi('project/deleteProject', { 'projectId': pid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					//this.router.navigate(['/project-management']);
					if (pid == this.pid) {
						console.log("pid == this.pid")
						this.broadcastService.boradcast("SELETED_PROJECT_DELETED", pid);
						this.pid = null;
						localStorage.removeItem("projectSelected");
					}
					this.broadcastService.boradcast("FETCH_PROJECT_LIST", pid);

					this.toastr.success(responseData.message);
					this.router.navigate(['/project-management']);
				}

			}, (err) => {
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	backToRetun() {
		this.location.back();
	}


	setUserRole(projectUser, role, roleId, index) {
		projectUser.userRoleId = roleId;
		projectUser.userRole = role;
		this.toggleClass(index);

	}

	updateUserList(projectUser) {
		this.isAllSelected = true;
		console.log("projectUser.role", projectUser);
		if (projectUser.checked == true) {
			projectUser.checked = true;
		} else {
			projectUser.checked = false;
		}
		if (this.associatedUsers.indexOf(projectUser) != -1) {
			var index = this.associatedUsers.indexOf(projectUser);
			this.associatedUsers.splice(index, 1);
		} else {
			if (projectUser.companyRole != 2) {
				this.associatedUsers.push(projectUser);
			}
		}


		if (projectUser.checked) {
			if (projectUser.companyRole != 2) {
				this.addUserToProject(projectUser.userId)
			}
		} else {
			this.removeUserFromProject(projectUser.userId)
		}


	}

	removeUserList(projectUser) {

		// if (projectUser.checked == true) {
		// 	projectUser.checked = true;
		// } else {
		projectUser.checked = false;
		// }
		if (this.associatedUsers.indexOf(projectUser) != -1) {
			var index = this.associatedUsers.indexOf(projectUser);
			this.associatedUsers.splice(index, 1);
		}

		this.removeUserFromProject(projectUser.userId)

	}


	mergeUsers() {
		this.allUsers = [];
		// if (this.associatedUsers.length > 0) {

		// 	this.associatedUsers.forEach(element => {
		// 		var isAlreadyAdded = false;

		// 		// this.allUsers.forEach(lelement => {
		// 		// 	console.log("lelement.userId", lelement.userId)
		// 		// 	console.log("element.userId", element.userId)
		// 		// 	if (lelement.userId == element.userId) {
		// 		// 		isAlreadyAdded = true;
		// 		// 		break;
		// 		// 	}
		// 		// });

		// 		for(let i=0; i<this.allUsers.length; i++) {
		// 			let user = this.allUsers[i];
		// 			console.log("lelement.userId", user.userId)
		// 			console.log("element.userId", element.userId)
		// 			if (user.userId == element.userId) {
		// 				isAlreadyAdded = true;
		// 				break;
		// 			}
		// 		}

		// 		if (!isAlreadyAdded) {
		// 			this.allUsers.push(element);
		// 		}

		// 	});

		// }

		if (this.associatedUsers?.length > 0) {
			this.associatedUsers.forEach(element => {
				this.allUsers.push(element);
			});
		}

		if (this.unassociatedUsers?.length > 0) {
			this.unassociatedUsers.forEach(element => {
				this.allUsers.push(element);
			});
		}
		this.allUsers?.sort(function (a, b) {
			return a.userName.localeCompare(b.userName);
		})
		this.allUsers?.sort((a, b) => b.checked - a.checked);

		console.log("allUsers", this.allUsers);
	}

	fiterPermissionLevelsByUser(companyRoleId) {
		var filterArray;
		if (companyRoleId == 1) {
			filterArray = this.permissionLevels.filter(elm => elm.value == 4)
		} else if (companyRoleId == 2) {
			filterArray = this.permissionLevels.filter(elm => elm.value == 1 || elm.value == 3)
		} else if (companyRoleId == 3) {
			filterArray = this.permissionLevels.filter(elm => elm.value == 2);
		} else {
			filterArray = this.permissionLevels;
		}
		return filterArray;
	}

	isCompanyOwner(userId) {
		if (userId == this.userData.ownerId) {
			return true;
		}
		return false;
	}

	loadMore() {
		this.page++;
		this.getProjectUnassociatedUser();

	}

	goToUser(userId) {
		if (this.userData.role == 1) {
			localStorage.setItem('returnTo', "/project-view/" + this.pid);
			this.router.navigateByUrl('/user-profile/' + userId);
		}

	}

	showedit() {
		if (this.isOwner || this.isCompanyAdmin) {
			$('.heading-editable h1').hide("fast");
			$('.heading-area').show("fast");
			$('.tick-cancel-input-filed').show("fast");
		}

	}
	hideedit() {
		$('.heading-editable h1').show("fast");
		$('.heading-area').hide("fast");
		$('.tick-cancel-input-filed').hide("fast");
	}

	updateProject(field, value = "") {
		let data: any;
		if (field == 'name') {
			value = $('#project_' + field).val();
			data = { 'name': value, 'projectId': this.pid };
		}

		this.restService.hitApi('project/update', data, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.toastr.success(responseData.message);
					this.projectData.name = $('#project_' + field).val();
					if (field == 'name') {
						$('.heading-editable h1').show("fast");
						$('.heading-area').hide("fast");
						$('.tick-cancel-input-filed').hide("fast");
					}
					// $('#create_release_popup_web').modal('hide');
					// this.files = [];
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(err.error.message);
			})
	}

	formatBytes(bytesValue) {
		if (bytesValue >= 1073741824) {
			return (bytesValue / (1073741824)).toFixed(2) + ' GB';
		} else {
			return (bytesValue / (1048576)).toFixed(2) + ' MB';
		}
	}

}



