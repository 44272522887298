export const YYY_mm_dd_DATE_FORMATS = {
    parse: {
        dateInput: 'yyyy-mm-dd',
    },
    display: {
        dateInput: 'yyyy-mm-dd',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMM YYYY'
    },
};
