import { FormControl, ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { MyErrorStateMatcher } from './../../MyErrorStateMatcher';
import { Component, OnInit, Input, Self, Optional, forwardRef, OnDestroy, ViewChild, AfterViewInit, OnChanges } from '@angular/core';



@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => InputComponent),
    multi: true,
  }]
})

export class InputComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges, ControlValueAccessor {

  @Input()
  label: string;

  @Input()
  required: boolean;
  @Input()
  showError: boolean;


  @Input() type: 'text' | 'email' | 'password' = 'text';
  @Input() isEyeButton;
  @Input() maxlength;
  @Input() disabled: true | false;
  @Input() ngAutoFocus: boolean;
  @Input() errors: any = null;


  isPasswordHide: boolean = false;
  value: any = '';
  validators;


  matcher = new MyErrorStateMatcher();

  onChange: () => void;
  onTouched: () => void;
  control;
  @ViewChild('input', { static: false, read: NgControl }) input


  constructor() {
  }

  ngAfterViewInit() {
    console.log('AfterViewInit call');
    console.log('disableddisabled', this.disabled);
    this.validate(null)
  }
  ngOnChanges() {
    console.log('on Change call');
    this.validate(null)
  }
  // constructor(
  //   // Retrieve the dependency only from the local injector,
  //   // not from parent or ancestors.
  //   @Self()
  //   // We want to be able to use the component without a form,
  //   // so we mark the dependency as optional.
  //   @Optional()
  //   private ngControl: NgControl
  // ) {
  //   if (this.ngControl) {
  //     this.ngControl.valueAccessor = this;
  //     console.log("this.ngControl", this.ngControl.valueAccessor);
  //   }
  // }


  ngOnInit() {
  }


  ngOnDestroy(): void {
    console.log("destroy");
    this.validate(null)

  }

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.value = value ? value : '';
    console.log("writeValue called")

  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    console.log("setDisabledState called")
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChange = fn;
    console.log("registerOnChange called")
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
    console.log("registerOnTouched called")
  }

  isPasswordVisible() {
    if (this.type == 'password') {
      this.type = 'text';
      this.isPasswordHide = false;
    } else {
      this.type = 'password';
      this.isPasswordHide = true;
    }
  }
  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.control)
      this.control = control;

    if (this.control && this.input) {
      this.input.control.setValidators(this.control.validator);
      this.input.control.touched = false;
    }


    console.log('control', this.control);
    return null
  }



}
