import { state } from '@angular/animations';
import { formatDate } from '@angular/common';
import { from } from 'rxjs';
import { Target } from '@angular-devkit/architect';
import { append } from '@syncfusion/ej2-base';
import { JsonAstArray } from '@angular-devkit/core';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IMyDpOptions } from 'mydatepicker';
import { Location } from '@angular/common';


// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { DateConverterService } from '../services/date-formatter/date-converter.service';
import { Data } from '../ui-components/select-dropdown/Data';
import { BroadcastService } from '../services/broadcast/broadcast.service';
import { FileUploadService } from "../file-upload-service";
import { HttpEvent, HttpEventType } from '@angular/common/http';

// import { MatDialog } from '@angular/material';
// import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

declare const $: any;


@Component({
	selector: 'app-sprint',
	templateUrl: './sprint.component.html',
	styleUrls: ['./sprint.component.scss'],

})

export class SprintComponent implements OnInit {
	userData: any = JSON.parse(localStorage.getItem("userData"));
	sprintCreateForm: FormGroup;
	selectedSprint: any;
	sprintData: any = [];
	pid: any = localStorage.getItem('projectSelected');
	userInfo: any;
	sid: any;
	sprintId: any;

	releaseList: any;
	selectedAttachment;


	linkTabs: any = ['Release', 'User Stories', 'Task', 'Defect', 'Test Plan']
	getUnassociatedData: any = [];
	unassociatedData: any;
	selectedEntity: any;
	selectedCheckboxArr: any = [];
	unclosedData: any;

	projectListData: any;
	projectData: any;
	files: any = [];
	imgArr: any = [];
	descriptionFiles: any = [];
	descriptionImgArr: any = [];

	sprintReleaseDisabled: boolean = true;
	sprintUserStoriesDisabled: boolean = true;
	sprintTaskDisabled: boolean = true;
	sprintDefectDisabled: boolean = true;
	sprintTestRunDisabled: boolean = true;
	jqueryRef: any = $;
	projectUserList: any;
	watchesUserList: any;
	release = new FormControl('');
	sprintStatus = new FormControl();
	duration = new FormControl();
	addCommentForm: FormGroup;
	editCommentForm: FormGroup;
	fb: FormBuilder;
	releaseLinkPanelOpenState: boolean = false;
	usLinkPanelOpenState: boolean = false;
	taskLinkPanelOpenState: boolean = false;
	defectLinkPanelOpenState: boolean = false;
	tpLinkPanelOpenState: boolean = false;
	allAssociatedUserStoryChecked: boolean = false;
	allAssociatedTaskChecked: boolean = false;
	allAssociatedDefectChecked: boolean = false;
	allAssociatedTestPlanChecked: boolean = false;
	allUnassociateChecked: boolean = false;
	fromDate: Date;
	toDate: Date;
	isLoading: boolean = false;
	isAddCommentEditorShown: boolean = false;
	isAddCommentInputShown: boolean = true;
	isAddDescriptionEditorShown: boolean = false;
	comment: any;
	selectedTab = 1;
	isEditable: boolean = true;
	sprintStartDate: any;
	sprintEndDate: any;
	sprintDuration: any;
	sprintActivateForm: FormGroup;
	isActiveSprintDialogShow = true;
	sprintList: any;
	toBeCompleteSprintArray: any = [];
	pendingIssueForCompleteSprintCount: any;
	doneIssueForCompleteSprintCount: any;
	deletedDescriptionItems: any[] = [];
	deletedAttachmentItems: any[] = [];

	sprintStatusList = [{ name: 'Start Sprint', value: 1 }, { name: 'Complete Sprint', value: 2 }, { name: 'Completed', value: 4 }]
	durationList = [
		{
			name: "1 Week",
			value: "1 Week"
		},
		{
			name: "2 Weeks",
			value: "2 Weeks"
		},
		{
			name: "3 Weeks",
			value: "3 Weeks"
		},
		{
			name: "4 Weeks",
			value: "4 Weeks"
		},
		{
			name: "Custom",
			value: "Custom"
		}
	];
	isProjectUser: boolean;

	public myStartDatePickerOptions: IMyDpOptions = {
		// other options...
		dateFormat: 'dd-mm-yyyy',
		disableUntil: { year: 0, month: 0, day: 0 },
		disableSince: { year: 0, month: 0, day: 0 }

	};

	public myEndDatePickerOptions: IMyDpOptions = {
		// other options
		dateFormat: 'dd-mm-yyyy',
		disableUntil: { year: 0, month: 0, day: 0 },
		disableSince: { year: 0, month: 0, day: 0 }
	};
	isCompanyAdmin: boolean;
	isProjectManager: boolean;
	isOwner: boolean;
	minEndDate: Date;
	maxStartDate: Date;
	showError: boolean = false;
	connectedTo: any[];
	selectedSprintForMoveItem: any;

	constructor(
		private restService: RestService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private _location: Location,
		private broadcastService: BroadcastService,
		private fileUploadService: FileUploadService,
		private dateService: DateConverterService) {
		this.fb = formBuilder;
		// Check for route params

		if (this.pid && this.pid !== null && this.pid !== undefined) {
			this.getProjectDetail();
			this.getSprintList();
		}
		this.route.params.subscribe(params => {

			this.sid = params['sid'];
			if (this.sid && this.sid !== null && this.sid !== undefined) {
				this.getSprint();
			}
		});

		if (this.userData) {
			this.getUserprofile();
		}

		this.createForm(null);
		this.createSprintForm();

		this.getUnassociated();

		this.broadcastService.subscribe("RELOAD_ENTITY", (id) => {
			this.sid = id
			this.pid = localStorage.getItem('projectSelected');
			if (this.pid && this.sid && this.sid !== null && this.sid !== undefined) {
				this.getSprint();
			}
		});

		// this.getProjectUserList();
	}

	createForm(comment) {
		if (comment) {
			this.editCommentForm = this.fb.group({
				'comment': [comment],
			});
		} else {
			this.addCommentForm = this.fb.group({
				'comment': [''],
			});
			this.editCommentForm = this.fb.group({
				'comment': [''],
			});
		}
	}

	createSprintForm() {
		this.sprintActivateForm = this.formBuilder.group({
			'name': [this.sprintData && this.sprintData.name ? this.sprintData.name : '', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
			'goal': [''],
			'releaseId': [this.sprintData && this.sprintData.releaseId ? this.sprintData.releaseId : ''],
			'releaseName': [this.sprintData && this.sprintData.releaseTitle ? this.sprintData.releaseTitle : ''],
			'duration': ['', [Validators.required]],
			'fromDate': ['', [Validators.required]],
			'toDate': [''],
			'sprintId': [this.sprintData && this.sprintData.id ? this.sprintData.id : '']
		});

	}

	setCheckedVar() {
		this.sprintReleaseDisabled = true;
		this.sprintUserStoriesDisabled = true;
		this.sprintTaskDisabled = true;
		this.sprintDefectDisabled = true;
		this.sprintTestRunDisabled = true;

		let that = this;
		$('.sprint-Release-checkbox').find('input').each(function () {
			if ($(this).prop("checked")) {
				that.sprintReleaseDisabled = false;
			}
		});
		$('.sprint-UserStories-checkbox').find('input').each(function () {
			if ($(this).prop("checked")) {
				that.sprintUserStoriesDisabled = false;
			}
		});
		$('.sprint-Task-checkbox').find('input').each(function () {
			if ($(this).prop("checked")) {
				that.sprintTaskDisabled = false;
			}
		});
		$('.sprint-Defect-checkbox').find('input').each(function () {
			if ($(this).prop("checked")) {
				that.sprintDefectDisabled = false;
			}
		});
		$('.sprint-TestRun-checkbox').find('input').each(function () {
			if ($(this).prop("checked")) {
				that.sprintTestRunDisabled = false;
			}
		});



	}

	ngOnInit() {
		let that = this;
		var previousStartDate;
		var previousEndDate;

		$(document).ready(function () {

			$('.htmlEditor').summernote({
				height: '150px',
				focus: true,
				disableDragAndDrop: true,
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
				callbacks: {
					onPaste: function (e) {
						var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
						e.preventDefault();
						document.execCommand('insertText', false, bufferText);
					}
				}
			});



			// $('.fromDate input').datepicker({
			// 	format: 'dd-mm-yyyy',
			// 	autoclose: true,
			// 	todayHighlight: true,
			// 	// startDate:new Date()
			// }).on('changeDate', function (e) {

			// 	if (that.sprintData) {

			// 		var date = new Date(e.date.valueOf());
			// 		var strDate = that.dateService.convertInDDMMYYYFormate(date)
			// 		if (previousStartDate != strDate) {
			// 			previousStartDate = strDate;
			// 			that.sprintData.fromDate = strDate;
			// 			that.startDateChange(date);
			// 		}

			// 	}

			// });
			// $('.toDate input').datepicker({
			// 	format: 'dd-mm-yyyy',
			// 	autoclose: true,
			// 	todayHighlight: true,
			// 	// startDate:new Date()
			// }).on('changeDate', function (e) {
			// 	if (that.sprintData) {
			// 		var date = new Date(e.date.valueOf());
			// 		if (previousEndDate != that.dateService.convertInDDMMYYYFormate(date)) {
			// 			previousEndDate = that.dateService.convertInDDMMYYYFormate(date);
			// 			that.sprintData.toDate = that.dateService.convertInDDMMYYYFormate(date);
			// 			that.endDateChange(that.sprintData.toDate);
			// 		}


			// 	}

			// });
			// .on('changeDate', function (e) {
			// 	$('.datepicker').hide();
			// 	console.log("e.currentTarget.classList[1]", e.currentTarget.classList[1]);
			// 	if (e.currentTarget.classList[1] == 'startDate') {
			// 		var minDate = new Date(e.date.valueOf());

			// 		if (that.duration.value != 'Custom') {
			// 			that.setEndDate(new Date(e.date.valueOf()));

			// 		}
			// 		$('.endDate').datepicker('setStartDate', minDate);
			// 		$('.endDate').datepicker('minDate', minDate);
			// 	} else if (e.currentTarget.classList[1] == 'endDate') {
			// 		var maxDate = new Date(e.date.valueOf());

			// 		$('.startDate').datepicker('setEndDate', maxDate);
			// 		$('.startDate').datepicker('maxDate', maxDate);
			// 	}
			// 	//that.updateSprint(e.currentTarget.classList[1], e.format('dd-MM-yyyy'));
			// });

		});

		$(document).mouseup(function (e) {
			if (!$(".reporter-work").is(e.target) && $(".reporter-work").has(e.target).length === 0 && !$(".release-work-open").is(e.target) && $(".release-work-open").has(e.target).length === 0) {
				$(".release-work-open").slideUp("fast");
			}
		});

		// this.createForm(null);
		// this.getProjectList();
	}

	getUserprofile() {
		this.restService.hitApi('user/profile', { 'userId': this.userData.id }, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.userInfo = responseData.result;
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	getProjectDetail() {
		this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.projectData = responseData.result;
					var isOwner = localStorage.getItem("isOwner");
					if (isOwner == "true") {
						this.isOwner = true
						this.isProjectUser = false;
						this.isCompanyAdmin = false;
						this.isProjectManager = false;
					} else {
						if (this.userData.role == 1) {
							this.isCompanyAdmin = true;
							this.isOwner = false
							this.isProjectUser = false;
							this.isProjectManager = false;
						} else {
							if (this.projectData && this.projectData.projectUsersProject) {
								this.projectData.projectUsersProject.forEach(element => {
									if (element.userId == this.userData.id) {
										if (element.userRoleId == 1) {
											this.isCompanyAdmin = false;
											this.isOwner = false
											this.isProjectManager = true;
											this.isProjectUser = false;
										} else {
											this.isProjectUser = true;
											this.isProjectManager = false;
											this.isCompanyAdmin = false;
											this.isOwner = false
										}
									}
								});


							} else {
								this.isProjectUser = false;
								this.isOwner = false
								this.isProjectManager = false;
								this.isProjectUser = false;
							}
						}
					}
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	getSprintList() {

		this.restService.hitApi('sprints/list-backlog', { "projectId": this.pid }, 'post', '', false)
			.subscribe((result) => {
				this.isLoading = false;
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.sprintList = responseData.result;
					//this.connectedTo = [];
					//console.log("sprintList, ", responseData.result)
					// responseData.result.forEach(element => {
					// 	this.connectedTo.push(element.name);
					// });

					// this.connectedTo.push("backlog-List");

					// console.log("connectedTo, ", this.connectedTo)

				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}



	// changeProject(pid){
	// 	localStorage.setItem('projectSelected',pid)
	// 	$('.project-title-list').toggle("slide");
	// 	this.router.navigateByUrl('/project/'+pid+'/sprints');
	// }

	toggleList(ele, event) {
		this.descriptionFiles = [];
		if (ele == 'description') {

			if (event == 'down') {
				$('#sprint_description').summernote('code', this.sprintData.description);
				$('.' + ele).slideDown("fast");
				if (this.sprintData.descriptionAttachments && this.sprintData.descriptionAttachments.length > 0) {
					this.sprintData.descriptionAttachments.forEach(element => {
						this.descriptionFiles.push({ id: element.id, name: element.displayName, serverFileName: element.fileName, image: element.fileTitle, isProgressBarShown: false });
						//obj.imgArr.push(element.displayName);
					});
					// 	}
				} else {
					//$('.' + ele + '_' + obj.id).slideUp("fast");
					this.descriptionFiles = [];
					this.descriptionImgArr = [];

				}
			} else {
				$('.' + ele).slideUp("fast");

			}
			// $('.comment').slideDown("fast");
		}
		// else if (ele == 'comment') {
		// 	if (event == 'down') {
		// 		$('.htmlEditor').summernote('code', this.editCommentForm.get('comment').value);

		// 		$('.' + ele).slideDown("fast");
		// 	} else {

		// 		$('.' + ele).slideUp("fast");
		// 	}


		// }

	}
	toggleAddCommentList(ele, event, show) {
		let that = this;
		that.files = [];
		that.imgArr = [];
		$('.htmlEditor').summernote({
			height: '150px',
			focus: true,
			disableDragAndDrop: true,
			toolbar: [
				// [groupName, [list of button]]
				['style', ['bold', 'italic', 'underline']],
				['para', ['ul', 'ol', 'paragraph']]
			],
			callbacks: {
				onPaste: function (e) {
					var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
					e.preventDefault();
					document.execCommand('insertText', false, bufferText);
				}
			}
		});

		this.isAddCommentInputShown = show;
		$('#sprint_comment').summernote("reset");
		$('.htmlEditor').summernote('code', '');
		if (event == 'down') {
			$('.' + ele).slideDown("fast");
		} else {
			$('.' + ele).slideUp("fast");

		}
	}

	toggleUpdateCommentList(ele, event, obj) {
		let that = this;
		obj.files = [];
		obj.imgArr = [];
		$('.htmlEditor').summernote({
			height: '150px',
			focus: true,
			disableDragAndDrop: true,
			toolbar: [
				// [groupName, [list of button]]
				['style', ['bold', 'italic', 'underline']],
				['para', ['ul', 'ol', 'paragraph']]
			],
			callbacks: {
				onPaste: function (e) {
					var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
					e.preventDefault();
					document.execCommand('insertText', false, bufferText);
				}
			}
		});

		$('.htmlEditor').summernote('code', obj.comment);
		if (event == 'down') {
			$('.' + ele + '_' + obj.id).slideDown("fast");
			if (obj.attachments && obj.attachments.length > 0) {
				obj.attachments.forEach(element => {
					obj.files.push({ id: element.id, name: element.displayName, serverFileName: element.fileName, image: element.fileTitle, isProgressBarShown: false });
					//obj.imgArr.push(element.displayName);
				});
				// 	}
			} else {
				//$('.' + ele + '_' + obj.id).slideUp("fast");
				obj.files = [];
				obj.imgArr = [];

			}
		} else {
			$('.' + ele + '_' + obj.id).slideUp("fast");

		}
	}


	showedit() {
		if (!this.isProjectUser && !this.isSprintCompleted()) {
			$('.heading-editable h1').hide("fast");
			$('.heading-area').show("fast");
			$('.tick-cancel-input-filed').show("fast");
		}

	}
	hideedit() {
		if (!this.isProjectUser && !this.isSprintCompleted()) {
			$('.heading-editable h1').show("fast");
			$('.heading-area').hide("fast");
			$('.tick-cancel-input-filed').hide("fast");
		}
	}

	// createForm(data) {
	// 	if (data === null) {
	// 		this.sprintCreateForm = this.formBuilder.group({
	// 			'name': ['', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
	// 			'description': [''],
	// 			'fDate':['',[Validators.required]],
	// 			'tDate':[''],
	// 			'releaseId':['',[Validators.required]],
	// 			'status':['',[Validators.required]],
	// 			'attachments':[]
	// 		});

	// 		let date = new Date();
	// 		this.sprintCreateForm.patchValue({fromDate: {
	// 		date: {
	// 			year: date.getFullYear(),
	// 			month: date.getMonth() + 1,
	// 			day: date.getDate()}
	// 		}});
	// 		this.sprintCreateForm.patchValue({toDate: {
	// 			date: {
	// 				year: date.getFullYear(),
	// 				month: date.getMonth() + 1,
	// 				day: date.getDate()}
	// 		}});

	// 	}else{
	// 		this.sprintCreateForm = this.formBuilder.group({
	// 			'name': [data.name, [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
	// 			'description': [data.description],
	// 			'fDate':[data.fromDate,[Validators.required]],
	// 			'tDate':[data.toDate],
	// 			'releaseId':[data.releaseId,[Validators.required]],
	// 			'sprintId':[data.id],
	// 			'status':[data.status,[Validators.required]],
	// 			'attachments':[],
	// 			'oldattachments':[data.attachments]
	// 		});
	// 		let fromdate = new Date(data.fromDate);
	// 		this.sprintCreateForm.patchValue({fDate: {
	// 			date: {
	// 				year: fromdate.getFullYear(),
	// 				month: fromdate.getMonth() + 1,
	// 				day: fromdate.getDate()}
	// 		}});
	// 		let todate = new Date(data.toDate);
	// 		this.sprintCreateForm.patchValue({tDate: {
	// 			date: {
	// 				year: todate.getFullYear(),
	// 				month: todate.getMonth() + 1,
	// 				day: todate.getDate()}
	// 		}});
	// 	}

	// }

	updateSprint(field, value = "") {

		let data: any;
		if (field == 'name') {
			value = $('#sprint_' + field).val();

			if (value != "") {
				if (value != this.sprintData.name) {
					data = { 'name': value, 'sprintId': this.sprintId, 'projectId': this.pid };
				}
			} else {
				this.toastr.error("Sprint name is required");
				return
			}

		} else if (field == 'status') {
			if (value != this.sprintData.status) {
				data = { 'status': value, 'sprintId': this.sprintId, 'projectId': this.pid };
			}
		} else if (field == 'startDate') {
			if (value != this.sprintData.previousFromDate) {
				data = { 'startDate': value, 'sprintId': this.sprintId, 'projectId': this.pid };

			}

		} else if (field == 'endDate') {
			if (value != this.sprintData.previousToDate) {
				data = { 'endDate': value, 'sprintId': this.sprintId, 'projectId': this.pid };
			}

		} else if (field == 'description') {
			value = $('#sprint_' + field).val();
			data = { 'description': value, 'sprintId': this.sprintId, 'projectId': this.pid, 'files': this.descriptionImgArr };
		} else if (field == 'releaseId') {
			data = { 'releaseId': value, 'sprintId': this.sprintId, 'projectId': this.pid };
		} else if (field == 'duration') {
			if (value != this.sprintData.duration) {
				data = { 'duration': value, 'sprintId': this.sprintId, 'projectId': this.pid };
			}
		}
		if (data != null) {

			this.restService.hitApi('sprint/update', data, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.toastr.success(responseData.message);
						if (field == 'name') {
							$('.heading-editable h1').show("fast");
							$('.heading-area').hide("fast");
							$('.tick-cancel-input-filed').hide("fast");
						} else if (field == 'status') {
							$('.status-work-open').hide("fast");
							$('#update-status').modal('hide');
						} else if (field == 'startDate') {
							$('.datepicker').hide();
						} else if (field == 'description') {
							this.descriptionImgArr = [];
							this.descriptionFiles = [];
							$('.description').slideToggle("fast");
						} else if (field == 'releaseId') {
							$('.release-work-open').slideUp("fast");
						} else if (field == 'duration') {
							$('.duration-work-open').slideToggle("fast");
						}


						this.getSprint();
						// $('#create_release_popup_web').modal('hide');
						// this.files = [];
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		} else {
			if (field == 'name') {
				$('.heading-editable h1').show("fast");
				$('.heading-area').hide("fast");
				$('.tick-cancel-input-filed').hide("fast");
			}
		}
	}


	// updateDate() {
	// 	var fromDate = new Date();
	// 	if (this.sprintData.fromDate) {
	// 		fromDate = new Date(this.sprintData.fromDate);
	// 	}

	// 	var toDate = new Date();
	// 	if (this.duration.value?.name == "1 Week") {
	// 		toDate.setDate(fromDate.getDate() + 7);
	// 	} else if (this.duration.value?.name == "2 Weeks") {
	// 		toDate.setDate(fromDate.getDate() + 14);
	// 	} else if (this.duration.value?.name == "3 Weeks") {
	// 		toDate.setDate(fromDate.getDate() + 21);
	// 	} else if (this.duration.value?.name == "4 Weeks") {
	// 		toDate.setDate(fromDate.getDate() + 28);
	// 	}

	// 	this.sprintData.toDate = toDate;

	// }



	// updateSprint(){
	// 	this.sprintCreateForm.value.projectId = this.pid;
	// 	this.sprintCreateForm.value.fromDate = this.sprintCreateForm.value.fDate.date.year+"-"+this.sprintCreateForm.value.fDate.date.month+"-"+this.sprintCreateForm.value.fDate.date.day;
	// 	this.sprintCreateForm.value.toDate = this.sprintCreateForm.value.tDate.date.year+"-"+this.sprintCreateForm.value.tDate.date.month+"-"+this.sprintCreateForm.value.tDate.date.day;
	// 	this.sprintCreateForm.value.attachments = this.imgArr;
	// 	this.restService.hitApi('sprint/update',this.sprintCreateForm.value,'post','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.getSprint();
	// 			$('#create_edit_popup_web').modal('hide');
	// 			this.toastr.success(responseData.message);
	// 			this.files = [];
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})	
	// }

	getSprint() {
		this.isLoading = true;
		this.restService.hitApi('sprint/details', { 'sprintId': this.sid }, 'post', '', true)
			.subscribe((result) => {
				this.isLoading = false;
				let responseData: any = result;
				if (responseData.statusCode == 200) {

					this.sprintData = responseData.result;
					this.sprintId = this.sprintData.id;
					this.createSprintForm();
					// if (this.sprintData.duration == '' || this.sprintData.duration == null) {
					// 	this.duration.setValue(this.durationList[4]);
					// }

					if (this.sprintData.duration) {
						this.durationList.forEach(element => {
							if (element.value == this.sprintData.duration) {
								this.duration.setValue(element);

							}
						})
					}
					if (this.sprintData.toDate) {
						var d = Date.parse(this.sprintData.toDate);
						var toDate = new Date(d);
						var date: string = this.dateService.convertInDDMMYYYFormate(toDate);
						//this.sprintData.toDate = date;
						this.sprintData.previousToDate = date;
						this.sprintData.toDate = { date: { year: toDate.getFullYear(), month: toDate.getMonth() + 1, day: toDate.getDate() }, formatted: date, jsdate: toDate };
						this.disableStartSince(this.sprintData.toDate.jsdate);
						//$(".toDate").datepicker('setDate', date);

						// $('.startDate').datepicker('setEndDate', maxDate);
						// $('.startDate').datepicker('maxDate', maxDate);
					}
					if (this.sprintData.fromDate) {
						var d = Date.parse(this.sprintData.fromDate);
						var fromDate = new Date(d);
						var date: string = this.dateService.convertInDDMMYYYFormate(fromDate);
						//this.sprintData.fromDate = date;
						this.sprintData.previousFromDate = date;
						this.sprintData.fromDate = { date: { year: fromDate.getFullYear(), month: fromDate.getMonth() + 1, day: fromDate.getDate() }, formatted: date, jsdate: fromDate };
						//$(".fromDate").datepicker('setDate', date);
						var minDate = new Date(this.sprintData.fromDate);
						this.disableEndUntil(this.sprintData.fromDate.jsdate);


						console.log("this.sprintData.fromDate :", this.sprintData.fromDate);

						// if (this.sprintData.duration && this.sprintData.duration != 'custom') {
						// 	if (this.sprintData.fromDate && this.sprintData.fromDate.jsdate) {
						// 		this.setEndDate(this.sprintData.fromDate.jsdate)
						// 	}
						// }

						// $('.endDate').datepicker('setStartDate', minDate);
						// $('.endDate').datepicker('minDate', minDate);
					}


					if (this.sprintData.status) {
						if (this.sprintData.status == 4) {
							this.isEditable = false;
						} else {
							this.isEditable = true;
						}
						this.sprintStatusList.forEach(element => {
							if (element.value == this.sprintData.status) {
								this.sprintStatus.setValue(element);

							}
						})
					}


					console.log("this.sprintData.duration", this.sprintData.duration);
					console.log("this.duration", this.duration);
					if (this.sprintData && this.sprintData.releaseId && this.releaseList) {

						this.releaseList.forEach(element => {
							if (element.id == this.sprintData.releaseId) {
								console.log("id match: ", element.id);
								this.release.setValue(element);

							}


						});
					}

					$('.endDate').datepicker('setStartDate', new Date(this.sprintData.fromDate));
					// this.createForm(this.sprintData);
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			});

		this.restService.hitApi('release/list-dropdown?projectId=' + this.pid, '', 'get', '', true)
			.subscribe((result) => {
				console.log("release call");
				console.log("sprint data", this.sprintData);
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.releaseList = responseData.result
					if (this.sprintData && this.sprintData.releaseId && this.releaseList) {
						console.log("match: ", this.sprintData.releaseId);
						this.releaseList.forEach(element => {
							if (element.id == this.sprintData.releaseId) {
								console.log("id match: ", element.id);
								this.release.setValue(element);

							}
						});
						console.log("formControl", this.release);
					}
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	deleteSprint(sid) {
		this.restService.hitApi('sprint/delete', { 'sprintId': sid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('#delete-record').modal('hide')
					this.toastr.success(responseData.message);
					this.router.navigateByUrl('/sprints');
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	deleteCommentAttachment(files) {

		var json = {
			projectId: this.pid,
			entity: 'sprint',
			files: files
		}

		this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					//this.getTestplan();
				} else {

				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}




	deleteAttachment(aid, index) {
		this.restService.hitApi('sprints/delete-file', { "attachmentId": aid, "projectId": this.pid }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					// this.sprintCreateForm.value.oldattachments.splice(index,1);
					this.getSprint();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	//Image upload
	uploadFile(event) {
		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];
			if (this.restService.checkFileSize(file, 50)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				formData.append('entity', 'sprint');
				formData.append('entityId', this.sprintId);
				formData.append('file', file);
				var reader = new FileReader();
				const this_ = this;
				var obj;
				reader.onload = function (e: any) {
					obj = { serverFileName: file.name, name: file.name, image: e.target.result, isProgressBarShown: true }
					this_.files.push(obj);
				}

				reader.readAsDataURL(file);

				this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
					.subscribe((event: HttpEvent<any>) => {
						switch (event.type) {
							case HttpEventType.Sent:
								console.log('Request has been made!');
								break;
							case HttpEventType.ResponseHeader:
								console.log('Response header has been received!');
								break;
							case HttpEventType.UploadProgress:
								this.files[this.files.length - 1].progress = Math.round(event.loaded / event.total * 100);
								console.log(`Uploaded! ${this.files[this.files.length - 1].progress}%`);
								$('#add_comment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');

								break;
							case HttpEventType.Response:
								let responseData: any = event.body;
								if (responseData.statusCode == 200) {
									this.imgArr.push(responseData.result);
									this.files[this.files.length - 1].serverFileName = responseData.result.fileTitle;
									this.files[this.files.length - 1].displayName = responseData.result.displayName;
									this.files.forEach(element => {
										element.isProgressBarShown = false;
									});
								} else {
									if (responseData.message) {
										this.toastr.error(responseData.message);
									}
								}
								console.log('Upload successfully', event.body);
								setTimeout(() => {
									this.files[this.files.length - 1].progress = 0;
									$('#add_comment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');

								}, 1500);


						}
					})


				// this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', false, false)
				// 	.subscribe((result) => {
				// 		let responseData: any = result;
				// 		if (responseData.statusCode == 200) {
				// 			this.imgArr.push(responseData.result);
				// 			this.files[this.files.length - 1].serverFileName = responseData.result.fileTitle;
				// 			this.files[this.files.length - 1].displayName = responseData.result.displayName;
				// 			this_.files.forEach(element => {
				// 				element.isProgressBarShown = false;
				// 			});
				// 			//this.getSprint();
				// 		} else {
				// 			this.toastr.error(responseData.message);
				// 		}
				// 	}, (err) => {
				// 		console.log(err);
				// 		let errorData: any = err;
				// 		this.toastr.error(errorData.error.message);
				// 	})
			}
		}

	}

	uploadCommentAttachment(event, commentObj) {
		var i;
		for (let index = 0; index < event.length; index++) {
			i = index;
			let file: File = event[index];
			if (this.restService.checkFileSize(file, 50)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				formData.append('entity', 'sprint');
				formData.append('entityId', this.sid);
				formData.append('file', file);

				// const element = event[index];


				var reader = new FileReader();
				const this_ = this;
				var obj;
				reader.onload = function (e: any) {
					obj = { name: file.name, serverFileName: file.name, image: e.target.result, isProgressBarShown: true }
					commentObj.files.push(obj);
				}

				reader.readAsDataURL(file);

				this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
					.subscribe((event: HttpEvent<any>) => {
						switch (event.type) {
							case HttpEventType.Sent:
								console.log('Request has been made!');
								break;
							case HttpEventType.ResponseHeader:
								console.log('Response header has been received!');
								break;
							case HttpEventType.UploadProgress:
								commentObj.files[commentObj.files.length - 1].progress = Math.round(event.loaded / event.total * 100);
								console.log(`Uploaded! ${commentObj.files[commentObj.files.length - 1].progress}%`);
								$('#edit_comment_progress_bar_' + (commentObj.files.length - 1)).attr('aria-valuenow', commentObj.files[commentObj.files.length - 1].progress).css('width', commentObj.files[commentObj.files.length - 1].progress + '%');

								break;
							case HttpEventType.Response:
								let responseData: any = event.body;
								if (responseData.statusCode == 200) {
									commentObj.imgArr.push(responseData.result);
									commentObj.files[commentObj.files.length - 1].serverFileName = responseData.result.fileTitle;
									commentObj.files[commentObj.files.length - 1].displayName = responseData.result.displayName;
									commentObj.files.forEach(element => {
										element.isProgressBarShown = false;
									});
								} else {
									if (responseData.message) {
										this.toastr.error(responseData.message);
									}
								}
								setTimeout(() => {
									commentObj.files[commentObj.files.length - 1].progress = 0;
									$('#edit_comment_progress_bar_' + (commentObj.files.length - 1)).attr('aria-valuenow', commentObj.files[commentObj.files.length - 1].progress).css('width', commentObj.files[commentObj.files.length - 1].progress + '%');

								}, 1500);
						}
					})

				// this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', false, false)
				// 	.subscribe((result) => {
				// 		let responseData: any = result;
				// 		if (responseData.statusCode == 200) {

				// 			commentObj.imgArr.push(responseData.result);
				// 			//const fileObj = commentObj.files.find(element => element.name == obj.name);
				// 			commentObj.files[commentObj.files.length - 1].serverFileName = responseData.result.fileTitle;
				// 			commentObj.files[commentObj.files.length - 1].displayName = responseData.result.displayName;
				// 			commentObj.files.forEach(element => {
				// 				element.isProgressBarShown = false;
				// 			});

				// 		} else {
				// 			this.toastr.error(responseData.message);
				// 		}
				// 	}, (err) => {
				// 		console.log(err);
				// 		let errorData: any = err;
				// 		this.toastr.error(errorData.error.message);
				// 	})
			}
		}
	}

	uploadDescriptionFile(event) {
		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];
			if (this.restService.checkFileSize(file, 50)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				formData.append('entity', 'sprint');
				formData.append('entityId', this.sid);
				formData.append('file', file);
				var reader = new FileReader();
				const this_ = this;
				var obj;
				reader.onload = function (e: any) {
					obj = { serverFileName: file.name, name: file.name, image: e.target.result, isProgressBarShown: true }
					this_.descriptionFiles.push(obj);
				}

				reader.readAsDataURL(file);


				this.fileUploadService.uploadFile(formData, 'project/uploadSaveFile')
					.subscribe((event: HttpEvent<any>) => {
						switch (event.type) {
							case HttpEventType.Sent:
								console.log('Request has been made!');
								break;
							case HttpEventType.ResponseHeader:
								console.log('Response header has been received!');
								break;
							case HttpEventType.UploadProgress:
								this.descriptionFiles[this.descriptionFiles.length - 1].progress = Math.round(event.loaded / event.total * 100);
								console.log(`Uploaded! ${this.descriptionFiles[this.descriptionFiles.length - 1].progress}%`);
								$('#discription_progress_bar_' + (this.descriptionFiles.length - 1)).attr('aria-valuenow', this.descriptionFiles[this.descriptionFiles.length - 1].progress).css('width', this.descriptionFiles[this.descriptionFiles.length - 1].progress + '%');

								break;
							case HttpEventType.Response:
								let responseData: any = event.body;
								if (responseData.statusCode == 200) {
									this.descriptionImgArr.push(responseData.result);
									this.descriptionFiles[this.descriptionFiles.length - 1].serverFileName = responseData.result.fileTitle;
									this.descriptionFiles[this.descriptionFiles.length - 1].displayName = responseData.result.displayName;
									this_.descriptionFiles.forEach(element => {
										element.isProgressBarShown = false;
									});
									//this.getSprint();
								} else {
									if (responseData.message) {
										this.toastr.error(responseData.message);
									}
								}
								console.log('User successfully created!', event.body);
								setTimeout(() => {
									this.descriptionFiles[this.descriptionFiles.length - 1].progress = 0;
									$('#discription_progress_bar_' + (this.descriptionFiles.length - 1)).attr('aria-valuenow', this.descriptionFiles[this.descriptionFiles.length - 1].progress).css('width', this.descriptionFiles[this.descriptionFiles.length - 1].progress + '%');

								}, 1500);
						}
					})

				// this.restService.hitApi('project/uploadSaveFile', formData, 'post', '', false, false)
				// 	.subscribe((result) => {
				// 		let responseData: any = result;
				// 		if (responseData.statusCode == 200) {
				// 			this.descriptionImgArr.push(responseData.result);
				// 			this.descriptionFiles[this.descriptionFiles.length - 1].serverFileName = responseData.result.fileTitle;
				// 			this.descriptionFiles[this.descriptionFiles.length - 1].displayName = responseData.result.displayName;
				// 			this_.descriptionFiles.forEach(element => {
				// 				element.isProgressBarShown = false;
				// 			});
				// 			//this.getSprint();
				// 		} else {
				// 			this.toastr.error(responseData.message);
				// 		}
				// 	}, (err) => {
				// 		console.log(err);
				// 		let errorData: any = err;
				// 		this.toastr.error(errorData.error.message);
				// 	})
			}
		}

	}

	saveFile() {
		var fileArray = [];
		if (this.files) {
			this.files.forEach(element => {
				if (element.displayName) {
					fileArray.push({ 'fileTitle': element.serverFileName, 'displayName': element.displayName });
				}
				else {
					fileArray.push(element.serverFileName);
				}
			});
		}
		if (fileArray.length > 0) {
			var json = {
				projectId: this.pid,
				entity: "sprint",
				entityId: this.sprintId,
				files: fileArray
			}

			this.restService.hitApi('project/justSaveFiles', json, 'post', '', true, false)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.getSprint();
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}
	}



	removeAll() {
		this.deletedAttachmentItems = [];
		this.files.forEach(element => {
			this.deletedAttachmentItems.push(element.serverFileName);
		});

		if (this.deletedAttachmentItems.length > 0) {
			this.removeAttachmentFromServer(null, null);
		}
	}

	removeDescriptionAttachment(fileObj, index) {
		//var fileObj = this.files[index];
		this.deletedDescriptionItems.push(fileObj.serverFileName);
		this.descriptionFiles.splice(index, 1);
		this.descriptionImgArr.splice(index, 1);

	}

	updateDescriptionAttachment() {
		if (this.deletedDescriptionItems && this.deletedDescriptionItems.length > 0) {
			this.removeDescriptionAttachmentFromServer();
		} else {
			this.updateSprint('description');
		}
	}

	updateAttachment(obj, field) {
		if (this.deletedAttachmentItems && this.deletedAttachmentItems.length > 0) {
			this.removeAttachmentFromServer(obj, field);
		} else {
			this.addComment(obj, field, true);
		}
	}

	removeDescriptionAttachmentFromServer() {

		var json = {
			projectId: this.pid,
			entity: 'sprint',
			files: this.deletedDescriptionItems
		}

		this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.deletedDescriptionItems = [];
					this.updateSprint('description');
				} else {
					this.toastr.success(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	removeAttachment(fileObj, index, commentObj) {

		this.deletedAttachmentItems.push(fileObj.serverFileName)

		if (commentObj != undefined && commentObj != null) {
			commentObj.files.splice(index, 1);
			commentObj.imgArr.splice(0, 1);

		} else {
			this.files.splice(index, 1);
			this.imgArr.splice(0, 1);
		}

	}

	removeAttachmentFromServer(obj, field) {
		var json = {
			projectId: this.pid,
			entity: 'sprint',
			files: this.deletedAttachmentItems
		}
		this.restService.hitApi('project/deleteUploadedFilesEntity', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.deletedAttachmentItems = [];
					if (obj && field) {
						this.addComment(obj, field, true);
					}
				} else {
					this.toastr.success(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	checkUncheckAll(ele, entity) {
		entity = entity.split(" ").join("")
		var checkboxes = $(".sprint-" + entity + "-checkbox input");
		if (ele) {
			for (var i = 0; i < checkboxes.length; i++) {
				checkboxes[i].checked = true;
			}
		} else {
			for (var i = 0; i < checkboxes.length; i++) {
				checkboxes[i].checked = false;
			}
		}
	}




	getUnassociated() {

		this.restService.hitApi('sprints/getUnAssociatedSprint', { "projectId": this.pid, "sprintId": this.sprintId, "associated": 0 }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getUnassociatedData = responseData.result;
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	openUnAssociatedList(entity) {
		this.selectedEntity = entity;
		if (entity == 'Release') {
			this.unassociatedData = this.getUnassociatedData['releases'];
		} else if (entity == 'User Stories') {
			this.unassociatedData = this.getUnassociatedData['userStories'];
		} else if (entity == 'Task') {
			this.unassociatedData = this.getUnassociatedData['tasks'];
		} else if (entity == 'Defect') {
			this.unassociatedData = this.getUnassociatedData['defects'];
		} else if (entity == 'Test Plan') {
			this.unassociatedData = this.getUnassociatedData['projectTestPlanes'];
		}
	}

	closeLinkModal() {
		this.selectedCheckboxArr = [];
		this.unassociatedData = [];
		$('.table-multiple-checkboxes input:checkbox').removeAttr('checked');
	}

	selectonlyOne(selected) {
		console.log(selected)
		$('.table-multiple-checkboxes input:checkbox').removeAttr('checked');
		$('#' + selected).prop("checked", true);
	}

	associateToSprint(entity) {
		this.selectedCheckboxArr = [];
		let taskName: any = "";
		if (entity == 'Release') taskName = 'release';
		if (entity == 'Task') taskName = 'task';
		if (entity == 'User Stories') taskName = 'userStory';
		if (entity == 'Defect') taskName = 'defect';
		if (entity == 'Test Plan') taskName = 'testPlan';
		// let that = this;
		// $('.sprint-unassociated-checkbox').find('input').each(function () {
		// 	if ($(this).prop("checked")) {
		// 		that.selectedCheckboxArr.push($(this).val());
		// 	}
		// });
		if (this.unassociatedData) {
			this.unassociatedData.forEach(element => {
				if (element.checked) {
					this.selectedCheckboxArr.push(element.id);
				}
			});
		}

		this.restService.hitApi('sprints/associateSprint', { "projectId": this.pid, "sprintId": this.sprintId, "entity": taskName, "id": this.selectedCheckboxArr, "set": 1 }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('#create_add-link_popup_web').modal('hide');
					this.selectedCheckboxArr = [];
					this.unassociatedData = [];
					this.allUnassociateChecked = false;
					$('.table-multiple-checkboxes input:checkbox').removeAttr('checked');
					this.getSprint();
					this.getUnassociated();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	unassociateToSprint(entity) {
		this.selectedCheckboxArr = [];
		console.log(entity);
		let entityname: any = "";
		if (entity == 'Release') entityname = "release";
		else if (entity == 'Defect') entityname = "defect";
		else if (entity == 'User Stories') entityname = "userStory";
		else if (entity == 'Task') entityname = "task";
		else if (entity == 'Test Plan') entityname = "testPlan";

		if (entityname == 'release') {
			this.selectedCheckboxArr.push(this.sprintData?.projectsRelease.id);
		} else if (entityname == 'userStory') {
			if (this.sprintData?.userStory) {
				this.sprintData?.userStory.forEach(element => {
					if (element.checked) {
						this.selectedCheckboxArr.push(element.id);
					}
				});
			}
		} else if (entityname == 'task') {
			if (this.sprintData?.projectTask) {
				this.sprintData?.projectTask.forEach(element => {
					if (element.checked) {
						this.selectedCheckboxArr.push(element.id);
					}
				});
			}

		} else if (entityname == 'defect') {
			if (this.sprintData?.projectBug) {
				this.sprintData?.projectBug.forEach(element => {
					if (element.checked) {
						this.selectedCheckboxArr.push(element.id);
					}
				});
			}
		} else if (entityname == 'testPlan') {
			if (this.sprintData?.projectTestPlan) {
				this.sprintData?.projectTestPlan.forEach(element => {
					if (element.checked) {
						this.selectedCheckboxArr.push(element.id);
					}
				});
			}
		}

		// let chechbox = 'sprint-' + entity.split(" ").join("") + '-checkbox';
		// console.log(chechbox)
		// let that = this;
		// $('.' + chechbox).find('input').each(function () {
		// 	if ($(this).prop("checked")) {
		// 		that.selectedCheckboxArr.push($(this).val());
		// 	}
		// });
		let data: any;
		if (entity == 'Release')
			data = { "projectId": this.pid, "sprintId": this.sprintId, "entity": entityname, "id": this.selectedCheckboxArr, "set": 0 };
		else
			data = { "projectId": this.pid, "sprintId": this.sprintId, "entity": entityname, "id": this.selectedCheckboxArr, "set": 0 };

		if (this.selectedCheckboxArr.length) {
			this.restService.hitApi('sprints/associateSprint', data, 'post', '', true, false)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.selectedCheckboxArr = [];
						this.unassociatedData = [];
						this.allAssociatedUserStoryChecked = false;
						this.allAssociatedTaskChecked = false;
						this.allAssociatedDefectChecked = false;
						this.allAssociatedTestPlanChecked = false;
						$('.table-multiple-checkboxes input:checkbox').removeAttr('checked');
						this.getSprint();
						this.getUnassociated();
						this.toastr.success(responseData.message);
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		} else {
			this.toastr.error('Please select ' + entity + ' to remove.');
		}
	}

	addComment(obj, field, shown) {

		let data: any;
		let comment: any;
		let apiname: any;
		if (obj?.id) {
			apiname = 'sprint/comment/update';
			comment = $('#sprint_edit_' + field + obj?.id).val();
			//comment = this.editCommentForm.get('comment').value;
			data = { 'sprintCommentId': obj?.id, 'comment': comment, 'files': obj.imgArr, 'projectId': this.pid }
		} else {
			apiname = 'sprint/comment/add';
			comment = $('#sprint_' + field).val();
			//comment = this.addCommentForm.get('comment').value;
			data = { 'sprintId': this.sprintId, 'comment': comment, 'files': this.imgArr, 'projectId': this.pid }
		}



		this.restService.hitApi(apiname, data, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					if (obj?.id) {
						$('#comment' + obj?.id).show();
						$('#commentinput' + obj?.id).hide();
					} else {
						$('#comment').val('');
						$('#sprint_comment').summernote("reset");
						//this.toggleAddCommentList(field, 'up', true);
						$('.' + field).slideUp("fast");
						this.files = [];
						this.imgArr = [];
						this.isAddCommentInputShown = shown;
					}
					if (obj?.id) {
						this.editCommentForm.reset();
					} else {
						this.addCommentForm.reset();
					}
					this.getSprint();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	deleteComment(sprintCommentId) {
		this.restService.hitApi('sprint/comment/delete', { "sprintCommentId": sprintCommentId }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getSprint();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	getWatchesUserList() {
		this.restService.hitApi('project/watchlist', { 'projectId': this.pid, 'entity': 'project_sprint', 'resourceId': this.sprintId }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.watchesUserList = responseData.result;
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	addRemoveWatch() {
		let selectedCheckboxArr: any = [];
		$('.user-watch-checkbox').find('input').each(function () {
			if ($(this).prop("checked")) {
				selectedCheckboxArr.push($(this).val());
			}
		});

		this.restService.hitApi('project/updateWatchlist', { 'projectId': this.pid, 'entity': 'project_sprint', 'resourceId': this.sprintId, "userId": selectedCheckboxArr, "action": 'manage' }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getWatchesUserList();
					this.getSprint();
					$('#watches_popup_web').modal('hide');
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	AddRemoveCurrentUserWatcher(action) {
		this.restService.hitApi('project/updateWatchlist', { 'projectId': this.pid, 'entity': 'project_sprint', 'resourceId': this.sprintId, "action": action }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('.stop-add-watches').slideUp('fast');
					this.getSprint();
					this.getWatchesUserList();
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	// User Story
	updateAllAssociateUserStory() {
		this.allAssociatedUserStoryChecked = this.sprintData?.userStory != null && this.sprintData?.userStory.every(t => t.checked);
	}

	someAssociateUserStoryComplete(): boolean {
		if (this.sprintData?.userStory == null) {
			return false;
		}
		return this.sprintData?.userStory.filter(t => t.checked).length > 0 && !this.allAssociatedUserStoryChecked;
	}

	setAllAssociateUserStory(checked: boolean) {
		this.allAssociatedUserStoryChecked = checked;
		if (this.sprintData?.userStory == null) {
			return;
		}
		this.sprintData?.userStory.forEach(t => t.checked = checked);
	}

	// Task
	updateAllAssociateTask() {
		this.allAssociatedTaskChecked = this.sprintData?.projectTask != null && this.sprintData?.projectTask.every(t => t.checked);
	}

	someAssociateTaskComplete(): boolean {
		if (this.sprintData?.projectTask == null) {
			return false;
		}
		return this.sprintData?.projectTask.filter(t => t.checked).length > 0 && !this.allAssociatedTaskChecked;
	}

	setAllAssociateTask(checked: boolean) {
		this.allAssociatedTaskChecked = checked;
		if (this.sprintData?.projectTask == null) {
			return;
		}
		this.sprintData?.projectTask.forEach(t => t.checked = checked);
	}


	// Defect
	updateAllAssociateDefect() {
		this.allAssociatedDefectChecked = this.sprintData?.projectBug != null && this.sprintData?.projectBug.every(t => t.checked);
	}

	someAssociateDefectComplete(): boolean {
		if (this.sprintData?.projectBug == null) {
			return false;
		}
		return this.sprintData?.projectBug.filter(t => t.checked).length > 0 && !this.allAssociatedDefectChecked;
	}

	setAllAssociateDefect(checked: boolean) {
		this.allAssociatedDefectChecked = checked;
		if (this.sprintData?.projectBug == null) {
			return;
		}
		this.sprintData?.projectBug.forEach(t => t.checked = checked);
	}

	// Test Plan
	updateAllAssociateTestPlan() {
		this.allAssociatedTestPlanChecked = this.sprintData?.projectTestPlan != null && this.sprintData?.projectTestPlan.every(t => t.checked);
	}

	someAssociateTestPlanComplete(): boolean {
		if (this.sprintData?.projectTestPlan == null) {
			return false;
		}
		return this.sprintData?.projectTestPlan.filter(t => t.checked).length > 0 && !this.allAssociatedTestPlanChecked;
	}

	setAllAssociateTestPlan(checked: boolean) {
		this.allAssociatedTestPlanChecked = checked;
		if (this.sprintData?.projectTestPlan == null) {
			return;
		}
		this.sprintData?.projectTestPlan.forEach(t => t.checked = checked);
	}

	updateAllUnassociate() {
		this.allUnassociateChecked = this.unassociatedData != null && this.unassociatedData.every(t => t.checked);
	}

	someUnassociateComplete(): boolean {
		if (this.unassociatedData == null) {
			return false;
		}
		return this.unassociatedData.filter(t => t.checked).length > 0 && !this.allUnassociateChecked;
	}

	setAllUnassociate(checked: boolean) {
		this.allUnassociateChecked = checked;
		if (this.unassociatedData == null) {
			return;
		}
		this.unassociatedData.forEach(t => t.checked = checked);
	}

	resetAllUnassociate() {
		this.allUnassociateChecked = false;
		this.unassociatedData.forEach(t => t.checked = false);

	}

	unCheckAllRemainingReleaseList(release) {
		if (this.unassociatedData) {
			this.unassociatedData.forEach(element => {
				if (element.id != release.id) {
					element.checked = false;
				}
			});
		}

	}
	download(url, fileName, $event) {
		$event.stopPropagation();
		this.restService.downloadFile(url, fileName);
	}

	getUnclosedData(sprint) {
		let data: any = { 'projectId': this.pid, 'sprintId': this.sprintId };
		this.restService.hitApi('sprint/getNotCloseSprint', data, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.unclosedData = responseData.result;
					this.pendingIssueForCompleteSprintCount = this.sprintData.pendingStatusCount;
					this.doneIssueForCompleteSprintCount = this.sprintData.completedStatusCount;
					//this.pendingIssueForCompleteSprintCount = this.unclosedData?.userStory?.length + this.unclosedData?.defect?.length + this.unclosedData?.task?.length + this.unclosedData?.projectTestPlane?.length;

					// if (this.sprintData.statusCount.length > 0) {
					// 	this.sprintData.statusCount.forEach(element => {
					// 		if (element.status == 4) {
					// 			this.doneIssueForCompleteSprintCount = element.total;
					// 		}
					// 	});
					// } else {
					// 	this.doneIssueForCompleteSprintCount = 0;
					// }

					this.toBeCompleteSprintArray = [];
					for (let i = 0; i < this.sprintList.length; i++) {
						let obj = this.sprintList[i]
						if (sprint?.id == obj.id) {
						} else {
							let lobj = { "name": obj.name, "value": obj.id }
							this.toBeCompleteSprintArray.push(lobj)
						}
					}
					if (this.unclosedData?.userStory?.length || this.unclosedData?.defect?.length || this.unclosedData?.task?.length || this.unclosedData?.projectTestPlane?.length) {
						$('#update-status').modal('hide');
						$('#create_close-sprint').modal('show');
					} else {
						$('#update-status').modal('show');
						$('#create_close-sprint').modal('hide');
						// this.updateSprint('status', '4');
					}

				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	selectedSprintForMoveIssues(event) {
		if (event != null) {
			this.selectedSprintForMoveItem = event.target.value
		}

		console.log("selectedSprintForMoveItem ", this.selectedSprintForMoveItem);
	}

	closeSprint(sid) {
		let data: any = { 'projectId': this.pid, 'sprintId': sid };
		if (this.selectedSprintForMoveItem != 0) {
			data["newSprintId"] = this.selectedSprintForMoveItem
		}
		this.restService.hitApi('sprint/closeSprint', data, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					//this.updateSprint('status')
					$('#update-status').modal('hide');
					$('#create_close-sprint').modal('hide');
					this.getSprint();
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	isSprintCompleted() {
		if (this.sprintStatus.value?.value == 4) {
			return true;
		}
		return false;
	}
	startDateChange(event) {
		console.log(event);
		var strDate;
		if (event.epoc > 0) {
			var date = event.jsdate;
			strDate = this.dateService.convertInDDMMYYYFormate(date)
			if (strDate != this.sprintData.previousFromDate) {

				this.sprintData.fromDate = { date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }, formatted: strDate, jsdate: date };;
				this.myEndDatePickerOptions.disableUntil = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
				if (this.duration.value.value != 'Custom') {
					this.setEndDate(date);



				}
			}
		} else {
			this.sprintData.fromDate = null;
			strDate = null;

		}
		this.disableEndUntil(event.jsdate);
		this.updateSprint('startDate', strDate);
	}
	endDateChange(event) {
		if (this.duration.value.value == 'Custom') {
			if (event.epoc > 0) {
				var date = event.jsdate;
				var strDate = this.dateService.convertInDDMMYYYFormate(date)
				this.sprintData.toDate = { date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }, formatted: strDate, jsdate: date };;

			} else {
				this.sprintData.toDate = null;
			}
			this.disableStartSince(event.jsdate);
			this.updateSprint('endDate', strDate);
		}
	}
	setEndDate(startDate) {
		console.log("selcted start date-", startDate)
		if (this.duration.value.value == '1 Week') {
			var endDate = this.dateService.getDateFromDay(startDate, 7);
		} else if (this.duration.value.value == '2 Weeks') {
			var endDate = this.dateService.getDateFromDay(startDate, 14);
		} else if (this.duration.value.value == '3 Weeks') {
			var endDate = this.dateService.getDateFromDay(startDate, 21);
		} else if (this.duration.value.value == '4 Weeks') {
			var endDate = this.dateService.getDateFromDay(startDate, 28);
		}


		console.log("selcted end date-", this.dateService.convertInDDMMYYYFormate(new Date(endDate)));
		var strToDate = this.dateService.convertInDDMMYYYFormate(new Date(endDate));
		this.sprintData.toDate = { date: { year: new Date(endDate).getFullYear(), month: new Date(endDate).getMonth() + 1, day: new Date(endDate).getDate() }, formatted: strToDate, jsdate: new Date(endDate) };;

		this.disableStartSince(this.sprintData.toDate.jsdate);
		this.updateSprint('endDate', strToDate);


	}


	changeDuration(durationObj, isNotUpdateApiCall) {
		if (durationObj?.value != this.sprintData.duration) {
			this.updateSprint('duration', durationObj?.value);
			if (durationObj?.value != 'custom') {
				if (this.sprintData.fromDate) {
					this.setEndDate(this.sprintData.fromDate.jsdate);

				}
			}
		}


	}


	sprintStartDateChange(event) {
		console.log(event);
		var duration = this.sprintActivateForm.get('duration').value;

		var strDate;
		if (event.epoc > 0) {
			var date = event.jsdate;
			strDate = this.dateService.convertInDDMMYYYFormate(date)
			var fromDate = { date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }, formatted: strDate, jsdate: date };
			this.sprintActivateForm.get('fromDate').setValue(fromDate);
			console.log("sprintStartDateAfterSelection", this.sprintActivateForm.get('fromDate').value);

			//this.myEndDatePickerOptions.disableUntil = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
			if (duration != 'Custom') {
				this.setSprintEndDate(date);
			}
		} else {
			this.sprintActivateForm.get('fromDate').setValue('');
			strDate = null;

		}
		//this.sprintActivateForm.get('fromDate').setValue(this.sprintStartDate);
		this.disableEndUntil(event.jsdate);
		//this.updateSprint('startDate', strDate);
	}
	sprintEndDateChange(event) {
		if (this.sprintDuration.value.value == 'Custom') {
			if (event.epoc > 0) {
				var date = event.jsdate;
				var strDate = this.dateService.convertInDDMMYYYFormate(date)
				var toDate = { date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }, formatted: strDate, jsdate: date };;
				this.sprintActivateForm.get('toDate').setValue(toDate);

			} else {
				this.sprintActivateForm.get('toDate').setValue('');
				//this.sprintEndDate = null;
			}
			//this.sprintActivateForm.get('toDate').setValue(this.sprintEndDate);
			this.disableStartSince(event.jsdate);
		}
	}
	setSprintEndDate(startDate) {
		var endDate: Date = new Date(JSON.parse(JSON.stringify(startDate)));
		var duration = this.sprintActivateForm.get('duration').value;
		console.log("selcted start date-", startDate)
		console.log("if start is same as endDate", startDate == endDate)

		if (duration == '1 Week') {
			endDate = this.dateService.getDateFromDay(endDate, 7);
		} else if (duration == '2 Weeks') {
			var endDate: Date = this.dateService.getDateFromDay(endDate, 14);
		} else if (duration == '3 Weeks') {
			var endDate: Date = this.dateService.getDateFromDay(endDate, 21);
		} else if (duration == '4 Weeks') {
			var endDate: Date = this.dateService.getDateFromDay(endDate, 28);
		}


		console.log("selcted end date-", this.dateService.convertInDDMMYYYFormate(new Date(endDate)));
		var strToDate = this.dateService.convertInDDMMYYYFormate(new Date(endDate));
		this.sprintEndDate = { date: { year: new Date(endDate).getFullYear(), month: new Date(endDate).getMonth() + 1, day: new Date(endDate).getDate() }, formatted: strToDate, jsdate: new Date(endDate) };;
		this.sprintActivateForm.get('toDate').setValue(this.sprintEndDate);
		console.log("sprintEndDate", this.sprintEndDate);
		console.log("sprintStartDate", startDate);
		this.disableStartSince(this.sprintEndDate.jsdate);


	}


	// changeSprintDuration(durationObj) {
	// 	//this.updateSprint('duration', durationObj?.value);
	// 	if (durationObj?.value != 'custom') {
	// 		if (this.sprintStartDate) {
	// 			this.setSprintEndDate(this.sprintStartDate.jsdate);

	// 		}
	// 	}

	// }

	getSelectedDuration(obj: Data) {

		console.log("duration call back call");
		if (obj) {
			this.sprintActivateForm.get('duration').setValue(obj.value);
			this.sprintDuration = obj.value;
			if (obj?.value != 'custom') {
				if (this.sprintActivateForm.get('fromDate').value != null && this.sprintActivateForm.get('fromDate').value != '') {
					if (this.sprintActivateForm.get('fromDate').value != this.sprintData.previousFromDate) {
						console.log("startData: ", this.sprintActivateForm.get('fromDate').value.jsdate);
						var date = this.sprintActivateForm.get('fromDate').value.jsdate;
						var strDate = this.dateService.convertInDDMMYYYFormate(date)
						var fromDate = { date: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }, formatted: strDate, jsdate: date };
						this.setSprintEndDate(date);

					}

				}
			}
			console.log("selectedRole" + obj.value);
			console.log("selectedDuration" + this.sprintActivateForm.get('duration').value);

		}

	}

	getExtensionOfFileType(fileName) {
		if (fileName) {
			var str = fileName.split(".");
			if (str[str.length - 1] == 'jpg' || str[str.length - 1] == 'png' || str[str.length - 1] == 'jpeg' || str[str.length - 1] == 'JPG' || str[str.length - 1] == 'PNG') {
				return 'image'
			} else {
				return 'file'
			}

		}
	}

	disableEndUntil(startDate) {
		if (startDate) {
			startDate.setDate(startDate.getDate() - 1);
			let copy = this.getCopyOfEndDateOptions();
			copy.disableUntil = {
				year: startDate.getFullYear(),
				month: startDate.getMonth() + 1,
				day: startDate.getDate()
			};
			this.myEndDatePickerOptions = copy;
		} else {
			let copy = this.getCopyOfEndDateOptions();
			copy.disableUntil = {
				year: 0,
				month: 0,
				day: 0
			};
			this.myEndDatePickerOptions = copy;
		}
	}

	// Returns copy of myOptions
	getCopyOfEndDateOptions(): IMyDpOptions {
		return JSON.parse(JSON.stringify(this.myEndDatePickerOptions));
	}

	disableStartSince(endDate) {
		if (endDate) {
			endDate.setDate(endDate.getDate() - 1);
			let copy = this.getCopyOfStartDateOptions();
			copy.disableSince = {
				year: endDate.getFullYear(),
				month: endDate.getMonth() + 1,
				day: endDate.getDate()
			};
			this.myStartDatePickerOptions = copy;
		} else {
			let copy = this.getCopyOfStartDateOptions();
			copy.disableSince = {
				year: 0,
				month: 0,
				day: 0
			};
			this.myStartDatePickerOptions = copy;
		}
	}

	// Returns copy of myOptions
	getCopyOfStartDateOptions(): IMyDpOptions {
		return JSON.parse(JSON.stringify(this.myStartDatePickerOptions));
	}

	startSprint() {
		this.updateSprint('status', "2");
		this.updateSprint('startDate', this.sprintData.fromDate.formatted);
		this.updateSprint('endDate', this.sprintData.toDate.formatted);
	}
	closeActiveSprintModal() {
		this.sprintActivateForm.reset();
		this.isActiveSprintDialogShow = false;
		this.showError = false;

	}

	activateSprint() {
		this.sprintActivateForm.value.projectId = this.pid;
		this.sprintActivateForm.value.fromDate = this.sprintActivateForm.get('fromDate').value.formatted;
		this.sprintActivateForm.value.toDate = this.sprintActivateForm.get('toDate').value.formatted;
		//this.sprintActivateForm.value.toDate = this.toDate;
		console.log(this.sprintActivateForm.value);
		if (this.sprintActivateForm.invalid) {
			this.showError = true;
			console.log("this.sprintActivateForm.invalid");

			return true;
		} else {
			this.showError = false;
			this.restService.hitApi('sprint/activate', this.sprintActivateForm.value, 'post', '', true, false)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {

						$('#create_activate-sprint').modal('hide');
						this.closeActiveSprintModal();
						this.getSprint();
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}
	}

	getRelease(obj) {

		if (obj) {
			this.sprintActivateForm.get('releaseId').setValue(parseInt(obj.id));
			this.sprintActivateForm.get('releaseName').setValue(parseInt(obj.name));

		}
	}

	backToRetun() {
		this._location.back();
	}

	isSprintEditable() {
		if (this.sprintData.isBlocked == true) {
			return false
		} else {
			return true
		}
	}

}

