import { Component, OnInit, Input, Self, Optional, forwardRef, OnDestroy, ViewChild, AfterViewInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  message: string;

  constructor() { }

  ngOnInit() {
  }

}
