import { Component, OnInit } from '@angular/core';
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from '../app.component';
import { FlexAlignStyleBuilder } from 'node_modules1111/@angular/flex-layout/typings';

declare const $: any;
declare const noUiSlider: any;

@Component({
  selector: 'app-test-price-calculator',
  templateUrl: './test-price-calculator.component.html',
  styleUrls: ['./test-price-calculator.component.scss']
})
export class TestPriceCalculatorComponent implements OnInit {
  userData: any = JSON.parse(localStorage.getItem("userData"));
  userCountData: any;
  storageCountData: any = [];
  isLoading: boolean = false;
  employeesObj: any;

  constructor(private restService: RestService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private appComponent: AppComponent) { }

  ngOnInit() { 
	// this.getPriceData();
	// this.getStrogeData();


  	$(document).ready(function () {

		this.employeesObj = document.getElementById('employees');
		var labels = { 0: '0', 10: '10', 15: '15', 20: '20', 25: '25', 40: '40', 55: '55', 70: '70', 85: '85', 100: '100', 110: '100+', };
		noUiSlider.create(this.employeesObj, {
			start: 10,
			connect: [true, false],
			tooltips: {
				to: function (value) {
					return value > 100 ? '100+' : parseInt(value)
				}
			},
			range: {
				'min': 0,
				'10%': 10,
				'20%': 15,
				'30%': 20,
				'40%': 25,
				'50%': 40,
				'60%': 55,
				'70%': 70,
				'80%': 85,
				'90%': 100,
				'max': 110
			},
			pips: {
				mode: 'steps',
				filter: function (value, type) {
					return type === 0 ? -1 : 1;
				},
				format: {
					to: function (value) {
						return labels[value];
					}
				}
			}
		});		

      const stroage = document.querySelector("#stroage")
      var labels = { 0: '0', 10: '10', 15: '15', 20: '20', 25: '25', 40: '40', 55: '55', 70: '70', 85: '85', 100: '100', 110: '100+', };
      noUiSlider.create(stroage, {
        start: 10,
        connect: [true, false],
        tooltips: {
          to: function (value) {
            return value > 100 ? '100+' : parseInt(value)
          }
        },
        range: {
          'min': 0,
          '10%': 10,
          '20%': 15,
          '30%': 20,
          '40%': 25,
          '50%': 40,
          '60%': 55,
          '70%': 70,
          '80%': 85,
          '90%': 100,
          'max': 110
        },
        pips: {
          mode: 'steps',
          filter: function (value, type) {
            return type === 0 ? -1 : 1;
          },
          format: {
            to: function (value) {
              return labels[value];
            }
          }
        }
      });

  	})
  }

  getPriceData() {
	this.restService.hitApi('users/userPackage', null, 'post', '', true)
	.subscribe((result) => {
		this.isLoading = false;
		let responseData: any = result;
		if (responseData.statusCode == 200) {
			console.log("responseData.result", responseData.result)
			this.userCountData = responseData.result;
			this.setUserData();
		} else {
			this.toastr.error(responseData.message);
		}

	}, (err) => {
		this.isLoading = false;
		console.log(err);
		let errorData: any = err;
	})
	
  }

  getStrogeData() {
	this.restService.hitApi('users/storagePackage', null, 'post', '', true)
	.subscribe((result) => {
		this.isLoading = false;
		let responseData: any = result;
		if (responseData.statusCode == 200) {
			console.log("responseData.result storageCountData", responseData.result)
			this.storageCountData = responseData.result;
		} else {
			this.toastr.error(responseData.message);
		}

	}, (err) => {
		this.isLoading = false;
		console.log(err);
		let errorData: any = err;
	})
	
  }

  setUserData() {
	if (this.userCountData) {
		var employees = $('#employees');
		let minUser1 = Number(this.userCountData?.minUser1);
		let maxUser1 = Number(this.userCountData?.maxUser1);
		let maxUserRange1 = Number(this.userCountData?.maxUserRange1);
		let maxUserRange2 = Number(this.userCountData?.maxUserRange2);
		let maxUserRange3 = Number(this.userCountData?.maxUserRange3);

		var labels = { minUser1 : this.userCountData?.minUser1, 
			maxUser1: this.userCountData?.maxUser1, 
			maxUserRange1: this.userCountData?.maxUserRange1, 
			maxUserRange2: this.userCountData?.maxUserRange2,
			maxUserRange3: this.userCountData?.maxUserRange3};
	
			console.log("minUser1", minUser1, " ", maxUser1, " ", maxUserRange1)
		employees[0].noUiSlider.updateOptions({
			start: this.userCountData?.minUser1,
			connect: [true, false],
			tooltips: {
				to: function (value) {
					return value > 100 ? '100+' : parseInt(value)
				}
			},
			range: {
				'min': minUser1,
				'10%': maxUser1,
				'20%': 100,
				'40%': 200,
				'60%': 300,
				'80%': 400,
				'max': 500,
			},
			pips: {
				mode: 'steps',
				filter: function (value, type) {
					return type === 0 ? -1 : 1;
				},
				format: {
					to: function (value) {
						return labels[value];
					}
				}
			}
		});
	}
  
		
  }

}
