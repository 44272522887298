import { FormControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { MyErrorStateMatcher } from './../../MyErrorStateMatcher';
import { Component, OnInit, Input, Self, Optional, forwardRef } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, ControlValueAccessor {


  @Input()
  length: string;

  @Input()
  pageSize: string;

  @Input()
  pageSizeOptions: [string];

  value: any = '';
  control: FormControl;
  matcher = new MyErrorStateMatcher();

  constructor(@Self() @Optional()
  private ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
      setTimeout(() => {
        this.control = ngControl.control as FormControl;
        console.log('this.control');
        console.log(this.control);
      })

    }

  }


  ngOnInit() {
    console.log(this.ngControl.valueAccessor);
  }

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.value = value;
    console.log("writeValue called")
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    console.log("setDisabledState called")
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChange = fn;
    console.log("registerOnChange called")
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    //this.onTouched = fn;
    console.log("registerOnTouched called")
  }
  
  private onChange() { }
  private onTouched() { }

}
