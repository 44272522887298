import { formatDate } from '@angular/common';
import { values } from 'node_modules1111/@types/lodash/ts3.1';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';

// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Data } from './../ui-components/select-dropdown/Data';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ValidationService } from '../services/config/config.service';
import { DateConverterService } from '../services/date-formatter/date-converter.service';
import { BroadcastService } from '../services/broadcast/broadcast.service';
import { PageEvent } from '@angular/material/paginator';
import * as moment from 'moment';

declare const $: any;
declare const noUiSlider: any;

export const MY_FORMATS = {
	parse: {
		dateInput: 'LL'
	},
	display: {
		dateInput: 'DD-MM-YYYY',
		monthYearLabel: 'YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'YYYY'
	}
};

@Component({
	selector: 'app-myaccount',
	templateUrl: './myaccount.component.html',
	styleUrls: ['./myaccount.component.scss'],
	providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
	{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
	]
})

export class MyaccountComponent implements OnInit {

	@ViewChild('ccNumber', { static: false }) ccNumberField: ElementRef;
	userData: any = JSON.parse(localStorage.getItem("userData"));
	token: any = JSON.parse(localStorage.getItem('userData')).accessToken;
	assignToForm: FormGroup;
	assignUserList: any = [];
	user_id: any = "";
	users: any;
	accountInfo: any = [];
	company_name: any = "";
	companyId: any = "";
	isLoading: boolean = false;
	selectedUserId: any;
	monthList: any = [];
	yearList: any = [];
	invoiceForm: FormGroup;
	selectedMonth: 0;
	selectedYear: 0;
	cardForm: FormGroup;
	packageTypeData: any;
	addressForm: FormGroup;
	countryList: any = [];
	stateList: any = [];
	cardInfo: any;
	selectedCountry: any;
	selectedState: any;
	pageEvent: PageEvent;
	pageIndex: number = 0;
	pageSize: number = 10;
	length: number = 0;
	searchString: string = "";
	sortOrder: any = 'desc';
	sortByField: any = 'id'
	sortByHolidayOrder: any = 'asc';
	sortByHolidayField: any = 'id'
	projectListData: any = [];
	upgradePlanAlert: any;
	holidayList: any[] = [];
	holidayPageEvent: PageEvent;
	holidayPageIndex: number = 0;
	holidayPageSize: number = 10;
	holidayLength: number = 0;
	holidaySearchString: string = "";

	isNoSearchResults: boolean = false;
	isNoData: boolean = false;
	isNoHolidaySearchResults: boolean = false;
	isNoHolidayData: boolean = false;
	holidayForm: FormGroup;
	holidayListData: any;
	countryListData: any[] = [];
	selectedHolidayForDelete: any;
	lastSelectedTab = 1;
	companyUser: any;
	countryFilter: any;
	yearFilter: any = new Date().getFullYear().toString();
	isSearchFiler: boolean = false;
	fileData: File = null;
	validateFile: any = "";
	importCsvCountryId: any;
	isHolidayListFilter: boolean = false;
	showCreateHolidayPopup = false;
	showEditHolidayPopup = false;

	constructor(
		public restService: RestService,
		private toastr: ToastrService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private dateService: DateConverterService,
		private broadcastService: BroadcastService
	) {
		this.createForm(null);
		this.isLoading = true;
		this.getUserDetails();
		//this.userList();
		this.getMonthAndYears();
		this.getCardDetails();
		this.getPackageList();

		this.cardForm = this.formBuilder.group({
			card_holder_name: ['', [Validators.required]],
			card_number: ['', [Validators.required, Validators.pattern('^[ 0-9]*$'), Validators.minLength(17)]],
			cvv: ['', [Validators.required, Validators.pattern('^[ 0-9]*$'), Validators.minLength(3)]],
			expiry_month: ['', [Validators.required, Validators.pattern('^[ 0-9]*$'), Validators.minLength(2)]],
			expiry_year: ['', [Validators.required, Validators.pattern('^[ 0-9]*$'), Validators.minLength(4)]]
		});

		this.addressForm = this.formBuilder.group({
			address: ['', [Validators.required]],
			city: ['', [Validators.required]],
			country: ['', [Validators.required]],
			state: ['', [Validators.required]],
			zip: ['', [Validators.required, Validators.pattern('^[ 0-9]*$'), Validators.minLength(6)]]
		});
		this.holidayForm = this.formBuilder.group({
			holidayId: ['', []],
			holidayName: ['', [Validators.required]],
			holidayDate: ['', [Validators.required]],
			countryId: ['', [Validators.required]],
			countryName: ['', [Validators.required]],

		});
	}

	ngOnInit() {
		this.company_name = localStorage.getItem('company_name');
		this.companyId = localStorage.getItem('company_id');
		this.createFormForAssignUser(null);

		if (this.pageEvent == null) {
			this.pageEvent = new PageEvent();
			this.pageEvent.length = this.length;
			this.pageEvent.pageIndex = this.pageIndex;
			this.pageEvent.pageSize = this.pageSize;
		}
		if (this.holidayPageEvent == null) {
			this.holidayPageEvent = new PageEvent();
			this.holidayPageEvent.length = this.holidayLength;
			this.holidayPageEvent.pageIndex = this.holidayPageIndex;
			this.holidayPageEvent.pageSize = this.holidayPageSize;
		}

		this.getProjectGrid(this.pageEvent)

	}

	openChangeOwnership() {
		$('.owernship-anchor-box-open').toggle('down');
	}


	/* Insert spaces to enhance legibility of credit card numbers */
	creditCardNumberSpacing() {
		const input = this.ccNumberField.nativeElement;
		const { selectionStart } = input;
		const { card_number } = this.cardForm.controls;

		let trimmedCardNum = card_number.value.replace(/\s+/g, '');

		if (trimmedCardNum.length > 16) {
			trimmedCardNum = trimmedCardNum.substr(0, 16);
		}

		/* Handle American Express 4-6-5 spacing */
		const partitions = trimmedCardNum.startsWith('34') || trimmedCardNum.startsWith('37')
			? [4, 6, 5]
			: [4, 4, 4, 4];

		const numbers = [];
		let position = 0;
		partitions.forEach(partition => {
			const part = trimmedCardNum.substr(position, partition);
			if (part) numbers.push(part);
			position += partition;
		})

		card_number.setValue(numbers.join(' '));

		/* Handle caret position if user edits the number later */
		if (selectionStart < card_number.value.length - 1) {
			input.setSelectionRange(selectionStart, selectionStart, 'none');
		}
	}

	getPackageList() {
		this.restService.hitApi('users/packageList', null, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.packageTypeData = responseData.result;
					this.setPlanData();
					console.log("this.packageTypeData: ", this.packageTypeData)
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.err.message);
			})
	}
	getHolidayList(event?: PageEvent) {
		this.isLoading = true;
		let postdata = {

			"page": event.pageIndex + 1,
			"column": this.sortByHolidayField,
			"direction": this.sortByHolidayOrder,
			"perPage": event.pageSize,
			"name": this.holidaySearchString,

		};
		if (this.countryFilter != undefined && this.countryFilter != null) {
			postdata['countryId'] = this.countryFilter
		}
		if (this.yearFilter != undefined && this.yearFilter != null) {
			postdata['holidayYear'] = this.yearFilter
		}
		this.restService.hitApi('holiday/list', postdata, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.isLoading = false;
					this.holidayListData = responseData.result;
					this.holidayList = responseData.result.data;
					if (this.holidayList) {
						this.holidayList.forEach(element => {
							if (element.date) {
								var newDate = new Date(element.date);
								if (newDate) {
									element['formatedDate'] = moment(newDate).format('D MMMM')
									element['year'] = moment(newDate).format('YYYY')
								}

							}
						});
					}
					//this.holidayList = responseData.result.data;
					if (responseData.result.currentPage != 0) {
						this.holidayPageIndex = responseData.result.currentPage - 1;
					}

					this.holidayPageSize = responseData.result.perPage;
					this.holidayLength = responseData.result.total;
					this.holidayPageEvent = new PageEvent();
					this.holidayPageEvent.length = this.holidayLength;
					this.holidayPageEvent.pageIndex = this.holidayPageIndex;
					this.holidayPageEvent.pageSize = this.holidayPageSize;
					this.setHolidayIndexArray();
					this.checkIfHolidayDataHasComes();
					this.isSearchFiler = false;

				} else {
					this.isLoading = false;
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				this.isLoading = false;
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.err.message);
			})
	}

	sortData(field) {
		this.sortByField = field;
		this.sortOrder = this.sortOrder == 'asc' ? 'desc' : 'asc';
		this.getProjectGrid(this.pageEvent);
	}

	sortHolidayData(field) {
		this.sortByHolidayField = field;
		this.sortByHolidayOrder = this.sortByHolidayOrder == 'asc' ? 'desc' : 'asc';
		this.getHolidayList(this.pageEvent);
	}
	searchData() {
		let searchdata: any = { "search": {} };
		this.searchString = $('#search_name').val();
		console.log("i am searching", this.searchString);
		console.log(this.pageEvent);
		if (this.searchString.length >= 2 || this.searchString.length == 0) {
			this.getProjectGrid(this.pageEvent);
		}
	}

	getProjectGrid(event?: PageEvent) {
		let postdata = {
			"sort": {
				"column": this.sortByField,
				"direction": this.sortOrder
			},
			"page": event.pageIndex + 1,
			"size": event.pageSize,
			"q": this.searchString
		};
		this.restService.hitApi('project/projectGrid', postdata, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.projectListData = responseData.result.data;
					if (responseData.result.currentPage != 0) {
						this.pageIndex = responseData.result.currentPage - 1;
					}

					this.pageSize = responseData.result.perPage;
					this.length = responseData.result.total;
					this.pageEvent = new PageEvent();
					this.pageEvent.length = this.length;
					this.pageEvent.pageIndex = this.pageIndex;
					this.pageEvent.pageSize = this.pageSize;
					this.setIndexArray();
					this.checkIfDataHasComes();

				} else {
					this.toastr.error(responseData.message);
				}

			}, (err) => {
				let errorData: any = err;
				this.toastr.error(errorData.err.message);
			})
	}

	checkIfDataHasComes() {
		if (this.projectListData.length == 0) {
			if (this.searchString != "" && this.searchString != null) {
				this.isNoSearchResults = true;
				this.isNoData = false;
			} else {
				this.isNoData = true;
				this.isNoSearchResults = false;
			}
		} else {
			this.isNoData = false;
			this.isNoSearchResults = false;
		}
	}

	checkIfHolidayDataHasComes() {
		if (this.holidayList.length == 0) {
			if ((this.holidaySearchString != "" && this.holidaySearchString != null) || this.isSearchFiler) {
				this.isNoHolidaySearchResults = true;
				this.isNoHolidayData = false;
			} else {
				this.isNoHolidayData = true;
				this.isNoHolidaySearchResults = false;
			}
		} else {
			this.isNoHolidayData = false;
			this.isNoHolidaySearchResults = false;
		}
	}

	setIndexArray() {
		for (let i = 0; i < this.projectListData.length; i++) {
			if (this.pageIndex == 0) {
				this.projectListData[i].tableIndex = i + 1;
			} else {
				this.projectListData[i].tableIndex = (this.pageSize * this.pageIndex) + i + 1;
			}

			if (this.projectListData[i].type == 1) {
				this.projectListData[i].typeName = "Agile"
			} else {
				this.projectListData[i].typeName = "Kanban"
			}
		}
	}

	getCountry(obj: Data) {
		if (obj && obj instanceof Object) {
			this.holidayForm.get('countryId').setValue(obj.value);
			this.holidayForm.get('countryName').setValue(obj.name);

		}

	}
	updateCountry(value, holidayObj: any) {
		if (value) {
			for (var i = 0; i < this.countryList.length; i++) {
				if (this.countryList[i]?.id == value) {
					this.holidayForm.get('countryId').setValue(this.countryList[i]?.id);
					this.holidayForm.get('countryName').setValue(this.countryList[i]?.name);
					break;
				}
			}
		}

		this.holidayForm.get('holidayId').setValue(holidayObj?.id);
		this.holidayForm.get('holidayName').setValue(holidayObj?.name);
		var date = new Date(holidayObj?.date);
		this.holidayForm.get('holidayDate').setValue(date);
		this.updateHoliday()
	}
	addHoliday() {
		if (this.holidayForm.valid) {
			this.isLoading = true;
			var holidayDate = null;
			if (this.holidayForm.value?.holidayDate != undefined && this.holidayForm.value?.holidayDate != null && this.holidayForm.value?.holidayDate != '') {
				holidayDate = moment(this.holidayForm.value?.holidayDate).format('YYYY-MM-DD');
			}
			var postData = {
				name: this.holidayForm.value?.holidayName,
				date: holidayDate,
				countryId: this.holidayForm.value?.countryId
			}

			this.restService.hitApi('holiday/create', postData, 'post', '', true)
				.subscribe((result) => {
					this.isLoading = false;
					this.toastr.success(result?.message)
					$('#create_holiday_leave').modal('hide');
					this.closeModal();
					this.getHolidayList(this.holidayPageEvent);
				}, (err) => {
					this.isLoading = false;
					let errorData: any = err;
					this.toastr.error(errorData.err.message);
				})

		}
	}

	updateHoliday() {
		if (this.holidayForm.valid) {
			this.isLoading = true;
			var holidayDate = null;
			if (this.holidayForm.value?.holidayDate != undefined && this.holidayForm.value?.holidayDate != null && this.holidayForm.value?.holidayDate != '') {
				holidayDate = moment(this.holidayForm.value?.holidayDate).format('YYYY-MM-DD');
			}
			var postData = {
				holidayId: this.holidayForm.value?.holidayId,
				name: this.holidayForm.value?.holidayName,
				date: holidayDate,
				countryId: this.holidayForm.value?.countryId
			}

			this.restService.hitApi('holiday/update', postData, 'post', '', true)
				.subscribe((result) => {
					this.isLoading = false;
					this.toastr.success(result?.message)
					$('#edit_holiday_leave').modal('hide');
					this.closeModal();
					this.getHolidayList(this.holidayPageEvent);
				}, (err) => {
					this.isLoading = false;
					let errorData: any = err;
					this.toastr.error(errorData.err.message);
				})

		}
	}
	closeModal() {
		this.holidayForm.reset();
		this.holidayForm.get('countryId').setValue(this.companyUser?.country);
		this.holidayForm.get('countryName').setValue(this.companyUser?.countryName);
		this.showCreateHolidayPopup = false;
		this.showEditHolidayPopup = false;
	}
	closeImportModal() {
		this.importCsvCountryId = this.companyUser.country,
			this.fileData = null;
	}

	setHolidayIndexArray() {
		for (let i = 0; i < this.holidayList.length; i++) {
			if (this.holidayPageIndex == 0) {
				this.holidayList[i].tableIndex = i + 1;
			} else {
				this.holidayList[i].tableIndex = (this.holidayPageSize * this.holidayPageIndex) + i + 1;
			}
		}
	}

	calculateDiff(dateSent) {
		let currentDate = new Date();
		dateSent = new Date(dateSent);

		return Math.floor((Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));

	}

	getUserDetails() {
		this.restService.hitApi('companyUser/accountDetail', {}, 'post', '', false)
			.subscribe((result) => {

				let responseData: any = result;
				if (responseData.statusCode == 200) {
					// this.user_id = responseData.result.ownerId;
					this.accountInfo = responseData.result;
					console.log("this.accountInfo: ", this.accountInfo);
					if (this.accountInfo.endDate != "" && this.accountInfo.endDate != null) {
						this.accountInfo.endOn = this.dateService.convertInDDMMYYYFormate(this.accountInfo.endDate);
					}
					else {
						this.accountInfo.endOn = "--:--:--";
					}

					if (this.accountInfo.startDate != "" && this.accountInfo.startDate != null) {
						this.accountInfo.startOn = this.dateService.convertInDDMMYYYFormate(this.accountInfo.startDate);
					}
					else {
						this.accountInfo.startOn = "--:--:--";
					}

					this.accountInfo.usedStorage = this.restService.convertedStorage(this.accountInfo.usedStorage);
					let diffrance = this.calculateDiff(this.accountInfo.endDate)
					console.log("diffrancediffrance", diffrance)
					if (diffrance < 1) {
						this.upgradePlanAlert = "Your plan has expired"
					} else if (diffrance <= 5) {
						this.upgradePlanAlert = "Your plan will expire in next " + diffrance + " days"
					} else {
						this.upgradePlanAlert = null;
					}

					this.setPlanData();
				} else {
					this.toastr.error(responseData.message);
				}
				this.isLoading = false;
			}, (err) => {
				this.isLoading = false;
				let errorData: any = err;
				this.toastr.error(errorData.err.message);
			})
	}

	getMonthAndYears() {
		this.restService.hitApi('users/invoiceYears', {}, 'get', '', false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					console.log("invoiceYears: ", responseData.result);
					this.yearList = responseData.result;
					this.selectedYear = 0
					this.selectedMonth = 0
					console.log(this.restService?.apiURL + "companyUser/invoicePdf?accessToken=" + this.token + "&month=" + this.selectedMonth + "&year=" + this.selectedYear + "&companyId=" + this.companyId);
				} else {
					this.toastr.error(responseData.message);
				}
				this.isLoading = false;
			}, (err) => {
				this.isLoading = false;
				let errorData: any = err;
				this.toastr.error(errorData.err.message);
			})
	}

	getMonths(year: any) {
		this.restService.hitApi('users/invoiceMonths?year=' + year, {}, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					console.log("invoiceMonths: ", responseData.result);
					this.monthList = responseData.result;
				} else {
					this.toastr.error(responseData.message);
				}
				this.isLoading = false;
			}, (err) => {
				this.isLoading = false;
				let errorData: any = err;
				this.toastr.error(errorData.err.message);
			})
	}

	setPlanData() {
		if (this.packageTypeData) {
			var currentPlan = ""
			if (this.packageTypeData.free.id == this.accountInfo.packageId) {
				currentPlan = this.packageTypeData.free.packageName
			} else if (this.packageTypeData.monthly.id == this.accountInfo.packageId) {
				currentPlan = this.packageTypeData.monthly.packageName
			} else if (this.packageTypeData.yearly.id == this.accountInfo.packageId) {
				currentPlan = this.packageTypeData.yearly.packageName
			}

			this.accountInfo.currentPlan = currentPlan;
		}
	}

	// setIndexArray() {
	// 	for (let i = 0; i < this.this.accountInfo.length; i++) {
	// 		if (this.pageIndex == 0) {
	// 			this.defectsDataList[i].tableIndex = i + 1;
	// 		} else {
	// 			this.defectsDataList[i].tableIndex = this.pageSize + i + 1;
	// 		}
	// 	}
	// }

	userList() {
		if (this.cardInfo.companyAdminUser) {
			this.users = this.cardInfo.companyAdminUser
			this.assignUserList = [];
			console.log("userList, :", this.users);
			this.users.forEach(element => {
				let data = new Data(element.companyUserName, element.id);
				this.assignUserList.push(data);
			});
		}





		// this.restService.hitApi('companyUser/list', { 'search': { 'role': 1 } }, 'post', '', false)
		// 	.subscribe((result) => {
		// 		let responseData: any = result;
		// 		if (responseData.statusCode == 200) {
		// 			this.users = responseData.result.data;
		// 			this.assignUserList = [];
		// 			console.log("userList, :", this.users);
		// 			this.users.forEach(element => {
		// 				let data = new Data(element.companyUserName, element.id);
		// 				this.assignUserList.push(data);
		// 			});
		// 			// this.setAutoSuggest(null);
		// 		} else {
		// 			// this.toastr.error(responseData.message);
		// 		}
		// 	}, (err) => {
		// 		console.log(err);
		// 		let errorData: any = err;
		// 		// this.toastr.error(errorData.error.message);
		// 	})
	}

	goToProject(pid) {
		this.router.navigateByUrl('/project-view/' + pid);
	}

	selectUser(event) {
		console.log("eventeventevent", event);
		let lsid = event.target.value;
		this.selectedUserId = lsid;
	}

	changeOwnership() {
		//console.log(this.user_id);
		// let userID = this.assignToForm.value.assignId;

		if (this.selectedUserId != null || this.selectedUserId != undefined || this.selectedUserId != 'undefined') {
			this.restService.hitApi('user/transferOwnerShip', { 'user_id': this.selectedUserId }, 'post')
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						localStorage.setItem('userData', JSON.stringify(responseData.result));
						// this.router.navigate(['dashboard'])
						// setTimeout(() => {
						// 	location.reload();
						// }, 100);
						this.broadcastService.boradcast("FATCH_USER_DATA_GO_TO_DASHBOARD", responseData.result);
						this.toastr.success(responseData.message);

						// setTimeout(() => {
						// 	location.reload();
						// }, 100);

					} else {
						this.toastr.error(responseData.message);
					}

				}, (err) => {
					let errorData: any = err;
					this.toastr.error(errorData.err.message);
				})
		} else {
			this.toastr.error("please select user");
		}

	}

	getAssign(obj: Data) {

		if (obj) {
			console.log("selectedRole" + obj.name);
			console.log("selectedId" + obj.value);
			this.assignToForm.get('assignId').setValue(parseInt(obj.value));
			this.assignToForm.get('assignName').setValue(parseInt(obj.name));

			console.log(this.assignToForm);
		}
	}

	createFormForAssignUser(data) {
		if (data === null) {
			this.assignToForm = this.formBuilder.group({
				'assignName': ['', [Validators.required]],
				'assignId': ['', [Validators.required]],
			});

		} else {
			this.assignToForm = this.formBuilder.group({
				'assignName': [data.releaseName, [Validators.required]],
				'assignId': [data.releaseId, [Validators.required]],
			});
		}

	}

	createForm(data) {
		this.invoiceForm = this.formBuilder.group({
			'invoice_email': [data ? data.invoiceEmail : '', [Validators.required, ValidationService.emailValidator]],
			'custom_note': [data ? data.customNote : '']
		})
	}

	changeInvoiceSetting() {
		this.restService.hitApi('user/updateUserDetail', this.invoiceForm.value, 'post')
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getUserDetails();
					this.toastr.success(responseData.message);
					this.createForm(null);
				} else {
					this.toastr.error(responseData.message);
				}

			}, (err) => {
				let errorData: any = err;
				console.log("errorData: ", errorData)
				this.toastr.error(errorData.error.message);
			})
	}

	selectMonth(event) {
		console.log("eventeventevent", event.target.value);
		let month = event.target.value;

		if (month != "null") {
			this.selectedMonth = month
		}
		else {
			this.selectedMonth = 0
		}
	}

	selectYear(event) {
		console.log("eventeventevent", event.target.value);
		let year = event.target.value;

		if (year != "null") {
			this.selectedYear = year
			this.getMonths(year)
		}
		else {
			this.selectedYear = 0
		}
	}

	getCardDetails() {
		this.restService.hitApi('user/userCardDetail', {}, 'get')
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					console.log("user/userCardDetail: ", responseData.result);
					this.cardInfo = responseData.result;
					if (this.cardInfo.countries) {
						this.countryList = this.cardInfo.countries
						if (this.countryList && this.countryList.length > 0) {
							this.countryList.forEach(element => {

								var data = new Data(element?.name, element?.id);
								this.countryListData.push(data);
							});
						}
					}
					if (this.cardInfo?.user) {
						this.companyUser = this.cardInfo?.user;
						this.holidayForm.get('countryId').setValue(this.cardInfo?.user?.country);
						this.holidayForm.get('countryName').setValue(this.cardInfo?.user?.countryName);
						this.countryFilter = this.cardInfo?.user?.country;
						this.importCsvCountryId = this.cardInfo?.user?.country;
					}
					this.userList();
					this.getHolidayList(this.holidayPageEvent);
				} else {
					this.toastr.error(responseData.message);
				}

			}, (err) => {
				let errorData: any = err;
				this.toastr.error(errorData.err.message);
			})

	}

	updateCard() {
		//user/updateCardDetail

		this.restService.hitApi('user/updateCardDetail', this.cardForm.value, 'post')
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					console.log("user/updateCardDetail: ", responseData.result);
					this.cardForm.reset();
					this.getCardDetails();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}

			}, (err) => {
				let errorData: any = err;
				console.log("errorData", errorData)
				this.toastr.error(errorData.error.message);
			})

	}

	updateAddress() {

		this.restService.hitApi('user/updateBillingAddress', this.addressForm.value, 'post')
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					console.log("user/updateCardDetail: ", responseData.result);
					this.addressForm.reset();
					this.getCardDetails();
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}

			}, (err) => {
				let errorData: any = err;
				this.toastr.error(errorData.err.message);
			})
	}

	changeCountry(event) {
		console.log("eventeventevent", event.target.value);
		let id = event.target.value;
		this.selectedCountry = id

		this.restService.hitApi('user/getStates', { "country_id": id }, 'post')
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.stateList = responseData.result
				} else {
					this.toastr.error(responseData.message);
				}

			}, (err) => {
				let errorData: any = err;
				this.toastr.error(errorData.err.message);
			})
	}

	changeState(event) {
		console.log("eventeventevent", event.target.value);
		let id = event.target.value;

		this.selectedState = id
	}

	checkNumeric(field, maxLength = 2, value = "") {
		value = $('#' + field).val();

		if (value.length > maxLength) {
			return false;
		}
	}
	checkHolidayYearNumeric(maxLength = 2, value) {
		console.log(value);
		if (value != null) {
			var number = parseInt(value);
			if (this.yearFilter.length > maxLength) {
				if (isNaN(number)) {
					return false;
				}
			}
		} else {
			return false;

		}

	}
	searchHoliday() {
		this.holidaySearchString = $('#search_holiday').val();
		console.log(this.pageEvent);
		if (this.holidaySearchString.length >= 2 || this.holidaySearchString.length == 0) {
			this.holidayPageEvent = new PageEvent();
			this.holidayPageEvent.length = this.holidayLength;
			this.holidayPageEvent.pageIndex = 0;
			this.holidayPageEvent.pageSize = this.holidayPageSize;
			this.isHolidayListFilter = true;
			this.getHolidayList(this.holidayPageEvent);

		}


	}
	makeEditForm(data) {
		if (data) {
			this.holidayForm.get('holidayId').setValue(data.id);
			this.holidayForm.get('holidayName').setValue(data.name);
			var date = new Date(data?.date);
			this.holidayForm.get('holidayDate').setValue(date);
			this.holidayForm.get('countryId').setValue(data?.countryId);
			if (this.countryList) {
				this.countryList.forEach(element => {
					if (element.id == data?.countryId) {
						this.holidayForm.get('countryName').setValue(element?.name);
					}
				});
			}
			this.showEditHolidayPopup = true;
		}
	}
	deleteHoliday(id: any) {
		var index;
		for (var i = 0; i < this.holidayList.length; i++) {
			if (this.holidayList[i].id == id) {
				index = i;
				break;
			}
		}
		this.restService.hitApi('holiday/delete', { 'holidayId': id }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$('#delete-holiday').modal('hide');
					$('#tabs a[href="#holiday-management-v"]').tab('show')
					if (index != -1) {
						this.holidayList.splice(index, 1);
						if (this.holidayList.length == 0) {
							this.holidayPageEvent = new PageEvent();
							this.holidayPageEvent.length = this.holidayLength;
							this.holidayPageEvent.pageIndex = 0;
							this.holidayPageEvent.pageSize = this.holidayPageSize;

							this.getHolidayList(this.holidayPageEvent);
						}
						this.holidayLength = this.holidayLength - 1;
					}
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}
	searchByCountry(country: any) {
		this.countryFilter = country;
		this.isSearchFiler = true;
		this.holidayPageEvent = new PageEvent();
		this.holidayPageEvent.length = this.holidayLength;
		this.holidayPageEvent.pageIndex = 0;
		this.holidayPageEvent.pageSize = this.holidayPageSize;
		this.isHolidayListFilter = true;
		this.getHolidayList(this.holidayPageEvent);
	}

	searchByYear() {

		this.checkHolidayYearNumeric(4, this.yearFilter)

		if (this.yearFilter == null || (this.yearFilter.toString().length == 4 || this.yearFilter.toString().length == 0)) {
			if (this.yearFilter != null) {
				this.yearFilter = parseInt(this.yearFilter);
			}
			this.isSearchFiler = true;
			this.holidayPageEvent = new PageEvent();
			this.holidayPageEvent.length = this.holidayLength;
			this.holidayPageEvent.pageIndex = 0;
			this.holidayPageEvent.pageSize = this.holidayPageSize;
			this.isHolidayListFilter = true;
			this.getHolidayList(this.holidayPageEvent);

		}

	}

	selectCsvFile(event) {
		this.fileData = event[0];
	}

	importHolidays() {
		if (this.fileData != undefined && this.fileData != null && this.importCsvCountryId != null && this.importCsvCountryId != undefined) {
			this.isLoading = true;
			const formData: FormData = new FormData();
			formData.append('importHoliday', this.fileData);
			formData.append('countryId', this.importCsvCountryId);
			this.restService.hitApi('holiday/import', formData, 'post', '', true, false)
				.subscribe((result) => {
					this.isLoading = false;
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						$('#export-pdf-popup').modal('hide');
						this.toastr.success(responseData.message);
						this.getHolidayList(this.holidayPageEvent);
						this.validateFile = "";
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					this.isLoading = false;
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		} else {
			if ((this.fileData == undefined || this.fileData == null) && (this.importCsvCountryId == undefined || this.importCsvCountryId == null)) {
				this.toastr.error("Please select CSV file and country to upload holidays");

			} else if (this.fileData == undefined || this.fileData != null) {
				this.toastr.error("Please select CSV file to upload holidays");
			} else if (this.importCsvCountryId == undefined || this.importCsvCountryId != null) {
				this.toastr.error("Please select country to upload holidays");
			}
		}
	}
	changeCvCountry(value) {
		this.importCsvCountryId = value;
	}
	onKeyPress(value) {
		if (value.length == 4) return false;
	}

	myFunction() {

	}

	onKeyDownFn(event) {
		return event.keyCode !== 69
	}

}


