import { Component, OnInit } from '@angular/core';

import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { RestService } from '../services/rest/rest.service';
import { ToastrService } from 'ngx-toastr';
declare const $:any;

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  freePlanData: any;
  public href : any;
  constructor(
		private router: Router, 
		private restService: RestService,
		private toastr: ToastrService,
	) {
		$("html, body").animate({ scrollTop: 0 }, "slow");
	}

  ngOnInit() {
    this.href = this.router.url;
    this.getFreePlan();
  }

  getFreePlan() {
		this.restService.hitApi('users/freePackageDetail',null,'get','',true)
		.subscribe((result)=>{
			let responseData : any = result;
			if(responseData.statusCode==200){
				this.freePlanData = responseData.result;
				console.log("this.freePlanData: ", this.freePlanData)
			} else {
				// this.toastr.error(responseData.message);
			}
		},(err)=>{
			console.log(err);
			let errorData : any = err;
			// this.toastr.error(errorData.error.message);
		})
	}

	tryFree(){
		if (this.freePlanData.status == 1) {
			this.router.navigateByUrl('/register', { state: { package: "free",  minStorage: this.freePlanData.maxData, minUsers:  this.freePlanData.noOfUsers} });
			
		} else {
			this.toastr.error("Sorry, free trail not available, please try to pay and use Trakk.in");
		}
	}

}
