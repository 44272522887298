import { element } from 'protractor';
import { filter } from 'rxjs/operators';
import { RestService } from './../services/rest/rest.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DateConverterService } from '../services/date-formatter/date-converter.service'
import { interval } from 'rxjs';



declare const $: any;
@Component({
	selector: 'app-discussion-thread',
	templateUrl: './discussion-thread.component.html',
	styleUrls: ['./discussion-thread.component.scss']
})
export class DiscussionThreadComponent implements OnInit {
	discussionThreadData: any;
	pid: any;
	did: any;
	discussionDetail: any;
	userData: any;
	userInfo: any;
	isOwner;
	isAddCommentInputShown: boolean = true;
	files: any = [];
	imgArr: any = [];
	usersArr: any = [];
	interval: any;
	timeLeft: number = 15;
	isEmailCheckBoxShown: boolean = false;
	projectData: any;
	selectAll: boolean;
	selectNone: boolean;
	isForEdit: boolean = false;
	jqueryRef: any = $;
	munites: number = 14;
	isEditable: boolean = true;
	selectedThreadPost: any;
	selectedThread: any;
	comment: any;
	isCompanyAdmin: boolean;
	isProjectManager: boolean;
	isOther: boolean;
	emailsToStr = "";
	isClosedDiscussion: boolean;
	rad1: number;
	isAddThisButtonNotShow = true;
	userList: any;
	deletedAttachmentItmes: any[] = [];


	constructor(private restService: RestService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private dateConverterService: DateConverterService) {
		this.userData = JSON.parse(localStorage.getItem("userData"));

		this.route.params.subscribe(params => {
			this.pid = params['pid'];
			this.did = params['did'];
			if (this.pid && this.pid != null && this.pid !== undefined) {
				this.getProjectDetail();
				this.getDiscussionThreads();
			}
		});
		if (this.userData) {
			this.getUserprofile();
			this.isOwner = this.restService.isOwner(this.userData);

		}


	}

	ngOnInit() {

		$(document).ready(() => {
			let that = this;

			$('.htmlEditor').summernote({
				height: '150px',
				focus: true,
				disableDragAndDrop: true,
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline']],
					['para', ['ul', 'ol', 'paragraph']]
				],
				callbacks: {

					onChange: function (contents, $editable) {
						//that.discussionAddForm.get('comment').setValue(contents);
						that.comment = contents;
					},
					onImageUpload: function (data) {
						data.pop();
					},
					onPaste: function (e) {
						var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
						e.preventDefault();
						document.execCommand('insertText', false, bufferText);
					}
				}
			});

			/*$('#summernote2').summernote({
				toolbar: [
					// [groupName, [list of button]]
					['style', ['bold', 'italic', 'underline', 'clear']],
					['font', ['strikethrough', 'superscript', 'subscript']],
					['fontsize', ['fontsize']],
					['color', ['color']],
					['para', ['ul', 'ol', 'paragraph']],
					['height', ['height']]
				],

			});*/

		});

		//this.getDiscussionThreads();
	}

	getUserprofile() {
		this.restService.hitApi('user/profile', { 'userId': this.userData.id }, 'get', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.userInfo = responseData.result;
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	getProjectDetail() {
		this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.projectData = responseData.result;
					if (this.userData) {
						console.log("discussion thread", localStorage.getItem('isOwner'))
						var isOwner = localStorage.getItem('isOwner');
						if (isOwner == "true") {
							this.isOwner = true;
							this.isCompanyAdmin = false;
							this.isProjectManager = false;
							this.isOther = false;
						} else {
							if (this.userData.role == 1) {
								this.isOwner = false;
								this.isCompanyAdmin = true;
								this.isProjectManager = false;
								this.isOther = false;
							} else if (this.userData.role == 2) {
								if (this.projectData && this.projectData.projectUsersProject) {
									this.projectData.projectUsersProject.forEach(element => {
										if (element.userId == this.userData.id) {
											if (element.userRoleId == 1) {
												this.isProjectManager = true;
												this.isOther = false;
											} else {
												this.isProjectManager = false;
												this.isOther = true;
											}
										}
									});
									this.isOther = false;

								} else {
									this.isOther = true;
								}
								this.isOwner = false;
								this.isCompanyAdmin = false;
							} else {
								this.isOther = true;
								this.isOwner = false;
								this.isProjectManager = false;
								this.isCompanyAdmin = false;
							}
						}

					}
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	getDiscussionThreads() {

		this.restService.hitApi('discussion/threads/list', { 'projectId': this.pid, 'threadId': this.did }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.discussionThreadData = responseData.result.list.data;
					this.discussionDetail = responseData.result.details;

					if (this.discussionThreadData) {
						this.discussionThreadData.forEach(element => {
							element.files = [];
							element.imgArr = [];
							this.checkTimeForUpdate(element);
							this.setMinutesForUpdate(element);
						});
					}
					if (this.discussionDetail) {
						this.setMinutesForUpdate(this.discussionDetail);

					}
					if (this.discussionDetail) {
						if (this.discussionDetail.emailsTo) {
							this.emailsToStr = "";

							this.discussionDetail.emailUserList = JSON.parse(this.discussionDetail.emailsTo);

							if (this.projectData?.projectUsersProject && this.projectData?.projectUsersProject.length > 0) {
								this.projectData.projectUsersProject = this.projectData?.projectUsersProject.filter(el => el.userId != this.userData.id);
								if (this.discussionDetail.emailUserList && this.discussionDetail.emailUserList.length > 0) {
									for (var i = 0; i < this.projectData.projectUsersProject.length; i++) {
										var el = this.projectData.projectUsersProject[i];
										for (var j = 0; j <= this.discussionDetail.emailUserList.length; j++) {
											if (this.discussionDetail.emailUserList[j] == el.userId) {
												el.checked = true;
												if (j <= this.discussionDetail.emailUserList.length) {
													this.emailsToStr = this.emailsToStr + el?.usersUser?.name + ', ';
												} else {
													this.emailsToStr = this.emailsToStr + el?.usersUser?.name;
												}

												this.resetRadio(el, i);
												break;

											}
										}


									}

								}
							}
						}
						if (this.discussionDetail.status == 1) {
							this.isClosedDiscussion = false;
						} else {
							this.isClosedDiscussion = true;
						}



					}
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})


	}

	setMinutesForUpdate(element) {
		if (this.isOwner || this.isCompanyAdmin) {
			element.munites = 1;
		} else {
			if (element.createdAt) {
				//var localDate: any = this.dateConverterService.convertUTCDateToLocalDate(new Date(element.createdAt));
				var today: any = new Date();
				var todayUtc = new Date(today.getTime() + today.getTimezoneOffset() * 60000);
				var diffMs = todayUtc.getTime() - new Date(element.createdAt).getTime();// milliseconds between now & date
				var diffDays = Math.floor(diffMs / 86400000); // days
				var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
				var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

				if (diffDays < 0 || diffDays == 0) {
					if (diffHrs < 0 || diffHrs == 0) {
						if (diffMins < 14) {
							element.munites = 15 - diffMins;
							this.startTimer(element);
							element.isEditable = true;
						} else {

							element.isEditable = false;
						}
					} else {
						element.isEditable = false;
					}

				} else {
					element.isEditable = false;
				}


			}
		}

	}

	checkTimeForUpdate(element) {
		var cd = new Date(element.createdAt);
		var ud = new Date(element.updatedAt);

		if (cd.getTime() != ud.getTime()) {
			element.isCommentEdit = true;
		} else {
			element.isCommentEdit = false;
		}
	}

	toggleAddCommentList(ele, event, show) {
		let that = this;
		$('#summernote_add').summernote({
			height: '150px',
			focus: true,
			disableDragAndDrop: true,
			toolbar: [
				// [groupName, [list of button]]
				['style', ['bold', 'italic', 'underline']],
				['para', ['ul', 'ol', 'paragraph']]
			],
			callbacks: {

				onImageUpload: function (data) {
					data.pop();
				},
				onPaste: function (e) {
					var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
					e.preventDefault();
					document.execCommand('insertText', false, bufferText);
				}
			}
		});

		this.isAddCommentInputShown = show;
		$('#summernote_add').summernote("reset");
		$('#summernote_add').summernote('code', '');
		// $('#summernote').summernote("focus");
		if (event == 'down') {
			$('.' + ele).slideDown("fast");
		} else {
			$('.' + ele).slideUp("fast");

		}

		$('#summernote_add').summernote({
			focus: true,
		});
	}

	toggleUpdateCommentList(ele, event, obj) {
		let that = this;
		$('#summernote_' + obj.id).summernote({
			height: '150px',
			focus: true,
			disableDragAndDrop: true,
			toolbar: [
				// [groupName, [list of button]]
				['style', ['bold', 'italic', 'underline']],
				['para', ['ul', 'ol', 'paragraph']]
			],
			callbacks: {
				onPaste: function (e) {
					var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
					e.preventDefault();
					document.execCommand('insertText', false, bufferText);
				},
				onImageUpload: function (data) {
					data.pop();
				},
			}
		});

		$('#summernote_' + obj.id).summernote('code', obj.post);
		if (event == 'down') {
			$('.' + ele + '_' + obj.id).slideDown("fast");
		} else {
			$('.' + ele + '_' + obj.id).slideUp("fast");

		}
		$('#summernote_' + obj.id).summernote({
			focus: true,
		});
	}

	uploadFile(event) {
		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];
			if (this.restService.checkFileSize(file, 1024)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				formData.append('imageFolder', 'thread_image/');
				formData.append('image', file);

				// const element = event[index];


				var reader = new FileReader();
				const this_ = this;
				var obj;
				reader.onload = function (e: any) {
					obj = { name: file.name, image: e.target.result, isProgressBarShown: true }
					this_.files.push(obj);
				}

				reader.readAsDataURL(file);

				// $(".p-bar" + index).animate({
				// 	width: "+=2000"
				// }, 5000, function () {
				// 	// Animation complete.
				// });

				this.restService.hitApi('discussion/attachments', formData, 'post', '', false, false)
					.subscribe((result) => {
						let responseData: any = result;
						if (responseData.statusCode == 200) {
							this.imgArr.push(responseData.result);
							this.files.forEach(element => {
								element.isProgressBarShown = false;
							});

						} else {
							this.toastr.error(responseData.message);
						}
					}, (err) => {
						console.log(err);
						let errorData: any = err;
						this.toastr.error(errorData.error.message);
					})
			}
		}

		this.isAddThisButtonNotShow = false;

	}

	checkAddCommentButtonShown() {
		var comment = $('#summernote_add').val();


		if (this.files.length > 0) {
			this.files.forEach(element => {
				if (element.isProgressBarShown == false) {
					this.isAddThisButtonNotShow = false
				} else {
					this.isAddThisButtonNotShow = true
				}

			});
		} else {
			if (comment?.length > 0) {
				this.isAddThisButtonNotShow = false
			} else {
				this.isAddThisButtonNotShow = true

			}

		}
		return this.isAddThisButtonNotShow;

	}

	uploadThreadAttachment(event, thread) {

		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];
			if (this.restService.checkFileSize(file, 1024)) {
				const formData: FormData = new FormData();
				formData.append('projectId', this.pid);
				formData.append('imageFolder', 'thread_image/');
				formData.append('image', file);

				// const element = event[index];


				var reader = new FileReader();
				const this_ = this;
				var obj;
				reader.onload = function (e: any) {
					obj = { name: file.name, image: e.target.result, isProgressBarShown: true }
					thread.files.push(obj);
				}

				reader.readAsDataURL(file);

				this.restService.hitApi('discussion/attachments', formData, 'post', '', false, false)
					.subscribe((result) => {
						let responseData: any = result;
						if (responseData.statusCode == 200) {

							thread.imgArr.push(responseData.result);
							thread.files.forEach(element => {
								var elemObj = document.getElementById('progress_' + element.name);
								elemObj.style.maxWidth = '100%';
								element.isProgressBarShown = false;
							});

						} else {
							this.toastr.error(responseData.message);
						}
					}, (err) => {
						console.log(err);
						let errorData: any = err;
						this.toastr.error(errorData.error.message);
					})
			}
		}
	}


	removeAttachment(index, name, files) {
		var fileObj = files[index];
		if (fileObj?.id) {
			this.deletedAttachmentItmes.push(fileObj?.id)
		} else {
			this.removeUploadedFileFromServer(name);
		}
		// if (fileObj.id) {
		// 	this.deleteAttachment(fileObj.id);
		// } else {
		// 	this.removeUploadedFileFromServer(name);

		// }
		files.splice(index, 1)
	}

	deleteItemsAndUpdateDiscussionThread(obj, field) {
		if (this.deletedAttachmentItmes.length > 0) {
			var count = 0;
			for (var i = 0; i < this.deletedAttachmentItmes.length; i++) {

				var json = { "projectThreadPostImageId": this.deletedAttachmentItmes[i], "projectId": this.pid }

				this.restService.hitApi('discussion/attachments/delete', json, 'post', '', true, false)
					.subscribe((result) => {
						let responseData: any = result;
						if (responseData.statusCode == 200) {
							this.imgArr.splice(name, 1);
							count = i;
							console.log("this.imgArr", this.imgArr);
						} else {
							this.toastr.success(responseData.message);
						}
						if (count == this.deletedAttachmentItmes.length) {
							this.deletedAttachmentItmes = [];
							if (obj != null && field != null) {
								this.updateDiscussionThread(obj, field)
							} else {
								this.addDiscussionThread(field)
							}

						}
					}, (err) => {
						console.log(err);
						let errorData: any = err;
						this.toastr.error(errorData.error.message);
					})
			}
		} else {
			if (obj != null && field != null) {
				this.updateDiscussionThread(obj, field)
			} else {
				this.addDiscussionThread(field)
			}
		}
	}

	removeUploadedFileFromServer(name) {
		var json = {
			projectId: this.discussionDetail.projectId,
			imageFolder: 'thread_image/',
			imageName: name
		}

		this.restService.hitApi('discussion/attachments/deleteByFile', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
				} else {
					this.toastr.success(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}



	deleteAttachment(aid) {
		this.restService.hitApi('discussion/attachments/delete', { "projectThreadPostImageId": aid, "projectId": this.pid }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}


	addDiscussionThread(field) {
		this.comment = $('#summernote_add').val();
		var json = {
			threadId: this.discussionDetail.id,
			projectId: this.discussionDetail.projectId,
			client_display: this.discussionDetail.clientDisplay,
			comment: this.comment,
			users: this.usersArr,
			rad1: this.rad1,
			upload_files_create: this.imgArr


		}

		this.restService.hitApi('discussion/create', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.imgArr = [];
					this.files = [];
					this.usersArr = [];
					this.isEmailCheckBoxShown = false;
					if (this.projectData.projectUsersProject && this.projectData.projectUsersProject.length > 0) {
						this.projectData.projectUsersProject.forEach(element => {
							element.checked = false;
						});
					}
					$('summernote_add').summernote("reset");
					$('.' + field).slideUp("fast");
					$('#discussion_add_comment').slideUp('fast')
					this.isAddCommentInputShown = true;
					$('#discussion_add_comment').hide();
					// this.emailsToStr = "";
					this.getDiscussionThreads();
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})

	}

	updateDiscussionThread(thread, field) {
		this.comment = $('#summernote_' + thread.id).val();
		var json = {
			"projectId": this.discussionDetail.projectId,
			"projectThreadPostId": thread.id,
			"comment": this.comment,
			"upload_files_create": thread.imgArr
		}

		this.restService.hitApi('discussion/threads/update', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					$(field + '_' + thread.id).summernote("reset");
					//$('.' + field).slideUp("fast");
					this.getDiscussionThreads();
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}
	deleteDiscussionThread(id) {
		var json = {
			projectId: this.discussionDetail.projectId,
			threadId: id
		}

		this.restService.hitApi('discussion/delete', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.toastr.success(responseData.message);
					$('#delete-thread').modal('hide')
					this.router.navigateByUrl('/project/' + this.pid + '/discussions');
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}
	deleteThreadPost(thradPostId) {
		var json = {
			projectId: this.discussionDetail.projectId,
			projectThreadPostId: thradPostId
		}
		this.restService.hitApi('discussion/threads/delete', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.toastr.success(responseData.message);
					this.getDiscussionThreads();
					$('#delete-thread-post').modal('hide')
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	updateDiscussionStatus(status) {
		var json = {
			"projectId": this.discussionDetail.projectId,
			"threadId": this.discussionDetail.id,
			"status": status
		}

		this.restService.hitApi('discussion/updateStatus', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getDiscussionThreads();
					$('#change-thread-status').modal('hide');
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	checkedAllUser() {
		if (this.projectData.projectUsersProject) {
			this.projectData.projectUsersProject.forEach(element => {
				element.checked = true;

			});
			this.emailRadioChange(true);
		}
		this.rad1 = 1;
	}
	checkedNoneUser() {
		if (this.projectData.projectUsersProject) {
			this.projectData.projectUsersProject.forEach(element => {
				element.checked = false;
			});
		}
		this.emptyUserArray();

		this.rad1 = null;
	}
	emailRadioChange(isSelect: boolean) {
		this.usersArr = [];
		if (this.projectData.projectUsersProject) {
			this.projectData.projectUsersProject.forEach(element => {
				element.checked = isSelect;
				if (isSelect) {
					this.usersArr.push(element?.usersUser?.id);
				}
			});
		}
	}


	emptyUserArray() {
		this.usersArr = [];
	}

	resetRadio(user, index) {
		var allChecked = false;

		if (this.userList) {
			var filterCheckedUser = this.userList.filter(el => el.checked && el.checked == true);
			if (filterCheckedUser && filterCheckedUser.length == this.userList.length) {
				allChecked = true;
			} else {
				allChecked = false;
			}
			// this.projectData.projectUsersProject.forEach(element => {
			// 	allChecked = element.checked ? true : false;
			// });
		}
		if (allChecked) {
			this.selectAll = true;
			this.selectNone = false;
			$('#select-all-radio').prop('checked', true);
			$('#select-none-radio').prop('checked', false);
			this.rad1 = 1;
		} else {
			if (this.projectData.projectUsersProject.filter(t => t.checked).length > 0 && !allChecked) {
				this.selectAll = false;
				this.selectNone = false;
				$('#select-all-radio').prop('checked', false);
				$('#select-none-radio').prop('checked', false);
				this.rad1 = 2;
			} else {
				this.selectAll = false;
				this.selectNone = true;
				$('#select-all-radio').prop('checked', false);
				$('#select-none-radio').prop('checked', true);
				this.rad1 = null;
			}
		}
		if (user.checked) {
			this.usersArr.push(user.userId)
		} else {
			this.usersArr.splice(this.usersArr.indexOf(user.userId), 1)
		}

		console.log(this.usersArr);

	}

	diff_minutes(dt2, dt1) {

		var diff = (dt2.getTime() - dt1.getTime()) / 1000;
		diff /= 60;
		return Math.abs(Math.round(diff));

	}

	startTimer(thread) {
		interval(60000).subscribe(x => {
			thread.munites = thread.munites - 1;
			document.getElementById("demo_" + thread.id).innerHTML = "for " + thread.munites + " minutes"
		});
	}

	toggleDiscussion(ele) {
		if (ele == 'discussionSummary') {
			$('.discussionSummary .htmlEditor').summernote('code', this.discussionDetail.description);
		}

		$('.' + ele).slideToggle("fast");

		$('#discussion_summernote').summernote({
			focus: true,
		});
	}

	toggleDiscussionUpdate(ele, event, obj) {
		obj.files = [];
		obj.imgArr = [];
		$('#summernote_' + obj.id).summernote({
			height: '150px',
			focus: true,
			disableDragAndDrop: true,
			toolbar: [
				// [groupName, [list of button]]
				['style', ['bold', 'italic', 'underline']],
				['para', ['ul', 'ol', 'paragraph']]
			],
			callbacks: {
				onPaste: function (e) {
					var bufferText = ((e.originalEvent || e).clipboardData || window.Clipboard).getData('Text');
					e.preventDefault();
					document.execCommand('insertText', false, bufferText);
				},
				onImageUpload: function (data) {
					data.pop();
				},
			}
		});

		if (event == 'down') {
			$('.htmlEditor').summernote('reset');
			$('#summernote_' + obj.id).summernote('code', obj.post);
			$('.' + ele + '_' + obj.id).slideDown("fast");
			if (obj.projectThreadPostImages && obj.projectThreadPostImages.length > 0) {
				obj.projectThreadPostImages.forEach(element => {
					obj.files.push({ id: element.id, name: element.imageName, image: element.imageTitle, isProgressBarShown: false });
					//obj.imgArr.push(element.displayName);
				});
				// 	}
			} else {
				// $('.' + ele + '_' + obj.id).slideUp("fast");
				obj.files = [];
				obj.imgArr = [];

			}
		} else {
			$('.' + ele + '_' + obj.id).slideUp("fast");
		}
		$('#summernote_' + obj.id).summernote({
			focus: true,
		});
	}
	getExtensionOfFileType(fileName) {
		if (fileName) {
			var str = fileName.split(".");
			if (str[str.length - 1] == 'jpg' || str[str.length - 1] == 'png' || str[str.length - 1] == 'jpeg' || str[str.length - 1] == 'JPG' || str[str.length - 1] == 'PNG') {
				return 'image'
			} else {
				return 'file'
			}

		}
	}

	download(url, fileName) {
		this.restService.downloadFile(url, fileName);
	}

	// toggleDiscussionUpdate(ele, thread) {

	// 	if (ele == 'discussion_edit') {
	// 		$('.discussion_edit .htmlEditor_' + thread.id).summernote('code', thread.post);
	// 	}

	// 	if (thread.projectThreadPostImages && thread.projectThreadPostImages.length > 0) {
	// 		thread.projectThreadPostImages.forEach(element => {
	// 			thread.files.push({ name: element.displayName, image: element.imageTitle });
	// 			thread.imgArr.push(element.displayName);
	// 		});
	// 	}

	// 	$('#' + ele + "_" + thread.id).slideToggle("fast");
	// }
	// startTimer(date) {
	// 	var localDate = this.dateConverterService.convertUTCDateToLocalDate(new Date(date));
	// 	var countDownDate = localDate.getTime();

	// 	// Update the count down every 1 second
	// 	var x = setInterval(function () {

	// 		// Get today's date and time
	// 		var now = new Date().getTime();

	// 		// Find the distance between now and the count down date
	// 		var distance = now - countDownDate;

	// 		// Time calculations for days, hours, minutes and seconds
	// 		var days = Math.floor(distance / (1000 * 60 * 60 * 24));
	// 		var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	// 		var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
	// 		var seconds = Math.floor((distance % (1000 * 60)) / 1000);

	// 		// Display the result in the element with id="demo"
	// 		document.getElementById("demo").innerHTML = "for " + minutes + " minutes"

	// 		// If the count down is finished, write some text
	// 		if (distance < 0) {
	// 			clearInterval(x);
	// 			document.getElementById("demo").innerHTML = "EXPIRED";
	// 		}
	// 	});
	// }




}
