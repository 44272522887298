import { Moment } from 'moment';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';

// Services
import { RestService } from '../services/rest/rest.service';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

// import { MatDialog } from '@angular/material';
// import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AstMemoryEfficientTransformer } from '@angular/compiler';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { versions } from 'process';
import { Data } from './../ui-components/select-dropdown/Data';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateConverterService } from '../services/date-formatter/date-converter.service';

import { FileUploadService } from "../file-upload-service";
import { HttpEvent, HttpEventType } from '@angular/common/http';



import * as _moment from 'moment';
import { validate } from 'node_modules1111/codelyzer/walkerFactory/walkerFn';

const moment = _moment;

export const MY_FORMATS = {
	parse: {
		dateInput: 'DD-MM-YYYY',
	},
	display: {
		dateInput: 'DD-MM-YYYY',
		monthYearLabel: 'MMMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY'
	},
};

declare const $: any;
@Component({
	selector: 'app-backlog',
	templateUrl: './backlog.component.html',
	styleUrls: ['./backlog.component.scss'],
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},

		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	]
})

export class BacklogComponent implements OnInit {

	sprintCreateForm: FormGroup;
	sprintActivateForm: FormGroup;

	selectedSprint: any;
	sprintData: any = [];
	pid: any;
	// sid : any = localStorage.getItem('sprintIdSelected')?localStorage.getItem('sprintIdSelected'):'';
	// sname : any = localStorage.getItem('sprintNameSelected')?localStorage.getItem('sprintNameSelected'):'';
	releaseList: any;
	projectListData: any;
	projectData: any;
	sprintList: any;

	// linkTabs : any = ['Release','User Stories','Task','Defect','Test Plan']
	backlogData: any = [];
	plannedData: any = [];
	unclosedData: any;

	files: any = [];
	imgArr: any = [];

	subs = new Subscription();
	fromDate: any;
	toDate: any;
	sprintCreateFormSubmit: boolean = false;
	isLoading: boolean = false;
	backlogArray: any = [];
	connectedTo = [];
	minEndDate: Date;
	maxStartDate: Date;
	isActiveSprintDialogShow: boolean = false;
	isCreateSprintDialogShow: boolean = false;
	isShowMore: boolean = false;
	isOwner: any = JSON.parse(localStorage.getItem("isOwner"));
	isCompanyAdmin: any = JSON.parse(localStorage.getItem("isCompanyAdmin"));
	isProjectManager: any = JSON.parse(localStorage.getItem("isProjectManager"));
	userData: any = JSON.parse(localStorage.getItem("userData"));
	backlogTodoCount: any = 0;
	backlogInProgressCount: any = 0;
	backlogQCCount: any = 0;
	backlogDoneCount: any = 0;
	backlogReopenCount: any = 0;
	backlogNotABugCount: any = 0;
	isDragDropDisabled: boolean = false;
	isEditSprint: boolean = false;
	isStartSprint: boolean = false;
	toBeCompleteSprintArray: any = [];
	pendingIssueForCompleteSprintCount: any;
	doneIssueForCompleteSprintCount: any;
	doneDropdownSprintList: any = [];
	selectedSprintForMoveItem = 0;
	searchdata: any;
	hideSprints: any = [];

	durationList = [
		{
			name: "1 Week",
			value: "1 Week"
		},
		{
			name: "2 Weeks",
			value: "2 Weeks"
		},
		{
			name: "3 Weeks",
			value: "3 Weeks"
		},
		{
			name: "4 Weeks",
			value: "4 Weeks"
		},
		{
			name: "Custom",
			value: "Custom"
		}
	];

	constructor(
		private restService: RestService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private dragulaService: DragulaService,
		private dateService: DateConverterService, private fileUploadService: FileUploadService) {
		// Check for route params

		this.pid = localStorage.getItem('projectSelected')

		if (this.pid && this.pid !== null && this.pid !== undefined) {
			this.isLoading = true;
			this.getProjectDetail();
			this.getUnassociated();
			this.getSprintList();
		}

		// 	isOwner: any = JSON.parse(localStorage.getItem("isOwner"));
		// isCompanyAdmin: any = JSON.parse(localStorage.getItem("isCompanyAdmin"));
		// isProjectManager: any = JSON.parse(localStorage.getItem("isProjectManager"));

		console.log("isOwner ", this.isOwner)
		console.log("isCompanyAdmin ", this.isCompanyAdmin)
		console.log("isProjectManager ", this.isProjectManager)

		//this.route.params.subscribe(params => {
		//this.pid = params['pid'];

		//});
		// this.getProjectList();
		//this.getSprintList();
		// this.getPlannedItem();
		// if(this.sid){
		// 	this.getPlannedItem();
		// }


		//drag
		this.subs.add(this.dragulaService.drag("DRAGULA")
			.subscribe(({ name, el, source }) => {
				// console.log(source.id);
				if (source.id == 'backlog') {
					$('.sprint-backlog-section .dragging-issues').addClass('active');
					$('.sprint-backlog-section .dragging-issues').html('');
				} else {
					$('.backlog-board-white-panel .dragging-issues').addClass('active');
					$('.backlog-board-white-panel .dragging-issues').html('');
				}
			})
		);
		this.subs.add(this.dragulaService.cancel("DRAGULA")
			.subscribe(({ name, el, source }) => {
				// console.log(source.id);
				if (source.id == 'backlog') {
					$('.sprint-backlog-section .dragging-issues').removeClass('active');
					$('.sprint-backlog-section .dragging-issues').html('Plan a sprint by dragging the sprint footer down below some issues, or by dragging issues here.');
				} else {
					$('.backlog-board-white-panel .dragging-issues').removeClass('active');
					$('.backlog-board-white-panel .dragging-issues').html('Drag and drop your planned items again into backlog.');
				}
			})
		);



		//drag and drop
		this.subs.add(this.dragulaService.drop("DRAGULA")
			.subscribe(({ name, el, source, target }) => {
				// console.log(el);
				// console.log(target);

				let entity: any = el.id.split("-")[0];
				let id: any = '';
				let sprintId: any = '';

				console.log(el.id);
				console.log(target.id);
				// if(target.id.split("-")[0]){
				// 	id = el.id.split("-")[1];
				// 	sprintId = target.id.split("-")[1];
				// 	this.moveToPlanned(entity,sprintId,id,1);
				// }else{
				// 	id = el.id.split("-")[1];
				// 	sprintId = el.id.split("-")[2];
				// 	this.moveToPlanned(entity,sprintId,id,0);
				// }

				if (target.id == 'backlog') {
					// id = el.id.split("-")[1];
					sprintId = el.id.split("-")[2];
					this.moveToPlanned(entity, sprintId, el, 0);
					// $('#'+el.id).remove();
				} else {
					// id = el.id.split("-")[1];
					sprintId = target.id.split("-")[1];
					this.moveToPlanned(entity, sprintId, el, 1);
				}

			}));


		//this.getReleaseList();
		this.createForm(null);
		if (this.isOwner || this.isCompanyAdmin || this.isProjectManager) {
			this.isDragDropDisabled = false;
		} else {
			this.isDragDropDisabled = true;
		}
	}

	ngOnInit() {
		let that = this;
		$(document).ready(function () {

			$('.date input').datepicker({
				format: 'dd-mm-yyyy',
				autoclose: true,
				todayHighlight: true,
				startDate: new Date()
			})
				.on('changeDate', function (e) {
					$('.datepicker').hide();
					// console.log(e.currentTarget.classList);
					// console.log(e.format('dd-mm-yyyy'));
					if (e.currentTarget.classList[1] == 'fromDate') {
						console.log(e.format('dd-mm-yyyy'));
						var minDate = new Date(e.date.valueOf());
						$('.toDate').datepicker('setStartDate', minDate);
						that.fromDate = e.format('dd-mm-yyyy')
						// that.sprintActivateForm.value.fromDate = e.format('dd-mm-yyyy');
					} else if (e.currentTarget.classList[1] == 'toDate') {
						that.toDate = e.format('dd-mm-yyyy')
						// that.sprintActivateForm.value.toDate = e.format('dd-mm-yyyy');
					}
					// that.updateSprint(e.currentTarget.classList[1],e.format('dd-mm-yyyy'));

				});
		});

		// $(document).mouseup(function(e) 
		// {
		// 	var container1 = $(".project-title-head");
		// 	var container2 = $(".project-title-list");
		// 	// console.log(e.target);
		// 	if (!container1.is(e.target) && container1.has(e.target).length === 0 && !container2.is(e.target) && container2.has(e.target).length === 0) 
		// 	{
		// 		$(".project-title-list").hide('down');
		// 	}
		// });



	}

	createForm(data = null) {
		// console.log(data);
		if (data) {
			console.log("i am here data:", data);
			this.sprintActivateForm = this.formBuilder.group({
				'name': [data.name, [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
				'goal': [data.goal],
				'releaseId': [data.releaseId],
				'releaseName': [data.releaseName],
				'duration': [data.duration, [Validators.required]],
				'fromDate': [data.fromDate],
				'toDate': [data.toDate],
				'sprintId': [data.id],
				'projectId': [this.pid]
			});

			this.sprintCreateForm = this.formBuilder.group({
				'name': [data.name, [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
				'goal': [data.goal],
				'releaseId': [data.releaseId],
				'releaseName': [data.releaseName],
				'attachments': [],
				'projectId': [this.pid]
			});

			this.isEditSprint = true;

		} else {
			console.log("data nil:");
			this.sprintActivateForm = this.formBuilder.group({
				'name': ['', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
				'goal': [''],
				'releaseId': [''],
				'releaseName': [''],
				'duration': ['', [Validators.required]],
				'fromDate': [''],
				'toDate': [''],
				'sprintId': [''],
				'projectId': [this.pid]
			});

			this.sprintCreateForm = this.formBuilder.group({
				'name': ['', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
				'goal': [''],
				'releaseId': [''],
				'releaseName': [''],
				'attachments': [],
				'projectId': [this.pid]
			});

			this.isEditSprint = false;
		}
	}

	getProjectDetail() {
		// this.restService.hitApi('project/details', { 'projectId': this.pid }, 'post', '', false)
		// 	.subscribe((result) => {
		// 		let responseData: any = result;
		// 		if (responseData.statusCode == 200) {
		// 			this.projectData = responseData.result;
		// 		} else {
		// 			// this.toastr.error(responseData.message);
		// 		}
		// 	}, (err) => {
		// 		console.log(err);
		// 		let errorData: any = err;
		// 		// this.toastr.error(errorData.error.message);
		// 	})
	}

	//Image upload
	uploadFile(event) {
		for (let index = 0; index < event.length; index++) {
			let file: File = event[index];
			const this_ = this;
			const formData: FormData = new FormData();
			formData.append('projectId', this.pid);
			formData.append('entity', 'sprint');
			formData.append('file', file);

			var reader = new FileReader();

			var obj;

			reader.onload = function (e: any) {
				obj = { displayName: file.name, serverFileName: file.name, name: file.name, image: e.target.result, isProgressBarShown: true }
				this_.files.push(obj);

			}

			reader.readAsDataURL(file);

			this.fileUploadService.uploadFile(formData, 'sprints/upload-file')
				.subscribe((event: HttpEvent<any>) => {
					switch (event.type) {
						case HttpEventType.Sent:
							console.log('Request has been made!');
							break;
						case HttpEventType.ResponseHeader:
							console.log('Response header has been received!');
							break;
						case HttpEventType.UploadProgress:
							this.files[this.files.length - 1].progress = Math.round(event.loaded / event.total * 100);
							console.log(`Uploaded! ${this.files[this.files.length - 1].progress}%`);
							$('#attachment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');

							break;
						case HttpEventType.Response:
							let responseData: any = event.body;
							if (responseData.statusCode == 200) {
								this.imgArr.push(responseData.result);
								this.files[this.files.length - 1].serverFileName = responseData.result;
								this.files.forEach(element => {
									element.isProgressBarShown = false;
								});
							} else {
								if (responseData.message) {
									this.toastr.error(responseData.message);
								}
							}
							console.log('Upload successfully', event.body);
							setTimeout(() => {
								this.files[this.files.length - 1].progress = 0;
								$('#attachment_progress_bar_' + (this.files.length - 1)).attr('aria-valuenow', this.files[this.files.length - 1].progress).css('width', this.files[this.files.length - 1].progress + '%');

							}, 1500);


					}
				})


			// this.restService.hitApi('sprints/upload-file', formData, 'post', '', true, false)
			// 	.subscribe((result) => {
			// 		let responseData: any = result;
			// 		if (responseData.statusCode == 200) {
			// 			this.imgArr.push(responseData.result);
			// 		} else {
			// 			this.toastr.error(responseData.message);
			// 		}
			// 	}, (err) => {
			// 		console.log(err);
			// 		let errorData: any = err;
			// 		this.toastr.error(errorData.error.message);
			// 	})
		}

	}

	// getProjectList() {
	// 	this.restService.hitApi('discussion/projects',{},'get','',true)
	// 	.subscribe((result)=>{
	// 		let responseData : any = result;
	// 		if(responseData.statusCode==200){
	// 			this.projectListData = responseData.result.data;
	// 		}else{
	// 			// this.toastr.error(responseData.message);
	// 		}
	// 	},(err)=>{
	// 		console.log(err);
	// 		let errorData : any = err;
	// 		// this.toastr.error(errorData.error.message);
	// 	})
	// }

	getReleaseList() {
		// this.restService.hitApi('release/list-dropdown?projectId=' + this.pid, '', 'get', '', false)
		// 	.subscribe((result) => {
		// 		let responseData: any = result;
		// 		if (responseData.statusCode == 200) {
		// 			this.releaseList = responseData.result
		// 		} else {
		// 			this.toastr.error(responseData.message);
		// 		}
		// 	}, (err) => {
		// 		console.log(err);
		// 		let errorData: any = err;
		// 		// this.toastr.error(errorData.error.message);
		// 	})
	}

	getSprintList() {

		this.restService.hitApi('sprints/list-backlog', { "projectId": this.pid, "keyword": this.searchdata }, 'post', '', false)
			.subscribe((result) => {
				this.isLoading = false;
				let responseData: any = result;
				if (responseData.statusCode == 200) {

					responseData.result.forEach(element => {
						if (element.toDate) {
							var d = Date.parse(element.toDate);
							var toDate = new Date(d);
							element.toDate = toDate
						}

						if (element.fromDate) {
							var d = Date.parse(element.fromDate);
							var fromDate = new Date(d);
							element.fromDate = fromDate
						}
					});

					this.sprintList = responseData.result;

					this.connectedTo = [];
					//console.log("sprintList, ", responseData.result)
					responseData.result.forEach(element => {
						this.connectedTo.push(element.name);
					});

					this.connectedTo.push("backlog-List");

					console.log("connectedTo, ", this.connectedTo)
					console.log("this.hideSprints", this.hideSprints)

					setTimeout(() => {
						this.hideSprints.forEach(element => {
							$('#sprintId-' + element).slideUp('fast');
						});
					});



				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}

	createSprint() {
		if (this.sprintCreateForm.invalid) {
			this.sprintCreateFormSubmit = true;
			return;
		} else {
			this.sprintCreateForm.value.projectId = this.pid;
			this.sprintCreateForm.value.attachments = this.imgArr;

			this.restService.hitApi('sprint/create', this.sprintCreateForm.value, 'post', '', true)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.isLoading = true;
						this.closeCreateSprintModal();
						this.getSprintList();
						$('#create_release_popup_web').modal('hide');
						this.toastr.success(responseData.message);
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})
		}
	}

	// changeProject(pid){
	// 	localStorage.setItem('projectSelected',pid)
	// 	$('#select-project-open-dpw').toggle("slide");
	// 	this.router.navigateByUrl('/project/'+pid+'/backlog');
	// 	// this.sid = localStorage.getItem('projectSelected');
	// }

	// changeSprint(sid,sname){
	// 	localStorage.setItem('sprintIdSelected',sid);
	// 	localStorage.setItem('sprintNameSelected',sname);
	// 	$('#select-sprint-open-dpw').toggle("slide");
	// 	this.sid = sid;
	// 	this.sname = sname;
	// 	this.getPlannedItem();
	// }



	getUnassociated() {
		this.restService.hitApi('sprints/getUnAssociatedSprintBacklog', { "projectId": this.pid, "backlog": 0, "associated": 0, "keyword": this.searchdata }, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.backlogArray = responseData.result;
					console.log("responseData, ", responseData)

					var todo = [];
					var inprogress = [];
					var QC = [];
					var done = [];
					var reopen = [];
					var notABug = [];

					for (let i = 0; i < this.backlogArray.length; i++) {
						let obj = this.backlogArray[i];
						if (obj.status == 1) {
							todo.push(obj);
						} else if (obj.status == 2) {
							inprogress.push(obj);
						} else if (obj.status == 3) {
							QC.push(obj);
						} else if (obj.status == 4) {
							done.push(obj);
						} else if (obj.status == 5) {
							reopen.push(obj);
						} else if (obj.status == 6) {
							notABug.push(obj);
						}
					}

					this.backlogTodoCount = todo.length;
					this.backlogInProgressCount = inprogress.length;
					this.backlogQCCount = QC.length;
					this.backlogDoneCount = done.length;
					this.backlogReopenCount = reopen.length;
					this.backlogNotABugCount = notABug.length;

				} else {
					// this.toastr.error(responseData.message);
				}
				this.isLoading = false;
			}, (err) => {
				this.isLoading = false;
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			})
	}


	// getPlannedItem(){

	// 	this.restService.hitApi('sprints/getUnAssociatedSprint',{"projectId":this.pid, "backlog":0, "associated":1},'post','',false,false)
	// 		.subscribe((result)=>{
	// 			let responseData : any = result;
	// 			if(responseData.statusCode==200){
	// 				this.plannedData = responseData.result;
	// 			}else{
	// 				this.toastr.error(responseData.message);
	// 			}
	// 		},(err)=>{
	// 			console.log(err);
	// 			let errorData : any = err;
	// 			this.toastr.error(errorData.error.message);
	// 	})
	// }

	moveToPlanned(entity, sprintId, el, set) {
		// let id:any = [0];
		// id = entityId.split("-")[1];
		// let entity = entityId.split("-")[0];
		// let sprintId : any = target.split("-")[1];
		let data: any;
		// if(target.split("-")[0]=='sprint'){
		let id: any = el.id.split("-")[1];
		data = { "projectId": this.pid, "sprintId": sprintId, "entity": entity, "id": [id], "set": set }
		// }else{
		// data = {"projectId":this.pid,"sprintId":sprintId, "entity":entity, "id":[id],"set":0}
		// }


		// this.restService.hitApi('sprints/associateSprint', data, 'post', '', true, false)
		// 	.subscribe((result) => {
		// 		let responseData: any = result;
		// 		if (responseData.statusCode == 200) {
		// 			if (set == 0) {
		// 				$('#' + el.id).remove();
		// 			}
		// 			this.getSprintList();
		// 			this.getUnassociated();
		// 		} else {
		// 			this.getSprintList();
		// 			this.getUnassociated();
		// 			// this.toastr.error(responseData.message);
		// 		}
		// 	}, (err) => {
		// 		console.log(err);
		// 		let errorData: any = err;
		// 		this.getSprintList();
		// 		this.getUnassociated();
		// 		// this.toastr.error(errorData.error.message);
		// 	})
	}

	searchData() {
		this.searchdata = $('#search_name').val();
		this.getUnassociated();
		this.getSprintList();
	}

	refreshAPI() {
		this.isLoading = true;
		this.getProjectDetail();
		this.getUnassociated();
		this.getSprintList();

		setTimeout(() => {
			this.toastr.success("Refreshed successfully");
		}, 1000);
	}

	activateSprint() {
		//this.sprintActivateForm.value.projectId = this.pid;
		//this.sprintActivateForm.value.fromDate = this.fromDate;
		//this.sprintActivateForm.value.toDate = this.toDate;

		if (this.isEditSprint && !this.selectedSprint?.activeSprint) {
			this.restService.hitApi('sprint/update', this.sprintActivateForm.value, 'post', '', true, false)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.isEditSprint = false;
						$('#create_activate-sprint').modal('hide');
						this.closeActiveSprintModal();
						this.getSprintList();
					} else {
						this.toastr.error(responseData.message);
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.toastr.error(errorData.error.message);
				})

			return true;
		}

		if (this.sprintActivateForm.get('toDate').value == '') {
			this.sprintActivateForm.get('toDate').setValue(null)
		}

		console.log(this.sprintActivateForm);

		if (this.sprintActivateForm.invalid) {
			console.log("this.sprintActivateForm.invalid");
			return true;
		} else {
			var serverFromDate = ""
			var serverToDate = ""

			console.log("selcted start date-", this.sprintActivateForm.get('fromDate').value)
			let startDate = this.sprintActivateForm.get('fromDate').value
			var lstrToDate = this.dateService.convertInDDMMYYYFormate(startDate);
			serverFromDate = lstrToDate
			//this.sprintActivateForm.get('fromDate').setValue(lstrToDate)

			console.log("lstrToDatelstrToDate", lstrToDate)

			let duration = this.sprintActivateForm.get('duration').value

			var endDate = new Date();

			if (duration == '1 Week') {
				endDate = this.dateService.getDateFromDay(startDate, 7);
			} else if (duration == '2 Weeks') {
				endDate = this.dateService.getDateFromDay(startDate, 14);
			} else if (duration == '3 Weeks') {
				endDate = this.dateService.getDateFromDay(startDate, 21);
			} else if (duration == '4 Weeks') {
				endDate = this.dateService.getDateFromDay(startDate, 28);
			}

			console.log("durationduration", duration)

			if (duration != 'Custom') {
				var strToDate = this.dateService.convertInDDMMYYYFormate(endDate);
				//this.sprintActivateForm.get('toDate').setValue(strToDate)
				serverToDate = strToDate
			} else {
				let endDate = this.sprintActivateForm.get('toDate').value
				var lstrToDate = this.dateService.convertInDDMMYYYFormate(endDate);
				//this.sprintActivateForm.get('toDate').setValue(lstrToDate)
				serverToDate = strToDate
			}

			const params = {
				"name": this.sprintActivateForm.get('name').value,
				"goal": this.sprintActivateForm.get('goal').value,
				"releaseId": this.sprintActivateForm.get('releaseId').value,
				"releaseName": this.sprintActivateForm.get('releaseName').value,
				"duration": this.sprintActivateForm.get('duration').value,
				"sprintId": this.sprintActivateForm.get('sprintId').value,
				"projectId": this.pid,
				"fromDate": serverFromDate,
				"toDate": serverToDate
			}

			if (this.isEditSprint) {
				this.restService.hitApi('sprint/update', params, 'post', '', true, false)
					.subscribe((result) => {
						let responseData: any = result;
						if (responseData.statusCode == 200) {
							this.isEditSprint = false;
							$('#create_activate-sprint').modal('hide');
							this.closeActiveSprintModal();
							this.getSprintList();
						} else {
							this.toastr.error(responseData.message);
						}
					}, (err) => {
						console.log(err);
						let errorData: any = err;
						this.toastr.error(errorData.error.message);
					})
			} else {
				this.restService.hitApi('sprint/activate', params, 'post', '', true, false)
					.subscribe((result) => {
						let responseData: any = result;
						if (responseData.statusCode == 200) {
							$('#create_activate-sprint').modal('hide');
							this.closeActiveSprintModal();
							this.getSprintList();
						} else {
							this.toastr.error(responseData.message);
						}
					}, (err) => {
						console.log(err);
						let errorData: any = err;
						this.toastr.error(errorData.error.message);
					})
			}

		}
	}

	getUncloseddata(sprint) {
		let data: any = { 'projectId': this.pid, 'sprintId': sprint?.id };
		this.restService.hitApi('sprint/getNotCloseSprint', data, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					// $('#create_close-sprint').modal('show');

					this.unclosedData = responseData.result;

					this.pendingIssueForCompleteSprintCount = this.unclosedData?.userStory?.length + this.unclosedData?.defect?.length + this.unclosedData?.task?.length + this.unclosedData?.projectTestPlane?.length;
					this.doneIssueForCompleteSprintCount = sprint?.backlog?.length - this.pendingIssueForCompleteSprintCount;

					this.toBeCompleteSprintArray = [];
					for (let i = 0; i < this.sprintList.length; i++) {
						let obj = this.sprintList[i]
						if (sprint?.id == obj.id) {
						} else {
							let lobj = { "name": obj.name, "value": obj.id }
							this.toBeCompleteSprintArray.push(lobj)
						}
					}

					// 				toBeCompleteSprintArray: any = [];
					// pendingIssueForCompleteSprintCount: any = [];
					// doneIssueForCompleteSprintCount: any = [];

					if (this.unclosedData?.userStory?.length || this.unclosedData?.defect?.length || this.unclosedData?.task?.length || this.unclosedData?.projectTestPlane?.length) {
						$('#update-status').modal('hide');
						$('#create_close-sprint').modal('show');
					} else {
						$('#update-status').modal('show');
						$('#create_close-sprint').modal('hide');
					}

				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	selectedSprintForMoveIssues(event) {
		if (event != null) {
			this.selectedSprintForMoveItem = event.target.value
		}

		console.log("selectedSprintForMoveItem ", this.selectedSprintForMoveItem);
	}

	closeSprint(sid) {
		let data: any = { 'projectId': this.pid, 'sprintId': sid };
		if (this.selectedSprintForMoveItem != 0) {
			data["newSprintId"] = this.selectedSprintForMoveItem
		}

		this.restService.hitApi('sprint/closeSprint', data, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				this.selectedSprintForMoveItem = 0;
				if (responseData.statusCode == 200) {
					$('#create_close-sprint').modal('hide');
					$('#update-status').modal('hide');
					this.getSprintList();
					this.getUnassociated();
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	deleteSprint(rid) {
		console.log("deleting ..", rid)
		this.restService.hitApi('sprint/delete', { 'sprintId': rid }, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.getSprintList();
					this.getUnassociated();
					$('#delete-record').modal('hide');
					this.toastr.success(responseData.message);
				} else {
					this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			})
	}

	showSprint(id) {
		const index: number = this.hideSprints.indexOf(id);
		console.log("index", index)
		if (index !== -1) {
			this.hideSprints.splice(index, 1);
			$('#sprintId-' + id).slideDown('fast');
		}
	}

	hideSprint(id) {
		const index: number = this.hideSprints.indexOf(id);
		if (index == -1) {
			this.hideSprints.push(id)
			$('#sprintId-' + id).slideUp('fast');
		}

	}

	// openClose(id) {
	// 	$('#sprintId-' + id).toggle('fast');
	// }

	isHide(id) {

		var isFound = false

		for (let i = 0; i <= this.hideSprints.length; i++) {
			if (this.hideSprints[i] == id) {
				isFound = true
				break
			}
		}

		return isFound
	}

	getRoleType(obj: Data) {
		if (obj) {
			console.log("selectedRole" + obj.name);
			console.log("selectedId" + obj.id);
			this.sprintCreateForm.get('releaseId').setValue(parseInt(obj.id));
			this.sprintCreateForm.get('releaseName').setValue(parseInt(obj.name));

			console.log(this.sprintCreateForm);
		}

	}

	getSelectedDuration(obj: Data) {
		if (obj) {
			this.sprintActivateForm.get('duration').setValue(obj.value);
			console.log("selectedRole" + obj.value);

			if (obj.value == 'Custom') {
				this.sprintActivateForm.get('toDate').setValue(null);
				this.sprintActivateForm.get('toDate').setValidators([Validators.required]);
			} else {
				this.sprintActivateForm.get('toDate').setValidators(null);
				this.sprintActivateForm.get('toDate').setAsyncValidators(null);
				this.sprintActivateForm.get('toDate').setErrors(null);
			}
		}

	}

	goToUserProfile(id) {
		if (id) {
			if (this.userData?.role == 1) {
				this.router.navigateByUrl('/user-profile/' + id);
			}
		}
	}

	closeCreateSprintModal() {
		this.sprintCreateForm.reset();
		this.sprintCreateFormSubmit = false;
		this.isCreateSprintDialogShow = false;
		this.isShowMore = false
		this.imgArr = [];
		this.files = [];
	}

	closeActiveSprintModal() {
		this.sprintActivateForm.reset();
		this.isActiveSprintDialogShow = false;
		this.sprintCreateFormSubmit = false;
		this.isEditSprint = false;
		this.isStartSprint = false;
	}

	setIntoActive(sprint) {
		console.log("i am start sprint...")
		this.sprintActivateForm.get('sprintId').setValue(sprint.id);
		this.sprintActivateForm.get('name').setValue(sprint.name);
		this.sprintActivateForm.get('goal').setValue(sprint.goal);
		this.isActiveSprintDialogShow = true;
		//this.getReleaseList();
	}

	getBacklogAvalibleSprintName() {
		let names = [];
		this.sprintList.forEach(element => {
			names.push(element.name);
		});
		console.log("namesnamesnames", names);
		return names;
	}

	getSprintsAvalibleSprintName() {
		this.sprintList.forEach(element => {
			this.connectedTo.push(element.name);
		});
		this.connectedTo.push("backlog-List");
	}

	drop(event: CdkDragDrop<string[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {

			transferArrayItem(event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex);
			console.log("event: ", event);
			//event.currentIndex
			//event.container.data
			//event.container.id

			//console.log("event.container.data: ", event.container.data);
			// console.log("event.previousIndex: ", event.previousIndex);
			// console.log("event.currentIndex): ", event.currentIndex);

			let id = null;
			var sprintId = "";
			var entity = "";
			var set = 1;

			if (event.container.data != null) {
				let obj: any = event.container.data[event.currentIndex];
				entity = obj.entityType;
				id = obj.id.toString();
			}

			if (event.container.id != null) {
				this.sprintList.forEach(element => {
					if (element.name == event.container.id) {
						sprintId = element.id
					}
				});
			}

			if (sprintId == "") {
				if (event.previousContainer.id != null) {
					this.sprintList.forEach(element => {
						if (element.name == event.previousContainer.id) {
							sprintId = element.id
						}
					});
				}
			}

			if (event.container.id == "backlog-List") {
				set = 0;
			}

			let data = { "projectId": this.pid, "sprintId": sprintId, "entity": entity, "id": [id], "set": set }

			console.log("datadatadata, ", data);

			this.restService.hitApi('sprints/associateSprint', data, 'post', '', false, false)
				.subscribe((result) => {
					let responseData: any = result;
					if (responseData.statusCode == 200) {
						this.getSprintList();
						this.getUnassociated();
					} else {
						this.getSprintList();
						this.getUnassociated();
					}
				}, (err) => {
					console.log(err);
					let errorData: any = err;
					this.getSprintList();
					this.getUnassociated();
				})
		}
	}

	removeAttachment(index) {
		var file = this.files[index];
		var deleteFileArray = [file.serverFileName];
		this.removeAttachmentFromServer(deleteFileArray, index);
		// this.files.splice(index, 1)
	}

	removeAttachmentFromServer(files, index) {
		var json = {
			projectId: this.pid,
			entity: 'sprint',
			files: files
		}
		this.restService.hitApi('project/deleteUploadedFiles', json, 'post', '', true, false)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.files.splice(index, 1);
					if (files && this.imgArr) {
						var removeIndex = null;
						files.forEach(element1 => {
							for (var i = 0; i < this.imgArr.length; i++) {
								if (this.imgArr[i] == element1) {
									removeIndex = i;
									break
								}
							}


						});

					}
					if (removeIndex != null) {
						this.imgArr.splice(removeIndex, 1);

					}
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				this.toastr.error(errorData.error.message);
			});
	}

	startDateEvent(type: string, event: MatDatepickerInputEvent<Date>) {
		let startDate: Moment = this.sprintActivateForm.get('fromDate').value;
		if (startDate != null) {
			console.log("startDate.toDate()", startDate.toDate())
			this.sprintActivateForm.get('fromDate').setValue(startDate.toDate());
			this.minEndDate = startDate.toDate();

			if (this.sprintActivateForm.get('duration').value == 'Custom') {
				this.sprintActivateForm.get('toDate').setValue('');
			}

		}

		console.log("sprintActivateForm", this.sprintActivateForm);
	}

	endDateEvent(type: string, event: MatDatepickerInputEvent<Date>) {
		let endDate: Moment = this.sprintActivateForm.get('toDate').value;
		if (endDate != null) {
			this.sprintActivateForm.get('toDate').setValue(endDate.toDate());
			this.maxStartDate = endDate.toDate();
		}

		console.log("sprintActivateForm", this.sprintActivateForm);
	}

	showMore() {
		this.isShowMore = true;
		$('.moretext').slideDown();

	}

	showLess() {
		this.isShowMore = false;
		$('.moretext').slideUp();

	}

	isShowEditFileds() {
		if (this.isStartSprint) {
			return true
		} else if (this.isEditSprint && !this.selectedSprint?.activeSprint) {
			return false
		}

		return true
	}

	getSprintDetails(sprint) {
		this.selectedSprint = sprint;
		this.isLoading = true;
		this.restService.hitApi('sprint/details', { 'sprintId': sprint.entityId }, 'post', '', true)
			.subscribe((result) => {
				this.isLoading = false;
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					this.selectedSprint = responseData.result;

					// this.createForm(this.sprintData);
				} else {
					// this.toastr.error(responseData.message);
				}
			}, (err) => {
				console.log(err);
				let errorData: any = err;
				// this.toastr.error(errorData.error.message);
			});
	}
}

