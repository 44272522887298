import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { ValidationService } from '../services/config/config.service';
import { RestService } from '../services/rest/rest.service';

import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
	forgotForm: FormGroup;
	errorMessage: string = "";
	successMessage: string = "";
	sentEmail: boolean = false;
	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private restService: RestService,
		private toastr: ToastrService) {
		this.forgotForm = this.formBuilder.group({
			email: ['', [Validators.required, ValidationService.emailValidator]],
			recaptchaToken: ['', [Validators.required]],
		});
	}

	// Check if user already logged in
	ngOnInit() {
		if (localStorage.getItem('userData')) {
			// let userData = JSON.parse(localStorage.getItem('userData'))
			// userData.role==1?this.router.navigate(['/user-management']):this.router.navigate(['/discussion-board']);
			this.router.navigate(['/dashboard']);
		}
	}

	doForgot() {
		this.successMessage = "";
		this.errorMessage = "";
		this.forgotForm.value.deviceInfo = { "id": "web" }

		this.restService.hitApi('forgotPassword', this.forgotForm.value, 'post', '', true)
			.subscribe((result) => {
				let responseData: any = result;
				if (responseData.statusCode == 200) {
					//this.forgotForm.reset();
					this.toastr.success(responseData.message);
					this.sentEmail = true;
				} else {
					this.toastr.error(responseData.message);
					this.sentEmail = false;
				}
			}, (err) => {
				this.toastr.error(err.error.message);
				this.sentEmail = false;
			})
		// this.getRecaptchaToken().then(v => {
		// 	this.forgotForm.value.recaptchaToken = v;
		// 	this.restService.hitApi('forgotPassword', this.forgotForm.value, 'post', '', true)
		// 		.subscribe((result) => {
		// 			let responseData: any = result;
		// 			if (responseData.statusCode == 200) {
		// 				//this.forgotForm.reset();
		// 				this.toastr.success(responseData.message);
		// 				this.sentEmail = true;
		// 			} else {
		// 				this.toastr.error(responseData.message);
		// 				this.sentEmail = false;
		// 			}
		// 		}, (err) => {
		// 			this.toastr.error(err.error.message);
		// 			this.sentEmail = false;
		// 		})

		// });

	}

	onSubmit(token) {
		this.forgotForm.get('recaptchaToken').setValue(token);
	}

	// callApiForToken() {
	// 	return new Promise(function (resolve, reject) {
	// 		grecaptcha.ready(function () {
	// 			grecaptcha.execute('6Lda-6caAAAAANaqQsn1PUA7sg25-Ll_bh1zsIzY', { action: 'forgotPassword' }).then(function (token) {
	// 				resolve(token);

	// 			});

	// 		});

	// 	});
	// }

	// getRecaptchaToken = async function () {
	// 	var tokenValue = await this.callApiForToken();
	// 	return tokenValue;


	// }


}
